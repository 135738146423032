export class VisasEstudio {
  public static visados= {
      "visas": [
        {
          "pasaporte": "Ecuador",
          "origen": "Todos",
          "destino": "Andorra",
          "tipo": "estudio",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Carta de aceptación \nsolvencia económica \ncertificado médico \nantecedentes penales \nseguro medico"
        },
        {
          "pasaporte": "Ecuador",
          "origen": "Todos",
          "destino": "Austria",
          "tipo": "estudio",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Carta de aceptación \nsolvencia económica \ncertificado médico \nantecedentes penales \nseguro medico"
        },
        {
          "pasaporte": "Ecuador",
          "origen": "Todos",
          "destino": "Bélgica",
          "tipo": "estudio",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Carta de aceptación \nsolvencia económica \ncertificado médico \nantecedentes penales \nseguro medico"
        },
        {
          "pasaporte": "Ecuador",
          "origen": "Todos",
          "destino": "Bulgaria",
          "tipo": "estudio",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Carta de aceptación \nsolvencia económica \ncertificado médico \nantecedentes penales \nseguro medico"
        },
        {
          "pasaporte": "Ecuador",
          "origen": "Todos",
          "destino": "Dinamarca",
          "tipo": "estudio",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Carta de aceptación \nsolvencia económica \ncertificado médico \nantecedentes penales \nseguro medico"
        },
        {
          "pasaporte": "Ecuador",
          "origen": "Todos",
          "destino": "Eslovaquia",
          "tipo": "estudio",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Carta de aceptación \nsolvencia económica \ncertificado médico \nantecedentes penales \nseguro medico"
        },
        {
          "pasaporte": "Ecuador",
          "origen": "Todos",
          "destino": "Eslovenia",
          "tipo": "estudio",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Carta de aceptación \nsolvencia económica \ncertificado médico \nantecedentes penales \nseguro medico"
        },
        {
          "pasaporte": "Ecuador",
          "origen": "Todos",
          "destino": "España",
          "tipo": "estudio",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Carta de aceptación \nsolvencia económica \ncertificado médico \nantecedentes penales \nseguro medico"
        },
        {
          "pasaporte": "Ecuador",
          "origen": "Todos",
          "destino": "Finlandia",
          "tipo": "estudio",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Carta de aceptación \nsolvencia económica \ncertificado médico \nantecedentes penales \nseguro medico"
        },
        {
          "pasaporte": "Ecuador",
          "origen": "Todos",
          "destino": "Francia",
          "tipo": "estudio",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Carta de aceptación \nsolvencia económica \ncertificado médico \nantecedentes penales \nseguro medico"
        },
        {
          "pasaporte": "Ecuador",
          "origen": "Todos",
          "destino": "Grecia",
          "tipo": "estudio",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Carta de aceptación \nsolvencia económica \ncertificado médico \nantecedentes penales \nseguro medico"
        },
        {
          "pasaporte": "Ecuador",
          "origen": "Todos",
          "destino": "Hungría",
          "tipo": "estudio",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Carta de aceptación \nsolvencia económica \ncertificado médico \nantecedentes penales \nseguro medico"
        },
        {
          "pasaporte": "Ecuador",
          "origen": "Todos",
          "destino": "Irlanda",
          "tipo": "estudio",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Carta de aceptación \nsolvencia económica \ncertificado médico \nantecedentes penales \nseguro medico"
        },
        {
          "pasaporte": "Ecuador",
          "origen": "Todos",
          "destino": "Islandia",
          "tipo": "estudio",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Carta de aceptación \nsolvencia económica \ncertificado médico \nantecedentes penales \nseguro medico"
        },
        {
          "pasaporte": "Ecuador",
          "origen": "Todos",
          "destino": "Italia",
          "tipo": "estudio",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Carta de aceptación \nsolvencia económica \ncertificado médico \nantecedentes penales \nseguro medico"
        },
        {
          "pasaporte": "Ecuador",
          "origen": "Todos",
          "destino": "Letonia",
          "tipo": "estudio",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Carta de aceptación \nsolvencia económica \ncertificado médico \nantecedentes penales \nseguro medico"
        },
        {
          "pasaporte": "Ecuador",
          "origen": "Todos",
          "destino": "Liechtenstein",
          "tipo": "estudio",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Carta de aceptación \nsolvencia económica \ncertificado médico \nantecedentes penales \nseguro medico"
        },
        {
          "pasaporte": "Ecuador",
          "origen": "Todos",
          "destino": "Luxemburgo",
          "tipo": "estudio",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Carta de aceptación \nsolvencia económica \ncertificado médico \nantecedentes penales \nseguro medico"
        },
        {
          "pasaporte": "Ecuador",
          "origen": "Todos",
          "destino": "Malta",
          "tipo": "estudio",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Carta de aceptación \nsolvencia económica \ncertificado médico \nantecedentes penales \nseguro medico"
        },
        {
          "pasaporte": "Ecuador",
          "origen": "Todos",
          "destino": "Mónaco",
          "tipo": "estudio",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Carta de aceptación \nsolvencia económica \ncertificado médico \nantecedentes penales \nseguro medico"
        },
        {
          "pasaporte": "Ecuador",
          "origen": "Todos",
          "destino": "Noruega",
          "tipo": "estudio",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Carta de aceptación \nsolvencia económica \ncertificado médico \nantecedentes penales \nseguro medico"
        },
        {
          "pasaporte": "Ecuador",
          "origen": "Todos",
          "destino": "Países Bajos",
          "tipo": "estudio",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Carta de aceptación \nsolvencia económica \ncertificado médico \nantecedentes penales \nseguro medico"
        },
        {
          "pasaporte": "Ecuador",
          "origen": "Todos",
          "destino": "Polonia",
          "tipo": "estudio",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Carta de aceptación \nsolvencia económica \ncertificado médico \nantecedentes penales \nseguro medico"
        },
        {
          "pasaporte": "Ecuador",
          "origen": "Todos",
          "destino": "Portugal",
          "tipo": "estudio",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Carta de aceptación \nsolvencia económica \ncertificado médico \nantecedentes penales \nseguro medico"
        },
        {
          "pasaporte": "Ecuador",
          "origen": "Todos",
          "destino": "Reino Unido",
          "tipo": "estudio",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Carta de aceptación \nsolvencia económica \ncertificado médico \nantecedentes penales \nseguro medico"
        },
        {
          "pasaporte": "Ecuador",
          "origen": "Todos",
          "destino": "República Checa",
          "tipo": "estudio",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Carta de aceptación \nsolvencia económica \ncertificado médico \nantecedentes penales \nseguro medico"
        },
        {
          "pasaporte": "Ecuador",
          "origen": "Todos",
          "destino": "Rumanía",
          "tipo": "estudio",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Carta de aceptación \nsolvencia económica \ncertificado médico \nantecedentes penales \nseguro medico"
        },
        {
          "pasaporte": "Ecuador",
          "origen": "Todos",
          "destino": "Suecia",
          "tipo": "estudio",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Carta de aceptación \nsolvencia económica \ncertificado médico \nantecedentes penales \nseguro medico"
        },
        {
          "pasaporte": "Ecuador",
          "origen": "Todos",
          "destino": "Suiza",
          "tipo": "estudio",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Carta de aceptación \nsolvencia económica \ncertificado médico \nantecedentes penales \nseguro medico"
        },
        {
          "pasaporte": "Colombia",
          "origen": "Todos",
          "destino": "Andorra",
          "tipo": "estudio",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Carta de aceptación \nsolvencia económica \ncertificado médico \nantecedentes penales \nseguro medico"
        },
        {
          "pasaporte": "Colombia",
          "origen": "Todos",
          "destino": "Austria",
          "tipo": "estudio",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Carta de aceptación \nsolvencia económica \ncertificado médico \nantecedentes penales \nseguro medico"
        },
        {
          "pasaporte": "Colombia",
          "origen": "Todos",
          "destino": "Bélgica",
          "tipo": "estudio",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Carta de aceptación \nsolvencia económica \ncertificado médico \nantecedentes penales \nseguro medico"
        },
        {
          "pasaporte": "Colombia",
          "origen": "Todos",
          "destino": "Bulgaria",
          "tipo": "estudio",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Carta de aceptación \nsolvencia económica \ncertificado médico \nantecedentes penales \nseguro medico"
        },
        {
          "pasaporte": "Colombia",
          "origen": "Todos",
          "destino": "Dinamarca",
          "tipo": "estudio",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Carta de aceptación \nsolvencia económica \ncertificado médico \nantecedentes penales \nseguro medico"
        },
        {
          "pasaporte": "Colombia",
          "origen": "Todos",
          "destino": "Eslovaquia",
          "tipo": "estudio",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Carta de aceptación \nsolvencia económica \ncertificado médico \nantecedentes penales \nseguro medico"
        },
        {
          "pasaporte": "Colombia",
          "origen": "Todos",
          "destino": "Eslovenia",
          "tipo": "estudio",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Carta de aceptación \nsolvencia económica \ncertificado médico \nantecedentes penales \nseguro medico"
        },
        {
          "pasaporte": "Colombia",
          "origen": "Todos",
          "destino": "España",
          "tipo": "estudio",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Carta de aceptación \nsolvencia económica \ncertificado médico \nantecedentes penales \nseguro medico"
        },
        {
          "pasaporte": "Colombia",
          "origen": "Todos",
          "destino": "Finlandia",
          "tipo": "estudio",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Carta de aceptación \nsolvencia económica \ncertificado médico \nantecedentes penales \nseguro medico"
        },
        {
          "pasaporte": "Colombia",
          "origen": "Todos",
          "destino": "Francia",
          "tipo": "estudio",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Carta de aceptación \nsolvencia económica \ncertificado médico \nantecedentes penales \nseguro medico"
        },
        {
          "pasaporte": "Colombia",
          "origen": "Todos",
          "destino": "Grecia",
          "tipo": "estudio",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Carta de aceptación \nsolvencia económica \ncertificado médico \nantecedentes penales \nseguro medico"
        },
        {
          "pasaporte": "Colombia",
          "origen": "Todos",
          "destino": "Hungría",
          "tipo": "estudio",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Carta de aceptación \nsolvencia económica \ncertificado médico \nantecedentes penales \nseguro medico"
        },
        {
          "pasaporte": "Colombia",
          "origen": "Todos",
          "destino": "Irlanda",
          "tipo": "estudio",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Carta de aceptación \nsolvencia económica \ncertificado médico \nantecedentes penales \nseguro medico"
        },
        {
          "pasaporte": "Colombia",
          "origen": "Todos",
          "destino": "Islandia",
          "tipo": "estudio",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Carta de aceptación \nsolvencia económica \ncertificado médico \nantecedentes penales \nseguro medico"
        },
        {
          "pasaporte": "Colombia",
          "origen": "Todos",
          "destino": "Italia",
          "tipo": "estudio",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Carta de aceptación \nsolvencia económica \ncertificado médico \nantecedentes penales \nseguro medico"
        },
        {
          "pasaporte": "Colombia",
          "origen": "Todos",
          "destino": "Letonia",
          "tipo": "estudio",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Carta de aceptación \nsolvencia económica \ncertificado médico \nantecedentes penales \nseguro medico"
        },
        {
          "pasaporte": "Colombia",
          "origen": "Todos",
          "destino": "Liechtenstein",
          "tipo": "estudio",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Carta de aceptación \nsolvencia económica \ncertificado médico \nantecedentes penales \nseguro medico"
        },
        {
          "pasaporte": "Colombia",
          "origen": "Todos",
          "destino": "Luxemburgo",
          "tipo": "estudio",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Carta de aceptación \nsolvencia económica \ncertificado médico \nantecedentes penales \nseguro medico"
        },
        {
          "pasaporte": "Colombia",
          "origen": "Todos",
          "destino": "Malta",
          "tipo": "estudio",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Carta de aceptación \nsolvencia económica \ncertificado médico \nantecedentes penales \nseguro medico"
        },
        {
          "pasaporte": "Colombia",
          "origen": "Todos",
          "destino": "Mónaco",
          "tipo": "estudio",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Carta de aceptación \nsolvencia económica \ncertificado médico \nantecedentes penales \nseguro medico"
        },
        {
          "pasaporte": "Colombia",
          "origen": "Todos",
          "destino": "Noruega",
          "tipo": "estudio",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Carta de aceptación \nsolvencia económica \ncertificado médico \nantecedentes penales \nseguro medico"
        },
        {
          "pasaporte": "Colombia",
          "origen": "Todos",
          "destino": "Países Bajos",
          "tipo": "estudio",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Carta de aceptación \nsolvencia económica \ncertificado médico \nantecedentes penales \nseguro medico"
        },
        {
          "pasaporte": "Colombia",
          "origen": "Todos",
          "destino": "Polonia",
          "tipo": "estudio",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Carta de aceptación \nsolvencia económica \ncertificado médico \nantecedentes penales \nseguro medico"
        },
        {
          "pasaporte": "Colombia",
          "origen": "Todos",
          "destino": "Portugal",
          "tipo": "estudio",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Carta de aceptación \nsolvencia económica \ncertificado médico \nantecedentes penales \nseguro medico"
        },
        {
          "pasaporte": "Colombia",
          "origen": "Todos",
          "destino": "Reino Unido",
          "tipo": "estudio",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Carta de aceptación \nsolvencia económica \ncertificado médico \nantecedentes penales \nseguro medico"
        },
        {
          "pasaporte": "Colombia",
          "origen": "Todos",
          "destino": "República Checa",
          "tipo": "estudio",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Carta de aceptación \nsolvencia económica \ncertificado médico \nantecedentes penales \nseguro medico"
        },
        {
          "pasaporte": "Colombia",
          "origen": "Todos",
          "destino": "Rumanía",
          "tipo": "estudio",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Carta de aceptación \nsolvencia económica \ncertificado médico \nantecedentes penales \nseguro medico"
        },
        {
          "pasaporte": "Colombia",
          "origen": "Todos",
          "destino": "Suecia",
          "tipo": "estudio",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Carta de aceptación \nsolvencia económica \ncertificado médico \nantecedentes penales \nseguro medico"
        },
        {
          "pasaporte": "Colombia",
          "origen": "Todos",
          "destino": "Suiza",
          "tipo": "estudio",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Carta de aceptación \nsolvencia económica \ncertificado médico \nantecedentes penales \nseguro medico"
        },
        {
          "pasaporte": "Colombia",
          "origen": "Todos",
          "destino": "Alemania",
          "tipo": "estudio",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Carta de aceptación \nsolvencia económica \nsuficiencia del idioma"
        },
        {
          "pasaporte": "Colombia",
          "origen": "Todos",
          "destino": "Arabia Saudí",
          "tipo": "estudio",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Carta de aceptación y carta MOFA"
        },
        {
          "pasaporte": "Colombia",
          "origen": "Todos",
          "destino": "Argelia",
          "tipo": "estudio",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Carta de aceptación \nsolvencia economica \njusitificación de estancia"
        },
        {
          "pasaporte": "Colombia",
          "origen": "Todos",
          "destino": "Canadá",
          "tipo": "estudio",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Carta de aceptación \nsolvencia economica"
        },
        {
          "pasaporte": "Colombia",
          "origen": "Todos",
          "destino": "Costa Rica",
          "tipo": "estudio",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Carta de aceptaación \nacta de nacimiento \nantecedentes penales \nsolvencia económica"
        },
        {
          "pasaporte": "Colombia",
          "origen": "Todos",
          "destino": "Croacia",
          "tipo": "estudio",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Carta de aceptación \nsolvencia economica"
        },
        {
          "pasaporte": "Colombia",
          "origen": "Todos",
          "destino": "Cuba",
          "tipo": "estudio",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Carta de aceptación \njustificación de estancia"
        },
        {
          "pasaporte": "Colombia",
          "origen": "Todos",
          "destino": "Estados Unidos de América",
          "tipo": "estudio",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Carta de aceptación \nsolvencia economica. Los requisitos pueden variar segun tipo de visa (F-1 \nM-1 \nJ-1)"
        },
        {
          "pasaporte": "Colombia",
          "origen": "Todos",
          "destino": "Estonia",
          "tipo": "estudio",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Carta de aceptación \nsolvencia económica \njusitifcación de estancia \nseguro medico \nacta de nacimiento"
        },
        {
          "pasaporte": "Colombia",
          "origen": "Todos",
          "destino": "Guatemala",
          "tipo": "estudio",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Carta de aceptación \nsolvencia económica \nantecedentes penales \nmovimientos migratorios"
        },
        {
          "pasaporte": "Colombia",
          "origen": "Todos",
          "destino": "Japón",
          "tipo": "estudio",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Certificado de elegibilidad COE \nCarta de admisión \ncarta motiva \nsolvencia economica \ncalificaciones de estudios previos"
        },
        {
          "pasaporte": "Colombia",
          "origen": "Todos",
          "destino": "Líbano",
          "tipo": "estudio",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Colombia",
          "origen": "Todos",
          "destino": "Liberia",
          "tipo": "estudio",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Colombia",
          "origen": "Todos",
          "destino": "Lituania",
          "tipo": "estudio",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Colombia",
          "origen": "Todos",
          "destino": "Marruecos",
          "tipo": "estudio",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Colombia",
          "origen": "Todos",
          "destino": "México",
          "tipo": "estudio",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Carta de aceptración \nsolvencia económica"
        },
        {
          "pasaporte": "Colombia",
          "origen": "Todos",
          "destino": "Montenegro",
          "tipo": "estudio",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Carta de aceptación \nsolvencia económica \nseguro médico \ncontancia de curso pagado en su totalidad \ndemostrar intención de retorno"
        },
        {
          "pasaporte": "Colombia",
          "origen": "Todos",
          "destino": "Nueva Zelanda",
          "tipo": "estudio",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Carta de aceptación \nsolvencia económica \nseguro médico \ncontancia de curso pagado en su totalidad \nexámenes médicos"
        },
        {
          "pasaporte": "Colombia",
          "origen": "Todos",
          "destino": "Serbia",
          "tipo": "estudio",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Carta de aceptación \nsolvencia económica \ncertificados de estudios previos"
        },
        {
          "pasaporte": "Colombia",
          "origen": "Todos",
          "destino": "Túnez",
          "tipo": "estudio",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Carta de admisión \nsolvencia económica"
        },
        {
          "pasaporte": "Colombia",
          "origen": "Todos",
          "destino": "Albania",
          "tipo": "estudio",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Carta de admisión \nsolvencia económica"
        },
        {
          "pasaporte": "Colombia",
          "origen": "Todos",
          "destino": "Australia",
          "tipo": "estudio",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Carta de aceptación \nsolvencia económica \nseguro médico \ncontancia de curso pagado en su totalidad \nexámenes médicos \nsuficiencia de ingles"
        },
        {
          "pasaporte": "Colombia",
          "origen": "Todos",
          "destino": "Emiratos Árabes Unidos",
          "tipo": "estudio",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Carta de admisión \nexámenes medicos \nsolvencia económica"
        },
        {
          "pasaporte": "Colombia",
          "origen": "Todos",
          "destino": "India",
          "tipo": "estudio",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Carta de admisión \nsolvencia económica"
        },
        {
          "pasaporte": "Colombia",
          "origen": "Todos",
          "destino": "Libia",
          "tipo": "estudio",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Carta de admisión \nsolvencia económica \nseguro médico"
        },
        {
          "pasaporte": "Colombia",
          "origen": "Todos",
          "destino": "Taiwán",
          "tipo": "estudio",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Carta de admisión \nsolvencia económica \nseguro médico"
        },
        {
          "pasaporte": "Colombia",
          "origen": "Todos",
          "destino": "Vietnam",
          "tipo": "estudio",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Carta de admisión y solvencia económica. Se puede tramitar en vietanm luego de entrar como turista."
        },
        {
          "pasaporte": "Colombia",
          "origen": "Todos",
          "destino": "Corea del Sur",
          "tipo": "estudio",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Carta de admisión \nsolvencia económica \nplan de estudio"
        },
        {
          "pasaporte": "Colombia",
          "origen": "Todos",
          "destino": "Argentina",
          "tipo": "estudio",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Carta de aceptación \nsolvencia económica \nacta de nacimiento \nantecedentes penales"
        },
        {
          "pasaporte": "Colombia",
          "origen": "Todos",
          "destino": "Bolivia",
          "tipo": "estudio",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Certificado de inscripción \ncertificados de estudios previos apostillados \nsolvencia económica \nvacuna fiebre amarilla"
        },
        {
          "pasaporte": "Colombia",
          "origen": "Todos",
          "destino": "Brasil",
          "tipo": "estudio",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Carta de admisión \nsolvencia económica \nacta de nacimientio \nantecedemtes penales"
        },
        {
          "pasaporte": "Colombia",
          "origen": "Todos",
          "destino": "Chile",
          "tipo": "estudio",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Certificado de matrícula \nsolvencia económica \ncertificado médico \nantecedentes penales"
        },
        {
          "pasaporte": "Colombia",
          "origen": "Todos",
          "destino": "China",
          "tipo": "estudio",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Carta de admisión \nsolvencia económica \nexámen físico"
        },
        {
          "pasaporte": "Colombia",
          "origen": "Todos",
          "destino": "Curacao",
          "tipo": "estudio",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Carta de admisión \nsolvencia económica"
        },
        {
          "pasaporte": "Colombia",
          "origen": "Todos",
          "destino": "El Salvador",
          "tipo": "estudio",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Carta de admisión \nsolvencia económica \nacta de nacimientio \nantecedemtes penales"
        },
        {
          "pasaporte": "Colombia",
          "origen": "Todos",
          "destino": "Federación Rusa",
          "tipo": "estudio",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Carta de admisión \nsolvencia económica \ncertificado médico"
        },
        {
          "pasaporte": "Colombia",
          "origen": "Todos",
          "destino": "Honduras",
          "tipo": "estudio",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Carta de admisión \nsolvencia económica \nacta de nacimientio \nantecedemtes penales \ncertificado médico"
        },
        {
          "pasaporte": "Colombia",
          "origen": "Todos",
          "destino": "Hong Kong",
          "tipo": "estudio",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Carta de admisión \nsolvencia económica \nseguro médico \nantecedentes penales \nseguro médico"
        },
        {
          "pasaporte": "Colombia",
          "origen": "Todos",
          "destino": "Israel",
          "tipo": "estudio",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Carta de admisión \nsolvencia económica"
        },
        {
          "pasaporte": "Colombia",
          "origen": "Todos",
          "destino": "Jamaica",
          "tipo": "estudio",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Carta de admisión \nsolvencia económica"
        },
        {
          "pasaporte": "Colombia",
          "origen": "Todos",
          "destino": "Nicaragua",
          "tipo": "estudio",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Carta de admisión \nsolvencia económica \ncertificado de nacimiento \nantecedentes penales \ncertificado de salud"
        },
        {
          "pasaporte": "Colombia",
          "origen": "Todos",
          "destino": "Panamá",
          "tipo": "estudio",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Carta de admisión \nsolvencia económica \ncertificado de salud \nantecedentes penales \npago de matrícula"
        },
        {
          "pasaporte": "Colombia",
          "origen": "Todos",
          "destino": "Paraguay",
          "tipo": "estudio",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Carta de admisión \nsolvencia económica"
        },
        {
          "pasaporte": "Colombia",
          "origen": "Todos",
          "destino": "Perú",
          "tipo": "estudio",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Carta de admisión \nsolvencia económica"
        },
        {
          "pasaporte": "Colombia",
          "origen": "Todos",
          "destino": "República Dominicana",
          "tipo": "estudio",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Carta de admisión \nsolvencia económica \ncertificado de nacimiento \nantecedentes penales \ncertificado de salud"
        },
        {
          "pasaporte": "Colombia",
          "origen": "Todos",
          "destino": "Singapur",
          "tipo": "estudio",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Carta de admisión \nsolvencia económica"
        },
        {
          "pasaporte": "Colombia",
          "origen": "Todos",
          "destino": "Sudáfrica",
          "tipo": "estudio",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Carta de admisión \nsolvencia económica \nantecedentes penales \ncertificado de salud \ncertificado de vacunación"
        },
        {
          "pasaporte": "Colombia",
          "origen": "Todos",
          "destino": "Tailandia",
          "tipo": "estudio",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Carta de inscripción \nsolvencia económica \nantecedentes penales"
        },
        {
          "pasaporte": "Colombia",
          "origen": "Todos",
          "destino": "Turquía",
          "tipo": "estudio",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Carta de admisión \nsolvencia económica \ncertificados de educación previa \nseguro médico \nprueba de alojamiento"
        },
        {
          "pasaporte": "Colombia",
          "origen": "Todos",
          "destino": "Uruguay",
          "tipo": "estudio",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Carta de admisión \nsolvencia económica \nantecedentes penales"
        },
        {
          "pasaporte": "Colombia",
          "origen": "Todos",
          "destino": "Venezuela",
          "tipo": "estudio",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Carta de admisión \ncertificado médico"
        },
        {
          "pasaporte": "Perú",
          "origen": "Todos",
          "destino": "Alemania",
          "tipo": "estudio",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Carta de aceptación \nsolvencia económica \nsuficiencia del idioma"
        },
        {
          "pasaporte": "Perú",
          "origen": "Todos",
          "destino": "Andorra",
          "tipo": "estudio",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Carta de aceptación \nsolvencia económica \ncertificado médico \nantecedentes penales \nseguro medico"
        },
        {
          "pasaporte": "Perú",
          "origen": "Todos",
          "destino": "Arabia Saudí",
          "tipo": "estudio",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Carta de aceptación y carta MOFA"
        },
        {
          "pasaporte": "Perú",
          "origen": "Todos",
          "destino": "Argelia",
          "tipo": "estudio",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Carta de aceptación \nsolvencia economica \njusitificación de estancia"
        },
        {
          "pasaporte": "Perú",
          "origen": "Todos",
          "destino": "Austria",
          "tipo": "estudio",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Carta de aceptación \nsolvencia económica \ncertificado médico \nantecedentes penales \nseguro medico"
        },
        {
          "pasaporte": "Perú",
          "origen": "Todos",
          "destino": "Bélgica",
          "tipo": "estudio",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Carta de aceptación \nsolvencia económica \ncertificado médico \nantecedentes penales \nseguro medico"
        },
        {
          "pasaporte": "Perú",
          "origen": "Todos",
          "destino": "Bulgaria",
          "tipo": "estudio",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Carta de aceptación \nsolvencia económica \ncertificado médico \nantecedentes penales \nseguro medico"
        },
        {
          "pasaporte": "Perú",
          "origen": "Todos",
          "destino": "Canadá",
          "tipo": "estudio",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Carta de aceptación \nsolvencia economica"
        },
        {
          "pasaporte": "Perú",
          "origen": "Todos",
          "destino": "Costa Rica",
          "tipo": "estudio",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Carta de aceptaación \nacta de nacimiento \nantecedentes penales \nsolvencia económica"
        },
        {
          "pasaporte": "Perú",
          "origen": "Todos",
          "destino": "Croacia",
          "tipo": "estudio",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Carta de aceptación \nsolvencia economica"
        },
        {
          "pasaporte": "Perú",
          "origen": "Todos",
          "destino": "Cuba",
          "tipo": "estudio",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Carta de aceptación \njustificación de estancia"
        },
        {
          "pasaporte": "Perú",
          "origen": "Todos",
          "destino": "Dinamarca",
          "tipo": "estudio",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Carta de aceptación \nsolvencia económica \ncertificado médico \nantecedentes penales \nseguro medico"
        },
        {
          "pasaporte": "Perú",
          "origen": "Todos",
          "destino": "Eslovaquia",
          "tipo": "estudio",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Carta de aceptación \nsolvencia económica \ncertificado médico \nantecedentes penales \nseguro medico"
        },
        {
          "pasaporte": "Perú",
          "origen": "Todos",
          "destino": "Eslovenia",
          "tipo": "estudio",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Carta de aceptación \nsolvencia económica \ncertificado médico \nantecedentes penales \nseguro medico"
        },
        {
          "pasaporte": "Perú",
          "origen": "Todos",
          "destino": "España",
          "tipo": "estudio",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Carta de aceptación \nsolvencia económica \ncertificado médico \nantecedentes penales \nseguro medico"
        },
        {
          "pasaporte": "Perú",
          "origen": "Todos",
          "destino": "Estados Unidos de América",
          "tipo": "estudio",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Carta de aceptación \nsolvencia economica. Los requisitos pueden variar segun tipo de visa (F-1 \nM-1 \nJ-1)"
        },
        {
          "pasaporte": "Perú",
          "origen": "Todos",
          "destino": "Estonia",
          "tipo": "estudio",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Carta de aceptación \nsolvencia económica \njusitifcación de estancia \nseguro medico \nacta de nacimiento"
        },
        {
          "pasaporte": "Perú",
          "origen": "Todos",
          "destino": "Finlandia",
          "tipo": "estudio",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Carta de aceptación \nsolvencia económica \ncertificado médico \nantecedentes penales \nseguro medico"
        },
        {
          "pasaporte": "Perú",
          "origen": "Todos",
          "destino": "Francia",
          "tipo": "estudio",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Carta de aceptación \nsolvencia económica \ncertificado médico \nantecedentes penales \nseguro medico"
        },
        {
          "pasaporte": "Perú",
          "origen": "Todos",
          "destino": "Grecia",
          "tipo": "estudio",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Carta de aceptación \nsolvencia económica \ncertificado médico \nantecedentes penales \nseguro medico"
        },
        {
          "pasaporte": "Perú",
          "origen": "Todos",
          "destino": "Guatemala",
          "tipo": "estudio",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Carta de aceptación \nsolvencia económica \nantecedentes penales \nmovimientos migratorios"
        },
        {
          "pasaporte": "Perú",
          "origen": "Todos",
          "destino": "Hungría",
          "tipo": "estudio",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Carta de aceptación \nsolvencia económica \ncertificado médico \nantecedentes penales \nseguro medico"
        },
        {
          "pasaporte": "Perú",
          "origen": "Todos",
          "destino": "Irlanda",
          "tipo": "estudio",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Carta de aceptación \nsolvencia económica \ncertificado médico \nantecedentes penales \nseguro medico"
        },
        {
          "pasaporte": "Perú",
          "origen": "Todos",
          "destino": "Islandia",
          "tipo": "estudio",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Carta de aceptación \nsolvencia económica \ncertificado médico \nantecedentes penales \nseguro medico"
        },
        {
          "pasaporte": "Perú",
          "origen": "Todos",
          "destino": "Italia",
          "tipo": "estudio",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Carta de aceptación \nsolvencia económica \ncertificado médico \nantecedentes penales \nseguro medico"
        },
        {
          "pasaporte": "Perú",
          "origen": "Todos",
          "destino": "Japón",
          "tipo": "estudio",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Certificado de elegibilidad COE \nCarta de admisión \ncarta motiva \nsolvencia economica \ncalificaciones de estudios previos"
        },
        {
          "pasaporte": "Perú",
          "origen": "Todos",
          "destino": "Letonia",
          "tipo": "estudio",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Carta de aceptación \nsolvencia económica \ncertificado médico \nantecedentes penales \nseguro medico"
        },
        {
          "pasaporte": "Perú",
          "origen": "Todos",
          "destino": "Líbano",
          "tipo": "estudio",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Perú",
          "origen": "Todos",
          "destino": "Liberia",
          "tipo": "estudio",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Perú",
          "origen": "Todos",
          "destino": "Liechtenstein",
          "tipo": "estudio",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Carta de aceptación \nsolvencia económica \ncertificado médico \nantecedentes penales \nseguro medico"
        },
        {
          "pasaporte": "Perú",
          "origen": "Todos",
          "destino": "Lituania",
          "tipo": "estudio",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Perú",
          "origen": "Todos",
          "destino": "Luxemburgo",
          "tipo": "estudio",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Carta de aceptación \nsolvencia económica \ncertificado médico \nantecedentes penales \nseguro medico"
        },
        {
          "pasaporte": "Perú",
          "origen": "Todos",
          "destino": "Malta",
          "tipo": "estudio",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Carta de aceptación \nsolvencia económica \ncertificado médico \nantecedentes penales \nseguro medico"
        },
        {
          "pasaporte": "Perú",
          "origen": "Todos",
          "destino": "Marruecos",
          "tipo": "estudio",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Perú",
          "origen": "Todos",
          "destino": "México",
          "tipo": "estudio",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Carta de aceptración \nsolvencia económica"
        },
        {
          "pasaporte": "Perú",
          "origen": "Todos",
          "destino": "Mónaco",
          "tipo": "estudio",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Carta de aceptación \nsolvencia económica \ncertificado médico \nantecedentes penales \nseguro medico"
        },
        {
          "pasaporte": "Perú",
          "origen": "Todos",
          "destino": "Montenegro",
          "tipo": "estudio",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Carta de aceptación \nsolvencia económica \nseguro médico \ncontancia de curso pagado en su totalidad \ndemostrar intención de retorno"
        },
        {
          "pasaporte": "Perú",
          "origen": "Todos",
          "destino": "Noruega",
          "tipo": "estudio",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Carta de aceptación \nsolvencia económica \ncertificado médico \nantecedentes penales \nseguro medico"
        },
        {
          "pasaporte": "Perú",
          "origen": "Todos",
          "destino": "Nueva Zelanda",
          "tipo": "estudio",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Carta de aceptación \nsolvencia económica \nseguro médico \ncontancia de curso pagado en su totalidad \nexámenes médicos"
        },
        {
          "pasaporte": "Perú",
          "origen": "Todos",
          "destino": "Países Bajos",
          "tipo": "estudio",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Carta de aceptación \nsolvencia económica \ncertificado médico \nantecedentes penales \nseguro medico"
        },
        {
          "pasaporte": "Perú",
          "origen": "Todos",
          "destino": "Polonia",
          "tipo": "estudio",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Carta de aceptación \nsolvencia económica \ncertificado médico \nantecedentes penales \nseguro medico"
        },
        {
          "pasaporte": "Perú",
          "origen": "Todos",
          "destino": "Portugal",
          "tipo": "estudio",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Carta de aceptación \nsolvencia económica \ncertificado médico \nantecedentes penales \nseguro medico"
        },
        {
          "pasaporte": "Perú",
          "origen": "Todos",
          "destino": "Reino Unido",
          "tipo": "estudio",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Carta de aceptación \nsolvencia económica \ncertificado médico \nantecedentes penales \nseguro medico"
        },
        {
          "pasaporte": "Perú",
          "origen": "Todos",
          "destino": "República Checa",
          "tipo": "estudio",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Carta de aceptación \nsolvencia económica \ncertificado médico \nantecedentes penales \nseguro medico"
        },
        {
          "pasaporte": "Perú",
          "origen": "Todos",
          "destino": "Rumanía",
          "tipo": "estudio",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Carta de aceptación \nsolvencia económica \ncertificado médico \nantecedentes penales \nseguro medico"
        },
        {
          "pasaporte": "Perú",
          "origen": "Todos",
          "destino": "Serbia",
          "tipo": "estudio",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Carta de aceptación \nsolvencia económica \ncertificados de estudios previos"
        },
        {
          "pasaporte": "Perú",
          "origen": "Todos",
          "destino": "Suecia",
          "tipo": "estudio",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Carta de aceptación \nsolvencia económica \ncertificado médico \nantecedentes penales \nseguro medico"
        },
        {
          "pasaporte": "Perú",
          "origen": "Todos",
          "destino": "Suiza",
          "tipo": "estudio",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Carta de aceptación \nsolvencia económica \ncertificado médico \nantecedentes penales \nseguro medico"
        },
        {
          "pasaporte": "Perú",
          "origen": "Todos",
          "destino": "Túnez",
          "tipo": "estudio",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Carta de admisión \nsolvencia económica"
        },
        {
          "pasaporte": "Perú",
          "origen": "Todos",
          "destino": "Albania",
          "tipo": "estudio",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Carta de admisión \nsolvencia económica"
        },
        {
          "pasaporte": "Perú",
          "origen": "Todos",
          "destino": "Australia",
          "tipo": "estudio",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Carta de aceptación \nsolvencia económica \nseguro médico \ncontancia de curso pagado en su totalidad \nexámenes médicos \nsuficiencia de ingles"
        },
        {
          "pasaporte": "Perú",
          "origen": "Todos",
          "destino": "Emiratos Árabes Unidos",
          "tipo": "estudio",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Carta de admisión \nexámenes medicos \nsolvencia económica"
        },
        {
          "pasaporte": "Perú",
          "origen": "Todos",
          "destino": "India",
          "tipo": "estudio",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Carta de admisión \nsolvencia económica"
        },
        {
          "pasaporte": "Perú",
          "origen": "Todos",
          "destino": "Libia",
          "tipo": "estudio",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Carta de admisión \nsolvencia económica \nseguro médico"
        },
        {
          "pasaporte": "Perú",
          "origen": "Todos",
          "destino": "Taiwán",
          "tipo": "estudio",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Carta de admisión \nsolvencia económica \nseguro médico"
        },
        {
          "pasaporte": "Perú",
          "origen": "Todos",
          "destino": "Vietnam",
          "tipo": "estudio",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Carta de admisión y solvencia económica. Se puede tramitar en vietanm luego de entrar como turista."
        },
        {
          "pasaporte": "Perú",
          "origen": "Todos",
          "destino": "Corea del Sur",
          "tipo": "estudio",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Carta de admisión \nsolvencia económica \nplan de estudio"
        },
        {
          "pasaporte": "Perú",
          "origen": "Todos",
          "destino": "Argentina",
          "tipo": "estudio",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Carta de aceptación \nsolvencia económica \nacta de nacimiento \nantecedentes penales"
        },
        {
          "pasaporte": "Perú",
          "origen": "Todos",
          "destino": "Bolivia",
          "tipo": "estudio",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Certificado de inscripción \ncertificados de estudios previos apostillados \nsolvencia económica \nvacuna fiebre amarilla"
        },
        {
          "pasaporte": "Perú",
          "origen": "Todos",
          "destino": "Brasil",
          "tipo": "estudio",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Carta de admisión \nsolvencia económica \nacta de nacimientio \nantecedemtes penales"
        },
        {
          "pasaporte": "Perú",
          "origen": "Todos",
          "destino": "Chile",
          "tipo": "estudio",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Certificado de matrícula \nsolvencia económica \ncertificado médico \nantecedentes penales"
        },
        {
          "pasaporte": "Perú",
          "origen": "Todos",
          "destino": "China",
          "tipo": "estudio",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Carta de admisión \nsolvencia económica \nexámen físico"
        },
        {
          "pasaporte": "Perú",
          "origen": "Todos",
          "destino": "Colombia",
          "tipo": "estudio",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Carta de admisión \ncarta motiva \nsolvencia económica \nseguro médico"
        },
        {
          "pasaporte": "Perú",
          "origen": "Todos",
          "destino": "Curacao",
          "tipo": "estudio",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Carta de admisión \nsolvencia económica"
        },
        {
          "pasaporte": "Perú",
          "origen": "Todos",
          "destino": "El Salvador",
          "tipo": "estudio",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Carta de admisión \nsolvencia económica \nacta de nacimientio \nantecedemtes penales"
        },
        {
          "pasaporte": "Perú",
          "origen": "Todos",
          "destino": "Federación Rusa",
          "tipo": "estudio",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Carta de admisión \nsolvencia económica \ncertificado médico"
        },
        {
          "pasaporte": "Perú",
          "origen": "Todos",
          "destino": "Honduras",
          "tipo": "estudio",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Carta de admisión \nsolvencia económica \nacta de nacimientio \nantecedemtes penales \ncertificado médico"
        },
        {
          "pasaporte": "Perú",
          "origen": "Todos",
          "destino": "Hong Kong",
          "tipo": "estudio",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Carta de admisión \nsolvencia económica \nseguro médico \nantecedentes penales \nseguro médico"
        },
        {
          "pasaporte": "Perú",
          "origen": "Todos",
          "destino": "Israel",
          "tipo": "estudio",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Carta de admisión \nsolvencia económica"
        },
        {
          "pasaporte": "Perú",
          "origen": "Todos",
          "destino": "Jamaica",
          "tipo": "estudio",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Carta de admisión \nsolvencia económica"
        },
        {
          "pasaporte": "Perú",
          "origen": "Todos",
          "destino": "Nicaragua",
          "tipo": "estudio",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Carta de admisión \nsolvencia económica \ncertificado de nacimiento \nantecedentes penales \ncertificado de salud"
        },
        {
          "pasaporte": "Perú",
          "origen": "Todos",
          "destino": "Panamá",
          "tipo": "estudio",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Carta de admisión \nsolvencia económica \ncertificado de salud \nantecedentes penales \npago de matrícula"
        },
        {
          "pasaporte": "Perú",
          "origen": "Todos",
          "destino": "Paraguay",
          "tipo": "estudio",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Carta de admisión \nsolvencia económica"
        },
        {
          "pasaporte": "Perú",
          "origen": "Todos",
          "destino": "República Dominicana",
          "tipo": "estudio",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Carta de admisión \nsolvencia económica \ncertificado de nacimiento \nantecedentes penales \ncertificado de salud"
        },
        {
          "pasaporte": "Perú",
          "origen": "Todos",
          "destino": "Singapur",
          "tipo": "estudio",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Carta de admisión \nsolvencia económica"
        },
        {
          "pasaporte": "Perú",
          "origen": "Todos",
          "destino": "Sudáfrica",
          "tipo": "estudio",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Carta de admisión \nsolvencia económica \nantecedentes penales \ncertificado de salud \ncertificado de vacunación"
        },
        {
          "pasaporte": "Perú",
          "origen": "Todos",
          "destino": "Tailandia",
          "tipo": "estudio",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Carta de inscripción \nsolvencia económica \nantecedentes penales"
        },
        {
          "pasaporte": "Perú",
          "origen": "Todos",
          "destino": "Turquía",
          "tipo": "estudio",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Carta de admisión \nsolvencia económica \ncertificados de educación previa \nseguro médico \nprueba de alojamiento"
        },
        {
          "pasaporte": "Perú",
          "origen": "Todos",
          "destino": "Uruguay",
          "tipo": "estudio",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Carta de admisión \nsolvencia económica \nantecedentes penales"
        },
        {
          "pasaporte": "Perú",
          "origen": "Todos",
          "destino": "Venezuela",
          "tipo": "estudio",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Carta de admisión \ncertificado médico"
        },
        {
          "pasaporte": "Venezuela",
          "origen": "Todos",
          "destino": "Alemania",
          "tipo": "estudio",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Carta de aceptación \nsolvencia económica \nsuficiencia del idioma"
        },
        {
          "pasaporte": "Venezuela",
          "origen": "Todos",
          "destino": "Andorra",
          "tipo": "estudio",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Carta de acerptación \nsolvencia económica \ncertificado médico \nantecedentes penales \nseguro medico"
        },
        {
          "pasaporte": "Venezuela",
          "origen": "Todos",
          "destino": "Arabia Saudí",
          "tipo": "estudio",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Carta de aceptación y carta MOFA"
        },
        {
          "pasaporte": "Venezuela",
          "origen": "Todos",
          "destino": "Argelia",
          "tipo": "estudio",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Carta de aceptación \nsolvencia economica \njusitificación de estancia"
        },
        {
          "pasaporte": "Venezuela",
          "origen": "Todos",
          "destino": "Austria",
          "tipo": "estudio",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Carta de aceptación \nsolvencia económica \ncertificado médico \nantecedentes penales \nseguro medico"
        },
        {
          "pasaporte": "Venezuela",
          "origen": "Todos",
          "destino": "Bélgica",
          "tipo": "estudio",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Carta de aceptación \nsolvencia económica \ncertificado médico \nantecedentes penales \nseguro medico"
        },
        {
          "pasaporte": "Venezuela",
          "origen": "Todos",
          "destino": "Bulgaria",
          "tipo": "estudio",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Carta de aceptación \nsolvencia económica \ncertificado médico \nantecedentes penales \nseguro medico"
        },
        {
          "pasaporte": "Venezuela",
          "origen": "Todos",
          "destino": "Canadá",
          "tipo": "estudio",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Carta de aceptación \nsolvencia economica"
        },
        {
          "pasaporte": "Venezuela",
          "origen": "Todos",
          "destino": "Costa Rica",
          "tipo": "estudio",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Carta de aceptaación \nacta de nacimiento \nantecedentes penales \nsolvencia económica"
        },
        {
          "pasaporte": "Venezuela",
          "origen": "Todos",
          "destino": "Croacia",
          "tipo": "estudio",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Carta de aceptación \nsolvencia economica"
        },
        {
          "pasaporte": "Venezuela",
          "origen": "Todos",
          "destino": "Cuba",
          "tipo": "estudio",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Carta de aceptación \njustificación de estancia"
        },
        {
          "pasaporte": "Venezuela",
          "origen": "Todos",
          "destino": "Dinamarca",
          "tipo": "estudio",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Carta de aceptación \nsolvencia económica \ncertificado médico \nantecedentes penales \nseguro medico"
        },
        {
          "pasaporte": "Venezuela",
          "origen": "Todos",
          "destino": "Eslovaquia",
          "tipo": "estudio",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Carta de aceptación \nsolvencia económica \ncertificado médico \nantecedentes penales \nseguro medico"
        },
        {
          "pasaporte": "Venezuela",
          "origen": "Todos",
          "destino": "Eslovenia",
          "tipo": "estudio",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Carta de aceptación \nsolvencia económica \ncertificado médico \nantecedentes penales \nseguro medico"
        },
        {
          "pasaporte": "Venezuela",
          "origen": "Todos",
          "destino": "España",
          "tipo": "estudio",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Carta de aceptación \nsolvencia económica \ncertificado médico \nantecedentes penales \nseguro medico"
        },
        {
          "pasaporte": "Venezuela",
          "origen": "Todos",
          "destino": "Estados Unidos de América",
          "tipo": "estudio",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Carta de aceptación \nsolvencia economica. Los requisitos pueden variar segun tipo de visa (F-1 \nM-1 \nJ-1)"
        },
        {
          "pasaporte": "Venezuela",
          "origen": "Todos",
          "destino": "Estonia",
          "tipo": "estudio",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Carta de aceptación \nsolvencia económica \njusitifcación de estancia \nseguro medico \nacta de nacimiento"
        },
        {
          "pasaporte": "Venezuela",
          "origen": "Todos",
          "destino": "Finlandia",
          "tipo": "estudio",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Carta de aceptación \nsolvencia económica \ncertificado médico \nantecedentes penales \nseguro medico"
        },
        {
          "pasaporte": "Venezuela",
          "origen": "Todos",
          "destino": "Francia",
          "tipo": "estudio",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Carta de aceptación \nsolvencia económica \ncertificado médico \nantecedentes penales \nseguro medico"
        },
        {
          "pasaporte": "Venezuela",
          "origen": "Todos",
          "destino": "Grecia",
          "tipo": "estudio",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Carta de aceptación \nsolvencia económica \ncertificado médico \nantecedentes penales \nseguro medico"
        },
        {
          "pasaporte": "Venezuela",
          "origen": "Todos",
          "destino": "Guatemala",
          "tipo": "estudio",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Carta de aceptación \nsolvencia económica \nantecedentes penales \nmovimientos migratorios"
        },
        {
          "pasaporte": "Venezuela",
          "origen": "Todos",
          "destino": "Hungría",
          "tipo": "estudio",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Carta de aceptación \nsolvencia económica \ncertificado médico \nantecedentes penales \nseguro medico"
        },
        {
          "pasaporte": "Venezuela",
          "origen": "Todos",
          "destino": "Irlanda",
          "tipo": "estudio",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Carta de aceptación \nsolvencia económica \ncertificado médico \nantecedentes penales \nseguro medico"
        },
        {
          "pasaporte": "Venezuela",
          "origen": "Todos",
          "destino": "Islandia",
          "tipo": "estudio",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Carta de aceptación \nsolvencia económica \ncertificado médico \nantecedentes penales \nseguro medico"
        },
        {
          "pasaporte": "Venezuela",
          "origen": "Todos",
          "destino": "Italia",
          "tipo": "estudio",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Carta de aceptación \nsolvencia económica \ncertificado médico \nantecedentes penales \nseguro medico"
        },
        {
          "pasaporte": "Venezuela",
          "origen": "Todos",
          "destino": "Japón",
          "tipo": "estudio",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Certificado de elegibilidad COE \nCarta de admisión \ncarta motiva \nsolvencia economica \ncalificaciones de estudios previos"
        },
        {
          "pasaporte": "Venezuela",
          "origen": "Todos",
          "destino": "Letonia",
          "tipo": "estudio",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Carta de aceptación \nsolvencia económica \ncertificado médico \nantecedentes penales \nseguro medico"
        },
        {
          "pasaporte": "Venezuela",
          "origen": "Todos",
          "destino": "Líbano",
          "tipo": "estudio",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Venezuela",
          "origen": "Todos",
          "destino": "Liberia",
          "tipo": "estudio",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Venezuela",
          "origen": "Todos",
          "destino": "Liechtenstein",
          "tipo": "estudio",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Carta de aceptación \nsolvencia económica \ncertificado médico \nantecedentes penales \nseguro medico"
        },
        {
          "pasaporte": "Venezuela",
          "origen": "Todos",
          "destino": "Lituania",
          "tipo": "estudio",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Venezuela",
          "origen": "Todos",
          "destino": "Luxemburgo",
          "tipo": "estudio",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Carta de aceptación \nsolvencia económica \ncertificado médico \nantecedentes penales \nseguro medico"
        },
        {
          "pasaporte": "Venezuela",
          "origen": "Todos",
          "destino": "Malta",
          "tipo": "estudio",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Carta de aceptación \nsolvencia económica \ncertificado médico \nantecedentes penales \nseguro medico"
        },
        {
          "pasaporte": "Venezuela",
          "origen": "Todos",
          "destino": "Marruecos",
          "tipo": "estudio",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Venezuela",
          "origen": "Todos",
          "destino": "México",
          "tipo": "estudio",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Carta de aceptración \nsolvencia económica"
        },
        {
          "pasaporte": "Venezuela",
          "origen": "Todos",
          "destino": "Mónaco",
          "tipo": "estudio",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Carta de aceptación \nsolvencia económica \ncertificado médico \nantecedentes penales \nseguro medico"
        },
        {
          "pasaporte": "Venezuela",
          "origen": "Todos",
          "destino": "Montenegro",
          "tipo": "estudio",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Carta de aceptación \nsolvencia económica \nseguro médico \ncontancia de curso pagado en su totalidad \ndemostrar intención de retorno"
        },
        {
          "pasaporte": "Venezuela",
          "origen": "Todos",
          "destino": "Noruega",
          "tipo": "estudio",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Carta de aceptación \nsolvencia económica \ncertificado médico \nantecedentes penales \nseguro medico"
        },
        {
          "pasaporte": "Venezuela",
          "origen": "Todos",
          "destino": "Nueva Zelanda",
          "tipo": "estudio",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Carta de aceptación \nsolvencia económica \nseguro médico \ncontancia de curso pagado en su totalidad \nexámenes médicos"
        },
        {
          "pasaporte": "Venezuela",
          "origen": "Todos",
          "destino": "Países Bajos",
          "tipo": "estudio",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Carta de aceptación \nsolvencia económica \ncertificado médico \nantecedentes penales \nseguro medico"
        },
        {
          "pasaporte": "Venezuela",
          "origen": "Todos",
          "destino": "Polonia",
          "tipo": "estudio",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Carta de aceptación \nsolvencia económica \ncertificado médico \nantecedentes penales \nseguro medico"
        },
        {
          "pasaporte": "Venezuela",
          "origen": "Todos",
          "destino": "Portugal",
          "tipo": "estudio",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Carta de aceptación \nsolvencia económica \ncertificado médico \nantecedentes penales \nseguro medico"
        },
        {
          "pasaporte": "Venezuela",
          "origen": "Todos",
          "destino": "Reino Unido",
          "tipo": "estudio",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Carta de aceptación \nsolvencia económica \ncertificado médico \nantecedentes penales \nseguro medico"
        },
        {
          "pasaporte": "Venezuela",
          "origen": "Todos",
          "destino": "República Checa",
          "tipo": "estudio",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Carta de aceptación \nsolvencia económica \ncertificado médico \nantecedentes penales \nseguro medico"
        },
        {
          "pasaporte": "Venezuela",
          "origen": "Todos",
          "destino": "Rumanía",
          "tipo": "estudio",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Carta de aceptación \nsolvencia económica \ncertificado médico \nantecedentes penales \nseguro medico"
        },
        {
          "pasaporte": "Venezuela",
          "origen": "Todos",
          "destino": "Serbia",
          "tipo": "estudio",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Carta de aceptación \nsolvencia económica \ncertificados de estudios previos"
        },
        {
          "pasaporte": "Venezuela",
          "origen": "Todos",
          "destino": "Suecia",
          "tipo": "estudio",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Carta de aceptación \nsolvencia económica \ncertificado médico \nantecedentes penales \nseguro medico"
        },
        {
          "pasaporte": "Venezuela",
          "origen": "Todos",
          "destino": "Suiza",
          "tipo": "estudio",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Carta de aceptación \nsolvencia económica \ncertificado médico \nantecedentes penales \nseguro medico"
        },
        {
          "pasaporte": "Venezuela",
          "origen": "Todos",
          "destino": "Túnez",
          "tipo": "estudio",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Carta de admisión \nsolvencia económica"
        },
        {
          "pasaporte": "Venezuela",
          "origen": "Todos",
          "destino": "Albania",
          "tipo": "estudio",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Carta de admisión \nsolvencia económica"
        },
        {
          "pasaporte": "Venezuela",
          "origen": "Todos",
          "destino": "Australia",
          "tipo": "estudio",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Carta de aceptación \nsolvencia económica \nseguro médico \ncontancia de curso pagado en su totalidad \nexámenes médicos \nsuficiencia de ingles"
        },
        {
          "pasaporte": "Venezuela",
          "origen": "Todos",
          "destino": "Emiratos Árabes Unidos",
          "tipo": "estudio",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Carta de admisión \nexámenes medicos \nsolvencia económica"
        },
        {
          "pasaporte": "Venezuela",
          "origen": "Todos",
          "destino": "India",
          "tipo": "estudio",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Carta de admisión \nsolvencia económica"
        },
        {
          "pasaporte": "Venezuela",
          "origen": "Todos",
          "destino": "Libia",
          "tipo": "estudio",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Carta de admisión \nsolvencia económica \nseguro médico"
        },
        {
          "pasaporte": "Venezuela",
          "origen": "Todos",
          "destino": "Taiwán",
          "tipo": "estudio",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Carta de admisión \nsolvencia económica \nseguro médico"
        },
        {
          "pasaporte": "Venezuela",
          "origen": "Todos",
          "destino": "Vietnam",
          "tipo": "estudio",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Carta de admisión y solvencia económica. Se puede tramitar en vietanm luego de entrar como turista."
        },
        {
          "pasaporte": "Venezuela",
          "origen": "Todos",
          "destino": "Corea del Sur",
          "tipo": "estudio",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Carta de admisión \nsolvencia económica \nplan de estudio"
        },
        {
          "pasaporte": "Venezuela",
          "origen": "Todos",
          "destino": "Argentina",
          "tipo": "estudio",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Carta de aceptación \nsolvencia económica \nacta de nacimiento \nantecedentes penales"
        },
        {
          "pasaporte": "Venezuela",
          "origen": "Todos",
          "destino": "Bolivia",
          "tipo": "estudio",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Certificado de inscripción \ncertificados de estudios previos apostillados \nsolvencia económica \nvacuna fiebre amarilla"
        },
        {
          "pasaporte": "Venezuela",
          "origen": "Todos",
          "destino": "Brasil",
          "tipo": "estudio",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Carta de admisión \nsolvencia económica \nacta de nacimientio \nantecedemtes penales"
        },
        {
          "pasaporte": "Venezuela",
          "origen": "Todos",
          "destino": "Chile",
          "tipo": "estudio",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Certificado de matrícula \nsolvencia económica \ncertificado médico \nantecedentes penales"
        },
        {
          "pasaporte": "Venezuela",
          "origen": "Todos",
          "destino": "China",
          "tipo": "estudio",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Carta de admisión \nsolvencia económica \nexámen físico"
        },
        {
          "pasaporte": "Venezuela",
          "origen": "Todos",
          "destino": "Colombia",
          "tipo": "estudio",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Carta de admisión \ncarta motiva \nsolvencia económica \nseguro médico"
        },
        {
          "pasaporte": "Venezuela",
          "origen": "Todos",
          "destino": "Curacao",
          "tipo": "estudio",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Carta de admisión \nsolvencia económica"
        },
        {
          "pasaporte": "Venezuela",
          "origen": "Todos",
          "destino": "El Salvador",
          "tipo": "estudio",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Carta de admisión \nsolvencia económica \nacta de nacimientio \nantecedemtes penales"
        },
        {
          "pasaporte": "Venezuela",
          "origen": "Todos",
          "destino": "Federación Rusa",
          "tipo": "estudio",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Carta de admisión \nsolvencia económica \ncertificado médico"
        },
        {
          "pasaporte": "Venezuela",
          "origen": "Todos",
          "destino": "Honduras",
          "tipo": "estudio",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Carta de admisión \nsolvencia económica \nacta de nacimientio \nantecedemtes penales \ncertificado médico"
        },
        {
          "pasaporte": "Venezuela",
          "origen": "Todos",
          "destino": "Hong Kong",
          "tipo": "estudio",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Carta de admisión \nsolvencia económica \nseguro médico \nantecedentes penales \nseguro médico"
        },
        {
          "pasaporte": "Venezuela",
          "origen": "Todos",
          "destino": "Israel",
          "tipo": "estudio",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Carta de admisión \nsolvencia económica"
        },
        {
          "pasaporte": "Venezuela",
          "origen": "Todos",
          "destino": "Jamaica",
          "tipo": "estudio",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Carta de admisión \nsolvencia económica"
        },
        {
          "pasaporte": "Venezuela",
          "origen": "Todos",
          "destino": "Nicaragua",
          "tipo": "estudio",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Carta de admisión \nsolvencia económica \ncertificado de nacimiento \nantecedentes penales \ncertificado de salud"
        },
        {
          "pasaporte": "Venezuela",
          "origen": "Todos",
          "destino": "Panamá",
          "tipo": "estudio",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Carta de admisión \nsolvencia económica \ncertificado de salud \nantecedentes penales \npago de matrícula"
        },
        {
          "pasaporte": "Venezuela",
          "origen": "Todos",
          "destino": "Paraguay",
          "tipo": "estudio",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Carta de admisión \nsolvencia económica"
        },
        {
          "pasaporte": "Venezuela",
          "origen": "Todos",
          "destino": "Perú",
          "tipo": "estudio",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Carta de admisión \nsolvencia económica"
        },
        {
          "pasaporte": "Venezuela",
          "origen": "Todos",
          "destino": "República Dominicana",
          "tipo": "estudio",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Carta de admisión \nsolvencia económica \ncertificado de nacimiento \nantecedentes penales \ncertificado de salud"
        },
        {
          "pasaporte": "Venezuela",
          "origen": "Todos",
          "destino": "Singapur",
          "tipo": "estudio",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Carta de admisión \nsolvencia económica"
        },
        {
          "pasaporte": "Venezuela",
          "origen": "Todos",
          "destino": "Sudáfrica",
          "tipo": "estudio",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Carta de admisión \nsolvencia económica \nantecedentes penales \ncertificado de salud \ncertificado de vacunación"
        },
        {
          "pasaporte": "Venezuela",
          "origen": "Todos",
          "destino": "Tailandia",
          "tipo": "estudio",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Carta de inscripción \nsolvencia económica \nantecedentes penales"
        },
        {
          "pasaporte": "Venezuela",
          "origen": "Todos",
          "destino": "Turquía",
          "tipo": "estudio",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Carta de admisión \nsolvencia económica \ncertificados de educación previa \nseguro médico \nprueba de alojamiento"
        },
        {
          "pasaporte": "Venezuela",
          "origen": "Todos",
          "destino": "Uruguay",
          "tipo": "estudio",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Carta de admisión \nsolvencia económica \nantecedentes penales"
        },
        {
          "pasaporte": "El Salvador",
          "origen": "Todos",
          "destino": "Alemania",
          "tipo": "estudio",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Carta de aceptación \nsolvencia económica \nsuficiencia del idioma"
        },
        {
          "pasaporte": "El Salvador",
          "origen": "Todos",
          "destino": "Andorra",
          "tipo": "estudio",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Carta de aceptación \nsolvencia económica \ncertificado médico \nantecedentes penales \nseguro medico"
        },
        {
          "pasaporte": "El Salvador",
          "origen": "Todos",
          "destino": "Arabia Saudí",
          "tipo": "estudio",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Carta de aceptación y carta MOFA"
        },
        {
          "pasaporte": "El Salvador",
          "origen": "Todos",
          "destino": "Argelia",
          "tipo": "estudio",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Carta de aceptación \nsolvencia economica \njusitificación de estancia"
        },
        {
          "pasaporte": "El Salvador",
          "origen": "Todos",
          "destino": "Austria",
          "tipo": "estudio",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Carta de aceptación \nsolvencia económica \ncertificado médico \nantecedentes penales \nseguro medico"
        },
        {
          "pasaporte": "El Salvador",
          "origen": "Todos",
          "destino": "Bélgica",
          "tipo": "estudio",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Carta de aceptación \nsolvencia económica \ncertificado médico \nantecedentes penales \nseguro medico"
        },
        {
          "pasaporte": "El Salvador",
          "origen": "Todos",
          "destino": "Bulgaria",
          "tipo": "estudio",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Carta de aceptación \nsolvencia económica \ncertificado médico \nantecedentes penales \nseguro medico"
        },
        {
          "pasaporte": "El Salvador",
          "origen": "Todos",
          "destino": "Canadá",
          "tipo": "estudio",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Carta de aceptación \nsolvencia economica"
        },
        {
          "pasaporte": "El Salvador",
          "origen": "Todos",
          "destino": "Costa Rica",
          "tipo": "estudio",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Carta de aceptaación \nacta de nacimiento \nantecedentes penales \nsolvencia económica"
        },
        {
          "pasaporte": "El Salvador",
          "origen": "Todos",
          "destino": "Croacia",
          "tipo": "estudio",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Carta de aceptación \nsolvencia economica"
        },
        {
          "pasaporte": "El Salvador",
          "origen": "Todos",
          "destino": "Cuba",
          "tipo": "estudio",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Carta de aceptación \njustificación de estancia"
        },
        {
          "pasaporte": "El Salvador",
          "origen": "Todos",
          "destino": "Dinamarca",
          "tipo": "estudio",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Carta de aceptación \nsolvencia económica \ncertificado médico \nantecedentes penales \nseguro medico"
        },
        {
          "pasaporte": "El Salvador",
          "origen": "Todos",
          "destino": "Eslovaquia",
          "tipo": "estudio",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Carta de aceptación \nsolvencia económica \ncertificado médico \nantecedentes penales \nseguro medico"
        },
        {
          "pasaporte": "El Salvador",
          "origen": "Todos",
          "destino": "Eslovenia",
          "tipo": "estudio",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Carta de aceptación \nsolvencia económica \ncertificado médico \nantecedentes penales \nseguro medico"
        },
        {
          "pasaporte": "El Salvador",
          "origen": "Todos",
          "destino": "España",
          "tipo": "estudio",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Carta de aceptación \nsolvencia económica \ncertificado médico \nantecedentes penales \nseguro medico"
        },
        {
          "pasaporte": "El Salvador",
          "origen": "Todos",
          "destino": "Estados Unidos de América",
          "tipo": "estudio",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Carta de aceptación \nsolvencia economica. Los requisitos pueden variar segun tipo de visa (F-1 \nM-1 \nJ-1)"
        },
        {
          "pasaporte": "El Salvador",
          "origen": "Todos",
          "destino": "Estonia",
          "tipo": "estudio",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Carta de aceptación \nsolvencia económica \njusitifcación de estancia \nseguro medico \nacta de nacimiento"
        },
        {
          "pasaporte": "El Salvador",
          "origen": "Todos",
          "destino": "Finlandia",
          "tipo": "estudio",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Carta de aceptación \nsolvencia económica \ncertificado médico \nantecedentes penales \nseguro medico"
        },
        {
          "pasaporte": "El Salvador",
          "origen": "Todos",
          "destino": "Francia",
          "tipo": "estudio",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Carta de aceptación \nsolvencia económica \ncertificado médico \nantecedentes penales \nseguro medico"
        },
        {
          "pasaporte": "El Salvador",
          "origen": "Todos",
          "destino": "Grecia",
          "tipo": "estudio",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Carta de aceptación \nsolvencia económica \ncertificado médico \nantecedentes penales \nseguro medico"
        },
        {
          "pasaporte": "El Salvador",
          "origen": "Todos",
          "destino": "Guatemala",
          "tipo": "estudio",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Carta de aceptación \nsolvencia económica \nantecedentes penales \nmovimientos migratorios"
        },
        {
          "pasaporte": "El Salvador",
          "origen": "Todos",
          "destino": "Hungría",
          "tipo": "estudio",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Carta de aceptación \nsolvencia económica \ncertificado médico \nantecedentes penales \nseguro medico"
        },
        {
          "pasaporte": "El Salvador",
          "origen": "Todos",
          "destino": "Irlanda",
          "tipo": "estudio",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Carta de aceptación \nsolvencia económica \ncertificado médico \nantecedentes penales \nseguro medico"
        },
        {
          "pasaporte": "El Salvador",
          "origen": "Todos",
          "destino": "Islandia",
          "tipo": "estudio",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Carta de aceptación \nsolvencia económica \ncertificado médico \nantecedentes penales \nseguro medico"
        },
        {
          "pasaporte": "El Salvador",
          "origen": "Todos",
          "destino": "Italia",
          "tipo": "estudio",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Carta de aceptación \nsolvencia económica \ncertificado médico \nantecedentes penales \nseguro medico"
        },
        {
          "pasaporte": "El Salvador",
          "origen": "Todos",
          "destino": "Japón",
          "tipo": "estudio",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Certificado de elegibilidad COE \nCarta de admisión \ncarta motiva \nsolvencia economica \ncalificaciones de estudios previos"
        },
        {
          "pasaporte": "El Salvador",
          "origen": "Todos",
          "destino": "Letonia",
          "tipo": "estudio",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "El Salvador",
          "origen": "Todos",
          "destino": "Líbano",
          "tipo": "estudio",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "El Salvador",
          "origen": "Todos",
          "destino": "Liberia",
          "tipo": "estudio",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "El Salvador",
          "origen": "Todos",
          "destino": "Liechtenstein",
          "tipo": "estudio",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "El Salvador",
          "origen": "Todos",
          "destino": "Lituania",
          "tipo": "estudio",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "El Salvador",
          "origen": "Todos",
          "destino": "Luxemburgo",
          "tipo": "estudio",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "El Salvador",
          "origen": "Todos",
          "destino": "Malta",
          "tipo": "estudio",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "El Salvador",
          "origen": "Todos",
          "destino": "Marruecos",
          "tipo": "estudio",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "El Salvador",
          "origen": "Todos",
          "destino": "México",
          "tipo": "estudio",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Carta de aceptración \nsolvencia económica"
        },
        {
          "pasaporte": "El Salvador",
          "origen": "Todos",
          "destino": "Mónaco",
          "tipo": "estudio",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Carta de aceptación \nsolvencia económica \ncertificado médico \nantecedentes penales \nseguro medico"
        },
        {
          "pasaporte": "El Salvador",
          "origen": "Todos",
          "destino": "Montenegro",
          "tipo": "estudio",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Carta de aceptación \nsolvencia económica \nseguro médico \ncontancia de curso pagado en su totalidad \ndemostrar intención de retorno"
        },
        {
          "pasaporte": "El Salvador",
          "origen": "Todos",
          "destino": "Noruega",
          "tipo": "estudio",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Carta de aceptación \nsolvencia económica \ncertificado médico \nantecedentes penales \nseguro medico"
        },
        {
          "pasaporte": "El Salvador",
          "origen": "Todos",
          "destino": "Nueva Zelanda",
          "tipo": "estudio",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Carta de aceptación \nsolvencia económica \nseguro médico \ncontancia de curso pagado en su totalidad \nexámenes médicos"
        },
        {
          "pasaporte": "El Salvador",
          "origen": "Todos",
          "destino": "Países Bajos",
          "tipo": "estudio",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Carta de aceptación \nsolvencia económica \ncertificado médico \nantecedentes penales \nseguro medico"
        },
        {
          "pasaporte": "El Salvador",
          "origen": "Todos",
          "destino": "Polonia",
          "tipo": "estudio",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Carta de aceptación \nsolvencia económica \ncertificado médico \nantecedentes penales \nseguro medico"
        },
        {
          "pasaporte": "El Salvador",
          "origen": "Todos",
          "destino": "Portugal",
          "tipo": "estudio",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Carta de aceptación \nsolvencia económica \ncertificado médico \nantecedentes penales \nseguro medico"
        },
        {
          "pasaporte": "El Salvador",
          "origen": "Todos",
          "destino": "Reino Unido",
          "tipo": "estudio",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Carta de aceptación \nsolvencia económica \ncertificado médico \nantecedentes penales \nseguro medico"
        },
        {
          "pasaporte": "El Salvador",
          "origen": "Todos",
          "destino": "República Checa",
          "tipo": "estudio",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Carta de aceptación \nsolvencia económica \ncertificado médico \nantecedentes penales \nseguro medico"
        },
        {
          "pasaporte": "El Salvador",
          "origen": "Todos",
          "destino": "Rumanía",
          "tipo": "estudio",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Carta de aceptación \nsolvencia económica \ncertificado médico \nantecedentes penales \nseguro medico"
        },
        {
          "pasaporte": "El Salvador",
          "origen": "Todos",
          "destino": "Serbia",
          "tipo": "estudio",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Carta de aceptación \nsolvencia económica \ncertificados de estudios previos"
        },
        {
          "pasaporte": "El Salvador",
          "origen": "Todos",
          "destino": "Suecia",
          "tipo": "estudio",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Carta de aceptación \nsolvencia económica \ncertificado médico \nantecedentes penales \nseguro medico"
        },
        {
          "pasaporte": "El Salvador",
          "origen": "Todos",
          "destino": "Suiza",
          "tipo": "estudio",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Carta de aceptación \nsolvencia económica \ncertificado médico \nantecedentes penales \nseguro medico"
        },
        {
          "pasaporte": "El Salvador",
          "origen": "Todos",
          "destino": "Túnez",
          "tipo": "estudio",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Carta de admisión \nsolvencia económica"
        },
        {
          "pasaporte": "El Salvador",
          "origen": "Todos",
          "destino": "Albania",
          "tipo": "estudio",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Carta de admisión \nsolvencia económica"
        },
        {
          "pasaporte": "El Salvador",
          "origen": "Todos",
          "destino": "Australia",
          "tipo": "estudio",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Carta de aceptación \nsolvencia económica \nseguro médico \ncontancia de curso pagado en su totalidad \nexámenes médicos \nsuficiencia de ingles"
        },
        {
          "pasaporte": "El Salvador",
          "origen": "Todos",
          "destino": "Emiratos Árabes Unidos",
          "tipo": "estudio",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Carta de admisión \nexámenes medicos \nsolvencia económica"
        },
        {
          "pasaporte": "El Salvador",
          "origen": "Todos",
          "destino": "India",
          "tipo": "estudio",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Carta de admisión \nsolvencia económica"
        },
        {
          "pasaporte": "El Salvador",
          "origen": "Todos",
          "destino": "Libia",
          "tipo": "estudio",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Carta de admisión \nsolvencia económica \nseguro médico"
        },
        {
          "pasaporte": "El Salvador",
          "origen": "Todos",
          "destino": "Taiwán",
          "tipo": "estudio",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Carta de admisión \nsolvencia económica \nseguro médico"
        },
        {
          "pasaporte": "El Salvador",
          "origen": "Todos",
          "destino": "Vietnam",
          "tipo": "estudio",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Carta de admisión y solvencia económica. Se puede tramitar en vietanm luego de entrar como turista."
        },
        {
          "pasaporte": "El Salvador",
          "origen": "Todos",
          "destino": "Corea del Sur",
          "tipo": "estudio",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Carta de admisión \nsolvencia económica \nplan de estudio"
        },
        {
          "pasaporte": "El Salvador",
          "origen": "Todos",
          "destino": "Argentina",
          "tipo": "estudio",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Carta de aceptación \nsolvencia económica \nacta de nacimiento \nantecedentes penales"
        },
        {
          "pasaporte": "El Salvador",
          "origen": "Todos",
          "destino": "Bolivia",
          "tipo": "estudio",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Certificado de inscripción \ncertificados de estudios previos apostillados \nsolvencia económica \nvacuna fiebre amarilla"
        },
        {
          "pasaporte": "El Salvador",
          "origen": "Todos",
          "destino": "Brasil",
          "tipo": "estudio",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Carta de admisión \nsolvencia económica \nacta de nacimientio \nantecedemtes penales"
        },
        {
          "pasaporte": "El Salvador",
          "origen": "Todos",
          "destino": "Chile",
          "tipo": "estudio",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Certificado de matrícula \nsolvencia económica \ncertificado médico \nantecedentes penales"
        },
        {
          "pasaporte": "El Salvador",
          "origen": "Todos",
          "destino": "China",
          "tipo": "estudio",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Carta de admisión \nsolvencia económica \nexámen físico"
        },
        {
          "pasaporte": "El Salvador",
          "origen": "Todos",
          "destino": "Colombia",
          "tipo": "estudio",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Carta de admisión \ncarta motiva \nsolvencia económica \nseguro médico"
        },
        {
          "pasaporte": "El Salvador",
          "origen": "Todos",
          "destino": "Curacao",
          "tipo": "estudio",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Carta de admisión \nsolvencia económica"
        },
        {
          "pasaporte": "El Salvador",
          "origen": "Todos",
          "destino": "Federación Rusa",
          "tipo": "estudio",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Carta de admisión \nsolvencia económica \ncertificado médico"
        },
        {
          "pasaporte": "El Salvador",
          "origen": "Todos",
          "destino": "Honduras",
          "tipo": "estudio",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Carta de admisión \nsolvencia económica \nacta de nacimientio \nantecedemtes penales \ncertificado médico"
        },
        {
          "pasaporte": "El Salvador",
          "origen": "Todos",
          "destino": "Hong Kong",
          "tipo": "estudio",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Carta de admisión \nsolvencia económica \nseguro médico \nantecedentes penales \nseguro médico"
        },
        {
          "pasaporte": "El Salvador",
          "origen": "Todos",
          "destino": "Israel",
          "tipo": "estudio",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Carta de admisión \nsolvencia económica"
        },
        {
          "pasaporte": "El Salvador",
          "origen": "Todos",
          "destino": "Jamaica",
          "tipo": "estudio",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Carta de admisión \nsolvencia económica"
        },
        {
          "pasaporte": "El Salvador",
          "origen": "Todos",
          "destino": "Nicaragua",
          "tipo": "estudio",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Carta de admisión \nsolvencia económica \ncertificado de nacimiento \nantecedentes penales \ncertificado de salud"
        },
        {
          "pasaporte": "El Salvador",
          "origen": "Todos",
          "destino": "Panamá",
          "tipo": "estudio",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Carta de admisión \nsolvencia económica \ncertificado de salud \nantecedentes penales \npago de matrícula"
        },
        {
          "pasaporte": "El Salvador",
          "origen": "Todos",
          "destino": "Paraguay",
          "tipo": "estudio",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Carta de admisión \nsolvencia económica"
        },
        {
          "pasaporte": "El Salvador",
          "origen": "Todos",
          "destino": "Perú",
          "tipo": "estudio",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Carta de admisión \nsolvencia económica"
        },
        {
          "pasaporte": "El Salvador",
          "origen": "Todos",
          "destino": "República Dominicana",
          "tipo": "estudio",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Carta de admisión \nsolvencia económica \ncertificado de nacimiento \nantecedentes penales \ncertificado de salud"
        },
        {
          "pasaporte": "El Salvador",
          "origen": "Todos",
          "destino": "Singapur",
          "tipo": "estudio",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Carta de admisión \nsolvencia económica"
        },
        {
          "pasaporte": "El Salvador",
          "origen": "Todos",
          "destino": "Sudáfrica",
          "tipo": "estudio",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Carta de admisión \nsolvencia económica \nantecedentes penales \ncertificado de salud \ncertificado de vacunación"
        },
        {
          "pasaporte": "El Salvador",
          "origen": "Todos",
          "destino": "Tailandia",
          "tipo": "estudio",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Carta de inscripción \nsolvencia económica \nantecedentes penales"
        },
        {
          "pasaporte": "El Salvador",
          "origen": "Todos",
          "destino": "Turquía",
          "tipo": "estudio",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Carta de admisión \nsolvencia económica \ncertificados de educación previa \nseguro médico \nprueba de alojamiento"
        },
        {
          "pasaporte": "El Salvador",
          "origen": "Todos",
          "destino": "Uruguay",
          "tipo": "estudio",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Carta de admisión \nsolvencia económica \nantecedentes penales"
        },
        {
          "pasaporte": "El Salvador",
          "origen": "Todos",
          "destino": "Venezuela",
          "tipo": "estudio",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Carta de admisión \ncertificado médico"
        },
        {
          "pasaporte": "México",
          "origen": "Todos",
          "destino": "Alemania",
          "tipo": "estudio",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Carta de aceptación \nsolvencia económica \nsuficiencia del idioma"
        },
        {
          "pasaporte": "México",
          "origen": "Todos",
          "destino": "Andorra",
          "tipo": "estudio",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Carta de acerptación \nsolvencia económica \ncertificado médico \nantecedentes penales \nseguro medico"
        },
        {
          "pasaporte": "México",
          "origen": "Todos",
          "destino": "Arabia Saudí",
          "tipo": "estudio",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Carta de aceptación y carta MOFA"
        },
        {
          "pasaporte": "México",
          "origen": "Todos",
          "destino": "Argelia",
          "tipo": "estudio",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Carta de aceptación \nsolvencia economica \njusitificación de estancia"
        },
        {
          "pasaporte": "México",
          "origen": "Todos",
          "destino": "Austria",
          "tipo": "estudio",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Carta de aceptación \nsolvencia económica \ncertificado médico \nantecedentes penales \nseguro medico"
        },
        {
          "pasaporte": "México",
          "origen": "Todos",
          "destino": "Bélgica",
          "tipo": "estudio",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Carta de aceptación \nsolvencia económica \ncertificado médico \nantecedentes penales \nseguro medico"
        },
        {
          "pasaporte": "México",
          "origen": "Todos",
          "destino": "Bulgaria",
          "tipo": "estudio",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Carta de aceptación \nsolvencia económica \ncertificado médico \nantecedentes penales \nseguro medico"
        },
        {
          "pasaporte": "México",
          "origen": "Todos",
          "destino": "Canadá",
          "tipo": "estudio",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Carta de aceptación \nsolvencia economica"
        },
        {
          "pasaporte": "México",
          "origen": "Todos",
          "destino": "Costa Rica",
          "tipo": "estudio",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Carta de aceptaación \nacta de nacimiento \nantecedentes penales \nsolvencia económica"
        },
        {
          "pasaporte": "México",
          "origen": "Todos",
          "destino": "Croacia",
          "tipo": "estudio",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Carta de aceptación \nsolvencia economica"
        },
        {
          "pasaporte": "México",
          "origen": "Todos",
          "destino": "Cuba",
          "tipo": "estudio",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Carta de aceptación \njustificación de estancia"
        },
        {
          "pasaporte": "México",
          "origen": "Todos",
          "destino": "Dinamarca",
          "tipo": "estudio",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Carta de aceptación \nsolvencia económica \ncertificado médico \nantecedentes penales \nseguro medico"
        },
        {
          "pasaporte": "México",
          "origen": "Todos",
          "destino": "Eslovaquia",
          "tipo": "estudio",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Carta de aceptación \nsolvencia económica \ncertificado médico \nantecedentes penales \nseguro medico"
        },
        {
          "pasaporte": "México",
          "origen": "Todos",
          "destino": "Eslovenia",
          "tipo": "estudio",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Carta de aceptación \nsolvencia económica \ncertificado médico \nantecedentes penales \nseguro medico"
        },
        {
          "pasaporte": "México",
          "origen": "Todos",
          "destino": "España",
          "tipo": "estudio",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Carta de aceptación \nsolvencia económica \ncertificado médico \nantecedentes penales \nseguro medico"
        },
        {
          "pasaporte": "México",
          "origen": "Todos",
          "destino": "Estados Unidos de América",
          "tipo": "estudio",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Carta de aceptación \nsolvencia economica. Los requisitos pueden variar segun tipo de visa (F-1 \nM-1 \nJ-1)"
        },
        {
          "pasaporte": "México",
          "origen": "Todos",
          "destino": "Estonia",
          "tipo": "estudio",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Carta de aceptación \nsolvencia económica \njusitifcación de estancia \nseguro medico \nacta de nacimiento"
        },
        {
          "pasaporte": "México",
          "origen": "Todos",
          "destino": "Finlandia",
          "tipo": "estudio",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Carta de aceptación \nsolvencia económica \ncertificado médico \nantecedentes penales \nseguro medico"
        },
        {
          "pasaporte": "México",
          "origen": "Todos",
          "destino": "Francia",
          "tipo": "estudio",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Carta de aceptación \nsolvencia económica \ncertificado médico \nantecedentes penales \nseguro medico"
        },
        {
          "pasaporte": "México",
          "origen": "Todos",
          "destino": "Grecia",
          "tipo": "estudio",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Carta de aceptación \nsolvencia económica \ncertificado médico \nantecedentes penales \nseguro medico"
        },
        {
          "pasaporte": "México",
          "origen": "Todos",
          "destino": "Guatemala",
          "tipo": "estudio",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Carta de aceptación \nsolvencia económica \nantecedentes penales \nmovimientos migratorios"
        },
        {
          "pasaporte": "México",
          "origen": "Todos",
          "destino": "Hungría",
          "tipo": "estudio",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Carta de aceptación \nsolvencia económica \ncertificado médico \nantecedentes penales \nseguro medico"
        },
        {
          "pasaporte": "México",
          "origen": "Todos",
          "destino": "Irlanda",
          "tipo": "estudio",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Carta de aceptación \nsolvencia económica \ncertificado médico \nantecedentes penales \nseguro medico"
        },
        {
          "pasaporte": "México",
          "origen": "Todos",
          "destino": "Islandia",
          "tipo": "estudio",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Carta de aceptación \nsolvencia económica \ncertificado médico \nantecedentes penales \nseguro medico"
        },
        {
          "pasaporte": "México",
          "origen": "Todos",
          "destino": "Italia",
          "tipo": "estudio",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Carta de aceptación \nsolvencia económica \ncertificado médico \nantecedentes penales \nseguro medico"
        },
        {
          "pasaporte": "México",
          "origen": "Todos",
          "destino": "Japón",
          "tipo": "estudio",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Certificado de elegibilidad COE \nCarta de admisión \ncarta motiva \nsolvencia economica \ncalificaciones de estudios previos"
        },
        {
          "pasaporte": "México",
          "origen": "Todos",
          "destino": "Letonia",
          "tipo": "estudio",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Carta de aceptación \nsolvencia económica \ncertificado médico \nantecedentes penales \nseguro medico"
        },
        {
          "pasaporte": "México",
          "origen": "Todos",
          "destino": "Líbano",
          "tipo": "estudio",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "México",
          "origen": "Todos",
          "destino": "Liberia",
          "tipo": "estudio",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "México",
          "origen": "Todos",
          "destino": "Liechtenstein",
          "tipo": "estudio",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Carta de aceptación \nsolvencia económica \ncertificado médico \nantecedentes penales \nseguro medico"
        },
        {
          "pasaporte": "México",
          "origen": "Todos",
          "destino": "Lituania",
          "tipo": "estudio",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "México",
          "origen": "Todos",
          "destino": "Luxemburgo",
          "tipo": "estudio",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Carta de aceptación \nsolvencia económica \ncertificado médico \nantecedentes penales \nseguro medico"
        },
        {
          "pasaporte": "México",
          "origen": "Todos",
          "destino": "Malta",
          "tipo": "estudio",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Carta de aceptación \nsolvencia económica \ncertificado médico \nantecedentes penales \nseguro medico"
        },
        {
          "pasaporte": "México",
          "origen": "Todos",
          "destino": "Marruecos",
          "tipo": "estudio",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "México",
          "origen": "Todos",
          "destino": "Mónaco",
          "tipo": "estudio",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Carta de aceptación \nsolvencia económica \ncertificado médico \nantecedentes penales \nseguro medico"
        },
        {
          "pasaporte": "México",
          "origen": "Todos",
          "destino": "Montenegro",
          "tipo": "estudio",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Carta de aceptación \nsolvencia económica \nseguro médico \ncontancia de curso pagado en su totalidad \ndemostrar intención de retorno"
        },
        {
          "pasaporte": "México",
          "origen": "Todos",
          "destino": "Noruega",
          "tipo": "estudio",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Carta de aceptación \nsolvencia económica \ncertificado médico \nantecedentes penales \nseguro medico"
        },
        {
          "pasaporte": "México",
          "origen": "Todos",
          "destino": "Nueva Zelanda",
          "tipo": "estudio",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Carta de aceptación \nsolvencia económica \nseguro médico \ncontancia de curso pagado en su totalidad \nexámenes médicos"
        },
        {
          "pasaporte": "México",
          "origen": "Todos",
          "destino": "Países Bajos",
          "tipo": "estudio",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Carta de aceptación \nsolvencia económica \ncertificado médico \nantecedentes penales \nseguro medico"
        },
        {
          "pasaporte": "México",
          "origen": "Todos",
          "destino": "Polonia",
          "tipo": "estudio",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Carta de aceptación \nsolvencia económica \ncertificado médico \nantecedentes penales \nseguro medico"
        },
        {
          "pasaporte": "México",
          "origen": "Todos",
          "destino": "Portugal",
          "tipo": "estudio",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Carta de aceptación \nsolvencia económica \ncertificado médico \nantecedentes penales \nseguro medico"
        },
        {
          "pasaporte": "México",
          "origen": "Todos",
          "destino": "Reino Unido",
          "tipo": "estudio",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Carta de aceptación \nsolvencia económica \ncertificado médico \nantecedentes penales \nseguro medico"
        },
        {
          "pasaporte": "México",
          "origen": "Todos",
          "destino": "República Checa",
          "tipo": "estudio",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Carta de aceptación \nsolvencia económica \ncertificado médico \nantecedentes penales \nseguro medico"
        },
        {
          "pasaporte": "México",
          "origen": "Todos",
          "destino": "Rumanía",
          "tipo": "estudio",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Carta de aceptación \nsolvencia económica \ncertificado médico \nantecedentes penales \nseguro medico"
        },
        {
          "pasaporte": "México",
          "origen": "Todos",
          "destino": "Serbia",
          "tipo": "estudio",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Carta de aceptación \nsolvencia económica \ncertificados de estudios previos"
        },
        {
          "pasaporte": "México",
          "origen": "Todos",
          "destino": "Suecia",
          "tipo": "estudio",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Carta de aceptación \nsolvencia económica \ncertificado médico \nantecedentes penales \nseguro medico"
        },
        {
          "pasaporte": "México",
          "origen": "Todos",
          "destino": "Suiza",
          "tipo": "estudio",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Carta de aceptación \nsolvencia económica \ncertificado médico \nantecedentes penales \nseguro medico"
        },
        {
          "pasaporte": "México",
          "origen": "Todos",
          "destino": "Túnez",
          "tipo": "estudio",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Carta de admisión \nsolvencia económica"
        },
        {
          "pasaporte": "México",
          "origen": "Todos",
          "destino": "Albania",
          "tipo": "estudio",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Carta de admisión \nsolvencia económica"
        },
        {
          "pasaporte": "México",
          "origen": "Todos",
          "destino": "Australia",
          "tipo": "estudio",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Carta de aceptación \nsolvencia económica \nseguro médico \ncontancia de curso pagado en su totalidad \nexámenes médicos \nsuficiencia de ingles"
        },
        {
          "pasaporte": "México",
          "origen": "Todos",
          "destino": "Emiratos Árabes Unidos",
          "tipo": "estudio",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Carta de admisión \nexámenes medicos \nsolvencia económica"
        },
        {
          "pasaporte": "México",
          "origen": "Todos",
          "destino": "India",
          "tipo": "estudio",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Carta de admisión \nsolvencia económica"
        },
        {
          "pasaporte": "México",
          "origen": "Todos",
          "destino": "Libia",
          "tipo": "estudio",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Carta de admisión \nsolvencia económica \nseguro médico"
        },
        {
          "pasaporte": "México",
          "origen": "Todos",
          "destino": "Taiwán",
          "tipo": "estudio",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Carta de admisión \nsolvencia económica \nseguro médico"
        },
        {
          "pasaporte": "México",
          "origen": "Todos",
          "destino": "Vietnam",
          "tipo": "estudio",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Carta de admisión y solvencia económica. Se puede tramitar en vietanm luego de entrar como turista."
        },
        {
          "pasaporte": "México",
          "origen": "Todos",
          "destino": "Corea del Sur",
          "tipo": "estudio",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Carta de admisión \nsolvencia económica \nplan de estudio"
        },
        {
          "pasaporte": "México",
          "origen": "Todos",
          "destino": "Argentina",
          "tipo": "estudio",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Carta de aceptación \nsolvencia económica \nacta de nacimiento \nantecedentes penales"
        },
        {
          "pasaporte": "México",
          "origen": "Todos",
          "destino": "Bolivia",
          "tipo": "estudio",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Certificado de inscripción \ncertificados de estudios previos apostillados \nsolvencia económica \nvacuna fiebre amarilla"
        },
        {
          "pasaporte": "México",
          "origen": "Todos",
          "destino": "Brasil",
          "tipo": "estudio",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Carta de admisión \nsolvencia económica \nacta de nacimientio \nantecedemtes penales"
        },
        {
          "pasaporte": "México",
          "origen": "Todos",
          "destino": "Chile",
          "tipo": "estudio",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Certificado de matrícula \nsolvencia económica \ncertificado médico \nantecedentes penales"
        },
        {
          "pasaporte": "México",
          "origen": "Todos",
          "destino": "China",
          "tipo": "estudio",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Carta de admisión \nsolvencia económica \nexámen físico"
        },
        {
          "pasaporte": "México",
          "origen": "Todos",
          "destino": "Colombia",
          "tipo": "estudio",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Carta de admisión \ncarta motiva \nsolvencia económica \nseguro médico"
        },
        {
          "pasaporte": "México",
          "origen": "Todos",
          "destino": "Curacao",
          "tipo": "estudio",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Carta de admisión \nsolvencia económica"
        },
        {
          "pasaporte": "México",
          "origen": "Todos",
          "destino": "El Salvador",
          "tipo": "estudio",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Carta de admisión \nsolvencia económica \nacta de nacimientio \nantecedemtes penales"
        },
        {
          "pasaporte": "México",
          "origen": "Todos",
          "destino": "Federación Rusa",
          "tipo": "estudio",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Carta de admisión \nsolvencia económica \ncertificado médico"
        },
        {
          "pasaporte": "México",
          "origen": "Todos",
          "destino": "Honduras",
          "tipo": "estudio",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Carta de admisión \nsolvencia económica \nacta de nacimientio \nantecedemtes penales \ncertificado médico"
        },
        {
          "pasaporte": "México",
          "origen": "Todos",
          "destino": "Hong Kong",
          "tipo": "estudio",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Carta de admisión \nsolvencia económica \nseguro médico \nantecedentes penales \nseguro médico"
        },
        {
          "pasaporte": "México",
          "origen": "Todos",
          "destino": "Israel",
          "tipo": "estudio",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Carta de admisión \nsolvencia económica"
        },
        {
          "pasaporte": "México",
          "origen": "Todos",
          "destino": "Jamaica",
          "tipo": "estudio",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Carta de admisión \nsolvencia económica"
        },
        {
          "pasaporte": "México",
          "origen": "Todos",
          "destino": "Nicaragua",
          "tipo": "estudio",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Carta de admisión \nsolvencia económica \ncertificado de nacimiento \nantecedentes penales \ncertificado de salud"
        },
        {
          "pasaporte": "México",
          "origen": "Todos",
          "destino": "Panamá",
          "tipo": "estudio",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Carta de admisión \nsolvencia económica \ncertificado de salud \nantecedentes penales \npago de matrícula"
        },
        {
          "pasaporte": "México",
          "origen": "Todos",
          "destino": "Paraguay",
          "tipo": "estudio",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Carta de admisión \nsolvencia económica"
        },
        {
          "pasaporte": "México",
          "origen": "Todos",
          "destino": "Perú",
          "tipo": "estudio",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Carta de admisión \nsolvencia económica"
        },
        {
          "pasaporte": "México",
          "origen": "Todos",
          "destino": "República Dominicana",
          "tipo": "estudio",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Carta de admisión \nsolvencia económica \ncertificado de nacimiento \nantecedentes penales \ncertificado de salud"
        },
        {
          "pasaporte": "México",
          "origen": "Todos",
          "destino": "Singapur",
          "tipo": "estudio",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Carta de admisión \nsolvencia económica"
        },
        {
          "pasaporte": "México",
          "origen": "Todos",
          "destino": "Sudáfrica",
          "tipo": "estudio",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Carta de admisión \nsolvencia económica \nantecedentes penales \ncertificado de salud \ncertificado de vacunación"
        },
        {
          "pasaporte": "México",
          "origen": "Todos",
          "destino": "Tailandia",
          "tipo": "estudio",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Carta de inscripción \nsolvencia económica \nantecedentes penales"
        },
        {
          "pasaporte": "México",
          "origen": "Todos",
          "destino": "Turquía",
          "tipo": "estudio",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Carta de admisión \nsolvencia económica \ncertificados de educación previa \nseguro médico \nprueba de alojamiento"
        },
        {
          "pasaporte": "México",
          "origen": "Todos",
          "destino": "Uruguay",
          "tipo": "estudio",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Carta de admisión \nsolvencia económica \nantecedentes penales"
        },
        {
          "pasaporte": "México",
          "origen": "Todos",
          "destino": "Venezuela",
          "tipo": "estudio",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Carta de admisión \ncertificado médico"
        },
        {
          "pasaporte": "México",
          "origen": "Todos",
          "destino": "Islandia",
          "tipo": "estudio",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "México",
          "origen": "Todos",
          "destino": "Italia",
          "tipo": "estudio",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "México",
          "origen": "Todos",
          "destino": "Japón",
          "tipo": "estudio",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Certificado de elegibilidad COE \nCarta de admisión \ncarta motiva \nsolvencia economica \ncalificaciones de estudios previos"
        },
        {
          "pasaporte": "México",
          "origen": "Todos",
          "destino": "Letonia",
          "tipo": "estudio",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "México",
          "origen": "Todos",
          "destino": "Líbano",
          "tipo": "estudio",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "México",
          "origen": "Todos",
          "destino": "Liberia",
          "tipo": "estudio",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "México",
          "origen": "Todos",
          "destino": "Liechtenstein",
          "tipo": "estudio",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "México",
          "origen": "Todos",
          "destino": "Lituania",
          "tipo": "estudio",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "México",
          "origen": "Todos",
          "destino": "Luxemburgo",
          "tipo": "estudio",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "México",
          "origen": "Todos",
          "destino": "Malta",
          "tipo": "estudio",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "México",
          "origen": "Todos",
          "destino": "Marruecos",
          "tipo": "estudio",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "México",
          "origen": "Todos",
          "destino": "Mónaco",
          "tipo": "estudio",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "México",
          "origen": "Todos",
          "destino": "Montenegro",
          "tipo": "estudio",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Carta de aceptación \nsolvencia económica \nseguro médico \ncontancia de curso pagado en su totalidad \ndemostrar intención de retorno"
        },
        {
          "pasaporte": "México",
          "origen": "Todos",
          "destino": "Noruega",
          "tipo": "estudio",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "México",
          "origen": "Todos",
          "destino": "Nueva Zelanda",
          "tipo": "estudio",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Carta de aceptación \nsolvencia económica \nseguro médico \ncontancia de curso pagado en su totalidad \nexámenes médicos"
        },
        {
          "pasaporte": "México",
          "origen": "Todos",
          "destino": "Países Bajos",
          "tipo": "estudio",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "México",
          "origen": "Todos",
          "destino": "Polonia",
          "tipo": "estudio",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "México",
          "origen": "Todos",
          "destino": "Portugal",
          "tipo": "estudio",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "México",
          "origen": "Todos",
          "destino": "Reino Unido",
          "tipo": "estudio",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "México",
          "origen": "Todos",
          "destino": "República Checa",
          "tipo": "estudio",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "México",
          "origen": "Todos",
          "destino": "Rumanía",
          "tipo": "estudio",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "México",
          "origen": "Todos",
          "destino": "Serbia",
          "tipo": "estudio",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Carta de aceptación \nsolvencia económica \ncertificados de estudios previos"
        },
        {
          "pasaporte": "México",
          "origen": "Todos",
          "destino": "Suecia",
          "tipo": "estudio",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "México",
          "origen": "Todos",
          "destino": "Suiza",
          "tipo": "estudio",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "México",
          "origen": "Todos",
          "destino": "Túnez",
          "tipo": "estudio",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Carta de admisión \nsolvencia económica"
        },
        {
          "pasaporte": "México",
          "origen": "Todos",
          "destino": "Albania",
          "tipo": "estudio",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Carta de admisión \nsolvencia económica"
        },
        {
          "pasaporte": "México",
          "origen": "Todos",
          "destino": "Australia",
          "tipo": "estudio",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Carta de aceptación \nsolvencia económica \nseguro médico \ncontancia de curso pagado en su totalidad \nexámenes médicos \nsuficiencia de ingles"
        },
        {
          "pasaporte": "México",
          "origen": "Todos",
          "destino": "Emiratos Árabes Unidos",
          "tipo": "estudio",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Carta de admisión \nexámenes medicos \nsolvencia económica"
        },
        {
          "pasaporte": "México",
          "origen": "Todos",
          "destino": "India",
          "tipo": "estudio",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Carta de admisión \nsolvencia económica"
        },
        {
          "pasaporte": "México",
          "origen": "Todos",
          "destino": "Libia",
          "tipo": "estudio",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Carta de admisión \nsolvencia económica \nseguro médico"
        },
        {
          "pasaporte": "México",
          "origen": "Todos",
          "destino": "Taiwán",
          "tipo": "estudio",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Carta de admisión \nsolvencia económica \nseguro médico"
        },
        {
          "pasaporte": "México",
          "origen": "Todos",
          "destino": "Vietnam",
          "tipo": "estudio",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Carta de admisión y solvencia económica. Se puede tramitar en vietanm luego de entrar como turista."
        },
        {
          "pasaporte": "México",
          "origen": "Todos",
          "destino": "Corea del Sur",
          "tipo": "estudio",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Carta de admisión \nsolvencia económica \nplan de estudio"
        },
        {
          "pasaporte": "México",
          "origen": "Todos",
          "destino": "Argentina",
          "tipo": "estudio",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Carta de aceptación \nsolvencia económica \nacta de nacimiento \nantecedentes penales"
        },
        {
          "pasaporte": "México",
          "origen": "Todos",
          "destino": "Bolivia",
          "tipo": "estudio",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Certificado de inscripción \ncertificados de estudios previos apostillados \nsolvencia económica \nvacuna fiebre amarilla"
        },
        {
          "pasaporte": "México",
          "origen": "Todos",
          "destino": "Brasil",
          "tipo": "estudio",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Carta de admisión \nsolvencia económica \nacta de nacimientio \nantecedemtes penales"
        },
        {
          "pasaporte": "México",
          "origen": "Todos",
          "destino": "Chile",
          "tipo": "estudio",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Certificado de matrícula \nsolvencia económica \ncertificado médico \nantecedentes penales"
        },
        {
          "pasaporte": "México",
          "origen": "Todos",
          "destino": "China",
          "tipo": "estudio",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Carta de admisión \nsolvencia económica \nexámen físico"
        },
        {
          "pasaporte": "México",
          "origen": "Todos",
          "destino": "Colombia",
          "tipo": "estudio",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Carta de admisión \ncarta motiva \nsolvencia económica \nseguro médico"
        },
        {
          "pasaporte": "México",
          "origen": "Todos",
          "destino": "Curacao",
          "tipo": "estudio",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Carta de admisión \nsolvencia económica"
        },
        {
          "pasaporte": "México",
          "origen": "Todos",
          "destino": "El Salvador",
          "tipo": "estudio",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Carta de admisión \nsolvencia económica \nacta de nacimientio \nantecedemtes penales"
        },
        {
          "pasaporte": "México",
          "origen": "Todos",
          "destino": "Federación Rusa",
          "tipo": "estudio",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Carta de admisión \nsolvencia económica \ncertificado médico"
        },
        {
          "pasaporte": "México",
          "origen": "Todos",
          "destino": "Honduras",
          "tipo": "estudio",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Carta de admisión \nsolvencia económica \nacta de nacimientio \nantecedemtes penales \ncertificado médico"
        },
        {
          "pasaporte": "México",
          "origen": "Todos",
          "destino": "Hong Kong",
          "tipo": "estudio",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Carta de admisión \nsolvencia económica \nseguro médico \nantecedentes penales \nseguro médico"
        },
        {
          "pasaporte": "México",
          "origen": "Todos",
          "destino": "Israel",
          "tipo": "estudio",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Carta de admisión \nsolvencia económica"
        },
        {
          "pasaporte": "México",
          "origen": "Todos",
          "destino": "Jamaica",
          "tipo": "estudio",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Carta de admisión \nsolvencia económica"
        },
        {
          "pasaporte": "México",
          "origen": "Todos",
          "destino": "Nicaragua",
          "tipo": "estudio",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Carta de admisión \nsolvencia económica \ncertificado de nacimiento \nantecedentes penales \ncertificado de salud"
        },
        {
          "pasaporte": "México",
          "origen": "Todos",
          "destino": "Panamá",
          "tipo": "estudio",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Carta de admisión \nsolvencia económica \ncertificado de salud \nantecedentes penales \npago de matrícula"
        },
        {
          "pasaporte": "México",
          "origen": "Todos",
          "destino": "Paraguay",
          "tipo": "estudio",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Carta de admisión \nsolvencia económica"
        },
        {
          "pasaporte": "México",
          "origen": "Todos",
          "destino": "Perú",
          "tipo": "estudio",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Carta de admisión \nsolvencia económica"
        },
        {
          "pasaporte": "México",
          "origen": "Todos",
          "destino": "República Dominicana",
          "tipo": "estudio",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Carta de admisión \nsolvencia económica \ncertificado de nacimiento \nantecedentes penales \ncertificado de salud"
        },
        {
          "pasaporte": "México",
          "origen": "Todos",
          "destino": "Singapur",
          "tipo": "estudio",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Carta de admisión \nsolvencia económica"
        },
        {
          "pasaporte": "México",
          "origen": "Todos",
          "destino": "Sudáfrica",
          "tipo": "estudio",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Carta de admisión \nsolvencia económica \nantecedentes penales \ncertificado de salud \ncertificado de vacunación"
        },
        {
          "pasaporte": "México",
          "origen": "Todos",
          "destino": "Tailandia",
          "tipo": "estudio",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Carta de inscripción \nsolvencia económica \nantecedentes penales"
        },
        {
          "pasaporte": "México",
          "origen": "Todos",
          "destino": "Turquía",
          "tipo": "estudio",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Carta de admisión \nsolvencia económica \ncertificados de educación previa \nseguro médico \nprueba de alojamiento"
        },
        {
          "pasaporte": "México",
          "origen": "Todos",
          "destino": "Uruguay",
          "tipo": "estudio",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Carta de admisión \nsolvencia económica \nantecedentes penales"
        },
        {
          "pasaporte": "México",
          "origen": "Todos",
          "destino": "Venezuela",
          "tipo": "estudio",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Carta de admisión \ncertificado médico"
        }
      ]
  }
}
