export class Visas {
  public static visados = {
      "visas":[
       {
        "pasaporte": "Venezuela",
        "origen": "Todos",
        "destino": "España",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Venezuela",
        "origen": "Todos",
        "destino": "Italia",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Venezuela",
        "origen": "Todos",
        "destino": "Portugal",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Venezuela",
        "origen": "Todos",
        "destino": "Paises Bajos",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Venezuela",
        "origen": "Todos",
        "destino": "Alemania",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Venezuela",
        "origen": "Todos",
        "destino": "Francia",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Venezuela",
        "origen": "Todos",
        "destino": "Austria",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Venezuela",
        "origen": "Todos",
        "destino": "República Checa",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Venezuela",
        "origen": "Todos",
        "destino": "Albania",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Venezuela",
        "origen": "Todos",
        "destino": "Andorra",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Venezuela",
        "origen": "Todos",
        "destino": "Antigua y Barbuda",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": ""
       },
       {
        "pasaporte": "Venezuela",
        "origen": "Todos",
        "destino": "Argentina",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": ""
       },
       {
        "pasaporte": "Venezuela",
        "origen": "Todos",
        "destino": "Bahamas",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": ""
       },
       {
        "pasaporte": "Venezuela",
        "origen": "Todos",
        "destino": "Barbados",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": ""
       },
       {
        "pasaporte": "Venezuela",
        "origen": "Todos",
        "destino": "Bélgica",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Venezuela",
        "origen": "Todos",
        "destino": "Bielorrusia",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Venezuela",
        "origen": "Todos",
        "destino": "Bolivia",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": ""
       },
       {
        "pasaporte": "Venezuela",
        "origen": "Todos",
        "destino": "Bulgaria",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Venezuela",
        "origen": "Todos",
        "destino": "Bosnia-Herzegovina",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Venezuela",
        "origen": "Todos",
        "destino": "Botswana",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": ""
       },
       {
        "pasaporte": "Venezuela",
        "origen": "Todos",
        "destino": "Brasil",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": ""
       },
       {
        "pasaporte": "Venezuela",
        "origen": "Todos",
        "destino": "Chipre",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Venezuela",
        "origen": "Todos",
        "destino": "Colombia",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": ""
       },
       {
        "pasaporte": "Venezuela",
        "origen": "Todos",
        "destino": "Croacia",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Venezuela",
        "origen": "Todos",
        "destino": "Dinamarca",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Venezuela",
        "origen": "Todos",
        "destino": "Dominica",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": ""
       },
       {
        "pasaporte": "Venezuela",
        "origen": "Todos",
        "destino": "Eslovaquia",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Venezuela",
        "origen": "Todos",
        "destino": "Eslovenia",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Venezuela",
        "origen": "Todos",
        "destino": "Estonia",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Venezuela",
        "origen": "Todos",
        "destino": "Rusia",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Venezuela",
        "origen": "Todos",
        "destino": "Fiji",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": ""
       },
       {
        "pasaporte": "Venezuela",
        "origen": "Todos",
        "destino": "Filipinas",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": ""
       },
       {
        "pasaporte": "Venezuela",
        "origen": "Todos",
        "destino": "Finlandia",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Venezuela",
        "origen": "Todos",
        "destino": "Gambia",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": ""
       },
       {
        "pasaporte": "Venezuela",
        "origen": "Todos",
        "destino": "Granada",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": ""
       },
       {
        "pasaporte": "Venezuela",
        "origen": "Todos",
        "destino": "Grecia",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Venezuela",
        "origen": "Todos",
        "destino": "Groenlandia",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Venezuela",
        "origen": "Todos",
        "destino": "Guyana Francesa",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": ""
       },
       {
        "pasaporte": "Venezuela",
        "origen": "Todos",
        "destino": "Haiti",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": ""
       },
       {
        "pasaporte": "Venezuela",
        "origen": "Todos",
        "destino": "Hong Kong",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": ""
       },
       {
        "pasaporte": "Venezuela",
        "origen": "Todos",
        "destino": "Hungria",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Venezuela",
        "origen": "Todos",
        "destino": "Irán",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": ""
       },
       {
        "pasaporte": "Venezuela",
        "origen": "Todos",
        "destino": "Islandia",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Venezuela",
        "origen": "Todos",
        "destino": "Islas Caimán",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": ""
       },
       {
        "pasaporte": "Venezuela",
        "origen": "Todos",
        "destino": "Islas Cook",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": ""
       },
       {
        "pasaporte": "Venezuela",
        "origen": "Todos",
        "destino": "Islas Feroes",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": ""
       },
       {
        "pasaporte": "Venezuela",
        "origen": "Todos",
        "destino": "Islas Virgenes Británicas",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": ""
       },
       {
        "pasaporte": "Venezuela",
        "origen": "Todos",
        "destino": "Jamaica",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": ""
       },
       {
        "pasaporte": "Venezuela",
        "origen": "Todos",
        "destino": "Kosovo",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": ""
       },
       {
        "pasaporte": "Venezuela",
        "origen": "Todos",
        "destino": "Letonia",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Venezuela",
        "origen": "Todos",
        "destino": "Liechtenstein",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Venezuela",
        "origen": "Todos",
        "destino": "Lituania",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Venezuela",
        "origen": "Todos",
        "destino": "Luxemburgo",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Venezuela",
        "origen": "Todos",
        "destino": "Macedonia del Norte",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Venezuela",
        "origen": "Todos",
        "destino": "Malasia",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": ""
       },
       {
        "pasaporte": "Venezuela",
        "origen": "Todos",
        "destino": "Malta",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Venezuela",
        "origen": "Todos",
        "destino": "Micronesia",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": ""
       },
       {
        "pasaporte": "Venezuela",
        "origen": "Todos",
        "destino": "Moldavia",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Venezuela",
        "origen": "Todos",
        "destino": "Mónaco",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Venezuela",
        "origen": "Todos",
        "destino": "Montenegro",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Venezuela",
        "origen": "Todos",
        "destino": "Noruega",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Venezuela",
        "origen": "Todos",
        "destino": "Nueva Caledonia",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Venezuela",
        "origen": "Todos",
        "destino": "Paraguay",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": ""
       },
       {
        "pasaporte": "Venezuela",
        "origen": "Todos",
        "destino": "Polinesia Francesa",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Venezuela",
        "origen": "Todos",
        "destino": "Polonia",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Venezuela",
        "origen": "Todos",
        "destino": "Rumania",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Venezuela",
        "origen": "Todos",
        "destino": "San Cristobal y Nieves",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": ""
       },
       {
        "pasaporte": "Venezuela",
        "origen": "Todos",
        "destino": "San Marino",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Venezuela",
        "origen": "Todos",
        "destino": "San Vicente y las Granadinas",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": ""
       },
       {
        "pasaporte": "Venezuela",
        "origen": "Todos",
        "destino": "Singapur",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": ""
       },
       {
        "pasaporte": "Venezuela",
        "origen": "Todos",
        "destino": "Sudafrica",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Venezuela",
        "origen": "Todos",
        "destino": "Suecia",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Venezuela",
        "origen": "Todos",
        "destino": "Suiza",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Venezuela",
        "origen": "Todos",
        "destino": "Turquia",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Venezuela",
        "origen": "Todos",
        "destino": "Uruguay",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": ""
       },
       {
        "pasaporte": "Venezuela",
        "origen": "Todos",
        "destino": "Afganistán",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.93",
        "requisitos": "Pasaporte vigente.\nReserva de vuelo ida y vuelta.\nBoleto ida y vuelta."
       },
       {
        "pasaporte": "Venezuela",
        "origen": "Todos",
        "destino": "Angola",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.93",
        "requisitos": ""
       },
       {
        "pasaporte": "Venezuela",
        "origen": "Todos",
        "destino": "Arabia Saudita",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.93",
        "requisitos": ""
       },
       {
        "pasaporte": "Venezuela",
        "origen": "Todos",
        "destino": "Argelia",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.93",
        "requisitos": ""
       },
       {
        "pasaporte": "Venezuela",
        "origen": "Todos",
        "destino": "Aruba",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.93",
        "requisitos": "Pasaporte con 3 meses de vigencia despues de la salida de Aruba\nComprobar fondos económicos\nBoleto Ida y Vuelta\nFormulario de inmigración."
       },
       {
        "pasaporte": "Venezuela",
        "origen": "Todos",
        "destino": "Azerbaiyán",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.93",
        "requisitos": ""
       },
       {
        "pasaporte": "Venezuela",
        "origen": "Todos",
        "destino": "Belice",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.93",
        "requisitos": ""
       },
       {
        "pasaporte": "Venezuela",
        "origen": "Todos",
        "destino": "Bermuda",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.93",
        "requisitos": ""
       },
       {
        "pasaporte": "Venezuela",
        "origen": "Todos",
        "destino": "Bonaire",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.93",
        "requisitos": ""
       },
       {
        "pasaporte": "Venezuela",
        "origen": "Todos",
        "destino": "Brunei",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.93",
        "requisitos": ""
       },
       {
        "pasaporte": "Venezuela",
        "origen": "Todos",
        "destino": "Canadá",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.93",
        "requisitos": "Pasaporte vigente\nSuficiencia económica\nVinculos con País de Origen\nVisado\nMotivo de viaje"
       },
       {
        "pasaporte": "Venezuela",
        "origen": "Todos",
        "destino": "Chad",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.93",
        "requisitos": ""
       },
       {
        "pasaporte": "Venezuela",
        "origen": "Todos",
        "destino": "Chile",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.93",
        "requisitos": ""
       },
       {
        "pasaporte": "Venezuela",
        "origen": "Todos",
        "destino": "China",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.93",
        "requisitos": "Pasaporte con al menos 6 meses de vigencia\nBoleto ida y vuelta\nComprobante de alojamiento\nSolicitud de visado"
       },
       {
        "pasaporte": "Venezuela",
        "origen": "Todos",
        "destino": "Corea del Norte",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.93",
        "requisitos": ""
       },
       {
        "pasaporte": "Venezuela",
        "origen": "Todos",
        "destino": "Costa Rica",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.93",
        "requisitos": "Pasaporte vigente con al menos 3 meses de vigencia\nVisado\nSuficiencia económica\nVacuna fiebre amarilla"
       },
       {
        "pasaporte": "Venezuela",
        "origen": "Todos",
        "destino": "Cuba",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.93",
        "requisitos": "Formulario de Viaje\nSeguro médico de viaje"
       },
       {
        "pasaporte": "Venezuela",
        "origen": "Todos",
        "destino": "Curacao",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.93",
        "requisitos": "Pasaporte con 3 meses de vigencia despues de la salida de Aruba\nComprobar fondos económicos\nBoleto Ida y Vuelta\nFormulario de inmigración."
       },
       {
        "pasaporte": "Venezuela",
        "origen": "Todos",
        "destino": "Eritrea",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.93",
        "requisitos": ""
       },
       {
        "pasaporte": "Venezuela",
        "origen": "Todos",
        "destino": "Islas Falkland",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.93",
        "requisitos": ""
       },
       {
        "pasaporte": "Venezuela",
        "origen": "Todos",
        "destino": "Ghana",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.93",
        "requisitos": ""
       },
       {
        "pasaporte": "Venezuela",
        "origen": "Todos",
        "destino": "Guam",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.93",
        "requisitos": ""
       },
       {
        "pasaporte": "Venezuela",
        "origen": "Todos",
        "destino": "Guatemala",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.93",
        "requisitos": "Visado\nFormulario electónico de viaje\nPasaporte vigente"
       },
       {
        "pasaporte": "Venezuela",
        "origen": "Todos",
        "destino": "Guyana",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.93",
        "requisitos": ""
       },
       {
        "pasaporte": "Venezuela",
        "origen": "Todos",
        "destino": "Honduras",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.93",
        "requisitos": "Visado\nFormulario electónico de viaje\nPasaporte vigente"
       },
       {
        "pasaporte": "Venezuela",
        "origen": "Todos",
        "destino": "Irak",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.93",
        "requisitos": ""
       },
       {
        "pasaporte": "Venezuela",
        "origen": "Todos",
        "destino": "Irlanda",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.93",
        "requisitos": "Visado\nPasaporte vigente"
       },
       {
        "pasaporte": "Venezuela",
        "origen": "Todos",
        "destino": "Islas Marianas",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.93",
        "requisitos": ""
       },
       {
        "pasaporte": "Venezuela",
        "origen": "Todos",
        "destino": "Islas Marshall",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.93",
        "requisitos": ""
       },
       {
        "pasaporte": "Venezuela",
        "origen": "Todos",
        "destino": "Islas Salomón",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.93",
        "requisitos": ""
       },
       {
        "pasaporte": "Venezuela",
        "origen": "Todos",
        "destino": "Islas Virgenes Americanas",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.93",
        "requisitos": ""
       },
       {
        "pasaporte": "Venezuela",
        "origen": "Todos",
        "destino": "Isarael",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.93",
        "requisitos": ""
       },
       {
        "pasaporte": "Venezuela",
        "origen": "Todos",
        "destino": "Japón",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.93",
        "requisitos": "Visado\nPasaporte vigente"
       },
       {
        "pasaporte": "Venezuela",
        "origen": "Todos",
        "destino": "Kuwait",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.93",
        "requisitos": ""
       },
       {
        "pasaporte": "Venezuela",
        "origen": "Todos",
        "destino": "Liberia",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.93",
        "requisitos": ""
       },
       {
        "pasaporte": "Venezuela",
        "origen": "Todos",
        "destino": "Mali",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.93",
        "requisitos": ""
       },
       {
        "pasaporte": "Venezuela",
        "origen": "Todos",
        "destino": "Marruecos",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.93",
        "requisitos": "Visado\nPasaporte vigente"
       },
       {
        "pasaporte": "Venezuela",
        "origen": "Todos",
        "destino": "México",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.93",
        "requisitos": "Visado\nPasaporte vigente\nSuficiencia económica\nVínculos con país de origen"
       },
       {
        "pasaporte": "Venezuela",
        "origen": "Todos",
        "destino": "Nicaragua",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.93",
        "requisitos": "Visado\nPasaporte Vigente\nVacuna fiebre amarilla"
       },
       {
        "pasaporte": "Venezuela",
        "origen": "Todos",
        "destino": "Niger",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.93",
        "requisitos": ""
       },
       {
        "pasaporte": "Venezuela",
        "origen": "Todos",
        "destino": "Nueva Zelanda",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.93",
        "requisitos": "Visado\nPasaporte vigente"
       },
       {
        "pasaporte": "Venezuela",
        "origen": "Todos",
        "destino": "Panamá",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.93",
        "requisitos": "Visado\nPasaporte vigente\nMotivo de viaje\nSuficiencia económica"
       },
       {
        "pasaporte": "Venezuela",
        "origen": "Todos",
        "destino": "Papua Nueva Guinea",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.93",
        "requisitos": ""
       },
       {
        "pasaporte": "Venezuela",
        "origen": "Todos",
        "destino": "Perú",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.93",
        "requisitos": "Visado\nPasaporte vigente"
       },
       {
        "pasaporte": "Venezuela",
        "origen": "Todos",
        "destino": "Puerto Rico",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.93",
        "requisitos": "Visado\nPasaporte vigente"
       },
       {
        "pasaporte": "Venezuela",
        "origen": "Todos",
        "destino": "Reino Unido",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.93",
        "requisitos": "Visado\nPasaporte vigente"
       },
       {
        "pasaporte": "Venezuela",
        "origen": "Todos",
        "destino": "República Centroafricana",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.93",
        "requisitos": ""
       },
       {
        "pasaporte": "Venezuela",
        "origen": "Todos",
        "destino": "República del Congo",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.93",
        "requisitos": ""
       },
       {
        "pasaporte": "Venezuela",
        "origen": "Todos",
        "destino": "República Dominicana",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.93",
        "requisitos": "Visado\nPasaporte vigente\nReserva de hotel\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "Venezuela",
        "origen": "Todos",
        "destino": "Samoa Americana",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.93",
        "requisitos": ""
       },
       {
        "pasaporte": "Venezuela",
        "origen": "Todos",
        "destino": "San Martín",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.93",
        "requisitos": ""
       },
       {
        "pasaporte": "Venezuela",
        "origen": "Todos",
        "destino": "Santa Lucia",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.93",
        "requisitos": "Visado\nBoleto aereo ida y vuelta\nPasaporte vigente\nFormulario eléctronico de inmigración"
       },
       {
        "pasaporte": "Venezuela",
        "origen": "Todos",
        "destino": "Senegal",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.93",
        "requisitos": ""
       },
       {
        "pasaporte": "Venezuela",
        "origen": "Todos",
        "destino": "Serbia",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.93",
        "requisitos": ""
       },
       {
        "pasaporte": "Venezuela",
        "origen": "Todos",
        "destino": "Somalia",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.93",
        "requisitos": ""
       },
       {
        "pasaporte": "Venezuela",
        "origen": "Todos",
        "destino": "Sudán",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.93",
        "requisitos": ""
       },
       {
        "pasaporte": "Venezuela",
        "origen": "Todos",
        "destino": "Taiwán",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.93",
        "requisitos": ""
       },
       {
        "pasaporte": "Venezuela",
        "origen": "Todos",
        "destino": "Territorios Palestinos",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.93",
        "requisitos": ""
       },
       {
        "pasaporte": "Venezuela",
        "origen": "Todos",
        "destino": "Tonga",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.93",
        "requisitos": ""
       },
       {
        "pasaporte": "Venezuela",
        "origen": "Todos",
        "destino": "Trinidad y Tobago",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.93",
        "requisitos": "Visa Eléctronica\nAlojamiento\nBoleto ida y vuelta\nPasaporte vigente"
       },
       {
        "pasaporte": "Venezuela",
        "origen": "Todos",
        "destino": "Túnez",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.93",
        "requisitos": ""
       },
       {
        "pasaporte": "Venezuela",
        "origen": "Todos",
        "destino": "Ucrania",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.93",
        "requisitos": ""
       },
       {
        "pasaporte": "Venezuela",
        "origen": "Todos",
        "destino": "Turkmenistán",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.93",
        "requisitos": ""
       },
       {
        "pasaporte": "Venezuela",
        "origen": "Todos",
        "destino": "Vanuatu",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.93",
        "requisitos": ""
       },
       {
        "pasaporte": "Venezuela",
        "origen": "Todos",
        "destino": "Yemen",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.93",
        "requisitos": ""
       },
       {
        "pasaporte": "Venezuela",
        "origen": "Todos",
        "destino": "Armenia",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.93",
        "requisitos": ""
       },
       {
        "pasaporte": "Venezuela",
        "origen": "Todos",
        "destino": "Australia",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.93",
        "requisitos": "Visado\nPasaporte vigente"
       },
       {
        "pasaporte": "Venezuela",
        "origen": "Todos",
        "destino": "Benin",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.93",
        "requisitos": ""
       },
       {
        "pasaporte": "Venezuela",
        "origen": "Todos",
        "destino": "Birmania",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.93",
        "requisitos": ""
       },
       {
        "pasaporte": "Venezuela",
        "origen": "Todos",
        "destino": "Butánb",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.93",
        "requisitos": ""
       },
       {
        "pasaporte": "Venezuela",
        "origen": "Todos",
        "destino": "Camerún",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.93",
        "requisitos": ""
       },
       {
        "pasaporte": "Venezuela",
        "origen": "Todos",
        "destino": "Costa de Marfil",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.93",
        "requisitos": ""
       },
       {
        "pasaporte": "Venezuela",
        "origen": "Todos",
        "destino": "Ecuador",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.93",
        "requisitos": "Visado eléctronico\nPasaporte vigente\nSolvencia económica"
       },
       {
        "pasaporte": "Venezuela",
        "origen": "Todos",
        "destino": "El Salvador",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.93",
        "requisitos": "Visado eléctronico\nPasaporte vigente\nSolvencia económica\nAlojamiento"
       },
       {
        "pasaporte": "Venezuela",
        "origen": "Todos",
        "destino": "Emiratos Árabes Unidos",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.93",
        "requisitos": "Visado eléctronico\nPasaporte vigente"
       },
       {
        "pasaporte": "Venezuela",
        "origen": "Todos",
        "destino": "Etiopía",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.93",
        "requisitos": "Visado eléctronico\nPasaporte vigente"
       },
       {
        "pasaporte": "Venezuela",
        "origen": "Todos",
        "destino": "Gabón",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.93",
        "requisitos": "Visado eléctronico\nPasaporte vigente"
       },
       {
        "pasaporte": "Venezuela",
        "origen": "Todos",
        "destino": "Georgia",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.93",
        "requisitos": "Visado eléctronico\nPasaporte vigente"
       },
       {
        "pasaporte": "Venezuela",
        "origen": "Todos",
        "destino": "Guinea",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.93",
        "requisitos": "Visado eléctronico\nPasaporte vigente"
       },
       {
        "pasaporte": "Venezuela",
        "origen": "Todos",
        "destino": "Guinea Ecuatorial",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.93",
        "requisitos": "Visado eléctronico\nPasaporte vigente"
       },
       {
        "pasaporte": "Venezuela",
        "origen": "Todos",
        "destino": "India",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.93",
        "requisitos": "Visado eléctronico\nPasaporte vigente"
       },
       {
        "pasaporte": "Venezuela",
        "origen": "Todos",
        "destino": "Kazajistán",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.93",
        "requisitos": "Visado eléctronico\nPasaporte vigente"
       },
       {
        "pasaporte": "Venezuela",
        "origen": "Todos",
        "destino": "Lesoto",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.93",
        "requisitos": "Visado eléctronico\nPasaporte vigente"
       },
       {
        "pasaporte": "Venezuela",
        "origen": "Todos",
        "destino": "Libia",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.93",
        "requisitos": "Visado eléctronico\nPasaporte vigente"
       },
       {
        "pasaporte": "Venezuela",
        "origen": "Todos",
        "destino": "Mongolia",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.93",
        "requisitos": "Visado eléctronico\nPasaporte vigente"
       },
       {
        "pasaporte": "Venezuela",
        "origen": "Todos",
        "destino": "Montserrat",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.93",
        "requisitos": "Visado eléctronico\nPasaporte vigente"
       },
       {
        "pasaporte": "Venezuela",
        "origen": "Todos",
        "destino": "Nigeria",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.93",
        "requisitos": "Visado eléctronico\nPasaporte vigente"
       },
       {
        "pasaporte": "Venezuela",
        "origen": "Todos",
        "destino": "Pakistan",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.93",
        "requisitos": "Visado eléctronico\nPasaporte vigente"
       },
       {
        "pasaporte": "Venezuela",
        "origen": "Todos",
        "destino": "República Democrática del Congo",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.93",
        "requisitos": "Visado eléctronico\nPasaporte vigente"
       },
       {
        "pasaporte": "Venezuela",
        "origen": "Todos",
        "destino": "Santo Tomé y Principe",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.93",
        "requisitos": "Visado eléctronico\nPasaporte vigente"
       },
       {
        "pasaporte": "Venezuela",
        "origen": "Todos",
        "destino": "Sierra Leona",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.93",
        "requisitos": "Visado eléctronico\nPasaporte vigente"
       },
       {
        "pasaporte": "Venezuela",
        "origen": "Todos",
        "destino": "Siria",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.93",
        "requisitos": "Visado eléctronico\nPasaporte vigente"
       },
       {
        "pasaporte": "Venezuela",
        "origen": "Todos",
        "destino": "Sudan del Sur",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.93",
        "requisitos": "Visado eléctronico\nPasaporte vigente"
       },
       {
        "pasaporte": "Venezuela",
        "origen": "Todos",
        "destino": "Surinam",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.93",
        "requisitos": "Visado eléctronico\nPasaporte vigente"
       },
       {
        "pasaporte": "Venezuela",
        "origen": "Todos",
        "destino": "Togo",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.93",
        "requisitos": "Visado eléctronico\nPasaporte vigente"
       },
       {
        "pasaporte": "Venezuela",
        "origen": "Todos",
        "destino": "Uganda",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.93",
        "requisitos": "Visado eléctronico\nPasaporte vigente"
       },
       {
        "pasaporte": "Venezuela",
        "origen": "Todos",
        "destino": "Uzbekistan",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.93",
        "requisitos": "Visado eléctronico\nPasaporte vigente"
       },
       {
        "pasaporte": "Venezuela",
        "origen": "Todos",
        "destino": "Vietnán",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.93",
        "requisitos": "Visado eléctronico\nPasaporte vigente"
       },
       {
        "pasaporte": "Venezuela",
        "origen": "Todos",
        "destino": "Corea del Sur",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.93",
        "requisitos": "Requiere ETA\nPasaporte vigente"
       },
       {
        "pasaporte": "Venezuela",
        "origen": "Todos",
        "destino": "Kenia",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.93",
        "requisitos": "Requiere ETA\nPasaporte vigente"
       },
       {
        "pasaporte": "Venezuela",
        "origen": "Todos",
        "destino": "Estados Unidos",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.93",
        "requisitos": "Solicitud de Visado\nPasaporte vigente"
       },
       {
        "pasaporte": "Ecuador",
        "origen": "Todos",
        "destino": "Afganistán",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": ""
       },
       {
        "pasaporte": "Ecuador",
        "origen": "Todos",
        "destino": "Alemania",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado\nPasaporte vigente\nAsistencia Médica de Viaje\nReserva de vuelo\nReserva de hospedaje\nSolvencia económica"
       },
       {
        "pasaporte": "Ecuador",
        "origen": "Todos",
        "destino": "Andorra",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado\nPasaporte vigente\nAsistencia Médica de Viaje\nReserva de vuelo\nReserva de hospedaje\nSolvencia económica"
       },
       {
        "pasaporte": "Ecuador",
        "origen": "Todos",
        "destino": "Angola",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": ""
       },
       {
        "pasaporte": "Ecuador",
        "origen": "Todos",
        "destino": "Anguilla",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": ""
       },
       {
        "pasaporte": "Ecuador",
        "origen": "Todos",
        "destino": "Arabia Saudí",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": ""
       },
       {
        "pasaporte": "Ecuador",
        "origen": "Todos",
        "destino": "Argelia",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": ""
       },
       {
        "pasaporte": "Ecuador",
        "origen": "Todos",
        "destino": "Austria",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado\nPasaporte vigente\nAsistencia Médica de Viaje\nReserva de vuelo\nReserva de hospedaje\nSolvencia económica"
       },
       {
        "pasaporte": "Ecuador",
        "origen": "Todos",
        "destino": "Bélgica",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado\nPasaporte vigente\nAsistencia Médica de Viaje\nReserva de vuelo\nReserva de hospedaje\nSolvencia económica"
       },
       {
        "pasaporte": "Ecuador",
        "origen": "Todos",
        "destino": "Belice",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": ""
       },
       {
        "pasaporte": "Ecuador",
        "origen": "Todos",
        "destino": "Bermuda",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": ""
       },
       {
        "pasaporte": "Ecuador",
        "origen": "Todos",
        "destino": "Bosnia-Herzegovina",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado\nPasaporte vigente\nAsistencia Médica de Viaje\nReserva de vuelo\nReserva de hospedaje\nSolvencia económica"
       },
       {
        "pasaporte": "Ecuador",
        "origen": "Todos",
        "destino": "Brunei",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": ""
       },
       {
        "pasaporte": "Ecuador",
        "origen": "Todos",
        "destino": "Bulgaria",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": ""
       },
       {
        "pasaporte": "Ecuador",
        "origen": "Todos",
        "destino": "Canadá",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado\nPasaporte vigente"
       },
       {
        "pasaporte": "Ecuador",
        "origen": "Todos",
        "destino": "Chad",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": ""
       },
       {
        "pasaporte": "Ecuador",
        "origen": "Todos",
        "destino": "Chipre",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": ""
       },
       {
        "pasaporte": "Ecuador",
        "origen": "Todos",
        "destino": "Ciudad del Vaticano",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado\nPasaporte vigente\nAsistencia Médica de Viaje\nReserva de vuelo\nReserva de hospedaje\nSolvencia económica"
       },
       {
        "pasaporte": "Ecuador",
        "origen": "Todos",
        "destino": "Corea del Norte",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": ""
       },
       {
        "pasaporte": "Ecuador",
        "origen": "Todos",
        "destino": "Costa Rica",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado\nPasaporte Vigente\nSuficiencia económica\nBoletos ida y vuelta"
       },
       {
        "pasaporte": "Ecuador",
        "origen": "Todos",
        "destino": "Croacia",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado\nPasaporte vigente\nAsistencia Médica de Viaje\nReserva de vuelo\nReserva de hospedaje\nSolvencia económica"
       },
       {
        "pasaporte": "Ecuador",
        "origen": "Todos",
        "destino": "Cuba",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": ""
       },
       {
        "pasaporte": "Ecuador",
        "origen": "Todos",
        "destino": "Dinamarca",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado\nPasaporte vigente\nAsistencia Médica de Viaje\nReserva de vuelo\nReserva de hospedaje\nSolvencia económica"
       },
       {
        "pasaporte": "Ecuador",
        "origen": "Todos",
        "destino": "Eritrea",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": ""
       },
       {
        "pasaporte": "Ecuador",
        "origen": "Todos",
        "destino": "Eslovaquia",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado\nPasaporte vigente\nAsistencia Médica de Viaje\nReserva de vuelo\nReserva de hospedaje\nSolvencia económica"
       },
       {
        "pasaporte": "Ecuador",
        "origen": "Todos",
        "destino": "Eslovenia",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado\nPasaporte vigente\nAsistencia Médica de Viaje\nReserva de vuelo\nReserva de hospedaje\nSolvencia económica"
       },
       {
        "pasaporte": "Ecuador",
        "origen": "Todos",
        "destino": "España",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado\nPasaporte vigente\nAsistencia Médica de Viaje\nReserva de vuelo\nReserva de hospedaje\nSolvencia económica"
       },
       {
        "pasaporte": "Ecuador",
        "origen": "Todos",
        "destino": "Estados Unidos de América",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado\nPasaporte Vigente"
       },
       {
        "pasaporte": "Ecuador",
        "origen": "Todos",
        "destino": "Estonia",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado\nPasaporte vigente\nAsistencia Médica de Viaje\nReserva de vuelo\nReserva de hospedaje\nSolvencia económica"
       },
       {
        "pasaporte": "Ecuador",
        "origen": "Todos",
        "destino": "Eswatini",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": ""
       },
       {
        "pasaporte": "Ecuador",
        "origen": "Todos",
        "destino": "Falkland Islands",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": ""
       },
       {
        "pasaporte": "Ecuador",
        "origen": "Todos",
        "destino": "Fiji",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": ""
       },
       {
        "pasaporte": "Ecuador",
        "origen": "Todos",
        "destino": "Finlandia",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado\nPasaporte vigente\nAsistencia Médica de Viaje\nReserva de vuelo\nReserva de hospedaje\nSolvencia económica"
       },
       {
        "pasaporte": "Ecuador",
        "origen": "Todos",
        "destino": "Francia",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado\nPasaporte vigente\nAsistencia Médica de Viaje\nReserva de vuelo\nReserva de hospedaje\nSolvencia económica"
       },
       {
        "pasaporte": "Ecuador",
        "origen": "Todos",
        "destino": "Ghana",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": ""
       },
       {
        "pasaporte": "Ecuador",
        "origen": "Todos",
        "destino": "Gibraltar",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado\nPasaporte vigente\nAsistencia Médica de Viaje\nReserva de vuelo\nReserva de hospedaje\nSolvencia económica"
       },
       {
        "pasaporte": "Ecuador",
        "origen": "Todos",
        "destino": "Grecia",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado\nPasaporte vigente\nAsistencia Médica de Viaje\nReserva de vuelo\nReserva de hospedaje\nSolvencia económica"
       },
       {
        "pasaporte": "Ecuador",
        "origen": "Todos",
        "destino": "Groenlandia",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": ""
       },
       {
        "pasaporte": "Ecuador",
        "origen": "Todos",
        "destino": "Guam",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": ""
       },
       {
        "pasaporte": "Ecuador",
        "origen": "Todos",
        "destino": "Guatemala",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado\nPasaporte vigenter\nVacuna Fiebre amarilla"
       },
       {
        "pasaporte": "Ecuador",
        "origen": "Todos",
        "destino": "Guayana Francesa​​​​",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": ""
       },
       {
        "pasaporte": "Ecuador",
        "origen": "Todos",
        "destino": "Hungría",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado\nPasaporte vigente\nAsistencia Médica de Viaje\nReserva de vuelo\nReserva de hospedaje\nSolvencia económica"
       },
       {
        "pasaporte": "Ecuador",
        "origen": "Todos",
        "destino": "Indias Occidentales Francesas",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": ""
       },
       {
        "pasaporte": "Ecuador",
        "origen": "Todos",
        "destino": "Irak",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": ""
       },
       {
        "pasaporte": "Ecuador",
        "origen": "Todos",
        "destino": "Irlanda",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado\nPasaporte vigente"
       },
       {
        "pasaporte": "Ecuador",
        "origen": "Todos",
        "destino": "Isla Norfolk",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": ""
       },
       {
        "pasaporte": "Ecuador",
        "origen": "Todos",
        "destino": "Isla Reunión",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": ""
       },
       {
        "pasaporte": "Ecuador",
        "origen": "Todos",
        "destino": "Islandia",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado\nPasaporte vigente\nAsistencia Médica de Viaje\nReserva de vuelo\nReserva de hospedaje\nSolvencia económica"
       },
       {
        "pasaporte": "Ecuador",
        "origen": "Todos",
        "destino": "Islas Feroe",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": ""
       },
       {
        "pasaporte": "Ecuador",
        "origen": "Todos",
        "destino": "Islas Marianas del Norte",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": ""
       },
       {
        "pasaporte": "Ecuador",
        "origen": "Todos",
        "destino": "Islas Marshall",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": ""
       },
       {
        "pasaporte": "Ecuador",
        "origen": "Todos",
        "destino": "Islas Salomón",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": ""
       },
       {
        "pasaporte": "Ecuador",
        "origen": "Todos",
        "destino": "Islas Vírgenes americanas",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": ""
       },
       {
        "pasaporte": "Ecuador",
        "origen": "Todos",
        "destino": "Italia",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado\nPasaporte vigente\nAsistencia Médica de Viaje\nReserva de vuelo\nReserva de hospedaje\nSolvencia económica"
       },
       {
        "pasaporte": "Ecuador",
        "origen": "Todos",
        "destino": "Japón",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado\nPasaporte vigente."
       },
       {
        "pasaporte": "Ecuador",
        "origen": "Todos",
        "destino": "Kiribati",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": ""
       },
       {
        "pasaporte": "Ecuador",
        "origen": "Todos",
        "destino": "Kosovo",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": ""
       },
       {
        "pasaporte": "Ecuador",
        "origen": "Todos",
        "destino": "Kuwait",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": ""
       },
       {
        "pasaporte": "Ecuador",
        "origen": "Todos",
        "destino": "Letonia",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado\nPasaporte vigente\nAsistencia Médica de Viaje\nReserva de vuelo\nReserva de hospedaje\nSolvencia económica"
       },
       {
        "pasaporte": "Ecuador",
        "origen": "Todos",
        "destino": "Líbano",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": ""
       },
       {
        "pasaporte": "Ecuador",
        "origen": "Todos",
        "destino": "Liberia",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": ""
       },
       {
        "pasaporte": "Ecuador",
        "origen": "Todos",
        "destino": "Liechtenstein",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado\nPasaporte vigente\nAsistencia Médica de Viaje\nReserva de vuelo\nReserva de hospedaje\nSolvencia económica"
       },
       {
        "pasaporte": "Ecuador",
        "origen": "Todos",
        "destino": "Lituania",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado\nPasaporte vigente\nAsistencia Médica de Viaje\nReserva de vuelo\nReserva de hospedaje\nSolvencia económica"
       },
       {
        "pasaporte": "Ecuador",
        "origen": "Todos",
        "destino": "Luxemburgo",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado\nPasaporte vigente\nAsistencia Médica de Viaje\nReserva de vuelo\nReserva de hospedaje\nSolvencia económica"
       },
       {
        "pasaporte": "Ecuador",
        "origen": "Todos",
        "destino": "Macedonia del Norte",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": ""
       },
       {
        "pasaporte": "Ecuador",
        "origen": "Todos",
        "destino": "Mali",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": ""
       },
       {
        "pasaporte": "Ecuador",
        "origen": "Todos",
        "destino": "Malta",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado\nPasaporte vigente\nAsistencia Médica de Viaje\nReserva de vuelo\nReserva de hospedaje\nSolvencia económica"
       },
       {
        "pasaporte": "Ecuador",
        "origen": "Todos",
        "destino": "Marruecos",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": ""
       },
       {
        "pasaporte": "Ecuador",
        "origen": "Todos",
        "destino": "Mayotte",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": ""
       },
       {
        "pasaporte": "Ecuador",
        "origen": "Todos",
        "destino": "México",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado\nPasaporte vigente\nSuficiencia económica\nVínculos con país de origen"
       },
       {
        "pasaporte": "Ecuador",
        "origen": "Todos",
        "destino": "Mónaco",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado\nPasaporte vigente\nAsistencia Médica de Viaje\nReserva de vuelo\nReserva de hospedaje\nSolvencia económica"
       },
       {
        "pasaporte": "Ecuador",
        "origen": "Todos",
        "destino": "Montenegro",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado\nPasaporte vigente\nAsistencia Médica de Viaje\nReserva de vuelo\nReserva de hospedaje\nSolvencia económica"
       },
       {
        "pasaporte": "Ecuador",
        "origen": "Todos",
        "destino": "Nauru",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": ""
       },
       {
        "pasaporte": "Ecuador",
        "origen": "Todos",
        "destino": "Níger",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": ""
       },
       {
        "pasaporte": "Ecuador",
        "origen": "Todos",
        "destino": "Noruega",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado\nPasaporte vigente\nAsistencia Médica de Viaje\nReserva de vuelo\nReserva de hospedaje\nSolvencia económica"
       },
       {
        "pasaporte": "Ecuador",
        "origen": "Todos",
        "destino": "Nueva Caledonia",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": ""
       },
       {
        "pasaporte": "Ecuador",
        "origen": "Todos",
        "destino": "Nueva Zelanda",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado\nPasaporte vigente"
       },
       {
        "pasaporte": "Ecuador",
        "origen": "Todos",
        "destino": "Países Bajos",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado\nPasaporte vigente\nAsistencia Médica de Viaje\nReserva de vuelo\nReserva de hospedaje\nSolvencia económica"
       },
       {
        "pasaporte": "Ecuador",
        "origen": "Todos",
        "destino": "Polinesia Francesa",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": ""
       },
       {
        "pasaporte": "Ecuador",
        "origen": "Todos",
        "destino": "Polonia",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado\nPasaporte vigente\nAsistencia Médica de Viaje\nReserva de vuelo\nReserva de hospedaje\nSolvencia económica"
       },
       {
        "pasaporte": "Ecuador",
        "origen": "Todos",
        "destino": "Portugal",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado\nPasaporte vigente\nAsistencia Médica de Viaje\nReserva de vuelo\nReserva de hospedaje\nSolvencia económica"
       },
       {
        "pasaporte": "Ecuador",
        "origen": "Todos",
        "destino": "Puerto Rico",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado\nPasaporte vigente"
       },
       {
        "pasaporte": "Ecuador",
        "origen": "Todos",
        "destino": "Reino Unido",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado\nPasaporte vigente"
       },
       {
        "pasaporte": "Ecuador",
        "origen": "Todos",
        "destino": "República Centroafricana",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": ""
       },
       {
        "pasaporte": "Ecuador",
        "origen": "Todos",
        "destino": "República Checa",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado\nPasaporte vigente\nAsistencia Médica de Viaje\nReserva de vuelo\nReserva de hospedaje\nSolvencia económica"
       },
       {
        "pasaporte": "Ecuador",
        "origen": "Todos",
        "destino": "República del Congo",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": ""
       },
       {
        "pasaporte": "Ecuador",
        "origen": "Todos",
        "destino": "Rumanía",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado\nPasaporte vigente\nAsistencia Médica de Viaje\nReserva de vuelo\nReserva de hospedaje\nSolvencia económica"
       },
       {
        "pasaporte": "Ecuador",
        "origen": "Todos",
        "destino": "Samoa Americana",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": ""
       },
       {
        "pasaporte": "Ecuador",
        "origen": "Todos",
        "destino": "San Marino",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado\nPasaporte vigente\nAsistencia Médica de Viaje\nReserva de vuelo\nReserva de hospedaje\nSolvencia económica"
       },
       {
        "pasaporte": "Ecuador",
        "origen": "Todos",
        "destino": "San Pedro y Miquelón",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": ""
       },
       {
        "pasaporte": "Ecuador",
        "origen": "Todos",
        "destino": "Senegal",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": ""
       },
       {
        "pasaporte": "Ecuador",
        "origen": "Todos",
        "destino": "Serbia",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado\nPasaporte vigente\nAsistencia Médica de Viaje\nReserva de vuelo\nReserva de hospedaje\nSolvencia económica"
       },
       {
        "pasaporte": "Ecuador",
        "origen": "Todos",
        "destino": "Somalia",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": ""
       },
       {
        "pasaporte": "Ecuador",
        "origen": "Todos",
        "destino": "Sudán",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": ""
       },
       {
        "pasaporte": "Ecuador",
        "origen": "Todos",
        "destino": "Suecia",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado\nPasaporte vigente\nAsistencia Médica de Viaje\nReserva de vuelo\nReserva de hospedaje\nSolvencia económica"
       },
       {
        "pasaporte": "Ecuador",
        "origen": "Todos",
        "destino": "Suiza",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado\nPasaporte vigente\nAsistencia Médica de Viaje\nReserva de vuelo\nReserva de hospedaje\nSolvencia económica"
       },
       {
        "pasaporte": "Ecuador",
        "origen": "Todos",
        "destino": "Tonga",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": ""
       },
       {
        "pasaporte": "Ecuador",
        "origen": "Todos",
        "destino": "Túnez",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": ""
       },
       {
        "pasaporte": "Ecuador",
        "origen": "Todos",
        "destino": "Turkmenistán",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": ""
       },
       {
        "pasaporte": "Ecuador",
        "origen": "Todos",
        "destino": "Vanuatu",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": ""
       },
       {
        "pasaporte": "Ecuador",
        "origen": "Todos",
        "destino": "Yemen",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": ""
       },
       {
        "pasaporte": "Ecuador",
        "origen": "Todos",
        "destino": "Albania",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado\nPasaporte vigente\nAsistencia Médica de Viaje\nReserva de vuelo\nReserva de hospedaje\nSolvencia económica"
       },
       {
        "pasaporte": "Ecuador",
        "origen": "Todos",
        "destino": "Australia",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado\nPasaporte vigente"
       },
       {
        "pasaporte": "Ecuador",
        "origen": "Todos",
        "destino": "Azerbaiyán",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": ""
       },
       {
        "pasaporte": "Ecuador",
        "origen": "Todos",
        "destino": "Benín",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": ""
       },
       {
        "pasaporte": "Ecuador",
        "origen": "Todos",
        "destino": "Birmania",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": ""
       },
       {
        "pasaporte": "Ecuador",
        "origen": "Todos",
        "destino": "Botswana",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": ""
       },
       {
        "pasaporte": "Ecuador",
        "origen": "Todos",
        "destino": "Burkina Faso",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": ""
       },
       {
        "pasaporte": "Ecuador",
        "origen": "Todos",
        "destino": "Bután",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": ""
       },
       {
        "pasaporte": "Ecuador",
        "origen": "Todos",
        "destino": "Camerún",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": ""
       },
       {
        "pasaporte": "Ecuador",
        "origen": "Todos",
        "destino": "Costa de Marfil",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": ""
       },
       {
        "pasaporte": "Ecuador",
        "origen": "Todos",
        "destino": "Emiratos Árabes Unidos",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado\nPasaporte vigente"
       },
       {
        "pasaporte": "Ecuador",
        "origen": "Todos",
        "destino": "Etiopia",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": ""
       },
       {
        "pasaporte": "Ecuador",
        "origen": "Todos",
        "destino": "Gabón",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": ""
       },
       {
        "pasaporte": "Ecuador",
        "origen": "Todos",
        "destino": "Guinea",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": ""
       },
       {
        "pasaporte": "Ecuador",
        "origen": "Todos",
        "destino": "Guinea Ecuatorial",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": ""
       },
       {
        "pasaporte": "Ecuador",
        "origen": "Todos",
        "destino": "India",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado\nPasaporte vigente"
       },
       {
        "pasaporte": "Ecuador",
        "origen": "Todos",
        "destino": "Irán",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": ""
       },
       {
        "pasaporte": "Ecuador",
        "origen": "Todos",
        "destino": "Kirguistán",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": ""
       },
       {
        "pasaporte": "Ecuador",
        "origen": "Todos",
        "destino": "Lesoto",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": ""
       },
       {
        "pasaporte": "Ecuador",
        "origen": "Todos",
        "destino": "Libia",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": ""
       },
       {
        "pasaporte": "Ecuador",
        "origen": "Todos",
        "destino": "Montserrat",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": ""
       },
       {
        "pasaporte": "Ecuador",
        "origen": "Todos",
        "destino": "Nigeria",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": ""
       },
       {
        "pasaporte": "Ecuador",
        "origen": "Todos",
        "destino": "Pakistán",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": ""
       },
       {
        "pasaporte": "Ecuador",
        "origen": "Todos",
        "destino": "Papúa Nueva Guinea",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": ""
       },
       {
        "pasaporte": "Ecuador",
        "origen": "Todos",
        "destino": "República Democrática del Congo",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": ""
       },
       {
        "pasaporte": "Ecuador",
        "origen": "Todos",
        "destino": "Santa Elena",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": ""
       },
       {
        "pasaporte": "Ecuador",
        "origen": "Todos",
        "destino": "Santo Tomé y Príncipe",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": ""
       },
       {
        "pasaporte": "Ecuador",
        "origen": "Todos",
        "destino": "Sierra Leona",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": ""
       },
       {
        "pasaporte": "Ecuador",
        "origen": "Todos",
        "destino": "Siria",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": ""
       },
       {
        "pasaporte": "Ecuador",
        "origen": "Todos",
        "destino": "Sudán del Sur",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": ""
       },
       {
        "pasaporte": "Ecuador",
        "origen": "Todos",
        "destino": "Taiwán",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": ""
       },
       {
        "pasaporte": "Ecuador",
        "origen": "Todos",
        "destino": "Togo",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": ""
       },
       {
        "pasaporte": "Ecuador",
        "origen": "Todos",
        "destino": "Uganda",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": ""
       },
       {
        "pasaporte": "Ecuador",
        "origen": "Todos",
        "destino": "Uzbekistán",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": ""
       },
       {
        "pasaporte": "Ecuador",
        "origen": "Todos",
        "destino": "Vietnam",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": ""
       },
       {
        "pasaporte": "Ecuador",
        "origen": "Todos",
        "destino": "Corea del Sur",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Autorización ETA\nPasaporte vigente"
       },
       {
        "pasaporte": "Ecuador",
        "origen": "Todos",
        "destino": "Kenia",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Autorización ETA\nPasaporte vigente"
       },
       {
        "pasaporte": "Ecuador",
        "origen": "Todos",
        "destino": "Antigua y Barbuda",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": ""
       },
       {
        "pasaporte": "Ecuador",
        "origen": "Todos",
        "destino": "Argentina",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "Ecuador",
        "origen": "Todos",
        "destino": "Armenia",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": ""
       },
       {
        "pasaporte": "Ecuador",
        "origen": "Todos",
        "destino": "Aruba",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Boleto ida y vuelta\nReserva de hospedaje\nComprobante de solvencia\nPasaporte vigente"
       },
       {
        "pasaporte": "Ecuador",
        "origen": "Todos",
        "destino": "Bahamas",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "Ecuador",
        "origen": "Todos",
        "destino": "Barbados",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "Ecuador",
        "origen": "Todos",
        "destino": "Bielorrusia",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte Vigente\nSeguro médico"
       },
       {
        "pasaporte": "Ecuador",
        "origen": "Todos",
        "destino": "Bolivia",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "Ecuador",
        "origen": "Todos",
        "destino": "Bonaire, San Eustaquio y Saba",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "Ecuador",
        "origen": "Todos",
        "destino": "Brasil",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "Ecuador",
        "origen": "Todos",
        "destino": "Chile",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "Ecuador",
        "origen": "Todos",
        "destino": "China",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "Ecuador",
        "origen": "Todos",
        "destino": "Colombia",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "Ecuador",
        "origen": "Todos",
        "destino": "Curacao",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Boleto ida y vuelta\nReserva de hospedaje\nComprobante de solvencia"
       },
       {
        "pasaporte": "Ecuador",
        "origen": "Todos",
        "destino": "Dominica",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "Ecuador",
        "origen": "Todos",
        "destino": "El Salvador",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Boleto ida y vuelta\nReserva de hospedaje\nComprobante de solvencia"
       },
       {
        "pasaporte": "Ecuador",
        "origen": "Todos",
        "destino": "Federación Rusa",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": ""
       },
       {
        "pasaporte": "Ecuador",
        "origen": "Todos",
        "destino": "Filipinas",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "Ecuador",
        "origen": "Todos",
        "destino": "Gambia",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "Ecuador",
        "origen": "Todos",
        "destino": "Georgia",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "Ecuador",
        "origen": "Todos",
        "destino": "Granada",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "Ecuador",
        "origen": "Todos",
        "destino": "Guyana",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "Ecuador",
        "origen": "Todos",
        "destino": "Haití",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "Ecuador",
        "origen": "Todos",
        "destino": "Honduras",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "Ecuador",
        "origen": "Todos",
        "destino": "Hong Kong",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "Ecuador",
        "origen": "Todos",
        "destino": "Islas Caimán",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "Ecuador",
        "origen": "Todos",
        "destino": "Islas Cook",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "Ecuador",
        "origen": "Todos",
        "destino": "Islas Turcas y Caicos",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "Ecuador",
        "origen": "Todos",
        "destino": "Islas Vírgenes Británicas",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "Ecuador",
        "origen": "Todos",
        "destino": "Israel",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "Ecuador",
        "origen": "Todos",
        "destino": "Jamaica",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "Ecuador",
        "origen": "Todos",
        "destino": "Kazajistán",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "Ecuador",
        "origen": "Todos",
        "destino": "Macao",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "Ecuador",
        "origen": "Todos",
        "destino": "Malasia",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "Ecuador",
        "origen": "Todos",
        "destino": "Micronesia",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "Ecuador",
        "origen": "Todos",
        "destino": "Moldavia",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "Ecuador",
        "origen": "Todos",
        "destino": "Mongolia",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "Ecuador",
        "origen": "Todos",
        "destino": "Nicaragua",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "Ecuador",
        "origen": "Todos",
        "destino": "Panamá",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "Ecuador",
        "origen": "Todos",
        "destino": "Paraguay",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "Ecuador",
        "origen": "Todos",
        "destino": "Perú",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "Ecuador",
        "origen": "Todos",
        "destino": "República Dominicana",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "Ecuador",
        "origen": "Todos",
        "destino": "San Cristóbal y Nieves",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "Ecuador",
        "origen": "Todos",
        "destino": "San Martín",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "Ecuador",
        "origen": "Todos",
        "destino": "San Vicente y las Granadinas",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "Ecuador",
        "origen": "Todos",
        "destino": "Singapur",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "Ecuador",
        "origen": "Todos",
        "destino": "Sudáfrica",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "Ecuador",
        "origen": "Todos",
        "destino": "Surinam",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "Ecuador",
        "origen": "Todos",
        "destino": "Tailandia",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "Ecuador",
        "origen": "Todos",
        "destino": "Tajikistán",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "Ecuador",
        "origen": "Todos",
        "destino": "Territorios Palestinos",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "Ecuador",
        "origen": "Todos",
        "destino": "Trinidad y Tobago",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "Ecuador",
        "origen": "Todos",
        "destino": "Turquía",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "Ecuador",
        "origen": "Todos",
        "destino": "Ucrania",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": ""
       },
       {
        "pasaporte": "Ecuador",
        "origen": "Todos",
        "destino": "Uruguay",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "Ecuador",
        "origen": "Todos",
        "destino": "Venezuela",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "Ecuador",
        "origen": "Todos",
        "destino": "Wallis y Futuna",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "Colombia",
        "origen": "Todos",
        "destino": "Albania",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "Colombia",
        "origen": "Todos",
        "destino": "Alemania",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Colombia",
        "origen": "Todos",
        "destino": "Andorra",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Colombia",
        "origen": "Todos",
        "destino": "Antigua y Barbuda",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "Colombia",
        "origen": "Todos",
        "destino": "Argentina",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "Colombia",
        "origen": "Todos",
        "destino": "Aruba",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "Colombia",
        "origen": "Todos",
        "destino": "Austria",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Colombia",
        "origen": "Todos",
        "destino": "Bahamas",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "Colombia",
        "origen": "Todos",
        "destino": "Barbados",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "Colombia",
        "origen": "Todos",
        "destino": "Bélgica",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Colombia",
        "origen": "Todos",
        "destino": "Belice",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "Colombia",
        "origen": "Todos",
        "destino": "Bolivia",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "Colombia",
        "origen": "Todos",
        "destino": "Bonaire, San Eustaquio y Saba",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "Colombia",
        "origen": "Todos",
        "destino": "Bosnia-Herzegovina",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "Colombia",
        "origen": "Todos",
        "destino": "Brasil",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "Colombia",
        "origen": "Todos",
        "destino": "Bulgaria",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Colombia",
        "origen": "Todos",
        "destino": "Chile",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "Colombia",
        "origen": "Todos",
        "destino": "Chipre",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Colombia",
        "origen": "Todos",
        "destino": "Ciudad del Vaticano",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "Colombia",
        "origen": "Todos",
        "destino": "Corea del Sur",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "Colombia",
        "origen": "Todos",
        "destino": "Croacia",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Colombia",
        "origen": "Todos",
        "destino": "Curaçao",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "Colombia",
        "origen": "Todos",
        "destino": "Dinamarca",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Colombia",
        "origen": "Todos",
        "destino": "Dominica",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "Colombia",
        "origen": "Todos",
        "destino": "Ecuador",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "Colombia",
        "origen": "Todos",
        "destino": "El Salvador",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "Colombia",
        "origen": "Todos",
        "destino": "Emiratos Árabes Unidos",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "Colombia",
        "origen": "Todos",
        "destino": "Eslovaquia",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Colombia",
        "origen": "Todos",
        "destino": "Eslovenia",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Colombia",
        "origen": "Todos",
        "destino": "España",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Colombia",
        "origen": "Todos",
        "destino": "Estonia",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Colombia",
        "origen": "Todos",
        "destino": "Federación Rusa",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "Colombia",
        "origen": "Todos",
        "destino": "Fiji",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "Colombia",
        "origen": "Todos",
        "destino": "Filipinas",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "Colombia",
        "origen": "Todos",
        "destino": "Finlandia",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Colombia",
        "origen": "Todos",
        "destino": "Francia",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Colombia",
        "origen": "Todos",
        "destino": "Georgia",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "Colombia",
        "origen": "Todos",
        "destino": "Grecia",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Colombia",
        "origen": "Todos",
        "destino": "Groenlandia",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Colombia",
        "origen": "Todos",
        "destino": "Guatemala",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "Colombia",
        "origen": "Todos",
        "destino": "Guayana Francesa​​​​",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "Colombia",
        "origen": "Todos",
        "destino": "Guyana",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "Colombia",
        "origen": "Todos",
        "destino": "Honduras",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "Colombia",
        "origen": "Todos",
        "destino": "Hong Kong",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "Colombia",
        "origen": "Todos",
        "destino": "Hungría",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Colombia",
        "origen": "Todos",
        "destino": "Indias Occidentales Francesas",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "Colombia",
        "origen": "Todos",
        "destino": "Isla Reunión",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "Colombia",
        "origen": "Todos",
        "destino": "Islandia",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Colombia",
        "origen": "Todos",
        "destino": "Islas Cook",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "Colombia",
        "origen": "Todos",
        "destino": "Islas Feroe",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "Colombia",
        "origen": "Todos",
        "destino": "Islas Turcas y Caicos",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "Colombia",
        "origen": "Todos",
        "destino": "Israel",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "Colombia",
        "origen": "Todos",
        "destino": "Italia",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Colombia",
        "origen": "Todos",
        "destino": "Jamaica",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "Colombia",
        "origen": "Todos",
        "destino": "Kazajistán",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "Colombia",
        "origen": "Todos",
        "destino": "Kiribati",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "Colombia",
        "origen": "Todos",
        "destino": "Kosovo",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "Colombia",
        "origen": "Todos",
        "destino": "Letonia",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Colombia",
        "origen": "Todos",
        "destino": "Liechtenstein",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Colombia",
        "origen": "Todos",
        "destino": "Lituania",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Colombia",
        "origen": "Todos",
        "destino": "Luxemburgo",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Colombia",
        "origen": "Todos",
        "destino": "Macedonia del Norte",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "Colombia",
        "origen": "Todos",
        "destino": "Malta",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Colombia",
        "origen": "Todos",
        "destino": "Marruecos",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "Colombia",
        "origen": "Todos",
        "destino": "Mayotte",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": ""
       },
       {
        "pasaporte": "Colombia",
        "origen": "Todos",
        "destino": "México",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "Colombia",
        "origen": "Todos",
        "destino": "Micronesia",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "Colombia",
        "origen": "Todos",
        "destino": "Moldavia",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "Colombia",
        "origen": "Todos",
        "destino": "Mónaco",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Colombia",
        "origen": "Todos",
        "destino": "Montenegro",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "Colombia",
        "origen": "Todos",
        "destino": "Noruega",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Colombia",
        "origen": "Todos",
        "destino": "Nueva Caledonia",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": ""
       },
       {
        "pasaporte": "Colombia",
        "origen": "Todos",
        "destino": "Países Bajos",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Colombia",
        "origen": "Todos",
        "destino": "Panamá",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "Colombia",
        "origen": "Todos",
        "destino": "Paraguay",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "Colombia",
        "origen": "Todos",
        "destino": "Perú",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "Colombia",
        "origen": "Todos",
        "destino": "Polinesia Francesa",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "Colombia",
        "origen": "Todos",
        "destino": "Polonia",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Colombia",
        "origen": "Todos",
        "destino": "Portugal",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Colombia",
        "origen": "Todos",
        "destino": "Qatar",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Colombia",
        "origen": "Todos",
        "destino": "Reino Unido",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Colombia",
        "origen": "Todos",
        "destino": "República Checa",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Colombia",
        "origen": "Todos",
        "destino": "República Dominicana",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "Colombia",
        "origen": "Todos",
        "destino": "Rumanía",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Colombia",
        "origen": "Todos",
        "destino": "San Cristóbal y Nieves",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": ""
       },
       {
        "pasaporte": "Colombia",
        "origen": "Todos",
        "destino": "San Marino",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Colombia",
        "origen": "Todos",
        "destino": "San Martín",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "Colombia",
        "origen": "Todos",
        "destino": "San Pedro y Miquelón",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "Colombia",
        "origen": "Todos",
        "destino": "San Vicente y las Granadinas",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "Colombia",
        "origen": "Todos",
        "destino": "Serbia",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Colombia",
        "origen": "Todos",
        "destino": "Singapur",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": ""
       },
       {
        "pasaporte": "Colombia",
        "origen": "Todos",
        "destino": "Suecia",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Colombia",
        "origen": "Todos",
        "destino": "Suiza",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Colombia",
        "origen": "Todos",
        "destino": "Surinam",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "Colombia",
        "origen": "Todos",
        "destino": "Tailandia",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "Colombia",
        "origen": "Todos",
        "destino": "Territorios Palestinos",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "Colombia",
        "origen": "Todos",
        "destino": "Trinidad y Tobago",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "Colombia",
        "origen": "Todos",
        "destino": "Turquía",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "Colombia",
        "origen": "Todos",
        "destino": "Ucrania",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "Colombia",
        "origen": "Todos",
        "destino": "Uruguay",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "Colombia",
        "origen": "Todos",
        "destino": "Venezuela",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "Colombia",
        "origen": "Todos",
        "destino": "Wallis y Futuna",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "Colombia",
        "origen": "Todos",
        "destino": "Bahrain",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.99",
        "requisitos": "Visado eléctronico\nPasaporte vigente"
       },
       {
        "pasaporte": "Colombia",
        "origen": "Todos",
        "destino": "Bangladesh",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.99",
        "requisitos": ""
       },
       {
        "pasaporte": "Colombia",
        "origen": "Todos",
        "destino": "Burkina Faso",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.99",
        "requisitos": ""
       },
       {
        "pasaporte": "Colombia",
        "origen": "Todos",
        "destino": "Burundi",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.99",
        "requisitos": ""
       },
       {
        "pasaporte": "Colombia",
        "origen": "Todos",
        "destino": "Cabo Verde",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.99",
        "requisitos": "Visado eléctronico\nPasaporte vigente"
       },
       {
        "pasaporte": "Colombia",
        "origen": "Todos",
        "destino": "Camboya",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.99",
        "requisitos": ""
       },
       {
        "pasaporte": "Colombia",
        "origen": "Todos",
        "destino": "Comoras",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.99",
        "requisitos": ""
       },
       {
        "pasaporte": "Colombia",
        "origen": "Todos",
        "destino": "Djibouti",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.99",
        "requisitos": ""
       },
       {
        "pasaporte": "Colombia",
        "origen": "Todos",
        "destino": "Egipto",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.99",
        "requisitos": "Visado eléctronico\nPasaporte vigente"
       },
       {
        "pasaporte": "Colombia",
        "origen": "Todos",
        "destino": "Guinea-Bissau",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.99",
        "requisitos": ""
       },
       {
        "pasaporte": "Colombia",
        "origen": "Todos",
        "destino": "Indonesia",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.99",
        "requisitos": ""
       },
       {
        "pasaporte": "Colombia",
        "origen": "Todos",
        "destino": "Isla Mauricio",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.99",
        "requisitos": ""
       },
       {
        "pasaporte": "Colombia",
        "origen": "Todos",
        "destino": "Islas Marshall",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.99",
        "requisitos": ""
       },
       {
        "pasaporte": "Colombia",
        "origen": "Todos",
        "destino": "Islas Seychelles",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.99",
        "requisitos": ""
       },
       {
        "pasaporte": "Colombia",
        "origen": "Todos",
        "destino": "Jordania",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.99",
        "requisitos": "Visado eléctronico\nPasaporte vigente"
       },
       {
        "pasaporte": "Colombia",
        "origen": "Todos",
        "destino": "Laos",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.99",
        "requisitos": ""
       },
       {
        "pasaporte": "Colombia",
        "origen": "Todos",
        "destino": "Macao",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.99",
        "requisitos": ""
       },
       {
        "pasaporte": "Colombia",
        "origen": "Todos",
        "destino": "Madagascar",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.99",
        "requisitos": "Visado\nPasaporte vigente"
       },
       {
        "pasaporte": "Colombia",
        "origen": "Todos",
        "destino": "Maldivas",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.99",
        "requisitos": ""
       },
       {
        "pasaporte": "Colombia",
        "origen": "Todos",
        "destino": "Mauritania",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.99",
        "requisitos": ""
       },
       {
        "pasaporte": "Colombia",
        "origen": "Todos",
        "destino": "Mozambique",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.99",
        "requisitos": ""
       },
       {
        "pasaporte": "Colombia",
        "origen": "Todos",
        "destino": "Nepal",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.99",
        "requisitos": ""
       },
       {
        "pasaporte": "Colombia",
        "origen": "Todos",
        "destino": "Nicaragua",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.99",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "Colombia",
        "origen": "Todos",
        "destino": "Omán",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.99",
        "requisitos": ""
       },
       {
        "pasaporte": "Colombia",
        "origen": "Todos",
        "destino": "Palaos",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.99",
        "requisitos": ""
       },
       {
        "pasaporte": "Colombia",
        "origen": "Todos",
        "destino": "Ruanda",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.99",
        "requisitos": ""
       },
       {
        "pasaporte": "Colombia",
        "origen": "Todos",
        "destino": "Samoa",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.99",
        "requisitos": ""
       },
       {
        "pasaporte": "Colombia",
        "origen": "Todos",
        "destino": "Sri Lanka",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.99",
        "requisitos": ""
       },
       {
        "pasaporte": "Colombia",
        "origen": "Todos",
        "destino": "Tanzania",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.99",
        "requisitos": ""
       },
       {
        "pasaporte": "Colombia",
        "origen": "Todos",
        "destino": "Timor Oriental",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.99",
        "requisitos": ""
       },
       {
        "pasaporte": "Colombia",
        "origen": "Todos",
        "destino": "Tuvalu",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.99",
        "requisitos": ""
       },
       {
        "pasaporte": "Colombia",
        "origen": "Todos",
        "destino": "Zambia",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.99",
        "requisitos": ""
       },
       {
        "pasaporte": "Colombia",
        "origen": "Todos",
        "destino": "Armenia",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.95",
        "requisitos": ""
       },
       {
        "pasaporte": "Colombia",
        "origen": "Todos",
        "destino": "Australia",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.95",
        "requisitos": "Visado\nPasaporte vigente"
       },
       {
        "pasaporte": "Colombia",
        "origen": "Todos",
        "destino": "Azerbaiyán",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.95",
        "requisitos": "Visado eléctronico\nPasaporte vigentr"
       },
       {
        "pasaporte": "Colombia",
        "origen": "Todos",
        "destino": "Benín",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.95",
        "requisitos": ""
       },
       {
        "pasaporte": "Colombia",
        "origen": "Todos",
        "destino": "Birmania",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.95",
        "requisitos": ""
       },
       {
        "pasaporte": "Colombia",
        "origen": "Todos",
        "destino": "Botswana",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.95",
        "requisitos": ""
       },
       {
        "pasaporte": "Colombia",
        "origen": "Todos",
        "destino": "Bután",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.95",
        "requisitos": "Visado eléctronico\nPasaporte vigentr"
       },
       {
        "pasaporte": "Colombia",
        "origen": "Todos",
        "destino": "Camerún",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.95",
        "requisitos": ""
       },
       {
        "pasaporte": "Colombia",
        "origen": "Todos",
        "destino": "Costa de Marfil",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.95",
        "requisitos": ""
       },
       {
        "pasaporte": "Colombia",
        "origen": "Todos",
        "destino": "Etiopia",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.95",
        "requisitos": ""
       },
       {
        "pasaporte": "Colombia",
        "origen": "Todos",
        "destino": "Gabón",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.95",
        "requisitos": ""
       },
       {
        "pasaporte": "Colombia",
        "origen": "Todos",
        "destino": "Guinea",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.95",
        "requisitos": ""
       },
       {
        "pasaporte": "Colombia",
        "origen": "Todos",
        "destino": "Guinea Ecuatorial",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.95",
        "requisitos": ""
       },
       {
        "pasaporte": "Colombia",
        "origen": "Todos",
        "destino": "India",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.95",
        "requisitos": "Visado eléctronico\nPasaporte vigentr"
       },
       {
        "pasaporte": "Colombia",
        "origen": "Todos",
        "destino": "Kirguistán",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.95",
        "requisitos": ""
       },
       {
        "pasaporte": "Colombia",
        "origen": "Todos",
        "destino": "Lesoto",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.95",
        "requisitos": ""
       },
       {
        "pasaporte": "Colombia",
        "origen": "Todos",
        "destino": "Libia",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.95",
        "requisitos": ""
       },
       {
        "pasaporte": "Colombia",
        "origen": "Todos",
        "destino": "Malasia",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.95",
        "requisitos": "Visado eléctronico\nPasaporte vigentr"
       },
       {
        "pasaporte": "Colombia",
        "origen": "Todos",
        "destino": "Malawi",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.95",
        "requisitos": ""
       },
       {
        "pasaporte": "Colombia",
        "origen": "Todos",
        "destino": "Mongolia",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.95",
        "requisitos": ""
       },
       {
        "pasaporte": "Colombia",
        "origen": "Todos",
        "destino": "Montserrat",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.95",
        "requisitos": ""
       },
       {
        "pasaporte": "Colombia",
        "origen": "Todos",
        "destino": "Nigeria",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.95",
        "requisitos": "Visado\nPasaporte vigente\nBoleto ida y vuelta\nHospedaje"
       },
       {
        "pasaporte": "Colombia",
        "origen": "Todos",
        "destino": "Pakistán",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.95",
        "requisitos": ""
       },
       {
        "pasaporte": "Colombia",
        "origen": "Todos",
        "destino": "República Democrática del Congo",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.95",
        "requisitos": ""
       },
       {
        "pasaporte": "Colombia",
        "origen": "Todos",
        "destino": "Santa Elena",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.95",
        "requisitos": ""
       },
       {
        "pasaporte": "Colombia",
        "origen": "Todos",
        "destino": "Santo Tomé y Príncipe",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.95",
        "requisitos": ""
       },
       {
        "pasaporte": "Colombia",
        "origen": "Todos",
        "destino": "Sierra Leona",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.95",
        "requisitos": ""
       },
       {
        "pasaporte": "Colombia",
        "origen": "Todos",
        "destino": "Siria",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.95",
        "requisitos": ""
       },
       {
        "pasaporte": "Colombia",
        "origen": "Todos",
        "destino": "Sudán del Sur",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.95",
        "requisitos": ""
       },
       {
        "pasaporte": "Colombia",
        "origen": "Todos",
        "destino": "Taiwán",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.95",
        "requisitos": "Visado eléctronico\nPasaporte vigentr"
       },
       {
        "pasaporte": "Colombia",
        "origen": "Todos",
        "destino": "Tajikistán",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.95",
        "requisitos": ""
       },
       {
        "pasaporte": "Colombia",
        "origen": "Todos",
        "destino": "Togo",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.95",
        "requisitos": "Visado eléctronico\nPasaporte vigentr"
       },
       {
        "pasaporte": "Colombia",
        "origen": "Todos",
        "destino": "Uganda",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.95",
        "requisitos": ""
       },
       {
        "pasaporte": "Colombia",
        "origen": "Todos",
        "destino": "Uzbekistán",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.95",
        "requisitos": ""
       },
       {
        "pasaporte": "Colombia",
        "origen": "Todos",
        "destino": "Vietnam",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.95",
        "requisitos": "Visado eléctronico\nPasaporte vigentr"
       },
       {
        "pasaporte": "Colombia",
        "origen": "Todos",
        "destino": "Zimbabue",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.95",
        "requisitos": ""
       },
       {
        "pasaporte": "Colombia",
        "origen": "Todos",
        "destino": "Kenia",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.95",
        "requisitos": ""
       },
       {
        "pasaporte": "Colombia",
        "origen": "Todos",
        "destino": "Afganistán",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": ""
       },
       {
        "pasaporte": "Colombia",
        "origen": "Todos",
        "destino": "Angola",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado eléctronico\nPasaporte vigentr"
       },
       {
        "pasaporte": "Colombia",
        "origen": "Todos",
        "destino": "Anguilla",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": ""
       },
       {
        "pasaporte": "Colombia",
        "origen": "Todos",
        "destino": "Arabia Saudí",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": ""
       },
       {
        "pasaporte": "Colombia",
        "origen": "Todos",
        "destino": "Argelia",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": ""
       },
       {
        "pasaporte": "Colombia",
        "origen": "Todos",
        "destino": "Bermuda",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": ""
       },
       {
        "pasaporte": "Colombia",
        "origen": "Todos",
        "destino": "Bielorrusia",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado\nPasaporte vigenter\nSeguro médico"
       },
       {
        "pasaporte": "Colombia",
        "origen": "Todos",
        "destino": "Brunei",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": ""
       },
       {
        "pasaporte": "Colombia",
        "origen": "Todos",
        "destino": "Canadá",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado\nPasaporte vigente"
       },
       {
        "pasaporte": "Colombia",
        "origen": "Todos",
        "destino": "Chad",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": ""
       },
       {
        "pasaporte": "Colombia",
        "origen": "Todos",
        "destino": "China",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": ""
       },
       {
        "pasaporte": "Colombia",
        "origen": "Todos",
        "destino": "Corea del Norte",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": ""
       },
       {
        "pasaporte": "Colombia",
        "origen": "Todos",
        "destino": "Costa Rica",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "Colombia",
        "origen": "Todos",
        "destino": "Cuba",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado\nPasaporte vigente\nSeguro médico"
       },
       {
        "pasaporte": "Colombia",
        "origen": "Todos",
        "destino": "Eritrea",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": ""
       },
       {
        "pasaporte": "Colombia",
        "origen": "Todos",
        "destino": "Estados Unidos de América",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado\nPasaporte vigente"
       },
       {
        "pasaporte": "Colombia",
        "origen": "Todos",
        "destino": "Eswatini",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado\nPasaporte vigente"
       },
       {
        "pasaporte": "Colombia",
        "origen": "Todos",
        "destino": "Falkland Islands",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado\nPasaporte vigente"
       },
       {
        "pasaporte": "Colombia",
        "origen": "Todos",
        "destino": "Gambia",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado\nPasaporte vigente"
       },
       {
        "pasaporte": "Colombia",
        "origen": "Todos",
        "destino": "Ghana",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado\nPasaporte vigente"
       },
       {
        "pasaporte": "Colombia",
        "origen": "Todos",
        "destino": "Gibraltar",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado\nPasaporte vigente"
       },
       {
        "pasaporte": "Colombia",
        "origen": "Todos",
        "destino": "Granada",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado\nPasaporte vigente"
       },
       {
        "pasaporte": "Colombia",
        "origen": "Todos",
        "destino": "Guam",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado\nPasaporte vigente"
       },
       {
        "pasaporte": "Colombia",
        "origen": "Todos",
        "destino": "Haití",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado\nPasaporte vigente"
       },
       {
        "pasaporte": "Colombia",
        "origen": "Todos",
        "destino": "Irak",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado\nPasaporte vigente"
       },
       {
        "pasaporte": "Colombia",
        "origen": "Todos",
        "destino": "Irán",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado\nPasaporte vigente"
       },
       {
        "pasaporte": "Colombia",
        "origen": "Todos",
        "destino": "Irlanda",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado\nPasaporte vigente"
       },
       {
        "pasaporte": "Colombia",
        "origen": "Todos",
        "destino": "Isla Norfolk",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": ""
       },
       {
        "pasaporte": "Colombia",
        "origen": "Todos",
        "destino": "Islas Caimán",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado\nPasaporte vigente"
       },
       {
        "pasaporte": "Colombia",
        "origen": "Todos",
        "destino": "Islas Marianas del Norte",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": ""
       },
       {
        "pasaporte": "Colombia",
        "origen": "Todos",
        "destino": "Islas Salomón",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado\nPasaporte vigente"
       },
       {
        "pasaporte": "Colombia",
        "origen": "Todos",
        "destino": "Islas Vírgenes americanas",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado\nPasaporte vigente"
       },
       {
        "pasaporte": "Colombia",
        "origen": "Todos",
        "destino": "Islas Vírgenes Británicas",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado\nPasaporte vigente"
       },
       {
        "pasaporte": "Colombia",
        "origen": "Todos",
        "destino": "Japón",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado\nPasaporte vigente"
       },
       {
        "pasaporte": "Colombia",
        "origen": "Todos",
        "destino": "Kuwait",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": ""
       },
       {
        "pasaporte": "Colombia",
        "origen": "Todos",
        "destino": "Líbano",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": ""
       },
       {
        "pasaporte": "Colombia",
        "origen": "Todos",
        "destino": "Liberia",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": ""
       },
       {
        "pasaporte": "Colombia",
        "origen": "Todos",
        "destino": "Mali",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado\nPasaporte vigente"
       },
       {
        "pasaporte": "Colombia",
        "origen": "Todos",
        "destino": "Namibia",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": ""
       },
       {
        "pasaporte": "Colombia",
        "origen": "Todos",
        "destino": "Nauru",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": ""
       },
       {
        "pasaporte": "Colombia",
        "origen": "Todos",
        "destino": "Níger",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": ""
       },
       {
        "pasaporte": "Colombia",
        "origen": "Todos",
        "destino": "Nueva Zelanda",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado\nPasaporte vigente"
       },
       {
        "pasaporte": "Colombia",
        "origen": "Todos",
        "destino": "Papúa Nueva Guinea",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": ""
       },
       {
        "pasaporte": "Colombia",
        "origen": "Todos",
        "destino": "Puerto Rico",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado\nPasaporte vigente"
       },
       {
        "pasaporte": "Colombia",
        "origen": "Todos",
        "destino": "República Centroafricana",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": ""
       },
       {
        "pasaporte": "Colombia",
        "origen": "Todos",
        "destino": "República del Congo",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": ""
       },
       {
        "pasaporte": "Colombia",
        "origen": "Todos",
        "destino": "Samoa Americana",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": ""
       },
       {
        "pasaporte": "Colombia",
        "origen": "Todos",
        "destino": "Santa Lucía",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": ""
       },
       {
        "pasaporte": "Colombia",
        "origen": "Todos",
        "destino": "Senegal",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": ""
       },
       {
        "pasaporte": "Colombia",
        "origen": "Todos",
        "destino": "Somalia",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado\nPasaporte vigente"
       },
       {
        "pasaporte": "Colombia",
        "origen": "Todos",
        "destino": "Sudáfrica",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado\nPasaporte vigente"
       },
       {
        "pasaporte": "Colombia",
        "origen": "Todos",
        "destino": "Sudán",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": ""
       },
       {
        "pasaporte": "Colombia",
        "origen": "Todos",
        "destino": "Tonga",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": ""
       },
       {
        "pasaporte": "Colombia",
        "origen": "Todos",
        "destino": "Túnez",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": ""
       },
       {
        "pasaporte": "Colombia",
        "origen": "Todos",
        "destino": "Turkmenistán",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": ""
       },
       {
        "pasaporte": "Colombia",
        "origen": "Todos",
        "destino": "Vanuatu",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": ""
       },
       {
        "pasaporte": "Colombia",
        "origen": "Todos",
        "destino": "Yemen",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": ""
       },
       {
        "pasaporte": "Perú",
        "origen": "Todos",
        "destino": "Albania",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Perú",
        "origen": "Todos",
        "destino": "Alemania",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Perú",
        "origen": "Todos",
        "destino": "Andorra",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Perú",
        "origen": "Todos",
        "destino": "Antigua y Barbuda",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": ""
       },
       {
        "pasaporte": "Perú",
        "origen": "Todos",
        "destino": "Argentina",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "Perú",
        "origen": "Todos",
        "destino": "Aruba",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": ""
       },
       {
        "pasaporte": "Perú",
        "origen": "Todos",
        "destino": "Austria",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Perú",
        "origen": "Todos",
        "destino": "Bahamas",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": ""
       },
       {
        "pasaporte": "Perú",
        "origen": "Todos",
        "destino": "Barbados",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": ""
       },
       {
        "pasaporte": "Perú",
        "origen": "Todos",
        "destino": "Bélgica",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Perú",
        "origen": "Todos",
        "destino": "Belice",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "Perú",
        "origen": "Todos",
        "destino": "Bermuda",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": ""
       },
       {
        "pasaporte": "Perú",
        "origen": "Todos",
        "destino": "Bielorrusia",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nSeguro médico"
       },
       {
        "pasaporte": "Perú",
        "origen": "Todos",
        "destino": "Bolivia",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "Perú",
        "origen": "Todos",
        "destino": "Bonaire, San Eustaquio y Saba",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": ""
       },
       {
        "pasaporte": "Perú",
        "origen": "Todos",
        "destino": "Bosnia-Herzegovina",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": ""
       },
       {
        "pasaporte": "Perú",
        "origen": "Todos",
        "destino": "Botswana",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": ""
       },
       {
        "pasaporte": "Perú",
        "origen": "Todos",
        "destino": "Brasil",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "Perú",
        "origen": "Todos",
        "destino": "Brunei",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": ""
       },
       {
        "pasaporte": "Perú",
        "origen": "Todos",
        "destino": "Bulgaria",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Perú",
        "origen": "Todos",
        "destino": "Chile",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "Perú",
        "origen": "Todos",
        "destino": "Chipre",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Perú",
        "origen": "Todos",
        "destino": "Ciudad del Vaticano",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Perú",
        "origen": "Todos",
        "destino": "Colombia",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "Perú",
        "origen": "Todos",
        "destino": "Costa Rica",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "Perú",
        "origen": "Todos",
        "destino": "Croacia",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Perú",
        "origen": "Todos",
        "destino": "Curacao",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "Perú",
        "origen": "Todos",
        "destino": "Dinamarca",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Perú",
        "origen": "Todos",
        "destino": "Dominica",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": ""
       },
       {
        "pasaporte": "Perú",
        "origen": "Todos",
        "destino": "Ecuador",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "Perú",
        "origen": "Todos",
        "destino": "El Salvador",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "Perú",
        "origen": "Todos",
        "destino": "Emiratos Árabes Unidos",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Visado electronico\nPasaporte vigente"
       },
       {
        "pasaporte": "Perú",
        "origen": "Todos",
        "destino": "Eslovaquia",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Perú",
        "origen": "Todos",
        "destino": "Eslovenia",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Perú",
        "origen": "Todos",
        "destino": "España",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Perú",
        "origen": "Todos",
        "destino": "Estonia",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Perú",
        "origen": "Todos",
        "destino": "Federación Rusa",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "Perú",
        "origen": "Todos",
        "destino": "Fiji",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": ""
       },
       {
        "pasaporte": "Perú",
        "origen": "Todos",
        "destino": "Filipinas",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "Perú",
        "origen": "Todos",
        "destino": "Finlandia",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Perú",
        "origen": "Todos",
        "destino": "Francia",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Perú",
        "origen": "Todos",
        "destino": "Georgia",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": ""
       },
       {
        "pasaporte": "Perú",
        "origen": "Todos",
        "destino": "Granada",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": ""
       },
       {
        "pasaporte": "Perú",
        "origen": "Todos",
        "destino": "Grecia",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Perú",
        "origen": "Todos",
        "destino": "Groenlandia",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Perú",
        "origen": "Todos",
        "destino": "Guatemala",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "Perú",
        "origen": "Todos",
        "destino": "Guayana Francesa​​​​",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "Perú",
        "origen": "Todos",
        "destino": "Guyana",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "Perú",
        "origen": "Todos",
        "destino": "Haití",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "Perú",
        "origen": "Todos",
        "destino": "Honduras",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "Perú",
        "origen": "Todos",
        "destino": "Hong Kong",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "Perú",
        "origen": "Todos",
        "destino": "Hungría",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Perú",
        "origen": "Todos",
        "destino": "Irán",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": ""
       },
       {
        "pasaporte": "Perú",
        "origen": "Todos",
        "destino": "Islandia",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Perú",
        "origen": "Todos",
        "destino": "Islas Caimán",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": ""
       },
       {
        "pasaporte": "Perú",
        "origen": "Todos",
        "destino": "Islas Cook",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": ""
       },
       {
        "pasaporte": "Perú",
        "origen": "Todos",
        "destino": "Islas Feroe",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": ""
       },
       {
        "pasaporte": "Perú",
        "origen": "Todos",
        "destino": "Israel",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": ""
       },
       {
        "pasaporte": "Perú",
        "origen": "Todos",
        "destino": "Italia",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Perú",
        "origen": "Todos",
        "destino": "Jamaica",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "Perú",
        "origen": "Todos",
        "destino": "Kiribati",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": ""
       },
       {
        "pasaporte": "Perú",
        "origen": "Todos",
        "destino": "Letonia",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Perú",
        "origen": "Todos",
        "destino": "Liechtenstein",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Perú",
        "origen": "Todos",
        "destino": "Lituania",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Perú",
        "origen": "Todos",
        "destino": "Luxemburgo",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Perú",
        "origen": "Todos",
        "destino": "Macedonia del Norte",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": ""
       },
       {
        "pasaporte": "Perú",
        "origen": "Todos",
        "destino": "Malasia",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "Perú",
        "origen": "Todos",
        "destino": "Malta",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Perú",
        "origen": "Todos",
        "destino": "Marruecos",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "Perú",
        "origen": "Todos",
        "destino": "Micronesia",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": ""
       },
       {
        "pasaporte": "Perú",
        "origen": "Todos",
        "destino": "Moldavia",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": ""
       },
       {
        "pasaporte": "Perú",
        "origen": "Todos",
        "destino": "Mónaco",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Perú",
        "origen": "Todos",
        "destino": "Mongolia",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": ""
       },
       {
        "pasaporte": "Perú",
        "origen": "Todos",
        "destino": "Montenegro",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Perú",
        "origen": "Todos",
        "destino": "Niue",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": ""
       },
       {
        "pasaporte": "Perú",
        "origen": "Todos",
        "destino": "Noruega",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Perú",
        "origen": "Todos",
        "destino": "Nueva Caledonia",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": ""
       },
       {
        "pasaporte": "Perú",
        "origen": "Todos",
        "destino": "Países Bajos",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Perú",
        "origen": "Todos",
        "destino": "Panamá",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "Perú",
        "origen": "Todos",
        "destino": "Paraguay",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "Perú",
        "origen": "Todos",
        "destino": "Polonia",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Perú",
        "origen": "Todos",
        "destino": "Portugal",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Perú",
        "origen": "Todos",
        "destino": "Qatar",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "Perú",
        "origen": "Todos",
        "destino": "Reino Unido",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Autorización ETA\nPasaporte vigente"
       },
       {
        "pasaporte": "Perú",
        "origen": "Todos",
        "destino": "República Checa",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Perú",
        "origen": "Todos",
        "destino": "República Dominicana",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "Perú",
        "origen": "Todos",
        "destino": "Rumanía",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Perú",
        "origen": "Todos",
        "destino": "San Cristóbal y Nieves",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": ""
       },
       {
        "pasaporte": "Perú",
        "origen": "Todos",
        "destino": "San Marino",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Perú",
        "origen": "Todos",
        "destino": "San Pedro y Miquelón",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": ""
       },
       {
        "pasaporte": "Perú",
        "origen": "Todos",
        "destino": "San Vicente y las Granadinas",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": ""
       },
       {
        "pasaporte": "Perú",
        "origen": "Todos",
        "destino": "Serbia",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Perú",
        "origen": "Todos",
        "destino": "Singapur",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "Perú",
        "origen": "Todos",
        "destino": "Sudáfrica",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "Perú",
        "origen": "Todos",
        "destino": "Suecia",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Perú",
        "origen": "Todos",
        "destino": "Suiza",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Perú",
        "origen": "Todos",
        "destino": "Surinam",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": ""
       },
       {
        "pasaporte": "Perú",
        "origen": "Todos",
        "destino": "Tailandia",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "Perú",
        "origen": "Todos",
        "destino": "Territorios Palestinos",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": ""
       },
       {
        "pasaporte": "Perú",
        "origen": "Todos",
        "destino": "Trinidad y Tobago",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "Perú",
        "origen": "Todos",
        "destino": "Turquía",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "Perú",
        "origen": "Todos",
        "destino": "Ucrania",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": ""
       },
       {
        "pasaporte": "Perú",
        "origen": "Todos",
        "destino": "Uruguay",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "Perú",
        "origen": "Todos",
        "destino": "Vanuatu",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": ""
       },
       {
        "pasaporte": "Perú",
        "origen": "Todos",
        "destino": "Wallis y Futuna",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": ""
       },
       {
        "pasaporte": "Perú",
        "origen": "Todos",
        "destino": "Armenia",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.97",
        "requisitos": "Visado eléctronico\nPasaporte vigente"
       },
       {
        "pasaporte": "Perú",
        "origen": "Todos",
        "destino": "Bahrain",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.97",
        "requisitos": "Visado eléctronico\nPasaporte vigente"
       },
       {
        "pasaporte": "Perú",
        "origen": "Todos",
        "destino": "Bangladesh",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.97",
        "requisitos": ""
       },
       {
        "pasaporte": "Perú",
        "origen": "Todos",
        "destino": "Burundi",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.97",
        "requisitos": ""
       },
       {
        "pasaporte": "Perú",
        "origen": "Todos",
        "destino": "Cabo Verde",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.97",
        "requisitos": "Visado eléctronico\nPasaporte vigente"
       },
       {
        "pasaporte": "Perú",
        "origen": "Todos",
        "destino": "Camboya",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.97",
        "requisitos": ""
       },
       {
        "pasaporte": "Perú",
        "origen": "Todos",
        "destino": "Comoras",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.97",
        "requisitos": ""
       },
       {
        "pasaporte": "Perú",
        "origen": "Todos",
        "destino": "Djibouti",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.97",
        "requisitos": ""
       },
       {
        "pasaporte": "Perú",
        "origen": "Todos",
        "destino": "Egipto",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.97",
        "requisitos": "Visado eléctronico\nPasaporte vigente"
       },
       {
        "pasaporte": "Perú",
        "origen": "Todos",
        "destino": "Guinea-Bissau",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.97",
        "requisitos": ""
       },
       {
        "pasaporte": "Perú",
        "origen": "Todos",
        "destino": "Indonesia",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.97",
        "requisitos": "Visado eléctronico\nPasaporte vigente"
       },
       {
        "pasaporte": "Perú",
        "origen": "Todos",
        "destino": "Isla Mauricio",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.97",
        "requisitos": ""
       },
       {
        "pasaporte": "Perú",
        "origen": "Todos",
        "destino": "Islas Marshall",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.97",
        "requisitos": ""
       },
       {
        "pasaporte": "Perú",
        "origen": "Todos",
        "destino": "Islas Seychelles",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.97",
        "requisitos": ""
       },
       {
        "pasaporte": "Perú",
        "origen": "Todos",
        "destino": "Jordania",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.97",
        "requisitos": "Visado\nPasaporte vigente"
       },
       {
        "pasaporte": "Perú",
        "origen": "Todos",
        "destino": "Laos",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.97",
        "requisitos": "Visado\nPasaporte vigente"
       },
       {
        "pasaporte": "Perú",
        "origen": "Todos",
        "destino": "Líbano",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.97",
        "requisitos": ""
       },
       {
        "pasaporte": "Perú",
        "origen": "Todos",
        "destino": "Macao",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.97",
        "requisitos": "Visado\nPasaporte vigente"
       },
       {
        "pasaporte": "Perú",
        "origen": "Todos",
        "destino": "Madagascar",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.97",
        "requisitos": "Visado\nPasaporte vigente"
       },
       {
        "pasaporte": "Perú",
        "origen": "Todos",
        "destino": "Malawi",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.97",
        "requisitos": ""
       },
       {
        "pasaporte": "Perú",
        "origen": "Todos",
        "destino": "Maldivas",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.97",
        "requisitos": "Visado\nAlojamiento\nPasaporte Vigente"
       },
       {
        "pasaporte": "Perú",
        "origen": "Todos",
        "destino": "Mauritania",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.97",
        "requisitos": ""
       },
       {
        "pasaporte": "Perú",
        "origen": "Todos",
        "destino": "Mozambique",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.97",
        "requisitos": ""
       },
       {
        "pasaporte": "Perú",
        "origen": "Todos",
        "destino": "Namibia",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.97",
        "requisitos": ""
       },
       {
        "pasaporte": "Perú",
        "origen": "Todos",
        "destino": "Nepal",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.97",
        "requisitos": ""
       },
       {
        "pasaporte": "Perú",
        "origen": "Todos",
        "destino": "Nicaragua",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.97",
        "requisitos": "Visado\nPasaporte Vigente\nVacuna Fiebre Amarilla"
       },
       {
        "pasaporte": "Perú",
        "origen": "Todos",
        "destino": "Palaos",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.97",
        "requisitos": ""
       },
       {
        "pasaporte": "Perú",
        "origen": "Todos",
        "destino": "Ruanda",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.97",
        "requisitos": ""
       },
       {
        "pasaporte": "Perú",
        "origen": "Todos",
        "destino": "Samoa",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.97",
        "requisitos": "Visado\nBoleto ida y vuelta\nAlojamiento"
       },
       {
        "pasaporte": "Perú",
        "origen": "Todos",
        "destino": "Santa Lucía",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.97",
        "requisitos": ""
       },
       {
        "pasaporte": "Perú",
        "origen": "Todos",
        "destino": "Sri Lanka",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.97",
        "requisitos": ""
       },
       {
        "pasaporte": "Perú",
        "origen": "Todos",
        "destino": "Tanzania",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.97",
        "requisitos": ""
       },
       {
        "pasaporte": "Perú",
        "origen": "Todos",
        "destino": "Timor Oriental",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.97",
        "requisitos": ""
       },
       {
        "pasaporte": "Perú",
        "origen": "Todos",
        "destino": "Tuvalu",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.97",
        "requisitos": ""
       },
       {
        "pasaporte": "Perú",
        "origen": "Todos",
        "destino": "Zambia",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.97",
        "requisitos": ""
       },
       {
        "pasaporte": "Perú",
        "origen": "Todos",
        "destino": "Zimbabue",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.97",
        "requisitos": ""
       },
       {
        "pasaporte": "Perú",
        "origen": "Todos",
        "destino": "Corea del Sur",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.97",
        "requisitos": ""
       },
       {
        "pasaporte": "Perú",
        "origen": "Todos",
        "destino": "Kenia",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.97",
        "requisitos": ""
       },
       {
        "pasaporte": "Perú",
        "origen": "Todos",
        "destino": "Australia",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.95",
        "requisitos": "Visado\nPasaporte vigente\nVacuna fiebre amarilla"
       },
       {
        "pasaporte": "Perú",
        "origen": "Todos",
        "destino": "Azerbaiyán",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.95",
        "requisitos": "Visado eléctronico\nPasaporte vigente"
       },
       {
        "pasaporte": "Perú",
        "origen": "Todos",
        "destino": "Benín",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.95",
        "requisitos": ""
       },
       {
        "pasaporte": "Perú",
        "origen": "Todos",
        "destino": "Birmania",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.95",
        "requisitos": ""
       },
       {
        "pasaporte": "Perú",
        "origen": "Todos",
        "destino": "Burkina Faso",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.95",
        "requisitos": ""
       },
       {
        "pasaporte": "Perú",
        "origen": "Todos",
        "destino": "Bután",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.95",
        "requisitos": "Visado eléctronico\nPasaporte vigente"
       },
       {
        "pasaporte": "Perú",
        "origen": "Todos",
        "destino": "Camerún",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.95",
        "requisitos": "Visado eléctronico\nPasaporte vigente"
       },
       {
        "pasaporte": "Perú",
        "origen": "Todos",
        "destino": "Costa de Marfil",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.95",
        "requisitos": ""
       },
       {
        "pasaporte": "Perú",
        "origen": "Todos",
        "destino": "Etiopia",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.95",
        "requisitos": ""
       },
       {
        "pasaporte": "Perú",
        "origen": "Todos",
        "destino": "Gabón",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.95",
        "requisitos": ""
       },
       {
        "pasaporte": "Perú",
        "origen": "Todos",
        "destino": "Guinea",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.95",
        "requisitos": ""
       },
       {
        "pasaporte": "Perú",
        "origen": "Todos",
        "destino": "Guinea Ecuatorial",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.95",
        "requisitos": ""
       },
       {
        "pasaporte": "Perú",
        "origen": "Todos",
        "destino": "India",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.95",
        "requisitos": "Visado eléctronico\nPasaporte vigente"
       },
       {
        "pasaporte": "Perú",
        "origen": "Todos",
        "destino": "Kazajistán",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.95",
        "requisitos": ""
       },
       {
        "pasaporte": "Perú",
        "origen": "Todos",
        "destino": "Kirguistán",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.95",
        "requisitos": ""
       },
       {
        "pasaporte": "Perú",
        "origen": "Todos",
        "destino": "Lesoto",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.95",
        "requisitos": ""
       },
       {
        "pasaporte": "Perú",
        "origen": "Todos",
        "destino": "Libia",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.95",
        "requisitos": ""
       },
       {
        "pasaporte": "Perú",
        "origen": "Todos",
        "destino": "Montserrat",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.95",
        "requisitos": ""
       },
       {
        "pasaporte": "Perú",
        "origen": "Todos",
        "destino": "Nigeria",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.95",
        "requisitos": "Visado\nPasaporte vigente"
       },
       {
        "pasaporte": "Perú",
        "origen": "Todos",
        "destino": "Omán",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.95",
        "requisitos": "Visado eléctronico\nPasaporte vigente"
       },
       {
        "pasaporte": "Perú",
        "origen": "Todos",
        "destino": "Pakistán",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.95",
        "requisitos": ""
       },
       {
        "pasaporte": "Perú",
        "origen": "Todos",
        "destino": "Papúa Nueva Guinea",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.95",
        "requisitos": ""
       },
       {
        "pasaporte": "Perú",
        "origen": "Todos",
        "destino": "República Democrática del Congo",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.95",
        "requisitos": ""
       },
       {
        "pasaporte": "Perú",
        "origen": "Todos",
        "destino": "Santa Elena",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.95",
        "requisitos": ""
       },
       {
        "pasaporte": "Perú",
        "origen": "Todos",
        "destino": "Santo Tomé y Príncipe",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.95",
        "requisitos": ""
       },
       {
        "pasaporte": "Perú",
        "origen": "Todos",
        "destino": "Siria",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.95",
        "requisitos": "Visado eléctronico\nPasaporte vigente"
       },
       {
        "pasaporte": "Perú",
        "origen": "Todos",
        "destino": "Sudán del Sur",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.95",
        "requisitos": ""
       },
       {
        "pasaporte": "Perú",
        "origen": "Todos",
        "destino": "Taiwán",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.95",
        "requisitos": "Visado eléctronico\nPasaporte vigente"
       },
       {
        "pasaporte": "Perú",
        "origen": "Todos",
        "destino": "Tajikistán",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.95",
        "requisitos": ""
       },
       {
        "pasaporte": "Perú",
        "origen": "Todos",
        "destino": "Togo",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.95",
        "requisitos": "Visado eléctronico\nPasaporte vigente"
       },
       {
        "pasaporte": "Perú",
        "origen": "Todos",
        "destino": "Uganda",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.95",
        "requisitos": ""
       },
       {
        "pasaporte": "Perú",
        "origen": "Todos",
        "destino": "Uzbekistán",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.95",
        "requisitos": ""
       },
       {
        "pasaporte": "Perú",
        "origen": "Todos",
        "destino": "Vietnam",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.95",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Perú",
        "origen": "Todos",
        "destino": "Afganistán",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": ""
       },
       {
        "pasaporte": "Perú",
        "origen": "Todos",
        "destino": "Angola",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": ""
       },
       {
        "pasaporte": "Perú",
        "origen": "Todos",
        "destino": "Anguilla",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": ""
       },
       {
        "pasaporte": "Perú",
        "origen": "Todos",
        "destino": "Arabia Saudí",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": ""
       },
       {
        "pasaporte": "Perú",
        "origen": "Todos",
        "destino": "Argelia",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": ""
       },
       {
        "pasaporte": "Perú",
        "origen": "Todos",
        "destino": "Canadá",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado\nPasaporte vigente"
       },
       {
        "pasaporte": "Perú",
        "origen": "Todos",
        "destino": "Chad",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": ""
       },
       {
        "pasaporte": "Perú",
        "origen": "Todos",
        "destino": "China",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": ""
       },
       {
        "pasaporte": "Perú",
        "origen": "Todos",
        "destino": "Corea del Norte",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": ""
       },
       {
        "pasaporte": "Perú",
        "origen": "Todos",
        "destino": "Cuba",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado\nPasaporte vigente\nSeguro médico"
       },
       {
        "pasaporte": "Perú",
        "origen": "Todos",
        "destino": "Eritrea",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": ""
       },
       {
        "pasaporte": "Perú",
        "origen": "Todos",
        "destino": "Estados Unidos de América",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado\nPasaporte vigente"
       },
       {
        "pasaporte": "Perú",
        "origen": "Todos",
        "destino": "Eswatini",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": ""
       },
       {
        "pasaporte": "Perú",
        "origen": "Todos",
        "destino": "Falkland Islands",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": ""
       },
       {
        "pasaporte": "Perú",
        "origen": "Todos",
        "destino": "Gambia",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": ""
       },
       {
        "pasaporte": "Perú",
        "origen": "Todos",
        "destino": "Ghana",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": ""
       },
       {
        "pasaporte": "Perú",
        "origen": "Todos",
        "destino": "Gibraltar",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": ""
       },
       {
        "pasaporte": "Perú",
        "origen": "Todos",
        "destino": "Guam",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": ""
       },
       {
        "pasaporte": "Perú",
        "origen": "Todos",
        "destino": "Indias Occidentales Francesas",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": ""
       },
       {
        "pasaporte": "Perú",
        "origen": "Todos",
        "destino": "Irak",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": ""
       },
       {
        "pasaporte": "Perú",
        "origen": "Todos",
        "destino": "Irlanda",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado\nPasaporte vigente"
       },
       {
        "pasaporte": "Perú",
        "origen": "Todos",
        "destino": "Isla Norfolk",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": ""
       },
       {
        "pasaporte": "Perú",
        "origen": "Todos",
        "destino": "Isla Reunión",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": ""
       },
       {
        "pasaporte": "Perú",
        "origen": "Todos",
        "destino": "Islas Marianas del Norte",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": ""
       },
       {
        "pasaporte": "Perú",
        "origen": "Todos",
        "destino": "Islas Salomón",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": ""
       },
       {
        "pasaporte": "Perú",
        "origen": "Todos",
        "destino": "Islas Turcas y Caicos",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": ""
       },
       {
        "pasaporte": "Perú",
        "origen": "Todos",
        "destino": "Islas Vírgenes americanas",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": ""
       },
       {
        "pasaporte": "Perú",
        "origen": "Todos",
        "destino": "Islas Vírgenes Británicas",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": ""
       },
       {
        "pasaporte": "Perú",
        "origen": "Todos",
        "destino": "Japón",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado\nPasaporte vigente"
       },
       {
        "pasaporte": "Perú",
        "origen": "Todos",
        "destino": "Kosovo",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": ""
       },
       {
        "pasaporte": "Perú",
        "origen": "Todos",
        "destino": "Kuwait",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": ""
       },
       {
        "pasaporte": "Perú",
        "origen": "Todos",
        "destino": "Liberia",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": ""
       },
       {
        "pasaporte": "Perú",
        "origen": "Todos",
        "destino": "Mali",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": ""
       },
       {
        "pasaporte": "Perú",
        "origen": "Todos",
        "destino": "Mayotte",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": ""
       },
       {
        "pasaporte": "Perú",
        "origen": "Todos",
        "destino": "México",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado\nPasaporte Vigente"
       },
       {
        "pasaporte": "Perú",
        "origen": "Todos",
        "destino": "Nauru",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Boleto ida y vuelta\nAlojamiento"
       },
       {
        "pasaporte": "Perú",
        "origen": "Todos",
        "destino": "Níger",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": ""
       },
       {
        "pasaporte": "Perú",
        "origen": "Todos",
        "destino": "Nueva Zelanda",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado\nPasaporte vigente"
       },
       {
        "pasaporte": "Perú",
        "origen": "Todos",
        "destino": "Polinesia Francesa",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": ""
       },
       {
        "pasaporte": "Perú",
        "origen": "Todos",
        "destino": "Puerto Rico",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado\nPasaporte vigente"
       },
       {
        "pasaporte": "Perú",
        "origen": "Todos",
        "destino": "República Centroafricana",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": ""
       },
       {
        "pasaporte": "Perú",
        "origen": "Todos",
        "destino": "República del Congo",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": ""
       },
       {
        "pasaporte": "Perú",
        "origen": "Todos",
        "destino": "Samoa Americana",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": ""
       },
       {
        "pasaporte": "Perú",
        "origen": "Todos",
        "destino": "San Martín",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": ""
       },
       {
        "pasaporte": "Perú",
        "origen": "Todos",
        "destino": "Senegal",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado\nPasaporte vigente"
       },
       {
        "pasaporte": "Perú",
        "origen": "Todos",
        "destino": "Sierra Leona",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado\nPasaporte vigente"
       },
       {
        "pasaporte": "Perú",
        "origen": "Todos",
        "destino": "Somalia",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": ""
       },
       {
        "pasaporte": "Perú",
        "origen": "Todos",
        "destino": "Sudán",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": ""
       },
       {
        "pasaporte": "Perú",
        "origen": "Todos",
        "destino": "Tonga",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": ""
       },
       {
        "pasaporte": "Perú",
        "origen": "Todos",
        "destino": "Túnez",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": ""
       },
       {
        "pasaporte": "Perú",
        "origen": "Todos",
        "destino": "Turkmenistán",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": ""
       },
       {
        "pasaporte": "Perú",
        "origen": "Todos",
        "destino": "Venezuela",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado\nPasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "Perú",
        "origen": "Todos",
        "destino": "Yemen",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": ""
       },
       {
        "pasaporte": "México",
        "origen": "Todos",
        "destino": "Albania",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": ""
       },
       {
        "pasaporte": "México",
        "origen": "Todos",
        "destino": "Alemania",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "México",
        "origen": "Todos",
        "destino": "Andorra",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "México",
        "origen": "Todos",
        "destino": "Angola",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": ""
       },
       {
        "pasaporte": "México",
        "origen": "Todos",
        "destino": "Anguilla",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": ""
       },
       {
        "pasaporte": "México",
        "origen": "Todos",
        "destino": "Antigua y Barbuda",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": ""
       },
       {
        "pasaporte": "México",
        "origen": "Todos",
        "destino": "Argentina",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "México",
        "origen": "Todos",
        "destino": "Aruba",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "México",
        "origen": "Todos",
        "destino": "Austria",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "México",
        "origen": "Todos",
        "destino": "Bahamas",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "México",
        "origen": "Todos",
        "destino": "Barbados",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "México",
        "origen": "Todos",
        "destino": "Bélgica",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "México",
        "origen": "Todos",
        "destino": "Belice",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": ""
       },
       {
        "pasaporte": "México",
        "origen": "Todos",
        "destino": "Bermuda",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": ""
       },
       {
        "pasaporte": "México",
        "origen": "Todos",
        "destino": "Bielorrusia",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": ""
       },
       {
        "pasaporte": "México",
        "origen": "Todos",
        "destino": "Bolivia",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "México",
        "origen": "Todos",
        "destino": "Bonaire, San Eustaquio y Saba",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": ""
       },
       {
        "pasaporte": "México",
        "origen": "Todos",
        "destino": "Bosnia-Herzegovina",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": ""
       },
       {
        "pasaporte": "México",
        "origen": "Todos",
        "destino": "Botswana",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": ""
       },
       {
        "pasaporte": "México",
        "origen": "Todos",
        "destino": "Brasil",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "México",
        "origen": "Todos",
        "destino": "Bulgaria",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "México",
        "origen": "Todos",
        "destino": "Chile",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "México",
        "origen": "Todos",
        "destino": "Chipre",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "México",
        "origen": "Todos",
        "destino": "Ciudad del Vaticano",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "México",
        "origen": "Todos",
        "destino": "Colombia",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "México",
        "origen": "Todos",
        "destino": "Costa Rica",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "México",
        "origen": "Todos",
        "destino": "Croacia",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": ""
       },
       {
        "pasaporte": "México",
        "origen": "Todos",
        "destino": "Curaçao",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "México",
        "origen": "Todos",
        "destino": "Dinamarca",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "México",
        "origen": "Todos",
        "destino": "Dominica",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": ""
       },
       {
        "pasaporte": "México",
        "origen": "Todos",
        "destino": "Ecuador",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "México",
        "origen": "Todos",
        "destino": "El Salvador",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "México",
        "origen": "Todos",
        "destino": "Emiratos Árabes Unidos",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": ""
       },
       {
        "pasaporte": "México",
        "origen": "Todos",
        "destino": "Eslovaquia",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "México",
        "origen": "Todos",
        "destino": "Eslovenia",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "México",
        "origen": "Todos",
        "destino": "España",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "México",
        "origen": "Todos",
        "destino": "Estonia",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "México",
        "origen": "Todos",
        "destino": "Fiji",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": ""
       },
       {
        "pasaporte": "México",
        "origen": "Todos",
        "destino": "Filipinas",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": ""
       },
       {
        "pasaporte": "México",
        "origen": "Todos",
        "destino": "Finlandia",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "México",
        "origen": "Todos",
        "destino": "Francia",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "México",
        "origen": "Todos",
        "destino": "Georgia",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": ""
       },
       {
        "pasaporte": "México",
        "origen": "Todos",
        "destino": "Gibraltar",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": ""
       },
       {
        "pasaporte": "México",
        "origen": "Todos",
        "destino": "Granada",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": ""
       },
       {
        "pasaporte": "México",
        "origen": "Todos",
        "destino": "Grecia",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "México",
        "origen": "Todos",
        "destino": "Groenlandia",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "México",
        "origen": "Todos",
        "destino": "Guatemala",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "México",
        "origen": "Todos",
        "destino": "Guayana Francesa​​​​",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": ""
       },
       {
        "pasaporte": "México",
        "origen": "Todos",
        "destino": "Haití",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "México",
        "origen": "Todos",
        "destino": "Honduras",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "México",
        "origen": "Todos",
        "destino": "Hong Kong",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "México",
        "origen": "Todos",
        "destino": "Hungría",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "México",
        "origen": "Todos",
        "destino": "Indias Occidentales Francesas",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": ""
       },
       {
        "pasaporte": "México",
        "origen": "Todos",
        "destino": "Irán",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": ""
       },
       {
        "pasaporte": "México",
        "origen": "Todos",
        "destino": "Irlanda",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "México",
        "origen": "Todos",
        "destino": "Isla Mauricio",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": ""
       },
       {
        "pasaporte": "México",
        "origen": "Todos",
        "destino": "Isla Reunión",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": ""
       },
       {
        "pasaporte": "México",
        "origen": "Todos",
        "destino": "Islandia",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "México",
        "origen": "Todos",
        "destino": "Islas Caimán",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": ""
       },
       {
        "pasaporte": "México",
        "origen": "Todos",
        "destino": "Islas Cook",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": ""
       },
       {
        "pasaporte": "México",
        "origen": "Todos",
        "destino": "Islas Feroe",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": ""
       },
       {
        "pasaporte": "México",
        "origen": "Todos",
        "destino": "Islas Turcas y Caicos",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": ""
       },
       {
        "pasaporte": "México",
        "origen": "Todos",
        "destino": "Islas Vírgenes Británicas",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": ""
       },
       {
        "pasaporte": "México",
        "origen": "Todos",
        "destino": "Israel",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "México",
        "origen": "Todos",
        "destino": "Italia",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "México",
        "origen": "Todos",
        "destino": "Jamaica",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "México",
        "origen": "Todos",
        "destino": "Japón",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "México",
        "origen": "Todos",
        "destino": "Kazajistán",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "México",
        "origen": "Todos",
        "destino": "Kirguistán",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "México",
        "origen": "Todos",
        "destino": "Kiribati",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "México",
        "origen": "Todos",
        "destino": "Kosovo",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "México",
        "origen": "Todos",
        "destino": "Letonia",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "México",
        "origen": "Todos",
        "destino": "Liechtenstein",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "México",
        "origen": "Todos",
        "destino": "Lituania",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "México",
        "origen": "Todos",
        "destino": "Luxemburgo",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "México",
        "origen": "Todos",
        "destino": "Macao",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "México",
        "origen": "Todos",
        "destino": "Macedonia del Norte",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "México",
        "origen": "Todos",
        "destino": "Malasia",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "México",
        "origen": "Todos",
        "destino": "Malta",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "México",
        "origen": "Todos",
        "destino": "Marruecos",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "México",
        "origen": "Todos",
        "destino": "Mayotte",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "México",
        "origen": "Todos",
        "destino": "Micronesia",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "México",
        "origen": "Todos",
        "destino": "Moldavia",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "México",
        "origen": "Todos",
        "destino": "Mónaco",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "México",
        "origen": "Todos",
        "destino": "Montenegro",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "México",
        "origen": "Todos",
        "destino": "Montserrat",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "México",
        "origen": "Todos",
        "destino": "Nicaragua",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "México",
        "origen": "Todos",
        "destino": "Noruega",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "México",
        "origen": "Todos",
        "destino": "Nueva Caledonia",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "México",
        "origen": "Todos",
        "destino": "Países Bajos",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "México",
        "origen": "Todos",
        "destino": "Panamá",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "México",
        "origen": "Todos",
        "destino": "Paraguay",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "México",
        "origen": "Todos",
        "destino": "Perú",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "México",
        "origen": "Todos",
        "destino": "Polinesia Francesa",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "México",
        "origen": "Todos",
        "destino": "Polonia",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "México",
        "origen": "Todos",
        "destino": "Portugal",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "México",
        "origen": "Todos",
        "destino": "Reino Unido",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "México",
        "origen": "Todos",
        "destino": "República Checa",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "México",
        "origen": "Todos",
        "destino": "República Dominicana",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "México",
        "origen": "Todos",
        "destino": "Rumanía",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "México",
        "origen": "Todos",
        "destino": "San Cristóbal y Nieves",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "México",
        "origen": "Todos",
        "destino": "San Marino",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "México",
        "origen": "Todos",
        "destino": "San Martín",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "México",
        "origen": "Todos",
        "destino": "San Pedro y Miquelón",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "México",
        "origen": "Todos",
        "destino": "San Vicente y las Granadinas",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "México",
        "origen": "Todos",
        "destino": "Santa Elena",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "México",
        "origen": "Todos",
        "destino": "Santa Lucía",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "México",
        "origen": "Todos",
        "destino": "Serbia",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "México",
        "origen": "Todos",
        "destino": "Singapur",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "México",
        "origen": "Todos",
        "destino": "Suecia",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "México",
        "origen": "Todos",
        "destino": "Suiza",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "México",
        "origen": "Todos",
        "destino": "Surinam",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "México",
        "origen": "Todos",
        "destino": "Tailandia",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "México",
        "origen": "Todos",
        "destino": "Tajikistán",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "México",
        "origen": "Todos",
        "destino": "Territorios Palestinos",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "México",
        "origen": "Todos",
        "destino": "Trinidad y Tobago",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "México",
        "origen": "Todos",
        "destino": "Túnez",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "México",
        "origen": "Todos",
        "destino": "Uruguay",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "México",
        "origen": "Todos",
        "destino": "Uzbekistán",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "México",
        "origen": "Todos",
        "destino": "Vanuatu",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "México",
        "origen": "Todos",
        "destino": "Venezuela",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "México",
        "origen": "Todos",
        "destino": "Wallis y Futuna",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "México",
        "origen": "Todos",
        "destino": "Armenia",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.97",
        "requisitos": ""
       },
       {
        "pasaporte": "México",
        "origen": "Todos",
        "destino": "Bahrain",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.97",
        "requisitos": "Visado eléctronico\nPasaporte vigente"
       },
       {
        "pasaporte": "México",
        "origen": "Todos",
        "destino": "Etiopia",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.97",
        "requisitos": ""
       },
       {
        "pasaporte": "México",
        "origen": "Todos",
        "destino": "Burundi",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.97",
        "requisitos": ""
       },
       {
        "pasaporte": "México",
        "origen": "Todos",
        "destino": "Cabo Verde",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.97",
        "requisitos": "Visado eléctronico\nPasaporte vigente"
       },
       {
        "pasaporte": "México",
        "origen": "Todos",
        "destino": "Camboya",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.97",
        "requisitos": ""
       },
       {
        "pasaporte": "México",
        "origen": "Todos",
        "destino": "Comoras",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.97",
        "requisitos": ""
       },
       {
        "pasaporte": "México",
        "origen": "Todos",
        "destino": "Djibouti",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.97",
        "requisitos": ""
       },
       {
        "pasaporte": "México",
        "origen": "Todos",
        "destino": "Egipto",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.97",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "México",
        "origen": "Todos",
        "destino": "Guinea-Bissau",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.97",
        "requisitos": ""
       },
       {
        "pasaporte": "México",
        "origen": "Todos",
        "destino": "Indonesia",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.97",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "México",
        "origen": "Todos",
        "destino": "Islas Marshall",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.97",
        "requisitos": ""
       },
       {
        "pasaporte": "México",
        "origen": "Todos",
        "destino": "Islas Seychelles",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.97",
        "requisitos": ""
       },
       {
        "pasaporte": "México",
        "origen": "Todos",
        "destino": "Jordania",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.97",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "México",
        "origen": "Todos",
        "destino": "Laos",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.97",
        "requisitos": ""
       },
       {
        "pasaporte": "México",
        "origen": "Todos",
        "destino": "Líbano",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.97",
        "requisitos": ""
       },
       {
        "pasaporte": "México",
        "origen": "Todos",
        "destino": "Niue",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.97",
        "requisitos": ""
       },
       {
        "pasaporte": "México",
        "origen": "Todos",
        "destino": "Madagascar",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.97",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "México",
        "origen": "Todos",
        "destino": "Malawi",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.97",
        "requisitos": ""
       },
       {
        "pasaporte": "México",
        "origen": "Todos",
        "destino": "Maldivas",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.97",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "México",
        "origen": "Todos",
        "destino": "Mauritania",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.97",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "México",
        "origen": "Todos",
        "destino": "Mozambique",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.97",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "México",
        "origen": "Todos",
        "destino": "Namibia",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.97",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "México",
        "origen": "Todos",
        "destino": "Nepal",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.97",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "México",
        "origen": "Todos",
        "destino": "Palaos",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.97",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "México",
        "origen": "Todos",
        "destino": "Qatar",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.97",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "México",
        "origen": "Todos",
        "destino": "Ruanda",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.97",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "México",
        "origen": "Todos",
        "destino": "Samoa",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.97",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "México",
        "origen": "Todos",
        "destino": "Sri Lanka",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.97",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "México",
        "origen": "Todos",
        "destino": "Tanzania",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.97",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "México",
        "origen": "Todos",
        "destino": "Timor Oriental",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.97",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "México",
        "origen": "Todos",
        "destino": "Tuvalu",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.97",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "México",
        "origen": "Todos",
        "destino": "Zambia",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.97",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "México",
        "origen": "Todos",
        "destino": "Zimbabue",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.97",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "México",
        "origen": "Todos",
        "destino": "Corea del Sur",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.97",
        "requisitos": "Autorización ETA\nPasaporte vigente"
       },
       {
        "pasaporte": "México",
        "origen": "Todos",
        "destino": "Nueva Zelanda",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.97",
        "requisitos": "Autorización ETA\nPasaporte vigente"
       },
       {
        "pasaporte": "México",
        "origen": "Todos",
        "destino": "Kenia",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.97",
        "requisitos": "Autorización ETA\nPasaporte vigente"
       },
       {
        "pasaporte": "México",
        "origen": "Todos",
        "destino": "Afganistán",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": ""
       },
       {
        "pasaporte": "México",
        "origen": "Todos",
        "destino": "Arabia Saudí",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": ""
       },
       {
        "pasaporte": "México",
        "origen": "Todos",
        "destino": "Argelia",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": ""
       },
       {
        "pasaporte": "México",
        "origen": "Todos",
        "destino": "Australia",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado\nPasaporte vigente"
       },
       {
        "pasaporte": "México",
        "origen": "Todos",
        "destino": "Azerbaijan",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado eléctronico\nPasaporte vigente"
       },
       {
        "pasaporte": "México",
        "origen": "Todos",
        "destino": "Bangladesh",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": ""
       },
       {
        "pasaporte": "México",
        "origen": "Todos",
        "destino": "Benín",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": ""
       },
       {
        "pasaporte": "México",
        "origen": "Todos",
        "destino": "Brunei",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": ""
       },
       {
        "pasaporte": "México",
        "origen": "Todos",
        "destino": "Burkina Faso",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": ""
       },
       {
        "pasaporte": "México",
        "origen": "Todos",
        "destino": "Bután",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": ""
       },
       {
        "pasaporte": "México",
        "origen": "Todos",
        "destino": "Camerún",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": ""
       },
       {
        "pasaporte": "México",
        "origen": "Todos",
        "destino": "Canadá",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Autorizacion ETA\nPasaporte vigente"
       },
       {
        "pasaporte": "México",
        "origen": "Todos",
        "destino": "Chad",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": ""
       },
       {
        "pasaporte": "México",
        "origen": "Todos",
        "destino": "China",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado\nBoleto ida y vuelta\nAlojamient0\nPasaporte vigente"
       },
       {
        "pasaporte": "México",
        "origen": "Todos",
        "destino": "Corea del Norte",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": ""
       },
       {
        "pasaporte": "México",
        "origen": "Todos",
        "destino": "Costa de Marfil",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": ""
       },
       {
        "pasaporte": "México",
        "origen": "Todos",
        "destino": "Cuba",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado\nPasaporte vigente\nSeguro médico"
       },
       {
        "pasaporte": "México",
        "origen": "Todos",
        "destino": "Eritrea",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": ""
       },
       {
        "pasaporte": "México",
        "origen": "Todos",
        "destino": "Eswatini",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": ""
       },
       {
        "pasaporte": "México",
        "origen": "Todos",
        "destino": "Federación Rusa",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado Eléctronico\nPasaporte vigente"
       },
       {
        "pasaporte": "México",
        "origen": "Todos",
        "destino": "Falkland Islands",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": ""
       },
       {
        "pasaporte": "México",
        "origen": "Todos",
        "destino": "Gabón",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": ""
       },
       {
        "pasaporte": "México",
        "origen": "Todos",
        "destino": "Gambia",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": ""
       },
       {
        "pasaporte": "México",
        "origen": "Todos",
        "destino": "Ghana",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": ""
       },
       {
        "pasaporte": "México",
        "origen": "Todos",
        "destino": "Guam",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": ""
       },
       {
        "pasaporte": "México",
        "origen": "Todos",
        "destino": "Guyana",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": ""
       },
       {
        "pasaporte": "México",
        "origen": "Todos",
        "destino": "India",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado eléctronico\nPasaporte vigente"
       },
       {
        "pasaporte": "México",
        "origen": "Todos",
        "destino": "Irak",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": ""
       },
       {
        "pasaporte": "México",
        "origen": "Todos",
        "destino": "Islas Marianas del Norte",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "México",
        "origen": "Todos",
        "destino": "Islas Norfolk",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "México",
        "origen": "Todos",
        "destino": "Islas Salomón",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "México",
        "origen": "Todos",
        "destino": "Islas Vírgenes americanas",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "México",
        "origen": "Todos",
        "destino": "Kuwait",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "México",
        "origen": "Todos",
        "destino": "Lesoto",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "México",
        "origen": "Todos",
        "destino": "Liberia",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "México",
        "origen": "Todos",
        "destino": "Libia",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "México",
        "origen": "Todos",
        "destino": "Mali",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "México",
        "origen": "Todos",
        "destino": "Mongolia",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "México",
        "origen": "Todos",
        "destino": "Nauru",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "México",
        "origen": "Todos",
        "destino": "Nigeria",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "México",
        "origen": "Todos",
        "destino": "Níger",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "México",
        "origen": "Todos",
        "destino": "Omán",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "México",
        "origen": "Todos",
        "destino": "Pakistán",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "México",
        "origen": "Todos",
        "destino": "Papúa Nueva Guinea",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": ""
       },
       {
        "pasaporte": "México",
        "origen": "Todos",
        "destino": "Puerto Rico",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado\nPasaporte Válido"
       },
       {
        "pasaporte": "México",
        "origen": "Todos",
        "destino": "República Centroafricana",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": ""
       },
       {
        "pasaporte": "México",
        "origen": "Todos",
        "destino": "República del Congo",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": ""
       },
       {
        "pasaporte": "México",
        "origen": "Todos",
        "destino": "República Democrática del Congo",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": ""
       },
       {
        "pasaporte": "México",
        "origen": "Todos",
        "destino": "Samoa Americana",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": ""
       },
       {
        "pasaporte": "México",
        "origen": "Todos",
        "destino": "Santo Tomé y Príncipe",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": ""
       },
       {
        "pasaporte": "México",
        "origen": "Todos",
        "destino": "Senegal",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": ""
       },
       {
        "pasaporte": "México",
        "origen": "Todos",
        "destino": "Sierra Leona",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": ""
       },
       {
        "pasaporte": "México",
        "origen": "Todos",
        "destino": "Siria",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": ""
       },
       {
        "pasaporte": "México",
        "origen": "Todos",
        "destino": "Somalia",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": ""
       },
       {
        "pasaporte": "México",
        "origen": "Todos",
        "destino": "Sudáfrica",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "México",
        "origen": "Todos",
        "destino": "Sudán",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": ""
       },
       {
        "pasaporte": "México",
        "origen": "Todos",
        "destino": "Sudán del Sur",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": ""
       },
       {
        "pasaporte": "México",
        "origen": "Todos",
        "destino": "Togo",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": ""
       },
       {
        "pasaporte": "México",
        "origen": "Todos",
        "destino": "Tonga",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": ""
       },
       {
        "pasaporte": "México",
        "origen": "Todos",
        "destino": "Turkmenistán",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": ""
       },
       {
        "pasaporte": "México",
        "origen": "Todos",
        "destino": "Turquía",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": ""
       },
       {
        "pasaporte": "México",
        "origen": "Todos",
        "destino": "Uganda",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": ""
       },
       {
        "pasaporte": "México",
        "origen": "Todos",
        "destino": "Ucrania",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": ""
       },
       {
        "pasaporte": "México",
        "origen": "Todos",
        "destino": "Vietnam",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "México",
        "origen": "Todos",
        "destino": "Yemen",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": ""
       },
       {
        "pasaporte": "República Dominicana",
        "origen": "Todos",
        "destino": "Angola",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "República Dominicana",
        "origen": "Todos",
        "destino": "Botsuana",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "República Dominicana",
        "origen": "Todos",
        "destino": "Brasil",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "República Dominicana",
        "origen": "Todos",
        "destino": "Colombia",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "República Dominicana",
        "origen": "Todos",
        "destino": "Ecuador",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "República Dominicana",
        "origen": "Todos",
        "destino": "El Salvador",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "República Dominicana",
        "origen": "Todos",
        "destino": "Federación Rusa",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "República Dominicana",
        "origen": "Todos",
        "destino": "Filipinas",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "República Dominicana",
        "origen": "Todos",
        "destino": "Gambia",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "República Dominicana",
        "origen": "Todos",
        "destino": "Georgia",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "República Dominicana",
        "origen": "Todos",
        "destino": "Granada",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "República Dominicana",
        "origen": "Todos",
        "destino": "Guyana",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "República Dominicana",
        "origen": "Todos",
        "destino": "Honduras",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "República Dominicana",
        "origen": "Todos",
        "destino": "Hong Kong",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "República Dominicana",
        "origen": "Todos",
        "destino": "Islas Cook",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "República Dominicana",
        "origen": "Todos",
        "destino": "Israel",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "República Dominicana",
        "origen": "Todos",
        "destino": "Japón",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "República Dominicana",
        "origen": "Todos",
        "destino": "Kiribati",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "República Dominicana",
        "origen": "Todos",
        "destino": "Malasia",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "República Dominicana",
        "origen": "Todos",
        "destino": "Marruecos",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "República Dominicana",
        "origen": "Todos",
        "destino": "Micronesia",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "República Dominicana",
        "origen": "Todos",
        "destino": "Montserrat",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "República Dominicana",
        "origen": "Todos",
        "destino": "Paraguay",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "República Dominicana",
        "origen": "Todos",
        "destino": "Perú",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "República Dominicana",
        "origen": "Todos",
        "destino": "Singapur",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "República Dominicana",
        "origen": "Todos",
        "destino": "Tailandia",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "República Dominicana",
        "origen": "Todos",
        "destino": "Taiwán",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "República Dominicana",
        "origen": "Todos",
        "destino": "Tayikistán",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "República Dominicana",
        "origen": "Todos",
        "destino": "Territorios Palestinos",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "República Dominicana",
        "origen": "Todos",
        "destino": "Trinidad y Tobago",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "República Dominicana",
        "origen": "Todos",
        "destino": "Túnez",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "República Dominicana",
        "origen": "Todos",
        "destino": "Uzbekistán",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "República Dominicana",
        "origen": "Todos",
        "destino": "Armenia",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.97",
        "requisitos": ""
       },
       {
        "pasaporte": "República Dominicana",
        "origen": "Todos",
        "destino": "Bangladesh",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.97",
        "requisitos": ""
       },
       {
        "pasaporte": "República Dominicana",
        "origen": "Todos",
        "destino": "Bolivia",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.97",
        "requisitos": ""
       },
       {
        "pasaporte": "República Dominicana",
        "origen": "Todos",
        "destino": "Burundi",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.97",
        "requisitos": ""
       },
       {
        "pasaporte": "República Dominicana",
        "origen": "Todos",
        "destino": "Cabo Verde",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.97",
        "requisitos": ""
       },
       {
        "pasaporte": "República Dominicana",
        "origen": "Todos",
        "destino": "Camboya",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.97",
        "requisitos": ""
       },
       {
        "pasaporte": "República Dominicana",
        "origen": "Todos",
        "destino": "Comoras",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.97",
        "requisitos": ""
       },
       {
        "pasaporte": "República Dominicana",
        "origen": "Todos",
        "destino": "Djibouti",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.97",
        "requisitos": ""
       },
       {
        "pasaporte": "República Dominicana",
        "origen": "Todos",
        "destino": "Egipto",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.97",
        "requisitos": ""
       },
       {
        "pasaporte": "República Dominicana",
        "origen": "Todos",
        "destino": "Guinea-Bissau",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.97",
        "requisitos": ""
       },
       {
        "pasaporte": "República Dominicana",
        "origen": "Todos",
        "destino": "Isla Mauricio",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.97",
        "requisitos": ""
       },
       {
        "pasaporte": "República Dominicana",
        "origen": "Todos",
        "destino": "Islas Salomón",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.97",
        "requisitos": ""
       },
       {
        "pasaporte": "República Dominicana",
        "origen": "Todos",
        "destino": "Islas Seychelles",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.97",
        "requisitos": ""
       },
       {
        "pasaporte": "República Dominicana",
        "origen": "Todos",
        "destino": "Jordania",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.97",
        "requisitos": ""
       },
       {
        "pasaporte": "República Dominicana",
        "origen": "Todos",
        "destino": "Laos",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.97",
        "requisitos": ""
       },
       {
        "pasaporte": "República Dominicana",
        "origen": "Todos",
        "destino": "Líbano",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.97",
        "requisitos": ""
       },
       {
        "pasaporte": "República Dominicana",
        "origen": "Todos",
        "destino": "Macao",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.97",
        "requisitos": ""
       },
       {
        "pasaporte": "República Dominicana",
        "origen": "Todos",
        "destino": "Madagascar",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.97",
        "requisitos": ""
       },
       {
        "pasaporte": "República Dominicana",
        "origen": "Todos",
        "destino": "Malawi",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.97",
        "requisitos": ""
       },
       {
        "pasaporte": "República Dominicana",
        "origen": "Todos",
        "destino": "Maldivas",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.97",
        "requisitos": ""
       },
       {
        "pasaporte": "República Dominicana",
        "origen": "Todos",
        "destino": "Mauritania",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.97",
        "requisitos": ""
       },
       {
        "pasaporte": "República Dominicana",
        "origen": "Todos",
        "destino": "Mozambique",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.97",
        "requisitos": ""
       },
       {
        "pasaporte": "República Dominicana",
        "origen": "Todos",
        "destino": "Nepal",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.97",
        "requisitos": ""
       },
       {
        "pasaporte": "República Dominicana",
        "origen": "Todos",
        "destino": "Nicaragua",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.97",
        "requisitos": ""
       },
       {
        "pasaporte": "República Dominicana",
        "origen": "Todos",
        "destino": "Niue",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.97",
        "requisitos": ""
       },
       {
        "pasaporte": "República Dominicana",
        "origen": "Todos",
        "destino": "Palaos",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.97",
        "requisitos": ""
       },
       {
        "pasaporte": "República Dominicana",
        "origen": "Todos",
        "destino": "Qatar",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.97",
        "requisitos": ""
       },
       {
        "pasaporte": "República Dominicana",
        "origen": "Todos",
        "destino": "Ruanda",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.97",
        "requisitos": ""
       },
       {
        "pasaporte": "República Dominicana",
        "origen": "Todos",
        "destino": "Samoa",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.97",
        "requisitos": ""
       },
       {
        "pasaporte": "República Dominicana",
        "origen": "Todos",
        "destino": "Senegal",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.97",
        "requisitos": ""
       },
       {
        "pasaporte": "República Dominicana",
        "origen": "Todos",
        "destino": "Sri Lanka",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.97",
        "requisitos": ""
       },
       {
        "pasaporte": "República Dominicana",
        "origen": "Todos",
        "destino": "Tanzania",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.97",
        "requisitos": ""
       },
       {
        "pasaporte": "República Dominicana",
        "origen": "Todos",
        "destino": "Timor Oriental",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.97",
        "requisitos": ""
       },
       {
        "pasaporte": "República Dominicana",
        "origen": "Todos",
        "destino": "Tuvalu",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.97",
        "requisitos": ""
       },
       {
        "pasaporte": "República Dominicana",
        "origen": "Todos",
        "destino": "Zambia",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.97",
        "requisitos": ""
       },
       {
        "pasaporte": "República Dominicana",
        "origen": "Todos",
        "destino": "Zimbabue",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.97",
        "requisitos": ""
       },
       {
        "pasaporte": "República Dominicana",
        "origen": "Todos",
        "destino": "Albania",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": ""
       },
       {
        "pasaporte": "República Dominicana",
        "origen": "Todos",
        "destino": "Antigua y Barbuda",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": ""
       },
       {
        "pasaporte": "República Dominicana",
        "origen": "Todos",
        "destino": "Australia",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": ""
       },
       {
        "pasaporte": "República Dominicana",
        "origen": "Todos",
        "destino": "Bahamas",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": ""
       },
       {
        "pasaporte": "República Dominicana",
        "origen": "Todos",
        "destino": "Bahrain",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": ""
       },
       {
        "pasaporte": "República Dominicana",
        "origen": "Todos",
        "destino": "Benín",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": ""
       },
       {
        "pasaporte": "República Dominicana",
        "origen": "Todos",
        "destino": "Burkina Faso",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": ""
       },
       {
        "pasaporte": "República Dominicana",
        "origen": "Todos",
        "destino": "Bután",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": ""
       },
       {
        "pasaporte": "República Dominicana",
        "origen": "Todos",
        "destino": "Camerún",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": ""
       },
       {
        "pasaporte": "República Dominicana",
        "origen": "Todos",
        "destino": "Costa de Marfil",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": ""
       },
       {
        "pasaporte": "República Dominicana",
        "origen": "Todos",
        "destino": "Emiratos Árabes Unidos",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": ""
       },
       {
        "pasaporte": "República Dominicana",
        "origen": "Todos",
        "destino": "Etiopía",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": ""
       },
       {
        "pasaporte": "República Dominicana",
        "origen": "Todos",
        "destino": "Gabón",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": ""
       },
       {
        "pasaporte": "República Dominicana",
        "origen": "Todos",
        "destino": "Guinea",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": ""
       },
       {
        "pasaporte": "República Dominicana",
        "origen": "Todos",
        "destino": "Guinea Ecuatorial",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": ""
       },
       {
        "pasaporte": "República Dominicana",
        "origen": "Todos",
        "destino": "India",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": ""
       },
       {
        "pasaporte": "República Dominicana",
        "origen": "Todos",
        "destino": "Indonesia",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": ""
       },
       {
        "pasaporte": "República Dominicana",
        "origen": "Todos",
        "destino": "Irán",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": ""
       },
       {
        "pasaporte": "República Dominicana",
        "origen": "Todos",
        "destino": "Kazajistán",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": ""
       },
       {
        "pasaporte": "República Dominicana",
        "origen": "Todos",
        "destino": "Kirguistán",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": ""
       },
       {
        "pasaporte": "República Dominicana",
        "origen": "Todos",
        "destino": "Lesoto",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": ""
       },
       {
        "pasaporte": "República Dominicana",
        "origen": "Todos",
        "destino": "Libia",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": ""
       },
       {
        "pasaporte": "República Dominicana",
        "origen": "Todos",
        "destino": "Moldavia",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": ""
       },
       {
        "pasaporte": "República Dominicana",
        "origen": "Todos",
        "destino": "Nigeria",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": ""
       },
       {
        "pasaporte": "República Dominicana",
        "origen": "Todos",
        "destino": "Omán",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": ""
       },
       {
        "pasaporte": "República Dominicana",
        "origen": "Todos",
        "destino": "Pakistán",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": ""
       },
       {
        "pasaporte": "República Dominicana",
        "origen": "Todos",
        "destino": "Papúa Nueva Guinea",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": ""
       },
       {
        "pasaporte": "República Dominicana",
        "origen": "Todos",
        "destino": "República Democrática del Congo",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": ""
       },
       {
        "pasaporte": "República Dominicana",
        "origen": "Todos",
        "destino": "San Cristóbal y Nieves",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": ""
       },
       {
        "pasaporte": "República Dominicana",
        "origen": "Todos",
        "destino": "Santa Elena",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": ""
       },
       {
        "pasaporte": "República Dominicana",
        "origen": "Todos",
        "destino": "Santo Tomé y Príncipe",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": ""
       },
       {
        "pasaporte": "República Dominicana",
        "origen": "Todos",
        "destino": "Sierra Leona",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": ""
       },
       {
        "pasaporte": "República Dominicana",
        "origen": "Todos",
        "destino": "Siria",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": ""
       },
       {
        "pasaporte": "República Dominicana",
        "origen": "Todos",
        "destino": "Sudán del Sur",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": ""
       },
       {
        "pasaporte": "República Dominicana",
        "origen": "Todos",
        "destino": "Surinam",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": ""
       },
       {
        "pasaporte": "República Dominicana",
        "origen": "Todos",
        "destino": "Togo",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": ""
       },
       {
        "pasaporte": "República Dominicana",
        "origen": "Todos",
        "destino": "Turquía",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": ""
       },
       {
        "pasaporte": "República Dominicana",
        "origen": "Todos",
        "destino": "Uganda",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": ""
       },
       {
        "pasaporte": "República Dominicana",
        "origen": "Todos",
        "destino": "Vietnam",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": ""
       },
       {
        "pasaporte": "República Dominicana",
        "origen": "Todos",
        "destino": "Corea del Sur",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": ""
       },
       {
        "pasaporte": "República Dominicana",
        "origen": "Todos",
        "destino": "Kenia",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": ""
       },
       {
        "pasaporte": "República Dominicana",
        "origen": "Todos",
        "destino": "Afganistán",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": ""
       },
       {
        "pasaporte": "República Dominicana",
        "origen": "Todos",
        "destino": "Alemania",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado\nPasaporte vigente\nAsistencia Médica de Viaje\nReserva de vuelo\nReserva de hospedaje\nSolvencia económica"
       },
       {
        "pasaporte": "República Dominicana",
        "origen": "Todos",
        "destino": "Andorra",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado\nPasaporte vigente\nAsistencia Médica de Viaje\nReserva de vuelo\nReserva de hospedaje\nSolvencia económica"
       },
       {
        "pasaporte": "República Dominicana",
        "origen": "Todos",
        "destino": "Anguilla",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": ""
       },
       {
        "pasaporte": "República Dominicana",
        "origen": "Todos",
        "destino": "Arabia Saudí",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": ""
       },
       {
        "pasaporte": "República Dominicana",
        "origen": "Todos",
        "destino": "Argelia",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": ""
       },
       {
        "pasaporte": "República Dominicana",
        "origen": "Todos",
        "destino": "Argentina",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado\nPasaporte vigente\nAsistencia Médica de Viaje\nReserva de vuelo\nReserva de hospedaje\nSolvencia económica"
       },
       {
        "pasaporte": "República Dominicana",
        "origen": "Todos",
        "destino": "Aruba",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado\nPasaporte vigente\nAsistencia Médica de Viaje\nReserva de vuelo\nReserva de hospedaje\nSolvencia económica"
       },
       {
        "pasaporte": "República Dominicana",
        "origen": "Todos",
        "destino": "Austria",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado\nPasaporte vigente\nAsistencia Médica de Viaje\nReserva de vuelo\nReserva de hospedaje\nSolvencia económica"
       },
       {
        "pasaporte": "República Dominicana",
        "origen": "Todos",
        "destino": "Azerbaiyán",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado\nPasaporte vigente\nAsistencia Médica de Viaje\nReserva de vuelo\nReserva de hospedaje\nSolvencia económica"
       },
       {
        "pasaporte": "República Dominicana",
        "origen": "Todos",
        "destino": "Barbados",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": ""
       },
       {
        "pasaporte": "República Dominicana",
        "origen": "Todos",
        "destino": "Bélgica",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado\nPasaporte vigente\nAsistencia Médica de Viaje\nReserva de vuelo\nReserva de hospedaje\nSolvencia económica"
       },
       {
        "pasaporte": "República Dominicana",
        "origen": "Todos",
        "destino": "Belice",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado\nPasaporte vigente\nAsistencia Médica de Viaje\nReserva de vuelo\nReserva de hospedaje\nSolvencia económica"
       },
       {
        "pasaporte": "República Dominicana",
        "origen": "Todos",
        "destino": "Bermuda",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": ""
       },
       {
        "pasaporte": "República Dominicana",
        "origen": "Todos",
        "destino": "Bielorrusia",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado\nPasaporte vigente\nAsistencia Médica de Viaje\nReserva de vuelo\nReserva de hospedaje\nSolvencia económica"
       },
       {
        "pasaporte": "República Dominicana",
        "origen": "Todos",
        "destino": "Birmania",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": ""
       },
       {
        "pasaporte": "República Dominicana",
        "origen": "Todos",
        "destino": "Bonaire, San Eustaquio y Saba",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": ""
       },
       {
        "pasaporte": "República Dominicana",
        "origen": "Todos",
        "destino": "Bosnia-Herzegovina",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": ""
       },
       {
        "pasaporte": "República Dominicana",
        "origen": "Todos",
        "destino": "Brunei",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": ""
       },
       {
        "pasaporte": "República Dominicana",
        "origen": "Todos",
        "destino": "Bulgaria",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado\nPasaporte vigente\nAsistencia Médica de Viaje\nReserva de vuelo\nReserva de hospedaje\nSolvencia económica"
       },
       {
        "pasaporte": "República Dominicana",
        "origen": "Todos",
        "destino": "Canadá",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado\nPasaporte vigente"
       },
       {
        "pasaporte": "República Dominicana",
        "origen": "Todos",
        "destino": "Chad",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": ""
       },
       {
        "pasaporte": "República Dominicana",
        "origen": "Todos",
        "destino": "Chile",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado\nPasaporte vigente\nAsistencia Médica de Viaje\nReserva de vuelo\nReserva de hospedaje\nSolvencia económica"
       },
       {
        "pasaporte": "República Dominicana",
        "origen": "Todos",
        "destino": "China",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": ""
       },
       {
        "pasaporte": "República Dominicana",
        "origen": "Todos",
        "destino": "Chipre",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado\nPasaporte vigente\nAsistencia Médica de Viaje\nReserva de vuelo\nReserva de hospedaje\nSolvencia económica"
       },
       {
        "pasaporte": "República Dominicana",
        "origen": "Todos",
        "destino": "Ciudad del Vaticano",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado\nPasaporte vigente\nAsistencia Médica de Viaje\nReserva de vuelo\nReserva de hospedaje\nSolvencia económica"
       },
       {
        "pasaporte": "República Dominicana",
        "origen": "Todos",
        "destino": "Corea del Norte",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": ""
       },
       {
        "pasaporte": "República Dominicana",
        "origen": "Todos",
        "destino": "Costa Rica",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado\nPasaporte vigente\nAsistencia Médica de Viaje\nReserva de vuelo\nReserva de hospedaje\nSolvencia económica"
       },
       {
        "pasaporte": "República Dominicana",
        "origen": "Todos",
        "destino": "Croacia",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado\nPasaporte vigente\nAsistencia Médica de Viaje\nReserva de vuelo\nReserva de hospedaje\nSolvencia económica"
       },
       {
        "pasaporte": "República Dominicana",
        "origen": "Todos",
        "destino": "Cuba",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado\nPasaporte vigente\nAsistencia Médica de Viaje\nReserva de vuelo\nReserva de hospedaje\nSolvencia económica"
       },
       {
        "pasaporte": "República Dominicana",
        "origen": "Todos",
        "destino": "Curaçao",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado\nPasaporte vigente\nAsistencia Médica de Viaje\nReserva de vuelo\nReserva de hospedaje\nSolvencia económica"
       },
       {
        "pasaporte": "República Dominicana",
        "origen": "Todos",
        "destino": "Dinamarca",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado\nPasaporte vigente\nAsistencia Médica de Viaje\nReserva de vuelo\nReserva de hospedaje\nSolvencia económica"
       },
       {
        "pasaporte": "República Dominicana",
        "origen": "Todos",
        "destino": "Dominica",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": ""
       },
       {
        "pasaporte": "República Dominicana",
        "origen": "Todos",
        "destino": "Eritrea",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": ""
       },
       {
        "pasaporte": "República Dominicana",
        "origen": "Todos",
        "destino": "Eslovaquia",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado\nPasaporte vigente\nAsistencia Médica de Viaje\nReserva de vuelo\nReserva de hospedaje\nSolvencia económica"
       },
       {
        "pasaporte": "República Dominicana",
        "origen": "Todos",
        "destino": "Eslovenia",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado\nPasaporte vigente\nAsistencia Médica de Viaje\nReserva de vuelo\nReserva de hospedaje\nSolvencia económica"
       },
       {
        "pasaporte": "República Dominicana",
        "origen": "Todos",
        "destino": "España",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado\nPasaporte vigente\nAsistencia Médica de Viaje\nReserva de vuelo\nReserva de hospedaje\nSolvencia económica"
       },
       {
        "pasaporte": "República Dominicana",
        "origen": "Todos",
        "destino": "Estados Unidos de América",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado\nPasaporte vigentr"
       },
       {
        "pasaporte": "República Dominicana",
        "origen": "Todos",
        "destino": "Estonia",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado\nPasaporte vigente\nAsistencia Médica de Viaje\nReserva de vuelo\nReserva de hospedaje\nSolvencia económica"
       },
       {
        "pasaporte": "República Dominicana",
        "origen": "Todos",
        "destino": "Eswatini",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": ""
       },
       {
        "pasaporte": "República Dominicana",
        "origen": "Todos",
        "destino": "Falkland Islands",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": ""
       },
       {
        "pasaporte": "República Dominicana",
        "origen": "Todos",
        "destino": "Fiji",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": ""
       },
       {
        "pasaporte": "República Dominicana",
        "origen": "Todos",
        "destino": "Finlandia",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado\nPasaporte vigente\nAsistencia Médica de Viaje\nReserva de vuelo\nReserva de hospedaje\nSolvencia económica"
       },
       {
        "pasaporte": "República Dominicana",
        "origen": "Todos",
        "destino": "Francia",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado\nPasaporte vigente\nAsistencia Médica de Viaje\nReserva de vuelo\nReserva de hospedaje\nSolvencia económica"
       },
       {
        "pasaporte": "República Dominicana",
        "origen": "Todos",
        "destino": "Ghana",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": ""
       },
       {
        "pasaporte": "República Dominicana",
        "origen": "Todos",
        "destino": "Gibraltar",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado\nPasaporte vigente\nAsistencia Médica de Viaje\nReserva de vuelo\nReserva de hospedaje\nSolvencia económica"
       },
       {
        "pasaporte": "República Dominicana",
        "origen": "Todos",
        "destino": "Grecia",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado\nPasaporte vigente\nAsistencia Médica de Viaje\nReserva de vuelo\nReserva de hospedaje\nSolvencia económica"
       },
       {
        "pasaporte": "República Dominicana",
        "origen": "Todos",
        "destino": "Groenlandia",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado\nPasaporte vigente\nAsistencia Médica de Viaje\nReserva de vuelo\nReserva de hospedaje\nSolvencia económica"
       },
       {
        "pasaporte": "República Dominicana",
        "origen": "Todos",
        "destino": "Guam",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": ""
       },
       {
        "pasaporte": "República Dominicana",
        "origen": "Todos",
        "destino": "Guatemala",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": ""
       },
       {
        "pasaporte": "República Dominicana",
        "origen": "Todos",
        "destino": "Guayana Francesa",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": ""
       },
       {
        "pasaporte": "República Dominicana",
        "origen": "Todos",
        "destino": "Haití",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": ""
       },
       {
        "pasaporte": "República Dominicana",
        "origen": "Todos",
        "destino": "Hungría",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado\nPasaporte vigente\nAsistencia Médica de Viaje\nReserva de vuelo\nReserva de hospedaje\nSolvencia económica"
       },
       {
        "pasaporte": "República Dominicana",
        "origen": "Todos",
        "destino": "Indias Occidentales Francesas",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": ""
       },
       {
        "pasaporte": "República Dominicana",
        "origen": "Todos",
        "destino": "Irak",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": ""
       },
       {
        "pasaporte": "República Dominicana",
        "origen": "Todos",
        "destino": "Irlanda",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado\nPasaporte vigente"
       },
       {
        "pasaporte": "República Dominicana",
        "origen": "Todos",
        "destino": "Isla Norfolk",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": ""
       },
       {
        "pasaporte": "República Dominicana",
        "origen": "Todos",
        "destino": "Isla Reunión",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": ""
       },
       {
        "pasaporte": "República Dominicana",
        "origen": "Todos",
        "destino": "Islandia",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado\nPasaporte vigente\nAsistencia Médica de Viaje\nReserva de vuelo\nReserva de hospedaje\nSolvencia económica"
       },
       {
        "pasaporte": "República Dominicana",
        "origen": "Todos",
        "destino": "Islas Caimán",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": ""
       },
       {
        "pasaporte": "República Dominicana",
        "origen": "Todos",
        "destino": "Islas Feroe",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": ""
       },
       {
        "pasaporte": "República Dominicana",
        "origen": "Todos",
        "destino": "Islas Marianas del Norte",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": ""
       },
       {
        "pasaporte": "República Dominicana",
        "origen": "Todos",
        "destino": "Islas Marshall",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": ""
       },
       {
        "pasaporte": "República Dominicana",
        "origen": "Todos",
        "destino": "Islas Turcas y Caicos",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": ""
       },
       {
        "pasaporte": "República Dominicana",
        "origen": "Todos",
        "destino": "Islas Vírgenes americanas",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": ""
       },
       {
        "pasaporte": "República Dominicana",
        "origen": "Todos",
        "destino": "Islas Vírgenes Británicas",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": ""
       },
       {
        "pasaporte": "República Dominicana",
        "origen": "Todos",
        "destino": "Italia",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado\nPasaporte vigente\nAsistencia Médica de Viaje\nReserva de vuelo\nReserva de hospedaje\nSolvencia económica"
       },
       {
        "pasaporte": "República Dominicana",
        "origen": "Todos",
        "destino": "Jamaica",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": ""
       },
       {
        "pasaporte": "República Dominicana",
        "origen": "Todos",
        "destino": "Kosovo",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": ""
       },
       {
        "pasaporte": "República Dominicana",
        "origen": "Todos",
        "destino": "Kuwait",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": ""
       },
       {
        "pasaporte": "República Dominicana",
        "origen": "Todos",
        "destino": "Letonia",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado\nPasaporte vigente\nAsistencia Médica de Viaje\nReserva de vuelo\nReserva de hospedaje\nSolvencia económica"
       },
       {
        "pasaporte": "República Dominicana",
        "origen": "Todos",
        "destino": "Liberia",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": ""
       },
       {
        "pasaporte": "República Dominicana",
        "origen": "Todos",
        "destino": "Liechtenstein",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado\nPasaporte vigente\nAsistencia Médica de Viaje\nReserva de vuelo\nReserva de hospedaje\nSolvencia económica"
       },
       {
        "pasaporte": "República Dominicana",
        "origen": "Todos",
        "destino": "Lituania",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado\nPasaporte vigente\nAsistencia Médica de Viaje\nReserva de vuelo\nReserva de hospedaje\nSolvencia económica"
       },
       {
        "pasaporte": "República Dominicana",
        "origen": "Todos",
        "destino": "Luxemburgo",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado\nPasaporte vigente\nAsistencia Médica de Viaje\nReserva de vuelo\nReserva de hospedaje\nSolvencia económica"
       },
       {
        "pasaporte": "República Dominicana",
        "origen": "Todos",
        "destino": "Macedonia del Norte",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": ""
       },
       {
        "pasaporte": "República Dominicana",
        "origen": "Todos",
        "destino": "Mali",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": ""
       },
       {
        "pasaporte": "República Dominicana",
        "origen": "Todos",
        "destino": "Malta",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado\nPasaporte vigente\nAsistencia Médica de Viaje\nReserva de vuelo\nReserva de hospedaje\nSolvencia económica"
       },
       {
        "pasaporte": "República Dominicana",
        "origen": "Todos",
        "destino": "Mayotte",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": ""
       },
       {
        "pasaporte": "República Dominicana",
        "origen": "Todos",
        "destino": "México",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado\nPasaporte vigente\nAsistencia Médica de Viaje\nReserva de vuelo\nReserva de hospedaje\nSolvencia económica"
       },
       {
        "pasaporte": "República Dominicana",
        "origen": "Todos",
        "destino": "Mónaco",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado\nPasaporte vigente\nAsistencia Médica de Viaje\nReserva de vuelo\nReserva de hospedaje\nSolvencia económica"
       },
       {
        "pasaporte": "República Dominicana",
        "origen": "Todos",
        "destino": "Mongolia",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": ""
       },
       {
        "pasaporte": "República Dominicana",
        "origen": "Todos",
        "destino": "Montenegro",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado\nPasaporte vigente\nAsistencia Médica de Viaje\nReserva de vuelo\nReserva de hospedaje\nSolvencia económica"
       },
       {
        "pasaporte": "República Dominicana",
        "origen": "Todos",
        "destino": "Namibia",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": ""
       },
       {
        "pasaporte": "República Dominicana",
        "origen": "Todos",
        "destino": "Nauru",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": ""
       },
       {
        "pasaporte": "República Dominicana",
        "origen": "Todos",
        "destino": "Níger",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": ""
       },
       {
        "pasaporte": "República Dominicana",
        "origen": "Todos",
        "destino": "Noruega",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado\nPasaporte vigente\nAsistencia Médica de Viaje\nReserva de vuelo\nReserva de hospedaje\nSolvencia económica"
       },
       {
        "pasaporte": "República Dominicana",
        "origen": "Todos",
        "destino": "Nueva Caledonia",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": ""
       },
       {
        "pasaporte": "República Dominicana",
        "origen": "Todos",
        "destino": "Nueva Zelanda",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado\nPasaporte vigente."
       },
       {
        "pasaporte": "República Dominicana",
        "origen": "Todos",
        "destino": "Países Bajos",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": ""
       },
       {
        "pasaporte": "República Dominicana",
        "origen": "Todos",
        "destino": "Panamá",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado\nPasaporte vigente\nAsistencia Médica de Viaje\nReserva de vuelo\nReserva de hospedaje\nSolvencia económica"
       },
       {
        "pasaporte": "República Dominicana",
        "origen": "Todos",
        "destino": "Polinesia Francesa",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": ""
       },
       {
        "pasaporte": "República Dominicana",
        "origen": "Todos",
        "destino": "Polonia",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado\nPasaporte vigente\nAsistencia Médica de Viaje\nReserva de vuelo\nReserva de hospedaje\nSolvencia económica"
       },
       {
        "pasaporte": "República Dominicana",
        "origen": "Todos",
        "destino": "Portugal",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado\nPasaporte vigente\nAsistencia Médica de Viaje\nReserva de vuelo\nReserva de hospedaje\nSolvencia económica"
       },
       {
        "pasaporte": "República Dominicana",
        "origen": "Todos",
        "destino": "Puerto Rico",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado\nPasaporte vigente"
       },
       {
        "pasaporte": "República Dominicana",
        "origen": "Todos",
        "destino": "Reino Unido",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado\nPasaporte vigente"
       },
       {
        "pasaporte": "República Dominicana",
        "origen": "Todos",
        "destino": "República Centroafricana",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": ""
       },
       {
        "pasaporte": "República Dominicana",
        "origen": "Todos",
        "destino": "República Checa",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado\nPasaporte vigente\nAsistencia Médica de Viaje\nReserva de vuelo\nReserva de hospedaje\nSolvencia económica"
       },
       {
        "pasaporte": "República Dominicana",
        "origen": "Todos",
        "destino": "República del Congo",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": ""
       },
       {
        "pasaporte": "República Dominicana",
        "origen": "Todos",
        "destino": "Rumanía",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado\nPasaporte vigente\nAsistencia Médica de Viaje\nReserva de vuelo\nReserva de hospedaje\nSolvencia económica"
       },
       {
        "pasaporte": "República Dominicana",
        "origen": "Todos",
        "destino": "Samoa Americana",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": ""
       },
       {
        "pasaporte": "República Dominicana",
        "origen": "Todos",
        "destino": "San Marino",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado\nPasaporte vigente\nAsistencia Médica de Viaje\nReserva de vuelo\nReserva de hospedaje\nSolvencia económica"
       },
       {
        "pasaporte": "República Dominicana",
        "origen": "Todos",
        "destino": "San Martín",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": ""
       },
       {
        "pasaporte": "República Dominicana",
        "origen": "Todos",
        "destino": "San Pedro y Miquelón",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": ""
       },
       {
        "pasaporte": "República Dominicana",
        "origen": "Todos",
        "destino": "San Vicente y las Granadinas",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": ""
       },
       {
        "pasaporte": "República Dominicana",
        "origen": "Todos",
        "destino": "Santa Lucía",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": ""
       },
       {
        "pasaporte": "República Dominicana",
        "origen": "Todos",
        "destino": "Serbia",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado\nPasaporte vigente\nAsistencia Médica de Viaje\nReserva de vuelo\nReserva de hospedaje\nSolvencia económica"
       },
       {
        "pasaporte": "República Dominicana",
        "origen": "Todos",
        "destino": "Somalia",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": ""
       },
       {
        "pasaporte": "República Dominicana",
        "origen": "Todos",
        "destino": "Sudáfrica",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": ""
       },
       {
        "pasaporte": "República Dominicana",
        "origen": "Todos",
        "destino": "Sudán",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": ""
       },
       {
        "pasaporte": "República Dominicana",
        "origen": "Todos",
        "destino": "Suecia",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado\nPasaporte vigente\nAsistencia Médica de Viaje\nReserva de vuelo\nReserva de hospedaje\nSolvencia económica"
       },
       {
        "pasaporte": "República Dominicana",
        "origen": "Todos",
        "destino": "Suiza",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado\nPasaporte vigente\nAsistencia Médica de Viaje\nReserva de vuelo\nReserva de hospedaje\nSolvencia económica"
       },
       {
        "pasaporte": "República Dominicana",
        "origen": "Todos",
        "destino": "Tonga",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": ""
       },
       {
        "pasaporte": "República Dominicana",
        "origen": "Todos",
        "destino": "Turkmenistán",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": ""
       },
       {
        "pasaporte": "República Dominicana",
        "origen": "Todos",
        "destino": "Ucrania",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": ""
       },
       {
        "pasaporte": "República Dominicana",
        "origen": "Todos",
        "destino": "Uruguay",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado\nPasaporte vigente\nAsistencia Médica de Viaje\nReserva de vuelo\nReserva de hospedaje\nSolvencia económica"
       },
       {
        "pasaporte": "República Dominicana",
        "origen": "Todos",
        "destino": "Vanuatu",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": ""
       },
       {
        "pasaporte": "República Dominicana",
        "origen": "Todos",
        "destino": "Venezuela",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado\nPasaporte vigente\nAsistencia Médica de Viaje\nReserva de vuelo\nReserva de hospedaje\nSolvencia económica"
       },
       {
        "pasaporte": "República Dominicana",
        "origen": "Todos",
        "destino": "Wallis y Futuna",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": ""
       },
       {
        "pasaporte": "República Dominicana",
        "origen": "Todos",
        "destino": "Yemen",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": ""
       },
       {
        "pasaporte": "Bolivia",
        "origen": "Todos",
        "destino": "Antigua y Barbuda",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "Bolivia",
        "origen": "Todos",
        "destino": "Argentina",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "Bolivia",
        "origen": "Todos",
        "destino": "Bahamas",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "Bolivia",
        "origen": "Todos",
        "destino": "Barbados",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "Bolivia",
        "origen": "Todos",
        "destino": "Belice",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "Bolivia",
        "origen": "Todos",
        "destino": "Brasil",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "Bolivia",
        "origen": "Todos",
        "destino": "Chile",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "Bolivia",
        "origen": "Todos",
        "destino": "Colombia",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "Bolivia",
        "origen": "Todos",
        "destino": "Costa Rica",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "Bolivia",
        "origen": "Todos",
        "destino": "Dominica",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "Bolivia",
        "origen": "Todos",
        "destino": "Ecuador",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "Bolivia",
        "origen": "Todos",
        "destino": "Federación Rusa",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "Bolivia",
        "origen": "Todos",
        "destino": "Filipinas",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "Bolivia",
        "origen": "Todos",
        "destino": "Gambia",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "Bolivia",
        "origen": "Todos",
        "destino": "Guayana Francesa",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "Bolivia",
        "origen": "Todos",
        "destino": "Haití",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "Bolivia",
        "origen": "Todos",
        "destino": "Hong Kong",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "Bolivia",
        "origen": "Todos",
        "destino": "Indias Occidentales Francesas",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "Bolivia",
        "origen": "Todos",
        "destino": "Isla Reunión",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "Bolivia",
        "origen": "Todos",
        "destino": "Islas Cook",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "Bolivia",
        "origen": "Todos",
        "destino": "Islas Vírgenes Británicas",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "Bolivia",
        "origen": "Todos",
        "destino": "Jamaica",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "Bolivia",
        "origen": "Todos",
        "destino": "Kiribati",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "Bolivia",
        "origen": "Todos",
        "destino": "Malasia",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "Bolivia",
        "origen": "Todos",
        "destino": "Mayotte",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "Bolivia",
        "origen": "Todos",
        "destino": "México",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "Bolivia",
        "origen": "Todos",
        "destino": "Micronesia",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "Bolivia",
        "origen": "Todos",
        "destino": "Nueva Caledonia",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "Bolivia",
        "origen": "Todos",
        "destino": "Panamá",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "Bolivia",
        "origen": "Todos",
        "destino": "Paraguay",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "Bolivia",
        "origen": "Todos",
        "destino": "Perú",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "Bolivia",
        "origen": "Todos",
        "destino": "Polinesia Francesa",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "Bolivia",
        "origen": "Todos",
        "destino": "República Dominicana",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "Bolivia",
        "origen": "Todos",
        "destino": "San Cristóbal y Nieves",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "Bolivia",
        "origen": "Todos",
        "destino": "San Vicente y las Granadinas",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "Bolivia",
        "origen": "Todos",
        "destino": "Singapur",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "Bolivia",
        "origen": "Todos",
        "destino": "Sudáfrica",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "Bolivia",
        "origen": "Todos",
        "destino": "Surinam",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "Bolivia",
        "origen": "Todos",
        "destino": "Turquía",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "Bolivia",
        "origen": "Todos",
        "destino": "Uruguay",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "Bolivia",
        "origen": "Todos",
        "destino": "Venezuela",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "Bolivia",
        "origen": "Todos",
        "destino": "Wallis y Futuna",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "Bolivia",
        "origen": "Todos",
        "destino": "Bahrain",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.98",
        "requisitos": "Visado eléctronico\nPasaporte vigente"
       },
       {
        "pasaporte": "Bolivia",
        "origen": "Todos",
        "destino": "Bangladesh",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.98",
        "requisitos": ""
       },
       {
        "pasaporte": "Bolivia",
        "origen": "Todos",
        "destino": "Burundi",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.98",
        "requisitos": ""
       },
       {
        "pasaporte": "Bolivia",
        "origen": "Todos",
        "destino": "Cabo Verde",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.98",
        "requisitos": ""
       },
       {
        "pasaporte": "Bolivia",
        "origen": "Todos",
        "destino": "Camboya",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.98",
        "requisitos": ""
       },
       {
        "pasaporte": "Bolivia",
        "origen": "Todos",
        "destino": "Comoras",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.98",
        "requisitos": ""
       },
       {
        "pasaporte": "Bolivia",
        "origen": "Todos",
        "destino": "Djibouti",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.98",
        "requisitos": ""
       },
       {
        "pasaporte": "Bolivia",
        "origen": "Todos",
        "destino": "Egipto",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.98",
        "requisitos": ""
       },
       {
        "pasaporte": "Bolivia",
        "origen": "Todos",
        "destino": "Guinea-Bissau",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.98",
        "requisitos": ""
       },
       {
        "pasaporte": "Bolivia",
        "origen": "Todos",
        "destino": "Isla Mauricio",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.98",
        "requisitos": ""
       },
       {
        "pasaporte": "Bolivia",
        "origen": "Todos",
        "destino": "Islas Seychelles",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.98",
        "requisitos": ""
       },
       {
        "pasaporte": "Bolivia",
        "origen": "Todos",
        "destino": "Jordania",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.98",
        "requisitos": ""
       },
       {
        "pasaporte": "Bolivia",
        "origen": "Todos",
        "destino": "Laos",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.98",
        "requisitos": ""
       },
       {
        "pasaporte": "Bolivia",
        "origen": "Todos",
        "destino": "Macao",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.98",
        "requisitos": ""
       },
       {
        "pasaporte": "Bolivia",
        "origen": "Todos",
        "destino": "Madagascar",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.98",
        "requisitos": ""
       },
       {
        "pasaporte": "Bolivia",
        "origen": "Todos",
        "destino": "Malawi",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.98",
        "requisitos": ""
       },
       {
        "pasaporte": "Bolivia",
        "origen": "Todos",
        "destino": "Maldivas",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.98",
        "requisitos": ""
       },
       {
        "pasaporte": "Bolivia",
        "origen": "Todos",
        "destino": "Mauritania",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.98",
        "requisitos": ""
       },
       {
        "pasaporte": "Bolivia",
        "origen": "Todos",
        "destino": "Mozambique",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.98",
        "requisitos": ""
       },
       {
        "pasaporte": "Bolivia",
        "origen": "Todos",
        "destino": "Nepal",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.98",
        "requisitos": ""
       },
       {
        "pasaporte": "Bolivia",
        "origen": "Todos",
        "destino": "Nicaragua",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.98",
        "requisitos": ""
       },
       {
        "pasaporte": "Bolivia",
        "origen": "Todos",
        "destino": "Niue",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.98",
        "requisitos": ""
       },
       {
        "pasaporte": "Bolivia",
        "origen": "Todos",
        "destino": "Omán",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.98",
        "requisitos": ""
       },
       {
        "pasaporte": "Bolivia",
        "origen": "Todos",
        "destino": "Palaos",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.98",
        "requisitos": ""
       },
       {
        "pasaporte": "Bolivia",
        "origen": "Todos",
        "destino": "Qatar",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.98",
        "requisitos": ""
       },
       {
        "pasaporte": "Bolivia",
        "origen": "Todos",
        "destino": "Ruanda",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.98",
        "requisitos": ""
       },
       {
        "pasaporte": "Bolivia",
        "origen": "Todos",
        "destino": "Samoa",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.98",
        "requisitos": ""
       },
       {
        "pasaporte": "Bolivia",
        "origen": "Todos",
        "destino": "Santa Lucía",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.98",
        "requisitos": ""
       },
       {
        "pasaporte": "Bolivia",
        "origen": "Todos",
        "destino": "Sierra Leona",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.98",
        "requisitos": ""
       },
       {
        "pasaporte": "Bolivia",
        "origen": "Todos",
        "destino": "Sri Lanka",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.98",
        "requisitos": ""
       },
       {
        "pasaporte": "Bolivia",
        "origen": "Todos",
        "destino": "Tailandia",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.98",
        "requisitos": ""
       },
       {
        "pasaporte": "Bolivia",
        "origen": "Todos",
        "destino": "Tanzania",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.98",
        "requisitos": ""
       },
       {
        "pasaporte": "Bolivia",
        "origen": "Todos",
        "destino": "Timor Oriental",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.98",
        "requisitos": ""
       },
       {
        "pasaporte": "Bolivia",
        "origen": "Todos",
        "destino": "Tuvalu",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.98",
        "requisitos": ""
       },
       {
        "pasaporte": "Bolivia",
        "origen": "Todos",
        "destino": "Zambia",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.98",
        "requisitos": ""
       },
       {
        "pasaporte": "Bolivia",
        "origen": "Todos",
        "destino": "Kenias",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.98",
        "requisitos": ""
       },
       {
        "pasaporte": "Bolivia",
        "origen": "Todos",
        "destino": "Albania",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.93",
        "requisitos": ""
       },
       {
        "pasaporte": "Bolivia",
        "origen": "Todos",
        "destino": "Armenia",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.93",
        "requisitos": ""
       },
       {
        "pasaporte": "Bolivia",
        "origen": "Todos",
        "destino": "Australia",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.93",
        "requisitos": "Solicitud de visado\nPasaporte vigente"
       },
       {
        "pasaporte": "Bolivia",
        "origen": "Todos",
        "destino": "Azerbaiyán",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.93",
        "requisitos": "Visado\nPasaporte vigente\nAsistencia Médica de Viaje\nReserva de vuelo\nReserva de hospedaje\nSolvencia económica"
       },
       {
        "pasaporte": "Bolivia",
        "origen": "Todos",
        "destino": "Benín",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.93",
        "requisitos": ""
       },
       {
        "pasaporte": "Bolivia",
        "origen": "Todos",
        "destino": "Birmania",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.93",
        "requisitos": ""
       },
       {
        "pasaporte": "Bolivia",
        "origen": "Todos",
        "destino": "Botswana",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.93",
        "requisitos": ""
       },
       {
        "pasaporte": "Bolivia",
        "origen": "Todos",
        "destino": "Burkina Faso",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.93",
        "requisitos": ""
       },
       {
        "pasaporte": "Bolivia",
        "origen": "Todos",
        "destino": "Bután",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.93",
        "requisitos": ""
       },
       {
        "pasaporte": "Bolivia",
        "origen": "Todos",
        "destino": "Camerún",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.93",
        "requisitos": ""
       },
       {
        "pasaporte": "Bolivia",
        "origen": "Todos",
        "destino": "Costa de Marfil",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.93",
        "requisitos": ""
       },
       {
        "pasaporte": "Bolivia",
        "origen": "Todos",
        "destino": "El Salvador",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.93",
        "requisitos": ""
       },
       {
        "pasaporte": "Bolivia",
        "origen": "Todos",
        "destino": "Emiratos Árabes Unidos",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.93",
        "requisitos": ""
       },
       {
        "pasaporte": "Bolivia",
        "origen": "Todos",
        "destino": "Etiopía",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.93",
        "requisitos": ""
       },
       {
        "pasaporte": "Bolivia",
        "origen": "Todos",
        "destino": "Gabón",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.93",
        "requisitos": ""
       },
       {
        "pasaporte": "Bolivia",
        "origen": "Todos",
        "destino": "Georgia",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.93",
        "requisitos": ""
       },
       {
        "pasaporte": "Bolivia",
        "origen": "Todos",
        "destino": "Guinea",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.93",
        "requisitos": ""
       },
       {
        "pasaporte": "Bolivia",
        "origen": "Todos",
        "destino": "Guinea Ecuatorial",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.93",
        "requisitos": ""
       },
       {
        "pasaporte": "Bolivia",
        "origen": "Todos",
        "destino": "India",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.93",
        "requisitos": ""
       },
       {
        "pasaporte": "Bolivia",
        "origen": "Todos",
        "destino": "Indonesia",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.93",
        "requisitos": ""
       },
       {
        "pasaporte": "Bolivia",
        "origen": "Todos",
        "destino": "Irán",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.93",
        "requisitos": ""
       },
       {
        "pasaporte": "Bolivia",
        "origen": "Todos",
        "destino": "Kazajistán",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.93",
        "requisitos": ""
       },
       {
        "pasaporte": "Bolivia",
        "origen": "Todos",
        "destino": "Kirguistán",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.93",
        "requisitos": ""
       },
       {
        "pasaporte": "Bolivia",
        "origen": "Todos",
        "destino": "Lesoto",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.93",
        "requisitos": ""
       },
       {
        "pasaporte": "Bolivia",
        "origen": "Todos",
        "destino": "Libia",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.93",
        "requisitos": ""
       },
       {
        "pasaporte": "Bolivia",
        "origen": "Todos",
        "destino": "Moldavia",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.93",
        "requisitos": ""
       },
       {
        "pasaporte": "Bolivia",
        "origen": "Todos",
        "destino": "Mongolia",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.93",
        "requisitos": ""
       },
       {
        "pasaporte": "Bolivia",
        "origen": "Todos",
        "destino": "Montserrat",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.93",
        "requisitos": ""
       },
       {
        "pasaporte": "Bolivia",
        "origen": "Todos",
        "destino": "Nigeria",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.93",
        "requisitos": ""
       },
       {
        "pasaporte": "Bolivia",
        "origen": "Todos",
        "destino": "Pakistán",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.93",
        "requisitos": ""
       },
       {
        "pasaporte": "Bolivia",
        "origen": "Todos",
        "destino": "República Democrática del Congo",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.93",
        "requisitos": ""
       },
       {
        "pasaporte": "Bolivia",
        "origen": "Todos",
        "destino": "Santa Elena",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.93",
        "requisitos": ""
       },
       {
        "pasaporte": "Bolivia",
        "origen": "Todos",
        "destino": "Santo Tomé y Príncipe",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.93",
        "requisitos": ""
       },
       {
        "pasaporte": "Bolivia",
        "origen": "Todos",
        "destino": "Siria",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.93",
        "requisitos": ""
       },
       {
        "pasaporte": "Bolivia",
        "origen": "Todos",
        "destino": "Sudán del Sur",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.93",
        "requisitos": ""
       },
       {
        "pasaporte": "Bolivia",
        "origen": "Todos",
        "destino": "Tajikistán",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.93",
        "requisitos": ""
       },
       {
        "pasaporte": "Bolivia",
        "origen": "Todos",
        "destino": "Togo",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.93",
        "requisitos": ""
       },
       {
        "pasaporte": "Bolivia",
        "origen": "Todos",
        "destino": "Uganda",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.93",
        "requisitos": ""
       },
       {
        "pasaporte": "Bolivia",
        "origen": "Todos",
        "destino": "Uzbekistán",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.93",
        "requisitos": ""
       },
       {
        "pasaporte": "Bolivia",
        "origen": "Todos",
        "destino": "Vietnam",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.93",
        "requisitos": ""
       },
       {
        "pasaporte": "Bolivia",
        "origen": "Todos",
        "destino": "Zimbabue",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.93",
        "requisitos": ""
       },
       {
        "pasaporte": "Bolivia",
        "origen": "Todos",
        "destino": "Afganistán",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": ""
       },
       {
        "pasaporte": "Bolivia",
        "origen": "Todos",
        "destino": "Alemania",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado\nPasaporte vigente\nAsistencia Médica de Viaje\nReserva de vuelo\nReserva de hospedaje\nSolvencia económica"
       },
       {
        "pasaporte": "Bolivia",
        "origen": "Todos",
        "destino": "Andorra",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado\nPasaporte vigente\nAsistencia Médica de Viaje\nReserva de vuelo\nReserva de hospedaje\nSolvencia económica"
       },
       {
        "pasaporte": "Bolivia",
        "origen": "Todos",
        "destino": "Angola",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": ""
       },
       {
        "pasaporte": "Bolivia",
        "origen": "Todos",
        "destino": "Anguilla",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": ""
       },
       {
        "pasaporte": "Bolivia",
        "origen": "Todos",
        "destino": "Arabia Saudí",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": ""
       },
       {
        "pasaporte": "Bolivia",
        "origen": "Todos",
        "destino": "Argelia",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": ""
       },
       {
        "pasaporte": "Bolivia",
        "origen": "Todos",
        "destino": "Aruba",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado\nPasaporte vigente\nAsistencia Médica de Viaje\nReserva de vuelo\nReserva de hospedaje\nSolvencia económica"
       },
       {
        "pasaporte": "Bolivia",
        "origen": "Todos",
        "destino": "Austria",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado\nPasaporte vigente\nAsistencia Médica de Viaje\nReserva de vuelo\nReserva de hospedaje\nSolvencia económica"
       },
       {
        "pasaporte": "Bolivia",
        "origen": "Todos",
        "destino": "Bélgica",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado\nPasaporte vigente\nAsistencia Médica de Viaje\nReserva de vuelo\nReserva de hospedaje\nSolvencia económica"
       },
       {
        "pasaporte": "Bolivia",
        "origen": "Todos",
        "destino": "Bermuda",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": ""
       },
       {
        "pasaporte": "Bolivia",
        "origen": "Todos",
        "destino": "Bielorrusia",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": ""
       },
       {
        "pasaporte": "Bolivia",
        "origen": "Todos",
        "destino": "Bonaire, San Eustaquio y Saba",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": ""
       },
       {
        "pasaporte": "Bolivia",
        "origen": "Todos",
        "destino": "Bosnia-Herzegovina",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": ""
       },
       {
        "pasaporte": "Bolivia",
        "origen": "Todos",
        "destino": "Brunei",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": ""
       },
       {
        "pasaporte": "Bolivia",
        "origen": "Todos",
        "destino": "Bulgaria",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado\nPasaporte vigente\nAsistencia Médica de Viaje\nReserva de vuelo\nReserva de hospedaje\nSolvencia económica"
       },
       {
        "pasaporte": "Bolivia",
        "origen": "Todos",
        "destino": "Canadá",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado\nPasaporte vigente"
       },
       {
        "pasaporte": "Bolivia",
        "origen": "Todos",
        "destino": "Chad",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": ""
       },
       {
        "pasaporte": "Bolivia",
        "origen": "Todos",
        "destino": "China",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": ""
       },
       {
        "pasaporte": "Bolivia",
        "origen": "Todos",
        "destino": "Chipre",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado\nPasaporte vigente\nAsistencia Médica de Viaje\nReserva de vuelo\nReserva de hospedaje\nSolvencia económica"
       },
       {
        "pasaporte": "Bolivia",
        "origen": "Todos",
        "destino": "Ciudad del Vaticano",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado\nPasaporte vigente\nAsistencia Médica de Viaje\nReserva de vuelo\nReserva de hospedaje\nSolvencia económica"
       },
       {
        "pasaporte": "Bolivia",
        "origen": "Todos",
        "destino": "Corea del Norte",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": ""
       },
       {
        "pasaporte": "Bolivia",
        "origen": "Todos",
        "destino": "Corea del Sur",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado\nPasaporte vigente\nAsistencia Médica de Viaje\nReserva de vuelo\nReserva de hospedaje\nSolvencia económica"
       },
       {
        "pasaporte": "Bolivia",
        "origen": "Todos",
        "destino": "Croacia",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado\nPasaporte vigente\nAsistencia Médica de Viaje\nReserva de vuelo\nReserva de hospedaje\nSolvencia económica"
       },
       {
        "pasaporte": "Bolivia",
        "origen": "Todos",
        "destino": "Cuba",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado\nPasaporte vigente\nSeguro médico\nVacuna fiebre amarilla"
       },
       {
        "pasaporte": "Bolivia",
        "origen": "Todos",
        "destino": "Curaçao",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado\nPasaporte vigente\nAsistencia Médica de Viaje\nReserva de vuelo\nReserva de hospedaje\nSolvencia económica"
       },
       {
        "pasaporte": "Bolivia",
        "origen": "Todos",
        "destino": "Dinamarca",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado\nPasaporte vigente\nAsistencia Médica de Viaje\nReserva de vuelo\nReserva de hospedaje\nSolvencia económica"
       },
       {
        "pasaporte": "Bolivia",
        "origen": "Todos",
        "destino": "Eritrea",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": ""
       },
       {
        "pasaporte": "Bolivia",
        "origen": "Todos",
        "destino": "Eslovaquia",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado\nPasaporte vigente\nAsistencia Médica de Viaje\nReserva de vuelo\nReserva de hospedaje\nSolvencia económica"
       },
       {
        "pasaporte": "Bolivia",
        "origen": "Todos",
        "destino": "Eslovenia",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado\nPasaporte vigente\nAsistencia Médica de Viaje\nReserva de vuelo\nReserva de hospedaje\nSolvencia económica"
       },
       {
        "pasaporte": "Bolivia",
        "origen": "Todos",
        "destino": "España",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado\nPasaporte vigente\nAsistencia Médica de Viaje\nReserva de vuelo\nReserva de hospedaje\nSolvencia económica"
       },
       {
        "pasaporte": "Bolivia",
        "origen": "Todos",
        "destino": "Estados Unidos de América",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado\nPasaporte vigente"
       },
       {
        "pasaporte": "Bolivia",
        "origen": "Todos",
        "destino": "Estonia",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado\nPasaporte vigente\nAsistencia Médica de Viaje\nReserva de vuelo\nReserva de hospedaje\nSolvencia económica"
       },
       {
        "pasaporte": "Bolivia",
        "origen": "Todos",
        "destino": "Eswatini",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": ""
       },
       {
        "pasaporte": "Bolivia",
        "origen": "Todos",
        "destino": "Falkland Islands",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": ""
       },
       {
        "pasaporte": "Bolivia",
        "origen": "Todos",
        "destino": "Fiji",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": ""
       },
       {
        "pasaporte": "Bolivia",
        "origen": "Todos",
        "destino": "Finlandia",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado\nPasaporte vigente\nAsistencia Médica de Viaje\nReserva de vuelo\nReserva de hospedaje\nSolvencia económica"
       },
       {
        "pasaporte": "Bolivia",
        "origen": "Todos",
        "destino": "Francia",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado\nPasaporte vigente\nAsistencia Médica de Viaje\nReserva de vuelo\nReserva de hospedaje\nSolvencia económica"
       },
       {
        "pasaporte": "Bolivia",
        "origen": "Todos",
        "destino": "Ghana",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": ""
       },
       {
        "pasaporte": "Bolivia",
        "origen": "Todos",
        "destino": "Gibraltar",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado\nPasaporte vigente\nAsistencia Médica de Viaje\nReserva de vuelo\nReserva de hospedaje\nSolvencia económica"
       },
       {
        "pasaporte": "Bolivia",
        "origen": "Todos",
        "destino": "Granada",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": ""
       },
       {
        "pasaporte": "Bolivia",
        "origen": "Todos",
        "destino": "Grecia",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado\nPasaporte vigente\nAsistencia Médica de Viaje\nReserva de vuelo\nReserva de hospedaje\nSolvencia económica"
       },
       {
        "pasaporte": "Bolivia",
        "origen": "Todos",
        "destino": "Groenlandia",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado\nPasaporte vigente\nAsistencia Médica de Viaje\nReserva de vuelo\nReserva de hospedaje\nSolvencia económica"
       },
       {
        "pasaporte": "Bolivia",
        "origen": "Todos",
        "destino": "Guam",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": ""
       },
       {
        "pasaporte": "Bolivia",
        "origen": "Todos",
        "destino": "Guatemala",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Bolivia",
        "origen": "Todos",
        "destino": "Guyana",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Bolivia",
        "origen": "Todos",
        "destino": "Honduras",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Bolivia",
        "origen": "Todos",
        "destino": "Hungría",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado\nPasaporte vigente\nAsistencia Médica de Viaje\nReserva de vuelo\nReserva de hospedaje\nSolvencia económica"
       },
       {
        "pasaporte": "Bolivia",
        "origen": "Todos",
        "destino": "Irak",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": ""
       },
       {
        "pasaporte": "Bolivia",
        "origen": "Todos",
        "destino": "Irlanda",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Bolivia",
        "origen": "Todos",
        "destino": "Isla Norfolk",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": ""
       },
       {
        "pasaporte": "Bolivia",
        "origen": "Todos",
        "destino": "Islandia",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado\nPasaporte vigente\nAsistencia Médica de Viaje\nReserva de vuelo\nReserva de hospedaje\nSolvencia económica"
       },
       {
        "pasaporte": "Bolivia",
        "origen": "Todos",
        "destino": "Islas Caimán",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Bolivia",
        "origen": "Todos",
        "destino": "Islas Feroe",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Bolivia",
        "origen": "Todos",
        "destino": "Islas Marianas del Norte",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Bolivia",
        "origen": "Todos",
        "destino": "Islas Marshall",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Bolivia",
        "origen": "Todos",
        "destino": "Islas Salomón",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Bolivia",
        "origen": "Todos",
        "destino": "Islas Turcas y Caicos",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Bolivia",
        "origen": "Todos",
        "destino": "Islas Vírgenes americanas",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Bolivia",
        "origen": "Todos",
        "destino": "Israel",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Bolivia",
        "origen": "Todos",
        "destino": "Italia",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado\nPasaporte vigente\nAsistencia Médica de Viaje\nReserva de vuelo\nReserva de hospedaje\nSolvencia económica"
       },
       {
        "pasaporte": "Bolivia",
        "origen": "Todos",
        "destino": "Japón",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado\nPasaporte vigente\nAsistencia Médica de Viaje\nReserva de vuelo\nReserva de hospedaje\nSolvencia económica"
       },
       {
        "pasaporte": "Bolivia",
        "origen": "Todos",
        "destino": "Kosovo",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": ""
       },
       {
        "pasaporte": "Bolivia",
        "origen": "Todos",
        "destino": "Kuwait",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Bolivia",
        "origen": "Todos",
        "destino": "Letonia",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado\nPasaporte vigente\nAsistencia Médica de Viaje\nReserva de vuelo\nReserva de hospedaje\nSolvencia económica"
       },
       {
        "pasaporte": "Bolivia",
        "origen": "Todos",
        "destino": "Líbano",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": ""
       },
       {
        "pasaporte": "Bolivia",
        "origen": "Todos",
        "destino": "Liberia",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": ""
       },
       {
        "pasaporte": "Bolivia",
        "origen": "Todos",
        "destino": "Liechtenstein",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado\nPasaporte vigente\nAsistencia Médica de Viaje\nReserva de vuelo\nReserva de hospedaje\nSolvencia económica"
       },
       {
        "pasaporte": "Bolivia",
        "origen": "Todos",
        "destino": "Lituania",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado\nPasaporte vigente\nAsistencia Médica de Viaje\nReserva de vuelo\nReserva de hospedaje\nSolvencia económica"
       },
       {
        "pasaporte": "Bolivia",
        "origen": "Todos",
        "destino": "Luxemburgo",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado\nPasaporte vigente\nAsistencia Médica de Viaje\nReserva de vuelo\nReserva de hospedaje\nSolvencia económica"
       },
       {
        "pasaporte": "Bolivia",
        "origen": "Todos",
        "destino": "Macedonia del Norte",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": ""
       },
       {
        "pasaporte": "Bolivia",
        "origen": "Todos",
        "destino": "Mali",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": ""
       },
       {
        "pasaporte": "Bolivia",
        "origen": "Todos",
        "destino": "Malta",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado\nPasaporte vigente\nAsistencia Médica de Viaje\nReserva de vuelo\nReserva de hospedaje\nSolvencia económica"
       },
       {
        "pasaporte": "Bolivia",
        "origen": "Todos",
        "destino": "Marruecos",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Bolivia",
        "origen": "Todos",
        "destino": "Mónaco",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado\nPasaporte vigente\nAsistencia Médica de Viaje\nReserva de vuelo\nReserva de hospedaje\nSolvencia económica"
       },
       {
        "pasaporte": "Bolivia",
        "origen": "Todos",
        "destino": "Montenegro",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado\nPasaporte vigente\nAsistencia Médica de Viaje\nReserva de vuelo\nReserva de hospedaje\nSolvencia económica"
       },
       {
        "pasaporte": "Bolivia",
        "origen": "Todos",
        "destino": "Namibia",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": ""
       },
       {
        "pasaporte": "Bolivia",
        "origen": "Todos",
        "destino": "Nauru",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": ""
       },
       {
        "pasaporte": "Bolivia",
        "origen": "Todos",
        "destino": "Níger",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": ""
       },
       {
        "pasaporte": "Bolivia",
        "origen": "Todos",
        "destino": "Noruega",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado\nPasaporte vigente\nAsistencia Médica de Viaje\nReserva de vuelo\nReserva de hospedaje\nSolvencia económica"
       },
       {
        "pasaporte": "Bolivia",
        "origen": "Todos",
        "destino": "Nueva Zelanda",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado\nPasaporte vigente\nAsistencia Médica de Viaje\nReserva de vuelo\nReserva de hospedaje\nSolvencia económica"
       },
       {
        "pasaporte": "Bolivia",
        "origen": "Todos",
        "destino": "Países Bajos",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado\nPasaporte vigente\nAsistencia Médica de Viaje\nReserva de vuelo\nReserva de hospedaje\nSolvencia económica"
       },
       {
        "pasaporte": "Bolivia",
        "origen": "Todos",
        "destino": "Papua Nueva Guinea",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": ""
       },
       {
        "pasaporte": "Bolivia",
        "origen": "Todos",
        "destino": "Polonia",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado\nPasaporte vigente\nAsistencia Médica de Viaje\nReserva de vuelo\nReserva de hospedaje\nSolvencia económica"
       },
       {
        "pasaporte": "Bolivia",
        "origen": "Todos",
        "destino": "Portugal",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado\nPasaporte vigente\nAsistencia Médica de Viaje\nReserva de vuelo\nReserva de hospedaje\nSolvencia económica"
       },
       {
        "pasaporte": "Bolivia",
        "origen": "Todos",
        "destino": "Puerto Rico",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Bolivia",
        "origen": "Todos",
        "destino": "Reino Unido",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Bolivia",
        "origen": "Todos",
        "destino": "República Centroafricana",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": ""
       },
       {
        "pasaporte": "Bolivia",
        "origen": "Todos",
        "destino": "República Checa",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado\nPasaporte vigente\nAsistencia Médica de Viaje\nReserva de vuelo\nReserva de hospedaje\nSolvencia económica"
       },
       {
        "pasaporte": "Bolivia",
        "origen": "Todos",
        "destino": "República del Congo",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": ""
       },
       {
        "pasaporte": "Bolivia",
        "origen": "Todos",
        "destino": "Rumanía",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado\nPasaporte vigente\nAsistencia Médica de Viaje\nReserva de vuelo\nReserva de hospedaje\nSolvencia económica"
       },
       {
        "pasaporte": "Bolivia",
        "origen": "Todos",
        "destino": "Samoa Americana",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": ""
       },
       {
        "pasaporte": "Bolivia",
        "origen": "Todos",
        "destino": "San Marino",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado\nPasaporte vigente\nAsistencia Médica de Viaje\nReserva de vuelo\nReserva de hospedaje\nSolvencia económica"
       },
       {
        "pasaporte": "Bolivia",
        "origen": "Todos",
        "destino": "San Martín",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Bolivia",
        "origen": "Todos",
        "destino": "San Pedro y Miquelón",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Bolivia",
        "origen": "Todos",
        "destino": "Senegal",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Bolivia",
        "origen": "Todos",
        "destino": "Serbia",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Bolivia",
        "origen": "Todos",
        "destino": "Somalia",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Bolivia",
        "origen": "Todos",
        "destino": "Sudán",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Bolivia",
        "origen": "Todos",
        "destino": "Suecia",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado\nPasaporte vigente\nAsistencia Médica de Viaje\nReserva de vuelo\nReserva de hospedaje\nSolvencia económica"
       },
       {
        "pasaporte": "Bolivia",
        "origen": "Todos",
        "destino": "Suiza",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado\nPasaporte vigente\nAsistencia Médica de Viaje\nReserva de vuelo\nReserva de hospedaje\nSolvencia económica"
       },
       {
        "pasaporte": "Bolivia",
        "origen": "Todos",
        "destino": "Taiwán",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Bolivia",
        "origen": "Todos",
        "destino": "Territorios Palestinos",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Bolivia",
        "origen": "Todos",
        "destino": "Tonga",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Bolivia",
        "origen": "Todos",
        "destino": "Trinidad y Tobago",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Bolivia",
        "origen": "Todos",
        "destino": "Túnez",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Bolivia",
        "origen": "Todos",
        "destino": "Turkmenistán",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Bolivia",
        "origen": "Todos",
        "destino": "Ucrania",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Bolivia",
        "origen": "Todos",
        "destino": "Vanuatu",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Bolivia",
        "origen": "Todos",
        "destino": "Yemen",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Argentina",
        "origen": "Todos",
        "destino": "Afganistán",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "Argentina",
        "origen": "Todos",
        "destino": "Alemania",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "Argentina",
        "origen": "Todos",
        "destino": "Andorra",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "Argentina",
        "origen": "Todos",
        "destino": "Angola",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "Argentina",
        "origen": "Todos",
        "destino": "Anguilla",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "Argentina",
        "origen": "Todos",
        "destino": "Antigua y Barbuda",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "Argentina",
        "origen": "Todos",
        "destino": "Armenia",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "Argentina",
        "origen": "Todos",
        "destino": "Aruba",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "Argentina",
        "origen": "Todos",
        "destino": "Austria",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "Argentina",
        "origen": "Todos",
        "destino": "Bahamas",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "Argentina",
        "origen": "Todos",
        "destino": "Barbados",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "Argentina",
        "origen": "Todos",
        "destino": "Belice",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "Argentina",
        "origen": "Todos",
        "destino": "Bermuda",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "Argentina",
        "origen": "Todos",
        "destino": "Bielorrusia",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "Argentina",
        "origen": "Todos",
        "destino": "Bolivia",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "Argentina",
        "origen": "Todos",
        "destino": "Bonaire, San Eustaquio y Saba",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "Argentina",
        "origen": "Todos",
        "destino": "Bosnia-Herzegovina",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "Argentina",
        "origen": "Todos",
        "destino": "Botswana",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "Argentina",
        "origen": "Todos",
        "destino": "Brasil",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "Argentina",
        "origen": "Todos",
        "destino": "Bulgaria",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "Argentina",
        "origen": "Todos",
        "destino": "Canadá",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "Argentina",
        "origen": "Todos",
        "destino": "Chile",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "Argentina",
        "origen": "Todos",
        "destino": "Chipre",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "Argentina",
        "origen": "Todos",
        "destino": "Ciudad del Vaticano",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "Argentina",
        "origen": "Todos",
        "destino": "Colombia",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "Argentina",
        "origen": "Todos",
        "destino": "Costa Rica",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "Argentina",
        "origen": "Todos",
        "destino": "Croacia",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "Argentina",
        "origen": "Todos",
        "destino": "Curaçao",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "Argentina",
        "origen": "Todos",
        "destino": "Dinamarca",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "Argentina",
        "origen": "Todos",
        "destino": "Dominica",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "Argentina",
        "origen": "Todos",
        "destino": "Ecuador",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "Argentina",
        "origen": "Todos",
        "destino": "El Salvador",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "Argentina",
        "origen": "Todos",
        "destino": "Emiratos Árabes Unidos",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "Argentina",
        "origen": "Todos",
        "destino": "Eslovaquia",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "Argentina",
        "origen": "Todos",
        "destino": "Eslovenia",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "Argentina",
        "origen": "Todos",
        "destino": "España",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "Argentina",
        "origen": "Todos",
        "destino": "Estonia",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "Argentina",
        "origen": "Todos",
        "destino": "Eswatini",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "Argentina",
        "origen": "Todos",
        "destino": "Falkland Islands",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "Argentina",
        "origen": "Todos",
        "destino": "Federación Rusa",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "Argentina",
        "origen": "Todos",
        "destino": "Fiji",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "Argentina",
        "origen": "Todos",
        "destino": "Filipinas",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "Argentina",
        "origen": "Todos",
        "destino": "Finlandia",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "Argentina",
        "origen": "Todos",
        "destino": "Francia",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "Argentina",
        "origen": "Todos",
        "destino": "Georgia",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "Argentina",
        "origen": "Todos",
        "destino": "Gibraltar",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "Argentina",
        "origen": "Todos",
        "destino": "Granada",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "Argentina",
        "origen": "Todos",
        "destino": "Grecia",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "Argentina",
        "origen": "Todos",
        "destino": "Groenlandia",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "Argentina",
        "origen": "Todos",
        "destino": "Guatemala",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "Argentina",
        "origen": "Todos",
        "destino": "Guayana Francesa",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "Argentina",
        "origen": "Todos",
        "destino": "Guyana",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "Argentina",
        "origen": "Todos",
        "destino": "Haití",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "Argentina",
        "origen": "Todos",
        "destino": "Honduras",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "Argentina",
        "origen": "Todos",
        "destino": "Hong Kong",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "Argentina",
        "origen": "Todos",
        "destino": "Hungría",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "Argentina",
        "origen": "Todos",
        "destino": "Indias Occidentales Francesas",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "Argentina",
        "origen": "Todos",
        "destino": "Irlanda",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "Argentina",
        "origen": "Todos",
        "destino": "Isla Mauricio",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "Argentina",
        "origen": "Todos",
        "destino": "Isla Reunión",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "Argentina",
        "origen": "Todos",
        "destino": "Islandia",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "Argentina",
        "origen": "Todos",
        "destino": "Islas Caimán",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "Argentina",
        "origen": "Todos",
        "destino": "Islas Cook",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "Argentina",
        "origen": "Todos",
        "destino": "Islas Feroe",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "Argentina",
        "origen": "Todos",
        "destino": "Islas Turcas y Caicos",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "Argentina",
        "origen": "Todos",
        "destino": "Islas Vírgenes Británicas",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "Argentina",
        "origen": "Todos",
        "destino": "Israel",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "Argentina",
        "origen": "Todos",
        "destino": "Italia",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "Argentina",
        "origen": "Todos",
        "destino": "Jamaica",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "Argentina",
        "origen": "Todos",
        "destino": "Japón",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "Argentina",
        "origen": "Todos",
        "destino": "Kazajistán",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "Argentina",
        "origen": "Todos",
        "destino": "Kirguistán",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "Argentina",
        "origen": "Todos",
        "destino": "Kiribati",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "Argentina",
        "origen": "Todos",
        "destino": "Kosovo",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "Argentina",
        "origen": "Todos",
        "destino": "Letonia",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "Argentina",
        "origen": "Todos",
        "destino": "Liechtenstein",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "Argentina",
        "origen": "Todos",
        "destino": "Lituania",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "Argentina",
        "origen": "Todos",
        "destino": "Luxemburgo",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "Argentina",
        "origen": "Todos",
        "destino": "Macao",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "Argentina",
        "origen": "Todos",
        "destino": "Macedonia del Norte",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "Argentina",
        "origen": "Todos",
        "destino": "Malasia",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "Argentina",
        "origen": "Todos",
        "destino": "Malta",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "Argentina",
        "origen": "Todos",
        "destino": "Marruecos",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "Argentina",
        "origen": "Todos",
        "destino": "Mayotte",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "Argentina",
        "origen": "Todos",
        "destino": "México",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "Argentina",
        "origen": "Todos",
        "destino": "Micronesia",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "Argentina",
        "origen": "Todos",
        "destino": "Moldavia",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "Argentina",
        "origen": "Todos",
        "destino": "Mónaco",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "Argentina",
        "origen": "Todos",
        "destino": "Mongolia",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "Argentina",
        "origen": "Todos",
        "destino": "Montenegro",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "Argentina",
        "origen": "Todos",
        "destino": "Montserrat",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "Argentina",
        "origen": "Todos",
        "destino": "Nicaragua",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "Argentina",
        "origen": "Todos",
        "destino": "Noruega",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "Argentina",
        "origen": "Todos",
        "destino": "Nueva Caledonia",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "Argentina",
        "origen": "Todos",
        "destino": "Países Bajos",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "Argentina",
        "origen": "Todos",
        "destino": "Panamá",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "Argentina",
        "origen": "Todos",
        "destino": "Paraguay",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "Argentina",
        "origen": "Todos",
        "destino": "Perú",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "Argentina",
        "origen": "Todos",
        "destino": "Polinesia Francesa",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "Argentina",
        "origen": "Todos",
        "destino": "Polonia",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "Argentina",
        "origen": "Todos",
        "destino": "Portugal",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "Argentina",
        "origen": "Todos",
        "destino": "Reino Unido",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "Argentina",
        "origen": "Todos",
        "destino": "República Checa",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "Argentina",
        "origen": "Todos",
        "destino": "República Dominicana",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "Argentina",
        "origen": "Todos",
        "destino": "Rumanía",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "Argentina",
        "origen": "Todos",
        "destino": "San Cristóbal y Nieves",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "Argentina",
        "origen": "Todos",
        "destino": "San Marino",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "Argentina",
        "origen": "Todos",
        "destino": "San Martín",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "Argentina",
        "origen": "Todos",
        "destino": "San Pedro y Miquelón",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "Argentina",
        "origen": "Todos",
        "destino": "San Vicente y las Granadinas",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "Argentina",
        "origen": "Todos",
        "destino": "Santa Elena",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "Argentina",
        "origen": "Todos",
        "destino": "Santa Lucía",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "Argentina",
        "origen": "Todos",
        "destino": "Serbia",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "Argentina",
        "origen": "Todos",
        "destino": "Singapur",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "Argentina",
        "origen": "Todos",
        "destino": "Sudáfrica",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "Argentina",
        "origen": "Todos",
        "destino": "Suecia",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "Argentina",
        "origen": "Todos",
        "destino": "Suiza",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "Argentina",
        "origen": "Todos",
        "destino": "Surinam",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "Argentina",
        "origen": "Todos",
        "destino": "Tailandia",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "Argentina",
        "origen": "Todos",
        "destino": "Tajikistán",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "Argentina",
        "origen": "Todos",
        "destino": "Territorios Palestinos",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "Argentina",
        "origen": "Todos",
        "destino": "Trinidad y Tobago",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "Argentina",
        "origen": "Todos",
        "destino": "Túnez",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "Argentina",
        "origen": "Todos",
        "destino": "Turquía",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "Argentina",
        "origen": "Todos",
        "destino": "Ucrania",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "Argentina",
        "origen": "Todos",
        "destino": "Uruguay",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "Argentina",
        "origen": "Todos",
        "destino": "Uzbekistán",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "Argentina",
        "origen": "Todos",
        "destino": "Vanuatu",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "Argentina",
        "origen": "Todos",
        "destino": "Venezuela",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "Argentina",
        "origen": "Todos",
        "destino": "Wallis y Futuna",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "Argentina",
        "origen": "Todos",
        "destino": "Bahrain",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.98",
        "requisitos": "Visado eléctronico\nPasaporte vigente"
       },
       {
        "pasaporte": "Argentina",
        "origen": "Todos",
        "destino": "Bangladesh",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.98",
        "requisitos": ""
       },
       {
        "pasaporte": "Argentina",
        "origen": "Todos",
        "destino": "Burkina Faso",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.98",
        "requisitos": ""
       },
       {
        "pasaporte": "Argentina",
        "origen": "Todos",
        "destino": "Burundi",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.98",
        "requisitos": ""
       },
       {
        "pasaporte": "Argentina",
        "origen": "Todos",
        "destino": "Cabo Verde",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.98",
        "requisitos": ""
       },
       {
        "pasaporte": "Argentina",
        "origen": "Todos",
        "destino": "Camboya",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.98",
        "requisitos": ""
       },
       {
        "pasaporte": "Argentina",
        "origen": "Todos",
        "destino": "Comoras",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.98",
        "requisitos": ""
       },
       {
        "pasaporte": "Argentina",
        "origen": "Todos",
        "destino": "Djibouti",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.98",
        "requisitos": ""
       },
       {
        "pasaporte": "Argentina",
        "origen": "Todos",
        "destino": "Egipto",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.98",
        "requisitos": ""
       },
       {
        "pasaporte": "Argentina",
        "origen": "Todos",
        "destino": "Etiopia",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.98",
        "requisitos": ""
       },
       {
        "pasaporte": "Argentina",
        "origen": "Todos",
        "destino": "Guinea-Bissau",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.98",
        "requisitos": ""
       },
       {
        "pasaporte": "Argentina",
        "origen": "Todos",
        "destino": "Indonesia",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.98",
        "requisitos": ""
       },
       {
        "pasaporte": "Argentina",
        "origen": "Todos",
        "destino": "Islas Marshall",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.98",
        "requisitos": ""
       },
       {
        "pasaporte": "Argentina",
        "origen": "Todos",
        "destino": "Islas Salomón",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.98",
        "requisitos": ""
       },
       {
        "pasaporte": "Argentina",
        "origen": "Todos",
        "destino": "Islas Seychelles",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.98",
        "requisitos": ""
       },
       {
        "pasaporte": "Argentina",
        "origen": "Todos",
        "destino": "Jordania",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.98",
        "requisitos": ""
       },
       {
        "pasaporte": "Argentina",
        "origen": "Todos",
        "destino": "Laos",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.98",
        "requisitos": ""
       },
       {
        "pasaporte": "Argentina",
        "origen": "Todos",
        "destino": "Líbano",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.98",
        "requisitos": ""
       },
       {
        "pasaporte": "Argentina",
        "origen": "Todos",
        "destino": "Madagascar",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.98",
        "requisitos": ""
       },
       {
        "pasaporte": "Argentina",
        "origen": "Todos",
        "destino": "Malawi",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.98",
        "requisitos": ""
       },
       {
        "pasaporte": "Argentina",
        "origen": "Todos",
        "destino": "Maldivas",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.98",
        "requisitos": ""
       },
       {
        "pasaporte": "Argentina",
        "origen": "Todos",
        "destino": "Mauritania",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.98",
        "requisitos": ""
       },
       {
        "pasaporte": "Argentina",
        "origen": "Todos",
        "destino": "Mozambique",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.98",
        "requisitos": ""
       },
       {
        "pasaporte": "Argentina",
        "origen": "Todos",
        "destino": "Namibia",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.98",
        "requisitos": ""
       },
       {
        "pasaporte": "Argentina",
        "origen": "Todos",
        "destino": "Nepal",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.98",
        "requisitos": ""
       },
       {
        "pasaporte": "Argentina",
        "origen": "Todos",
        "destino": "Niue",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.98",
        "requisitos": ""
       },
       {
        "pasaporte": "Argentina",
        "origen": "Todos",
        "destino": "Omán",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.98",
        "requisitos": ""
       },
       {
        "pasaporte": "Argentina",
        "origen": "Todos",
        "destino": "Palaos",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.98",
        "requisitos": ""
       },
       {
        "pasaporte": "Argentina",
        "origen": "Todos",
        "destino": "Qatar",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.98",
        "requisitos": ""
       },
       {
        "pasaporte": "Argentina",
        "origen": "Todos",
        "destino": "Ruanda",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.98",
        "requisitos": ""
       },
       {
        "pasaporte": "Argentina",
        "origen": "Todos",
        "destino": "Samoa",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.98",
        "requisitos": ""
       },
       {
        "pasaporte": "Argentina",
        "origen": "Todos",
        "destino": "Senegal",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.98",
        "requisitos": ""
       },
       {
        "pasaporte": "Argentina",
        "origen": "Todos",
        "destino": "Sri Lanka",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.98",
        "requisitos": ""
       },
       {
        "pasaporte": "Argentina",
        "origen": "Todos",
        "destino": "Tanzania",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.98",
        "requisitos": ""
       },
       {
        "pasaporte": "Argentina",
        "origen": "Todos",
        "destino": "Timor Oriental",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.98",
        "requisitos": ""
       },
       {
        "pasaporte": "Argentina",
        "origen": "Todos",
        "destino": "Tuvalu",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.98",
        "requisitos": ""
       },
       {
        "pasaporte": "Argentina",
        "origen": "Todos",
        "destino": "Zambia",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.98",
        "requisitos": ""
       },
       {
        "pasaporte": "Argentina",
        "origen": "Todos",
        "destino": "Zimbabue",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.98",
        "requisitos": ""
       },
       {
        "pasaporte": "Argentina",
        "origen": "Todos",
        "destino": "Corea del Sur",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.97",
        "requisitos": ""
       },
       {
        "pasaporte": "Argentina",
        "origen": "Todos",
        "destino": "Kenia",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.97",
        "requisitos": ""
       },
       {
        "pasaporte": "Argentina",
        "origen": "Todos",
        "destino": "Nueva Zelanda",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.97",
        "requisitos": "Autorización ETA\nPasaporte vigente"
       },
       {
        "pasaporte": "Argentina",
        "origen": "Todos",
        "destino": "Pakistan",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.97",
        "requisitos": ""
       },
       {
        "pasaporte": "Argentina",
        "origen": "Todos",
        "destino": "Australia",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.95",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Argentina",
        "origen": "Todos",
        "destino": "Azerbaiyán",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.95",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Argentina",
        "origen": "Todos",
        "destino": "Benín",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.95",
        "requisitos": ""
       },
       {
        "pasaporte": "Argentina",
        "origen": "Todos",
        "destino": "Birmania",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.95",
        "requisitos": ""
       },
       {
        "pasaporte": "Argentina",
        "origen": "Todos",
        "destino": "Bután",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.95",
        "requisitos": ""
       },
       {
        "pasaporte": "Argentina",
        "origen": "Todos",
        "destino": "Camerún",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.95",
        "requisitos": ""
       },
       {
        "pasaporte": "Argentina",
        "origen": "Todos",
        "destino": "Costa de Marfil",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.95",
        "requisitos": ""
       },
       {
        "pasaporte": "Argentina",
        "origen": "Todos",
        "destino": "Gabón",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.95",
        "requisitos": ""
       },
       {
        "pasaporte": "Argentina",
        "origen": "Todos",
        "destino": "Guinea",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.95",
        "requisitos": ""
       },
       {
        "pasaporte": "Argentina",
        "origen": "Todos",
        "destino": "Guinea Ecuatorial",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.95",
        "requisitos": ""
       },
       {
        "pasaporte": "Argentina",
        "origen": "Todos",
        "destino": "India",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.95",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Argentina",
        "origen": "Todos",
        "destino": "Irán",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.95",
        "requisitos": ""
       },
       {
        "pasaporte": "Argentina",
        "origen": "Todos",
        "destino": "Isla Norfolk",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.95",
        "requisitos": ""
       },
       {
        "pasaporte": "Argentina",
        "origen": "Todos",
        "destino": "Lesoto",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.95",
        "requisitos": ""
       },
       {
        "pasaporte": "Argentina",
        "origen": "Todos",
        "destino": "Libia",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.95",
        "requisitos": ""
       },
       {
        "pasaporte": "Argentina",
        "origen": "Todos",
        "destino": "Nigeria",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.95",
        "requisitos": ""
       },
       {
        "pasaporte": "Argentina",
        "origen": "Todos",
        "destino": "Papua Nueva Guinea",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.95",
        "requisitos": ""
       },
       {
        "pasaporte": "Argentina",
        "origen": "Todos",
        "destino": "República Democrática del Congo",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.95",
        "requisitos": ""
       },
       {
        "pasaporte": "Argentina",
        "origen": "Todos",
        "destino": "Santo Tomé y Príncipe",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.95",
        "requisitos": ""
       },
       {
        "pasaporte": "Argentina",
        "origen": "Todos",
        "destino": "Sierra Leona",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.95",
        "requisitos": ""
       },
       {
        "pasaporte": "Argentina",
        "origen": "Todos",
        "destino": "Siria",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.95",
        "requisitos": ""
       },
       {
        "pasaporte": "Argentina",
        "origen": "Todos",
        "destino": "Sudán del Sur",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.95",
        "requisitos": ""
       },
       {
        "pasaporte": "Argentina",
        "origen": "Todos",
        "destino": "Togo",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.95",
        "requisitos": ""
       },
       {
        "pasaporte": "Argentina",
        "origen": "Todos",
        "destino": "Uganda",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.95",
        "requisitos": ""
       },
       {
        "pasaporte": "Argentina",
        "origen": "Todos",
        "destino": "Vietnam",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.95",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Argentina",
        "origen": "Todos",
        "destino": "Afganistán",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.93",
        "requisitos": ""
       },
       {
        "pasaporte": "Argentina",
        "origen": "Todos",
        "destino": "Arabia Saudí",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.93",
        "requisitos": ""
       },
       {
        "pasaporte": "Argentina",
        "origen": "Todos",
        "destino": "Argelia",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.93",
        "requisitos": ""
       },
       {
        "pasaporte": "Argentina",
        "origen": "Todos",
        "destino": "Brunei",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.93",
        "requisitos": ""
       },
       {
        "pasaporte": "Argentina",
        "origen": "Todos",
        "destino": "Chad",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.93",
        "requisitos": ""
       },
       {
        "pasaporte": "Argentina",
        "origen": "Todos",
        "destino": "China",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.93",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Argentina",
        "origen": "Todos",
        "destino": "Corea del Norte",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.93",
        "requisitos": ""
       },
       {
        "pasaporte": "Argentina",
        "origen": "Todos",
        "destino": "Cuba",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.93",
        "requisitos": "Visado\nPasaporte vigentr\nSeguro médico"
       },
       {
        "pasaporte": "Argentina",
        "origen": "Todos",
        "destino": "Eritrea",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.93",
        "requisitos": ""
       },
       {
        "pasaporte": "Argentina",
        "origen": "Todos",
        "destino": "Estados Unidos de América",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.93",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Argentina",
        "origen": "Todos",
        "destino": "Gambia",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.93",
        "requisitos": ""
       },
       {
        "pasaporte": "Argentina",
        "origen": "Todos",
        "destino": "Ghana",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.93",
        "requisitos": ""
       },
       {
        "pasaporte": "Argentina",
        "origen": "Todos",
        "destino": "Guam",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.93",
        "requisitos": ""
       },
       {
        "pasaporte": "Argentina",
        "origen": "Todos",
        "destino": "Irak",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.93",
        "requisitos": ""
       },
       {
        "pasaporte": "Argentina",
        "origen": "Todos",
        "destino": "Islas Marianas del Norte",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.93",
        "requisitos": ""
       },
       {
        "pasaporte": "Argentina",
        "origen": "Todos",
        "destino": "Islas Vírgenes americanas",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.93",
        "requisitos": ""
       },
       {
        "pasaporte": "Argentina",
        "origen": "Todos",
        "destino": "Kuwait",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.93",
        "requisitos": ""
       },
       {
        "pasaporte": "Argentina",
        "origen": "Todos",
        "destino": "Liberia",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.93",
        "requisitos": ""
       },
       {
        "pasaporte": "Argentina",
        "origen": "Todos",
        "destino": "Mali",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.93",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Argentina",
        "origen": "Todos",
        "destino": "Nauru",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.93",
        "requisitos": ""
       },
       {
        "pasaporte": "Argentina",
        "origen": "Todos",
        "destino": "Níger",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.93",
        "requisitos": ""
       },
       {
        "pasaporte": "Argentina",
        "origen": "Todos",
        "destino": "Puerto Rico",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.93",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Argentina",
        "origen": "Todos",
        "destino": "República Centroafricana",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.93",
        "requisitos": ""
       },
       {
        "pasaporte": "Argentina",
        "origen": "Todos",
        "destino": "República del Congo",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.93",
        "requisitos": ""
       },
       {
        "pasaporte": "Argentina",
        "origen": "Todos",
        "destino": "Samoa Americana",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.93",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Argentina",
        "origen": "Todos",
        "destino": "Somalia",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.93",
        "requisitos": ""
       },
       {
        "pasaporte": "Argentina",
        "origen": "Todos",
        "destino": "Sudán",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.93",
        "requisitos": ""
       },
       {
        "pasaporte": "Argentina",
        "origen": "Todos",
        "destino": "Taiwán",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.93",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Argentina",
        "origen": "Todos",
        "destino": "Tonga",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.93",
        "requisitos": ""
       },
       {
        "pasaporte": "Argentina",
        "origen": "Todos",
        "destino": "Turkmenistán",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.93",
        "requisitos": ""
       },
       {
        "pasaporte": "Nicaragua",
        "origen": "Todos",
        "destino": "Albania",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Nicaragua",
        "origen": "Todos",
        "destino": "Alemania",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Nicaragua",
        "origen": "Todos",
        "destino": "Andorra",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Nicaragua",
        "origen": "Todos",
        "destino": "Anguilla",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Nicaragua",
        "origen": "Todos",
        "destino": "Argentina",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Nicaragua",
        "origen": "Todos",
        "destino": "Aruba",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Nicaragua",
        "origen": "Todos",
        "destino": "Austria",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Nicaragua",
        "origen": "Todos",
        "destino": "Bahamas",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Nicaragua",
        "origen": "Todos",
        "destino": "Barbados",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Nicaragua",
        "origen": "Todos",
        "destino": "Bélgica",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Nicaragua",
        "origen": "Todos",
        "destino": "Belice",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Nicaragua",
        "origen": "Todos",
        "destino": "Bermuda",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Nicaragua",
        "origen": "Todos",
        "destino": "Bielorrusia",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Nicaragua",
        "origen": "Todos",
        "destino": "Bonaire, San Eustaquio y Saba",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Nicaragua",
        "origen": "Todos",
        "destino": "Bosnia-Herzegovina",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Nicaragua",
        "origen": "Todos",
        "destino": "Brasil",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Nicaragua",
        "origen": "Todos",
        "destino": "Bulgaria",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Nicaragua",
        "origen": "Todos",
        "destino": "Chile",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Nicaragua",
        "origen": "Todos",
        "destino": "Chipre",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Nicaragua",
        "origen": "Todos",
        "destino": "Ciudad del Vaticano",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Nicaragua",
        "origen": "Todos",
        "destino": "Croacia",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Nicaragua",
        "origen": "Todos",
        "destino": "Curaçao",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Nicaragua",
        "origen": "Todos",
        "destino": "Dinamarca",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Nicaragua",
        "origen": "Todos",
        "destino": "Dominica",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Nicaragua",
        "origen": "Todos",
        "destino": "Ecuador",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Nicaragua",
        "origen": "Todos",
        "destino": "El Salvador",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Nicaragua",
        "origen": "Todos",
        "destino": "Eslovaquia",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Nicaragua",
        "origen": "Todos",
        "destino": "Eslovenia",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Nicaragua",
        "origen": "Todos",
        "destino": "España",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Nicaragua",
        "origen": "Todos",
        "destino": "Estonia",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Nicaragua",
        "origen": "Todos",
        "destino": "Federación Rusa",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Nicaragua",
        "origen": "Todos",
        "destino": "Filipinas",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Nicaragua",
        "origen": "Todos",
        "destino": "Finlandia",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Nicaragua",
        "origen": "Todos",
        "destino": "Francia",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Nicaragua",
        "origen": "Todos",
        "destino": "Gibraltar",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Nicaragua",
        "origen": "Todos",
        "destino": "Granada",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Nicaragua",
        "origen": "Todos",
        "destino": "Grecia",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Nicaragua",
        "origen": "Todos",
        "destino": "Groenlandia",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Nicaragua",
        "origen": "Todos",
        "destino": "Guatemala",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Nicaragua",
        "origen": "Todos",
        "destino": "Guayana Francesa",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Nicaragua",
        "origen": "Todos",
        "destino": "Haití",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Nicaragua",
        "origen": "Todos",
        "destino": "Honduras",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Nicaragua",
        "origen": "Todos",
        "destino": "Hungría",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Nicaragua",
        "origen": "Todos",
        "destino": "Indias Occidentales Francesas",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Nicaragua",
        "origen": "Todos",
        "destino": "Irlanda",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Nicaragua",
        "origen": "Todos",
        "destino": "Isla Reunión",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Nicaragua",
        "origen": "Todos",
        "destino": "Islandia",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Nicaragua",
        "origen": "Todos",
        "destino": "Islas Cook",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Nicaragua",
        "origen": "Todos",
        "destino": "Islas Feroe",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Nicaragua",
        "origen": "Todos",
        "destino": "Islas Vírgenes Británicas",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Nicaragua",
        "origen": "Todos",
        "destino": "Italia",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Nicaragua",
        "origen": "Todos",
        "destino": "Kosovo",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Nicaragua",
        "origen": "Todos",
        "destino": "Letonia",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Nicaragua",
        "origen": "Todos",
        "destino": "Liechtenstein",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Nicaragua",
        "origen": "Todos",
        "destino": "Lituania",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Nicaragua",
        "origen": "Todos",
        "destino": "Luxemburgo",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Nicaragua",
        "origen": "Todos",
        "destino": "Macedonia del Norte",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Nicaragua",
        "origen": "Todos",
        "destino": "Malasia",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Nicaragua",
        "origen": "Todos",
        "destino": "Malta",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Nicaragua",
        "origen": "Todos",
        "destino": "Mayotte",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Nicaragua",
        "origen": "Todos",
        "destino": "Micronesia",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Nicaragua",
        "origen": "Todos",
        "destino": "Moldavia",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Nicaragua",
        "origen": "Todos",
        "destino": "Mónaco",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Nicaragua",
        "origen": "Todos",
        "destino": "Montenegro",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Nicaragua",
        "origen": "Todos",
        "destino": "Montserrat",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Nicaragua",
        "origen": "Todos",
        "destino": "Noruega",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Nicaragua",
        "origen": "Todos",
        "destino": "Nueva Caledonia",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Nicaragua",
        "origen": "Todos",
        "destino": "Países Bajos",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Nicaragua",
        "origen": "Todos",
        "destino": "Panamá",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Nicaragua",
        "origen": "Todos",
        "destino": "Paraguay",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Nicaragua",
        "origen": "Todos",
        "destino": "Polinesia Francesa",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Nicaragua",
        "origen": "Todos",
        "destino": "Polonia",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Nicaragua",
        "origen": "Todos",
        "destino": "Portugal",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Nicaragua",
        "origen": "Todos",
        "destino": "Reino Unido",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Nicaragua",
        "origen": "Todos",
        "destino": "República Checa",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Nicaragua",
        "origen": "Todos",
        "destino": "República Dominicana",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Nicaragua",
        "origen": "Todos",
        "destino": "Rumanía",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Nicaragua",
        "origen": "Todos",
        "destino": "San Cristóbal y Nieves",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Nicaragua",
        "origen": "Todos",
        "destino": "San Marino",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Nicaragua",
        "origen": "Todos",
        "destino": "San Martín",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Nicaragua",
        "origen": "Todos",
        "destino": "San Pedro y Miquelón",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Nicaragua",
        "origen": "Todos",
        "destino": "San Vicente y las Granadinas",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Nicaragua",
        "origen": "Todos",
        "destino": "Santa Elena",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Nicaragua",
        "origen": "Todos",
        "destino": "Singapur",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Nicaragua",
        "origen": "Todos",
        "destino": "Suecia",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Nicaragua",
        "origen": "Todos",
        "destino": "Suiza",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Nicaragua",
        "origen": "Todos",
        "destino": "Surinam",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Nicaragua",
        "origen": "Todos",
        "destino": "Taiwán",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Nicaragua",
        "origen": "Todos",
        "destino": "Trinidad y Tobago",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Nicaragua",
        "origen": "Todos",
        "destino": "Turquía",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Nicaragua",
        "origen": "Todos",
        "destino": "Uruguay",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Nicaragua",
        "origen": "Todos",
        "destino": "Uzbekistán",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Nicaragua",
        "origen": "Todos",
        "destino": "Venezuela",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Nicaragua",
        "origen": "Todos",
        "destino": "Wallis y Futuna",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Nicaragua",
        "origen": "Todos",
        "destino": "Bangladesh",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.98",
        "requisitos": ""
       },
       {
        "pasaporte": "Nicaragua",
        "origen": "Todos",
        "destino": "Bolivia",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.98",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Nicaragua",
        "origen": "Todos",
        "destino": "Burundi",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.98",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Nicaragua",
        "origen": "Todos",
        "destino": "Cabo Verde",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.98",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Nicaragua",
        "origen": "Todos",
        "destino": "Camboya",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.98",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Nicaragua",
        "origen": "Todos",
        "destino": "Comoras",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.98",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Nicaragua",
        "origen": "Todos",
        "destino": "Djibouti",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.98",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Nicaragua",
        "origen": "Todos",
        "destino": "Egipto",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.98",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Nicaragua",
        "origen": "Todos",
        "destino": "Guinea-Bissau",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.98",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Nicaragua",
        "origen": "Todos",
        "destino": "Isla Mauricio",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.98",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Nicaragua",
        "origen": "Todos",
        "destino": "Islas Seychelles",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.98",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Nicaragua",
        "origen": "Todos",
        "destino": "Jordania",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.98",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Nicaragua",
        "origen": "Todos",
        "destino": "Laos",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.98",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Nicaragua",
        "origen": "Todos",
        "destino": "Macao",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.98",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Nicaragua",
        "origen": "Todos",
        "destino": "Madagascar",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.98",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Nicaragua",
        "origen": "Todos",
        "destino": "Malawi",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.98",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Nicaragua",
        "origen": "Todos",
        "destino": "Maldivas",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.98",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Nicaragua",
        "origen": "Todos",
        "destino": "Mauritania",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.98",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Nicaragua",
        "origen": "Todos",
        "destino": "Mozambique",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.98",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Nicaragua",
        "origen": "Todos",
        "destino": "Namibia",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.98",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Nicaragua",
        "origen": "Todos",
        "destino": "Nepal",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.98",
        "requisitos": ""
       },
       {
        "pasaporte": "Nicaragua",
        "origen": "Todos",
        "destino": "Niue",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.98",
        "requisitos": ""
       },
       {
        "pasaporte": "Nicaragua",
        "origen": "Todos",
        "destino": "Palaos",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.98",
        "requisitos": ""
       },
       {
        "pasaporte": "Nicaragua",
        "origen": "Todos",
        "destino": "Ruanda",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.98",
        "requisitos": ""
       },
       {
        "pasaporte": "Nicaragua",
        "origen": "Todos",
        "destino": "Samoa",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.98",
        "requisitos": ""
       },
       {
        "pasaporte": "Nicaragua",
        "origen": "Todos",
        "destino": "Santa Lucía",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.98",
        "requisitos": ""
       },
       {
        "pasaporte": "Nicaragua",
        "origen": "Todos",
        "destino": "Sri Lanka",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.98",
        "requisitos": ""
       },
       {
        "pasaporte": "Nicaragua",
        "origen": "Todos",
        "destino": "Tanzania",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.98",
        "requisitos": ""
       },
       {
        "pasaporte": "Nicaragua",
        "origen": "Todos",
        "destino": "Timor Oriental",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.98",
        "requisitos": ""
       },
       {
        "pasaporte": "Nicaragua",
        "origen": "Todos",
        "destino": "Tuvalu",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.98",
        "requisitos": ""
       },
       {
        "pasaporte": "Nicaragua",
        "origen": "Todos",
        "destino": "Zambia",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.98",
        "requisitos": ""
       },
       {
        "pasaporte": "Nicaragua",
        "origen": "Todos",
        "destino": "Zimbabue",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.98",
        "requisitos": ""
       },
       {
        "pasaporte": "Nicaragua",
        "origen": "Todos",
        "destino": "Corea del Sur",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.98",
        "requisitos": "Autorización ETA\nPasaporte vigente"
       },
       {
        "pasaporte": "Nicaragua",
        "origen": "Todos",
        "destino": "Kenia",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.98",
        "requisitos": ""
       },
       {
        "pasaporte": "Nicaragua",
        "origen": "Todos",
        "destino": "Australia",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.95",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Nicaragua",
        "origen": "Todos",
        "destino": "Bahrain",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.95",
        "requisitos": "Visado eléctronico\nPasaporte vigente"
       },
       {
        "pasaporte": "Nicaragua",
        "origen": "Todos",
        "destino": "Benín",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.95",
        "requisitos": ""
       },
       {
        "pasaporte": "Nicaragua",
        "origen": "Todos",
        "destino": "Botswana",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.95",
        "requisitos": ""
       },
       {
        "pasaporte": "Nicaragua",
        "origen": "Todos",
        "destino": "Burkina Faso",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.95",
        "requisitos": ""
       },
       {
        "pasaporte": "Nicaragua",
        "origen": "Todos",
        "destino": "Bután",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.95",
        "requisitos": ""
       },
       {
        "pasaporte": "Nicaragua",
        "origen": "Todos",
        "destino": "Camerún",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.95",
        "requisitos": ""
       },
       {
        "pasaporte": "Nicaragua",
        "origen": "Todos",
        "destino": "Colombia",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.95",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Nicaragua",
        "origen": "Todos",
        "destino": "Costa de Marfil",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.95",
        "requisitos": ""
       },
       {
        "pasaporte": "Nicaragua",
        "origen": "Todos",
        "destino": "Emiratos Árabes Unidos",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.95",
        "requisitos": "Visado eléctronico\nPasaporte vigente"
       },
       {
        "pasaporte": "Nicaragua",
        "origen": "Todos",
        "destino": "Etiopia",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.96",
        "requisitos": ""
       },
       {
        "pasaporte": "Nicaragua",
        "origen": "Todos",
        "destino": "Gabón",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.98",
        "requisitos": ""
       },
       {
        "pasaporte": "Nicaragua",
        "origen": "Todos",
        "destino": "Guinea",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.98",
        "requisitos": ""
       },
       {
        "pasaporte": "Nicaragua",
        "origen": "Todos",
        "destino": "Guinea Ecuatorial",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.98",
        "requisitos": ""
       },
       {
        "pasaporte": "Nicaragua",
        "origen": "Todos",
        "destino": "Hong Kong",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.98",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Nicaragua",
        "origen": "Todos",
        "destino": "India",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.98",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Nicaragua",
        "origen": "Todos",
        "destino": "Indonesia",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.98",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Nicaragua",
        "origen": "Todos",
        "destino": "Irán",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.98",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Nicaragua",
        "origen": "Todos",
        "destino": "Kazajistán",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.98",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Nicaragua",
        "origen": "Todos",
        "destino": "Kirguistán",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.98",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Nicaragua",
        "origen": "Todos",
        "destino": "Lesoto",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.98",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Nicaragua",
        "origen": "Todos",
        "destino": "Libia",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.98",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Nicaragua",
        "origen": "Todos",
        "destino": "Mongolia",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.98",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Nicaragua",
        "origen": "Todos",
        "destino": "Nigeria",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.98",
        "requisitos": ""
       },
       {
        "pasaporte": "Nicaragua",
        "origen": "Todos",
        "destino": "Omán",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.98",
        "requisitos": ""
       },
       {
        "pasaporte": "Nicaragua",
        "origen": "Todos",
        "destino": "Pakistán",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.98",
        "requisitos": ""
       },
       {
        "pasaporte": "Nicaragua",
        "origen": "Todos",
        "destino": "Papua Nueva Guinea",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.98",
        "requisitos": ""
       },
       {
        "pasaporte": "Nicaragua",
        "origen": "Todos",
        "destino": "Qatar",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.98",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Nicaragua",
        "origen": "Todos",
        "destino": "República Democrática del Congo",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.98",
        "requisitos": ""
       },
       {
        "pasaporte": "Nicaragua",
        "origen": "Todos",
        "destino": "Santo Tomé y Príncipe",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.98",
        "requisitos": ""
       },
       {
        "pasaporte": "Nicaragua",
        "origen": "Todos",
        "destino": "Sierra Leona",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.98",
        "requisitos": ""
       },
       {
        "pasaporte": "Nicaragua",
        "origen": "Todos",
        "destino": "Siria",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.95",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Nicaragua",
        "origen": "Todos",
        "destino": "Sudán del Sur",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.95",
        "requisitos": ""
       },
       {
        "pasaporte": "Nicaragua",
        "origen": "Todos",
        "destino": "Tajikistán",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.95",
        "requisitos": ""
       },
       {
        "pasaporte": "Nicaragua",
        "origen": "Todos",
        "destino": "Togo",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.95",
        "requisitos": ""
       },
       {
        "pasaporte": "Nicaragua",
        "origen": "Todos",
        "destino": "Uganda",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.95",
        "requisitos": ""
       },
       {
        "pasaporte": "Nicaragua",
        "origen": "Todos",
        "destino": "Vietnam",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.95",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Nicaragua",
        "origen": "Todos",
        "destino": "Afganistán",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": ""
       },
       {
        "pasaporte": "Nicaragua",
        "origen": "Todos",
        "destino": "Angola",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": ""
       },
       {
        "pasaporte": "Nicaragua",
        "origen": "Todos",
        "destino": "Arabia Saudí",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Nicaragua",
        "origen": "Todos",
        "destino": "Argelia",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Nicaragua",
        "origen": "Todos",
        "destino": "Azerbaiyán",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Nicaragua",
        "origen": "Todos",
        "destino": "Birmania",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": ""
       },
       {
        "pasaporte": "Nicaragua",
        "origen": "Todos",
        "destino": "Brunei",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": ""
       },
       {
        "pasaporte": "Nicaragua",
        "origen": "Todos",
        "destino": "Canadá",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Nicaragua",
        "origen": "Todos",
        "destino": "Chad",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Nicaragua",
        "origen": "Todos",
        "destino": "China",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Nicaragua",
        "origen": "Todos",
        "destino": "Corea del Norte",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Nicaragua",
        "origen": "Todos",
        "destino": "Costa Rica",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Nicaragua",
        "origen": "Todos",
        "destino": "Cuba",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado\nPasaporte vigente\nSeguro médico"
       },
       {
        "pasaporte": "Nicaragua",
        "origen": "Todos",
        "destino": "Eritrea",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": ""
       },
       {
        "pasaporte": "Nicaragua",
        "origen": "Todos",
        "destino": "Estados Unidos de América",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Nicaragua",
        "origen": "Todos",
        "destino": "Eswatini",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": ""
       },
       {
        "pasaporte": "Nicaragua",
        "origen": "Todos",
        "destino": "Falkland Islands",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": ""
       },
       {
        "pasaporte": "Nicaragua",
        "origen": "Todos",
        "destino": "Fiji",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": ""
       },
       {
        "pasaporte": "Nicaragua",
        "origen": "Todos",
        "destino": "Gambia",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": ""
       },
       {
        "pasaporte": "Nicaragua",
        "origen": "Todos",
        "destino": "Georgia",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": ""
       },
       {
        "pasaporte": "Nicaragua",
        "origen": "Todos",
        "destino": "Ghana",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": ""
       },
       {
        "pasaporte": "Nicaragua",
        "origen": "Todos",
        "destino": "Guam",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": ""
       },
       {
        "pasaporte": "Nicaragua",
        "origen": "Todos",
        "destino": "Guyana",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": ""
       },
       {
        "pasaporte": "Nicaragua",
        "origen": "Todos",
        "destino": "Irak",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": ""
       },
       {
        "pasaporte": "Nicaragua",
        "origen": "Todos",
        "destino": "Isla Norfolk",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": ""
       },
       {
        "pasaporte": "Nicaragua",
        "origen": "Todos",
        "destino": "Islas Caimán",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Nicaragua",
        "origen": "Todos",
        "destino": "Islas Marianas del Norte",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Nicaragua",
        "origen": "Todos",
        "destino": "Islas Marshall",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Nicaragua",
        "origen": "Todos",
        "destino": "Islas Salomón",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Nicaragua",
        "origen": "Todos",
        "destino": "Islas Turcas y Caicos",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Nicaragua",
        "origen": "Todos",
        "destino": "Islas Vírgenes americanas",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Nicaragua",
        "origen": "Todos",
        "destino": "Israel",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Nicaragua",
        "origen": "Todos",
        "destino": "Jamaica",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Nicaragua",
        "origen": "Todos",
        "destino": "Japón",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Nicaragua",
        "origen": "Todos",
        "destino": "Kiribati",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": ""
       },
       {
        "pasaporte": "Nicaragua",
        "origen": "Todos",
        "destino": "Kuwait",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": ""
       },
       {
        "pasaporte": "Nicaragua",
        "origen": "Todos",
        "destino": "Líbano",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": ""
       },
       {
        "pasaporte": "Nicaragua",
        "origen": "Todos",
        "destino": "Liberia",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": ""
       },
       {
        "pasaporte": "Nicaragua",
        "origen": "Todos",
        "destino": "Mali",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": ""
       },
       {
        "pasaporte": "Nicaragua",
        "origen": "Todos",
        "destino": "Marruecos",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Nicaragua",
        "origen": "Todos",
        "destino": "México",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Nicaragua",
        "origen": "Todos",
        "destino": "Nauru",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": ""
       },
       {
        "pasaporte": "Nicaragua",
        "origen": "Todos",
        "destino": "Níger",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": ""
       },
       {
        "pasaporte": "Nicaragua",
        "origen": "Todos",
        "destino": "Nueva Zelanda",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Nicaragua",
        "origen": "Todos",
        "destino": "Perú",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Nicaragua",
        "origen": "Todos",
        "destino": "Puerto Rico",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Nicaragua",
        "origen": "Todos",
        "destino": "República Centroafricana",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": ""
       },
       {
        "pasaporte": "Nicaragua",
        "origen": "Todos",
        "destino": "República del Congo",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": ""
       },
       {
        "pasaporte": "Nicaragua",
        "origen": "Todos",
        "destino": "Samoa Americana",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": ""
       },
       {
        "pasaporte": "Nicaragua",
        "origen": "Todos",
        "destino": "Senegal",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Nicaragua",
        "origen": "Todos",
        "destino": "Serbia",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Nicaragua",
        "origen": "Todos",
        "destino": "Somalia",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": ""
       },
       {
        "pasaporte": "Nicaragua",
        "origen": "Todos",
        "destino": "Sudáfrica",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": ""
       },
       {
        "pasaporte": "Nicaragua",
        "origen": "Todos",
        "destino": "Sudán",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": ""
       },
       {
        "pasaporte": "Nicaragua",
        "origen": "Todos",
        "destino": "Tailandia",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Nicaragua",
        "origen": "Todos",
        "destino": "Territorios Palestinos",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": ""
       },
       {
        "pasaporte": "Nicaragua",
        "origen": "Todos",
        "destino": "Tonga",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": ""
       },
       {
        "pasaporte": "Nicaragua",
        "origen": "Todos",
        "destino": "Túnez",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": ""
       },
       {
        "pasaporte": "Nicaragua",
        "origen": "Todos",
        "destino": "Turkmenistán",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": ""
       },
       {
        "pasaporte": "Nicaragua",
        "origen": "Todos",
        "destino": "Ucrania",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": ""
       },
       {
        "pasaporte": "Nicaragua",
        "origen": "Todos",
        "destino": "Vanuatu",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": ""
       },
       {
        "pasaporte": "Nicaragua",
        "origen": "Todos",
        "destino": "Yemen",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": ""
       },
       {
        "pasaporte": "Costa Rica",
        "origen": "Todos",
        "destino": "Albania",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": ""
       },
       {
        "pasaporte": "Costa Rica",
        "origen": "Todos",
        "destino": "Alemania",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Costa Rica",
        "origen": "Todos",
        "destino": "Andorra",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Costa Rica",
        "origen": "Todos",
        "destino": "Anguilla",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": ""
       },
       {
        "pasaporte": "Costa Rica",
        "origen": "Todos",
        "destino": "Argentina",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "Costa Rica",
        "origen": "Todos",
        "destino": "Aruba",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "Costa Rica",
        "origen": "Todos",
        "destino": "Austria",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Costa Rica",
        "origen": "Todos",
        "destino": "Bahamas",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "Costa Rica",
        "origen": "Todos",
        "destino": "Barbados",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "Costa Rica",
        "origen": "Todos",
        "destino": "Bélgica",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Costa Rica",
        "origen": "Todos",
        "destino": "Belice",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "Costa Rica",
        "origen": "Todos",
        "destino": "Bermuda",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "Costa Rica",
        "origen": "Todos",
        "destino": "Bolivia",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "Costa Rica",
        "origen": "Todos",
        "destino": "Bonaire, San Eustaquio y Saba",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "Costa Rica",
        "origen": "Todos",
        "destino": "Bosnia-Herzegovina",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "Costa Rica",
        "origen": "Todos",
        "destino": "Botswana",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "Costa Rica",
        "origen": "Todos",
        "destino": "Brasil",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "Costa Rica",
        "origen": "Todos",
        "destino": "Brunei",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "Costa Rica",
        "origen": "Todos",
        "destino": "Bulgaria",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Costa Rica",
        "origen": "Todos",
        "destino": "Chile",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "Costa Rica",
        "origen": "Todos",
        "destino": "Chipre",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Costa Rica",
        "origen": "Todos",
        "destino": "Ciudad del Vaticano",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Costa Rica",
        "origen": "Todos",
        "destino": "Colombia",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "Costa Rica",
        "origen": "Todos",
        "destino": "Croacia",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "Costa Rica",
        "origen": "Todos",
        "destino": "Curaçao",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "Costa Rica",
        "origen": "Todos",
        "destino": "Dinamarca",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Costa Rica",
        "origen": "Todos",
        "destino": "Dominica",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "Costa Rica",
        "origen": "Todos",
        "destino": "Ecuador",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "Costa Rica",
        "origen": "Todos",
        "destino": "El Salvador",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "Costa Rica",
        "origen": "Todos",
        "destino": "Emiratos Árabes Unidos",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Visado eléctronico\nPasaporte vigente"
       },
       {
        "pasaporte": "Costa Rica",
        "origen": "Todos",
        "destino": "Eslovaquia",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Costa Rica",
        "origen": "Todos",
        "destino": "Eslovenia",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Costa Rica",
        "origen": "Todos",
        "destino": "España",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Costa Rica",
        "origen": "Todos",
        "destino": "Estonia",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Costa Rica",
        "origen": "Todos",
        "destino": "Federación Rusa",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "Costa Rica",
        "origen": "Todos",
        "destino": "Filipinas",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": ""
       },
       {
        "pasaporte": "Costa Rica",
        "origen": "Todos",
        "destino": "Finlandia",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Costa Rica",
        "origen": "Todos",
        "destino": "Francia",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Costa Rica",
        "origen": "Todos",
        "destino": "Georgia",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": ""
       },
       {
        "pasaporte": "Costa Rica",
        "origen": "Todos",
        "destino": "Gibraltar",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Costa Rica",
        "origen": "Todos",
        "destino": "Granada",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": ""
       },
       {
        "pasaporte": "Costa Rica",
        "origen": "Todos",
        "destino": "Grecia",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Costa Rica",
        "origen": "Todos",
        "destino": "Groenlandia",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Costa Rica",
        "origen": "Todos",
        "destino": "Guatemala",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "Costa Rica",
        "origen": "Todos",
        "destino": "Guayana Francesa",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "Costa Rica",
        "origen": "Todos",
        "destino": "Guyana",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "Costa Rica",
        "origen": "Todos",
        "destino": "Haití",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "Costa Rica",
        "origen": "Todos",
        "destino": "Hong Kong",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "Costa Rica",
        "origen": "Todos",
        "destino": "Hungría",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Costa Rica",
        "origen": "Todos",
        "destino": "Indias Occidentales Francesas",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": ""
       },
       {
        "pasaporte": "Costa Rica",
        "origen": "Todos",
        "destino": "Irlanda",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Costa Rica",
        "origen": "Todos",
        "destino": "Isla Reunión",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": ""
       },
       {
        "pasaporte": "Costa Rica",
        "origen": "Todos",
        "destino": "Islandia",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Costa Rica",
        "origen": "Todos",
        "destino": "Islas Caimán",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "Costa Rica",
        "origen": "Todos",
        "destino": "Islas Cook",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "Costa Rica",
        "origen": "Todos",
        "destino": "Islas Feroe",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "Costa Rica",
        "origen": "Todos",
        "destino": "Islas Turcas y Caicos",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "Costa Rica",
        "origen": "Todos",
        "destino": "Islas Vírgenes Británicas",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "Costa Rica",
        "origen": "Todos",
        "destino": "Israel",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "Costa Rica",
        "origen": "Todos",
        "destino": "Italia",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Costa Rica",
        "origen": "Todos",
        "destino": "Jamaica",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "Costa Rica",
        "origen": "Todos",
        "destino": "Japón",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "Costa Rica",
        "origen": "Todos",
        "destino": "Kiribati",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "Costa Rica",
        "origen": "Todos",
        "destino": "Kosovo",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "Costa Rica",
        "origen": "Todos",
        "destino": "Letonia",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Costa Rica",
        "origen": "Todos",
        "destino": "Liechtenstein",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Costa Rica",
        "origen": "Todos",
        "destino": "Lituania",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Costa Rica",
        "origen": "Todos",
        "destino": "Luxemburgo",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Costa Rica",
        "origen": "Todos",
        "destino": "Macedonia del Norte",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": ""
       },
       {
        "pasaporte": "Costa Rica",
        "origen": "Todos",
        "destino": "Malasia",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "Costa Rica",
        "origen": "Todos",
        "destino": "Malta",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Costa Rica",
        "origen": "Todos",
        "destino": "Mayotte",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": ""
       },
       {
        "pasaporte": "Costa Rica",
        "origen": "Todos",
        "destino": "México",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "Costa Rica",
        "origen": "Todos",
        "destino": "Micronesia",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": ""
       },
       {
        "pasaporte": "Costa Rica",
        "origen": "Todos",
        "destino": "Moldavia",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "Costa Rica",
        "origen": "Todos",
        "destino": "Mónaco",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Costa Rica",
        "origen": "Todos",
        "destino": "Montenegro",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "Costa Rica",
        "origen": "Todos",
        "destino": "Montserrat",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": ""
       },
       {
        "pasaporte": "Costa Rica",
        "origen": "Todos",
        "destino": "Nicaragua",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": ""
       },
       {
        "pasaporte": "Costa Rica",
        "origen": "Todos",
        "destino": "Noruega",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Costa Rica",
        "origen": "Todos",
        "destino": "Nueva Caledonia",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": ""
       },
       {
        "pasaporte": "Costa Rica",
        "origen": "Todos",
        "destino": "Países Bajos",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Costa Rica",
        "origen": "Todos",
        "destino": "Panamá",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "Costa Rica",
        "origen": "Todos",
        "destino": "Paraguay",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "Costa Rica",
        "origen": "Todos",
        "destino": "Perú",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "Costa Rica",
        "origen": "Todos",
        "destino": "Polinesia Francesa",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": ""
       },
       {
        "pasaporte": "Costa Rica",
        "origen": "Todos",
        "destino": "Polonia",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Costa Rica",
        "origen": "Todos",
        "destino": "Portugal",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Costa Rica",
        "origen": "Todos",
        "destino": "Reino Unido",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "Costa Rica",
        "origen": "Todos",
        "destino": "República Checa",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Costa Rica",
        "origen": "Todos",
        "destino": "República Dominicana",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "Costa Rica",
        "origen": "Todos",
        "destino": "Rumanía",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Costa Rica",
        "origen": "Todos",
        "destino": "San Cristóbal y Nieves",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "Costa Rica",
        "origen": "Todos",
        "destino": "San Marino",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "Costa Rica",
        "origen": "Todos",
        "destino": "San Martín",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "Costa Rica",
        "origen": "Todos",
        "destino": "San Pedro y Miquelón",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "Costa Rica",
        "origen": "Todos",
        "destino": "San Vicente y las Granadinas",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "Costa Rica",
        "origen": "Todos",
        "destino": "Santa Elena",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "Costa Rica",
        "origen": "Todos",
        "destino": "Santa Lucía",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "Costa Rica",
        "origen": "Todos",
        "destino": "Serbia",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "Costa Rica",
        "origen": "Todos",
        "destino": "Singapur",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "Costa Rica",
        "origen": "Todos",
        "destino": "Sudáfrica",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "Costa Rica",
        "origen": "Todos",
        "destino": "Suecia",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Costa Rica",
        "origen": "Todos",
        "destino": "Suiza",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Costa Rica",
        "origen": "Todos",
        "destino": "Surinam",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "Costa Rica",
        "origen": "Todos",
        "destino": "Tajikistán",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "Costa Rica",
        "origen": "Todos",
        "destino": "Territorios Palestinos",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "Costa Rica",
        "origen": "Todos",
        "destino": "Trinidad y Tobago",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "Costa Rica",
        "origen": "Todos",
        "destino": "Túnez",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "Costa Rica",
        "origen": "Todos",
        "destino": "Turquía",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "Costa Rica",
        "origen": "Todos",
        "destino": "Uruguay",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "Costa Rica",
        "origen": "Todos",
        "destino": "Uzbekistán",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "Costa Rica",
        "origen": "Todos",
        "destino": "Venezuela",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "Costa Rica",
        "origen": "Todos",
        "destino": "Wallis y Futuna",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "Costa Rica",
        "origen": "Todos",
        "destino": "Bangladesh",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.98",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Costa Rica",
        "origen": "Todos",
        "destino": "Burundi",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.98",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Costa Rica",
        "origen": "Todos",
        "destino": "Cabo Verde",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.98",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Costa Rica",
        "origen": "Todos",
        "destino": "Camboya",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.98",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Costa Rica",
        "origen": "Todos",
        "destino": "Comoras",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.98",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Costa Rica",
        "origen": "Todos",
        "destino": "Djibouti",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.98",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Costa Rica",
        "origen": "Todos",
        "destino": "Egipto",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.98",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Costa Rica",
        "origen": "Todos",
        "destino": "Guinea-Bissau",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.98",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Costa Rica",
        "origen": "Todos",
        "destino": "Isla Mauricio",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.98",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Costa Rica",
        "origen": "Todos",
        "destino": "Islas Marshall",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.98",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Costa Rica",
        "origen": "Todos",
        "destino": "Islas Seychelles",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.98",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Costa Rica",
        "origen": "Todos",
        "destino": "Jordania",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.98",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Costa Rica",
        "origen": "Todos",
        "destino": "Laos",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.98",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Costa Rica",
        "origen": "Todos",
        "destino": "Líbano",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.98",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Costa Rica",
        "origen": "Todos",
        "destino": "Macao",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.98",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Costa Rica",
        "origen": "Todos",
        "destino": "Madagascar",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.98",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Costa Rica",
        "origen": "Todos",
        "destino": "Malawi",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.98",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Costa Rica",
        "origen": "Todos",
        "destino": "Maldivas",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.98",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Costa Rica",
        "origen": "Todos",
        "destino": "Mauritania",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.98",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Costa Rica",
        "origen": "Todos",
        "destino": "Mozambique",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.98",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Costa Rica",
        "origen": "Todos",
        "destino": "Nepal",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.98",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Costa Rica",
        "origen": "Todos",
        "destino": "Niue",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.98",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Costa Rica",
        "origen": "Todos",
        "destino": "Palaos",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.98",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Costa Rica",
        "origen": "Todos",
        "destino": "Qatar",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.98",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Costa Rica",
        "origen": "Todos",
        "destino": "Ruanda",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.98",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Costa Rica",
        "origen": "Todos",
        "destino": "Samoa",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.98",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Costa Rica",
        "origen": "Todos",
        "destino": "Senegal",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.98",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Costa Rica",
        "origen": "Todos",
        "destino": "Sri Lanka",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.98",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Costa Rica",
        "origen": "Todos",
        "destino": "Tailandia",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.98",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Costa Rica",
        "origen": "Todos",
        "destino": "Tanzania",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.98",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Costa Rica",
        "origen": "Todos",
        "destino": "Timor Oriental",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.98",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Costa Rica",
        "origen": "Todos",
        "destino": "Tuvalu",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.98",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Costa Rica",
        "origen": "Todos",
        "destino": "Zambia",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.98",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Costa Rica",
        "origen": "Todos",
        "destino": "Zimbabue",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.98",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Costa Rica",
        "origen": "Todos",
        "destino": "Corea del Sur",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.98",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Costa Rica",
        "origen": "Todos",
        "destino": "Kenia",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.98",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Costa Rica",
        "origen": "Todos",
        "destino": "Pakistan",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.98",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Costa Rica",
        "origen": "Todos",
        "destino": "Antigua y Barbuda",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.98",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Costa Rica",
        "origen": "Todos",
        "destino": "Armenia",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.98",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Costa Rica",
        "origen": "Todos",
        "destino": "Australia",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.98",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Costa Rica",
        "origen": "Todos",
        "destino": "Azerbaiyán",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.98",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Costa Rica",
        "origen": "Todos",
        "destino": "Bahrain",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.98",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Costa Rica",
        "origen": "Todos",
        "destino": "Benín",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.98",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Costa Rica",
        "origen": "Todos",
        "destino": "Birmania",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.98",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Costa Rica",
        "origen": "Todos",
        "destino": "Burkina Faso",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.98",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Costa Rica",
        "origen": "Todos",
        "destino": "Bután",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.98",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Costa Rica",
        "origen": "Todos",
        "destino": "Camerún",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.98",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Costa Rica",
        "origen": "Todos",
        "destino": "Costa de Marfil",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.98",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Costa Rica",
        "origen": "Todos",
        "destino": "Etiopía",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.98",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Costa Rica",
        "origen": "Todos",
        "destino": "Gabón",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.98",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Costa Rica",
        "origen": "Todos",
        "destino": "Guinea",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.98",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Costa Rica",
        "origen": "Todos",
        "destino": "Guinea Ecuatorial",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.98",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Costa Rica",
        "origen": "Todos",
        "destino": "India",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.98",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Costa Rica",
        "origen": "Todos",
        "destino": "Irán",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.98",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Costa Rica",
        "origen": "Todos",
        "destino": "Kazajistán",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.98",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Costa Rica",
        "origen": "Todos",
        "destino": "Kirguistán",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.98",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Costa Rica",
        "origen": "Todos",
        "destino": "Lesoto",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.98",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Costa Rica",
        "origen": "Todos",
        "destino": "Libia",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.98",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Costa Rica",
        "origen": "Todos",
        "destino": "Mongolia",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.98",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Costa Rica",
        "origen": "Todos",
        "destino": "Nigeria",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.98",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Costa Rica",
        "origen": "Todos",
        "destino": "Omán",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.98",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Costa Rica",
        "origen": "Todos",
        "destino": "República Democrática del Congo",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.98",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Costa Rica",
        "origen": "Todos",
        "destino": "Santo Tomé y Príncipe",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.98",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Costa Rica",
        "origen": "Todos",
        "destino": "Siria",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.98",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Costa Rica",
        "origen": "Todos",
        "destino": "Sudán del Sur",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.98",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Costa Rica",
        "origen": "Todos",
        "destino": "Togo",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.98",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Costa Rica",
        "origen": "Todos",
        "destino": "Uganda",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.98",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Costa Rica",
        "origen": "Todos",
        "destino": "Vietnam",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.98",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Costa Rica",
        "origen": "Todos",
        "destino": "Afganistán",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.95",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Costa Rica",
        "origen": "Todos",
        "destino": "Angola",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.95",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Costa Rica",
        "origen": "Todos",
        "destino": "Arabia Saudí",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.95",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Costa Rica",
        "origen": "Todos",
        "destino": "Argelia",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.95",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Costa Rica",
        "origen": "Todos",
        "destino": "Bielorrusia",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.95",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Costa Rica",
        "origen": "Todos",
        "destino": "Canadá",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.95",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Costa Rica",
        "origen": "Todos",
        "destino": "Chad",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.95",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Costa Rica",
        "origen": "Todos",
        "destino": "China",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.95",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Costa Rica",
        "origen": "Todos",
        "destino": "Corea del Norte",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.95",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Costa Rica",
        "origen": "Todos",
        "destino": "Cuba",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.95",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Costa Rica",
        "origen": "Todos",
        "destino": "Eritrea",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.95",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Costa Rica",
        "origen": "Todos",
        "destino": "Estados Unidos de América",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.95",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Costa Rica",
        "origen": "Todos",
        "destino": "Eswatini",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.95",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Costa Rica",
        "origen": "Todos",
        "destino": "Falkland Islands",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.95",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Costa Rica",
        "origen": "Todos",
        "destino": "Fiji",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.95",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Costa Rica",
        "origen": "Todos",
        "destino": "Gambia",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.95",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Costa Rica",
        "origen": "Todos",
        "destino": "Ghana",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.95",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Costa Rica",
        "origen": "Todos",
        "destino": "Guam",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.95",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Costa Rica",
        "origen": "Todos",
        "destino": "Honduras",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.95",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Costa Rica",
        "origen": "Todos",
        "destino": "Indonesia",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.95",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Costa Rica",
        "origen": "Todos",
        "destino": "Irak",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.95",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Costa Rica",
        "origen": "Todos",
        "destino": "Isla Norfolk",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.95",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Costa Rica",
        "origen": "Todos",
        "destino": "Islas Marianas del Norte",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.95",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Costa Rica",
        "origen": "Todos",
        "destino": "Islas Salomón",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.95",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Costa Rica",
        "origen": "Todos",
        "destino": "Islas Vírgenes americanas",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.95",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Costa Rica",
        "origen": "Todos",
        "destino": "Kuwait",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.95",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Costa Rica",
        "origen": "Todos",
        "destino": "Liberia",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.95",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Costa Rica",
        "origen": "Todos",
        "destino": "Mali",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.95",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Costa Rica",
        "origen": "Todos",
        "destino": "Marruecos",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.95",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Costa Rica",
        "origen": "Todos",
        "destino": "Namibia",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.95",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Costa Rica",
        "origen": "Todos",
        "destino": "Nauru",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.95",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Costa Rica",
        "origen": "Todos",
        "destino": "Níger",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.95",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Costa Rica",
        "origen": "Todos",
        "destino": "Nueva Zelanda",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.95",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Costa Rica",
        "origen": "Todos",
        "destino": "Papua Nueva Guinea",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.95",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Costa Rica",
        "origen": "Todos",
        "destino": "Puerto Rico",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.95",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Costa Rica",
        "origen": "Todos",
        "destino": "República Centroafricana",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.95",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Costa Rica",
        "origen": "Todos",
        "destino": "República del Congo",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.95",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Costa Rica",
        "origen": "Todos",
        "destino": "Samoa Americana",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.95",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Costa Rica",
        "origen": "Todos",
        "destino": "Sierra Leona",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.95",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Costa Rica",
        "origen": "Todos",
        "destino": "Somalia",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.95",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Costa Rica",
        "origen": "Todos",
        "destino": "Sudán",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.95",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Costa Rica",
        "origen": "Todos",
        "destino": "Taiwán",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.95",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Costa Rica",
        "origen": "Todos",
        "destino": "Tonga",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.95",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Costa Rica",
        "origen": "Todos",
        "destino": "Turkmenistán",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.95",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Costa Rica",
        "origen": "Todos",
        "destino": "Ucrania",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.95",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Costa Rica",
        "origen": "Todos",
        "destino": "Vanuatu",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.95",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Costa Rica",
        "origen": "Todos",
        "destino": "Yemen",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.95",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Guatemala",
        "origen": "Todos",
        "destino": "Albania",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": ""
       },
       {
        "pasaporte": "Guatemala",
        "origen": "Todos",
        "destino": "Alemania",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Guatemala",
        "origen": "Todos",
        "destino": "Andorra",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Guatemala",
        "origen": "Todos",
        "destino": "Anguilla",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": ""
       },
       {
        "pasaporte": "Guatemala",
        "origen": "Todos",
        "destino": "Argentina",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "Guatemala",
        "origen": "Todos",
        "destino": "Aruba",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "Guatemala",
        "origen": "Todos",
        "destino": "Austria",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Guatemala",
        "origen": "Todos",
        "destino": "Bahamas",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "Guatemala",
        "origen": "Todos",
        "destino": "Barbados",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "Guatemala",
        "origen": "Todos",
        "destino": "Bélgica",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Guatemala",
        "origen": "Todos",
        "destino": "Belice",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "Guatemala",
        "origen": "Todos",
        "destino": "Bermuda",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "Guatemala",
        "origen": "Todos",
        "destino": "Bonaire, San Eustaquio y Saba",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "Guatemala",
        "origen": "Todos",
        "destino": "Bosnia-Herzegovina",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": ""
       },
       {
        "pasaporte": "Guatemala",
        "origen": "Todos",
        "destino": "Brasil",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Guatemala",
        "origen": "Todos",
        "destino": "Bulgaria",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Guatemala",
        "origen": "Todos",
        "destino": "Chile",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "Guatemala",
        "origen": "Todos",
        "destino": "Chipre",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Guatemala",
        "origen": "Todos",
        "destino": "Ciudad del Vaticano",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Guatemala",
        "origen": "Todos",
        "destino": "Colombia",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "Guatemala",
        "origen": "Todos",
        "destino": "Costa Rica",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "Guatemala",
        "origen": "Todos",
        "destino": "Croacia",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Guatemala",
        "origen": "Todos",
        "destino": "Curaçao",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "Guatemala",
        "origen": "Todos",
        "destino": "Dinamarca",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Guatemala",
        "origen": "Todos",
        "destino": "Dominica",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "Guatemala",
        "origen": "Todos",
        "destino": "Ecuador",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "Guatemala",
        "origen": "Todos",
        "destino": "El Salvador",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "Guatemala",
        "origen": "Todos",
        "destino": "Eslovaquia",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Guatemala",
        "origen": "Todos",
        "destino": "Eslovenia",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Guatemala",
        "origen": "Todos",
        "destino": "España",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Guatemala",
        "origen": "Todos",
        "destino": "Estonia",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Guatemala",
        "origen": "Todos",
        "destino": "Federación Rusa",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "Guatemala",
        "origen": "Todos",
        "destino": "Filipinas",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": ""
       },
       {
        "pasaporte": "Guatemala",
        "origen": "Todos",
        "destino": "Finlandia",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Guatemala",
        "origen": "Todos",
        "destino": "Francia",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Guatemala",
        "origen": "Todos",
        "destino": "Gibraltar",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Guatemala",
        "origen": "Todos",
        "destino": "Grecia",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Guatemala",
        "origen": "Todos",
        "destino": "Groenlandia",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Guatemala",
        "origen": "Todos",
        "destino": "Guayana Francesa",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "Guatemala",
        "origen": "Todos",
        "destino": "Haití",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "Guatemala",
        "origen": "Todos",
        "destino": "Honduras",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "Guatemala",
        "origen": "Todos",
        "destino": "Hong Kong",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "Guatemala",
        "origen": "Todos",
        "destino": "Hungría",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Guatemala",
        "origen": "Todos",
        "destino": "Indias Occidentales Francesas",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": ""
       },
       {
        "pasaporte": "Guatemala",
        "origen": "Todos",
        "destino": "Irlanda",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Guatemala",
        "origen": "Todos",
        "destino": "Isla Reunión",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": ""
       },
       {
        "pasaporte": "Guatemala",
        "origen": "Todos",
        "destino": "Islandia",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Guatemala",
        "origen": "Todos",
        "destino": "Islas Cook",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "Guatemala",
        "origen": "Todos",
        "destino": "Islas Feroe",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "Guatemala",
        "origen": "Todos",
        "destino": "Islas Vírgenes Británicas",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "Guatemala",
        "origen": "Todos",
        "destino": "Israel",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "Guatemala",
        "origen": "Todos",
        "destino": "Italia",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Guatemala",
        "origen": "Todos",
        "destino": "Jamaica",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "Guatemala",
        "origen": "Todos",
        "destino": "Japón",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "Guatemala",
        "origen": "Todos",
        "destino": "Kiribati",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "Guatemala",
        "origen": "Todos",
        "destino": "Kosovo",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "Guatemala",
        "origen": "Todos",
        "destino": "Letonia",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Guatemala",
        "origen": "Todos",
        "destino": "Liechtenstein",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Guatemala",
        "origen": "Todos",
        "destino": "Lituania",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Guatemala",
        "origen": "Todos",
        "destino": "Luxemburgo",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Guatemala",
        "origen": "Todos",
        "destino": "Macedonia del Norte",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": ""
       },
       {
        "pasaporte": "Guatemala",
        "origen": "Todos",
        "destino": "Malasia",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "Guatemala",
        "origen": "Todos",
        "destino": "Malta",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Guatemala",
        "origen": "Todos",
        "destino": "Mayotte",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": ""
       },
       {
        "pasaporte": "Guatemala",
        "origen": "Todos",
        "destino": "Micronesia",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": ""
       },
       {
        "pasaporte": "Guatemala",
        "origen": "Todos",
        "destino": "Moldavia",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "Guatemala",
        "origen": "Todos",
        "destino": "Mónaco",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Guatemala",
        "origen": "Todos",
        "destino": "Montenegro",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "Guatemala",
        "origen": "Todos",
        "destino": "Montserrat",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": ""
       },
       {
        "pasaporte": "Guatemala",
        "origen": "Todos",
        "destino": "Nicaragua",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": ""
       },
       {
        "pasaporte": "Guatemala",
        "origen": "Todos",
        "destino": "Noruega",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Guatemala",
        "origen": "Todos",
        "destino": "Nueva Caledonia",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": ""
       },
       {
        "pasaporte": "Guatemala",
        "origen": "Todos",
        "destino": "Países Bajos",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Guatemala",
        "origen": "Todos",
        "destino": "Panamá",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "Guatemala",
        "origen": "Todos",
        "destino": "Paraguay",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "Guatemala",
        "origen": "Todos",
        "destino": "Perú",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "Guatemala",
        "origen": "Todos",
        "destino": "Polinesia Francesa",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": ""
       },
       {
        "pasaporte": "Guatemala",
        "origen": "Todos",
        "destino": "Polonia",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Guatemala",
        "origen": "Todos",
        "destino": "Portugal",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Guatemala",
        "origen": "Todos",
        "destino": "Reino Unido",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Guatemala",
        "origen": "Todos",
        "destino": "República Checa",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Guatemala",
        "origen": "Todos",
        "destino": "República Dominicana",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "Guatemala",
        "origen": "Todos",
        "destino": "Rumanía",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Guatemala",
        "origen": "Todos",
        "destino": "San Cristóbal y Nieves",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": ""
       },
       {
        "pasaporte": "Guatemala",
        "origen": "Todos",
        "destino": "San Marino",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Guatemala",
        "origen": "Todos",
        "destino": "San Martín",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": ""
       },
       {
        "pasaporte": "Guatemala",
        "origen": "Todos",
        "destino": "San Pedro y Miquelón",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": ""
       },
       {
        "pasaporte": "Guatemala",
        "origen": "Todos",
        "destino": "San Vicente y las Granadinas",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": ""
       },
       {
        "pasaporte": "Guatemala",
        "origen": "Todos",
        "destino": "Santa Elena",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": ""
       },
       {
        "pasaporte": "Guatemala",
        "origen": "Todos",
        "destino": "Singapur",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "Guatemala",
        "origen": "Todos",
        "destino": "Suecia",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Guatemala",
        "origen": "Todos",
        "destino": "Suiza",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Guatemala",
        "origen": "Todos",
        "destino": "Surinam",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": ""
       },
       {
        "pasaporte": "Guatemala",
        "origen": "Todos",
        "destino": "Tailandia",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "Guatemala",
        "origen": "Todos",
        "destino": "Taiwán",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "Guatemala",
        "origen": "Todos",
        "destino": "Territorios Palestinos",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "Guatemala",
        "origen": "Todos",
        "destino": "Trinidad y Tobago",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "Guatemala",
        "origen": "Todos",
        "destino": "Turquía",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "Guatemala",
        "origen": "Todos",
        "destino": "Uruguay",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "Guatemala",
        "origen": "Todos",
        "destino": "Uzbekistán",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "Guatemala",
        "origen": "Todos",
        "destino": "Wallis y Futuna",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "Guatemala",
        "origen": "Todos",
        "destino": "Bangladesh",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.97",
        "requisitos": ""
       },
       {
        "pasaporte": "Guatemala",
        "origen": "Todos",
        "destino": "Bolivia",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.97",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Guatemala",
        "origen": "Todos",
        "destino": "Burundi",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.97",
        "requisitos": ""
       },
       {
        "pasaporte": "Guatemala",
        "origen": "Todos",
        "destino": "Cabo Verde",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.97",
        "requisitos": ""
       },
       {
        "pasaporte": "Guatemala",
        "origen": "Todos",
        "destino": "Camboya",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.97",
        "requisitos": ""
       },
       {
        "pasaporte": "Guatemala",
        "origen": "Todos",
        "destino": "Comoras",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.97",
        "requisitos": ""
       },
       {
        "pasaporte": "Guatemala",
        "origen": "Todos",
        "destino": "Djibouti",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.97",
        "requisitos": ""
       },
       {
        "pasaporte": "Guatemala",
        "origen": "Todos",
        "destino": "Guinea-Bissau",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.97",
        "requisitos": ""
       },
       {
        "pasaporte": "Guatemala",
        "origen": "Todos",
        "destino": "Indonesia",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.97",
        "requisitos": ""
       },
       {
        "pasaporte": "Guatemala",
        "origen": "Todos",
        "destino": "Isla Mauricio",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.97",
        "requisitos": ""
       },
       {
        "pasaporte": "Guatemala",
        "origen": "Todos",
        "destino": "Islas Marshall",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.97",
        "requisitos": ""
       },
       {
        "pasaporte": "Guatemala",
        "origen": "Todos",
        "destino": "Islas Seychelles",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.97",
        "requisitos": ""
       },
       {
        "pasaporte": "Guatemala",
        "origen": "Todos",
        "destino": "Jordania",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.97",
        "requisitos": ""
       },
       {
        "pasaporte": "Guatemala",
        "origen": "Todos",
        "destino": "Laos",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.97",
        "requisitos": ""
       },
       {
        "pasaporte": "Guatemala",
        "origen": "Todos",
        "destino": "Macao",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.97",
        "requisitos": ""
       },
       {
        "pasaporte": "Guatemala",
        "origen": "Todos",
        "destino": "Madagascar",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.97",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Guatemala",
        "origen": "Todos",
        "destino": "Malawi",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.97",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Guatemala",
        "origen": "Todos",
        "destino": "Maldivas",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.97",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Guatemala",
        "origen": "Todos",
        "destino": "Mauritania",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.97",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Guatemala",
        "origen": "Todos",
        "destino": "Mozambique",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.97",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Guatemala",
        "origen": "Todos",
        "destino": "Nepal",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.97",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Guatemala",
        "origen": "Todos",
        "destino": "Niue",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.97",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Guatemala",
        "origen": "Todos",
        "destino": "Palaos",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.97",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Guatemala",
        "origen": "Todos",
        "destino": "Ruanda",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.97",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Guatemala",
        "origen": "Todos",
        "destino": "Samoa",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.97",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Guatemala",
        "origen": "Todos",
        "destino": "Santa Lucía",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.97",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Guatemala",
        "origen": "Todos",
        "destino": "Senegal",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.97",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Guatemala",
        "origen": "Todos",
        "destino": "Sri Lanka",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.97",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Guatemala",
        "origen": "Todos",
        "destino": "Tanzania",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.97",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Guatemala",
        "origen": "Todos",
        "destino": "Timor Oriental",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.97",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Guatemala",
        "origen": "Todos",
        "destino": "Tuvalu",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.97",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Guatemala",
        "origen": "Todos",
        "destino": "Zambia",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.97",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Guatemala",
        "origen": "Todos",
        "destino": "Zimbabue",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.97",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Guatemala",
        "origen": "Todos",
        "destino": "Corea del Sur",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.95",
        "requisitos": "Autorización ETA\nPasaporte vigente"
       },
       {
        "pasaporte": "Guatemala",
        "origen": "Todos",
        "destino": "Kenia",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.95",
        "requisitos": "Autorización ETA\nPasaporte vigente"
       },
       {
        "pasaporte": "Guatemala",
        "origen": "Todos",
        "destino": "Antigua y Barbuda",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.93",
        "requisitos": ""
       },
       {
        "pasaporte": "Guatemala",
        "origen": "Todos",
        "destino": "Armenia",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.93",
        "requisitos": ""
       },
       {
        "pasaporte": "Guatemala",
        "origen": "Todos",
        "destino": "Australia",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.93",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Guatemala",
        "origen": "Todos",
        "destino": "Azerbaiyán",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.93",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Guatemala",
        "origen": "Todos",
        "destino": "Bahréin",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.93",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Guatemala",
        "origen": "Todos",
        "destino": "Benín",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.93",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Guatemala",
        "origen": "Todos",
        "destino": "Birmania",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.93",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Guatemala",
        "origen": "Todos",
        "destino": "Botsuana",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.93",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Guatemala",
        "origen": "Todos",
        "destino": "Burkina Faso",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.93",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Guatemala",
        "origen": "Todos",
        "destino": "Bután",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.93",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Guatemala",
        "origen": "Todos",
        "destino": "Camerún",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.93",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Guatemala",
        "origen": "Todos",
        "destino": "Costa de Marfil",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.93",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Guatemala",
        "origen": "Todos",
        "destino": "Emiratos Árabes Unidos",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.93",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Guatemala",
        "origen": "Todos",
        "destino": "Etiopía",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.93",
        "requisitos": ""
       },
       {
        "pasaporte": "Guatemala",
        "origen": "Todos",
        "destino": "Gabón",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.93",
        "requisitos": ""
       },
       {
        "pasaporte": "Guatemala",
        "origen": "Todos",
        "destino": "Georgia",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.93",
        "requisitos": ""
       },
       {
        "pasaporte": "Guatemala",
        "origen": "Todos",
        "destino": "Guinea",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.93",
        "requisitos": ""
       },
       {
        "pasaporte": "Guatemala",
        "origen": "Todos",
        "destino": "Guinea Ecuatorial",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.93",
        "requisitos": ""
       },
       {
        "pasaporte": "Guatemala",
        "origen": "Todos",
        "destino": "India",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.93",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Guatemala",
        "origen": "Todos",
        "destino": "Irán",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.93",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Guatemala",
        "origen": "Todos",
        "destino": "Kazajistán",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.93",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Guatemala",
        "origen": "Todos",
        "destino": "Kirguistán",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.93",
        "requisitos": ""
       },
       {
        "pasaporte": "Guatemala",
        "origen": "Todos",
        "destino": "Lesoto",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.93",
        "requisitos": ""
       },
       {
        "pasaporte": "Guatemala",
        "origen": "Todos",
        "destino": "Libia",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.93",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Guatemala",
        "origen": "Todos",
        "destino": "Mongolia",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.93",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Guatemala",
        "origen": "Todos",
        "destino": "Nigeria",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.93",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Guatemala",
        "origen": "Todos",
        "destino": "Omán",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.93",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Guatemala",
        "origen": "Todos",
        "destino": "Pakistán",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.93",
        "requisitos": ""
       },
       {
        "pasaporte": "Guatemala",
        "origen": "Todos",
        "destino": "Papúa Nueva Guinea",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.93",
        "requisitos": ""
       },
       {
        "pasaporte": "Guatemala",
        "origen": "Todos",
        "destino": "Catar",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.93",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Guatemala",
        "origen": "Todos",
        "destino": "República Democrática del Congo",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.93",
        "requisitos": ""
       },
       {
        "pasaporte": "Guatemala",
        "origen": "Todos",
        "destino": "Santo Tomé y Príncipe",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.93",
        "requisitos": ""
       },
       {
        "pasaporte": "Guatemala",
        "origen": "Todos",
        "destino": "Sierra Leona",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.93",
        "requisitos": ""
       },
       {
        "pasaporte": "Guatemala",
        "origen": "Todos",
        "destino": "Siria",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.93",
        "requisitos": ""
       },
       {
        "pasaporte": "Guatemala",
        "origen": "Todos",
        "destino": "Sudán del Sur",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.93",
        "requisitos": ""
       },
       {
        "pasaporte": "Guatemala",
        "origen": "Todos",
        "destino": "Tayikistán",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.93",
        "requisitos": ""
       },
       {
        "pasaporte": "Guatemala",
        "origen": "Todos",
        "destino": "Togo",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.93",
        "requisitos": ""
       },
       {
        "pasaporte": "Guatemala",
        "origen": "Todos",
        "destino": "Uganda",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.93",
        "requisitos": ""
       },
       {
        "pasaporte": "Guatemala",
        "origen": "Todos",
        "destino": "Vietnam",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.93",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Guatemala",
        "origen": "Todos",
        "destino": "Afganistán",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": ""
       },
       {
        "pasaporte": "Guatemala",
        "origen": "Todos",
        "destino": "Angola",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": ""
       },
       {
        "pasaporte": "Guatemala",
        "origen": "Todos",
        "destino": "Arabia Saudí",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": ""
       },
       {
        "pasaporte": "Guatemala",
        "origen": "Todos",
        "destino": "Argelia",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": ""
       },
       {
        "pasaporte": "Guatemala",
        "origen": "Todos",
        "destino": "Bielorrusia",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Guatemala",
        "origen": "Todos",
        "destino": "Brunei",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": ""
       },
       {
        "pasaporte": "Guatemala",
        "origen": "Todos",
        "destino": "Canadá",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Guatemala",
        "origen": "Todos",
        "destino": "Chad",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": ""
       },
       {
        "pasaporte": "Guatemala",
        "origen": "Todos",
        "destino": "China",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Guatemala",
        "origen": "Todos",
        "destino": "Corea del Norte",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": ""
       },
       {
        "pasaporte": "Guatemala",
        "origen": "Todos",
        "destino": "Cuba",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado\nPasaporte vigentr\nSeguro médico"
       },
       {
        "pasaporte": "Guatemala",
        "origen": "Todos",
        "destino": "Egipto",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Guatemala",
        "origen": "Todos",
        "destino": "Eritrea",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": ""
       },
       {
        "pasaporte": "Guatemala",
        "origen": "Todos",
        "destino": "Estados Unidos de América",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Guatemala",
        "origen": "Todos",
        "destino": "Eswatini",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": ""
       },
       {
        "pasaporte": "Guatemala",
        "origen": "Todos",
        "destino": "Falkland Islands",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": ""
       },
       {
        "pasaporte": "Guatemala",
        "origen": "Todos",
        "destino": "Fiji",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": ""
       },
       {
        "pasaporte": "Guatemala",
        "origen": "Todos",
        "destino": "Gambia",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": ""
       },
       {
        "pasaporte": "Guatemala",
        "origen": "Todos",
        "destino": "Ghana",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": ""
       },
       {
        "pasaporte": "Guatemala",
        "origen": "Todos",
        "destino": "Granada",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Guatemala",
        "origen": "Todos",
        "destino": "Guam",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": ""
       },
       {
        "pasaporte": "Guatemala",
        "origen": "Todos",
        "destino": "Guyana",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": ""
       },
       {
        "pasaporte": "Guatemala",
        "origen": "Todos",
        "destino": "Irak",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": ""
       },
       {
        "pasaporte": "Guatemala",
        "origen": "Todos",
        "destino": "Isla Norfolk",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Guatemala",
        "origen": "Todos",
        "destino": "Islas Caimán",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Guatemala",
        "origen": "Todos",
        "destino": "Islas Marianas del Norte",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Guatemala",
        "origen": "Todos",
        "destino": "Islas Salomón",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Guatemala",
        "origen": "Todos",
        "destino": "Islas Turcas y Caicos",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Guatemala",
        "origen": "Todos",
        "destino": "Islas Vírgenes Americanas",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Guatemala",
        "origen": "Todos",
        "destino": "Kuwait",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": ""
       },
       {
        "pasaporte": "Guatemala",
        "origen": "Todos",
        "destino": "Líbano",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": ""
       },
       {
        "pasaporte": "Guatemala",
        "origen": "Todos",
        "destino": "Liberia",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": ""
       },
       {
        "pasaporte": "Guatemala",
        "origen": "Todos",
        "destino": "Mali",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": ""
       },
       {
        "pasaporte": "Guatemala",
        "origen": "Todos",
        "destino": "Marruecos",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": ""
       },
       {
        "pasaporte": "Guatemala",
        "origen": "Todos",
        "destino": "México",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado\nPasaporte vigente\nAsistencia Médica de Viaje\nReserva de vuelo\nReserva de hospedaje\nSolvencia económica"
       },
       {
        "pasaporte": "Guatemala",
        "origen": "Todos",
        "destino": "Namibia",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": ""
       },
       {
        "pasaporte": "Guatemala",
        "origen": "Todos",
        "destino": "Nauru",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": ""
       },
       {
        "pasaporte": "Guatemala",
        "origen": "Todos",
        "destino": "Níger",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": ""
       },
       {
        "pasaporte": "Guatemala",
        "origen": "Todos",
        "destino": "Nueva Zelanda",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Guatemala",
        "origen": "Todos",
        "destino": "Puerto Rico",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Guatemala",
        "origen": "Todos",
        "destino": "República Centroafricana",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": ""
       },
       {
        "pasaporte": "Guatemala",
        "origen": "Todos",
        "destino": "República del Congo",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": ""
       },
       {
        "pasaporte": "Guatemala",
        "origen": "Todos",
        "destino": "Samoa Americana",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": ""
       },
       {
        "pasaporte": "Guatemala",
        "origen": "Todos",
        "destino": "Serbia",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": ""
       },
       {
        "pasaporte": "Guatemala",
        "origen": "Todos",
        "destino": "Somalia",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": ""
       },
       {
        "pasaporte": "Guatemala",
        "origen": "Todos",
        "destino": "Sudáfrica",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Guatemala",
        "origen": "Todos",
        "destino": "Sudán",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": ""
       },
       {
        "pasaporte": "Guatemala",
        "origen": "Todos",
        "destino": "Tonga",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": ""
       },
       {
        "pasaporte": "Guatemala",
        "origen": "Todos",
        "destino": "Túnez",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": ""
       },
       {
        "pasaporte": "Guatemala",
        "origen": "Todos",
        "destino": "Turkmenistán",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": ""
       },
       {
        "pasaporte": "Guatemala",
        "origen": "Todos",
        "destino": "Ucrania",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": ""
       },
       {
        "pasaporte": "Guatemala",
        "origen": "Todos",
        "destino": "Vanuatu",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": ""
       },
       {
        "pasaporte": "Guatemala",
        "origen": "Todos",
        "destino": "Venezuela",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado\nPasaporte vigente\nAsistencia Médica de Viaje\nReserva de vuelo\nReserva de hospedaje\nSolvencia económica"
       },
       {
        "pasaporte": "Guatemala",
        "origen": "Todos",
        "destino": "Yemen",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": ""
       },
       {
        "pasaporte": "Honduras",
        "origen": "Todos",
        "destino": "Albania",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": ""
       },
       {
        "pasaporte": "Honduras",
        "origen": "Todos",
        "destino": "Alemania",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Honduras",
        "origen": "Todos",
        "destino": "Andorra",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Honduras",
        "origen": "Todos",
        "destino": "Angola",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": ""
       },
       {
        "pasaporte": "Honduras",
        "origen": "Todos",
        "destino": "Anguilla",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": ""
       },
       {
        "pasaporte": "Honduras",
        "origen": "Todos",
        "destino": "Antigua y Barbuda",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "Honduras",
        "origen": "Todos",
        "destino": "Argentina",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "Honduras",
        "origen": "Todos",
        "destino": "Aruba",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "Honduras",
        "origen": "Todos",
        "destino": "Austria",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Honduras",
        "origen": "Todos",
        "destino": "Bahamas",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "Honduras",
        "origen": "Todos",
        "destino": "Barbados",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "Honduras",
        "origen": "Todos",
        "destino": "Bélgica",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Honduras",
        "origen": "Todos",
        "destino": "Belice",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "Honduras",
        "origen": "Todos",
        "destino": "Bielorrusia",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "Honduras",
        "origen": "Todos",
        "destino": "Bolivia",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "Honduras",
        "origen": "Todos",
        "destino": "Bonaire, San Eustaquio y Saba",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "Honduras",
        "origen": "Todos",
        "destino": "Bosnia-Herzegovina",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "Honduras",
        "origen": "Todos",
        "destino": "Brasil",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "Honduras",
        "origen": "Todos",
        "destino": "Bulgaria",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Honduras",
        "origen": "Todos",
        "destino": "Chile",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "Honduras",
        "origen": "Todos",
        "destino": "Chipre",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Honduras",
        "origen": "Todos",
        "destino": "Ciudad del Vaticano",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Honduras",
        "origen": "Todos",
        "destino": "Colombia",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "Honduras",
        "origen": "Todos",
        "destino": "Costa Rica",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "Honduras",
        "origen": "Todos",
        "destino": "Croacia",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "Honduras",
        "origen": "Todos",
        "destino": "Curaçao",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "Honduras",
        "origen": "Todos",
        "destino": "Dinamarca",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Honduras",
        "origen": "Todos",
        "destino": "Dominica",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": ""
       },
       {
        "pasaporte": "Honduras",
        "origen": "Todos",
        "destino": "Ecuador",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "Honduras",
        "origen": "Todos",
        "destino": "El Salvador",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "Honduras",
        "origen": "Todos",
        "destino": "Eslovaquia",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Honduras",
        "origen": "Todos",
        "destino": "Eslovenia",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Honduras",
        "origen": "Todos",
        "destino": "España",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Honduras",
        "origen": "Todos",
        "destino": "Estonia",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Honduras",
        "origen": "Todos",
        "destino": "Federación Rusa",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": ""
       },
       {
        "pasaporte": "Honduras",
        "origen": "Todos",
        "destino": "Filipinas",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": ""
       },
       {
        "pasaporte": "Honduras",
        "origen": "Todos",
        "destino": "Finlandia",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Honduras",
        "origen": "Todos",
        "destino": "Francia",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Honduras",
        "origen": "Todos",
        "destino": "Georgia",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": ""
       },
       {
        "pasaporte": "Honduras",
        "origen": "Todos",
        "destino": "Gibraltar",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Honduras",
        "origen": "Todos",
        "destino": "Grecia",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Honduras",
        "origen": "Todos",
        "destino": "Groenlandia",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Honduras",
        "origen": "Todos",
        "destino": "Guatemala",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "Honduras",
        "origen": "Todos",
        "destino": "Guayana Francesa",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "Honduras",
        "origen": "Todos",
        "destino": "Guyana",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "Honduras",
        "origen": "Todos",
        "destino": "Honduras",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "Honduras",
        "origen": "Todos",
        "destino": "Hong Kong",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "Honduras",
        "origen": "Todos",
        "destino": "Hungría",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Honduras",
        "origen": "Todos",
        "destino": "Indias Occidentales Francesas",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": ""
       },
       {
        "pasaporte": "Honduras",
        "origen": "Todos",
        "destino": "Irlanda",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": ""
       },
       {
        "pasaporte": "Honduras",
        "origen": "Todos",
        "destino": "Isla Reunión",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": ""
       },
       {
        "pasaporte": "Honduras",
        "origen": "Todos",
        "destino": "Islandia",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Honduras",
        "origen": "Todos",
        "destino": "Islas Caimán",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "Honduras",
        "origen": "Todos",
        "destino": "Islas Cook",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": ""
       },
       {
        "pasaporte": "Honduras",
        "origen": "Todos",
        "destino": "Islas Feroe",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": ""
       },
       {
        "pasaporte": "Honduras",
        "origen": "Todos",
        "destino": "Islas Turcas y Caicos",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": ""
       },
       {
        "pasaporte": "Honduras",
        "origen": "Todos",
        "destino": "Islas Vírgenes Británicas",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": ""
       },
       {
        "pasaporte": "Honduras",
        "origen": "Todos",
        "destino": "Israel",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "Honduras",
        "origen": "Todos",
        "destino": "Italia",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Honduras",
        "origen": "Todos",
        "destino": "Jamaica",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "Honduras",
        "origen": "Todos",
        "destino": "Japón",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "Honduras",
        "origen": "Todos",
        "destino": "Kiribati",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": ""
       },
       {
        "pasaporte": "Honduras",
        "origen": "Todos",
        "destino": "Kosovo",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": ""
       },
       {
        "pasaporte": "Honduras",
        "origen": "Todos",
        "destino": "Letonia",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Honduras",
        "origen": "Todos",
        "destino": "Liechtenstein",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Honduras",
        "origen": "Todos",
        "destino": "Lituania",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Honduras",
        "origen": "Todos",
        "destino": "Luxemburgo",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Honduras",
        "origen": "Todos",
        "destino": "Macedonia del Norte",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": ""
       },
       {
        "pasaporte": "Honduras",
        "origen": "Todos",
        "destino": "Malasia",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "Honduras",
        "origen": "Todos",
        "destino": "Malta",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Honduras",
        "origen": "Todos",
        "destino": "Mayotte",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": ""
       },
       {
        "pasaporte": "Honduras",
        "origen": "Todos",
        "destino": "México",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "Honduras",
        "origen": "Todos",
        "destino": "Micronesia",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": ""
       },
       {
        "pasaporte": "Honduras",
        "origen": "Todos",
        "destino": "Moldavia",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": ""
       },
       {
        "pasaporte": "Honduras",
        "origen": "Todos",
        "destino": "Mónaco",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Honduras",
        "origen": "Todos",
        "destino": "Montenegro",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "Honduras",
        "origen": "Todos",
        "destino": "Montserrat",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": ""
       },
       {
        "pasaporte": "Honduras",
        "origen": "Todos",
        "destino": "Nicaragua",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "Honduras",
        "origen": "Todos",
        "destino": "Noruega",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Honduras",
        "origen": "Todos",
        "destino": "Nueva Caledonia",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": ""
       },
       {
        "pasaporte": "Honduras",
        "origen": "Todos",
        "destino": "Países Bajos",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Honduras",
        "origen": "Todos",
        "destino": "Paraguay",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "Honduras",
        "origen": "Todos",
        "destino": "Perú",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "Honduras",
        "origen": "Todos",
        "destino": "Polinesia Francesa",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": ""
       },
       {
        "pasaporte": "Honduras",
        "origen": "Todos",
        "destino": "Polonia",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Honduras",
        "origen": "Todos",
        "destino": "Portugal",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Honduras",
        "origen": "Todos",
        "destino": "Reino Unido",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "Honduras",
        "origen": "Todos",
        "destino": "República Checa",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Honduras",
        "origen": "Todos",
        "destino": "República Dominicana",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": ""
       },
       {
        "pasaporte": "Honduras",
        "origen": "Todos",
        "destino": "Rumanía",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Honduras",
        "origen": "Todos",
        "destino": "San Cristóbal y Nieves",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": ""
       },
       {
        "pasaporte": "Honduras",
        "origen": "Todos",
        "destino": "San Marino",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Honduras",
        "origen": "Todos",
        "destino": "San Martín",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": ""
       },
       {
        "pasaporte": "Honduras",
        "origen": "Todos",
        "destino": "San Pedro y Miquelón",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": ""
       },
       {
        "pasaporte": "Honduras",
        "origen": "Todos",
        "destino": "San Vicente y las Granadinas",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": ""
       },
       {
        "pasaporte": "Honduras",
        "origen": "Todos",
        "destino": "Santa Elena",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": ""
       },
       {
        "pasaporte": "Honduras",
        "origen": "Todos",
        "destino": "Santa Lucía",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "Honduras",
        "origen": "Todos",
        "destino": "Singapur",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "Honduras",
        "origen": "Todos",
        "destino": "Sudáfrica",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "Honduras",
        "origen": "Todos",
        "destino": "Suecia",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Honduras",
        "origen": "Todos",
        "destino": "Suiza",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Honduras",
        "origen": "Todos",
        "destino": "Surinam",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": ""
       },
       {
        "pasaporte": "Honduras",
        "origen": "Todos",
        "destino": "Tailandia",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "Honduras",
        "origen": "Todos",
        "destino": "Territorios Palestinos",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": ""
       },
       {
        "pasaporte": "Honduras",
        "origen": "Todos",
        "destino": "Trinidad y Tobago",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "Honduras",
        "origen": "Todos",
        "destino": "Turquía",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "Honduras",
        "origen": "Todos",
        "destino": "Ucrania",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "Honduras",
        "origen": "Todos",
        "destino": "Uruguay",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "Honduras",
        "origen": "Todos",
        "destino": "Uzbekistán",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "Honduras",
        "origen": "Todos",
        "destino": "Vietnam",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "Honduras",
        "origen": "Todos",
        "destino": "Wallis y Futuna",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "Honduras",
        "origen": "Todos",
        "destino": "Arabia Saudí",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.97",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Honduras",
        "origen": "Todos",
        "destino": "Armenia",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.97",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Honduras",
        "origen": "Todos",
        "destino": "Bangladesh",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.97",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Honduras",
        "origen": "Todos",
        "destino": "Burundi",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.97",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Honduras",
        "origen": "Todos",
        "destino": "Cabo Verde",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.97",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Honduras",
        "origen": "Todos",
        "destino": "Camboya",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.97",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Honduras",
        "origen": "Todos",
        "destino": "Comoras",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.97",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Honduras",
        "origen": "Todos",
        "destino": "Djibouti",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.97",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Honduras",
        "origen": "Todos",
        "destino": "Egipto",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.97",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Honduras",
        "origen": "Todos",
        "destino": "Guinea-Bissau",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.97",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Honduras",
        "origen": "Todos",
        "destino": "Isla Mauricio",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.97",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Honduras",
        "origen": "Todos",
        "destino": "Islas Seychelles",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.97",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Honduras",
        "origen": "Todos",
        "destino": "Jordania",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.97",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Honduras",
        "origen": "Todos",
        "destino": "Laos",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.97",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Honduras",
        "origen": "Todos",
        "destino": "Líbano",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.97",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Honduras",
        "origen": "Todos",
        "destino": "Macao",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.97",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Honduras",
        "origen": "Todos",
        "destino": "Madagascar",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.97",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Honduras",
        "origen": "Todos",
        "destino": "Malawi",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.97",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Honduras",
        "origen": "Todos",
        "destino": "Maldivas",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.97",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Honduras",
        "origen": "Todos",
        "destino": "Mauritania",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.97",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Honduras",
        "origen": "Todos",
        "destino": "Mozambique",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.97",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Honduras",
        "origen": "Todos",
        "destino": "Nepal",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.97",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Honduras",
        "origen": "Todos",
        "destino": "Niue",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.97",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Honduras",
        "origen": "Todos",
        "destino": "Palaos",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.97",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Honduras",
        "origen": "Todos",
        "destino": "Qatar",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.97",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Honduras",
        "origen": "Todos",
        "destino": "Ruanda",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.97",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Honduras",
        "origen": "Todos",
        "destino": "Samoa",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.97",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Honduras",
        "origen": "Todos",
        "destino": "Sri Lanka",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.97",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Honduras",
        "origen": "Todos",
        "destino": "Tanzania",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.97",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Honduras",
        "origen": "Todos",
        "destino": "Timor Oriental",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.97",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Honduras",
        "origen": "Todos",
        "destino": "Tuvalu",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.97",
        "requisitos": ""
       },
       {
        "pasaporte": "Honduras",
        "origen": "Todos",
        "destino": "Zambia",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.97",
        "requisitos": ""
       },
       {
        "pasaporte": "Honduras",
        "origen": "Todos",
        "destino": "Zimbabue",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.97",
        "requisitos": ""
       },
       {
        "pasaporte": "Honduras",
        "origen": "Todos",
        "destino": "Corea del Sur",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.95",
        "requisitos": "Requiere ETA\nPasaporte vigente"
       },
       {
        "pasaporte": "Honduras",
        "origen": "Todos",
        "destino": "Kenia",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.95",
        "requisitos": "Requiere ETA\nPasaporte vigente"
       },
       {
        "pasaporte": "Honduras",
        "origen": "Todos",
        "destino": "Australia",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.93",
        "requisitos": "Visado\nPasaporte vigente"
       },
       {
        "pasaporte": "Honduras",
        "origen": "Todos",
        "destino": "Azerbaiyán",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.93",
        "requisitos": ""
       },
       {
        "pasaporte": "Honduras",
        "origen": "Todos",
        "destino": "Bahréin",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.93",
        "requisitos": "Visado eléctronico\nPasaporte vigente"
       },
       {
        "pasaporte": "Honduras",
        "origen": "Todos",
        "destino": "Benín",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.93",
        "requisitos": ""
       },
       {
        "pasaporte": "Honduras",
        "origen": "Todos",
        "destino": "Birmania",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.93",
        "requisitos": ""
       },
       {
        "pasaporte": "Honduras",
        "origen": "Todos",
        "destino": "Botsuana",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.93",
        "requisitos": ""
       },
       {
        "pasaporte": "Honduras",
        "origen": "Todos",
        "destino": "Burkina Faso",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.93",
        "requisitos": ""
       },
       {
        "pasaporte": "Honduras",
        "origen": "Todos",
        "destino": "Bután",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.93",
        "requisitos": ""
       },
       {
        "pasaporte": "Honduras",
        "origen": "Todos",
        "destino": "Camerún",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.93",
        "requisitos": ""
       },
       {
        "pasaporte": "Honduras",
        "origen": "Todos",
        "destino": "Costa de Marfil",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.93",
        "requisitos": ""
       },
       {
        "pasaporte": "Honduras",
        "origen": "Todos",
        "destino": "Emiratos Árabes Unidos",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.93",
        "requisitos": "Visado eléctronico\nPasaporte vigente"
       },
       {
        "pasaporte": "Honduras",
        "origen": "Todos",
        "destino": "Etiopía",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.93",
        "requisitos": ""
       },
       {
        "pasaporte": "Honduras",
        "origen": "Todos",
        "destino": "Gabón",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.93",
        "requisitos": ""
       },
       {
        "pasaporte": "Honduras",
        "origen": "Todos",
        "destino": "Guinea",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.93",
        "requisitos": ""
       },
       {
        "pasaporte": "Honduras",
        "origen": "Todos",
        "destino": "Guinea Ecuatorial",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.93",
        "requisitos": ""
       },
       {
        "pasaporte": "Honduras",
        "origen": "Todos",
        "destino": "India",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.93",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Honduras",
        "origen": "Todos",
        "destino": "Indonesia",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.93",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Honduras",
        "origen": "Todos",
        "destino": "Irán",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.93",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Honduras",
        "origen": "Todos",
        "destino": "Kazajistán",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.93",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Honduras",
        "origen": "Todos",
        "destino": "Kirguistán",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.93",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Honduras",
        "origen": "Todos",
        "destino": "Lesoto",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.93",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Honduras",
        "origen": "Todos",
        "destino": "Libia",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.93",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Honduras",
        "origen": "Todos",
        "destino": "Mongolia",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.93",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Honduras",
        "origen": "Todos",
        "destino": "Nigeria",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.93",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Honduras",
        "origen": "Todos",
        "destino": "Omán",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.93",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Honduras",
        "origen": "Todos",
        "destino": "Pakistán",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.93",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Honduras",
        "origen": "Todos",
        "destino": "Papúa Nueva Guinea",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.93",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Honduras",
        "origen": "Todos",
        "destino": "República Democrática del Congo",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.93",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Honduras",
        "origen": "Todos",
        "destino": "Santo Tomé y Príncipe",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.93",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Honduras",
        "origen": "Todos",
        "destino": "Siria",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.93",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Honduras",
        "origen": "Todos",
        "destino": "Sudán del Sur",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.93",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Honduras",
        "origen": "Todos",
        "destino": "Taiwán",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.93",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Honduras",
        "origen": "Todos",
        "destino": "Tayikistán",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.93",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Honduras",
        "origen": "Todos",
        "destino": "Togo",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.93",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Honduras",
        "origen": "Todos",
        "destino": "Uganda",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.93",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Honduras",
        "origen": "Todos",
        "destino": "Afganistán",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Honduras",
        "origen": "Todos",
        "destino": "Argelia",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Honduras",
        "origen": "Todos",
        "destino": "Bermuda",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Honduras",
        "origen": "Todos",
        "destino": "Brunei",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Honduras",
        "origen": "Todos",
        "destino": "Canadá",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Honduras",
        "origen": "Todos",
        "destino": "Chad",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Honduras",
        "origen": "Todos",
        "destino": "China",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Honduras",
        "origen": "Todos",
        "destino": "Corea del Norte",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Honduras",
        "origen": "Todos",
        "destino": "Cuba",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado\nPasaporte vigente\nAsistencia Médica de Viaje\nReserva de vuelo\nReserva de hospedaje\nSolvencia económica"
       },
       {
        "pasaporte": "Honduras",
        "origen": "Todos",
        "destino": "Eritrea",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": ""
       },
       {
        "pasaporte": "Honduras",
        "origen": "Todos",
        "destino": "Estados Unidos de América",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Honduras",
        "origen": "Todos",
        "destino": "Eswatini",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": ""
       },
       {
        "pasaporte": "Honduras",
        "origen": "Todos",
        "destino": "Falkland Islands",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": ""
       },
       {
        "pasaporte": "Honduras",
        "origen": "Todos",
        "destino": "Fiji",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": ""
       },
       {
        "pasaporte": "Honduras",
        "origen": "Todos",
        "destino": "Gambia",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado\nPasaporte vigente\nAsistencia Médica de Viaje\nReserva de vuelo\nReserva de hospedaje\nSolvencia económica"
       },
       {
        "pasaporte": "Honduras",
        "origen": "Todos",
        "destino": "Ghana",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado\nPasaporte vigente\nAsistencia Médica de Viaje\nReserva de vuelo\nReserva de hospedaje\nSolvencia económica"
       },
       {
        "pasaporte": "Honduras",
        "origen": "Todos",
        "destino": "Granada",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado\nPasaporte vigente\nAsistencia Médica de Viaje\nReserva de vuelo\nReserva de hospedaje\nSolvencia económica"
       },
       {
        "pasaporte": "Honduras",
        "origen": "Todos",
        "destino": "Guam",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado\nPasaporte vigente\nAsistencia Médica de Viaje\nReserva de vuelo\nReserva de hospedaje\nSolvencia económica"
       },
       {
        "pasaporte": "Honduras",
        "origen": "Todos",
        "destino": "Haití",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado\nPasaporte vigente\nAsistencia Médica de Viaje\nReserva de vuelo\nReserva de hospedaje\nSolvencia económica"
       },
       {
        "pasaporte": "Honduras",
        "origen": "Todos",
        "destino": "Irak",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado\nPasaporte vigente\nAsistencia Médica de Viaje\nReserva de vuelo\nReserva de hospedaje\nSolvencia económica"
       },
       {
        "pasaporte": "Honduras",
        "origen": "Todos",
        "destino": "Isla Norfolk",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado\nPasaporte vigente\nAsistencia Médica de Viaje\nReserva de vuelo\nReserva de hospedaje\nSolvencia económica"
       },
       {
        "pasaporte": "Honduras",
        "origen": "Todos",
        "destino": "Islas Marianas del Norte",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado\nPasaporte vigente\nAsistencia Médica de Viaje\nReserva de vuelo\nReserva de hospedaje\nSolvencia económica"
       },
       {
        "pasaporte": "Honduras",
        "origen": "Todos",
        "destino": "Islas Marshall",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado\nPasaporte vigente\nAsistencia Médica de Viaje\nReserva de vuelo\nReserva de hospedaje\nSolvencia económica"
       },
       {
        "pasaporte": "Honduras",
        "origen": "Todos",
        "destino": "Islas Salomón",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado\nPasaporte vigente\nAsistencia Médica de Viaje\nReserva de vuelo\nReserva de hospedaje\nSolvencia económica"
       },
       {
        "pasaporte": "Honduras",
        "origen": "Todos",
        "destino": "Islas Vírgenes Americanas",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado\nPasaporte vigente\nAsistencia Médica de Viaje\nReserva de vuelo\nReserva de hospedaje\nSolvencia económica"
       },
       {
        "pasaporte": "Honduras",
        "origen": "Todos",
        "destino": "Kuwait",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado\nPasaporte vigente\nAsistencia Médica de Viaje\nReserva de vuelo\nReserva de hospedaje\nSolvencia económica"
       },
       {
        "pasaporte": "Honduras",
        "origen": "Todos",
        "destino": "Liberia",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado\nPasaporte vigente\nAsistencia Médica de Viaje\nReserva de vuelo\nReserva de hospedaje\nSolvencia económica"
       },
       {
        "pasaporte": "Honduras",
        "origen": "Todos",
        "destino": "Mali",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado\nPasaporte vigente\nAsistencia Médica de Viaje\nReserva de vuelo\nReserva de hospedaje\nSolvencia económica"
       },
       {
        "pasaporte": "Honduras",
        "origen": "Todos",
        "destino": "Marruecos",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado\nPasaporte vigente\nAsistencia Médica de Viaje\nReserva de vuelo\nReserva de hospedaje\nSolvencia económica"
       },
       {
        "pasaporte": "Honduras",
        "origen": "Todos",
        "destino": "Namibia",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado\nPasaporte vigente\nAsistencia Médica de Viaje\nReserva de vuelo\nReserva de hospedaje\nSolvencia económica"
       },
       {
        "pasaporte": "Honduras",
        "origen": "Todos",
        "destino": "Nauru",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado\nPasaporte vigente\nAsistencia Médica de Viaje\nReserva de vuelo\nReserva de hospedaje\nSolvencia económica"
       },
       {
        "pasaporte": "Honduras",
        "origen": "Todos",
        "destino": "Níger",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado\nPasaporte vigente\nAsistencia Médica de Viaje\nReserva de vuelo\nReserva de hospedaje\nSolvencia económica"
       },
       {
        "pasaporte": "Honduras",
        "origen": "Todos",
        "destino": "Nueva Zelanda",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado\nPasaporte vigente\nAsistencia Médica de Viaje\nReserva de vuelo\nReserva de hospedaje\nSolvencia económica"
       },
       {
        "pasaporte": "Honduras",
        "origen": "Todos",
        "destino": "Puerto Rico",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Honduras",
        "origen": "Todos",
        "destino": "República Centroafricana",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": ""
       },
       {
        "pasaporte": "Honduras",
        "origen": "Todos",
        "destino": "República del Congo",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": ""
       },
       {
        "pasaporte": "Honduras",
        "origen": "Todos",
        "destino": "Samoa Americana",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": ""
       },
       {
        "pasaporte": "Honduras",
        "origen": "Todos",
        "destino": "Senegal",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado\nPasaporte vigente\nAsistencia Médica de Viaje\nReserva de vuelo\nReserva de hospedaje\nSolvencia económica"
       },
       {
        "pasaporte": "Honduras",
        "origen": "Todos",
        "destino": "Serbia",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado\nPasaporte vigente\nAsistencia Médica de Viaje\nReserva de vuelo\nReserva de hospedaje\nSolvencia económica"
       },
       {
        "pasaporte": "Honduras",
        "origen": "Todos",
        "destino": "Sierra Leona",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado\nPasaporte vigente\nAsistencia Médica de Viaje\nReserva de vuelo\nReserva de hospedaje\nSolvencia económica"
       },
       {
        "pasaporte": "Honduras",
        "origen": "Todos",
        "destino": "Somalia",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado\nPasaporte vigente\nAsistencia Médica de Viaje\nReserva de vuelo\nReserva de hospedaje\nSolvencia económica"
       },
       {
        "pasaporte": "Honduras",
        "origen": "Todos",
        "destino": "Sudán",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado\nPasaporte vigente\nAsistencia Médica de Viaje\nReserva de vuelo\nReserva de hospedaje\nSolvencia económica"
       },
       {
        "pasaporte": "Honduras",
        "origen": "Todos",
        "destino": "Tonga",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado\nPasaporte vigente\nAsistencia Médica de Viaje\nReserva de vuelo\nReserva de hospedaje\nSolvencia económica"
       },
       {
        "pasaporte": "Honduras",
        "origen": "Todos",
        "destino": "Túnez",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado\nPasaporte vigente\nAsistencia Médica de Viaje\nReserva de vuelo\nReserva de hospedaje\nSolvencia económica"
       },
       {
        "pasaporte": "Honduras",
        "origen": "Todos",
        "destino": "Turkmenistán",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado\nPasaporte vigente\nAsistencia Médica de Viaje\nReserva de vuelo\nReserva de hospedaje\nSolvencia económica"
       },
       {
        "pasaporte": "Honduras",
        "origen": "Todos",
        "destino": "Vanuatu",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado\nPasaporte vigente\nAsistencia Médica de Viaje\nReserva de vuelo\nReserva de hospedaje\nSolvencia económica"
       },
       {
        "pasaporte": "Honduras",
        "origen": "Todos",
        "destino": "Venezuela",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado\nPasaporte vigente\nAsistencia Médica de Viaje\nReserva de vuelo\nReserva de hospedaje\nSolvencia económica"
       },
       {
        "pasaporte": "Honduras",
        "origen": "Todos",
        "destino": "Yemen",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": ""
       },
       {
        "pasaporte": "Panamá",
        "origen": "Todos",
        "destino": "Albania",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Panamá",
        "origen": "Todos",
        "destino": "Alemania",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Panamá",
        "origen": "Todos",
        "destino": "Andorra",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Panamá",
        "origen": "Todos",
        "destino": "Angola",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Panamá",
        "origen": "Todos",
        "destino": "Anguilla",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Panamá",
        "origen": "Todos",
        "destino": "Antigua y Barbuda",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Panamá",
        "origen": "Todos",
        "destino": "Argentina",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Panamá",
        "origen": "Todos",
        "destino": "Aruba",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Panamá",
        "origen": "Todos",
        "destino": "Austria",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Panamá",
        "origen": "Todos",
        "destino": "Bahamas",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Panamá",
        "origen": "Todos",
        "destino": "Barbados",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Panamá",
        "origen": "Todos",
        "destino": "Bélgica",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Panamá",
        "origen": "Todos",
        "destino": "Belice",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Panamá",
        "origen": "Todos",
        "destino": "Bielorrusia",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Panamá",
        "origen": "Todos",
        "destino": "Bolivia",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Panamá",
        "origen": "Todos",
        "destino": "Bonaire, San Eustaquio y Saba",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Panamá",
        "origen": "Todos",
        "destino": "Bosnia-Herzegovina",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Panamá",
        "origen": "Todos",
        "destino": "Brasil",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Panamá",
        "origen": "Todos",
        "destino": "Bulgaria",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Panamá",
        "origen": "Todos",
        "destino": "Chile",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Panamá",
        "origen": "Todos",
        "destino": "Chipre",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Panamá",
        "origen": "Todos",
        "destino": "Ciudad del Vaticano",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Panamá",
        "origen": "Todos",
        "destino": "Colombia",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Panamá",
        "origen": "Todos",
        "destino": "Costa Rica",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Panamá",
        "origen": "Todos",
        "destino": "Croacia",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Panamá",
        "origen": "Todos",
        "destino": "Curaçao",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Panamá",
        "origen": "Todos",
        "destino": "Dinamarca",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Panamá",
        "origen": "Todos",
        "destino": "Dominica",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Panamá",
        "origen": "Todos",
        "destino": "Ecuador",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Panamá",
        "origen": "Todos",
        "destino": "El Salvador",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Panamá",
        "origen": "Todos",
        "destino": "Eslovaquia",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Panamá",
        "origen": "Todos",
        "destino": "Eslovenia",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Panamá",
        "origen": "Todos",
        "destino": "España",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Panamá",
        "origen": "Todos",
        "destino": "Estonia",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Panamá",
        "origen": "Todos",
        "destino": "Federación Rusa",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Panamá",
        "origen": "Todos",
        "destino": "Filipinas",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Panamá",
        "origen": "Todos",
        "destino": "Finlandia",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Panamá",
        "origen": "Todos",
        "destino": "Francia",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Panamá",
        "origen": "Todos",
        "destino": "Georgia",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Panamá",
        "origen": "Todos",
        "destino": "Gibraltar",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Panamá",
        "origen": "Todos",
        "destino": "Grecia",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Panamá",
        "origen": "Todos",
        "destino": "Groenlandia",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Panamá",
        "origen": "Todos",
        "destino": "Guatemala",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Panamá",
        "origen": "Todos",
        "destino": "Guayana Francesa",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Panamá",
        "origen": "Todos",
        "destino": "Guyana",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Panamá",
        "origen": "Todos",
        "destino": "Honduras",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Panamá",
        "origen": "Todos",
        "destino": "Hong Kong",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Panamá",
        "origen": "Todos",
        "destino": "Hungría",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Panamá",
        "origen": "Todos",
        "destino": "Indias Occidentales Francesas",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Panamá",
        "origen": "Todos",
        "destino": "Irlanda",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Panamá",
        "origen": "Todos",
        "destino": "Isla Reunión",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Panamá",
        "origen": "Todos",
        "destino": "Islandia",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Panamá",
        "origen": "Todos",
        "destino": "Islas Caimán",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Panamá",
        "origen": "Todos",
        "destino": "Islas Cook",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Panamá",
        "origen": "Todos",
        "destino": "Islas Feroe",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Panamá",
        "origen": "Todos",
        "destino": "Islas Turcas y Caicos",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Panamá",
        "origen": "Todos",
        "destino": "Islas Vírgenes Británicas",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Panamá",
        "origen": "Todos",
        "destino": "Israel",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Panamá",
        "origen": "Todos",
        "destino": "Italia",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Panamá",
        "origen": "Todos",
        "destino": "Jamaica",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Panamá",
        "origen": "Todos",
        "destino": "Japón",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Panamá",
        "origen": "Todos",
        "destino": "Kiribati",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Panamá",
        "origen": "Todos",
        "destino": "Kosovo",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Panamá",
        "origen": "Todos",
        "destino": "Letonia",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Panamá",
        "origen": "Todos",
        "destino": "Liechtenstein",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Panamá",
        "origen": "Todos",
        "destino": "Lituania",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Panamá",
        "origen": "Todos",
        "destino": "Luxemburgo",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Panamá",
        "origen": "Todos",
        "destino": "Macedonia del Norte",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Panamá",
        "origen": "Todos",
        "destino": "Malasia",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Panamá",
        "origen": "Todos",
        "destino": "Malta",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Panamá",
        "origen": "Todos",
        "destino": "Mayotte",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Panamá",
        "origen": "Todos",
        "destino": "México",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Panamá",
        "origen": "Todos",
        "destino": "Micronesia",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Panamá",
        "origen": "Todos",
        "destino": "Moldavia",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Panamá",
        "origen": "Todos",
        "destino": "Mónaco",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Panamá",
        "origen": "Todos",
        "destino": "Montenegro",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Panamá",
        "origen": "Todos",
        "destino": "Montserrat",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Panamá",
        "origen": "Todos",
        "destino": "Nicaragua",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Panamá",
        "origen": "Todos",
        "destino": "Noruega",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Panamá",
        "origen": "Todos",
        "destino": "Nueva Caledonia",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Panamá",
        "origen": "Todos",
        "destino": "Países Bajos",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Panamá",
        "origen": "Todos",
        "destino": "Paraguay",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Panamá",
        "origen": "Todos",
        "destino": "Perú",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Panamá",
        "origen": "Todos",
        "destino": "Polinesia Francesa",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Panamá",
        "origen": "Todos",
        "destino": "Polonia",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Panamá",
        "origen": "Todos",
        "destino": "Portugal",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Panamá",
        "origen": "Todos",
        "destino": "Reino Unido",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Panamá",
        "origen": "Todos",
        "destino": "República Checa",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Panamá",
        "origen": "Todos",
        "destino": "República Dominicana",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Panamá",
        "origen": "Todos",
        "destino": "Rumanía",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Panamá",
        "origen": "Todos",
        "destino": "San Cristóbal y Nieves",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Panamá",
        "origen": "Todos",
        "destino": "San Marino",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Panamá",
        "origen": "Todos",
        "destino": "San Martín",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Panamá",
        "origen": "Todos",
        "destino": "San Pedro y Miquelón",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Panamá",
        "origen": "Todos",
        "destino": "San Vicente y las Granadinas",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Panamá",
        "origen": "Todos",
        "destino": "Santa Elena",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Panamá",
        "origen": "Todos",
        "destino": "Santa Lucía",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Panamá",
        "origen": "Todos",
        "destino": "Singapur",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Panamá",
        "origen": "Todos",
        "destino": "Sudáfrica",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Panamá",
        "origen": "Todos",
        "destino": "Suecia",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Panamá",
        "origen": "Todos",
        "destino": "Suiza",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Panamá",
        "origen": "Todos",
        "destino": "Surinam",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Panamá",
        "origen": "Todos",
        "destino": "Tailandia",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Panamá",
        "origen": "Todos",
        "destino": "Territorios Palestinos",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Panamá",
        "origen": "Todos",
        "destino": "Trinidad y Tobago",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Panamá",
        "origen": "Todos",
        "destino": "Turquía",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Panamá",
        "origen": "Todos",
        "destino": "Ucrania",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Panamá",
        "origen": "Todos",
        "destino": "Uruguay",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Panamá",
        "origen": "Todos",
        "destino": "Uzbekistán",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Panamá",
        "origen": "Todos",
        "destino": "Vietnam",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Panamá",
        "origen": "Todos",
        "destino": "Wallis y Futuna",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Panamá",
        "origen": "Todos",
        "destino": "Arabia Saudí",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.98",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Panamá",
        "origen": "Todos",
        "destino": "Armenia",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.98",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Panamá",
        "origen": "Todos",
        "destino": "Bangladesh",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.98",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Panamá",
        "origen": "Todos",
        "destino": "Burundi",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.98",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Panamá",
        "origen": "Todos",
        "destino": "Comoras",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.98",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Panamá",
        "origen": "Todos",
        "destino": "Djibouti",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.98",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Panamá",
        "origen": "Todos",
        "destino": "Egipto",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.98",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Panamá",
        "origen": "Todos",
        "destino": "Guinea-Bissau",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.98",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Panamá",
        "origen": "Todos",
        "destino": "Isla Mauricio",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.98",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Panamá",
        "origen": "Todos",
        "destino": "Islas Seychelles",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.98",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Panamá",
        "origen": "Todos",
        "destino": "Jordania",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.98",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Panamá",
        "origen": "Todos",
        "destino": "Laos",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.98",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Panamá",
        "origen": "Todos",
        "destino": "Líbano",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.98",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Panamá",
        "origen": "Todos",
        "destino": "Macao",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.98",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Panamá",
        "origen": "Todos",
        "destino": "Madagascar",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.98",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Panamá",
        "origen": "Todos",
        "destino": "Malawi",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.98",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Panamá",
        "origen": "Todos",
        "destino": "Maldivas",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.98",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Panamá",
        "origen": "Todos",
        "destino": "Mauritania",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.98",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Panamá",
        "origen": "Todos",
        "destino": "Mozambique",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.98",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Panamá",
        "origen": "Todos",
        "destino": "Nepal",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.98",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Panamá",
        "origen": "Todos",
        "destino": "Niue",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.98",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Panamá",
        "origen": "Todos",
        "destino": "Palaos",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.98",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Panamá",
        "origen": "Todos",
        "destino": "Ruanda",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.98",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Panamá",
        "origen": "Todos",
        "destino": "Samoa",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.98",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Panamá",
        "origen": "Todos",
        "destino": "Sri Lanka",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.98",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Panamá",
        "origen": "Todos",
        "destino": "Tanzania",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.98",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Panamá",
        "origen": "Todos",
        "destino": "Timor Oriental",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.98",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Panamá",
        "origen": "Todos",
        "destino": "Tuvalu",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.98",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Panamá",
        "origen": "Todos",
        "destino": "Zambia",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.98",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Panamá",
        "origen": "Todos",
        "destino": "Zimbabue",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.98",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Panamá",
        "origen": "Todos",
        "destino": "Corea del Sur",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.97",
        "requisitos": "Autorización ETA\nPasaporte vigente"
       },
       {
        "pasaporte": "Panamá",
        "origen": "Todos",
        "destino": "Kenia",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.97",
        "requisitos": "Autorización ETA\nPasaporte vigente"
       },
       {
        "pasaporte": "Panamá",
        "origen": "Todos",
        "destino": "Australia",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.95",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Panamá",
        "origen": "Todos",
        "destino": "Azerbaiyán",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.95",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Panamá",
        "origen": "Todos",
        "destino": "Bahrain",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.95",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Panamá",
        "origen": "Todos",
        "destino": "Benín",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.95",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Panamá",
        "origen": "Todos",
        "destino": "Birmania",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.95",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Panamá",
        "origen": "Todos",
        "destino": "Botswana",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.95",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Panamá",
        "origen": "Todos",
        "destino": "Burkina Faso",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.95",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Panamá",
        "origen": "Todos",
        "destino": "Bután",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.95",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Panamá",
        "origen": "Todos",
        "destino": "Camerún",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.95",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Panamá",
        "origen": "Todos",
        "destino": "Costa de Marfil",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.95",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Panamá",
        "origen": "Todos",
        "destino": "Emiratos Árabes Unidos",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.95",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Panamá",
        "origen": "Todos",
        "destino": "Etiopia",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.95",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Panamá",
        "origen": "Todos",
        "destino": "Gabón",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.95",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Panamá",
        "origen": "Todos",
        "destino": "Guinea",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.95",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Panamá",
        "origen": "Todos",
        "destino": "Guinea Ecuatorial",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.95",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Panamá",
        "origen": "Todos",
        "destino": "India",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.95",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Panamá",
        "origen": "Todos",
        "destino": "Indonesia",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.95",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Panamá",
        "origen": "Todos",
        "destino": "Irán",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.95",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Panamá",
        "origen": "Todos",
        "destino": "Kazajistán",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.95",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Panamá",
        "origen": "Todos",
        "destino": "Kirguistán",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.95",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Panamá",
        "origen": "Todos",
        "destino": "Lesoto",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.95",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Panamá",
        "origen": "Todos",
        "destino": "Libia",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.95",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Panamá",
        "origen": "Todos",
        "destino": "Mongolia",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.95",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Panamá",
        "origen": "Todos",
        "destino": "Nigeria",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.95",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Panamá",
        "origen": "Todos",
        "destino": "Omán",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.95",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Panamá",
        "origen": "Todos",
        "destino": "Pakistán",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.95",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Panamá",
        "origen": "Todos",
        "destino": "Papua Nueva Guinea",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.95",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Panamá",
        "origen": "Todos",
        "destino": "República Democrática del Congo",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.95",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Panamá",
        "origen": "Todos",
        "destino": "Santo Tomé y Príncipe",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.95",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Panamá",
        "origen": "Todos",
        "destino": "Siria",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.95",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Panamá",
        "origen": "Todos",
        "destino": "Sudán del Sur",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.95",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Panamá",
        "origen": "Todos",
        "destino": "Taiwán",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.95",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Panamá",
        "origen": "Todos",
        "destino": "Tajikistán",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.95",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Panamá",
        "origen": "Todos",
        "destino": "Togo",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.95",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Panamá",
        "origen": "Todos",
        "destino": "Uganda",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.95",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Panamá",
        "origen": "Todos",
        "destino": "Afganistán",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado\nPasaporte vigente\nAsistencia Médica de Viaje\nReserva de vuelo\nReserva de hospedaje\nSolvencia económica"
       },
       {
        "pasaporte": "Panamá",
        "origen": "Todos",
        "destino": "Argelia",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado\nPasaporte vigente\nAsistencia Médica de Viaje\nReserva de vuelo\nReserva de hospedaje\nSolvencia económica"
       },
       {
        "pasaporte": "Panamá",
        "origen": "Todos",
        "destino": "Bermuda",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado\nPasaporte vigente\nAsistencia Médica de Viaje\nReserva de vuelo\nReserva de hospedaje\nSolvencia económica"
       },
       {
        "pasaporte": "Panamá",
        "origen": "Todos",
        "destino": "Brunei",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado\nPasaporte vigente\nAsistencia Médica de Viaje\nReserva de vuelo\nReserva de hospedaje\nSolvencia económica"
       },
       {
        "pasaporte": "Panamá",
        "origen": "Todos",
        "destino": "Canadá",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado\nPasaporte vigente\nAsistencia Médica de Viaje\nReserva de vuelo\nReserva de hospedaje\nSolvencia económica"
       },
       {
        "pasaporte": "Panamá",
        "origen": "Todos",
        "destino": "Chad",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado\nPasaporte vigente\nAsistencia Médica de Viaje\nReserva de vuelo\nReserva de hospedaje\nSolvencia económica"
       },
       {
        "pasaporte": "Panamá",
        "origen": "Todos",
        "destino": "China",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado\nPasaporte vigente\nAsistencia Médica de Viaje\nReserva de vuelo\nReserva de hospedaje\nSolvencia económica"
       },
       {
        "pasaporte": "Panamá",
        "origen": "Todos",
        "destino": "Corea del Norte",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado\nPasaporte vigente\nAsistencia Médica de Viaje\nReserva de vuelo\nReserva de hospedaje\nSolvencia económica"
       },
       {
        "pasaporte": "Panamá",
        "origen": "Todos",
        "destino": "Cuba",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado\nPasaporte vigente\nAsistencia Médica de Viaje\nReserva de vuelo\nReserva de hospedaje\nSolvencia económica"
       },
       {
        "pasaporte": "Panamá",
        "origen": "Todos",
        "destino": "Eritrea",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": ""
       },
       {
        "pasaporte": "Panamá",
        "origen": "Todos",
        "destino": "Estados Unidos de América",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Panamá",
        "origen": "Todos",
        "destino": "Eswatini",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": ""
       },
       {
        "pasaporte": "Panamá",
        "origen": "Todos",
        "destino": "Falkland Islands",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": ""
       },
       {
        "pasaporte": "Panamá",
        "origen": "Todos",
        "destino": "Fiji",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": ""
       },
       {
        "pasaporte": "Panamá",
        "origen": "Todos",
        "destino": "Gambia",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": ""
       },
       {
        "pasaporte": "Panamá",
        "origen": "Todos",
        "destino": "Ghana",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": ""
       },
       {
        "pasaporte": "Panamá",
        "origen": "Todos",
        "destino": "Granada",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Panamá",
        "origen": "Todos",
        "destino": "Guam",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": ""
       },
       {
        "pasaporte": "Panamá",
        "origen": "Todos",
        "destino": "Haití",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Panamá",
        "origen": "Todos",
        "destino": "Irak",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": ""
       },
       {
        "pasaporte": "Panamá",
        "origen": "Todos",
        "destino": "Isla Norfolk",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": ""
       },
       {
        "pasaporte": "Panamá",
        "origen": "Todos",
        "destino": "Islas Marianas del Norte",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": ""
       },
       {
        "pasaporte": "Panamá",
        "origen": "Todos",
        "destino": "Islas Marshall",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Panamá",
        "origen": "Todos",
        "destino": "Islas Salomón",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Panamá",
        "origen": "Todos",
        "destino": "Islas Vírgenes americanas",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Panamá",
        "origen": "Todos",
        "destino": "Kuwait",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Panamá",
        "origen": "Todos",
        "destino": "Liberia",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Panamá",
        "origen": "Todos",
        "destino": "Mali",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Panamá",
        "origen": "Todos",
        "destino": "Marruecos",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Panamá",
        "origen": "Todos",
        "destino": "Namibia",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Panamá",
        "origen": "Todos",
        "destino": "Nauru",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Panamá",
        "origen": "Todos",
        "destino": "Níger",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": ""
       },
       {
        "pasaporte": "Panamá",
        "origen": "Todos",
        "destino": "Nueva Zelanda",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado\nPasaporte vigente\nAsistencia Médica de Viaje\nReserva de vuelo\nReserva de hospedaje\nSolvencia económica"
       },
       {
        "pasaporte": "Panamá",
        "origen": "Todos",
        "destino": "Puerto Rico",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Panamá",
        "origen": "Todos",
        "destino": "República Centroafricana",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": ""
       },
       {
        "pasaporte": "Panamá",
        "origen": "Todos",
        "destino": "República del Congo",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": ""
       },
       {
        "pasaporte": "Panamá",
        "origen": "Todos",
        "destino": "Samoa Americana",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": ""
       },
       {
        "pasaporte": "Panamá",
        "origen": "Todos",
        "destino": "Senegal",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado\nPasaporte vigente\nAsistencia Médica de Viaje\nReserva de vuelo\nReserva de hospedaje\nSolvencia económica"
       },
       {
        "pasaporte": "Panamá",
        "origen": "Todos",
        "destino": "Serbia",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado\nPasaporte vigente\nAsistencia Médica de Viaje\nReserva de vuelo\nReserva de hospedaje\nSolvencia económica"
       },
       {
        "pasaporte": "Panamá",
        "origen": "Todos",
        "destino": "Sierra Leona",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado\nPasaporte vigente\nAsistencia Médica de Viaje\nReserva de vuelo\nReserva de hospedaje\nSolvencia económica"
       },
       {
        "pasaporte": "Panamá",
        "origen": "Todos",
        "destino": "Somalia",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado\nPasaporte vigente\nAsistencia Médica de Viaje\nReserva de vuelo\nReserva de hospedaje\nSolvencia económica"
       },
       {
        "pasaporte": "Panamá",
        "origen": "Todos",
        "destino": "Sudán",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado\nPasaporte vigente\nAsistencia Médica de Viaje\nReserva de vuelo\nReserva de hospedaje\nSolvencia económica"
       },
       {
        "pasaporte": "Panamá",
        "origen": "Todos",
        "destino": "Tonga",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado\nPasaporte vigente\nAsistencia Médica de Viaje\nReserva de vuelo\nReserva de hospedaje\nSolvencia económica"
       },
       {
        "pasaporte": "Panamá",
        "origen": "Todos",
        "destino": "Túnez",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado\nPasaporte vigente\nAsistencia Médica de Viaje\nReserva de vuelo\nReserva de hospedaje\nSolvencia económica"
       },
       {
        "pasaporte": "Panamá",
        "origen": "Todos",
        "destino": "Turkmenistán",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado\nPasaporte vigente\nAsistencia Médica de Viaje\nReserva de vuelo\nReserva de hospedaje\nSolvencia económica"
       },
       {
        "pasaporte": "Panamá",
        "origen": "Todos",
        "destino": "Vanuatu",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado\nPasaporte vigente\nAsistencia Médica de Viaje\nReserva de vuelo\nReserva de hospedaje\nSolvencia económica"
       },
       {
        "pasaporte": "Panamá",
        "origen": "Todos",
        "destino": "Venezuela",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado\nPasaporte vigente\nAsistencia Médica de Viaje\nReserva de vuelo\nReserva de hospedaje\nSolvencia económica"
       },
       {
        "pasaporte": "Panamá",
        "origen": "Todos",
        "destino": "Yemen",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado\nPasaporte vigente\nAsistencia Médica de Viaje\nReserva de vuelo\nReserva de hospedaje\nSolvencia económica"
       },
       {
        "pasaporte": "Brasil",
        "origen": "Todos",
        "destino": "Albania",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Brasil",
        "origen": "Todos",
        "destino": "Alemania",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Brasil",
        "origen": "Todos",
        "destino": "Andorra",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Brasil",
        "origen": "Todos",
        "destino": "Angola",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Brasil",
        "origen": "Todos",
        "destino": "Anguilla",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Brasil",
        "origen": "Todos",
        "destino": "Antigua y Barbuda",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Brasil",
        "origen": "Todos",
        "destino": "Argentina",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Brasil",
        "origen": "Todos",
        "destino": "Armenia",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Brasil",
        "origen": "Todos",
        "destino": "Aruba",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Brasil",
        "origen": "Todos",
        "destino": "Austria",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Brasil",
        "origen": "Todos",
        "destino": "Bahamas",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Brasil",
        "origen": "Todos",
        "destino": "Barbados",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Brasil",
        "origen": "Todos",
        "destino": "Bélgica",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Brasil",
        "origen": "Todos",
        "destino": "Belice",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Brasil",
        "origen": "Todos",
        "destino": "Bielorrusia",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Brasil",
        "origen": "Todos",
        "destino": "Bolivia",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Brasil",
        "origen": "Todos",
        "destino": "Bonaire, San Eustaquio y Saba",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Brasil",
        "origen": "Todos",
        "destino": "Bosnia-Herzegovina",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Brasil",
        "origen": "Todos",
        "destino": "Botswana",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Brasil",
        "origen": "Todos",
        "destino": "Bulgaria",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Brasil",
        "origen": "Todos",
        "destino": "Chile",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Brasil",
        "origen": "Todos",
        "destino": "Chipre",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Brasil",
        "origen": "Todos",
        "destino": "Ciudad del Vaticano",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Brasil",
        "origen": "Todos",
        "destino": "Colombia",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Brasil",
        "origen": "Todos",
        "destino": "Costa Rica",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Brasil",
        "origen": "Todos",
        "destino": "Croacia",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Brasil",
        "origen": "Todos",
        "destino": "Curaçao",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Brasil",
        "origen": "Todos",
        "destino": "Dinamarca",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Brasil",
        "origen": "Todos",
        "destino": "Dominica",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Brasil",
        "origen": "Todos",
        "destino": "Ecuador",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Brasil",
        "origen": "Todos",
        "destino": "El Salvador",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Brasil",
        "origen": "Todos",
        "destino": "Emiratos Árabes Unidos",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Brasil",
        "origen": "Todos",
        "destino": "Eslovaquia",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Brasil",
        "origen": "Todos",
        "destino": "Eslovenia",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Brasil",
        "origen": "Todos",
        "destino": "España",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Brasil",
        "origen": "Todos",
        "destino": "Estonia",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Brasil",
        "origen": "Todos",
        "destino": "Eswatini",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Brasil",
        "origen": "Todos",
        "destino": "Falkland Islands",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Brasil",
        "origen": "Todos",
        "destino": "Federación Rusa",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Brasil",
        "origen": "Todos",
        "destino": "Fiji",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Brasil",
        "origen": "Todos",
        "destino": "Filipinas",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Brasil",
        "origen": "Todos",
        "destino": "Finlandia",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Brasil",
        "origen": "Todos",
        "destino": "Francia",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Brasil",
        "origen": "Todos",
        "destino": "Georgia",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Brasil",
        "origen": "Todos",
        "destino": "Gibraltar",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Brasil",
        "origen": "Todos",
        "destino": "Granada",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Brasil",
        "origen": "Todos",
        "destino": "Grecia",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Brasil",
        "origen": "Todos",
        "destino": "Groenlandia",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Brasil",
        "origen": "Todos",
        "destino": "Guatemala",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Brasil",
        "origen": "Todos",
        "destino": "Guyana",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Brasil",
        "origen": "Todos",
        "destino": "Haití",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Brasil",
        "origen": "Todos",
        "destino": "Honduras",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Brasil",
        "origen": "Todos",
        "destino": "Hong Kong",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Brasil",
        "origen": "Todos",
        "destino": "Hungría",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Brasil",
        "origen": "Todos",
        "destino": "Indias Occidentales Francesas",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Brasil",
        "origen": "Todos",
        "destino": "Irán",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Brasil",
        "origen": "Todos",
        "destino": "Irlanda",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Brasil",
        "origen": "Todos",
        "destino": "Isla Mauricio",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Brasil",
        "origen": "Todos",
        "destino": "Isla Reunión",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Brasil",
        "origen": "Todos",
        "destino": "Islandia",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Brasil",
        "origen": "Todos",
        "destino": "Islas Caimán",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Brasil",
        "origen": "Todos",
        "destino": "Islas Cook",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Brasil",
        "origen": "Todos",
        "destino": "Islas Feroe",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Brasil",
        "origen": "Todos",
        "destino": "Islas Turcas y Caicos",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Brasil",
        "origen": "Todos",
        "destino": "Islas Vírgenes Británicas",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Brasil",
        "origen": "Todos",
        "destino": "Israel",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Brasil",
        "origen": "Todos",
        "destino": "Italia",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Brasil",
        "origen": "Todos",
        "destino": "Jamaica",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Brasil",
        "origen": "Todos",
        "destino": "Japón",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Brasil",
        "origen": "Todos",
        "destino": "Kazajistán",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Brasil",
        "origen": "Todos",
        "destino": "Kirguistán",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Brasil",
        "origen": "Todos",
        "destino": "Kiribati",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Brasil",
        "origen": "Todos",
        "destino": "Kosovo",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Brasil",
        "origen": "Todos",
        "destino": "Letonia",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Brasil",
        "origen": "Todos",
        "destino": "Liechtenstein",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Brasil",
        "origen": "Todos",
        "destino": "Lituania",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Brasil",
        "origen": "Todos",
        "destino": "Luxemburgo",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Brasil",
        "origen": "Todos",
        "destino": "Macao",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Brasil",
        "origen": "Todos",
        "destino": "Macedonia del Norte",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Brasil",
        "origen": "Todos",
        "destino": "Malasia",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Brasil",
        "origen": "Todos",
        "destino": "Malta",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Brasil",
        "origen": "Todos",
        "destino": "Mayotte",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Brasil",
        "origen": "Todos",
        "destino": "Micronesia",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Brasil",
        "origen": "Todos",
        "destino": "Moldavia",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Brasil",
        "origen": "Todos",
        "destino": "Mónaco",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Brasil",
        "origen": "Todos",
        "destino": "Mongolia",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Brasil",
        "origen": "Todos",
        "destino": "Montenegro",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Brasil",
        "origen": "Todos",
        "destino": "Montserrat",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Brasil",
        "origen": "Todos",
        "destino": "Namibia",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Brasil",
        "origen": "Todos",
        "destino": "Nicaragua",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Brasil",
        "origen": "Todos",
        "destino": "Noruega",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Brasil",
        "origen": "Todos",
        "destino": "Nueva Caledonia",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Brasil",
        "origen": "Todos",
        "destino": "Países Bajos",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Brasil",
        "origen": "Todos",
        "destino": "Panamá",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Brasil",
        "origen": "Todos",
        "destino": "Paraguay",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Brasil",
        "origen": "Todos",
        "destino": "Perú",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Brasil",
        "origen": "Todos",
        "destino": "Polinesia Francesa",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Brasil",
        "origen": "Todos",
        "destino": "Polonia",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Brasil",
        "origen": "Todos",
        "destino": "Portugal",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Brasil",
        "origen": "Todos",
        "destino": "Reino Unido",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Brasil",
        "origen": "Todos",
        "destino": "República Checa",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Brasil",
        "origen": "Todos",
        "destino": "República Dominicana",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Brasil",
        "origen": "Todos",
        "destino": "Rumanía",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Brasil",
        "origen": "Todos",
        "destino": "San Cristóbal y Nieves",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Brasil",
        "origen": "Todos",
        "destino": "San Marino",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Brasil",
        "origen": "Todos",
        "destino": "San Martín",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Brasil",
        "origen": "Todos",
        "destino": "San Pedro y Miquelón",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Brasil",
        "origen": "Todos",
        "destino": "San Vicente y las Granadinas",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Brasil",
        "origen": "Todos",
        "destino": "Santa Elena",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Brasil",
        "origen": "Todos",
        "destino": "Santa Lucía",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Brasil",
        "origen": "Todos",
        "destino": "Santo Tomé y Príncipe",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Brasil",
        "origen": "Todos",
        "destino": "Senegal",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Brasil",
        "origen": "Todos",
        "destino": "Serbia",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Brasil",
        "origen": "Todos",
        "destino": "Singapur",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Brasil",
        "origen": "Todos",
        "destino": "Sudáfrica",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Brasil",
        "origen": "Todos",
        "destino": "Suecia",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Brasil",
        "origen": "Todos",
        "destino": "Suiza",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Brasil",
        "origen": "Todos",
        "destino": "Surinam",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Brasil",
        "origen": "Todos",
        "destino": "Tailandia",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Brasil",
        "origen": "Todos",
        "destino": "Tajikistán",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Brasil",
        "origen": "Todos",
        "destino": "Territorios Palestinos",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Brasil",
        "origen": "Todos",
        "destino": "Trinidad y Tobago",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Brasil",
        "origen": "Todos",
        "destino": "Túnez",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Brasil",
        "origen": "Todos",
        "destino": "Turquía",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Brasil",
        "origen": "Todos",
        "destino": "Ucrania",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Brasil",
        "origen": "Todos",
        "destino": "Uruguay",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Brasil",
        "origen": "Todos",
        "destino": "Uzbekistán",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Brasil",
        "origen": "Todos",
        "destino": "Vanuatu",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Brasil",
        "origen": "Todos",
        "destino": "Venezuela",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Brasil",
        "origen": "Todos",
        "destino": "Wallis y Futuna",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Brasil",
        "origen": "Todos",
        "destino": "Bahrain",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.98",
        "requisitos": "Visado\nBoleto ida y vuelta\nHospedaje\nAsistencia Médica"
       },
       {
        "pasaporte": "Brasil",
        "origen": "Todos",
        "destino": "Burkina Faso",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.98",
        "requisitos": "Visado\nBoleto ida y vuelta\nHospedaje\nAsistencia Médica"
       },
       {
        "pasaporte": "Brasil",
        "origen": "Todos",
        "destino": "Burundi",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.98",
        "requisitos": "Visado\nBoleto ida y vuelta\nHospedaje\nAsistencia Médica"
       },
       {
        "pasaporte": "Brasil",
        "origen": "Todos",
        "destino": "Cabo Verde",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.98",
        "requisitos": "Visado\nBoleto ida y vuelta\nHospedaje\nAsistencia Médica"
       },
       {
        "pasaporte": "Brasil",
        "origen": "Todos",
        "destino": "Camboya",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.98",
        "requisitos": "Visado\nBoleto ida y vuelta\nHospedaje\nAsistencia Médica"
       },
       {
        "pasaporte": "Brasil",
        "origen": "Todos",
        "destino": "Comoras",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.98",
        "requisitos": "Visado\nBoleto ida y vuelta\nHospedaje\nAsistencia Médica"
       },
       {
        "pasaporte": "Brasil",
        "origen": "Todos",
        "destino": "Djibouti",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.98",
        "requisitos": "Visado\nBoleto ida y vuelta\nHospedaje\nAsistencia Médica"
       },
       {
        "pasaporte": "Brasil",
        "origen": "Todos",
        "destino": "Egipto",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.98",
        "requisitos": "Visado\nBoleto ida y vuelta\nHospedaje\nAsistencia Médica"
       },
       {
        "pasaporte": "Brasil",
        "origen": "Todos",
        "destino": "Etiopia",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.98",
        "requisitos": "Visado\nBoleto ida y vuelta\nHospedaje\nAsistencia Médica"
       },
       {
        "pasaporte": "Brasil",
        "origen": "Todos",
        "destino": "Guinea-Bissau",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.98",
        "requisitos": "Visado\nBoleto ida y vuelta\nHospedaje\nAsistencia Médica"
       },
       {
        "pasaporte": "Brasil",
        "origen": "Todos",
        "destino": "Indonesia",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.98",
        "requisitos": "Visado\nBoleto ida y vuelta\nHospedaje\nAsistencia Médica"
       },
       {
        "pasaporte": "Brasil",
        "origen": "Todos",
        "destino": "Islas Marshall",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.98",
        "requisitos": "Visado\nBoleto ida y vuelta\nHospedaje\nAsistencia Médica"
       },
       {
        "pasaporte": "Brasil",
        "origen": "Todos",
        "destino": "Islas Salomón",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.98",
        "requisitos": "Visado\nBoleto ida y vuelta\nHospedaje\nAsistencia Médica"
       },
       {
        "pasaporte": "Brasil",
        "origen": "Todos",
        "destino": "Islas Seychelles",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.98",
        "requisitos": "Visado\nBoleto ida y vuelta\nHospedaje\nAsistencia Médica"
       },
       {
        "pasaporte": "Brasil",
        "origen": "Todos",
        "destino": "Jordania",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.98",
        "requisitos": "Visado\nBoleto ida y vuelta\nHospedaje\nAsistencia Médica"
       },
       {
        "pasaporte": "Brasil",
        "origen": "Todos",
        "destino": "Laos",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.98",
        "requisitos": "Visado\nBoleto ida y vuelta\nHospedaje\nAsistencia Médica"
       },
       {
        "pasaporte": "Brasil",
        "origen": "Todos",
        "destino": "Líbano",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.98",
        "requisitos": "Visado\nBoleto ida y vuelta\nHospedaje\nAsistencia Médica"
       },
       {
        "pasaporte": "Brasil",
        "origen": "Todos",
        "destino": "Madagascar",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.98",
        "requisitos": "Visado\nBoleto ida y vuelta\nHospedaje\nAsistencia Médica"
       },
       {
        "pasaporte": "Brasil",
        "origen": "Todos",
        "destino": "Malawi",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.98",
        "requisitos": "Visado\nBoleto ida y vuelta\nHospedaje\nAsistencia Médica"
       },
       {
        "pasaporte": "Brasil",
        "origen": "Todos",
        "destino": "Maldivas",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.98",
        "requisitos": "Visado\nBoleto ida y vuelta\nHospedaje\nAsistencia Médica"
       },
       {
        "pasaporte": "Brasil",
        "origen": "Todos",
        "destino": "Mauritania",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.98",
        "requisitos": "Visado\nBoleto ida y vuelta\nHospedaje\nAsistencia Médica"
       },
       {
        "pasaporte": "Brasil",
        "origen": "Todos",
        "destino": "Mozambique",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.98",
        "requisitos": "Visado\nBoleto ida y vuelta\nHospedaje\nAsistencia Médica"
       },
       {
        "pasaporte": "Brasil",
        "origen": "Todos",
        "destino": "Nepal",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.98",
        "requisitos": "Visado\nBoleto ida y vuelta\nHospedaje\nAsistencia Médica"
       },
       {
        "pasaporte": "Brasil",
        "origen": "Todos",
        "destino": "Niue",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.98",
        "requisitos": "Visado\nBoleto ida y vuelta\nHospedaje\nAsistencia Médica"
       },
       {
        "pasaporte": "Brasil",
        "origen": "Todos",
        "destino": "Omán",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.98",
        "requisitos": "Visado\nBoleto ida y vuelta\nHospedaje\nAsistencia Médica"
       },
       {
        "pasaporte": "Brasil",
        "origen": "Todos",
        "destino": "Palaos",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.98",
        "requisitos": "Visado\nBoleto ida y vuelta\nHospedaje\nAsistencia Médica"
       },
       {
        "pasaporte": "Brasil",
        "origen": "Todos",
        "destino": "Qatar",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.98",
        "requisitos": "Visado\nBoleto ida y vuelta\nHospedaje\nAsistencia Médica"
       },
       {
        "pasaporte": "Brasil",
        "origen": "Todos",
        "destino": "Ruanda",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.98",
        "requisitos": "Visado\nBoleto ida y vuelta\nHospedaje\nAsistencia Médica"
       },
       {
        "pasaporte": "Brasil",
        "origen": "Todos",
        "destino": "Samoa",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.98",
        "requisitos": "Visado\nBoleto ida y vuelta\nHospedaje\nAsistencia Médica"
       },
       {
        "pasaporte": "Brasil",
        "origen": "Todos",
        "destino": "Sierra Leona",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.98",
        "requisitos": "Visado\nBoleto ida y vuelta\nHospedaje\nAsistencia Médica"
       },
       {
        "pasaporte": "Brasil",
        "origen": "Todos",
        "destino": "Sri Lanka",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.98",
        "requisitos": "Visado\nBoleto ida y vuelta\nHospedaje\nAsistencia Médica"
       },
       {
        "pasaporte": "Brasil",
        "origen": "Todos",
        "destino": "Tanzania",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.98",
        "requisitos": "Visado\nBoleto ida y vuelta\nHospedaje\nAsistencia Médica"
       },
       {
        "pasaporte": "Brasil",
        "origen": "Todos",
        "destino": "Timor Oriental",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.98",
        "requisitos": "Visado\nBoleto ida y vuelta\nHospedaje\nAsistencia Médica"
       },
       {
        "pasaporte": "Brasil",
        "origen": "Todos",
        "destino": "Tonga",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.98",
        "requisitos": "Visado\nBoleto ida y vuelta\nHospedaje\nAsistencia Médica"
       },
       {
        "pasaporte": "Brasil",
        "origen": "Todos",
        "destino": "Tuvalu",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.98",
        "requisitos": "Visado\nBoleto ida y vuelta\nHospedaje\nAsistencia Médica"
       },
       {
        "pasaporte": "Brasil",
        "origen": "Todos",
        "destino": "Zambia",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.98",
        "requisitos": "Visado\nBoleto ida y vuelta\nHospedaje\nAsistencia Médica"
       },
       {
        "pasaporte": "Brasil",
        "origen": "Todos",
        "destino": "Zimbabue",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.98",
        "requisitos": "Visado\nBoleto ida y vuelta\nHospedaje\nAsistencia Médica"
       },
       {
        "pasaporte": "Brasil",
        "origen": "Todos",
        "destino": "Corea del Sur",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.99",
        "requisitos": "Autorización ETA\nPasaporte vigente"
       },
       {
        "pasaporte": "Brasil",
        "origen": "Todos",
        "destino": "Kenia",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.99",
        "requisitos": "Autorización ETA\nPasaporte vigente"
       },
       {
        "pasaporte": "Brasil",
        "origen": "Todos",
        "destino": "Nueva Zelanda",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.99",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Brasil",
        "origen": "Todos",
        "destino": "Australia",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.95",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Brasil",
        "origen": "Todos",
        "destino": "Azerbaiyán",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.95",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Brasil",
        "origen": "Todos",
        "destino": "Benín",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.95",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Brasil",
        "origen": "Todos",
        "destino": "Birmania",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.95",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Brasil",
        "origen": "Todos",
        "destino": "Bután",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.95",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Brasil",
        "origen": "Todos",
        "destino": "Camerún",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.95",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Brasil",
        "origen": "Todos",
        "destino": "Costa de Marfil",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.95",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Brasil",
        "origen": "Todos",
        "destino": "Gabón",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.95",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Brasil",
        "origen": "Todos",
        "destino": "Guinea",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.95",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Brasil",
        "origen": "Todos",
        "destino": "Guinea Ecuatorial",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.95",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Brasil",
        "origen": "Todos",
        "destino": "India",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.95",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Brasil",
        "origen": "Todos",
        "destino": "Lesoto",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.95",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Brasil",
        "origen": "Todos",
        "destino": "Libia",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.95",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Brasil",
        "origen": "Todos",
        "destino": "Nigeria",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.95",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Brasil",
        "origen": "Todos",
        "destino": "Pakistán",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.95",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Brasil",
        "origen": "Todos",
        "destino": "Papua Nueva Guinea",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.95",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Brasil",
        "origen": "Todos",
        "destino": "República Democrática del Congo",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.95",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Brasil",
        "origen": "Todos",
        "destino": "Siria",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.95",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Brasil",
        "origen": "Todos",
        "destino": "Sudán del Sur",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.95",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Brasil",
        "origen": "Todos",
        "destino": "Togo",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.95",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Brasil",
        "origen": "Todos",
        "destino": "Uganda",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.95",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Brasil",
        "origen": "Todos",
        "destino": "Vietnam",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.95",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Brasil",
        "origen": "Todos",
        "destino": "Afganistán",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.93",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Brasil",
        "origen": "Todos",
        "destino": "Arabia Saudí",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.93",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Brasil",
        "origen": "Todos",
        "destino": "Argelia",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.93",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Brasil",
        "origen": "Todos",
        "destino": "Bangladesh",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.93",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Brasil",
        "origen": "Todos",
        "destino": "Brunei",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.93",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Brasil",
        "origen": "Todos",
        "destino": "Canadá",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.93",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Brasil",
        "origen": "Todos",
        "destino": "Chad",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.93",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Brasil",
        "origen": "Todos",
        "destino": "China",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.93",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Brasil",
        "origen": "Todos",
        "destino": "Corea del Norte",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.93",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Brasil",
        "origen": "Todos",
        "destino": "Cuba",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.93",
        "requisitos": "Visado\nPasaporte vigente\nAsistencia Médica de Viaje\nReserva de vuelo\nReserva de hospedaje\nSolvencia económica"
       },
       {
        "pasaporte": "Brasil",
        "origen": "Todos",
        "destino": "Eritrea",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.93",
        "requisitos": ""
       },
       {
        "pasaporte": "Brasil",
        "origen": "Todos",
        "destino": "Estados Unidos de América",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.93",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Brasil",
        "origen": "Todos",
        "destino": "Gambia",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.93",
        "requisitos": ""
       },
       {
        "pasaporte": "Brasil",
        "origen": "Todos",
        "destino": "Ghana",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.93",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Brasil",
        "origen": "Todos",
        "destino": "Guam",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.93",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Brasil",
        "origen": "Todos",
        "destino": "Guayana Francesa",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.93",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Brasil",
        "origen": "Todos",
        "destino": "Irak",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.93",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Brasil",
        "origen": "Todos",
        "destino": "Isla Norfolk",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.93",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Brasil",
        "origen": "Todos",
        "destino": "Islas Marianas del Norte",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.93",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Brasil",
        "origen": "Todos",
        "destino": "Islas Vírgenes americanas",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.93",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Brasil",
        "origen": "Todos",
        "destino": "Kuwait",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.93",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Brasil",
        "origen": "Todos",
        "destino": "Liberia",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.93",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Brasil",
        "origen": "Todos",
        "destino": "Mali",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.93",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Brasil",
        "origen": "Todos",
        "destino": "México",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.93",
        "requisitos": "Visado\nPasaporte vigente\nAsistencia Médica de Viaje\nReserva de vuelo\nReserva de hospedaje\nSolvencia económica"
       },
       {
        "pasaporte": "Brasil",
        "origen": "Todos",
        "destino": "Nauru",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.93",
        "requisitos": ""
       },
       {
        "pasaporte": "Brasil",
        "origen": "Todos",
        "destino": "Níger",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.93",
        "requisitos": ""
       },
       {
        "pasaporte": "Brasil",
        "origen": "Todos",
        "destino": "Puerto Rico",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.93",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Brasil",
        "origen": "Todos",
        "destino": "República Centroafricana",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.93",
        "requisitos": ""
       },
       {
        "pasaporte": "Brasil",
        "origen": "Todos",
        "destino": "República del Congo",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.93",
        "requisitos": ""
       },
       {
        "pasaporte": "Brasil",
        "origen": "Todos",
        "destino": "Samoa Americana",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.93",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Brasil",
        "origen": "Todos",
        "destino": "Somalia",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.93",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Brasil",
        "origen": "Todos",
        "destino": "Sudán",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.93",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Brasil",
        "origen": "Todos",
        "destino": "Taiwán",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.93",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Brasil",
        "origen": "Todos",
        "destino": "Turkmenistán",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.93",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Brasil",
        "origen": "Todos",
        "destino": "Yemen",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.93",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Chile",
        "origen": "Todos",
        "destino": "Albania",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Chile",
        "origen": "Todos",
        "destino": "Alemania",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Chile",
        "origen": "Todos",
        "destino": "Andorra",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Chile",
        "origen": "Todos",
        "destino": "Angola",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Chile",
        "origen": "Todos",
        "destino": "Anguilla",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Chile",
        "origen": "Todos",
        "destino": "Antigua y Barbuda",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Chile",
        "origen": "Todos",
        "destino": "Argentina",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Chile",
        "origen": "Todos",
        "destino": "Aruba",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Chile",
        "origen": "Todos",
        "destino": "Austria",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Chile",
        "origen": "Todos",
        "destino": "Bahamas",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Chile",
        "origen": "Todos",
        "destino": "Barbados",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Chile",
        "origen": "Todos",
        "destino": "Bélgica",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Chile",
        "origen": "Todos",
        "destino": "Belice",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Chile",
        "origen": "Todos",
        "destino": "Bermuda",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Chile",
        "origen": "Todos",
        "destino": "Bielorrusia",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Chile",
        "origen": "Todos",
        "destino": "Bolivia",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Chile",
        "origen": "Todos",
        "destino": "Bonaire, San Eustaquio y Saba",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Chile",
        "origen": "Todos",
        "destino": "Bosnia-Herzegovina",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Chile",
        "origen": "Todos",
        "destino": "Botswana",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Chile",
        "origen": "Todos",
        "destino": "Brasil",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Chile",
        "origen": "Todos",
        "destino": "Bulgaria",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Chile",
        "origen": "Todos",
        "destino": "Chipre",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Chile",
        "origen": "Todos",
        "destino": "Ciudad del Vaticano",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Chile",
        "origen": "Todos",
        "destino": "Colombia",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Chile",
        "origen": "Todos",
        "destino": "Costa Rica",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Chile",
        "origen": "Todos",
        "destino": "Croacia",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Chile",
        "origen": "Todos",
        "destino": "Curaçao",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Chile",
        "origen": "Todos",
        "destino": "Dinamarca",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Chile",
        "origen": "Todos",
        "destino": "Dominica",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Chile",
        "origen": "Todos",
        "destino": "Ecuador",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Chile",
        "origen": "Todos",
        "destino": "El Salvador",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Chile",
        "origen": "Todos",
        "destino": "Emiratos Árabes Unidos",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Chile",
        "origen": "Todos",
        "destino": "Eslovaquia",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Chile",
        "origen": "Todos",
        "destino": "Eslovenia",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Chile",
        "origen": "Todos",
        "destino": "España",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Chile",
        "origen": "Todos",
        "destino": "Estonia",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Chile",
        "origen": "Todos",
        "destino": "Eswatini",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Chile",
        "origen": "Todos",
        "destino": "Falkland Islands",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Chile",
        "origen": "Todos",
        "destino": "Federación Rusa",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Chile",
        "origen": "Todos",
        "destino": "Fiji",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Chile",
        "origen": "Todos",
        "destino": "Filipinas",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Chile",
        "origen": "Todos",
        "destino": "Finlandia",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Chile",
        "origen": "Todos",
        "destino": "Francia",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Chile",
        "origen": "Todos",
        "destino": "Georgia",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Chile",
        "origen": "Todos",
        "destino": "Gibraltar",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Chile",
        "origen": "Todos",
        "destino": "Granada",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Chile",
        "origen": "Todos",
        "destino": "Grecia",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Chile",
        "origen": "Todos",
        "destino": "Groenlandia",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Chile",
        "origen": "Todos",
        "destino": "Guatemala",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Chile",
        "origen": "Todos",
        "destino": "Guyana",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Chile",
        "origen": "Todos",
        "destino": "Haití",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Chile",
        "origen": "Todos",
        "destino": "Honduras",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Chile",
        "origen": "Todos",
        "destino": "Hong Kong",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Chile",
        "origen": "Todos",
        "destino": "Hungría",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Chile",
        "origen": "Todos",
        "destino": "Indias Occidentales Francesas",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Chile",
        "origen": "Todos",
        "destino": "Irlanda",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Chile",
        "origen": "Todos",
        "destino": "Isla Mauricio",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Chile",
        "origen": "Todos",
        "destino": "Isla Reunión",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Chile",
        "origen": "Todos",
        "destino": "Islandia",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Chile",
        "origen": "Todos",
        "destino": "Islas Caimán",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Chile",
        "origen": "Todos",
        "destino": "Islas Cook",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Chile",
        "origen": "Todos",
        "destino": "Islas Feroe",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Chile",
        "origen": "Todos",
        "destino": "Islas Turcas y Caicos",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Chile",
        "origen": "Todos",
        "destino": "Islas Vírgenes Británicas",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Chile",
        "origen": "Todos",
        "destino": "Israel",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Chile",
        "origen": "Todos",
        "destino": "Italia",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Chile",
        "origen": "Todos",
        "destino": "Jamaica",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Chile",
        "origen": "Todos",
        "destino": "Japón",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Chile",
        "origen": "Todos",
        "destino": "Kazajistán",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Chile",
        "origen": "Todos",
        "destino": "Kirguistán",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Chile",
        "origen": "Todos",
        "destino": "Kiribati",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Chile",
        "origen": "Todos",
        "destino": "Kosovo",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Chile",
        "origen": "Todos",
        "destino": "Letonia",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Chile",
        "origen": "Todos",
        "destino": "Liechtenstein",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Chile",
        "origen": "Todos",
        "destino": "Lituania",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Chile",
        "origen": "Todos",
        "destino": "Luxemburgo",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Chile",
        "origen": "Todos",
        "destino": "Macao",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Chile",
        "origen": "Todos",
        "destino": "Macedonia del Norte",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Chile",
        "origen": "Todos",
        "destino": "Malasia",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Chile",
        "origen": "Todos",
        "destino": "Malta",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Chile",
        "origen": "Todos",
        "destino": "Marruecos",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Chile",
        "origen": "Todos",
        "destino": "Mayotte",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Chile",
        "origen": "Todos",
        "destino": "México",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Chile",
        "origen": "Todos",
        "destino": "Micronesia",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Chile",
        "origen": "Todos",
        "destino": "Moldavia",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Chile",
        "origen": "Todos",
        "destino": "Mónaco",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Chile",
        "origen": "Todos",
        "destino": "Mongolia",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Chile",
        "origen": "Todos",
        "destino": "Montenegro",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Chile",
        "origen": "Todos",
        "destino": "Montserrat",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Chile",
        "origen": "Todos",
        "destino": "Nicaragua",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Chile",
        "origen": "Todos",
        "destino": "Noruega",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Chile",
        "origen": "Todos",
        "destino": "Nueva Caledonia",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Chile",
        "origen": "Todos",
        "destino": "Países Bajos",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Chile",
        "origen": "Todos",
        "destino": "Panamá",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Chile",
        "origen": "Todos",
        "destino": "Paraguay",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Chile",
        "origen": "Todos",
        "destino": "Perú",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Chile",
        "origen": "Todos",
        "destino": "Polinesia Francesa",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Chile",
        "origen": "Todos",
        "destino": "Polonia",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Chile",
        "origen": "Todos",
        "destino": "Portugal",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Chile",
        "origen": "Todos",
        "destino": "Reino Unido",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Chile",
        "origen": "Todos",
        "destino": "República Checa",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Chile",
        "origen": "Todos",
        "destino": "República Dominicana",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Chile",
        "origen": "Todos",
        "destino": "Rumanía",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Chile",
        "origen": "Todos",
        "destino": "San Cristóbal y Nieves",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Chile",
        "origen": "Todos",
        "destino": "San Marino",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Chile",
        "origen": "Todos",
        "destino": "San Martín",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Chile",
        "origen": "Todos",
        "destino": "San Pedro y Miquelón",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Chile",
        "origen": "Todos",
        "destino": "San Vicente y las Granadinas",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Chile",
        "origen": "Todos",
        "destino": "Santa Elena",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Chile",
        "origen": "Todos",
        "destino": "Santa Lucía",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Chile",
        "origen": "Todos",
        "destino": "Serbia",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Chile",
        "origen": "Todos",
        "destino": "Singapur",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Chile",
        "origen": "Todos",
        "destino": "Sudáfrica",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Chile",
        "origen": "Todos",
        "destino": "Suecia",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Chile",
        "origen": "Todos",
        "destino": "Suiza",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Chile",
        "origen": "Todos",
        "destino": "Surinam",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Chile",
        "origen": "Todos",
        "destino": "Tailandia",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Chile",
        "origen": "Todos",
        "destino": "Taiwán",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Chile",
        "origen": "Todos",
        "destino": "Tajikistán",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Chile",
        "origen": "Todos",
        "destino": "Territorios Palestinos",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Chile",
        "origen": "Todos",
        "destino": "Trinidad y Tobago",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Chile",
        "origen": "Todos",
        "destino": "Túnez",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Chile",
        "origen": "Todos",
        "destino": "Turquía",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Chile",
        "origen": "Todos",
        "destino": "Ucrania",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Chile",
        "origen": "Todos",
        "destino": "Uruguay",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Chile",
        "origen": "Todos",
        "destino": "Uzbekistán",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Chile",
        "origen": "Todos",
        "destino": "Vanuatu",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Chile",
        "origen": "Todos",
        "destino": "Vietnam",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Chile",
        "origen": "Todos",
        "destino": "Wallis y Futuna",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Chile",
        "origen": "Todos",
        "destino": "Armenia",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.98",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Chile",
        "origen": "Todos",
        "destino": "Bahrain",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.98",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Chile",
        "origen": "Todos",
        "destino": "Bangladesh",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.98",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Chile",
        "origen": "Todos",
        "destino": "Burundi",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.98",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Chile",
        "origen": "Todos",
        "destino": "Cabo Verde",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.98",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Chile",
        "origen": "Todos",
        "destino": "Camboya",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.98",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Chile",
        "origen": "Todos",
        "destino": "Comoras",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.98",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Chile",
        "origen": "Todos",
        "destino": "Djibouti",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.98",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Chile",
        "origen": "Todos",
        "destino": "Egipto",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.98",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Chile",
        "origen": "Todos",
        "destino": "Guinea-Bissau",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.98",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Chile",
        "origen": "Todos",
        "destino": "Indonesia",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.98",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Chile",
        "origen": "Todos",
        "destino": "Islas Marshall",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.98",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Chile",
        "origen": "Todos",
        "destino": "Islas Salomón",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.98",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Chile",
        "origen": "Todos",
        "destino": "Islas Seychelles",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.98",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Chile",
        "origen": "Todos",
        "destino": "Jordania",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.98",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Chile",
        "origen": "Todos",
        "destino": "Laos",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.98",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Chile",
        "origen": "Todos",
        "destino": "Líbano",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.98",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Chile",
        "origen": "Todos",
        "destino": "Madagascar",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.98",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Chile",
        "origen": "Todos",
        "destino": "Malawi",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.98",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Chile",
        "origen": "Todos",
        "destino": "Maldivas",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.98",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Chile",
        "origen": "Todos",
        "destino": "Mauritania",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.98",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Chile",
        "origen": "Todos",
        "destino": "Mozambique",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.98",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Chile",
        "origen": "Todos",
        "destino": "Namibia",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.98",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Chile",
        "origen": "Todos",
        "destino": "Nepal",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.98",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Chile",
        "origen": "Todos",
        "destino": "Niue",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.98",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Chile",
        "origen": "Todos",
        "destino": "Omán",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.98",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Chile",
        "origen": "Todos",
        "destino": "Palaos",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.98",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Chile",
        "origen": "Todos",
        "destino": "Qatar",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.98",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Chile",
        "origen": "Todos",
        "destino": "Ruanda",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.98",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Chile",
        "origen": "Todos",
        "destino": "Samoa",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.98",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Chile",
        "origen": "Todos",
        "destino": "Sri Lanka",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.98",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Chile",
        "origen": "Todos",
        "destino": "Tanzania",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.98",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Chile",
        "origen": "Todos",
        "destino": "Timor Oriental",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.98",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Chile",
        "origen": "Todos",
        "destino": "Tuvalu",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.98",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Chile",
        "origen": "Todos",
        "destino": "Zambia",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.98",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Chile",
        "origen": "Todos",
        "destino": "Zimbabue",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.98",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Chile",
        "origen": "Todos",
        "destino": "Canadá",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.98",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Chile",
        "origen": "Todos",
        "destino": "Corea del Sur",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.98",
        "requisitos": "Autorización ETA\nPasaporte vigente"
       },
       {
        "pasaporte": "Chile",
        "origen": "Todos",
        "destino": "Estados Unidos de América",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.98",
        "requisitos": "Autorización ETA\nPasaporte vigente"
       },
       {
        "pasaporte": "Chile",
        "origen": "Todos",
        "destino": "Guam",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.98",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Chile",
        "origen": "Todos",
        "destino": "Islas Marianas del Norte",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.98",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Chile",
        "origen": "Todos",
        "destino": "Islas Vírgenes americanas",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.98",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Chile",
        "origen": "Todos",
        "destino": "Kenia",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.98",
        "requisitos": "Autorización ETA\nPasaporte vigente"
       },
       {
        "pasaporte": "Chile",
        "origen": "Todos",
        "destino": "Nueva Zelanda",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.98",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Chile",
        "origen": "Todos",
        "destino": "Puerto Rico",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.98",
        "requisitos": "Autorización ETA\nPasaporte vigente"
       },
       {
        "pasaporte": "Chile",
        "origen": "Todos",
        "destino": "Australia",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.95",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Chile",
        "origen": "Todos",
        "destino": "Azerbaiyán",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.95",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Chile",
        "origen": "Todos",
        "destino": "Benín",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.95",
        "requisitos": "Visado\nBoleto ida y vuelta\nHospedaje\nAsistencia Médica"
       },
       {
        "pasaporte": "Chile",
        "origen": "Todos",
        "destino": "Birmania",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.95",
        "requisitos": "Visado\nBoleto ida y vuelta\nHospedaje\nAsistencia Médica"
       },
       {
        "pasaporte": "Chile",
        "origen": "Todos",
        "destino": "Burkina Faso",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.95",
        "requisitos": "Visado\nBoleto ida y vuelta\nHospedaje\nAsistencia Médica"
       },
       {
        "pasaporte": "Chile",
        "origen": "Todos",
        "destino": "Bután",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.95",
        "requisitos": "Visado\nBoleto ida y vuelta\nHospedaje\nAsistencia Médica"
       },
       {
        "pasaporte": "Chile",
        "origen": "Todos",
        "destino": "Camerún",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.95",
        "requisitos": "Visado\nBoleto ida y vuelta\nHospedaje\nAsistencia Médica"
       },
       {
        "pasaporte": "Chile",
        "origen": "Todos",
        "destino": "Costa de Marfil",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.95",
        "requisitos": "Visado\nBoleto ida y vuelta\nHospedaje\nAsistencia Médica"
       },
       {
        "pasaporte": "Chile",
        "origen": "Todos",
        "destino": "Etiopia",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.95",
        "requisitos": "Visado\nBoleto ida y vuelta\nHospedaje\nAsistencia Médica"
       },
       {
        "pasaporte": "Chile",
        "origen": "Todos",
        "destino": "Gabón",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.95",
        "requisitos": "Visado\nBoleto ida y vuelta\nHospedaje\nAsistencia Médica"
       },
       {
        "pasaporte": "Chile",
        "origen": "Todos",
        "destino": "Guinea",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.95",
        "requisitos": "Visado\nBoleto ida y vuelta\nHospedaje\nAsistencia Médica"
       },
       {
        "pasaporte": "Chile",
        "origen": "Todos",
        "destino": "Guinea Ecuatorial",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.95",
        "requisitos": "Visado\nBoleto ida y vuelta\nHospedaje\nAsistencia Médica"
       },
       {
        "pasaporte": "Chile",
        "origen": "Todos",
        "destino": "India",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.95",
        "requisitos": "Visado\nBoleto ida y vuelta\nHospedaje\nAsistencia Médica"
       },
       {
        "pasaporte": "Chile",
        "origen": "Todos",
        "destino": "Irán",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.95",
        "requisitos": "Visado\nBoleto ida y vuelta\nHospedaje\nAsistencia Médica"
       },
       {
        "pasaporte": "Chile",
        "origen": "Todos",
        "destino": "Isla Norfolk",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.95",
        "requisitos": "Visado\nBoleto ida y vuelta\nHospedaje\nAsistencia Médica"
       },
       {
        "pasaporte": "Chile",
        "origen": "Todos",
        "destino": "Lesoto",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.95",
        "requisitos": "Visado\nBoleto ida y vuelta\nHospedaje\nAsistencia Médica"
       },
       {
        "pasaporte": "Chile",
        "origen": "Todos",
        "destino": "Libia",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.95",
        "requisitos": "Visado\nBoleto ida y vuelta\nHospedaje\nAsistencia Médica"
       },
       {
        "pasaporte": "Chile",
        "origen": "Todos",
        "destino": "Nigeria",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.95",
        "requisitos": "Visado\nBoleto ida y vuelta\nHospedaje\nAsistencia Médica"
       },
       {
        "pasaporte": "Chile",
        "origen": "Todos",
        "destino": "Pakistán",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.95",
        "requisitos": "Visado\nBoleto ida y vuelta\nHospedaje\nAsistencia Médica"
       },
       {
        "pasaporte": "Chile",
        "origen": "Todos",
        "destino": "Papua Nueva Guinea",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.95",
        "requisitos": "Visado\nBoleto ida y vuelta\nHospedaje\nAsistencia Médica"
       },
       {
        "pasaporte": "Chile",
        "origen": "Todos",
        "destino": "República Democrática del Congo",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.95",
        "requisitos": "Visado\nBoleto ida y vuelta\nHospedaje\nAsistencia Médica"
       },
       {
        "pasaporte": "Chile",
        "origen": "Todos",
        "destino": "Santo Tomé y Príncipe",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.95",
        "requisitos": "Visado\nBoleto ida y vuelta\nHospedaje\nAsistencia Médica"
       },
       {
        "pasaporte": "Chile",
        "origen": "Todos",
        "destino": "Sierra Leona",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.95",
        "requisitos": "Visado\nBoleto ida y vuelta\nHospedaje\nAsistencia Médica"
       },
       {
        "pasaporte": "Chile",
        "origen": "Todos",
        "destino": "Siria",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.95",
        "requisitos": "Visado\nBoleto ida y vuelta\nHospedaje\nAsistencia Médica"
       },
       {
        "pasaporte": "Chile",
        "origen": "Todos",
        "destino": "Sudán del Sur",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.95",
        "requisitos": "Visado\nBoleto ida y vuelta\nHospedaje\nAsistencia Médica"
       },
       {
        "pasaporte": "Chile",
        "origen": "Todos",
        "destino": "Togo",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.95",
        "requisitos": "Visado\nBoleto ida y vuelta\nHospedaje\nAsistencia Médica"
       },
       {
        "pasaporte": "Chile",
        "origen": "Todos",
        "destino": "Uganda",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.95",
        "requisitos": "Visado\nBoleto ida y vuelta\nHospedaje\nAsistencia Médica"
       },
       {
        "pasaporte": "Chile",
        "origen": "Todos",
        "destino": "Afganistán",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.93",
        "requisitos": "Visado\nBoleto ida y vuelta\nHospedaje\nAsistencia Médica"
       },
       {
        "pasaporte": "Chile",
        "origen": "Todos",
        "destino": "Arabia Saudí",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.93",
        "requisitos": "Visado\nBoleto ida y vuelta\nHospedaje\nAsistencia Médica"
       },
       {
        "pasaporte": "Chile",
        "origen": "Todos",
        "destino": "Argelia",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.93",
        "requisitos": "Visado\nBoleto ida y vuelta\nHospedaje\nAsistencia Médica"
       },
       {
        "pasaporte": "Chile",
        "origen": "Todos",
        "destino": "Brunei",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.93",
        "requisitos": "Visado\nBoleto ida y vuelta\nHospedaje\nAsistencia Médica"
       },
       {
        "pasaporte": "Chile",
        "origen": "Todos",
        "destino": "Chad",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.93",
        "requisitos": "Visado\nBoleto ida y vuelta\nHospedaje\nAsistencia Médica"
       },
       {
        "pasaporte": "Chile",
        "origen": "Todos",
        "destino": "China",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.93",
        "requisitos": "Visado\nBoleto ida y vuelta\nHospedaje\nAsistencia Médica"
       },
       {
        "pasaporte": "Chile",
        "origen": "Todos",
        "destino": "Corea del Norte",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.93",
        "requisitos": "Visado\nBoleto ida y vuelta\nHospedaje\nAsistencia Médica"
       },
       {
        "pasaporte": "Chile",
        "origen": "Todos",
        "destino": "Cuba",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.93",
        "requisitos": "Visado\nBoleto ida y vuelta\nHospedaje\nAsistencia Médica"
       },
       {
        "pasaporte": "Chile",
        "origen": "Todos",
        "destino": "Eritrea",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.93",
        "requisitos": "Visado\nBoleto ida y vuelta\nHospedaje\nAsistencia Médica"
       },
       {
        "pasaporte": "Chile",
        "origen": "Todos",
        "destino": "Gambia",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.93",
        "requisitos": "Visado\nBoleto ida y vuelta\nHospedaje\nAsistencia Médica"
       },
       {
        "pasaporte": "Chile",
        "origen": "Todos",
        "destino": "Ghana",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.93",
        "requisitos": "Visado\nBoleto ida y vuelta\nHospedaje\nAsistencia Médica"
       },
       {
        "pasaporte": "Chile",
        "origen": "Todos",
        "destino": "Irak",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.93",
        "requisitos": "Visado\nBoleto ida y vuelta\nHospedaje\nAsistencia Médica"
       },
       {
        "pasaporte": "Chile",
        "origen": "Todos",
        "destino": "Kuwait",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.93",
        "requisitos": "Visado\nBoleto ida y vuelta\nHospedaje\nAsistencia Médica"
       },
       {
        "pasaporte": "Chile",
        "origen": "Todos",
        "destino": "Liberia",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.93",
        "requisitos": "Visado\nBoleto ida y vuelta\nHospedaje\nAsistencia Médica"
       },
       {
        "pasaporte": "Chile",
        "origen": "Todos",
        "destino": "Mali",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.93",
        "requisitos": "Visado\nBoleto ida y vuelta\nHospedaje\nAsistencia Médica"
       },
       {
        "pasaporte": "Chile",
        "origen": "Todos",
        "destino": "Nauru",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.93",
        "requisitos": "Visado\nBoleto ida y vuelta\nHospedaje\nAsistencia Médica"
       },
       {
        "pasaporte": "Chile",
        "origen": "Todos",
        "destino": "Níger",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.93",
        "requisitos": "Visado\nBoleto ida y vuelta\nHospedaje\nAsistencia Médica"
       },
       {
        "pasaporte": "Chile",
        "origen": "Todos",
        "destino": "República Centroafricana",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.93",
        "requisitos": "Visado\nBoleto ida y vuelta\nHospedaje\nAsistencia Médica"
       },
       {
        "pasaporte": "Chile",
        "origen": "Todos",
        "destino": "República del Congo",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.93",
        "requisitos": "Visado\nBoleto ida y vuelta\nHospedaje\nAsistencia Médica"
       },
       {
        "pasaporte": "Chile",
        "origen": "Todos",
        "destino": "Senegal",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.93",
        "requisitos": "Visado\nBoleto ida y vuelta\nHospedaje\nAsistencia Médica"
       },
       {
        "pasaporte": "Chile",
        "origen": "Todos",
        "destino": "Somalia",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.93",
        "requisitos": "Visado\nBoleto ida y vuelta\nHospedaje\nAsistencia Médica"
       },
       {
        "pasaporte": "Chile",
        "origen": "Todos",
        "destino": "Sudán",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.93",
        "requisitos": "Visado\nBoleto ida y vuelta\nHospedaje\nAsistencia Médica"
       },
       {
        "pasaporte": "Chile",
        "origen": "Todos",
        "destino": "Tonga",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.93",
        "requisitos": "Visado\nBoleto ida y vuelta\nHospedaje\nAsistencia Médica"
       },
       {
        "pasaporte": "Chile",
        "origen": "Todos",
        "destino": "Turkmenistán",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.93",
        "requisitos": "Visado\nBoleto ida y vuelta\nHospedaje\nAsistencia Médica"
       },
       {
        "pasaporte": "Chile",
        "origen": "Todos",
        "destino": "Venezuela",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.93",
        "requisitos": "Visado\nBoleto ida y vuelta\nHospedaje\nAsistencia Médica"
       },
       {
        "pasaporte": "Chile",
        "origen": "Todos",
        "destino": "Yemen",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.93",
        "requisitos": "Visado\nBoleto ida y vuelta\nHospedaje\nAsistencia Médica"
       },
       {
        "pasaporte": "Paraguay",
        "origen": "Todos",
        "destino": "Albania",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Paraguay",
        "origen": "Todos",
        "destino": "Alemania",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Paraguay",
        "origen": "Todos",
        "destino": "Andorra",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Paraguay",
        "origen": "Todos",
        "destino": "Anguilla",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Paraguay",
        "origen": "Todos",
        "destino": "Argentina",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Paraguay",
        "origen": "Todos",
        "destino": "Aruba",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Paraguay",
        "origen": "Todos",
        "destino": "Austria",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Paraguay",
        "origen": "Todos",
        "destino": "Bahamas",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Paraguay",
        "origen": "Todos",
        "destino": "Barbados",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Paraguay",
        "origen": "Todos",
        "destino": "Bélgica",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Paraguay",
        "origen": "Todos",
        "destino": "Belice",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Paraguay",
        "origen": "Todos",
        "destino": "Bermuda",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Paraguay",
        "origen": "Todos",
        "destino": "Bolivia",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Paraguay",
        "origen": "Todos",
        "destino": "Bonaire, San Eustaquio y Saba",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Paraguay",
        "origen": "Todos",
        "destino": "Bosnia-Herzegovina",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Paraguay",
        "origen": "Todos",
        "destino": "Botswana",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Paraguay",
        "origen": "Todos",
        "destino": "Brasil",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Paraguay",
        "origen": "Todos",
        "destino": "Bulgaria",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Paraguay",
        "origen": "Todos",
        "destino": "Chile",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Paraguay",
        "origen": "Todos",
        "destino": "Chipre",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Paraguay",
        "origen": "Todos",
        "destino": "Ciudad del Vaticano",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Paraguay",
        "origen": "Todos",
        "destino": "Colombia",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Paraguay",
        "origen": "Todos",
        "destino": "Costa Rica",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Paraguay",
        "origen": "Todos",
        "destino": "Croacia",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Paraguay",
        "origen": "Todos",
        "destino": "Curaçao",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Paraguay",
        "origen": "Todos",
        "destino": "Dinamarca",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Paraguay",
        "origen": "Todos",
        "destino": "Dominica",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Paraguay",
        "origen": "Todos",
        "destino": "Ecuador",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Paraguay",
        "origen": "Todos",
        "destino": "El Salvador",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Paraguay",
        "origen": "Todos",
        "destino": "Emiratos Árabes Unidos",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Paraguay",
        "origen": "Todos",
        "destino": "Eslovaquia",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Paraguay",
        "origen": "Todos",
        "destino": "Eslovenia",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Paraguay",
        "origen": "Todos",
        "destino": "España",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Paraguay",
        "origen": "Todos",
        "destino": "Estonia",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Paraguay",
        "origen": "Todos",
        "destino": "Federación Rusa",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Paraguay",
        "origen": "Todos",
        "destino": "Fiji",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Paraguay",
        "origen": "Todos",
        "destino": "Filipinas",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Paraguay",
        "origen": "Todos",
        "destino": "Finlandia",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Paraguay",
        "origen": "Todos",
        "destino": "Francia",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Paraguay",
        "origen": "Todos",
        "destino": "Georgia",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Paraguay",
        "origen": "Todos",
        "destino": "Gibraltar",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Paraguay",
        "origen": "Todos",
        "destino": "Grecia",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Paraguay",
        "origen": "Todos",
        "destino": "Groenlandia",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Paraguay",
        "origen": "Todos",
        "destino": "Guatemala",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Paraguay",
        "origen": "Todos",
        "destino": "Guayana Francesa",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Paraguay",
        "origen": "Todos",
        "destino": "Haití",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Paraguay",
        "origen": "Todos",
        "destino": "Honduras",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Paraguay",
        "origen": "Todos",
        "destino": "Hong Kong",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Paraguay",
        "origen": "Todos",
        "destino": "Hungría",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Paraguay",
        "origen": "Todos",
        "destino": "Indias Occidentales Francesas",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Paraguay",
        "origen": "Todos",
        "destino": "Irlanda",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Paraguay",
        "origen": "Todos",
        "destino": "Isla Mauricio",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "Paraguay",
        "origen": "Todos",
        "destino": "Isla Reunión",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "Paraguay",
        "origen": "Todos",
        "destino": "Islandia",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "Paraguay",
        "origen": "Todos",
        "destino": "Islas Cook",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "Paraguay",
        "origen": "Todos",
        "destino": "Islas Feroe",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "Paraguay",
        "origen": "Todos",
        "destino": "Islas Vírgenes Británicas",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "Paraguay",
        "origen": "Todos",
        "destino": "Israel",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Paraguay",
        "origen": "Todos",
        "destino": "Italia",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Paraguay",
        "origen": "Todos",
        "destino": "Jamaica",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Paraguay",
        "origen": "Todos",
        "destino": "Kiribati",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Paraguay",
        "origen": "Todos",
        "destino": "Kosovo",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Paraguay",
        "origen": "Todos",
        "destino": "Letonia",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Paraguay",
        "origen": "Todos",
        "destino": "Liechtenstein",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Paraguay",
        "origen": "Todos",
        "destino": "Lituania",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Paraguay",
        "origen": "Todos",
        "destino": "Luxemburgo",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Paraguay",
        "origen": "Todos",
        "destino": "Macedonia del Norte",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Paraguay",
        "origen": "Todos",
        "destino": "Malasia",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Paraguay",
        "origen": "Todos",
        "destino": "Malta",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Paraguay",
        "origen": "Todos",
        "destino": "Mayotte",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Paraguay",
        "origen": "Todos",
        "destino": "México",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Paraguay",
        "origen": "Todos",
        "destino": "Micronesia",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Paraguay",
        "origen": "Todos",
        "destino": "Moldavia",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Paraguay",
        "origen": "Todos",
        "destino": "Mónaco",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Paraguay",
        "origen": "Todos",
        "destino": "Mongolia",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Paraguay",
        "origen": "Todos",
        "destino": "Montenegro",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Paraguay",
        "origen": "Todos",
        "destino": "Montserrat",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Paraguay",
        "origen": "Todos",
        "destino": "Nicaragua",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Paraguay",
        "origen": "Todos",
        "destino": "Noruega",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Paraguay",
        "origen": "Todos",
        "destino": "Nueva Caledonia",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Paraguay",
        "origen": "Todos",
        "destino": "Países Bajos",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Paraguay",
        "origen": "Todos",
        "destino": "Panamá",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Paraguay",
        "origen": "Todos",
        "destino": "Perú",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Paraguay",
        "origen": "Todos",
        "destino": "Polinesia Francesa",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Paraguay",
        "origen": "Todos",
        "destino": "Polonia",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Paraguay",
        "origen": "Todos",
        "destino": "Portugal",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Paraguay",
        "origen": "Todos",
        "destino": "Reino Unido",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Paraguay",
        "origen": "Todos",
        "destino": "República Checa",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Paraguay",
        "origen": "Todos",
        "destino": "República Dominicana",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Paraguay",
        "origen": "Todos",
        "destino": "Rumanía",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Paraguay",
        "origen": "Todos",
        "destino": "San Cristóbal y Nieves",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Paraguay",
        "origen": "Todos",
        "destino": "San Marino",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Paraguay",
        "origen": "Todos",
        "destino": "San Martín",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Paraguay",
        "origen": "Todos",
        "destino": "San Pedro y Miquelón",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Paraguay",
        "origen": "Todos",
        "destino": "San Vicente y las Granadinas",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Paraguay",
        "origen": "Todos",
        "destino": "Santa Elena",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Paraguay",
        "origen": "Todos",
        "destino": "Serbia",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Paraguay",
        "origen": "Todos",
        "destino": "Singapur",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Paraguay",
        "origen": "Todos",
        "destino": "Sudáfrica",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Paraguay",
        "origen": "Todos",
        "destino": "Suecia",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Paraguay",
        "origen": "Todos",
        "destino": "Suiza",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "Paraguay",
        "origen": "Todos",
        "destino": "Surinam",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "Paraguay",
        "origen": "Todos",
        "destino": "Taiwán",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "Paraguay",
        "origen": "Todos",
        "destino": "Territorios Palestinos",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "Paraguay",
        "origen": "Todos",
        "destino": "Turquía",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "Paraguay",
        "origen": "Todos",
        "destino": "Ucrania",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "Paraguay",
        "origen": "Todos",
        "destino": "Uruguay",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "Paraguay",
        "origen": "Todos",
        "destino": "Venezuela",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "Paraguay",
        "origen": "Todos",
        "destino": "Wallis y Futuna",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "Paraguay",
        "origen": "Todos",
        "destino": "Bahréin",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.97",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Paraguay",
        "origen": "Todos",
        "destino": "Bangladesh",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.97",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Paraguay",
        "origen": "Todos",
        "destino": "Burkina Faso",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.97",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Paraguay",
        "origen": "Todos",
        "destino": "Burundi",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.97",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Paraguay",
        "origen": "Todos",
        "destino": "Cabo Verde",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.97",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Paraguay",
        "origen": "Todos",
        "destino": "Camboya",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.97",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Paraguay",
        "origen": "Todos",
        "destino": "Comoras",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.97",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Paraguay",
        "origen": "Todos",
        "destino": "Djibouti",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.97",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Paraguay",
        "origen": "Todos",
        "destino": "Egipto",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.97",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Paraguay",
        "origen": "Todos",
        "destino": "Guinea-Bissau",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.97",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Paraguay",
        "origen": "Todos",
        "destino": "Islas Seychelles",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.97",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Paraguay",
        "origen": "Todos",
        "destino": "Jordania",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.97",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Paraguay",
        "origen": "Todos",
        "destino": "Laos",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.97",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Paraguay",
        "origen": "Todos",
        "destino": "Líbano",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.97",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Paraguay",
        "origen": "Todos",
        "destino": "Macao",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.97",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Paraguay",
        "origen": "Todos",
        "destino": "Madagascar",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.97",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Paraguay",
        "origen": "Todos",
        "destino": "Malawi",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.97",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Paraguay",
        "origen": "Todos",
        "destino": "Maldivas",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.97",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Paraguay",
        "origen": "Todos",
        "destino": "Mauritania",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.97",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Paraguay",
        "origen": "Todos",
        "destino": "Mozambique",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.97",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Paraguay",
        "origen": "Todos",
        "destino": "Namibia",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.97",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Paraguay",
        "origen": "Todos",
        "destino": "Nepal",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.97",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Paraguay",
        "origen": "Todos",
        "destino": "Niue",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.97",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Paraguay",
        "origen": "Todos",
        "destino": "Omán",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.97",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Paraguay",
        "origen": "Todos",
        "destino": "Palaos",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.97",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Paraguay",
        "origen": "Todos",
        "destino": "Qatar",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.97",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Paraguay",
        "origen": "Todos",
        "destino": "Ruanda",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.97",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Paraguay",
        "origen": "Todos",
        "destino": "Samoa",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.97",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Paraguay",
        "origen": "Todos",
        "destino": "Santa Lucía",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.97",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Paraguay",
        "origen": "Todos",
        "destino": "Sri Lanka",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.97",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Paraguay",
        "origen": "Todos",
        "destino": "Tailandia",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.97",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Paraguay",
        "origen": "Todos",
        "destino": "Tanzania",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.97",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Paraguay",
        "origen": "Todos",
        "destino": "Timor Oriental",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.97",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Paraguay",
        "origen": "Todos",
        "destino": "Tuvalu",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.97",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Paraguay",
        "origen": "Todos",
        "destino": "Zambia",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.97",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Paraguay",
        "origen": "Todos",
        "destino": "Zimbabue",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.97",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Paraguay",
        "origen": "Todos",
        "destino": "Corea del Sur",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.97",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Paraguay",
        "origen": "Todos",
        "destino": "Kenia",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.97",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Paraguay",
        "origen": "Todos",
        "destino": "Pakistan",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.97",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Paraguay",
        "origen": "Todos",
        "destino": "Antigua y Barbuda",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.95",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Paraguay",
        "origen": "Todos",
        "destino": "Armenia",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.95",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Paraguay",
        "origen": "Todos",
        "destino": "Australia",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.95",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Paraguay",
        "origen": "Todos",
        "destino": "Azerbaiyán",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.95",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Paraguay",
        "origen": "Todos",
        "destino": "Benín",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.95",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Paraguay",
        "origen": "Todos",
        "destino": "Bután",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.95",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Paraguay",
        "origen": "Todos",
        "destino": "Camerún",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.95",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Paraguay",
        "origen": "Todos",
        "destino": "Costa de Marfil",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.95",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Paraguay",
        "origen": "Todos",
        "destino": "Etiopía",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.95",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Paraguay",
        "origen": "Todos",
        "destino": "Gabón",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.95",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Paraguay",
        "origen": "Todos",
        "destino": "Guinea",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.95",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Paraguay",
        "origen": "Todos",
        "destino": "Guinea Ecuatorial",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.95",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Paraguay",
        "origen": "Todos",
        "destino": "India",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.95",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Paraguay",
        "origen": "Todos",
        "destino": "Irán",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.95",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Paraguay",
        "origen": "Todos",
        "destino": "Kazajistán",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.95",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Paraguay",
        "origen": "Todos",
        "destino": "Kirguistán",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.95",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Paraguay",
        "origen": "Todos",
        "destino": "Lesoto",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.95",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Paraguay",
        "origen": "Todos",
        "destino": "Libia",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.95",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Paraguay",
        "origen": "Todos",
        "destino": "Nigeria",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.95",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Paraguay",
        "origen": "Todos",
        "destino": "República Democrática del Congo",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.95",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Paraguay",
        "origen": "Todos",
        "destino": "Santo Tomé y Príncipe",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.95",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Paraguay",
        "origen": "Todos",
        "destino": "Sierra Leona",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.95",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Paraguay",
        "origen": "Todos",
        "destino": "Siria",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.95",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Paraguay",
        "origen": "Todos",
        "destino": "Sudán del Sur",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.95",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Paraguay",
        "origen": "Todos",
        "destino": "Tayikistán",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.95",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Paraguay",
        "origen": "Todos",
        "destino": "Togo",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.95",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Paraguay",
        "origen": "Todos",
        "destino": "Uganda",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.95",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Paraguay",
        "origen": "Todos",
        "destino": "Uzbekistán",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.95",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Paraguay",
        "origen": "Todos",
        "destino": "Vietnam",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.95",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Paraguay",
        "origen": "Todos",
        "destino": "Afganistán",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.93",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Paraguay",
        "origen": "Todos",
        "destino": "Angola",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.93",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Paraguay",
        "origen": "Todos",
        "destino": "Arabia Saudí",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.93",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Paraguay",
        "origen": "Todos",
        "destino": "Argelia",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.93",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Paraguay",
        "origen": "Todos",
        "destino": "Bielorrusia",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.93",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Paraguay",
        "origen": "Todos",
        "destino": "Birmania",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.93",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Paraguay",
        "origen": "Todos",
        "destino": "Brunei",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.93",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Paraguay",
        "origen": "Todos",
        "destino": "Canadá",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.93",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Paraguay",
        "origen": "Todos",
        "destino": "Chad",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.93",
        "requisitos": "Visado\nBoleto ida y vuelta\nHospedaje\nAsistencia Médica"
       },
       {
        "pasaporte": "Paraguay",
        "origen": "Todos",
        "destino": "China",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.93",
        "requisitos": "Visado\nBoleto ida y vuelta\nHospedaje\nAsistencia Médica"
       },
       {
        "pasaporte": "Paraguay",
        "origen": "Todos",
        "destino": "Corea del Norte",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.93",
        "requisitos": "Visado\nBoleto ida y vuelta\nHospedaje\nAsistencia Médica"
       },
       {
        "pasaporte": "Paraguay",
        "origen": "Todos",
        "destino": "Cuba",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.93",
        "requisitos": "Visado\nBoleto ida y vuelta\nHospedaje\nAsistencia Médica"
       },
       {
        "pasaporte": "Paraguay",
        "origen": "Todos",
        "destino": "Eritrea",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.93",
        "requisitos": "Visado\nBoleto ida y vuelta\nHospedaje\nAsistencia Médica"
       },
       {
        "pasaporte": "Paraguay",
        "origen": "Todos",
        "destino": "Estados Unidos de América",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.93",
        "requisitos": "Visado\nBoleto ida y vuelta\nHospedaje\nAsistencia Médica"
       },
       {
        "pasaporte": "Paraguay",
        "origen": "Todos",
        "destino": "Eswatini",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.93",
        "requisitos": "Visado\nBoleto ida y vuelta\nHospedaje\nAsistencia Médica"
       },
       {
        "pasaporte": "Paraguay",
        "origen": "Todos",
        "destino": "Falkland Islands",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.93",
        "requisitos": "Visado\nBoleto ida y vuelta\nHospedaje\nAsistencia Médica"
       },
       {
        "pasaporte": "Paraguay",
        "origen": "Todos",
        "destino": "Gambia",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.93",
        "requisitos": "Visado\nBoleto ida y vuelta\nHospedaje\nAsistencia Médica"
       },
       {
        "pasaporte": "Paraguay",
        "origen": "Todos",
        "destino": "Ghana",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.93",
        "requisitos": "Visado\nBoleto ida y vuelta\nHospedaje\nAsistencia Médica"
       },
       {
        "pasaporte": "Paraguay",
        "origen": "Todos",
        "destino": "Granada",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.93",
        "requisitos": "Visado\nBoleto ida y vuelta\nHospedaje\nAsistencia Médica"
       },
       {
        "pasaporte": "Paraguay",
        "origen": "Todos",
        "destino": "Guam",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.93",
        "requisitos": "Visado\nBoleto ida y vuelta\nHospedaje\nAsistencia Médica"
       },
       {
        "pasaporte": "Paraguay",
        "origen": "Todos",
        "destino": "Guyana",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.93",
        "requisitos": "Visado\nBoleto ida y vuelta\nHospedaje\nAsistencia Médica"
       },
       {
        "pasaporte": "Paraguay",
        "origen": "Todos",
        "destino": "Indonesia",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.93",
        "requisitos": "Visado\nBoleto ida y vuelta\nHospedaje\nAsistencia Médica"
       },
       {
        "pasaporte": "Paraguay",
        "origen": "Todos",
        "destino": "Irak",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.93",
        "requisitos": "Visado\nBoleto ida y vuelta\nHospedaje\nAsistencia Médica"
       },
       {
        "pasaporte": "Paraguay",
        "origen": "Todos",
        "destino": "Isla Norfolk",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.93",
        "requisitos": "Visado\nBoleto ida y vuelta\nHospedaje\nAsistencia Médica"
       },
       {
        "pasaporte": "Paraguay",
        "origen": "Todos",
        "destino": "Islas Caimán",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.93",
        "requisitos": "Visado\nBoleto ida y vuelta\nHospedaje\nAsistencia Médica"
       },
       {
        "pasaporte": "Paraguay",
        "origen": "Todos",
        "destino": "Islas Marianas del Norte",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.93",
        "requisitos": "Visado\nBoleto ida y vuelta\nHospedaje\nAsistencia Médica"
       },
       {
        "pasaporte": "Paraguay",
        "origen": "Todos",
        "destino": "Islas Marshall",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.93",
        "requisitos": "Visado\nBoleto ida y vuelta\nHospedaje\nAsistencia Médica"
       },
       {
        "pasaporte": "Paraguay",
        "origen": "Todos",
        "destino": "Islas Salomón",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.93",
        "requisitos": "Visado\nBoleto ida y vuelta\nHospedaje\nAsistencia Médica"
       },
       {
        "pasaporte": "Paraguay",
        "origen": "Todos",
        "destino": "Islas Turcas y Caicos",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.93",
        "requisitos": "Visado\nBoleto ida y vuelta\nHospedaje\nAsistencia Médica"
       },
       {
        "pasaporte": "Paraguay",
        "origen": "Todos",
        "destino": "Islas Vírgenes americanas",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.93",
        "requisitos": "Visado\nBoleto ida y vuelta\nHospedaje\nAsistencia Médica"
       },
       {
        "pasaporte": "Paraguay",
        "origen": "Todos",
        "destino": "Japón",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.93",
        "requisitos": "Visado\nBoleto ida y vuelta\nHospedaje\nAsistencia Médica"
       },
       {
        "pasaporte": "Paraguay",
        "origen": "Todos",
        "destino": "Kuwait",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.93",
        "requisitos": "Visado\nBoleto ida y vuelta\nHospedaje\nAsistencia Médica"
       },
       {
        "pasaporte": "Paraguay",
        "origen": "Todos",
        "destino": "Liberia",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.93",
        "requisitos": "Visado\nBoleto ida y vuelta\nHospedaje\nAsistencia Médica"
       },
       {
        "pasaporte": "Paraguay",
        "origen": "Todos",
        "destino": "Mali",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.93",
        "requisitos": "Visado\nBoleto ida y vuelta\nHospedaje\nAsistencia Médica"
       },
       {
        "pasaporte": "Paraguay",
        "origen": "Todos",
        "destino": "Marruecos",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.93",
        "requisitos": "Visado\nBoleto ida y vuelta\nHospedaje\nAsistencia Médica"
       },
       {
        "pasaporte": "Paraguay",
        "origen": "Todos",
        "destino": "Nauru",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.93",
        "requisitos": "Visado\nBoleto ida y vuelta\nHospedaje\nAsistencia Médica"
       },
       {
        "pasaporte": "Paraguay",
        "origen": "Todos",
        "destino": "Níger",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.93",
        "requisitos": "Visado\nBoleto ida y vuelta\nHospedaje\nAsistencia Médica"
       },
       {
        "pasaporte": "Paraguay",
        "origen": "Todos",
        "destino": "Nueva Zelanda",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.93",
        "requisitos": "Visado\nBoleto ida y vuelta\nHospedaje\nAsistencia Médica"
       },
       {
        "pasaporte": "Paraguay",
        "origen": "Todos",
        "destino": "Papua Nueva Guinea",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.93",
        "requisitos": "Visado\nBoleto ida y vuelta\nHospedaje\nAsistencia Médica"
       },
       {
        "pasaporte": "Paraguay",
        "origen": "Todos",
        "destino": "Puerto Rico",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.93",
        "requisitos": "Visado\nBoleto ida y vuelta\nHospedaje\nAsistencia Médica"
       },
       {
        "pasaporte": "Paraguay",
        "origen": "Todos",
        "destino": "República Centroafricana",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.93",
        "requisitos": "Visado\nBoleto ida y vuelta\nHospedaje\nAsistencia Médica"
       },
       {
        "pasaporte": "Paraguay",
        "origen": "Todos",
        "destino": "República del Congo",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.93",
        "requisitos": "Visado\nBoleto ida y vuelta\nHospedaje\nAsistencia Médica"
       },
       {
        "pasaporte": "Paraguay",
        "origen": "Todos",
        "destino": "Samoa Americana",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.93",
        "requisitos": "Visado\nBoleto ida y vuelta\nHospedaje\nAsistencia Médica"
       },
       {
        "pasaporte": "Paraguay",
        "origen": "Todos",
        "destino": "Senegal",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.93",
        "requisitos": "Visado\nBoleto ida y vuelta\nHospedaje\nAsistencia Médica"
       },
       {
        "pasaporte": "Paraguay",
        "origen": "Todos",
        "destino": "Somalia",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.93",
        "requisitos": "Visado\nBoleto ida y vuelta\nHospedaje\nAsistencia Médica"
       },
       {
        "pasaporte": "Paraguay",
        "origen": "Todos",
        "destino": "Sudán",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.93",
        "requisitos": "Visado\nBoleto ida y vuelta\nHospedaje\nAsistencia Médica"
       },
       {
        "pasaporte": "Paraguay",
        "origen": "Todos",
        "destino": "Tonga",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.93",
        "requisitos": "Visado\nBoleto ida y vuelta\nHospedaje\nAsistencia Médica"
       },
       {
        "pasaporte": "Paraguay",
        "origen": "Todos",
        "destino": "Trinidad y Tobago",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.93",
        "requisitos": "Visado\nBoleto ida y vuelta\nHospedaje\nAsistencia Médica"
       },
       {
        "pasaporte": "Paraguay",
        "origen": "Todos",
        "destino": "Túnez",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.93",
        "requisitos": "Visado\nBoleto ida y vuelta\nHospedaje\nAsistencia Médica"
       },
       {
        "pasaporte": "Paraguay",
        "origen": "Todos",
        "destino": "Turkmenistán",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.93",
        "requisitos": "Visado\nBoleto ida y vuelta\nHospedaje\nAsistencia Médica"
       },
       {
        "pasaporte": "Paraguay",
        "origen": "Todos",
        "destino": "Vanuatu",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.93",
        "requisitos": "Visado\nBoleto ida y vuelta\nHospedaje\nAsistencia Médica"
       },
       {
        "pasaporte": "Paraguay",
        "origen": "Todos",
        "destino": "Yemen",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.93",
        "requisitos": "Visado\nBoleto ida y vuelta\nHospedaje\nAsistencia Médica"
       },
       {
        "pasaporte": "Uruguay",
        "origen": "Todos",
        "destino": "Albania",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Uruguay",
        "origen": "Todos",
        "destino": "Alemania",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Uruguay",
        "origen": "Todos",
        "destino": "Andorra",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Uruguay",
        "origen": "Todos",
        "destino": "Angola",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Uruguay",
        "origen": "Todos",
        "destino": "Anguilla",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Uruguay",
        "origen": "Todos",
        "destino": "Argentina",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Uruguay",
        "origen": "Todos",
        "destino": "Armenia",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Uruguay",
        "origen": "Todos",
        "destino": "Aruba",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Uruguay",
        "origen": "Todos",
        "destino": "Austria",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Uruguay",
        "origen": "Todos",
        "destino": "Bahamas",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Uruguay",
        "origen": "Todos",
        "destino": "Barbados",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Uruguay",
        "origen": "Todos",
        "destino": "Bélgica",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Uruguay",
        "origen": "Todos",
        "destino": "Belice",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Uruguay",
        "origen": "Todos",
        "destino": "Bermuda",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Uruguay",
        "origen": "Todos",
        "destino": "Bielorrusia",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Uruguay",
        "origen": "Todos",
        "destino": "Bolivia",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Uruguay",
        "origen": "Todos",
        "destino": "Bonaire, San Eustaquio y Saba",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Uruguay",
        "origen": "Todos",
        "destino": "Bosnia-Herzegovina",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Uruguay",
        "origen": "Todos",
        "destino": "Botswana",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Uruguay",
        "origen": "Todos",
        "destino": "Brasil",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Uruguay",
        "origen": "Todos",
        "destino": "Bulgaria",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Uruguay",
        "origen": "Todos",
        "destino": "Chile",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Uruguay",
        "origen": "Todos",
        "destino": "Chipre",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Uruguay",
        "origen": "Todos",
        "destino": "Ciudad del Vaticano",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Uruguay",
        "origen": "Todos",
        "destino": "Colombia",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Uruguay",
        "origen": "Todos",
        "destino": "Costa Rica",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Uruguay",
        "origen": "Todos",
        "destino": "Croacia",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Uruguay",
        "origen": "Todos",
        "destino": "Curaçao",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Uruguay",
        "origen": "Todos",
        "destino": "Dinamarca",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Uruguay",
        "origen": "Todos",
        "destino": "Dominica",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Uruguay",
        "origen": "Todos",
        "destino": "Ecuador",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Uruguay",
        "origen": "Todos",
        "destino": "El Salvador",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Uruguay",
        "origen": "Todos",
        "destino": "Emiratos Árabes Unidos",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Uruguay",
        "origen": "Todos",
        "destino": "Eslovaquia",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Uruguay",
        "origen": "Todos",
        "destino": "Eslovenia",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Uruguay",
        "origen": "Todos",
        "destino": "España",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Uruguay",
        "origen": "Todos",
        "destino": "Estonia",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Uruguay",
        "origen": "Todos",
        "destino": "Eswatini",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Uruguay",
        "origen": "Todos",
        "destino": "Falkland Islands",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Uruguay",
        "origen": "Todos",
        "destino": "Federación Rusa",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Uruguay",
        "origen": "Todos",
        "destino": "Fiji",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Uruguay",
        "origen": "Todos",
        "destino": "Filipinas",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Uruguay",
        "origen": "Todos",
        "destino": "Finlandia",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Uruguay",
        "origen": "Todos",
        "destino": "Francia",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Uruguay",
        "origen": "Todos",
        "destino": "Georgia",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Uruguay",
        "origen": "Todos",
        "destino": "Gibraltar",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Uruguay",
        "origen": "Todos",
        "destino": "Granada",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Uruguay",
        "origen": "Todos",
        "destino": "Grecia",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Uruguay",
        "origen": "Todos",
        "destino": "Groenlandia",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Uruguay",
        "origen": "Todos",
        "destino": "Guatemala",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Uruguay",
        "origen": "Todos",
        "destino": "Guayana Francesa",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Uruguay",
        "origen": "Todos",
        "destino": "Guyana",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Uruguay",
        "origen": "Todos",
        "destino": "Haití",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Uruguay",
        "origen": "Todos",
        "destino": "Honduras",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Uruguay",
        "origen": "Todos",
        "destino": "Hong Kong",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Uruguay",
        "origen": "Todos",
        "destino": "Hungría",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Uruguay",
        "origen": "Todos",
        "destino": "Indias Occidentales Francesas",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Uruguay",
        "origen": "Todos",
        "destino": "Irlanda",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Uruguay",
        "origen": "Todos",
        "destino": "Isla Reunión",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "Uruguay",
        "origen": "Todos",
        "destino": "Islandia",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "Uruguay",
        "origen": "Todos",
        "destino": "Islas Cook",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "Uruguay",
        "origen": "Todos",
        "destino": "Islas Feroe",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "Uruguay",
        "origen": "Todos",
        "destino": "Islas Vírgenes Británicas",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "Uruguay",
        "origen": "Todos",
        "destino": "Israel",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Uruguay",
        "origen": "Todos",
        "destino": "Italia",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Uruguay",
        "origen": "Todos",
        "destino": "Jamaica",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Uruguay",
        "origen": "Todos",
        "destino": "Japón",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Uruguay",
        "origen": "Todos",
        "destino": "Kiribati",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Uruguay",
        "origen": "Todos",
        "destino": "Kosovo",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Uruguay",
        "origen": "Todos",
        "destino": "Letonia",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Uruguay",
        "origen": "Todos",
        "destino": "Liechtenstein",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Uruguay",
        "origen": "Todos",
        "destino": "Lituania",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Uruguay",
        "origen": "Todos",
        "destino": "Luxemburgo",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Uruguay",
        "origen": "Todos",
        "destino": "Macao",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Uruguay",
        "origen": "Todos",
        "destino": "Macedonia del Norte",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Uruguay",
        "origen": "Todos",
        "destino": "Malasia",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Uruguay",
        "origen": "Todos",
        "destino": "Malta",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Uruguay",
        "origen": "Todos",
        "destino": "Mayotte",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Uruguay",
        "origen": "Todos",
        "destino": "México",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Uruguay",
        "origen": "Todos",
        "destino": "Micronesia",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Uruguay",
        "origen": "Todos",
        "destino": "Moldavia",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Uruguay",
        "origen": "Todos",
        "destino": "Mónaco",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Uruguay",
        "origen": "Todos",
        "destino": "Mongolia",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Uruguay",
        "origen": "Todos",
        "destino": "Montenegro",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Uruguay",
        "origen": "Todos",
        "destino": "Montserrat",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Uruguay",
        "origen": "Todos",
        "destino": "Nicaragua",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Uruguay",
        "origen": "Todos",
        "destino": "Noruega",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Uruguay",
        "origen": "Todos",
        "destino": "Nueva Caledonia",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Uruguay",
        "origen": "Todos",
        "destino": "Países Bajos",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Uruguay",
        "origen": "Todos",
        "destino": "Panamá",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Uruguay",
        "origen": "Todos",
        "destino": "Paraguay",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Uruguay",
        "origen": "Todos",
        "destino": "Perú",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Uruguay",
        "origen": "Todos",
        "destino": "Polinesia Francesa",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Uruguay",
        "origen": "Todos",
        "destino": "Polonia",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Uruguay",
        "origen": "Todos",
        "destino": "Portugal",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Uruguay",
        "origen": "Todos",
        "destino": "Reino Unido",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Uruguay",
        "origen": "Todos",
        "destino": "República Checa",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Uruguay",
        "origen": "Todos",
        "destino": "República Dominicana",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Uruguay",
        "origen": "Todos",
        "destino": "Rumanía",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Uruguay",
        "origen": "Todos",
        "destino": "San Cristóbal y Nieves",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Uruguay",
        "origen": "Todos",
        "destino": "San Marino",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Uruguay",
        "origen": "Todos",
        "destino": "San Martín",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Uruguay",
        "origen": "Todos",
        "destino": "San Pedro y Miquelón",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Uruguay",
        "origen": "Todos",
        "destino": "San Vicente y las Granadinas",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Uruguay",
        "origen": "Todos",
        "destino": "Santa Elena",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Uruguay",
        "origen": "Todos",
        "destino": "Santa Lucía",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Uruguay",
        "origen": "Todos",
        "destino": "Serbia",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Uruguay",
        "origen": "Todos",
        "destino": "Singapur",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Uruguay",
        "origen": "Todos",
        "destino": "Sudáfrica",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Uruguay",
        "origen": "Todos",
        "destino": "Suecia",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Uruguay",
        "origen": "Todos",
        "destino": "Suiza",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Uruguay",
        "origen": "Todos",
        "destino": "Surinam",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "Uruguay",
        "origen": "Todos",
        "destino": "Tailandia",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "Uruguay",
        "origen": "Todos",
        "destino": "Territorios Palestinos",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "Uruguay",
        "origen": "Todos",
        "destino": "Trinidad y Tobago",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "Uruguay",
        "origen": "Todos",
        "destino": "Turquía",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "Uruguay",
        "origen": "Todos",
        "destino": "Ucrania",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "Uruguay",
        "origen": "Todos",
        "destino": "Vanuatu",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "Uruguay",
        "origen": "Todos",
        "destino": "Venezuela",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "Uruguay",
        "origen": "Todos",
        "destino": "Wallis y Futuna",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "Uruguay",
        "origen": "Todos",
        "destino": "Bahréin",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.98",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Uruguay",
        "origen": "Todos",
        "destino": "Bangladesh",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.98",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Uruguay",
        "origen": "Todos",
        "destino": "Burundi",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.98",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Uruguay",
        "origen": "Todos",
        "destino": "Cabo Verde",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.98",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Uruguay",
        "origen": "Todos",
        "destino": "Camboya",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.98",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Uruguay",
        "origen": "Todos",
        "destino": "Comoras",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.98",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Uruguay",
        "origen": "Todos",
        "destino": "Djibouti",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.98",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Uruguay",
        "origen": "Todos",
        "destino": "Egipto",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.98",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Uruguay",
        "origen": "Todos",
        "destino": "Guinea-Bissau",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.98",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Uruguay",
        "origen": "Todos",
        "destino": "Isla Mauricio",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.98",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Uruguay",
        "origen": "Todos",
        "destino": "Islas Marshall",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.98",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Uruguay",
        "origen": "Todos",
        "destino": "Islas Seychelles",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.98",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Uruguay",
        "origen": "Todos",
        "destino": "Jordania",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.98",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Uruguay",
        "origen": "Todos",
        "destino": "Laos",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.98",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Uruguay",
        "origen": "Todos",
        "destino": "Líbano",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.98",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Uruguay",
        "origen": "Todos",
        "destino": "Madagascar",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.98",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Uruguay",
        "origen": "Todos",
        "destino": "Malawi",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.98",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Uruguay",
        "origen": "Todos",
        "destino": "Maldivas",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.98",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Uruguay",
        "origen": "Todos",
        "destino": "Mauritania",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.98",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Uruguay",
        "origen": "Todos",
        "destino": "Mozambique",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.98",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Uruguay",
        "origen": "Todos",
        "destino": "Namibia",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.98",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Uruguay",
        "origen": "Todos",
        "destino": "Nepal",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.98",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Uruguay",
        "origen": "Todos",
        "destino": "Niue",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.98",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Uruguay",
        "origen": "Todos",
        "destino": "Omán",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.98",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Uruguay",
        "origen": "Todos",
        "destino": "Palaos",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.98",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Uruguay",
        "origen": "Todos",
        "destino": "Qatar",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.98",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Uruguay",
        "origen": "Todos",
        "destino": "Ruanda",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.98",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Uruguay",
        "origen": "Todos",
        "destino": "Samoa",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.98",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Uruguay",
        "origen": "Todos",
        "destino": "Sri Lanka",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.98",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Uruguay",
        "origen": "Todos",
        "destino": "Tanzania",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.98",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Uruguay",
        "origen": "Todos",
        "destino": "Timor Oriental",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.98",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Uruguay",
        "origen": "Todos",
        "destino": "Tuvalu",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.98",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Uruguay",
        "origen": "Todos",
        "destino": "Zambia",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.98",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Uruguay",
        "origen": "Todos",
        "destino": "Zimbabue",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.98",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Uruguay",
        "origen": "Todos",
        "destino": "Corea del Sur",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.98",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Uruguay",
        "origen": "Todos",
        "destino": "Kenia",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.98",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Uruguay",
        "origen": "Todos",
        "destino": "Nueva Zelanda",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.98",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Uruguay",
        "origen": "Todos",
        "destino": "Antigua y Barbuda",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.96",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Uruguay",
        "origen": "Todos",
        "destino": "Australia",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.96",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Uruguay",
        "origen": "Todos",
        "destino": "Benín",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.96",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Uruguay",
        "origen": "Todos",
        "destino": "Birmania",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.96",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Uruguay",
        "origen": "Todos",
        "destino": "Burkina Faso",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.96",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Uruguay",
        "origen": "Todos",
        "destino": "Bután",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.96",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Uruguay",
        "origen": "Todos",
        "destino": "Camerún",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.96",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Uruguay",
        "origen": "Todos",
        "destino": "Costa de Marfil",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.96",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Uruguay",
        "origen": "Todos",
        "destino": "Etiopía",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.96",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Uruguay",
        "origen": "Todos",
        "destino": "Gabón",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.96",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Uruguay",
        "origen": "Todos",
        "destino": "Guinea",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.96",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Uruguay",
        "origen": "Todos",
        "destino": "Guinea Ecuatorial",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.96",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Uruguay",
        "origen": "Todos",
        "destino": "India",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.96",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Uruguay",
        "origen": "Todos",
        "destino": "Irán",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.96",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Uruguay",
        "origen": "Todos",
        "destino": "Isla Norfolk",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.96",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Uruguay",
        "origen": "Todos",
        "destino": "Kazajistán",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.96",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Uruguay",
        "origen": "Todos",
        "destino": "Kirguistán",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.96",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Uruguay",
        "origen": "Todos",
        "destino": "Lesoto",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.96",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Uruguay",
        "origen": "Todos",
        "destino": "Libia",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.96",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Uruguay",
        "origen": "Todos",
        "destino": "Nigeria",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.96",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Uruguay",
        "origen": "Todos",
        "destino": "Pakistán",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.96",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Uruguay",
        "origen": "Todos",
        "destino": "Papua Nueva Guinea",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.96",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Uruguay",
        "origen": "Todos",
        "destino": "República Democrática del Congo",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.96",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Uruguay",
        "origen": "Todos",
        "destino": "Santo Tomé y Príncipe",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.96",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Uruguay",
        "origen": "Todos",
        "destino": "Sierra Leona",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.96",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Uruguay",
        "origen": "Todos",
        "destino": "Siria",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.96",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Uruguay",
        "origen": "Todos",
        "destino": "Sudán del Sur",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.96",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Uruguay",
        "origen": "Todos",
        "destino": "Tayikistán",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.96",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Uruguay",
        "origen": "Todos",
        "destino": "Togo",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.96",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Uruguay",
        "origen": "Todos",
        "destino": "Uganda",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.96",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Uruguay",
        "origen": "Todos",
        "destino": "Uzbekistán",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.96",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Uruguay",
        "origen": "Todos",
        "destino": "Vietnam",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.96",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Uruguay",
        "origen": "Todos",
        "destino": "Afganistán",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.92",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Uruguay",
        "origen": "Todos",
        "destino": "Arabia Saudí",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.92",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Uruguay",
        "origen": "Todos",
        "destino": "Argelia",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.92",
        "requisitos": ""
       },
       {
        "pasaporte": "Uruguay",
        "origen": "Todos",
        "destino": "Azerbaiyán",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.92",
        "requisitos": ""
       },
       {
        "pasaporte": "Uruguay",
        "origen": "Todos",
        "destino": "Brunei",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.92",
        "requisitos": ""
       },
       {
        "pasaporte": "Uruguay",
        "origen": "Todos",
        "destino": "Chad",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.92",
        "requisitos": ""
       },
       {
        "pasaporte": "Uruguay",
        "origen": "Todos",
        "destino": "China",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.92",
        "requisitos": ""
       },
       {
        "pasaporte": "Uruguay",
        "origen": "Todos",
        "destino": "Corea del Norte",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.92",
        "requisitos": ""
       },
       {
        "pasaporte": "Uruguay",
        "origen": "Todos",
        "destino": "Cuba",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.92",
        "requisitos": "Visado\nPasaporte vigente\nAsistencia Médica de Viaje\nReserva de vuelo\nReserva de hospedaje\nSolvencia económica"
       },
       {
        "pasaporte": "Uruguay",
        "origen": "Todos",
        "destino": "Eritrea",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.92",
        "requisitos": ""
       },
       {
        "pasaporte": "Uruguay",
        "origen": "Todos",
        "destino": "Estados Unidos de América",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.92",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Uruguay",
        "origen": "Todos",
        "destino": "Gambia",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.92",
        "requisitos": "Visado\nBoleto ida y vuelta\nHospedaje\nAsistencia Médica"
       },
       {
        "pasaporte": "Uruguay",
        "origen": "Todos",
        "destino": "Ghana",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.92",
        "requisitos": "Visado\nBoleto ida y vuelta\nHospedaje\nAsistencia Médica"
       },
       {
        "pasaporte": "Uruguay",
        "origen": "Todos",
        "destino": "Guam",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.92",
        "requisitos": "Visado\nBoleto ida y vuelta\nHospedaje\nAsistencia Médica"
       },
       {
        "pasaporte": "Uruguay",
        "origen": "Todos",
        "destino": "Indonesia",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.92",
        "requisitos": "Visado\nBoleto ida y vuelta\nHospedaje\nAsistencia Médica"
       },
       {
        "pasaporte": "Uruguay",
        "origen": "Todos",
        "destino": "Irak",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.92",
        "requisitos": "Visado\nBoleto ida y vuelta\nHospedaje\nAsistencia Médica"
       },
       {
        "pasaporte": "Uruguay",
        "origen": "Todos",
        "destino": "Islas Caimán",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.92",
        "requisitos": "Visado\nBoleto ida y vuelta\nHospedaje\nAsistencia Médica"
       },
       {
        "pasaporte": "Uruguay",
        "origen": "Todos",
        "destino": "Islas Marianas del Norte",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.92",
        "requisitos": "Visado\nBoleto ida y vuelta\nHospedaje\nAsistencia Médica"
       },
       {
        "pasaporte": "Uruguay",
        "origen": "Todos",
        "destino": "Islas Salomón",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.92",
        "requisitos": "Visado\nBoleto ida y vuelta\nHospedaje\nAsistencia Médica"
       },
       {
        "pasaporte": "Uruguay",
        "origen": "Todos",
        "destino": "Islas Turcas y Caicos",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.92",
        "requisitos": "Visado\nBoleto ida y vuelta\nHospedaje\nAsistencia Médica"
       },
       {
        "pasaporte": "Uruguay",
        "origen": "Todos",
        "destino": "Islas Vírgenes americanas",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.92",
        "requisitos": "Visado\nBoleto ida y vuelta\nHospedaje\nAsistencia Médica"
       },
       {
        "pasaporte": "Uruguay",
        "origen": "Todos",
        "destino": "Kuwait",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.92",
        "requisitos": "Visado\nBoleto ida y vuelta\nHospedaje\nAsistencia Médica"
       },
       {
        "pasaporte": "Uruguay",
        "origen": "Todos",
        "destino": "Liberia",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.92",
        "requisitos": "Visado\nBoleto ida y vuelta\nHospedaje\nAsistencia Médica"
       },
       {
        "pasaporte": "Uruguay",
        "origen": "Todos",
        "destino": "Mali",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.92",
        "requisitos": "Visado\nBoleto ida y vuelta\nHospedaje\nAsistencia Médica"
       },
       {
        "pasaporte": "Uruguay",
        "origen": "Todos",
        "destino": "Marruecos",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.92",
        "requisitos": "Visado\nBoleto ida y vuelta\nHospedaje\nAsistencia Médica"
       },
       {
        "pasaporte": "Uruguay",
        "origen": "Todos",
        "destino": "Nauru",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.92",
        "requisitos": "Visado\nBoleto ida y vuelta\nHospedaje\nAsistencia Médica"
       },
       {
        "pasaporte": "Uruguay",
        "origen": "Todos",
        "destino": "Níger",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.92",
        "requisitos": "Visado\nBoleto ida y vuelta\nHospedaje\nAsistencia Médica"
       },
       {
        "pasaporte": "Uruguay",
        "origen": "Todos",
        "destino": "Puerto Rico",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.92",
        "requisitos": "Visado\nBoleto ida y vuelta\nHospedaje\nAsistencia Médica"
       },
       {
        "pasaporte": "Uruguay",
        "origen": "Todos",
        "destino": "República Centroafricana",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.92",
        "requisitos": "Visado\nBoleto ida y vuelta\nHospedaje\nAsistencia Médica"
       },
       {
        "pasaporte": "Uruguay",
        "origen": "Todos",
        "destino": "República del Congo",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.92",
        "requisitos": "Visado\nBoleto ida y vuelta\nHospedaje\nAsistencia Médica"
       },
       {
        "pasaporte": "Uruguay",
        "origen": "Todos",
        "destino": "Samoa Americana",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.92",
        "requisitos": "Visado\nBoleto ida y vuelta\nHospedaje\nAsistencia Médica"
       },
       {
        "pasaporte": "Uruguay",
        "origen": "Todos",
        "destino": "Senegal",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.92",
        "requisitos": "Visado\nBoleto ida y vuelta\nHospedaje\nAsistencia Médica"
       },
       {
        "pasaporte": "Uruguay",
        "origen": "Todos",
        "destino": "Somalia",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.92",
        "requisitos": "Visado\nBoleto ida y vuelta\nHospedaje\nAsistencia Médica"
       },
       {
        "pasaporte": "Uruguay",
        "origen": "Todos",
        "destino": "Sudán",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.92",
        "requisitos": "Visado\nBoleto ida y vuelta\nHospedaje\nAsistencia Médica"
       },
       {
        "pasaporte": "Uruguay",
        "origen": "Todos",
        "destino": "Taiwán",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.92",
        "requisitos": "Visado\nBoleto ida y vuelta\nHospedaje\nAsistencia Médica"
       },
       {
        "pasaporte": "Uruguay",
        "origen": "Todos",
        "destino": "Tonga",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.92",
        "requisitos": "Visado\nBoleto ida y vuelta\nHospedaje\nAsistencia Médica"
       },
       {
        "pasaporte": "Uruguay",
        "origen": "Todos",
        "destino": "Túnez",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.92",
        "requisitos": "Visado\nBoleto ida y vuelta\nHospedaje\nAsistencia Médica"
       },
       {
        "pasaporte": "Uruguay",
        "origen": "Todos",
        "destino": "Turkmenistán",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.92",
        "requisitos": "Visado\nBoleto ida y vuelta\nHospedaje\nAsistencia Médica"
       },
       {
        "pasaporte": "Uruguay",
        "origen": "Todos",
        "destino": "Yemen",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.92",
        "requisitos": "Visado\nBoleto ida y vuelta\nHospedaje\nAsistencia Médica"
       },
       {
        "pasaporte": "Haití",
        "origen": "Todos",
        "destino": "Angola",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "Haití",
        "origen": "Todos",
        "destino": "Benín",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "Haití",
        "origen": "Todos",
        "destino": "Filipinas",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "Haití",
        "origen": "Todos",
        "destino": "Gambia",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "Haití",
        "origen": "Todos",
        "destino": "Granada",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "Haití",
        "origen": "Todos",
        "destino": "Hong Kong",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "Haití",
        "origen": "Todos",
        "destino": "Islas Cook",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "Haití",
        "origen": "Todos",
        "destino": "Israel",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "Haití",
        "origen": "Todos",
        "destino": "Kiribati",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "Haití",
        "origen": "Todos",
        "destino": "Malasia",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "Haití",
        "origen": "Todos",
        "destino": "Micronesia",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "Haití",
        "origen": "Todos",
        "destino": "Montserrat",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "Haití",
        "origen": "Todos",
        "destino": "Ruanda",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "Haití",
        "origen": "Todos",
        "destino": "Singapur",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "Haití",
        "origen": "Todos",
        "destino": "Taiwán",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "Haití",
        "origen": "Todos",
        "destino": "Territorios Palestinos",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Pasaporte vigente\nHospedaje\nBoleto ida y vuelta"
       },
       {
        "pasaporte": "Haití",
        "origen": "Todos",
        "destino": "Bangladesh",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado\nPasaporte vigente\nAsistencia Médica de Viaje\nReserva de vuelo\nReserva de hospedaje\nSolvencia económica"
       },
       {
        "pasaporte": "Haití",
        "origen": "Todos",
        "destino": "Bolivia",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado\nPasaporte vigente\nAsistencia Médica de Viaje\nReserva de vuelo\nReserva de hospedaje\nSolvencia económica"
       },
       {
        "pasaporte": "Haití",
        "origen": "Todos",
        "destino": "Burundi",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado\nPasaporte vigente\nAsistencia Médica de Viaje\nReserva de vuelo\nReserva de hospedaje\nSolvencia económica"
       },
       {
        "pasaporte": "Haití",
        "origen": "Todos",
        "destino": "Cabo Verde",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado\nPasaporte vigente\nAsistencia Médica de Viaje\nReserva de vuelo\nReserva de hospedaje\nSolvencia económica"
       },
       {
        "pasaporte": "Haití",
        "origen": "Todos",
        "destino": "Camboya",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado\nPasaporte vigente\nAsistencia Médica de Viaje\nReserva de vuelo\nReserva de hospedaje\nSolvencia económica"
       },
       {
        "pasaporte": "Haití",
        "origen": "Todos",
        "destino": "Comoras",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado\nPasaporte vigente\nAsistencia Médica de Viaje\nReserva de vuelo\nReserva de hospedaje\nSolvencia económica"
       },
       {
        "pasaporte": "Haití",
        "origen": "Todos",
        "destino": "Djibouti",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado\nPasaporte vigente\nAsistencia Médica de Viaje\nReserva de vuelo\nReserva de hospedaje\nSolvencia económica"
       },
       {
        "pasaporte": "Haití",
        "origen": "Todos",
        "destino": "Egipto",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado\nPasaporte vigente\nAsistencia Médica de Viaje\nReserva de vuelo\nReserva de hospedaje\nSolvencia económica"
       },
       {
        "pasaporte": "Haití",
        "origen": "Todos",
        "destino": "Guinea-Bissau",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado\nPasaporte vigente\nAsistencia Médica de Viaje\nReserva de vuelo\nReserva de hospedaje\nSolvencia económica"
       },
       {
        "pasaporte": "Haití",
        "origen": "Todos",
        "destino": "Isla Mauricio",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado\nPasaporte vigente\nAsistencia Médica de Viaje\nReserva de vuelo\nReserva de hospedaje\nSolvencia económica"
       },
       {
        "pasaporte": "Haití",
        "origen": "Todos",
        "destino": "Islas Seychelles",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado\nPasaporte vigente\nAsistencia Médica de Viaje\nReserva de vuelo\nReserva de hospedaje\nSolvencia económica"
       },
       {
        "pasaporte": "Haití",
        "origen": "Todos",
        "destino": "Jordania",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado\nPasaporte vigente\nAsistencia Médica de Viaje\nReserva de vuelo\nReserva de hospedaje\nSolvencia económica"
       },
       {
        "pasaporte": "Haití",
        "origen": "Todos",
        "destino": "Laos",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado\nPasaporte vigente\nAsistencia Médica de Viaje\nReserva de vuelo\nReserva de hospedaje\nSolvencia económica"
       },
       {
        "pasaporte": "Haití",
        "origen": "Todos",
        "destino": "Macao",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado\nPasaporte vigente\nAsistencia Médica de Viaje\nReserva de vuelo\nReserva de hospedaje\nSolvencia económica"
       },
       {
        "pasaporte": "Haití",
        "origen": "Todos",
        "destino": "Madagascar",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado\nPasaporte vigente\nAsistencia Médica de Viaje\nReserva de vuelo\nReserva de hospedaje\nSolvencia económica"
       },
       {
        "pasaporte": "Haití",
        "origen": "Todos",
        "destino": "Malawi",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado\nPasaporte vigente\nAsistencia Médica de Viaje\nReserva de vuelo\nReserva de hospedaje\nSolvencia económica"
       },
       {
        "pasaporte": "Haití",
        "origen": "Todos",
        "destino": "Maldivas",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado\nPasaporte vigente\nAsistencia Médica de Viaje\nReserva de vuelo\nReserva de hospedaje\nSolvencia económica"
       },
       {
        "pasaporte": "Haití",
        "origen": "Todos",
        "destino": "Mauritania",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado\nPasaporte vigente\nAsistencia Médica de Viaje\nReserva de vuelo\nReserva de hospedaje\nSolvencia económica"
       },
       {
        "pasaporte": "Haití",
        "origen": "Todos",
        "destino": "Mozambique",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado\nPasaporte vigente\nAsistencia Médica de Viaje\nReserva de vuelo\nReserva de hospedaje\nSolvencia económica"
       },
       {
        "pasaporte": "Haití",
        "origen": "Todos",
        "destino": "Nepal",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado\nPasaporte vigente\nAsistencia Médica de Viaje\nReserva de vuelo\nReserva de hospedaje\nSolvencia económica"
       },
       {
        "pasaporte": "Haití",
        "origen": "Todos",
        "destino": "Niue",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado\nPasaporte vigente\nAsistencia Médica de Viaje\nReserva de vuelo\nReserva de hospedaje\nSolvencia económica"
       },
       {
        "pasaporte": "Haití",
        "origen": "Todos",
        "destino": "Palaos",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado\nPasaporte vigente\nAsistencia Médica de Viaje\nReserva de vuelo\nReserva de hospedaje\nSolvencia económica"
       },
       {
        "pasaporte": "Haití",
        "origen": "Todos",
        "destino": "Samoa",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado\nPasaporte vigente\nAsistencia Médica de Viaje\nReserva de vuelo\nReserva de hospedaje\nSolvencia económica"
       },
       {
        "pasaporte": "Haití",
        "origen": "Todos",
        "destino": "Senegal",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado\nPasaporte vigente\nAsistencia Médica de Viaje\nReserva de vuelo\nReserva de hospedaje\nSolvencia económica"
       },
       {
        "pasaporte": "Haití",
        "origen": "Todos",
        "destino": "Sri Lanka",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado\nPasaporte vigente\nAsistencia Médica de Viaje\nReserva de vuelo\nReserva de hospedaje\nSolvencia económica"
       },
       {
        "pasaporte": "Haití",
        "origen": "Todos",
        "destino": "Tanzania",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado\nPasaporte vigente\nAsistencia Médica de Viaje\nReserva de vuelo\nReserva de hospedaje\nSolvencia económica"
       },
       {
        "pasaporte": "Haití",
        "origen": "Todos",
        "destino": "Timor Oriental",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado\nPasaporte vigente\nAsistencia Médica de Viaje\nReserva de vuelo\nReserva de hospedaje\nSolvencia económica"
       },
       {
        "pasaporte": "Haití",
        "origen": "Todos",
        "destino": "Turquía",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado\nPasaporte vigente\nAsistencia Médica de Viaje\nReserva de vuelo\nReserva de hospedaje\nSolvencia económica"
       },
       {
        "pasaporte": "Haití",
        "origen": "Todos",
        "destino": "Tuvalu",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado\nPasaporte vigente\nAsistencia Médica de Viaje\nReserva de vuelo\nReserva de hospedaje\nSolvencia económica"
       },
       {
        "pasaporte": "Haití",
        "origen": "Todos",
        "destino": "Zambia",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado\nPasaporte vigente\nAsistencia Médica de Viaje\nReserva de vuelo\nReserva de hospedaje\nSolvencia económica"
       },
       {
        "pasaporte": "Haití",
        "origen": "Todos",
        "destino": "Zimbabue",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado\nPasaporte vigente\nAsistencia Médica de Viaje\nReserva de vuelo\nReserva de hospedaje\nSolvencia económica"
       },
       {
        "pasaporte": "Haití",
        "origen": "Todos",
        "destino": "Corea del Dur",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado\nPasaporte vigente\nAsistencia Médica de Viaje\nReserva de vuelo\nReserva de hospedaje\nSolvencia económica"
       },
       {
        "pasaporte": "Haití",
        "origen": "Todos",
        "destino": "Kenia",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado\nPasaporte vigente\nAsistencia Médica de Viaje\nReserva de vuelo\nReserva de hospedaje\nSolvencia económica"
       },
       {
        "pasaporte": "Haití",
        "origen": "Todos",
        "destino": "Albania",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado\nPasaporte vigente\nAsistencia Médica de Viaje\nReserva de vuelo\nReserva de hospedaje\nSolvencia económica"
       },
       {
        "pasaporte": "Haití",
        "origen": "Todos",
        "destino": "Antigua y Barbuda",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado\nPasaporte vigente\nAsistencia Médica de Viaje\nReserva de vuelo\nReserva de hospedaje\nSolvencia económica"
       },
       {
        "pasaporte": "Haití",
        "origen": "Todos",
        "destino": "Armenia",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado\nPasaporte vigente\nAsistencia Médica de Viaje\nReserva de vuelo\nReserva de hospedaje\nSolvencia económica"
       },
       {
        "pasaporte": "Haití",
        "origen": "Todos",
        "destino": "Australia",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado\nPasaporte vigente\nAsistencia Médica de Viaje\nReserva de vuelo\nReserva de hospedaje\nSolvencia económica"
       },
       {
        "pasaporte": "Haití",
        "origen": "Todos",
        "destino": "Bahamas",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado\nPasaporte vigente\nAsistencia Médica de Viaje\nReserva de vuelo\nReserva de hospedaje\nSolvencia económica"
       },
       {
        "pasaporte": "Haití",
        "origen": "Todos",
        "destino": "Bahréin",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado\nPasaporte vigente\nAsistencia Médica de Viaje\nReserva de vuelo\nReserva de hospedaje\nSolvencia económica"
       },
       {
        "pasaporte": "Haití",
        "origen": "Todos",
        "destino": "Botsuana",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado\nPasaporte vigente\nAsistencia Médica de Viaje\nReserva de vuelo\nReserva de hospedaje\nSolvencia económica"
       },
       {
        "pasaporte": "Haití",
        "origen": "Todos",
        "destino": "Burkina Faso",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado\nPasaporte vigente\nAsistencia Médica de Viaje\nReserva de vuelo\nReserva de hospedaje\nSolvencia económica"
       },
       {
        "pasaporte": "Haití",
        "origen": "Todos",
        "destino": "Bután",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado\nPasaporte vigente\nAsistencia Médica de Viaje\nReserva de vuelo\nReserva de hospedaje\nSolvencia económica"
       },
       {
        "pasaporte": "Haití",
        "origen": "Todos",
        "destino": "Camerún",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado\nPasaporte vigente\nAsistencia Médica de Viaje\nReserva de vuelo\nReserva de hospedaje\nSolvencia económica"
       },
       {
        "pasaporte": "Haití",
        "origen": "Todos",
        "destino": "Colombia",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado\nPasaporte vigente\nAsistencia Médica de Viaje\nReserva de vuelo\nReserva de hospedaje\nSolvencia económica"
       },
       {
        "pasaporte": "Haití",
        "origen": "Todos",
        "destino": "Costa de Marfil",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado\nPasaporte vigente\nAsistencia Médica de Viaje\nReserva de vuelo\nReserva de hospedaje\nSolvencia económica"
       },
       {
        "pasaporte": "Haití",
        "origen": "Todos",
        "destino": "Ecuador",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado\nPasaporte vigente\nAsistencia Médica de Viaje\nReserva de vuelo\nReserva de hospedaje\nSolvencia económica"
       },
       {
        "pasaporte": "Haití",
        "origen": "Todos",
        "destino": "El Salvador",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado\nPasaporte vigente\nAsistencia Médica de Viaje\nReserva de vuelo\nReserva de hospedaje\nSolvencia económica"
       },
       {
        "pasaporte": "Haití",
        "origen": "Todos",
        "destino": "Emiratos Árabes Unidos",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado\nPasaporte vigente\nAsistencia Médica de Viaje\nReserva de vuelo\nReserva de hospedaje\nSolvencia económica"
       },
       {
        "pasaporte": "Haití",
        "origen": "Todos",
        "destino": "Etiopía",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado\nPasaporte vigente\nAsistencia Médica de Viaje\nReserva de vuelo\nReserva de hospedaje\nSolvencia económica"
       },
       {
        "pasaporte": "Haití",
        "origen": "Todos",
        "destino": "Gabón",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado\nPasaporte vigente\nAsistencia Médica de Viaje\nReserva de vuelo\nReserva de hospedaje\nSolvencia económica"
       },
       {
        "pasaporte": "Haití",
        "origen": "Todos",
        "destino": "Georgia",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado\nPasaporte vigente\nAsistencia Médica de Viaje\nReserva de vuelo\nReserva de hospedaje\nSolvencia económica"
       },
       {
        "pasaporte": "Haití",
        "origen": "Todos",
        "destino": "Guinea",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado\nPasaporte vigente\nAsistencia Médica de Viaje\nReserva de vuelo\nReserva de hospedaje\nSolvencia económica"
       },
       {
        "pasaporte": "Haití",
        "origen": "Todos",
        "destino": "Guinea Ecuatorial",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado\nPasaporte vigente\nAsistencia Médica de Viaje\nReserva de vuelo\nReserva de hospedaje\nSolvencia económica"
       },
       {
        "pasaporte": "Haití",
        "origen": "Todos",
        "destino": "India",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado\nPasaporte vigente\nAsistencia Médica de Viaje\nReserva de vuelo\nReserva de hospedaje\nSolvencia económica"
       },
       {
        "pasaporte": "Haití",
        "origen": "Todos",
        "destino": "Indonesia",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado\nPasaporte vigente\nAsistencia Médica de Viaje\nReserva de vuelo\nReserva de hospedaje\nSolvencia económica"
       },
       {
        "pasaporte": "Haití",
        "origen": "Todos",
        "destino": "Irán",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado\nPasaporte vigente\nAsistencia Médica de Viaje\nReserva de vuelo\nReserva de hospedaje\nSolvencia económica"
       },
       {
        "pasaporte": "Haití",
        "origen": "Todos",
        "destino": "Kazajistán",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado\nPasaporte vigente\nAsistencia Médica de Viaje\nReserva de vuelo\nReserva de hospedaje\nSolvencia económica"
       },
       {
        "pasaporte": "Haití",
        "origen": "Todos",
        "destino": "Kirguistán",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado\nPasaporte vigente\nAsistencia Médica de Viaje\nReserva de vuelo\nReserva de hospedaje\nSolvencia económica"
       },
       {
        "pasaporte": "Haití",
        "origen": "Todos",
        "destino": "Lesoto",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado\nPasaporte vigente\nAsistencia Médica de Viaje\nReserva de vuelo\nReserva de hospedaje\nSolvencia económica"
       },
       {
        "pasaporte": "Haití",
        "origen": "Todos",
        "destino": "Libia",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado\nPasaporte vigente\nAsistencia Médica de Viaje\nReserva de vuelo\nReserva de hospedaje\nSolvencia económica"
       },
       {
        "pasaporte": "Haití",
        "origen": "Todos",
        "destino": "Moldavia",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado\nPasaporte vigente\nAsistencia Médica de Viaje\nReserva de vuelo\nReserva de hospedaje\nSolvencia económica"
       },
       {
        "pasaporte": "Haití",
        "origen": "Todos",
        "destino": "Mongolia",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado\nPasaporte vigente\nAsistencia Médica de Viaje\nReserva de vuelo\nReserva de hospedaje\nSolvencia económica"
       },
       {
        "pasaporte": "Haití",
        "origen": "Todos",
        "destino": "Nigeria",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado\nPasaporte vigente\nAsistencia Médica de Viaje\nReserva de vuelo\nReserva de hospedaje\nSolvencia económica"
       },
       {
        "pasaporte": "Haití",
        "origen": "Todos",
        "destino": "Omán",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado\nPasaporte vigente\nAsistencia Médica de Viaje\nReserva de vuelo\nReserva de hospedaje\nSolvencia económica"
       },
       {
        "pasaporte": "Haití",
        "origen": "Todos",
        "destino": "Pakistán",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado\nPasaporte vigente\nAsistencia Médica de Viaje\nReserva de vuelo\nReserva de hospedaje\nSolvencia económica"
       },
       {
        "pasaporte": "Haití",
        "origen": "Todos",
        "destino": "Papua Nueva Guinea",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado\nPasaporte vigente\nAsistencia Médica de Viaje\nReserva de vuelo\nReserva de hospedaje\nSolvencia económica"
       },
       {
        "pasaporte": "Haití",
        "origen": "Todos",
        "destino": "República Democrática del Congo",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado\nPasaporte vigente\nAsistencia Médica de Viaje\nReserva de vuelo\nReserva de hospedaje\nSolvencia económica"
       },
       {
        "pasaporte": "Haití",
        "origen": "Todos",
        "destino": "San Cristóbal y Nieves",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado\nPasaporte vigente\nAsistencia Médica de Viaje\nReserva de vuelo\nReserva de hospedaje\nSolvencia económica"
       },
       {
        "pasaporte": "Haití",
        "origen": "Todos",
        "destino": "Santa Elena",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado\nPasaporte vigente\nAsistencia Médica de Viaje\nReserva de vuelo\nReserva de hospedaje\nSolvencia económica"
       },
       {
        "pasaporte": "Haití",
        "origen": "Todos",
        "destino": "Santo Tomé y Príncipe",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado\nPasaporte vigente\nAsistencia Médica de Viaje\nReserva de vuelo\nReserva de hospedaje\nSolvencia económica"
       },
       {
        "pasaporte": "Haití",
        "origen": "Todos",
        "destino": "Sierra Leona",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado\nPasaporte vigente\nAsistencia Médica de Viaje\nReserva de vuelo\nReserva de hospedaje\nSolvencia económica"
       },
       {
        "pasaporte": "Haití",
        "origen": "Todos",
        "destino": "Siria",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado\nPasaporte vigente\nAsistencia Médica de Viaje\nReserva de vuelo\nReserva de hospedaje\nSolvencia económica"
       },
       {
        "pasaporte": "Haití",
        "origen": "Todos",
        "destino": "Sudán del Sur",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado\nPasaporte vigente\nAsistencia Médica de Viaje\nReserva de vuelo\nReserva de hospedaje\nSolvencia económica"
       },
       {
        "pasaporte": "Haití",
        "origen": "Todos",
        "destino": "Tayikistán",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado\nPasaporte vigente\nAsistencia Médica de Viaje\nReserva de vuelo\nReserva de hospedaje\nSolvencia económica"
       },
       {
        "pasaporte": "Haití",
        "origen": "Todos",
        "destino": "Togo",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado\nPasaporte vigente\nAsistencia Médica de Viaje\nReserva de vuelo\nReserva de hospedaje\nSolvencia económica"
       },
       {
        "pasaporte": "Haití",
        "origen": "Todos",
        "destino": "Uganda",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado\nPasaporte vigente\nAsistencia Médica de Viaje\nReserva de vuelo\nReserva de hospedaje\nSolvencia económica"
       },
       {
        "pasaporte": "Haití",
        "origen": "Todos",
        "destino": "Vietnam",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado\nPasaporte vigente\nAsistencia Médica de Viaje\nReserva de vuelo\nReserva de hospedaje\nSolvencia económica"
       },
       {
        "pasaporte": "Haití",
        "origen": "Todos",
        "destino": "Afganistán",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado\nPasaporte vigente\nAsistencia Médica de Viaje\nReserva de vuelo\nReserva de hospedaje\nSolvencia económica"
       },
       {
        "pasaporte": "Haití",
        "origen": "Todos",
        "destino": "Alemania",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado\nPasaporte vigente\nAsistencia Médica de Viaje\nReserva de vuelo\nReserva de hospedaje\nSolvencia económica"
       },
       {
        "pasaporte": "Haití",
        "origen": "Todos",
        "destino": "Andorra",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado\nPasaporte vigente\nAsistencia Médica de Viaje\nReserva de vuelo\nReserva de hospedaje\nSolvencia económica"
       },
       {
        "pasaporte": "Haití",
        "origen": "Todos",
        "destino": "Anguilla",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado\nPasaporte vigente\nAsistencia Médica de Viaje\nReserva de vuelo\nReserva de hospedaje\nSolvencia económica"
       },
       {
        "pasaporte": "Haití",
        "origen": "Todos",
        "destino": "Arabia Saudí",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado\nPasaporte vigente\nAsistencia Médica de Viaje\nReserva de vuelo\nReserva de hospedaje\nSolvencia económica"
       },
       {
        "pasaporte": "Haití",
        "origen": "Todos",
        "destino": "Argelia",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado\nPasaporte vigente\nAsistencia Médica de Viaje\nReserva de vuelo\nReserva de hospedaje\nSolvencia económica"
       },
       {
        "pasaporte": "Haití",
        "origen": "Todos",
        "destino": "Argentina",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado\nPasaporte vigente\nAsistencia Médica de Viaje\nReserva de vuelo\nReserva de hospedaje\nSolvencia económica"
       },
       {
        "pasaporte": "Haití",
        "origen": "Todos",
        "destino": "Aruba",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado\nPasaporte vigente\nAsistencia Médica de Viaje\nReserva de vuelo\nReserva de hospedaje\nSolvencia económica"
       },
       {
        "pasaporte": "Haití",
        "origen": "Todos",
        "destino": "Austria",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado\nPasaporte vigente\nAsistencia Médica de Viaje\nReserva de vuelo\nReserva de hospedaje\nSolvencia económica"
       },
       {
        "pasaporte": "Haití",
        "origen": "Todos",
        "destino": "Azerbaiyán",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado\nPasaporte vigente\nAsistencia Médica de Viaje\nReserva de vuelo\nReserva de hospedaje\nSolvencia económica"
       },
       {
        "pasaporte": "Haití",
        "origen": "Todos",
        "destino": "Barbados",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado\nPasaporte vigente\nAsistencia Médica de Viaje\nReserva de vuelo\nReserva de hospedaje\nSolvencia económica"
       },
       {
        "pasaporte": "Haití",
        "origen": "Todos",
        "destino": "Bélgica",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado\nPasaporte vigente\nAsistencia Médica de Viaje\nReserva de vuelo\nReserva de hospedaje\nSolvencia económica"
       },
       {
        "pasaporte": "Haití",
        "origen": "Todos",
        "destino": "Belice",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado\nPasaporte vigente\nAsistencia Médica de Viaje\nReserva de vuelo\nReserva de hospedaje\nSolvencia económica"
       },
       {
        "pasaporte": "Haití",
        "origen": "Todos",
        "destino": "Bermuda",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado\nPasaporte vigente\nAsistencia Médica de Viaje\nReserva de vuelo\nReserva de hospedaje\nSolvencia económica"
       },
       {
        "pasaporte": "Haití",
        "origen": "Todos",
        "destino": "Bielorrusia",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado\nPasaporte vigente\nAsistencia Médica de Viaje\nReserva de vuelo\nReserva de hospedaje\nSolvencia económica"
       },
       {
        "pasaporte": "Haití",
        "origen": "Todos",
        "destino": "Birmania",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado\nPasaporte vigente\nAsistencia Médica de Viaje\nReserva de vuelo\nReserva de hospedaje\nSolvencia económica"
       },
       {
        "pasaporte": "Haití",
        "origen": "Todos",
        "destino": "Bonaire, San Eustaquio y Saba",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado\nPasaporte vigente\nAsistencia Médica de Viaje\nReserva de vuelo\nReserva de hospedaje\nSolvencia económica"
       },
       {
        "pasaporte": "Haití",
        "origen": "Todos",
        "destino": "Bosnia-Herzegovina",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado\nPasaporte vigente\nAsistencia Médica de Viaje\nReserva de vuelo\nReserva de hospedaje\nSolvencia económica"
       },
       {
        "pasaporte": "Haití",
        "origen": "Todos",
        "destino": "Brasil",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado\nPasaporte vigente\nAsistencia Médica de Viaje\nReserva de vuelo\nReserva de hospedaje\nSolvencia económica"
       },
       {
        "pasaporte": "Haití",
        "origen": "Todos",
        "destino": "Brunei",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado\nPasaporte vigente\nAsistencia Médica de Viaje\nReserva de vuelo\nReserva de hospedaje\nSolvencia económica"
       },
       {
        "pasaporte": "Haití",
        "origen": "Todos",
        "destino": "Bulgaria",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado\nPasaporte vigente\nAsistencia Médica de Viaje\nReserva de vuelo\nReserva de hospedaje\nSolvencia económica"
       },
       {
        "pasaporte": "Haití",
        "origen": "Todos",
        "destino": "Canadá",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado\nPasaporte vigente\nAsistencia Médica de Viaje\nReserva de vuelo\nReserva de hospedaje\nSolvencia económica"
       },
       {
        "pasaporte": "Haití",
        "origen": "Todos",
        "destino": "Chad",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado\nPasaporte vigente\nAsistencia Médica de Viaje\nReserva de vuelo\nReserva de hospedaje\nSolvencia económica"
       },
       {
        "pasaporte": "Haití",
        "origen": "Todos",
        "destino": "Chile",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado\nPasaporte vigente\nAsistencia Médica de Viaje\nReserva de vuelo\nReserva de hospedaje\nSolvencia económica"
       },
       {
        "pasaporte": "Haití",
        "origen": "Todos",
        "destino": "China",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado\nPasaporte vigente\nAsistencia Médica de Viaje\nReserva de vuelo\nReserva de hospedaje\nSolvencia económica"
       },
       {
        "pasaporte": "Haití",
        "origen": "Todos",
        "destino": "Chipre",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado\nPasaporte vigente\nAsistencia Médica de Viaje\nReserva de vuelo\nReserva de hospedaje\nSolvencia económica"
       },
       {
        "pasaporte": "Haití",
        "origen": "Todos",
        "destino": "Ciudad del Vaticano",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado\nPasaporte vigente\nAsistencia Médica de Viaje\nReserva de vuelo\nReserva de hospedaje\nSolvencia económica"
       },
       {
        "pasaporte": "Haití",
        "origen": "Todos",
        "destino": "Corea del Norte",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado\nPasaporte vigente\nAsistencia Médica de Viaje\nReserva de vuelo\nReserva de hospedaje\nSolvencia económica"
       },
       {
        "pasaporte": "Haití",
        "origen": "Todos",
        "destino": "Costa Rica",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado\nPasaporte vigente\nAsistencia Médica de Viaje\nReserva de vuelo\nReserva de hospedaje\nSolvencia económica"
       },
       {
        "pasaporte": "Haití",
        "origen": "Todos",
        "destino": "Croacia",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado\nPasaporte vigente\nAsistencia Médica de Viaje\nReserva de vuelo\nReserva de hospedaje\nSolvencia económica"
       },
       {
        "pasaporte": "Haití",
        "origen": "Todos",
        "destino": "Cuba",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado\nPasaporte vigente\nAsistencia Médica de Viaje\nReserva de vuelo\nReserva de hospedaje\nSolvencia económica"
       },
       {
        "pasaporte": "Haití",
        "origen": "Todos",
        "destino": "Curaçao",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado\nPasaporte vigente\nAsistencia Médica de Viaje\nReserva de vuelo\nReserva de hospedaje\nSolvencia económica"
       },
       {
        "pasaporte": "Haití",
        "origen": "Todos",
        "destino": "Dinamarca",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado\nPasaporte vigente\nAsistencia Médica de Viaje\nReserva de vuelo\nReserva de hospedaje\nSolvencia económica"
       },
       {
        "pasaporte": "Haití",
        "origen": "Todos",
        "destino": "Dominica",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado\nPasaporte vigente\nAsistencia Médica de Viaje\nReserva de vuelo\nReserva de hospedaje\nSolvencia económica"
       },
       {
        "pasaporte": "Haití",
        "origen": "Todos",
        "destino": "Eritrea",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado\nPasaporte vigente\nAsistencia Médica de Viaje\nReserva de vuelo\nReserva de hospedaje\nSolvencia económica"
       },
       {
        "pasaporte": "Haití",
        "origen": "Todos",
        "destino": "Eslovaquia",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado\nPasaporte vigente\nAsistencia Médica de Viaje\nReserva de vuelo\nReserva de hospedaje\nSolvencia económica"
       },
       {
        "pasaporte": "Haití",
        "origen": "Todos",
        "destino": "Eslovenia",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado\nPasaporte vigente\nAsistencia Médica de Viaje\nReserva de vuelo\nReserva de hospedaje\nSolvencia económica"
       },
       {
        "pasaporte": "Haití",
        "origen": "Todos",
        "destino": "España",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado\nPasaporte vigente\nAsistencia Médica de Viaje\nReserva de vuelo\nReserva de hospedaje\nSolvencia económica"
       },
       {
        "pasaporte": "Haití",
        "origen": "Todos",
        "destino": "Estados Unidos de América",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado\nPasaporte vigente\nAsistencia Médica de Viaje\nReserva de vuelo\nReserva de hospedaje\nSolvencia económica"
       },
       {
        "pasaporte": "Haití",
        "origen": "Todos",
        "destino": "Estonia",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado\nPasaporte vigente\nAsistencia Médica de Viaje\nReserva de vuelo\nReserva de hospedaje\nSolvencia económica"
       },
       {
        "pasaporte": "Haití",
        "origen": "Todos",
        "destino": "Eswatini",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado\nPasaporte vigente\nAsistencia Médica de Viaje\nReserva de vuelo\nReserva de hospedaje\nSolvencia económica"
       },
       {
        "pasaporte": "Haití",
        "origen": "Todos",
        "destino": "Falkland Islands",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado\nPasaporte vigente\nAsistencia Médica de Viaje\nReserva de vuelo\nReserva de hospedaje\nSolvencia económica"
       },
       {
        "pasaporte": "Haití",
        "origen": "Todos",
        "destino": "Federación Rusa",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado\nPasaporte vigente\nAsistencia Médica de Viaje\nReserva de vuelo\nReserva de hospedaje\nSolvencia económica"
       },
       {
        "pasaporte": "Haití",
        "origen": "Todos",
        "destino": "Fiji",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado\nPasaporte vigente\nAsistencia Médica de Viaje\nReserva de vuelo\nReserva de hospedaje\nSolvencia económica"
       },
       {
        "pasaporte": "Haití",
        "origen": "Todos",
        "destino": "Finlandia",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado\nPasaporte vigente\nAsistencia Médica de Viaje\nReserva de vuelo\nReserva de hospedaje\nSolvencia económica"
       },
       {
        "pasaporte": "Haití",
        "origen": "Todos",
        "destino": "Francia",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado\nPasaporte vigente\nAsistencia Médica de Viaje\nReserva de vuelo\nReserva de hospedaje\nSolvencia económica"
       },
       {
        "pasaporte": "Haití",
        "origen": "Todos",
        "destino": "Ghana",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado\nPasaporte vigente\nAsistencia Médica de Viaje\nReserva de vuelo\nReserva de hospedaje\nSolvencia económica"
       },
       {
        "pasaporte": "Haití",
        "origen": "Todos",
        "destino": "Gibraltar",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado\nPasaporte vigente\nAsistencia Médica de Viaje\nReserva de vuelo\nReserva de hospedaje\nSolvencia económica"
       },
       {
        "pasaporte": "Haití",
        "origen": "Todos",
        "destino": "Grecia",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado\nPasaporte vigente\nAsistencia Médica de Viaje\nReserva de vuelo\nReserva de hospedaje\nSolvencia económica"
       },
       {
        "pasaporte": "Haití",
        "origen": "Todos",
        "destino": "Groenlandia",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado\nPasaporte vigente\nAsistencia Médica de Viaje\nReserva de vuelo\nReserva de hospedaje\nSolvencia económica"
       },
       {
        "pasaporte": "Haití",
        "origen": "Todos",
        "destino": "Guam",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado\nPasaporte vigente\nAsistencia Médica de Viaje\nReserva de vuelo\nReserva de hospedaje\nSolvencia económica"
       },
       {
        "pasaporte": "Haití",
        "origen": "Todos",
        "destino": "Guatemala",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado\nPasaporte vigente\nAsistencia Médica de Viaje\nReserva de vuelo\nReserva de hospedaje\nSolvencia económica"
       },
       {
        "pasaporte": "Haití",
        "origen": "Todos",
        "destino": "Guayana Francesa",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado\nPasaporte vigente\nAsistencia Médica de Viaje\nReserva de vuelo\nReserva de hospedaje\nSolvencia económica"
       },
       {
        "pasaporte": "Haití",
        "origen": "Todos",
        "destino": "Guyana",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado\nPasaporte vigente\nAsistencia Médica de Viaje\nReserva de vuelo\nReserva de hospedaje\nSolvencia económica"
       },
       {
        "pasaporte": "Haití",
        "origen": "Todos",
        "destino": "Honduras",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado\nPasaporte vigente\nAsistencia Médica de Viaje\nReserva de vuelo\nReserva de hospedaje\nSolvencia económica"
       },
       {
        "pasaporte": "Haití",
        "origen": "Todos",
        "destino": "Hungría",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado\nPasaporte vigente\nAsistencia Médica de Viaje\nReserva de vuelo\nReserva de hospedaje\nSolvencia económica"
       },
       {
        "pasaporte": "Haití",
        "origen": "Todos",
        "destino": "Indias Occidentales Francesas",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado\nPasaporte vigente\nAsistencia Médica de Viaje\nReserva de vuelo\nReserva de hospedaje\nSolvencia económica"
       },
       {
        "pasaporte": "Haití",
        "origen": "Todos",
        "destino": "Irak",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado\nPasaporte vigente\nAsistencia Médica de Viaje\nReserva de vuelo\nReserva de hospedaje\nSolvencia económica"
       },
       {
        "pasaporte": "Haití",
        "origen": "Todos",
        "destino": "Irlanda",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado\nPasaporte vigente\nAsistencia Médica de Viaje\nReserva de vuelo\nReserva de hospedaje\nSolvencia económica"
       },
       {
        "pasaporte": "Haití",
        "origen": "Todos",
        "destino": "Isla Norfolk",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado\nPasaporte vigente\nAsistencia Médica de Viaje\nReserva de vuelo\nReserva de hospedaje\nSolvencia económica"
       },
       {
        "pasaporte": "Haití",
        "origen": "Todos",
        "destino": "Isla Reunión",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado\nPasaporte vigente\nAsistencia Médica de Viaje\nReserva de vuelo\nReserva de hospedaje\nSolvencia económica"
       },
       {
        "pasaporte": "Haití",
        "origen": "Todos",
        "destino": "Islandia",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado\nPasaporte vigente\nAsistencia Médica de Viaje\nReserva de vuelo\nReserva de hospedaje\nSolvencia económica"
       },
       {
        "pasaporte": "Haití",
        "origen": "Todos",
        "destino": "Islas Caimán",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado\nPasaporte vigente\nAsistencia Médica de Viaje\nReserva de vuelo\nReserva de hospedaje\nSolvencia económica"
       },
       {
        "pasaporte": "Haití",
        "origen": "Todos",
        "destino": "Islas Feroe",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado\nPasaporte vigente\nAsistencia Médica de Viaje\nReserva de vuelo\nReserva de hospedaje\nSolvencia económica"
       },
       {
        "pasaporte": "Haití",
        "origen": "Todos",
        "destino": "Islas Marianas del Norte",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado\nPasaporte vigente\nAsistencia Médica de Viaje\nReserva de vuelo\nReserva de hospedaje\nSolvencia económica"
       },
       {
        "pasaporte": "Haití",
        "origen": "Todos",
        "destino": "Islas Marshall",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado\nPasaporte vigente\nAsistencia Médica de Viaje\nReserva de vuelo\nReserva de hospedaje\nSolvencia económica"
       },
       {
        "pasaporte": "Haití",
        "origen": "Todos",
        "destino": "Islas Salomón",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado\nPasaporte vigente\nAsistencia Médica de Viaje\nReserva de vuelo\nReserva de hospedaje\nSolvencia económica"
       },
       {
        "pasaporte": "Haití",
        "origen": "Todos",
        "destino": "Islas Turcas y Caicos",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado\nPasaporte vigente\nAsistencia Médica de Viaje\nReserva de vuelo\nReserva de hospedaje\nSolvencia económica"
       },
       {
        "pasaporte": "Haití",
        "origen": "Todos",
        "destino": "Islas Vírgenes americanas",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado\nPasaporte vigente\nAsistencia Médica de Viaje\nReserva de vuelo\nReserva de hospedaje\nSolvencia económica"
       },
       {
        "pasaporte": "Haití",
        "origen": "Todos",
        "destino": "Islas Vírgenes Británicas",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado\nPasaporte vigente\nAsistencia Médica de Viaje\nReserva de vuelo\nReserva de hospedaje\nSolvencia económica"
       },
       {
        "pasaporte": "Haití",
        "origen": "Todos",
        "destino": "Italia",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado\nPasaporte vigente\nAsistencia Médica de Viaje\nReserva de vuelo\nReserva de hospedaje\nSolvencia económica"
       },
       {
        "pasaporte": "Haití",
        "origen": "Todos",
        "destino": "Jamaica",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado\nPasaporte vigente\nAsistencia Médica de Viaje\nReserva de vuelo\nReserva de hospedaje\nSolvencia económica"
       },
       {
        "pasaporte": "Haití",
        "origen": "Todos",
        "destino": "Japón",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado\nPasaporte vigente\nAsistencia Médica de Viaje\nReserva de vuelo\nReserva de hospedaje\nSolvencia económica"
       },
       {
        "pasaporte": "Haití",
        "origen": "Todos",
        "destino": "Kosovo",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado\nPasaporte vigente\nAsistencia Médica de Viaje\nReserva de vuelo\nReserva de hospedaje\nSolvencia económica"
       },
       {
        "pasaporte": "Haití",
        "origen": "Todos",
        "destino": "Kuwait",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado\nPasaporte vigente\nAsistencia Médica de Viaje\nReserva de vuelo\nReserva de hospedaje\nSolvencia económica"
       },
       {
        "pasaporte": "Haití",
        "origen": "Todos",
        "destino": "Letonia",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado\nPasaporte vigente\nAsistencia Médica de Viaje\nReserva de vuelo\nReserva de hospedaje\nSolvencia económica"
       },
       {
        "pasaporte": "Haití",
        "origen": "Todos",
        "destino": "Líbano",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado\nPasaporte vigente\nAsistencia Médica de Viaje\nReserva de vuelo\nReserva de hospedaje\nSolvencia económica"
       },
       {
        "pasaporte": "Haití",
        "origen": "Todos",
        "destino": "Liberia",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado\nPasaporte vigente\nAsistencia Médica de Viaje\nReserva de vuelo\nReserva de hospedaje\nSolvencia económica"
       },
       {
        "pasaporte": "Haití",
        "origen": "Todos",
        "destino": "Liechtenstein",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado\nPasaporte vigente\nAsistencia Médica de Viaje\nReserva de vuelo\nReserva de hospedaje\nSolvencia económica"
       },
       {
        "pasaporte": "Haití",
        "origen": "Todos",
        "destino": "Lituania",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado\nPasaporte vigente\nAsistencia Médica de Viaje\nReserva de vuelo\nReserva de hospedaje\nSolvencia económica"
       },
       {
        "pasaporte": "Haití",
        "origen": "Todos",
        "destino": "Luxemburgo",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado\nPasaporte vigente\nAsistencia Médica de Viaje\nReserva de vuelo\nReserva de hospedaje\nSolvencia económica"
       },
       {
        "pasaporte": "Haití",
        "origen": "Todos",
        "destino": "Macedonia del Norte",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado\nPasaporte vigente\nAsistencia Médica de Viaje\nReserva de vuelo\nReserva de hospedaje\nSolvencia económica"
       },
       {
        "pasaporte": "Haití",
        "origen": "Todos",
        "destino": "Mali",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado\nPasaporte vigente\nAsistencia Médica de Viaje\nReserva de vuelo\nReserva de hospedaje\nSolvencia económica"
       },
       {
        "pasaporte": "Haití",
        "origen": "Todos",
        "destino": "Malta",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado\nPasaporte vigente\nAsistencia Médica de Viaje\nReserva de vuelo\nReserva de hospedaje\nSolvencia económica"
       },
       {
        "pasaporte": "Haití",
        "origen": "Todos",
        "destino": "Marruecos",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado\nPasaporte vigente\nAsistencia Médica de Viaje\nReserva de vuelo\nReserva de hospedaje\nSolvencia económica"
       },
       {
        "pasaporte": "Haití",
        "origen": "Todos",
        "destino": "Mayotte",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado\nPasaporte vigente\nAsistencia Médica de Viaje\nReserva de vuelo\nReserva de hospedaje\nSolvencia económica"
       },
       {
        "pasaporte": "Haití",
        "origen": "Todos",
        "destino": "México",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado\nPasaporte vigente\nAsistencia Médica de Viaje\nReserva de vuelo\nReserva de hospedaje\nSolvencia económica"
       },
       {
        "pasaporte": "Haití",
        "origen": "Todos",
        "destino": "Mónaco",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado\nPasaporte vigente\nAsistencia Médica de Viaje\nReserva de vuelo\nReserva de hospedaje\nSolvencia económica"
       },
       {
        "pasaporte": "Haití",
        "origen": "Todos",
        "destino": "Montenegro",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado\nPasaporte vigente\nAsistencia Médica de Viaje\nReserva de vuelo\nReserva de hospedaje\nSolvencia económica"
       },
       {
        "pasaporte": "Haití",
        "origen": "Todos",
        "destino": "Namibia",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado\nPasaporte vigente\nAsistencia Médica de Viaje\nReserva de vuelo\nReserva de hospedaje\nSolvencia económica"
       },
       {
        "pasaporte": "Haití",
        "origen": "Todos",
        "destino": "Nauru",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado\nPasaporte vigente\nAsistencia Médica de Viaje\nReserva de vuelo\nReserva de hospedaje\nSolvencia económica"
       },
       {
        "pasaporte": "Haití",
        "origen": "Todos",
        "destino": "Nicaragua",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado\nPasaporte vigente\nAsistencia Médica de Viaje\nReserva de vuelo\nReserva de hospedaje\nSolvencia económica"
       },
       {
        "pasaporte": "Haití",
        "origen": "Todos",
        "destino": "Níger",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado\nPasaporte vigente\nAsistencia Médica de Viaje\nReserva de vuelo\nReserva de hospedaje\nSolvencia económica"
       },
       {
        "pasaporte": "Haití",
        "origen": "Todos",
        "destino": "Noruega",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado\nPasaporte vigente\nAsistencia Médica de Viaje\nReserva de vuelo\nReserva de hospedaje\nSolvencia económica"
       },
       {
        "pasaporte": "Haití",
        "origen": "Todos",
        "destino": "Nueva Caledonia",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado\nPasaporte vigente\nAsistencia Médica de Viaje\nReserva de vuelo\nReserva de hospedaje\nSolvencia económica"
       },
       {
        "pasaporte": "Haití",
        "origen": "Todos",
        "destino": "Nueva Zelanda",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado\nPasaporte vigente\nAsistencia Médica de Viaje\nReserva de vuelo\nReserva de hospedaje\nSolvencia económica"
       },
       {
        "pasaporte": "Haití",
        "origen": "Todos",
        "destino": "Países Bajos",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado\nPasaporte vigente\nAsistencia Médica de Viaje\nReserva de vuelo\nReserva de hospedaje\nSolvencia económica"
       },
       {
        "pasaporte": "Haití",
        "origen": "Todos",
        "destino": "Panamá",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado\nPasaporte vigente\nAsistencia Médica de Viaje\nReserva de vuelo\nReserva de hospedaje\nSolvencia económica"
       },
       {
        "pasaporte": "Haití",
        "origen": "Todos",
        "destino": "Paraguay",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado\nPasaporte vigente\nAsistencia Médica de Viaje\nReserva de vuelo\nReserva de hospedaje\nSolvencia económica"
       },
       {
        "pasaporte": "Haití",
        "origen": "Todos",
        "destino": "Perú",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado\nPasaporte vigente\nAsistencia Médica de Viaje\nReserva de vuelo\nReserva de hospedaje\nSolvencia económica"
       },
       {
        "pasaporte": "Haití",
        "origen": "Todos",
        "destino": "Polinesia Francesa",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado\nPasaporte vigente\nAsistencia Médica de Viaje\nReserva de vuelo\nReserva de hospedaje\nSolvencia económica"
       },
       {
        "pasaporte": "Haití",
        "origen": "Todos",
        "destino": "Polonia",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado\nPasaporte vigente\nAsistencia Médica de Viaje\nReserva de vuelo\nReserva de hospedaje\nSolvencia económica"
       },
       {
        "pasaporte": "Haití",
        "origen": "Todos",
        "destino": "Portugal",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado\nPasaporte vigente\nAsistencia Médica de Viaje\nReserva de vuelo\nReserva de hospedaje\nSolvencia económica"
       },
       {
        "pasaporte": "Haití",
        "origen": "Todos",
        "destino": "Puerto Rico",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado\nPasaporte vigente\nAsistencia Médica de Viaje\nReserva de vuelo\nReserva de hospedaje\nSolvencia económica"
       },
       {
        "pasaporte": "Haití",
        "origen": "Todos",
        "destino": "Qatar",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado\nPasaporte vigente\nAsistencia Médica de Viaje\nReserva de vuelo\nReserva de hospedaje\nSolvencia económica"
       },
       {
        "pasaporte": "Haití",
        "origen": "Todos",
        "destino": "Reino Unido",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado\nPasaporte vigente\nAsistencia Médica de Viaje\nReserva de vuelo\nReserva de hospedaje\nSolvencia económica"
       },
       {
        "pasaporte": "Haití",
        "origen": "Todos",
        "destino": "República Centroafricana",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado\nPasaporte vigente\nAsistencia Médica de Viaje\nReserva de vuelo\nReserva de hospedaje\nSolvencia económica"
       },
       {
        "pasaporte": "Haití",
        "origen": "Todos",
        "destino": "República Checa",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado\nPasaporte vigente\nAsistencia Médica de Viaje\nReserva de vuelo\nReserva de hospedaje\nSolvencia económica"
       },
       {
        "pasaporte": "Haití",
        "origen": "Todos",
        "destino": "República del Congo",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado\nPasaporte vigente\nAsistencia Médica de Viaje\nReserva de vuelo\nReserva de hospedaje\nSolvencia económica"
       },
       {
        "pasaporte": "Haití",
        "origen": "Todos",
        "destino": "República Dominicana",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado\nPasaporte vigente\nAsistencia Médica de Viaje\nReserva de vuelo\nReserva de hospedaje\nSolvencia económica"
       },
       {
        "pasaporte": "Haití",
        "origen": "Todos",
        "destino": "Rumanía",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado\nPasaporte vigente\nAsistencia Médica de Viaje\nReserva de vuelo\nReserva de hospedaje\nSolvencia económica"
       },
       {
        "pasaporte": "Haití",
        "origen": "Todos",
        "destino": "Samoa Americana",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado\nPasaporte vigente\nAsistencia Médica de Viaje\nReserva de vuelo\nReserva de hospedaje\nSolvencia económica"
       },
       {
        "pasaporte": "Haití",
        "origen": "Todos",
        "destino": "San Marino",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado\nPasaporte vigente\nAsistencia Médica de Viaje\nReserva de vuelo\nReserva de hospedaje\nSolvencia económica"
       },
       {
        "pasaporte": "Haití",
        "origen": "Todos",
        "destino": "San Martín",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado\nPasaporte vigente\nAsistencia Médica de Viaje\nReserva de vuelo\nReserva de hospedaje\nSolvencia económica"
       },
       {
        "pasaporte": "Haití",
        "origen": "Todos",
        "destino": "San Pedro y Miquelón",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado\nPasaporte vigente\nAsistencia Médica de Viaje\nReserva de vuelo\nReserva de hospedaje\nSolvencia económica"
       },
       {
        "pasaporte": "Haití",
        "origen": "Todos",
        "destino": "San Vicente y las Granadinas",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado\nPasaporte vigente\nAsistencia Médica de Viaje\nReserva de vuelo\nReserva de hospedaje\nSolvencia económica"
       },
       {
        "pasaporte": "Haití",
        "origen": "Todos",
        "destino": "Santa Lucía",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado\nPasaporte vigente\nAsistencia Médica de Viaje\nReserva de vuelo\nReserva de hospedaje\nSolvencia económica"
       },
       {
        "pasaporte": "Haití",
        "origen": "Todos",
        "destino": "Serbia",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado\nPasaporte vigente\nAsistencia Médica de Viaje\nReserva de vuelo\nReserva de hospedaje\nSolvencia económica"
       },
       {
        "pasaporte": "Haití",
        "origen": "Todos",
        "destino": "Somalia",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado\nPasaporte vigente\nAsistencia Médica de Viaje\nReserva de vuelo\nReserva de hospedaje\nSolvencia económica"
       },
       {
        "pasaporte": "Haití",
        "origen": "Todos",
        "destino": "Sudáfrica",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado\nPasaporte vigente\nAsistencia Médica de Viaje\nReserva de vuelo\nReserva de hospedaje\nSolvencia económica"
       },
       {
        "pasaporte": "Haití",
        "origen": "Todos",
        "destino": "Sudán",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado\nPasaporte vigente\nAsistencia Médica de Viaje\nReserva de vuelo\nReserva de hospedaje\nSolvencia económica"
       },
       {
        "pasaporte": "Haití",
        "origen": "Todos",
        "destino": "Suecia",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado\nPasaporte vigente\nAsistencia Médica de Viaje\nReserva de vuelo\nReserva de hospedaje\nSolvencia económica"
       },
       {
        "pasaporte": "Haití",
        "origen": "Todos",
        "destino": "Suiza",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado\nPasaporte vigente\nAsistencia Médica de Viaje\nReserva de vuelo\nReserva de hospedaje\nSolvencia económica"
       },
       {
        "pasaporte": "Haití",
        "origen": "Todos",
        "destino": "Surinam",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado\nPasaporte vigente\nAsistencia Médica de Viaje\nReserva de vuelo\nReserva de hospedaje\nSolvencia económica"
       },
       {
        "pasaporte": "Haití",
        "origen": "Todos",
        "destino": "Tailandia",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado\nPasaporte vigente\nAsistencia Médica de Viaje\nReserva de vuelo\nReserva de hospedaje\nSolvencia económica"
       },
       {
        "pasaporte": "Haití",
        "origen": "Todos",
        "destino": "Tonga",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado\nPasaporte vigente\nAsistencia Médica de Viaje\nReserva de vuelo\nReserva de hospedaje\nSolvencia económica"
       },
       {
        "pasaporte": "Haití",
        "origen": "Todos",
        "destino": "Trinidad y Tobago",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado\nPasaporte vigente\nAsistencia Médica de Viaje\nReserva de vuelo\nReserva de hospedaje\nSolvencia económica"
       },
       {
        "pasaporte": "Haití",
        "origen": "Todos",
        "destino": "Túnez",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado\nPasaporte vigente\nAsistencia Médica de Viaje\nReserva de vuelo\nReserva de hospedaje\nSolvencia económica"
       },
       {
        "pasaporte": "Haití",
        "origen": "Todos",
        "destino": "Turkmenistán",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado\nPasaporte vigente\nAsistencia Médica de Viaje\nReserva de vuelo\nReserva de hospedaje\nSolvencia económica"
       },
       {
        "pasaporte": "Haití",
        "origen": "Todos",
        "destino": "Ucrania",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado\nPasaporte vigente\nAsistencia Médica de Viaje\nReserva de vuelo\nReserva de hospedaje\nSolvencia económica"
       },
       {
        "pasaporte": "Haití",
        "origen": "Todos",
        "destino": "Uruguay",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado\nPasaporte vigente\nAsistencia Médica de Viaje\nReserva de vuelo\nReserva de hospedaje\nSolvencia económica"
       },
       {
        "pasaporte": "Haití",
        "origen": "Todos",
        "destino": "Uzbekistán",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado\nPasaporte vigente\nAsistencia Médica de Viaje\nReserva de vuelo\nReserva de hospedaje\nSolvencia económica"
       },
       {
        "pasaporte": "Haití",
        "origen": "Todos",
        "destino": "Vanuatu",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado\nPasaporte vigente\nAsistencia Médica de Viaje\nReserva de vuelo\nReserva de hospedaje\nSolvencia económica"
       },
       {
        "pasaporte": "Haití",
        "origen": "Todos",
        "destino": "Venezuela",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado\nPasaporte vigente\nAsistencia Médica de Viaje\nReserva de vuelo\nReserva de hospedaje\nSolvencia económica"
       },
       {
        "pasaporte": "Haití",
        "origen": "Todos",
        "destino": "Wallis y Futuna",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado\nPasaporte vigente\nAsistencia Médica de Viaje\nReserva de vuelo\nReserva de hospedaje\nSolvencia económica"
       },
       {
        "pasaporte": "Haití",
        "origen": "Todos",
        "destino": "Yemen",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado\nPasaporte vigente\nAsistencia Médica de Viaje\nReserva de vuelo\nReserva de hospedaje\nSolvencia económica"
       },
       {
        "pasaporte": "Trinidad y Tobago",
        "origen": "Todos",
        "destino": "Albania",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Trinidad y Tobago",
        "origen": "Todos",
        "destino": "Alemania",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Trinidad y Tobago",
        "origen": "Todos",
        "destino": "Andorra",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Trinidad y Tobago",
        "origen": "Todos",
        "destino": "Angola",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Trinidad y Tobago",
        "origen": "Todos",
        "destino": "Anguilla",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Trinidad y Tobago",
        "origen": "Todos",
        "destino": "Antigua y Barbuda",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Trinidad y Tobago",
        "origen": "Todos",
        "destino": "Argentina",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Trinidad y Tobago",
        "origen": "Todos",
        "destino": "Aruba",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Trinidad y Tobago",
        "origen": "Todos",
        "destino": "Austria",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Trinidad y Tobago",
        "origen": "Todos",
        "destino": "Bahamas",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Trinidad y Tobago",
        "origen": "Todos",
        "destino": "Barbados",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Trinidad y Tobago",
        "origen": "Todos",
        "destino": "Bélgica",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Trinidad y Tobago",
        "origen": "Todos",
        "destino": "Belice",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Trinidad y Tobago",
        "origen": "Todos",
        "destino": "Bermuda",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Trinidad y Tobago",
        "origen": "Todos",
        "destino": "Bonaire, San Eustaquio y Saba",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Trinidad y Tobago",
        "origen": "Todos",
        "destino": "Bosnia-Herzegovina",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Trinidad y Tobago",
        "origen": "Todos",
        "destino": "Botswana",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Trinidad y Tobago",
        "origen": "Todos",
        "destino": "Brasil",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Trinidad y Tobago",
        "origen": "Todos",
        "destino": "Bulgaria",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Trinidad y Tobago",
        "origen": "Todos",
        "destino": "Chile",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Trinidad y Tobago",
        "origen": "Todos",
        "destino": "Chipre",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Trinidad y Tobago",
        "origen": "Todos",
        "destino": "Ciudad del Vaticano",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Trinidad y Tobago",
        "origen": "Todos",
        "destino": "Colombia",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Trinidad y Tobago",
        "origen": "Todos",
        "destino": "Costa Rica",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Trinidad y Tobago",
        "origen": "Todos",
        "destino": "Croacia",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Trinidad y Tobago",
        "origen": "Todos",
        "destino": "Curaçao",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Trinidad y Tobago",
        "origen": "Todos",
        "destino": "Dinamarca",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Trinidad y Tobago",
        "origen": "Todos",
        "destino": "Dominica",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Trinidad y Tobago",
        "origen": "Todos",
        "destino": "Ecuador",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Trinidad y Tobago",
        "origen": "Todos",
        "destino": "El Salvador",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Trinidad y Tobago",
        "origen": "Todos",
        "destino": "Eslovaquia",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Trinidad y Tobago",
        "origen": "Todos",
        "destino": "Eslovenia",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Trinidad y Tobago",
        "origen": "Todos",
        "destino": "España",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Trinidad y Tobago",
        "origen": "Todos",
        "destino": "Estonia",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Trinidad y Tobago",
        "origen": "Todos",
        "destino": "Eswatini",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Trinidad y Tobago",
        "origen": "Todos",
        "destino": "Fiji",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Trinidad y Tobago",
        "origen": "Todos",
        "destino": "Filipinas",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Trinidad y Tobago",
        "origen": "Todos",
        "destino": "Finlandia",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Trinidad y Tobago",
        "origen": "Todos",
        "destino": "Francia",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Trinidad y Tobago",
        "origen": "Todos",
        "destino": "Gambia",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Trinidad y Tobago",
        "origen": "Todos",
        "destino": "Ghana",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Trinidad y Tobago",
        "origen": "Todos",
        "destino": "Gibraltar",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Trinidad y Tobago",
        "origen": "Todos",
        "destino": "Granada",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Trinidad y Tobago",
        "origen": "Todos",
        "destino": "Grecia",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Trinidad y Tobago",
        "origen": "Todos",
        "destino": "Groenlandia",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Trinidad y Tobago",
        "origen": "Todos",
        "destino": "Guatemala",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Trinidad y Tobago",
        "origen": "Todos",
        "destino": "Guayana Francesa",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Trinidad y Tobago",
        "origen": "Todos",
        "destino": "Guyana",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Trinidad y Tobago",
        "origen": "Todos",
        "destino": "Haití",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Trinidad y Tobago",
        "origen": "Todos",
        "destino": "Honduras",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Trinidad y Tobago",
        "origen": "Todos",
        "destino": "Hong Kong",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Trinidad y Tobago",
        "origen": "Todos",
        "destino": "Hungría",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Trinidad y Tobago",
        "origen": "Todos",
        "destino": "Indias Occidentales Francesas",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Trinidad y Tobago",
        "origen": "Todos",
        "destino": "Irlanda",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Trinidad y Tobago",
        "origen": "Todos",
        "destino": "Isla Mauricio",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Trinidad y Tobago",
        "origen": "Todos",
        "destino": "Isla Reunión",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Trinidad y Tobago",
        "origen": "Todos",
        "destino": "Islandia",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Trinidad y Tobago",
        "origen": "Todos",
        "destino": "Islas Caimán",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Trinidad y Tobago",
        "origen": "Todos",
        "destino": "Islas Cook",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Trinidad y Tobago",
        "origen": "Todos",
        "destino": "Islas Feroe",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Trinidad y Tobago",
        "origen": "Todos",
        "destino": "Islas Turcas y Caicos",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Trinidad y Tobago",
        "origen": "Todos",
        "destino": "Islas Vírgenes Británicas",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Trinidad y Tobago",
        "origen": "Todos",
        "destino": "Israel",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Trinidad y Tobago",
        "origen": "Todos",
        "destino": "Italia",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Trinidad y Tobago",
        "origen": "Todos",
        "destino": "Jamaica",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Trinidad y Tobago",
        "origen": "Todos",
        "destino": "Kiribati",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Trinidad y Tobago",
        "origen": "Todos",
        "destino": "Kosovo",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Trinidad y Tobago",
        "origen": "Todos",
        "destino": "Lesoto",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Trinidad y Tobago",
        "origen": "Todos",
        "destino": "Letonia",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Trinidad y Tobago",
        "origen": "Todos",
        "destino": "Liechtenstein",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Trinidad y Tobago",
        "origen": "Todos",
        "destino": "Lituania",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Trinidad y Tobago",
        "origen": "Todos",
        "destino": "Luxemburgo",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Trinidad y Tobago",
        "origen": "Todos",
        "destino": "Malasia",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Trinidad y Tobago",
        "origen": "Todos",
        "destino": "Malawi",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Trinidad y Tobago",
        "origen": "Todos",
        "destino": "Malta",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Trinidad y Tobago",
        "origen": "Todos",
        "destino": "Mayotte",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Trinidad y Tobago",
        "origen": "Todos",
        "destino": "México",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Trinidad y Tobago",
        "origen": "Todos",
        "destino": "Micronesia",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Trinidad y Tobago",
        "origen": "Todos",
        "destino": "Moldavia",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Trinidad y Tobago",
        "origen": "Todos",
        "destino": "Mónaco",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Trinidad y Tobago",
        "origen": "Todos",
        "destino": "Montenegro",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Trinidad y Tobago",
        "origen": "Todos",
        "destino": "Montserrat",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Trinidad y Tobago",
        "origen": "Todos",
        "destino": "Nicaragua",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Trinidad y Tobago",
        "origen": "Todos",
        "destino": "Noruega",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Trinidad y Tobago",
        "origen": "Todos",
        "destino": "Nueva Caledonia",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Trinidad y Tobago",
        "origen": "Todos",
        "destino": "Países Bajos",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Trinidad y Tobago",
        "origen": "Todos",
        "destino": "Pakistán",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Trinidad y Tobago",
        "origen": "Todos",
        "destino": "Panamá",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Trinidad y Tobago",
        "origen": "Todos",
        "destino": "Perú",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Trinidad y Tobago",
        "origen": "Todos",
        "destino": "Polinesia Francesa",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Trinidad y Tobago",
        "origen": "Todos",
        "destino": "Polonia",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Trinidad y Tobago",
        "origen": "Todos",
        "destino": "Portugal",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Trinidad y Tobago",
        "origen": "Todos",
        "destino": "Reino Unido",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Trinidad y Tobago",
        "origen": "Todos",
        "destino": "República Checa",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Trinidad y Tobago",
        "origen": "Todos",
        "destino": "República Dominicana",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Trinidad y Tobago",
        "origen": "Todos",
        "destino": "Ruanda",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Trinidad y Tobago",
        "origen": "Todos",
        "destino": "Rumanía",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Trinidad y Tobago",
        "origen": "Todos",
        "destino": "San Cristóbal y Nieves",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Trinidad y Tobago",
        "origen": "Todos",
        "destino": "San Marino",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Trinidad y Tobago",
        "origen": "Todos",
        "destino": "San Martín",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Trinidad y Tobago",
        "origen": "Todos",
        "destino": "San Pedro y Miquelón",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Trinidad y Tobago",
        "origen": "Todos",
        "destino": "San Vicente y las Granadinas",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Trinidad y Tobago",
        "origen": "Todos",
        "destino": "Santa Elena",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Trinidad y Tobago",
        "origen": "Todos",
        "destino": "Santa Lucía",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Trinidad y Tobago",
        "origen": "Todos",
        "destino": "Serbia",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Trinidad y Tobago",
        "origen": "Todos",
        "destino": "Singapur",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Trinidad y Tobago",
        "origen": "Todos",
        "destino": "Sudáfrica",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Trinidad y Tobago",
        "origen": "Todos",
        "destino": "Suecia",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Trinidad y Tobago",
        "origen": "Todos",
        "destino": "Suiza",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Trinidad y Tobago",
        "origen": "Todos",
        "destino": "Surinam",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Trinidad y Tobago",
        "origen": "Todos",
        "destino": "Tailandia",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Trinidad y Tobago",
        "origen": "Todos",
        "destino": "Tanzania",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Trinidad y Tobago",
        "origen": "Todos",
        "destino": "Territorios Palestinos",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Trinidad y Tobago",
        "origen": "Todos",
        "destino": "Turquía",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Trinidad y Tobago",
        "origen": "Todos",
        "destino": "Uruguay",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Trinidad y Tobago",
        "origen": "Todos",
        "destino": "Uzbekistán",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Trinidad y Tobago",
        "origen": "Todos",
        "destino": "Vanuatu",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Trinidad y Tobago",
        "origen": "Todos",
        "destino": "Venezuela",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Trinidad y Tobago",
        "origen": "Todos",
        "destino": "Wallis y Futuna",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Trinidad y Tobago",
        "origen": "Todos",
        "destino": "Zambia",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Trinidad y Tobago",
        "origen": "Todos",
        "destino": "Zimbabue",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "Trinidad y Tobago",
        "origen": "Todos",
        "destino": "Bangladesh",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.97",
        "requisitos": "Visado\nPasaporte vigente\nAsistencia Médica de Viaje\nReserva de vuelo\nReserva de hospedaje\nSolvencia económica"
       },
       {
        "pasaporte": "Trinidad y Tobago",
        "origen": "Todos",
        "destino": "Bolivia",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.97",
        "requisitos": "Visado\nPasaporte vigente\nAsistencia Médica de Viaje\nReserva de vuelo\nReserva de hospedaje\nSolvencia económica"
       },
       {
        "pasaporte": "Trinidad y Tobago",
        "origen": "Todos",
        "destino": "Burundi",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.97",
        "requisitos": "Visado\nPasaporte vigente\nAsistencia Médica de Viaje\nReserva de vuelo\nReserva de hospedaje\nSolvencia económica"
       },
       {
        "pasaporte": "Trinidad y Tobago",
        "origen": "Todos",
        "destino": "Cabo Verde",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.97",
        "requisitos": "Visado\nPasaporte vigente\nAsistencia Médica de Viaje\nReserva de vuelo\nReserva de hospedaje\nSolvencia económica"
       },
       {
        "pasaporte": "Trinidad y Tobago",
        "origen": "Todos",
        "destino": "Camboya",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.97",
        "requisitos": "Visado\nPasaporte vigente\nAsistencia Médica de Viaje\nReserva de vuelo\nReserva de hospedaje\nSolvencia económica"
       },
       {
        "pasaporte": "Trinidad y Tobago",
        "origen": "Todos",
        "destino": "Comoras",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.97",
        "requisitos": "Visado\nPasaporte vigente\nAsistencia Médica de Viaje\nReserva de vuelo\nReserva de hospedaje\nSolvencia económica"
       },
       {
        "pasaporte": "Trinidad y Tobago",
        "origen": "Todos",
        "destino": "Djibouti",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.97",
        "requisitos": "Visado\nPasaporte vigente\nAsistencia Médica de Viaje\nReserva de vuelo\nReserva de hospedaje\nSolvencia económica"
       },
       {
        "pasaporte": "Trinidad y Tobago",
        "origen": "Todos",
        "destino": "Egipto",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.97",
        "requisitos": "Visado\nPasaporte vigente\nAsistencia Médica de Viaje\nReserva de vuelo\nReserva de hospedaje\nSolvencia económica"
       },
       {
        "pasaporte": "Trinidad y Tobago",
        "origen": "Todos",
        "destino": "Guinea-Bissau",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.97",
        "requisitos": "Visado\nPasaporte vigente\nAsistencia Médica de Viaje\nReserva de vuelo\nReserva de hospedaje\nSolvencia económica"
       },
       {
        "pasaporte": "Trinidad y Tobago",
        "origen": "Todos",
        "destino": "Islas Salomón",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.97",
        "requisitos": "Visado\nPasaporte vigente\nAsistencia Médica de Viaje\nReserva de vuelo\nReserva de hospedaje\nSolvencia económica"
       },
       {
        "pasaporte": "Trinidad y Tobago",
        "origen": "Todos",
        "destino": "Islas Seychelles",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.97",
        "requisitos": "Visado\nPasaporte vigente\nAsistencia Médica de Viaje\nReserva de vuelo\nReserva de hospedaje\nSolvencia económica"
       },
       {
        "pasaporte": "Trinidad y Tobago",
        "origen": "Todos",
        "destino": "Jordania",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.97",
        "requisitos": "Visado\nPasaporte vigente\nAsistencia Médica de Viaje\nReserva de vuelo\nReserva de hospedaje\nSolvencia económica"
       },
       {
        "pasaporte": "Trinidad y Tobago",
        "origen": "Todos",
        "destino": "Laos",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.97",
        "requisitos": "Visado\nPasaporte vigente\nAsistencia Médica de Viaje\nReserva de vuelo\nReserva de hospedaje\nSolvencia económica"
       },
       {
        "pasaporte": "Trinidad y Tobago",
        "origen": "Todos",
        "destino": "Macao",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.97",
        "requisitos": "Visado\nPasaporte vigente\nAsistencia Médica de Viaje\nReserva de vuelo\nReserva de hospedaje\nSolvencia económica"
       },
       {
        "pasaporte": "Trinidad y Tobago",
        "origen": "Todos",
        "destino": "Madagascar",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.97",
        "requisitos": "Visado\nPasaporte vigente\nAsistencia Médica de Viaje\nReserva de vuelo\nReserva de hospedaje\nSolvencia económica"
       },
       {
        "pasaporte": "Trinidad y Tobago",
        "origen": "Todos",
        "destino": "Maldivas",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.97",
        "requisitos": "Visado\nPasaporte vigente\nAsistencia Médica de Viaje\nReserva de vuelo\nReserva de hospedaje\nSolvencia económica"
       },
       {
        "pasaporte": "Trinidad y Tobago",
        "origen": "Todos",
        "destino": "Mauritania",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.97",
        "requisitos": "Visado\nPasaporte vigente\nAsistencia Médica de Viaje\nReserva de vuelo\nReserva de hospedaje\nSolvencia económica"
       },
       {
        "pasaporte": "Trinidad y Tobago",
        "origen": "Todos",
        "destino": "Mozambique",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.97",
        "requisitos": "Visado\nPasaporte vigente\nAsistencia Médica de Viaje\nReserva de vuelo\nReserva de hospedaje\nSolvencia económica"
       },
       {
        "pasaporte": "Trinidad y Tobago",
        "origen": "Todos",
        "destino": "Nepal",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.97",
        "requisitos": "Visado\nPasaporte vigente\nAsistencia Médica de Viaje\nReserva de vuelo\nReserva de hospedaje\nSolvencia económica"
       },
       {
        "pasaporte": "Trinidad y Tobago",
        "origen": "Todos",
        "destino": "Niue",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.97",
        "requisitos": "Visado\nPasaporte vigente\nAsistencia Médica de Viaje\nReserva de vuelo\nReserva de hospedaje\nSolvencia económica"
       },
       {
        "pasaporte": "Trinidad y Tobago",
        "origen": "Todos",
        "destino": "Palaos",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.97",
        "requisitos": "Visado\nPasaporte vigente\nAsistencia Médica de Viaje\nReserva de vuelo\nReserva de hospedaje\nSolvencia económica"
       },
       {
        "pasaporte": "Trinidad y Tobago",
        "origen": "Todos",
        "destino": "Samoa",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.97",
        "requisitos": "Visado\nPasaporte vigente\nAsistencia Médica de Viaje\nReserva de vuelo\nReserva de hospedaje\nSolvencia económica"
       },
       {
        "pasaporte": "Trinidad y Tobago",
        "origen": "Todos",
        "destino": "Senegal",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.97",
        "requisitos": "Visado\nPasaporte vigente\nAsistencia Médica de Viaje\nReserva de vuelo\nReserva de hospedaje\nSolvencia económica"
       },
       {
        "pasaporte": "Trinidad y Tobago",
        "origen": "Todos",
        "destino": "Sierra Leona",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.97",
        "requisitos": "Visado\nPasaporte vigente\nAsistencia Médica de Viaje\nReserva de vuelo\nReserva de hospedaje\nSolvencia económica"
       },
       {
        "pasaporte": "Trinidad y Tobago",
        "origen": "Todos",
        "destino": "Sri Lanka",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.97",
        "requisitos": "Visado\nPasaporte vigente\nAsistencia Médica de Viaje\nReserva de vuelo\nReserva de hospedaje\nSolvencia económica"
       },
       {
        "pasaporte": "Trinidad y Tobago",
        "origen": "Todos",
        "destino": "Timor Oriental",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.97",
        "requisitos": "Visado\nPasaporte vigente\nAsistencia Médica de Viaje\nReserva de vuelo\nReserva de hospedaje\nSolvencia económica"
       },
       {
        "pasaporte": "Trinidad y Tobago",
        "origen": "Todos",
        "destino": "Tuvalu",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.97",
        "requisitos": "Visado\nPasaporte vigente\nAsistencia Médica de Viaje\nReserva de vuelo\nReserva de hospedaje\nSolvencia económica"
       },
       {
        "pasaporte": "Trinidad y Tobago",
        "origen": "Todos",
        "destino": "Corea del Sur",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.97",
        "requisitos": "Visado\nPasaporte vigente\nAsistencia Médica de Viaje\nReserva de vuelo\nReserva de hospedaje\nSolvencia económica"
       },
       {
        "pasaporte": "Trinidad y Tobago",
        "origen": "Todos",
        "destino": "Kenia",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.97",
        "requisitos": "Visado\nPasaporte vigente\nAsistencia Médica de Viaje\nReserva de vuelo\nReserva de hospedaje\nSolvencia económica"
       },
       {
        "pasaporte": "Trinidad y Tobago",
        "origen": "Todos",
        "destino": "Armenia",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.93",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Trinidad y Tobago",
        "origen": "Todos",
        "destino": "Australia",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.93",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Trinidad y Tobago",
        "origen": "Todos",
        "destino": "Azerbaiyán",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.93",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Trinidad y Tobago",
        "origen": "Todos",
        "destino": "Bahrain",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.93",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Trinidad y Tobago",
        "origen": "Todos",
        "destino": "Benín",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.93",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Trinidad y Tobago",
        "origen": "Todos",
        "destino": "Burkina Faso",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.93",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Trinidad y Tobago",
        "origen": "Todos",
        "destino": "Bután",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.93",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Trinidad y Tobago",
        "origen": "Todos",
        "destino": "Camerún",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.93",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Trinidad y Tobago",
        "origen": "Todos",
        "destino": "Costa de Marfil",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.93",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Trinidad y Tobago",
        "origen": "Todos",
        "destino": "Emiratos Árabes Unidos",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.93",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Trinidad y Tobago",
        "origen": "Todos",
        "destino": "Etiopia",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.93",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Trinidad y Tobago",
        "origen": "Todos",
        "destino": "Gabón",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.93",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Trinidad y Tobago",
        "origen": "Todos",
        "destino": "Georgia",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.93",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Trinidad y Tobago",
        "origen": "Todos",
        "destino": "Guinea",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.93",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Trinidad y Tobago",
        "origen": "Todos",
        "destino": "Guinea Ecuatorial",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.93",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Trinidad y Tobago",
        "origen": "Todos",
        "destino": "India",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.93",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Trinidad y Tobago",
        "origen": "Todos",
        "destino": "Indonesia",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.93",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Trinidad y Tobago",
        "origen": "Todos",
        "destino": "Irán",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.93",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Trinidad y Tobago",
        "origen": "Todos",
        "destino": "Kazajistán",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.93",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Trinidad y Tobago",
        "origen": "Todos",
        "destino": "Kirguistán",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.93",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Trinidad y Tobago",
        "origen": "Todos",
        "destino": "Libia",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.93",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Trinidad y Tobago",
        "origen": "Todos",
        "destino": "Mongolia",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.93",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Trinidad y Tobago",
        "origen": "Todos",
        "destino": "Nigeria",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.93",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Trinidad y Tobago",
        "origen": "Todos",
        "destino": "Omán",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.93",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Trinidad y Tobago",
        "origen": "Todos",
        "destino": "Papua Nueva Guinea",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.93",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Trinidad y Tobago",
        "origen": "Todos",
        "destino": "República Democrática del Congo",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.93",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Trinidad y Tobago",
        "origen": "Todos",
        "destino": "Santo Tomé y Príncipe",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.93",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Trinidad y Tobago",
        "origen": "Todos",
        "destino": "Siria",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.93",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Trinidad y Tobago",
        "origen": "Todos",
        "destino": "Sudán del Sur",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.93",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Trinidad y Tobago",
        "origen": "Todos",
        "destino": "Tajikistán",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.93",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Trinidad y Tobago",
        "origen": "Todos",
        "destino": "Togo",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.93",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "Trinidad y Tobago",
        "origen": "Todos",
        "destino": "Uganda",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.93",
        "requisitos": "Visado\nBoleto ida y vuelta\nHospedaje\nAsistencia Médica"
       },
       {
        "pasaporte": "Trinidad y Tobago",
        "origen": "Todos",
        "destino": "Vietnam",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.93",
        "requisitos": "Visado\nBoleto ida y vuelta\nHospedaje\nAsistencia Médica"
       },
       {
        "pasaporte": "Trinidad y Tobago",
        "origen": "Todos",
        "destino": "Afganistán",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado\nBoleto ida y vuelta\nHospedaje\nAsistencia Médica"
       },
       {
        "pasaporte": "Trinidad y Tobago",
        "origen": "Todos",
        "destino": "Arabia Saudí",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado\nBoleto ida y vuelta\nHospedaje\nAsistencia Médica"
       },
       {
        "pasaporte": "Trinidad y Tobago",
        "origen": "Todos",
        "destino": "Argelia",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado\nBoleto ida y vuelta\nHospedaje\nAsistencia Médica"
       },
       {
        "pasaporte": "Trinidad y Tobago",
        "origen": "Todos",
        "destino": "Bielorrusia",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado\nBoleto ida y vuelta\nHospedaje\nAsistencia Médica"
       },
       {
        "pasaporte": "Trinidad y Tobago",
        "origen": "Todos",
        "destino": "Birmania",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado\nBoleto ida y vuelta\nHospedaje\nAsistencia Médica"
       },
       {
        "pasaporte": "Trinidad y Tobago",
        "origen": "Todos",
        "destino": "Brunei",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado\nBoleto ida y vuelta\nHospedaje\nAsistencia Médica"
       },
       {
        "pasaporte": "Trinidad y Tobago",
        "origen": "Todos",
        "destino": "Canadá",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado\nBoleto ida y vuelta\nHospedaje\nAsistencia Médica"
       },
       {
        "pasaporte": "Trinidad y Tobago",
        "origen": "Todos",
        "destino": "Chad",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado\nBoleto ida y vuelta\nHospedaje\nAsistencia Médica"
       },
       {
        "pasaporte": "Trinidad y Tobago",
        "origen": "Todos",
        "destino": "China",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado\nBoleto ida y vuelta\nHospedaje\nAsistencia Médica"
       },
       {
        "pasaporte": "Trinidad y Tobago",
        "origen": "Todos",
        "destino": "Corea del Norte",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado\nBoleto ida y vuelta\nHospedaje\nAsistencia Médica"
       },
       {
        "pasaporte": "Trinidad y Tobago",
        "origen": "Todos",
        "destino": "Cuba",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado\nBoleto ida y vuelta\nHospedaje\nAsistencia Médica"
       },
       {
        "pasaporte": "Trinidad y Tobago",
        "origen": "Todos",
        "destino": "Eritrea",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado\nBoleto ida y vuelta\nHospedaje\nAsistencia Médica"
       },
       {
        "pasaporte": "Trinidad y Tobago",
        "origen": "Todos",
        "destino": "Estados Unidos de América",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado\nBoleto ida y vuelta\nHospedaje\nAsistencia Médica"
       },
       {
        "pasaporte": "Trinidad y Tobago",
        "origen": "Todos",
        "destino": "Falkland Islands",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado\nBoleto ida y vuelta\nHospedaje\nAsistencia Médica"
       },
       {
        "pasaporte": "Trinidad y Tobago",
        "origen": "Todos",
        "destino": "Federación Rusa",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado\nBoleto ida y vuelta\nHospedaje\nAsistencia Médica"
       },
       {
        "pasaporte": "Trinidad y Tobago",
        "origen": "Todos",
        "destino": "Guam",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.93",
        "requisitos": "Visado\nBoleto ida y vuelta\nHospedaje\nAsistencia Médica"
       },
       {
        "pasaporte": "Trinidad y Tobago",
        "origen": "Todos",
        "destino": "Irak",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.93",
        "requisitos": "Visado\nBoleto ida y vuelta\nHospedaje\nAsistencia Médica"
       },
       {
        "pasaporte": "Trinidad y Tobago",
        "origen": "Todos",
        "destino": "Isla Norfolk",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.93",
        "requisitos": "Visado\nBoleto ida y vuelta\nHospedaje\nAsistencia Médica"
       },
       {
        "pasaporte": "Trinidad y Tobago",
        "origen": "Todos",
        "destino": "Islas Marianas del Norte",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.93",
        "requisitos": "Visado\nBoleto ida y vuelta\nHospedaje\nAsistencia Médica"
       },
       {
        "pasaporte": "Trinidad y Tobago",
        "origen": "Todos",
        "destino": "Islas Marshall",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.93",
        "requisitos": "Visado\nBoleto ida y vuelta\nHospedaje\nAsistencia Médica"
       },
       {
        "pasaporte": "Trinidad y Tobago",
        "origen": "Todos",
        "destino": "Islas Vírgenes americanas",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.93",
        "requisitos": "Visado\nBoleto ida y vuelta\nHospedaje\nAsistencia Médica"
       },
       {
        "pasaporte": "Trinidad y Tobago",
        "origen": "Todos",
        "destino": "Japón",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.93",
        "requisitos": "Visado\nBoleto ida y vuelta\nHospedaje\nAsistencia Médica"
       },
       {
        "pasaporte": "Trinidad y Tobago",
        "origen": "Todos",
        "destino": "Kuwait",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado\nBoleto ida y vuelta\nHospedaje\nAsistencia Médica"
       },
       {
        "pasaporte": "Trinidad y Tobago",
        "origen": "Todos",
        "destino": "Líbano",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado\nBoleto ida y vuelta\nHospedaje\nAsistencia Médica"
       },
       {
        "pasaporte": "Trinidad y Tobago",
        "origen": "Todos",
        "destino": "Liberia",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado\nBoleto ida y vuelta\nHospedaje\nAsistencia Médica"
       },
       {
        "pasaporte": "Trinidad y Tobago",
        "origen": "Todos",
        "destino": "Macedonia del Norte",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado\nBoleto ida y vuelta\nHospedaje\nAsistencia Médica"
       },
       {
        "pasaporte": "Trinidad y Tobago",
        "origen": "Todos",
        "destino": "Mali",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado\nBoleto ida y vuelta\nHospedaje\nAsistencia Médica"
       },
       {
        "pasaporte": "Trinidad y Tobago",
        "origen": "Todos",
        "destino": "Marruecos",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado\nBoleto ida y vuelta\nHospedaje\nAsistencia Médica"
       },
       {
        "pasaporte": "Trinidad y Tobago",
        "origen": "Todos",
        "destino": "Namibia",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado\nBoleto ida y vuelta\nHospedaje\nAsistencia Médica"
       },
       {
        "pasaporte": "Trinidad y Tobago",
        "origen": "Todos",
        "destino": "Nauru",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado\nBoleto ida y vuelta\nHospedaje\nAsistencia Médica"
       },
       {
        "pasaporte": "Trinidad y Tobago",
        "origen": "Todos",
        "destino": "Níger",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado\nBoleto ida y vuelta\nHospedaje\nAsistencia Médica"
       },
       {
        "pasaporte": "Trinidad y Tobago",
        "origen": "Todos",
        "destino": "Nueva Zelanda",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado\nBoleto ida y vuelta\nHospedaje\nAsistencia Médica"
       },
       {
        "pasaporte": "Trinidad y Tobago",
        "origen": "Todos",
        "destino": "Paraguay",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado\nBoleto ida y vuelta\nHospedaje\nAsistencia Médica"
       },
       {
        "pasaporte": "Trinidad y Tobago",
        "origen": "Todos",
        "destino": "Puerto Rico",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado\nBoleto ida y vuelta\nHospedaje\nAsistencia Médica"
       },
       {
        "pasaporte": "Trinidad y Tobago",
        "origen": "Todos",
        "destino": "Qatar",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado\nBoleto ida y vuelta\nHospedaje\nAsistencia Médica"
       },
       {
        "pasaporte": "Trinidad y Tobago",
        "origen": "Todos",
        "destino": "República Centroafricana",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado\nBoleto ida y vuelta\nHospedaje\nAsistencia Médica"
       },
       {
        "pasaporte": "Trinidad y Tobago",
        "origen": "Todos",
        "destino": "República del Congo",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado\nBoleto ida y vuelta\nHospedaje\nAsistencia Médica"
       },
       {
        "pasaporte": "Trinidad y Tobago",
        "origen": "Todos",
        "destino": "Samoa Americana",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.93",
        "requisitos": "Visado\nBoleto ida y vuelta\nHospedaje\nAsistencia Médica"
       },
       {
        "pasaporte": "Trinidad y Tobago",
        "origen": "Todos",
        "destino": "Somalia",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.93",
        "requisitos": "Visado\nBoleto ida y vuelta\nHospedaje\nAsistencia Médica"
       },
       {
        "pasaporte": "Trinidad y Tobago",
        "origen": "Todos",
        "destino": "Sudán",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.93",
        "requisitos": "Visado\nBoleto ida y vuelta\nHospedaje\nAsistencia Médica"
       },
       {
        "pasaporte": "Trinidad y Tobago",
        "origen": "Todos",
        "destino": "Taiwán",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.93",
        "requisitos": "Visado\nBoleto ida y vuelta\nHospedaje\nAsistencia Médica"
       },
       {
        "pasaporte": "Trinidad y Tobago",
        "origen": "Todos",
        "destino": "Tonga",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.93",
        "requisitos": "Visado\nBoleto ida y vuelta\nHospedaje\nAsistencia Médica"
       },
       {
        "pasaporte": "Trinidad y Tobago",
        "origen": "Todos",
        "destino": "Túnez",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.93",
        "requisitos": "Visado\nBoleto ida y vuelta\nHospedaje\nAsistencia Médica"
       },
       {
        "pasaporte": "Trinidad y Tobago",
        "origen": "Todos",
        "destino": "Turkmenistán",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.93",
        "requisitos": "Visado\nBoleto ida y vuelta\nHospedaje\nAsistencia Médica"
       },
       {
        "pasaporte": "Trinidad y Tobago",
        "origen": "Todos",
        "destino": "Ucrania",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado\nBoleto ida y vuelta\nHospedaje\nAsistencia Médica"
       },
       {
        "pasaporte": "Trinidad y Tobago",
        "origen": "Todos",
        "destino": "Yemen",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado\nBoleto ida y vuelta\nHospedaje\nAsistencia Médica"
       },
       {
        "pasaporte": "El Salvador",
        "origen": "Todos",
        "destino": "Albania",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "El Salvador",
        "origen": "Todos",
        "destino": "Alemania",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "El Salvador",
        "origen": "Todos",
        "destino": "Andorra",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "El Salvador",
        "origen": "Todos",
        "destino": "Anguilla",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "El Salvador",
        "origen": "Todos",
        "destino": "Argentina",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "El Salvador",
        "origen": "Todos",
        "destino": "Aruba",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "El Salvador",
        "origen": "Todos",
        "destino": "Austria",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "El Salvador",
        "origen": "Todos",
        "destino": "Bahamas",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "El Salvador",
        "origen": "Todos",
        "destino": "Barbados",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "El Salvador",
        "origen": "Todos",
        "destino": "Bélgica",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "El Salvador",
        "origen": "Todos",
        "destino": "Belice",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "El Salvador",
        "origen": "Todos",
        "destino": "Bermuda",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "El Salvador",
        "origen": "Todos",
        "destino": "Bielorrusia",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "El Salvador",
        "origen": "Todos",
        "destino": "Bonaire, San Eustaquio y Saba",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "El Salvador",
        "origen": "Todos",
        "destino": "Bosnia-Herzegovina",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "El Salvador",
        "origen": "Todos",
        "destino": "Brasil",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "El Salvador",
        "origen": "Todos",
        "destino": "Bulgaria",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "El Salvador",
        "origen": "Todos",
        "destino": "Chile",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "El Salvador",
        "origen": "Todos",
        "destino": "Chipre",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "El Salvador",
        "origen": "Todos",
        "destino": "Ciudad del Vaticano",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "El Salvador",
        "origen": "Todos",
        "destino": "Colombia",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "El Salvador",
        "origen": "Todos",
        "destino": "Costa Rica",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "El Salvador",
        "origen": "Todos",
        "destino": "Croacia",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "El Salvador",
        "origen": "Todos",
        "destino": "Curaçao",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "El Salvador",
        "origen": "Todos",
        "destino": "Dinamarca",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "El Salvador",
        "origen": "Todos",
        "destino": "Dominica",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "El Salvador",
        "origen": "Todos",
        "destino": "Ecuador",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "El Salvador",
        "origen": "Todos",
        "destino": "Emiratos Árabes Unidos",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "El Salvador",
        "origen": "Todos",
        "destino": "Eslovaquia",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "El Salvador",
        "origen": "Todos",
        "destino": "Eslovenia",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "El Salvador",
        "origen": "Todos",
        "destino": "España",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "El Salvador",
        "origen": "Todos",
        "destino": "Estonia",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "El Salvador",
        "origen": "Todos",
        "destino": "Federación Rusa",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "El Salvador",
        "origen": "Todos",
        "destino": "Filipinas",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "El Salvador",
        "origen": "Todos",
        "destino": "Finlandia",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "El Salvador",
        "origen": "Todos",
        "destino": "Francia",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "El Salvador",
        "origen": "Todos",
        "destino": "Georgia",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "El Salvador",
        "origen": "Todos",
        "destino": "Gibraltar",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "El Salvador",
        "origen": "Todos",
        "destino": "Granada",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "El Salvador",
        "origen": "Todos",
        "destino": "Grecia",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "El Salvador",
        "origen": "Todos",
        "destino": "Groenlandia",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "El Salvador",
        "origen": "Todos",
        "destino": "Guatemala",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "El Salvador",
        "origen": "Todos",
        "destino": "Guayana Francesa",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "El Salvador",
        "origen": "Todos",
        "destino": "Haití",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "El Salvador",
        "origen": "Todos",
        "destino": "Honduras",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "El Salvador",
        "origen": "Todos",
        "destino": "Hong Kong",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "El Salvador",
        "origen": "Todos",
        "destino": "Hungría",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "El Salvador",
        "origen": "Todos",
        "destino": "Indias Occidentales Francesas",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "El Salvador",
        "origen": "Todos",
        "destino": "Irlanda",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "El Salvador",
        "origen": "Todos",
        "destino": "Isla Reunión",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "El Salvador",
        "origen": "Todos",
        "destino": "Islandia",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "El Salvador",
        "origen": "Todos",
        "destino": "Islas Cook",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "El Salvador",
        "origen": "Todos",
        "destino": "Islas Feroe",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "El Salvador",
        "origen": "Todos",
        "destino": "Islas Vírgenes Británicas",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "El Salvador",
        "origen": "Todos",
        "destino": "Israel",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "El Salvador",
        "origen": "Todos",
        "destino": "Italia",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "El Salvador",
        "origen": "Todos",
        "destino": "Jamaica",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "El Salvador",
        "origen": "Todos",
        "destino": "Japón",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "El Salvador",
        "origen": "Todos",
        "destino": "Kiribati",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "El Salvador",
        "origen": "Todos",
        "destino": "Kosovo",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "El Salvador",
        "origen": "Todos",
        "destino": "Letonia",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "El Salvador",
        "origen": "Todos",
        "destino": "Liechtenstein",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "El Salvador",
        "origen": "Todos",
        "destino": "Lituania",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "El Salvador",
        "origen": "Todos",
        "destino": "Luxemburgo",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "El Salvador",
        "origen": "Todos",
        "destino": "Macedonia del Norte",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "El Salvador",
        "origen": "Todos",
        "destino": "Malasia",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "El Salvador",
        "origen": "Todos",
        "destino": "Malta",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "El Salvador",
        "origen": "Todos",
        "destino": "Mayotte",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "El Salvador",
        "origen": "Todos",
        "destino": "Micronesia",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "El Salvador",
        "origen": "Todos",
        "destino": "Moldavia",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "El Salvador",
        "origen": "Todos",
        "destino": "Mónaco",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "El Salvador",
        "origen": "Todos",
        "destino": "Montenegro",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "El Salvador",
        "origen": "Todos",
        "destino": "Nicaragua",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "El Salvador",
        "origen": "Todos",
        "destino": "Noruega",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "El Salvador",
        "origen": "Todos",
        "destino": "Nueva Caledonia",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "El Salvador",
        "origen": "Todos",
        "destino": "Países Bajos",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "El Salvador",
        "origen": "Todos",
        "destino": "Panamá",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "El Salvador",
        "origen": "Todos",
        "destino": "Paraguay",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "El Salvador",
        "origen": "Todos",
        "destino": "Perú",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "El Salvador",
        "origen": "Todos",
        "destino": "Polinesia Francesa",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "El Salvador",
        "origen": "Todos",
        "destino": "Polonia",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "El Salvador",
        "origen": "Todos",
        "destino": "Portugal",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "El Salvador",
        "origen": "Todos",
        "destino": "República Checa",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "El Salvador",
        "origen": "Todos",
        "destino": "República Dominicana",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "El Salvador",
        "origen": "Todos",
        "destino": "Rumanía",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "El Salvador",
        "origen": "Todos",
        "destino": "San Cristóbal y Nieves",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "El Salvador",
        "origen": "Todos",
        "destino": "San Marino",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "El Salvador",
        "origen": "Todos",
        "destino": "San Martín",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "El Salvador",
        "origen": "Todos",
        "destino": "San Pedro y Miquelón",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "El Salvador",
        "origen": "Todos",
        "destino": "San Vicente y las Granadinas",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "El Salvador",
        "origen": "Todos",
        "destino": "Santa Elena",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "El Salvador",
        "origen": "Todos",
        "destino": "Singapur",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "El Salvador",
        "origen": "Todos",
        "destino": "Suecia",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "El Salvador",
        "origen": "Todos",
        "destino": "Suiza",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "El Salvador",
        "origen": "Todos",
        "destino": "Surinam",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "El Salvador",
        "origen": "Todos",
        "destino": "Territorios Palestinos",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "El Salvador",
        "origen": "Todos",
        "destino": "Trinidad y Tobago",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "El Salvador",
        "origen": "Todos",
        "destino": "Turquía",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "El Salvador",
        "origen": "Todos",
        "destino": "Uruguay",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "El Salvador",
        "origen": "Todos",
        "destino": "Uzbekistán",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "El Salvador",
        "origen": "Todos",
        "destino": "Wallis y Futuna",
        "tipo": "turismo",
        "visado": "NO",
        "requisitos": "Asistencia médica de viajes. \nBoleto aereo de regreso\nReserva de hotel o carta de invitación\nPasaporte vigente"
       },
       {
        "pasaporte": "El Salvador",
        "origen": "Todos",
        "destino": "Bangladesh",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.97",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "El Salvador",
        "origen": "Todos",
        "destino": "Bolivia",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.97",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "El Salvador",
        "origen": "Todos",
        "destino": "Burundi",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.97",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "El Salvador",
        "origen": "Todos",
        "destino": "Cabo Verde",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.97",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "El Salvador",
        "origen": "Todos",
        "destino": "Camboya",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.97",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "El Salvador",
        "origen": "Todos",
        "destino": "Comoras",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.97",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "El Salvador",
        "origen": "Todos",
        "destino": "Djibouti",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.97",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "El Salvador",
        "origen": "Todos",
        "destino": "Egipto",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.97",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "El Salvador",
        "origen": "Todos",
        "destino": "Guinea-Bissau",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.97",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "El Salvador",
        "origen": "Todos",
        "destino": "Isla Mauricio",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.97",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "El Salvador",
        "origen": "Todos",
        "destino": "Islas Seychelles",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.97",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "El Salvador",
        "origen": "Todos",
        "destino": "Jordania",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.97",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "El Salvador",
        "origen": "Todos",
        "destino": "Laos",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.97",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "El Salvador",
        "origen": "Todos",
        "destino": "Macao",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.97",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "El Salvador",
        "origen": "Todos",
        "destino": "Madagascar",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.97",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "El Salvador",
        "origen": "Todos",
        "destino": "Malawi",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.97",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "El Salvador",
        "origen": "Todos",
        "destino": "Maldivas",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.97",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "El Salvador",
        "origen": "Todos",
        "destino": "Mauritania",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.97",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "El Salvador",
        "origen": "Todos",
        "destino": "Mozambique",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.97",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "El Salvador",
        "origen": "Todos",
        "destino": "Nepal",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.97",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "El Salvador",
        "origen": "Todos",
        "destino": "Niue",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.97",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "El Salvador",
        "origen": "Todos",
        "destino": "Palaos",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.97",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "El Salvador",
        "origen": "Todos",
        "destino": "Ruanda",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.97",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "El Salvador",
        "origen": "Todos",
        "destino": "Samoa",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.97",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "El Salvador",
        "origen": "Todos",
        "destino": "Santa Lucía",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.97",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "El Salvador",
        "origen": "Todos",
        "destino": "Senegal",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.97",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "El Salvador",
        "origen": "Todos",
        "destino": "Sri Lanka",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.97",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "El Salvador",
        "origen": "Todos",
        "destino": "Tailandia",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.97",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "El Salvador",
        "origen": "Todos",
        "destino": "Tanzania",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.97",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "El Salvador",
        "origen": "Todos",
        "destino": "Timor Oriental",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.97",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "El Salvador",
        "origen": "Todos",
        "destino": "Tuvalu",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.97",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "El Salvador",
        "origen": "Todos",
        "destino": "Zambia",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.97",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "El Salvador",
        "origen": "Todos",
        "destino": "Zimbabue",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.97",
        "requisitos": "Visado \nPasaporte vigente"
       },
       {
        "pasaporte": "El Salvador",
        "origen": "Todos",
        "destino": "Corea del Sur",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.97",
        "requisitos": "Autorización ETA\nPasaporte vigente"
       },
       {
        "pasaporte": "El Salvador",
        "origen": "Todos",
        "destino": "Kenias",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.97",
        "requisitos": "Autorización ETA\nPasaporte vigente"
       },
       {
        "pasaporte": "El Salvador",
        "origen": "Todos",
        "destino": "Antigua y Barbuda",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.95",
        "requisitos": "Visado\nBoleto ida y vuelta\nHospedaje\nAsistencia Médica"
       },
       {
        "pasaporte": "El Salvador",
        "origen": "Todos",
        "destino": "Armenia",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.95",
        "requisitos": "Visado\nBoleto ida y vuelta\nHospedaje\nAsistencia Médica"
       },
       {
        "pasaporte": "El Salvador",
        "origen": "Todos",
        "destino": "Australia",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.95",
        "requisitos": "Visado\nBoleto ida y vuelta\nHospedaje\nAsistencia Médica"
       },
       {
        "pasaporte": "El Salvador",
        "origen": "Todos",
        "destino": "Bahrain",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.95",
        "requisitos": "Visado\nBoleto ida y vuelta\nHospedaje\nAsistencia Médica"
       },
       {
        "pasaporte": "El Salvador",
        "origen": "Todos",
        "destino": "Benín",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.95",
        "requisitos": "Visado\nBoleto ida y vuelta\nHospedaje\nAsistencia Médica"
       },
       {
        "pasaporte": "El Salvador",
        "origen": "Todos",
        "destino": "Botswana",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.95",
        "requisitos": "Visado\nBoleto ida y vuelta\nHospedaje\nAsistencia Médica"
       },
       {
        "pasaporte": "El Salvador",
        "origen": "Todos",
        "destino": "Burkina Faso",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.95",
        "requisitos": "Visado\nBoleto ida y vuelta\nHospedaje\nAsistencia Médica"
       },
       {
        "pasaporte": "El Salvador",
        "origen": "Todos",
        "destino": "Bután",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.95",
        "requisitos": "Visado\nBoleto ida y vuelta\nHospedaje\nAsistencia Médica"
       },
       {
        "pasaporte": "El Salvador",
        "origen": "Todos",
        "destino": "Camerún",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.95",
        "requisitos": "Visado\nBoleto ida y vuelta\nHospedaje\nAsistencia Médica"
       },
       {
        "pasaporte": "El Salvador",
        "origen": "Todos",
        "destino": "Costa de Marfil",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.95",
        "requisitos": "Visado\nBoleto ida y vuelta\nHospedaje\nAsistencia Médica"
       },
       {
        "pasaporte": "El Salvador",
        "origen": "Todos",
        "destino": "Etiopía",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.95",
        "requisitos": "Visado\nBoleto ida y vuelta\nHospedaje\nAsistencia Médica"
       },
       {
        "pasaporte": "El Salvador",
        "origen": "Todos",
        "destino": "Gabón",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.95",
        "requisitos": "Visado\nBoleto ida y vuelta\nHospedaje\nAsistencia Médica"
       },
       {
        "pasaporte": "El Salvador",
        "origen": "Todos",
        "destino": "Guinea",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.95",
        "requisitos": "Visado\nBoleto ida y vuelta\nHospedaje\nAsistencia Médica"
       },
       {
        "pasaporte": "El Salvador",
        "origen": "Todos",
        "destino": "Guinea Ecuatorial",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.95",
        "requisitos": "Visado\nBoleto ida y vuelta\nHospedaje\nAsistencia Médica"
       },
       {
        "pasaporte": "El Salvador",
        "origen": "Todos",
        "destino": "India",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.95",
        "requisitos": "Visado\nBoleto ida y vuelta\nHospedaje\nAsistencia Médica"
       },
       {
        "pasaporte": "El Salvador",
        "origen": "Todos",
        "destino": "Indonesia",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.95",
        "requisitos": "Visado\nBoleto ida y vuelta\nHospedaje\nAsistencia Médica"
       },
       {
        "pasaporte": "El Salvador",
        "origen": "Todos",
        "destino": "Irán",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.95",
        "requisitos": "Visado\nBoleto ida y vuelta\nHospedaje\nAsistencia Médica"
       },
       {
        "pasaporte": "El Salvador",
        "origen": "Todos",
        "destino": "Kazajistán",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.95",
        "requisitos": "Visado\nBoleto ida y vuelta\nHospedaje\nAsistencia Médica"
       },
       {
        "pasaporte": "El Salvador",
        "origen": "Todos",
        "destino": "Kirguistán",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.95",
        "requisitos": "Visado\nBoleto ida y vuelta\nHospedaje\nAsistencia Médica"
       },
       {
        "pasaporte": "El Salvador",
        "origen": "Todos",
        "destino": "Lesoto",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.95",
        "requisitos": "Visado\nBoleto ida y vuelta\nHospedaje\nAsistencia Médica"
       },
       {
        "pasaporte": "El Salvador",
        "origen": "Todos",
        "destino": "Libia",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.95",
        "requisitos": "Visado\nBoleto ida y vuelta\nHospedaje\nAsistencia Médica"
       },
       {
        "pasaporte": "El Salvador",
        "origen": "Todos",
        "destino": "Mongolia",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.95",
        "requisitos": "Visado\nBoleto ida y vuelta\nHospedaje\nAsistencia Médica"
       },
       {
        "pasaporte": "El Salvador",
        "origen": "Todos",
        "destino": "Montserrat",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.95",
        "requisitos": "Visado\nBoleto ida y vuelta\nHospedaje\nAsistencia Médica"
       },
       {
        "pasaporte": "El Salvador",
        "origen": "Todos",
        "destino": "Nigeria",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.95",
        "requisitos": "Visado\nBoleto ida y vuelta\nHospedaje\nAsistencia Médica"
       },
       {
        "pasaporte": "El Salvador",
        "origen": "Todos",
        "destino": "Omán",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.95",
        "requisitos": "Visado\nBoleto ida y vuelta\nHospedaje\nAsistencia Médica"
       },
       {
        "pasaporte": "El Salvador",
        "origen": "Todos",
        "destino": "Pakistán",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.95",
        "requisitos": "Visado\nBoleto ida y vuelta\nHospedaje\nAsistencia Médica"
       },
       {
        "pasaporte": "El Salvador",
        "origen": "Todos",
        "destino": "Papua Nueva Guinea",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.95",
        "requisitos": "Visado\nBoleto ida y vuelta\nHospedaje\nAsistencia Médica"
       },
       {
        "pasaporte": "El Salvador",
        "origen": "Todos",
        "destino": "Qatar",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.95",
        "requisitos": "Visado\nBoleto ida y vuelta\nHospedaje\nAsistencia Médica"
       },
       {
        "pasaporte": "El Salvador",
        "origen": "Todos",
        "destino": "República Democrática del Congo",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.95",
        "requisitos": "Visado\nBoleto ida y vuelta\nHospedaje\nAsistencia Médica"
       },
       {
        "pasaporte": "El Salvador",
        "origen": "Todos",
        "destino": "Santo Tomé y Príncipe",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.95",
        "requisitos": "Visado\nBoleto ida y vuelta\nHospedaje\nAsistencia Médica"
       },
       {
        "pasaporte": "El Salvador",
        "origen": "Todos",
        "destino": "Sierra Leona",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.95",
        "requisitos": "Visado\nBoleto ida y vuelta\nHospedaje\nAsistencia Médica"
       },
       {
        "pasaporte": "El Salvador",
        "origen": "Todos",
        "destino": "Siria",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.95",
        "requisitos": "Visado\nBoleto ida y vuelta\nHospedaje\nAsistencia Médica"
       },
       {
        "pasaporte": "El Salvador",
        "origen": "Todos",
        "destino": "Sudán del Sur",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.95",
        "requisitos": "Visado\nBoleto ida y vuelta\nHospedaje\nAsistencia Médica"
       },
       {
        "pasaporte": "El Salvador",
        "origen": "Todos",
        "destino": "Tajikistán",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.95",
        "requisitos": "Visado\nBoleto ida y vuelta\nHospedaje\nAsistencia Médica"
       },
       {
        "pasaporte": "El Salvador",
        "origen": "Todos",
        "destino": "Togo",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.95",
        "requisitos": "Visado\nBoleto ida y vuelta\nHospedaje\nAsistencia Médica"
       },
       {
        "pasaporte": "El Salvador",
        "origen": "Todos",
        "destino": "Uganda",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.95",
        "requisitos": "Visado\nBoleto ida y vuelta\nHospedaje\nAsistencia Médica"
       },
       {
        "pasaporte": "El Salvador",
        "origen": "Todos",
        "destino": "Vietnam",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.95",
        "requisitos": "Visado\nBoleto ida y vuelta\nHospedaje\nAsistencia Médica"
       },
       {
        "pasaporte": "El Salvador",
        "origen": "Todos",
        "destino": "Afganistán",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado\nPasaporte vigente\nAsistencia Médica de Viaje\nReserva de vuelo\nReserva de hospedaje\nSolvencia económica"
       },
       {
        "pasaporte": "El Salvador",
        "origen": "Todos",
        "destino": "Angola",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado\nPasaporte vigente\nAsistencia Médica de Viaje\nReserva de vuelo\nReserva de hospedaje\nSolvencia económica"
       },
       {
        "pasaporte": "El Salvador",
        "origen": "Todos",
        "destino": "Arabia Saudí",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado\nPasaporte vigente\nAsistencia Médica de Viaje\nReserva de vuelo\nReserva de hospedaje\nSolvencia económica"
       },
       {
        "pasaporte": "El Salvador",
        "origen": "Todos",
        "destino": "Argelia",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado\nPasaporte vigente\nAsistencia Médica de Viaje\nReserva de vuelo\nReserva de hospedaje\nSolvencia económica"
       },
       {
        "pasaporte": "El Salvador",
        "origen": "Todos",
        "destino": "Azerbaiyán",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado\nPasaporte vigente\nAsistencia Médica de Viaje\nReserva de vuelo\nReserva de hospedaje\nSolvencia económica"
       },
       {
        "pasaporte": "El Salvador",
        "origen": "Todos",
        "destino": "Birmania",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado\nPasaporte vigente\nAsistencia Médica de Viaje\nReserva de vuelo\nReserva de hospedaje\nSolvencia económica"
       },
       {
        "pasaporte": "El Salvador",
        "origen": "Todos",
        "destino": "Brunei",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado\nPasaporte vigente\nAsistencia Médica de Viaje\nReserva de vuelo\nReserva de hospedaje\nSolvencia económica"
       },
       {
        "pasaporte": "El Salvador",
        "origen": "Todos",
        "destino": "Canadá",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado\nPasaporte vigente\nAsistencia Médica de Viaje\nReserva de vuelo\nReserva de hospedaje\nSolvencia económica"
       },
       {
        "pasaporte": "El Salvador",
        "origen": "Todos",
        "destino": "Chad",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado\nPasaporte vigente\nAsistencia Médica de Viaje\nReserva de vuelo\nReserva de hospedaje\nSolvencia económica"
       },
       {
        "pasaporte": "El Salvador",
        "origen": "Todos",
        "destino": "China",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado\nPasaporte vigente\nAsistencia Médica de Viaje\nReserva de vuelo\nReserva de hospedaje\nSolvencia económica"
       },
       {
        "pasaporte": "El Salvador",
        "origen": "Todos",
        "destino": "Corea del Norte",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado\nPasaporte vigente\nAsistencia Médica de Viaje\nReserva de vuelo\nReserva de hospedaje\nSolvencia económica"
       },
       {
        "pasaporte": "El Salvador",
        "origen": "Todos",
        "destino": "Cuba",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado\nPasaporte vigente\nAsistencia Médica de Viaje\nReserva de vuelo\nReserva de hospedaje\nSolvencia económica"
       },
       {
        "pasaporte": "El Salvador",
        "origen": "Todos",
        "destino": "Eritrea",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado\nPasaporte vigente\nAsistencia Médica de Viaje\nReserva de vuelo\nReserva de hospedaje\nSolvencia económica"
       },
       {
        "pasaporte": "El Salvador",
        "origen": "Todos",
        "destino": "Estados Unidos de América",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado\nPasaporte vigente\nAsistencia Médica de Viaje\nReserva de vuelo\nReserva de hospedaje\nSolvencia económica"
       },
       {
        "pasaporte": "El Salvador",
        "origen": "Todos",
        "destino": "Eswatini",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado\nPasaporte vigente\nAsistencia Médica de Viaje\nReserva de vuelo\nReserva de hospedaje\nSolvencia económica"
       },
       {
        "pasaporte": "El Salvador",
        "origen": "Todos",
        "destino": "Falkland Islands",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado\nPasaporte vigente\nAsistencia Médica de Viaje\nReserva de vuelo\nReserva de hospedaje\nSolvencia económica"
       },
       {
        "pasaporte": "El Salvador",
        "origen": "Todos",
        "destino": "Fiji",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado\nPasaporte vigente\nAsistencia Médica de Viaje\nReserva de vuelo\nReserva de hospedaje\nSolvencia económica"
       },
       {
        "pasaporte": "El Salvador",
        "origen": "Todos",
        "destino": "Gambia",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado\nPasaporte vigente\nAsistencia Médica de Viaje\nReserva de vuelo\nReserva de hospedaje\nSolvencia económica"
       },
       {
        "pasaporte": "El Salvador",
        "origen": "Todos",
        "destino": "Ghana",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado\nPasaporte vigente\nAsistencia Médica de Viaje\nReserva de vuelo\nReserva de hospedaje\nSolvencia económica"
       },
       {
        "pasaporte": "El Salvador",
        "origen": "Todos",
        "destino": "Guam",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado\nPasaporte vigente\nAsistencia Médica de Viaje\nReserva de vuelo\nReserva de hospedaje\nSolvencia económica"
       },
       {
        "pasaporte": "El Salvador",
        "origen": "Todos",
        "destino": "Guyana",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado\nPasaporte vigente\nAsistencia Médica de Viaje\nReserva de vuelo\nReserva de hospedaje\nSolvencia económica"
       },
       {
        "pasaporte": "El Salvador",
        "origen": "Todos",
        "destino": "Irak",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado\nPasaporte vigente\nAsistencia Médica de Viaje\nReserva de vuelo\nReserva de hospedaje\nSolvencia económica"
       },
       {
        "pasaporte": "El Salvador",
        "origen": "Todos",
        "destino": "Isla Norfolk",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado\nPasaporte vigente\nAsistencia Médica de Viaje\nReserva de vuelo\nReserva de hospedaje\nSolvencia económica"
       },
       {
        "pasaporte": "El Salvador",
        "origen": "Todos",
        "destino": "Islas Caimán",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado\nPasaporte vigente\nAsistencia Médica de Viaje\nReserva de vuelo\nReserva de hospedaje\nSolvencia económica"
       },
       {
        "pasaporte": "El Salvador",
        "origen": "Todos",
        "destino": "Islas Marianas del Norte",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado\nPasaporte vigente\nAsistencia Médica de Viaje\nReserva de vuelo\nReserva de hospedaje\nSolvencia económica"
       },
       {
        "pasaporte": "El Salvador",
        "origen": "Todos",
        "destino": "Islas Marshall",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado\nPasaporte vigente\nAsistencia Médica de Viaje\nReserva de vuelo\nReserva de hospedaje\nSolvencia económica"
       },
       {
        "pasaporte": "El Salvador",
        "origen": "Todos",
        "destino": "Islas Salomón",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado\nPasaporte vigente\nAsistencia Médica de Viaje\nReserva de vuelo\nReserva de hospedaje\nSolvencia económica"
       },
       {
        "pasaporte": "El Salvador",
        "origen": "Todos",
        "destino": "Islas Turcas y Caicos",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado\nPasaporte vigente\nAsistencia Médica de Viaje\nReserva de vuelo\nReserva de hospedaje\nSolvencia económica"
       },
       {
        "pasaporte": "El Salvador",
        "origen": "Todos",
        "destino": "Islas Vírgenes americanas",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado\nPasaporte vigente\nAsistencia Médica de Viaje\nReserva de vuelo\nReserva de hospedaje\nSolvencia económica"
       },
       {
        "pasaporte": "El Salvador",
        "origen": "Todos",
        "destino": "Kuwait",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado\nPasaporte vigente\nAsistencia Médica de Viaje\nReserva de vuelo\nReserva de hospedaje\nSolvencia económica"
       },
       {
        "pasaporte": "El Salvador",
        "origen": "Todos",
        "destino": "Líbano",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado\nPasaporte vigente\nAsistencia Médica de Viaje\nReserva de vuelo\nReserva de hospedaje\nSolvencia económica"
       },
       {
        "pasaporte": "El Salvador",
        "origen": "Todos",
        "destino": "Liberia",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado\nPasaporte vigente\nAsistencia Médica de Viaje\nReserva de vuelo\nReserva de hospedaje\nSolvencia económica"
       },
       {
        "pasaporte": "El Salvador",
        "origen": "Todos",
        "destino": "Mali",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado\nPasaporte vigente\nAsistencia Médica de Viaje\nReserva de vuelo\nReserva de hospedaje\nSolvencia económica"
       },
       {
        "pasaporte": "El Salvador",
        "origen": "Todos",
        "destino": "Marruecos",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado\nPasaporte vigente\nAsistencia Médica de Viaje\nReserva de vuelo\nReserva de hospedaje\nSolvencia económica"
       },
       {
        "pasaporte": "El Salvador",
        "origen": "Todos",
        "destino": "México",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado\nPasaporte vigente\nAsistencia Médica de Viaje\nReserva de vuelo\nReserva de hospedaje\nSolvencia económica"
       },
       {
        "pasaporte": "El Salvador",
        "origen": "Todos",
        "destino": "Namibia",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado\nPasaporte vigente\nAsistencia Médica de Viaje\nReserva de vuelo\nReserva de hospedaje\nSolvencia económica"
       },
       {
        "pasaporte": "El Salvador",
        "origen": "Todos",
        "destino": "Nauru",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado\nPasaporte vigente\nAsistencia Médica de Viaje\nReserva de vuelo\nReserva de hospedaje\nSolvencia económica"
       },
       {
        "pasaporte": "El Salvador",
        "origen": "Todos",
        "destino": "Níger",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado\nPasaporte vigente\nAsistencia Médica de Viaje\nReserva de vuelo\nReserva de hospedaje\nSolvencia económica"
       },
       {
        "pasaporte": "El Salvador",
        "origen": "Todos",
        "destino": "Nueva Zelanda",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado\nPasaporte vigente\nAsistencia Médica de Viaje\nReserva de vuelo\nReserva de hospedaje\nSolvencia económica"
       },
       {
        "pasaporte": "El Salvador",
        "origen": "Todos",
        "destino": "Puerto Rico",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado\nPasaporte vigente\nAsistencia Médica de Viaje\nReserva de vuelo\nReserva de hospedaje\nSolvencia económica"
       },
       {
        "pasaporte": "El Salvador",
        "origen": "Todos",
        "destino": "Reino Unido",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado\nPasaporte vigente\nAsistencia Médica de Viaje\nReserva de vuelo\nReserva de hospedaje\nSolvencia económica"
       },
       {
        "pasaporte": "El Salvador",
        "origen": "Todos",
        "destino": "República Centroafricana",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado\nPasaporte vigente\nAsistencia Médica de Viaje\nReserva de vuelo\nReserva de hospedaje\nSolvencia económica"
       },
       {
        "pasaporte": "El Salvador",
        "origen": "Todos",
        "destino": "República del Congo",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado\nPasaporte vigente\nAsistencia Médica de Viaje\nReserva de vuelo\nReserva de hospedaje\nSolvencia económica"
       },
       {
        "pasaporte": "El Salvador",
        "origen": "Todos",
        "destino": "Samoa Americana",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado\nPasaporte vigente\nAsistencia Médica de Viaje\nReserva de vuelo\nReserva de hospedaje\nSolvencia económica"
       },
       {
        "pasaporte": "El Salvador",
        "origen": "Todos",
        "destino": "Serbia",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado\nPasaporte vigente\nAsistencia Médica de Viaje\nReserva de vuelo\nReserva de hospedaje\nSolvencia económica"
       },
       {
        "pasaporte": "El Salvador",
        "origen": "Todos",
        "destino": "Somalia",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado\nPasaporte vigente\nAsistencia Médica de Viaje\nReserva de vuelo\nReserva de hospedaje\nSolvencia económica"
       },
       {
        "pasaporte": "El Salvador",
        "origen": "Todos",
        "destino": "Sudáfrica",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado\nPasaporte vigente\nAsistencia Médica de Viaje\nReserva de vuelo\nReserva de hospedaje\nSolvencia económica"
       },
       {
        "pasaporte": "El Salvador",
        "origen": "Todos",
        "destino": "Sudán",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado\nPasaporte vigente\nAsistencia Médica de Viaje\nReserva de vuelo\nReserva de hospedaje\nSolvencia económica"
       },
       {
        "pasaporte": "El Salvador",
        "origen": "Todos",
        "destino": "Taiwán",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado\nPasaporte vigente\nAsistencia Médica de Viaje\nReserva de vuelo\nReserva de hospedaje\nSolvencia económica"
       },
       {
        "pasaporte": "El Salvador",
        "origen": "Todos",
        "destino": "Tonga",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado\nPasaporte vigente\nAsistencia Médica de Viaje\nReserva de vuelo\nReserva de hospedaje\nSolvencia económica"
       },
       {
        "pasaporte": "El Salvador",
        "origen": "Todos",
        "destino": "Túnez",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado\nPasaporte vigente\nAsistencia Médica de Viaje\nReserva de vuelo\nReserva de hospedaje\nSolvencia económica"
       },
       {
        "pasaporte": "El Salvador",
        "origen": "Todos",
        "destino": "Turkmenistán",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado\nPasaporte vigente\nAsistencia Médica de Viaje\nReserva de vuelo\nReserva de hospedaje\nSolvencia económica"
       },
       {
        "pasaporte": "El Salvador",
        "origen": "Todos",
        "destino": "Ucrania",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado\nPasaporte vigente\nAsistencia Médica de Viaje\nReserva de vuelo\nReserva de hospedaje\nSolvencia económica"
       },
       {
        "pasaporte": "El Salvador",
        "origen": "Todos",
        "destino": "Vanuatu",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado\nPasaporte vigente\nAsistencia Médica de Viaje\nReserva de vuelo\nReserva de hospedaje\nSolvencia económica"
       },
       {
        "pasaporte": "El Salvador",
        "origen": "Todos",
        "destino": "Venezuela",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado\nPasaporte vigente\nAsistencia Médica de Viaje\nReserva de vuelo\nReserva de hospedaje\nSolvencia económica"
       },
       {
        "pasaporte": "El Salvador",
        "origen": "Todos",
        "destino": "Yemen",
        "tipo": "turismo",
        "visado": "SI",
        "porcentaje": "0.90",
        "requisitos": "Visado\nPasaporte vigente\nAsistencia Médica de Viaje\nReserva de vuelo\nReserva de hospedaje\nSolvencia económica"
       }
      ]
  }
}
