export class VisasOtros {
  public static visados = {
      "visas":[
        {
          "pasaporte": "Ecuador",
          "origen": "Todos",
          "destino": "Alemania",
          "tipo": "inversionista",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Ecuador",
          "origen": "Todos",
          "destino": "Andorra",
          "tipo": "inversionista",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Ecuador",
          "origen": "Todos",
          "destino": "Arabia Saudí",
          "tipo": "inversionista",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Ecuador",
          "origen": "Todos",
          "destino": "Argelia",
          "tipo": "inversionista",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Ecuador",
          "origen": "Todos",
          "destino": "Austria",
          "tipo": "inversionista",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Ecuador",
          "origen": "Todos",
          "destino": "Bélgica",
          "tipo": "inversionista",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Ecuador",
          "origen": "Todos",
          "destino": "Bulgaria",
          "tipo": "inversionista",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Ecuador",
          "origen": "Todos",
          "destino": "Canadá",
          "tipo": "inversionista",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Ecuador",
          "origen": "Todos",
          "destino": "Costa Rica",
          "tipo": "inversionista",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Ecuador",
          "origen": "Todos",
          "destino": "Croacia",
          "tipo": "inversionista",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Ecuador",
          "origen": "Todos",
          "destino": "Cuba",
          "tipo": "inversionista",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Ecuador",
          "origen": "Todos",
          "destino": "Dinamarca",
          "tipo": "inversionista",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Ecuador",
          "origen": "Todos",
          "destino": "Eslovaquia",
          "tipo": "inversionista",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Ecuador",
          "origen": "Todos",
          "destino": "Eslovenia",
          "tipo": "inversionista",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Ecuador",
          "origen": "Todos",
          "destino": "España",
          "tipo": "inversionista",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Ecuador",
          "origen": "Todos",
          "destino": "Estados Unidos de América",
          "tipo": "inversionista",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Ecuador",
          "origen": "Todos",
          "destino": "Estonia",
          "tipo": "inversionista",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Ecuador",
          "origen": "Todos",
          "destino": "Finlandia",
          "tipo": "inversionista",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Ecuador",
          "origen": "Todos",
          "destino": "Francia",
          "tipo": "inversionista",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Ecuador",
          "origen": "Todos",
          "destino": "Grecia",
          "tipo": "inversionista",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Ecuador",
          "origen": "Todos",
          "destino": "Guatemala",
          "tipo": "inversionista",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Ecuador",
          "origen": "Todos",
          "destino": "Hungría",
          "tipo": "inversionista",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Ecuador",
          "origen": "Todos",
          "destino": "Irlanda",
          "tipo": "inversionista",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Ecuador",
          "origen": "Todos",
          "destino": "Islandia",
          "tipo": "inversionista",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Ecuador",
          "origen": "Todos",
          "destino": "Italia",
          "tipo": "inversionista",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Ecuador",
          "origen": "Todos",
          "destino": "Japón",
          "tipo": "inversionista",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Ecuador",
          "origen": "Todos",
          "destino": "Letonia",
          "tipo": "inversionista",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Ecuador",
          "origen": "Todos",
          "destino": "Líbano",
          "tipo": "inversionista",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Ecuador",
          "origen": "Todos",
          "destino": "Liberia",
          "tipo": "inversionista",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Ecuador",
          "origen": "Todos",
          "destino": "Liechtenstein",
          "tipo": "inversionista",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Ecuador",
          "origen": "Todos",
          "destino": "Lituania",
          "tipo": "inversionista",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Ecuador",
          "origen": "Todos",
          "destino": "Luxemburgo",
          "tipo": "inversionista",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Ecuador",
          "origen": "Todos",
          "destino": "Malta",
          "tipo": "inversionista",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Ecuador",
          "origen": "Todos",
          "destino": "Marruecos",
          "tipo": "inversionista",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Ecuador",
          "origen": "Todos",
          "destino": "México",
          "tipo": "inversionista",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Ecuador",
          "origen": "Todos",
          "destino": "Mónaco",
          "tipo": "inversionista",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Ecuador",
          "origen": "Todos",
          "destino": "Montenegro",
          "tipo": "inversionista",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Ecuador",
          "origen": "Todos",
          "destino": "Noruega",
          "tipo": "inversionista",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Ecuador",
          "origen": "Todos",
          "destino": "Nueva Zelanda",
          "tipo": "inversionista",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Ecuador",
          "origen": "Todos",
          "destino": "Países Bajos",
          "tipo": "inversionista",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Ecuador",
          "origen": "Todos",
          "destino": "Polonia",
          "tipo": "inversionista",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Ecuador",
          "origen": "Todos",
          "destino": "Portugal",
          "tipo": "inversionista",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Ecuador",
          "origen": "Todos",
          "destino": "Reino Unido",
          "tipo": "inversionista",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Ecuador",
          "origen": "Todos",
          "destino": "República Checa",
          "tipo": "inversionista",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Ecuador",
          "origen": "Todos",
          "destino": "Rumanía",
          "tipo": "inversionista",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Ecuador",
          "origen": "Todos",
          "destino": "Serbia",
          "tipo": "inversionista",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Ecuador",
          "origen": "Todos",
          "destino": "Suecia",
          "tipo": "inversionista",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Ecuador",
          "origen": "Todos",
          "destino": "Suiza",
          "tipo": "inversionista",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Ecuador",
          "origen": "Todos",
          "destino": "Túnez",
          "tipo": "inversionista",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Ecuador",
          "origen": "Todos",
          "destino": "Albania",
          "tipo": "inversionista",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Ecuador",
          "origen": "Todos",
          "destino": "Australia",
          "tipo": "inversionista",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Ecuador",
          "origen": "Todos",
          "destino": "Emiratos Árabes Unidos",
          "tipo": "inversionista",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Ecuador",
          "origen": "Todos",
          "destino": "India",
          "tipo": "inversionista",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Ecuador",
          "origen": "Todos",
          "destino": "Libia",
          "tipo": "inversionista",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Ecuador",
          "origen": "Todos",
          "destino": "Taiwán",
          "tipo": "inversionista",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Ecuador",
          "origen": "Todos",
          "destino": "Vietnam",
          "tipo": "inversionista",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Ecuador",
          "origen": "Todos",
          "destino": "Corea del Sur",
          "tipo": "inversionista",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Ecuador",
          "origen": "Todos",
          "destino": "Argentina",
          "tipo": "inversionista",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Ecuador",
          "origen": "Todos",
          "destino": "Bolivia",
          "tipo": "inversionista",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Ecuador",
          "origen": "Todos",
          "destino": "Brasil",
          "tipo": "inversionista",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Ecuador",
          "origen": "Todos",
          "destino": "Chile",
          "tipo": "inversionista",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Ecuador",
          "origen": "Todos",
          "destino": "China",
          "tipo": "inversionista",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Ecuador",
          "origen": "Todos",
          "destino": "Colombia",
          "tipo": "inversionista",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Ecuador",
          "origen": "Todos",
          "destino": "Curacao",
          "tipo": "inversionista",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Ecuador",
          "origen": "Todos",
          "destino": "El Salvador",
          "tipo": "inversionista",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Ecuador",
          "origen": "Todos",
          "destino": "Federación Rusa",
          "tipo": "inversionista",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Ecuador",
          "origen": "Todos",
          "destino": "Honduras",
          "tipo": "inversionista",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Ecuador",
          "origen": "Todos",
          "destino": "Hong Kong",
          "tipo": "inversionista",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Ecuador",
          "origen": "Todos",
          "destino": "Israel",
          "tipo": "inversionista",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Ecuador",
          "origen": "Todos",
          "destino": "Jamaica",
          "tipo": "inversionista",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Ecuador",
          "origen": "Todos",
          "destino": "Nicaragua",
          "tipo": "inversionista",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Ecuador",
          "origen": "Todos",
          "destino": "Panamá",
          "tipo": "inversionista",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Ecuador",
          "origen": "Todos",
          "destino": "Paraguay",
          "tipo": "inversionista",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Ecuador",
          "origen": "Todos",
          "destino": "Perú",
          "tipo": "inversionista",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Ecuador",
          "origen": "Todos",
          "destino": "República Dominicana",
          "tipo": "inversionista",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Ecuador",
          "origen": "Todos",
          "destino": "Singapur",
          "tipo": "inversionista",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Ecuador",
          "origen": "Todos",
          "destino": "Sudáfrica",
          "tipo": "inversionista",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Ecuador",
          "origen": "Todos",
          "destino": "Tailandia",
          "tipo": "inversionista",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Ecuador",
          "origen": "Todos",
          "destino": "Turquía",
          "tipo": "inversionista",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Ecuador",
          "origen": "Todos",
          "destino": "Uruguay",
          "tipo": "inversionista",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Ecuador",
          "origen": "Todos",
          "destino": "Venezuela",
          "tipo": "inversionista",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Colombia",
          "origen": "Todos",
          "destino": "Alemania",
          "tipo": "inversionista",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Colombia",
          "origen": "Todos",
          "destino": "Andorra",
          "tipo": "inversionista",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Colombia",
          "origen": "Todos",
          "destino": "Arabia Saudí",
          "tipo": "inversionista",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Colombia",
          "origen": "Todos",
          "destino": "Argelia",
          "tipo": "inversionista",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Colombia",
          "origen": "Todos",
          "destino": "Austria",
          "tipo": "inversionista",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Colombia",
          "origen": "Todos",
          "destino": "Bélgica",
          "tipo": "inversionista",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Colombia",
          "origen": "Todos",
          "destino": "Bulgaria",
          "tipo": "inversionista",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Colombia",
          "origen": "Todos",
          "destino": "Canadá",
          "tipo": "inversionista",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Colombia",
          "origen": "Todos",
          "destino": "Costa Rica",
          "tipo": "inversionista",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Colombia",
          "origen": "Todos",
          "destino": "Croacia",
          "tipo": "inversionista",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Colombia",
          "origen": "Todos",
          "destino": "Cuba",
          "tipo": "inversionista",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Colombia",
          "origen": "Todos",
          "destino": "Dinamarca",
          "tipo": "inversionista",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Colombia",
          "origen": "Todos",
          "destino": "Eslovaquia",
          "tipo": "inversionista",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Colombia",
          "origen": "Todos",
          "destino": "Eslovenia",
          "tipo": "inversionista",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Colombia",
          "origen": "Todos",
          "destino": "España",
          "tipo": "inversionista",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Colombia",
          "origen": "Todos",
          "destino": "Estados Unidos de América",
          "tipo": "inversionista",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Colombia",
          "origen": "Todos",
          "destino": "Estonia",
          "tipo": "inversionista",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Colombia",
          "origen": "Todos",
          "destino": "Finlandia",
          "tipo": "inversionista",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Colombia",
          "origen": "Todos",
          "destino": "Francia",
          "tipo": "inversionista",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Colombia",
          "origen": "Todos",
          "destino": "Grecia",
          "tipo": "inversionista",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Colombia",
          "origen": "Todos",
          "destino": "Guatemala",
          "tipo": "inversionista",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Colombia",
          "origen": "Todos",
          "destino": "Hungría",
          "tipo": "inversionista",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Colombia",
          "origen": "Todos",
          "destino": "Irlanda",
          "tipo": "inversionista",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Colombia",
          "origen": "Todos",
          "destino": "Islandia",
          "tipo": "inversionista",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Colombia",
          "origen": "Todos",
          "destino": "Italia",
          "tipo": "inversionista",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Colombia",
          "origen": "Todos",
          "destino": "Japón",
          "tipo": "inversionista",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Colombia",
          "origen": "Todos",
          "destino": "Letonia",
          "tipo": "inversionista",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Colombia",
          "origen": "Todos",
          "destino": "Líbano",
          "tipo": "inversionista",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Colombia",
          "origen": "Todos",
          "destino": "Liberia",
          "tipo": "inversionista",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Colombia",
          "origen": "Todos",
          "destino": "Liechtenstein",
          "tipo": "inversionista",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Colombia",
          "origen": "Todos",
          "destino": "Lituania",
          "tipo": "inversionista",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Colombia",
          "origen": "Todos",
          "destino": "Luxemburgo",
          "tipo": "inversionista",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Colombia",
          "origen": "Todos",
          "destino": "Malta",
          "tipo": "inversionista",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Colombia",
          "origen": "Todos",
          "destino": "Marruecos",
          "tipo": "inversionista",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Colombia",
          "origen": "Todos",
          "destino": "México",
          "tipo": "inversionista",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Colombia",
          "origen": "Todos",
          "destino": "Mónaco",
          "tipo": "inversionista",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Colombia",
          "origen": "Todos",
          "destino": "Montenegro",
          "tipo": "inversionista",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Colombia",
          "origen": "Todos",
          "destino": "Noruega",
          "tipo": "inversionista",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Colombia",
          "origen": "Todos",
          "destino": "Nueva Zelanda",
          "tipo": "inversionista",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Colombia",
          "origen": "Todos",
          "destino": "Países Bajos",
          "tipo": "inversionista",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Colombia",
          "origen": "Todos",
          "destino": "Polonia",
          "tipo": "inversionista",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Colombia",
          "origen": "Todos",
          "destino": "Portugal",
          "tipo": "inversionista",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Colombia",
          "origen": "Todos",
          "destino": "Reino Unido",
          "tipo": "inversionista",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Colombia",
          "origen": "Todos",
          "destino": "República Checa",
          "tipo": "inversionista",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Colombia",
          "origen": "Todos",
          "destino": "Rumanía",
          "tipo": "inversionista",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Colombia",
          "origen": "Todos",
          "destino": "Serbia",
          "tipo": "inversionista",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Colombia",
          "origen": "Todos",
          "destino": "Suecia",
          "tipo": "inversionista",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Colombia",
          "origen": "Todos",
          "destino": "Suiza",
          "tipo": "inversionista",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Colombia",
          "origen": "Todos",
          "destino": "Túnez",
          "tipo": "inversionista",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Colombia",
          "origen": "Todos",
          "destino": "Albania",
          "tipo": "inversionista",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Colombia",
          "origen": "Todos",
          "destino": "Australia",
          "tipo": "inversionista",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Colombia",
          "origen": "Todos",
          "destino": "Emiratos Árabes Unidos",
          "tipo": "inversionista",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Colombia",
          "origen": "Todos",
          "destino": "India",
          "tipo": "inversionista",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Colombia",
          "origen": "Todos",
          "destino": "Libia",
          "tipo": "inversionista",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Colombia",
          "origen": "Todos",
          "destino": "Taiwán",
          "tipo": "inversionista",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Colombia",
          "origen": "Todos",
          "destino": "Vietnam",
          "tipo": "inversionista",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Colombia",
          "origen": "Todos",
          "destino": "Corea del Sur",
          "tipo": "inversionista",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Colombia",
          "origen": "Todos",
          "destino": "Argentina",
          "tipo": "inversionista",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Colombia",
          "origen": "Todos",
          "destino": "Bolivia",
          "tipo": "inversionista",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Colombia",
          "origen": "Todos",
          "destino": "Brasil",
          "tipo": "inversionista",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Colombia",
          "origen": "Todos",
          "destino": "Chile",
          "tipo": "inversionista",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Colombia",
          "origen": "Todos",
          "destino": "China",
          "tipo": "inversionista",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Colombia",
          "origen": "Todos",
          "destino": "Colombia",
          "tipo": "inversionista",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Colombia",
          "origen": "Todos",
          "destino": "Curacao",
          "tipo": "inversionista",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Colombia",
          "origen": "Todos",
          "destino": "El Salvador",
          "tipo": "inversionista",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Colombia",
          "origen": "Todos",
          "destino": "Federación Rusa",
          "tipo": "inversionista",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Colombia",
          "origen": "Todos",
          "destino": "Honduras",
          "tipo": "inversionista",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Colombia",
          "origen": "Todos",
          "destino": "Hong Kong",
          "tipo": "inversionista",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Colombia",
          "origen": "Todos",
          "destino": "Israel",
          "tipo": "inversionista",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Colombia",
          "origen": "Todos",
          "destino": "Jamaica",
          "tipo": "inversionista",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Colombia",
          "origen": "Todos",
          "destino": "Nicaragua",
          "tipo": "inversionista",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Colombia",
          "origen": "Todos",
          "destino": "Panamá",
          "tipo": "inversionista",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Colombia",
          "origen": "Todos",
          "destino": "Paraguay",
          "tipo": "inversionista",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Colombia",
          "origen": "Todos",
          "destino": "Perú",
          "tipo": "inversionista",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Colombia",
          "origen": "Todos",
          "destino": "República Dominicana",
          "tipo": "inversionista",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Colombia",
          "origen": "Todos",
          "destino": "Singapur",
          "tipo": "inversionista",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Colombia",
          "origen": "Todos",
          "destino": "Sudáfrica",
          "tipo": "inversionista",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Colombia",
          "origen": "Todos",
          "destino": "Tailandia",
          "tipo": "inversionista",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Colombia",
          "origen": "Todos",
          "destino": "Turquía",
          "tipo": "inversionista",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Colombia",
          "origen": "Todos",
          "destino": "Uruguay",
          "tipo": "inversionista",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Colombia",
          "origen": "Todos",
          "destino": "Venezuela",
          "tipo": "inversionista",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Peru",
          "origen": "Todos",
          "destino": "Alemania",
          "tipo": "inversionista",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Peru",
          "origen": "Todos",
          "destino": "Andorra",
          "tipo": "inversionista",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Peru",
          "origen": "Todos",
          "destino": "Arabia Saudí",
          "tipo": "inversionista",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Peru",
          "origen": "Todos",
          "destino": "Argelia",
          "tipo": "inversionista",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Peru",
          "origen": "Todos",
          "destino": "Austria",
          "tipo": "inversionista",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Peru",
          "origen": "Todos",
          "destino": "Bélgica",
          "tipo": "inversionista",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Peru",
          "origen": "Todos",
          "destino": "Bulgaria",
          "tipo": "inversionista",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Peru",
          "origen": "Todos",
          "destino": "Canadá",
          "tipo": "inversionista",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Peru",
          "origen": "Todos",
          "destino": "Costa Rica",
          "tipo": "inversionista",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Peru",
          "origen": "Todos",
          "destino": "Croacia",
          "tipo": "inversionista",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Peru",
          "origen": "Todos",
          "destino": "Cuba",
          "tipo": "inversionista",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Peru",
          "origen": "Todos",
          "destino": "Dinamarca",
          "tipo": "inversionista",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Peru",
          "origen": "Todos",
          "destino": "Eslovaquia",
          "tipo": "inversionista",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Peru",
          "origen": "Todos",
          "destino": "Eslovenia",
          "tipo": "inversionista",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Peru",
          "origen": "Todos",
          "destino": "España",
          "tipo": "inversionista",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Peru",
          "origen": "Todos",
          "destino": "Estados Unidos de América",
          "tipo": "inversionista",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Peru",
          "origen": "Todos",
          "destino": "Estonia",
          "tipo": "inversionista",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Peru",
          "origen": "Todos",
          "destino": "Finlandia",
          "tipo": "inversionista",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Peru",
          "origen": "Todos",
          "destino": "Francia",
          "tipo": "inversionista",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Peru",
          "origen": "Todos",
          "destino": "Grecia",
          "tipo": "inversionista",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Peru",
          "origen": "Todos",
          "destino": "Guatemala",
          "tipo": "inversionista",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Peru",
          "origen": "Todos",
          "destino": "Hungría",
          "tipo": "inversionista",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Peru",
          "origen": "Todos",
          "destino": "Irlanda",
          "tipo": "inversionista",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Peru",
          "origen": "Todos",
          "destino": "Islandia",
          "tipo": "inversionista",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Peru",
          "origen": "Todos",
          "destino": "Italia",
          "tipo": "inversionista",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Peru",
          "origen": "Todos",
          "destino": "Japón",
          "tipo": "inversionista",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Peru",
          "origen": "Todos",
          "destino": "Letonia",
          "tipo": "inversionista",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Peru",
          "origen": "Todos",
          "destino": "Líbano",
          "tipo": "inversionista",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Peru",
          "origen": "Todos",
          "destino": "Liberia",
          "tipo": "inversionista",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Peru",
          "origen": "Todos",
          "destino": "Liechtenstein",
          "tipo": "inversionista",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Peru",
          "origen": "Todos",
          "destino": "Lituania",
          "tipo": "inversionista",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Peru",
          "origen": "Todos",
          "destino": "Luxemburgo",
          "tipo": "inversionista",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Peru",
          "origen": "Todos",
          "destino": "Malta",
          "tipo": "inversionista",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Peru",
          "origen": "Todos",
          "destino": "Marruecos",
          "tipo": "inversionista",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Peru",
          "origen": "Todos",
          "destino": "México",
          "tipo": "inversionista",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Peru",
          "origen": "Todos",
          "destino": "Mónaco",
          "tipo": "inversionista",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Peru",
          "origen": "Todos",
          "destino": "Montenegro",
          "tipo": "inversionista",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Peru",
          "origen": "Todos",
          "destino": "Noruega",
          "tipo": "inversionista",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Peru",
          "origen": "Todos",
          "destino": "Nueva Zelanda",
          "tipo": "inversionista",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Peru",
          "origen": "Todos",
          "destino": "Países Bajos",
          "tipo": "inversionista",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Peru",
          "origen": "Todos",
          "destino": "Polonia",
          "tipo": "inversionista",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Peru",
          "origen": "Todos",
          "destino": "Portugal",
          "tipo": "inversionista",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Peru",
          "origen": "Todos",
          "destino": "Reino Unido",
          "tipo": "inversionista",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Peru",
          "origen": "Todos",
          "destino": "República Checa",
          "tipo": "inversionista",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Peru",
          "origen": "Todos",
          "destino": "Rumanía",
          "tipo": "inversionista",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Peru",
          "origen": "Todos",
          "destino": "Serbia",
          "tipo": "inversionista",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Peru",
          "origen": "Todos",
          "destino": "Suecia",
          "tipo": "inversionista",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Peru",
          "origen": "Todos",
          "destino": "Suiza",
          "tipo": "inversionista",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Peru",
          "origen": "Todos",
          "destino": "Túnez",
          "tipo": "inversionista",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Peru",
          "origen": "Todos",
          "destino": "Albania",
          "tipo": "inversionista",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Peru",
          "origen": "Todos",
          "destino": "Australia",
          "tipo": "inversionista",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Peru",
          "origen": "Todos",
          "destino": "Emiratos Árabes Unidos",
          "tipo": "inversionista",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Peru",
          "origen": "Todos",
          "destino": "India",
          "tipo": "inversionista",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Peru",
          "origen": "Todos",
          "destino": "Libia",
          "tipo": "inversionista",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Peru",
          "origen": "Todos",
          "destino": "Taiwán",
          "tipo": "inversionista",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Peru",
          "origen": "Todos",
          "destino": "Vietnam",
          "tipo": "inversionista",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Peru",
          "origen": "Todos",
          "destino": "Corea del Sur",
          "tipo": "inversionista",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Peru",
          "origen": "Todos",
          "destino": "Argentina",
          "tipo": "inversionista",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Peru",
          "origen": "Todos",
          "destino": "Bolivia",
          "tipo": "inversionista",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Peru",
          "origen": "Todos",
          "destino": "Brasil",
          "tipo": "inversionista",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Peru",
          "origen": "Todos",
          "destino": "Chile",
          "tipo": "inversionista",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Peru",
          "origen": "Todos",
          "destino": "China",
          "tipo": "inversionista",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Peru",
          "origen": "Todos",
          "destino": "Colombia",
          "tipo": "inversionista",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Peru",
          "origen": "Todos",
          "destino": "Curacao",
          "tipo": "inversionista",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Peru",
          "origen": "Todos",
          "destino": "El Salvador",
          "tipo": "inversionista",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Peru",
          "origen": "Todos",
          "destino": "Federación Rusa",
          "tipo": "inversionista",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Peru",
          "origen": "Todos",
          "destino": "Honduras",
          "tipo": "inversionista",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Peru",
          "origen": "Todos",
          "destino": "Hong Kong",
          "tipo": "inversionista",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Peru",
          "origen": "Todos",
          "destino": "Israel",
          "tipo": "inversionista",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Peru",
          "origen": "Todos",
          "destino": "Jamaica",
          "tipo": "inversionista",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Peru",
          "origen": "Todos",
          "destino": "Nicaragua",
          "tipo": "inversionista",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Peru",
          "origen": "Todos",
          "destino": "Panamá",
          "tipo": "inversionista",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Peru",
          "origen": "Todos",
          "destino": "Paraguay",
          "tipo": "inversionista",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Peru",
          "origen": "Todos",
          "destino": "Perú",
          "tipo": "inversionista",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Peru",
          "origen": "Todos",
          "destino": "República Dominicana",
          "tipo": "inversionista",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Peru",
          "origen": "Todos",
          "destino": "Singapur",
          "tipo": "inversionista",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Peru",
          "origen": "Todos",
          "destino": "Sudáfrica",
          "tipo": "inversionista",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Peru",
          "origen": "Todos",
          "destino": "Tailandia",
          "tipo": "inversionista",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Peru",
          "origen": "Todos",
          "destino": "Turquía",
          "tipo": "inversionista",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Peru",
          "origen": "Todos",
          "destino": "Uruguay",
          "tipo": "inversionista",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Peru",
          "origen": "Todos",
          "destino": "Venezuela",
          "tipo": "inversionista",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Venezuela",
          "origen": "Todos",
          "destino": "Alemania",
          "tipo": "inversionista",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Venezuela",
          "origen": "Todos",
          "destino": "Andorra",
          "tipo": "inversionista",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Venezuela",
          "origen": "Todos",
          "destino": "Arabia Saudí",
          "tipo": "inversionista",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Venezuela",
          "origen": "Todos",
          "destino": "Argelia",
          "tipo": "inversionista",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Venezuela",
          "origen": "Todos",
          "destino": "Austria",
          "tipo": "inversionista",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Venezuela",
          "origen": "Todos",
          "destino": "Bélgica",
          "tipo": "inversionista",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Venezuela",
          "origen": "Todos",
          "destino": "Bulgaria",
          "tipo": "inversionista",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Venezuela",
          "origen": "Todos",
          "destino": "Canadá",
          "tipo": "inversionista",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Venezuela",
          "origen": "Todos",
          "destino": "Costa Rica",
          "tipo": "inversionista",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Venezuela",
          "origen": "Todos",
          "destino": "Croacia",
          "tipo": "inversionista",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Venezuela",
          "origen": "Todos",
          "destino": "Cuba",
          "tipo": "inversionista",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Venezuela",
          "origen": "Todos",
          "destino": "Dinamarca",
          "tipo": "inversionista",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Venezuela",
          "origen": "Todos",
          "destino": "Eslovaquia",
          "tipo": "inversionista",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Venezuela",
          "origen": "Todos",
          "destino": "Eslovenia",
          "tipo": "inversionista",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Venezuela",
          "origen": "Todos",
          "destino": "España",
          "tipo": "inversionista",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Venezuela",
          "origen": "Todos",
          "destino": "Estados Unidos de América",
          "tipo": "inversionista",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Venezuela",
          "origen": "Todos",
          "destino": "Estonia",
          "tipo": "inversionista",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Venezuela",
          "origen": "Todos",
          "destino": "Finlandia",
          "tipo": "inversionista",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Venezuela",
          "origen": "Todos",
          "destino": "Francia",
          "tipo": "inversionista",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Venezuela",
          "origen": "Todos",
          "destino": "Grecia",
          "tipo": "inversionista",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Venezuela",
          "origen": "Todos",
          "destino": "Guatemala",
          "tipo": "inversionista",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Venezuela",
          "origen": "Todos",
          "destino": "Hungría",
          "tipo": "inversionista",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Venezuela",
          "origen": "Todos",
          "destino": "Irlanda",
          "tipo": "inversionista",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Venezuela",
          "origen": "Todos",
          "destino": "Islandia",
          "tipo": "inversionista",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Venezuela",
          "origen": "Todos",
          "destino": "Italia",
          "tipo": "inversionista",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Venezuela",
          "origen": "Todos",
          "destino": "Japón",
          "tipo": "inversionista",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Venezuela",
          "origen": "Todos",
          "destino": "Letonia",
          "tipo": "inversionista",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Venezuela",
          "origen": "Todos",
          "destino": "Líbano",
          "tipo": "inversionista",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Venezuela",
          "origen": "Todos",
          "destino": "Liberia",
          "tipo": "inversionista",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Venezuela",
          "origen": "Todos",
          "destino": "Liechtenstein",
          "tipo": "inversionista",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Venezuela",
          "origen": "Todos",
          "destino": "Lituania",
          "tipo": "inversionista",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Venezuela",
          "origen": "Todos",
          "destino": "Luxemburgo",
          "tipo": "inversionista",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Venezuela",
          "origen": "Todos",
          "destino": "Malta",
          "tipo": "inversionista",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Venezuela",
          "origen": "Todos",
          "destino": "Marruecos",
          "tipo": "inversionista",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Venezuela",
          "origen": "Todos",
          "destino": "México",
          "tipo": "inversionista",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Venezuela",
          "origen": "Todos",
          "destino": "Mónaco",
          "tipo": "inversionista",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Venezuela",
          "origen": "Todos",
          "destino": "Montenegro",
          "tipo": "inversionista",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Venezuela",
          "origen": "Todos",
          "destino": "Noruega",
          "tipo": "inversionista",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Venezuela",
          "origen": "Todos",
          "destino": "Nueva Zelanda",
          "tipo": "inversionista",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Venezuela",
          "origen": "Todos",
          "destino": "Países Bajos",
          "tipo": "inversionista",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Venezuela",
          "origen": "Todos",
          "destino": "Polonia",
          "tipo": "inversionista",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Venezuela",
          "origen": "Todos",
          "destino": "Portugal",
          "tipo": "inversionista",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Venezuela",
          "origen": "Todos",
          "destino": "Reino Unido",
          "tipo": "inversionista",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Venezuela",
          "origen": "Todos",
          "destino": "República Checa",
          "tipo": "inversionista",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Venezuela",
          "origen": "Todos",
          "destino": "Rumanía",
          "tipo": "inversionista",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Venezuela",
          "origen": "Todos",
          "destino": "Serbia",
          "tipo": "inversionista",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Venezuela",
          "origen": "Todos",
          "destino": "Suecia",
          "tipo": "inversionista",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Venezuela",
          "origen": "Todos",
          "destino": "Suiza",
          "tipo": "inversionista",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Venezuela",
          "origen": "Todos",
          "destino": "Túnez",
          "tipo": "inversionista",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Venezuela",
          "origen": "Todos",
          "destino": "Albania",
          "tipo": "inversionista",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Venezuela",
          "origen": "Todos",
          "destino": "Australia",
          "tipo": "inversionista",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Venezuela",
          "origen": "Todos",
          "destino": "Emiratos Árabes Unidos",
          "tipo": "inversionista",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Venezuela",
          "origen": "Todos",
          "destino": "India",
          "tipo": "inversionista",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Venezuela",
          "origen": "Todos",
          "destino": "Libia",
          "tipo": "inversionista",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Venezuela",
          "origen": "Todos",
          "destino": "Taiwán",
          "tipo": "inversionista",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Venezuela",
          "origen": "Todos",
          "destino": "Vietnam",
          "tipo": "inversionista",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Venezuela",
          "origen": "Todos",
          "destino": "Corea del Sur",
          "tipo": "inversionista",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Venezuela",
          "origen": "Todos",
          "destino": "Argentina",
          "tipo": "inversionista",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Venezuela",
          "origen": "Todos",
          "destino": "Bolivia",
          "tipo": "inversionista",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Venezuela",
          "origen": "Todos",
          "destino": "Brasil",
          "tipo": "inversionista",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Venezuela",
          "origen": "Todos",
          "destino": "Chile",
          "tipo": "inversionista",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Venezuela",
          "origen": "Todos",
          "destino": "China",
          "tipo": "inversionista",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Venezuela",
          "origen": "Todos",
          "destino": "Colombia",
          "tipo": "inversionista",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Venezuela",
          "origen": "Todos",
          "destino": "Curacao",
          "tipo": "inversionista",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Venezuela",
          "origen": "Todos",
          "destino": "El Salvador",
          "tipo": "inversionista",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Venezuela",
          "origen": "Todos",
          "destino": "Federación Rusa",
          "tipo": "inversionista",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Venezuela",
          "origen": "Todos",
          "destino": "Honduras",
          "tipo": "inversionista",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Venezuela",
          "origen": "Todos",
          "destino": "Hong Kong",
          "tipo": "inversionista",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Venezuela",
          "origen": "Todos",
          "destino": "Israel",
          "tipo": "inversionista",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Venezuela",
          "origen": "Todos",
          "destino": "Jamaica",
          "tipo": "inversionista",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Venezuela",
          "origen": "Todos",
          "destino": "Nicaragua",
          "tipo": "inversionista",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Venezuela",
          "origen": "Todos",
          "destino": "Panamá",
          "tipo": "inversionista",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Venezuela",
          "origen": "Todos",
          "destino": "Paraguay",
          "tipo": "inversionista",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Venezuela",
          "origen": "Todos",
          "destino": "Perú",
          "tipo": "inversionista",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Venezuela",
          "origen": "Todos",
          "destino": "República Dominicana",
          "tipo": "inversionista",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Venezuela",
          "origen": "Todos",
          "destino": "Singapur",
          "tipo": "inversionista",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Venezuela",
          "origen": "Todos",
          "destino": "Sudáfrica",
          "tipo": "inversionista",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Venezuela",
          "origen": "Todos",
          "destino": "Tailandia",
          "tipo": "inversionista",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Venezuela",
          "origen": "Todos",
          "destino": "Turquía",
          "tipo": "inversionista",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Venezuela",
          "origen": "Todos",
          "destino": "Uruguay",
          "tipo": "inversionista",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Venezuela",
          "origen": "Todos",
          "destino": "Venezuela",
          "tipo": "inversionista",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "El Salvador",
          "origen": "Todos",
          "destino": "Andorra",
          "tipo": "inversionista",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "El Salvador",
          "origen": "Todos",
          "destino": "Arabia Saudí",
          "tipo": "inversionista",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "El Salvador",
          "origen": "Todos",
          "destino": "Argelia",
          "tipo": "inversionista",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "El Salvador",
          "origen": "Todos",
          "destino": "Austria",
          "tipo": "inversionista",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "El Salvador",
          "origen": "Todos",
          "destino": "Bélgica",
          "tipo": "inversionista",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "El Salvador",
          "origen": "Todos",
          "destino": "Bulgaria",
          "tipo": "inversionista",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "El Salvador",
          "origen": "Todos",
          "destino": "Canadá",
          "tipo": "inversionista",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "El Salvador",
          "origen": "Todos",
          "destino": "Costa Rica",
          "tipo": "inversionista",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "El Salvador",
          "origen": "Todos",
          "destino": "Croacia",
          "tipo": "inversionista",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "El Salvador",
          "origen": "Todos",
          "destino": "Cuba",
          "tipo": "inversionista",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "El Salvador",
          "origen": "Todos",
          "destino": "Dinamarca",
          "tipo": "inversionista",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "El Salvador",
          "origen": "Todos",
          "destino": "Eslovaquia",
          "tipo": "inversionista",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "El Salvador",
          "origen": "Todos",
          "destino": "Eslovenia",
          "tipo": "inversionista",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "El Salvador",
          "origen": "Todos",
          "destino": "España",
          "tipo": "inversionista",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "El Salvador",
          "origen": "Todos",
          "destino": "Estados Unidos de América",
          "tipo": "inversionista",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "El Salvador",
          "origen": "Todos",
          "destino": "Estonia",
          "tipo": "inversionista",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "El Salvador",
          "origen": "Todos",
          "destino": "Finlandia",
          "tipo": "inversionista",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "El Salvador",
          "origen": "Todos",
          "destino": "Francia",
          "tipo": "inversionista",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "El Salvador",
          "origen": "Todos",
          "destino": "Grecia",
          "tipo": "inversionista",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "El Salvador",
          "origen": "Todos",
          "destino": "Guatemala",
          "tipo": "inversionista",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "El Salvador",
          "origen": "Todos",
          "destino": "Hungría",
          "tipo": "inversionista",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "El Salvador",
          "origen": "Todos",
          "destino": "Irlanda",
          "tipo": "inversionista",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "El Salvador",
          "origen": "Todos",
          "destino": "Islandia",
          "tipo": "inversionista",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "El Salvador",
          "origen": "Todos",
          "destino": "Italia",
          "tipo": "inversionista",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "El Salvador",
          "origen": "Todos",
          "destino": "Japón",
          "tipo": "inversionista",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "El Salvador",
          "origen": "Todos",
          "destino": "Letonia",
          "tipo": "inversionista",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "El Salvador",
          "origen": "Todos",
          "destino": "Líbano",
          "tipo": "inversionista",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "El Salvador",
          "origen": "Todos",
          "destino": "Liberia",
          "tipo": "inversionista",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "El Salvador",
          "origen": "Todos",
          "destino": "Liechtenstein",
          "tipo": "inversionista",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "El Salvador",
          "origen": "Todos",
          "destino": "Lituania",
          "tipo": "inversionista",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "El Salvador",
          "origen": "Todos",
          "destino": "Luxemburgo",
          "tipo": "inversionista",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "El Salvador",
          "origen": "Todos",
          "destino": "Malta",
          "tipo": "inversionista",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "El Salvador",
          "origen": "Todos",
          "destino": "Marruecos",
          "tipo": "inversionista",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "El Salvador",
          "origen": "Todos",
          "destino": "México",
          "tipo": "inversionista",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "El Salvador",
          "origen": "Todos",
          "destino": "Mónaco",
          "tipo": "inversionista",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "El Salvador",
          "origen": "Todos",
          "destino": "Montenegro",
          "tipo": "inversionista",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "El Salvador",
          "origen": "Todos",
          "destino": "Noruega",
          "tipo": "inversionista",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "El Salvador",
          "origen": "Todos",
          "destino": "Nueva Zelanda",
          "tipo": "inversionista",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "El Salvador",
          "origen": "Todos",
          "destino": "Países Bajos",
          "tipo": "inversionista",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "El Salvador",
          "origen": "Todos",
          "destino": "Polonia",
          "tipo": "inversionista",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "El Salvador",
          "origen": "Todos",
          "destino": "Portugal",
          "tipo": "inversionista",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "El Salvador",
          "origen": "Todos",
          "destino": "Reino Unido",
          "tipo": "inversionista",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "El Salvador",
          "origen": "Todos",
          "destino": "República Checa",
          "tipo": "inversionista",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "El Salvador",
          "origen": "Todos",
          "destino": "Rumanía",
          "tipo": "inversionista",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "El Salvador",
          "origen": "Todos",
          "destino": "Serbia",
          "tipo": "inversionista",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "El Salvador",
          "origen": "Todos",
          "destino": "Suecia",
          "tipo": "inversionista",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "El Salvador",
          "origen": "Todos",
          "destino": "Suiza",
          "tipo": "inversionista",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "El Salvador",
          "origen": "Todos",
          "destino": "Túnez",
          "tipo": "inversionista",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "El Salvador",
          "origen": "Todos",
          "destino": "Albania",
          "tipo": "inversionista",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "El Salvador",
          "origen": "Todos",
          "destino": "Australia",
          "tipo": "inversionista",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "El Salvador",
          "origen": "Todos",
          "destino": "Emiratos Árabes Unidos",
          "tipo": "inversionista",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "El Salvador",
          "origen": "Todos",
          "destino": "India",
          "tipo": "inversionista",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "El Salvador",
          "origen": "Todos",
          "destino": "Libia",
          "tipo": "inversionista",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "El Salvador",
          "origen": "Todos",
          "destino": "Taiwán",
          "tipo": "inversionista",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "El Salvador",
          "origen": "Todos",
          "destino": "Vietnam",
          "tipo": "inversionista",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "El Salvador",
          "origen": "Todos",
          "destino": "Corea del Sur",
          "tipo": "inversionista",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "El Salvador",
          "origen": "Todos",
          "destino": "Argentina",
          "tipo": "inversionista",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "El Salvador",
          "origen": "Todos",
          "destino": "Bolivia",
          "tipo": "inversionista",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "El Salvador",
          "origen": "Todos",
          "destino": "Brasil",
          "tipo": "inversionista",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "El Salvador",
          "origen": "Todos",
          "destino": "Chile",
          "tipo": "inversionista",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "El Salvador",
          "origen": "Todos",
          "destino": "China",
          "tipo": "inversionista",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "El Salvador",
          "origen": "Todos",
          "destino": "Colombia",
          "tipo": "inversionista",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "El Salvador",
          "origen": "Todos",
          "destino": "Curacao",
          "tipo": "inversionista",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "El Salvador",
          "origen": "Todos",
          "destino": "El Salvador",
          "tipo": "inversionista",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "El Salvador",
          "origen": "Todos",
          "destino": "Federación Rusa",
          "tipo": "inversionista",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "El Salvador",
          "origen": "Todos",
          "destino": "Honduras",
          "tipo": "inversionista",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "El Salvador",
          "origen": "Todos",
          "destino": "Hong Kong",
          "tipo": "inversionista",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "El Salvador",
          "origen": "Todos",
          "destino": "Israel",
          "tipo": "inversionista",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "El Salvador",
          "origen": "Todos",
          "destino": "Jamaica",
          "tipo": "inversionista",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "El Salvador",
          "origen": "Todos",
          "destino": "Nicaragua",
          "tipo": "inversionista",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "El Salvador",
          "origen": "Todos",
          "destino": "Panamá",
          "tipo": "inversionista",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "El Salvador",
          "origen": "Todos",
          "destino": "Paraguay",
          "tipo": "inversionista",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "El Salvador",
          "origen": "Todos",
          "destino": "Perú",
          "tipo": "inversionista",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "El Salvador",
          "origen": "Todos",
          "destino": "República Dominicana",
          "tipo": "inversionista",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "El Salvador",
          "origen": "Todos",
          "destino": "Singapur",
          "tipo": "inversionista",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "El Salvador",
          "origen": "Todos",
          "destino": "Sudáfrica",
          "tipo": "inversionista",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "El Salvador",
          "origen": "Todos",
          "destino": "Tailandia",
          "tipo": "inversionista",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "El Salvador",
          "origen": "Todos",
          "destino": "Turquía",
          "tipo": "inversionista",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "El Salvador",
          "origen": "Todos",
          "destino": "Uruguay",
          "tipo": "inversionista",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "El Salvador",
          "origen": "Todos",
          "destino": "Venezuela",
          "tipo": "inversionista",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Mexico",
          "origen": "Todos",
          "destino": "Andorra",
          "tipo": "inversionista",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Mexico",
          "origen": "Todos",
          "destino": "Arabia Saudí",
          "tipo": "inversionista",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Mexico",
          "origen": "Todos",
          "destino": "Argelia",
          "tipo": "inversionista",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Mexico",
          "origen": "Todos",
          "destino": "Austria",
          "tipo": "inversionista",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Mexico",
          "origen": "Todos",
          "destino": "Bélgica",
          "tipo": "inversionista",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Mexico",
          "origen": "Todos",
          "destino": "Bulgaria",
          "tipo": "inversionista",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Mexico",
          "origen": "Todos",
          "destino": "Canadá",
          "tipo": "inversionista",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Mexico",
          "origen": "Todos",
          "destino": "Costa Rica",
          "tipo": "inversionista",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Mexico",
          "origen": "Todos",
          "destino": "Croacia",
          "tipo": "inversionista",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Mexico",
          "origen": "Todos",
          "destino": "Cuba",
          "tipo": "inversionista",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Mexico",
          "origen": "Todos",
          "destino": "Dinamarca",
          "tipo": "inversionista",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Mexico",
          "origen": "Todos",
          "destino": "Eslovaquia",
          "tipo": "inversionista",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Mexico",
          "origen": "Todos",
          "destino": "Eslovenia",
          "tipo": "inversionista",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Mexico",
          "origen": "Todos",
          "destino": "España",
          "tipo": "inversionista",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Mexico",
          "origen": "Todos",
          "destino": "Estados Unidos de América",
          "tipo": "inversionista",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Mexico",
          "origen": "Todos",
          "destino": "Estonia",
          "tipo": "inversionista",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Mexico",
          "origen": "Todos",
          "destino": "Finlandia",
          "tipo": "inversionista",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Mexico",
          "origen": "Todos",
          "destino": "Francia",
          "tipo": "inversionista",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Mexico",
          "origen": "Todos",
          "destino": "Grecia",
          "tipo": "inversionista",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Mexico",
          "origen": "Todos",
          "destino": "Guatemala",
          "tipo": "inversionista",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Mexico",
          "origen": "Todos",
          "destino": "Hungría",
          "tipo": "inversionista",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Mexico",
          "origen": "Todos",
          "destino": "Irlanda",
          "tipo": "inversionista",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Mexico",
          "origen": "Todos",
          "destino": "Islandia",
          "tipo": "inversionista",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Mexico",
          "origen": "Todos",
          "destino": "Italia",
          "tipo": "inversionista",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Mexico",
          "origen": "Todos",
          "destino": "Japón",
          "tipo": "inversionista",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Mexico",
          "origen": "Todos",
          "destino": "Letonia",
          "tipo": "inversionista",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Mexico",
          "origen": "Todos",
          "destino": "Líbano",
          "tipo": "inversionista",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Mexico",
          "origen": "Todos",
          "destino": "Liberia",
          "tipo": "inversionista",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Mexico",
          "origen": "Todos",
          "destino": "Liechtenstein",
          "tipo": "inversionista",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Mexico",
          "origen": "Todos",
          "destino": "Lituania",
          "tipo": "inversionista",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Mexico",
          "origen": "Todos",
          "destino": "Luxemburgo",
          "tipo": "inversionista",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Mexico",
          "origen": "Todos",
          "destino": "Malta",
          "tipo": "inversionista",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Mexico",
          "origen": "Todos",
          "destino": "Marruecos",
          "tipo": "inversionista",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Mexico",
          "origen": "Todos",
          "destino": "México",
          "tipo": "inversionista",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Mexico",
          "origen": "Todos",
          "destino": "Mónaco",
          "tipo": "inversionista",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Mexico",
          "origen": "Todos",
          "destino": "Montenegro",
          "tipo": "inversionista",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Mexico",
          "origen": "Todos",
          "destino": "Noruega",
          "tipo": "inversionista",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Mexico",
          "origen": "Todos",
          "destino": "Nueva Zelanda",
          "tipo": "inversionista",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Mexico",
          "origen": "Todos",
          "destino": "Países Bajos",
          "tipo": "inversionista",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Mexico",
          "origen": "Todos",
          "destino": "Polonia",
          "tipo": "inversionista",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Mexico",
          "origen": "Todos",
          "destino": "Portugal",
          "tipo": "inversionista",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Mexico",
          "origen": "Todos",
          "destino": "Reino Unido",
          "tipo": "inversionista",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Mexico",
          "origen": "Todos",
          "destino": "República Checa",
          "tipo": "inversionista",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Mexico",
          "origen": "Todos",
          "destino": "Rumanía",
          "tipo": "inversionista",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Mexico",
          "origen": "Todos",
          "destino": "Serbia",
          "tipo": "inversionista",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Mexico",
          "origen": "Todos",
          "destino": "Suecia",
          "tipo": "inversionista",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Mexico",
          "origen": "Todos",
          "destino": "Suiza",
          "tipo": "inversionista",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Mexico",
          "origen": "Todos",
          "destino": "Túnez",
          "tipo": "inversionista",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Mexico",
          "origen": "Todos",
          "destino": "Albania",
          "tipo": "inversionista",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Mexico",
          "origen": "Todos",
          "destino": "Australia",
          "tipo": "inversionista",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Mexico",
          "origen": "Todos",
          "destino": "Emiratos Árabes Unidos",
          "tipo": "inversionista",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Mexico",
          "origen": "Todos",
          "destino": "India",
          "tipo": "inversionista",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Mexico",
          "origen": "Todos",
          "destino": "Libia",
          "tipo": "inversionista",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Mexico",
          "origen": "Todos",
          "destino": "Taiwán",
          "tipo": "inversionista",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Mexico",
          "origen": "Todos",
          "destino": "Vietnam",
          "tipo": "inversionista",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Mexico",
          "origen": "Todos",
          "destino": "Corea del Sur",
          "tipo": "inversionista",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Mexico",
          "origen": "Todos",
          "destino": "Argentina",
          "tipo": "inversionista",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Mexico",
          "origen": "Todos",
          "destino": "Bolivia",
          "tipo": "inversionista",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Mexico",
          "origen": "Todos",
          "destino": "Brasil",
          "tipo": "inversionista",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Mexico",
          "origen": "Todos",
          "destino": "Chile",
          "tipo": "inversionista",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Mexico",
          "origen": "Todos",
          "destino": "China",
          "tipo": "inversionista",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Mexico",
          "origen": "Todos",
          "destino": "Colombia",
          "tipo": "inversionista",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Mexico",
          "origen": "Todos",
          "destino": "Curacao",
          "tipo": "inversionista",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Mexico",
          "origen": "Todos",
          "destino": "El Salvador",
          "tipo": "inversionista",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Mexico",
          "origen": "Todos",
          "destino": "Federación Rusa",
          "tipo": "inversionista",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Mexico",
          "origen": "Todos",
          "destino": "Honduras",
          "tipo": "inversionista",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Mexico",
          "origen": "Todos",
          "destino": "Hong Kong",
          "tipo": "inversionista",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Mexico",
          "origen": "Todos",
          "destino": "Israel",
          "tipo": "inversionista",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Mexico",
          "origen": "Todos",
          "destino": "Jamaica",
          "tipo": "inversionista",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Mexico",
          "origen": "Todos",
          "destino": "Nicaragua",
          "tipo": "inversionista",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Mexico",
          "origen": "Todos",
          "destino": "Panamá",
          "tipo": "inversionista",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Mexico",
          "origen": "Todos",
          "destino": "Paraguay",
          "tipo": "inversionista",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Mexico",
          "origen": "Todos",
          "destino": "Perú",
          "tipo": "inversionista",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Mexico",
          "origen": "Todos",
          "destino": "República Dominicana",
          "tipo": "inversionista",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Mexico",
          "origen": "Todos",
          "destino": "Singapur",
          "tipo": "inversionista",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Mexico",
          "origen": "Todos",
          "destino": "Sudáfrica",
          "tipo": "inversionista",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Mexico",
          "origen": "Todos",
          "destino": "Tailandia",
          "tipo": "inversionista",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Mexico",
          "origen": "Todos",
          "destino": "Turquía",
          "tipo": "inversionista",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Mexico",
          "origen": "Todos",
          "destino": "Uruguay",
          "tipo": "inversionista",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Mexico",
          "origen": "Todos",
          "destino": "Venezuela",
          "tipo": "inversionista",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Ecuador",
          "origen": "Todos",
          "destino": "Alemania",
          "tipo": "investigacion",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Ecuador",
          "origen": "Todos",
          "destino": "Andorra",
          "tipo": "investigacion",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Ecuador",
          "origen": "Todos",
          "destino": "Arabia Saudí",
          "tipo": "investigacion",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Ecuador",
          "origen": "Todos",
          "destino": "Argelia",
          "tipo": "investigacion",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Ecuador",
          "origen": "Todos",
          "destino": "Austria",
          "tipo": "investigacion",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Ecuador",
          "origen": "Todos",
          "destino": "Bélgica",
          "tipo": "investigacion",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Ecuador",
          "origen": "Todos",
          "destino": "Bulgaria",
          "tipo": "investigacion",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Ecuador",
          "origen": "Todos",
          "destino": "Canadá",
          "tipo": "investigacion",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Ecuador",
          "origen": "Todos",
          "destino": "Costa Rica",
          "tipo": "investigacion",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Ecuador",
          "origen": "Todos",
          "destino": "Croacia",
          "tipo": "investigacion",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Ecuador",
          "origen": "Todos",
          "destino": "Cuba",
          "tipo": "investigacion",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Ecuador",
          "origen": "Todos",
          "destino": "Dinamarca",
          "tipo": "investigacion",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Ecuador",
          "origen": "Todos",
          "destino": "Eslovaquia",
          "tipo": "investigacion",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Ecuador",
          "origen": "Todos",
          "destino": "Eslovenia",
          "tipo": "investigacion",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Ecuador",
          "origen": "Todos",
          "destino": "España",
          "tipo": "investigacion",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Ecuador",
          "origen": "Todos",
          "destino": "Estados Unidos de América",
          "tipo": "investigacion",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Ecuador",
          "origen": "Todos",
          "destino": "Estonia",
          "tipo": "investigacion",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Ecuador",
          "origen": "Todos",
          "destino": "Finlandia",
          "tipo": "investigacion",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Ecuador",
          "origen": "Todos",
          "destino": "Francia",
          "tipo": "investigacion",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Ecuador",
          "origen": "Todos",
          "destino": "Grecia",
          "tipo": "investigacion",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Ecuador",
          "origen": "Todos",
          "destino": "Guatemala",
          "tipo": "investigacion",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Ecuador",
          "origen": "Todos",
          "destino": "Hungría",
          "tipo": "investigacion",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Ecuador",
          "origen": "Todos",
          "destino": "Irlanda",
          "tipo": "investigacion",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Ecuador",
          "origen": "Todos",
          "destino": "Islandia",
          "tipo": "investigacion",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Ecuador",
          "origen": "Todos",
          "destino": "Italia",
          "tipo": "investigacion",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Ecuador",
          "origen": "Todos",
          "destino": "Japón",
          "tipo": "investigacion",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Ecuador",
          "origen": "Todos",
          "destino": "Letonia",
          "tipo": "investigacion",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Ecuador",
          "origen": "Todos",
          "destino": "Líbano",
          "tipo": "investigacion",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Ecuador",
          "origen": "Todos",
          "destino": "Liberia",
          "tipo": "investigacion",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Ecuador",
          "origen": "Todos",
          "destino": "Liechtenstein",
          "tipo": "investigacion",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Ecuador",
          "origen": "Todos",
          "destino": "Lituania",
          "tipo": "investigacion",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Ecuador",
          "origen": "Todos",
          "destino": "Luxemburgo",
          "tipo": "investigacion",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Ecuador",
          "origen": "Todos",
          "destino": "Malta",
          "tipo": "investigacion",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Ecuador",
          "origen": "Todos",
          "destino": "Marruecos",
          "tipo": "investigacion",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Ecuador",
          "origen": "Todos",
          "destino": "México",
          "tipo": "investigacion",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Ecuador",
          "origen": "Todos",
          "destino": "Mónaco",
          "tipo": "investigacion",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Ecuador",
          "origen": "Todos",
          "destino": "Montenegro",
          "tipo": "investigacion",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Ecuador",
          "origen": "Todos",
          "destino": "Noruega",
          "tipo": "investigacion",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Ecuador",
          "origen": "Todos",
          "destino": "Nueva Zelanda",
          "tipo": "investigacion",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Ecuador",
          "origen": "Todos",
          "destino": "Países Bajos",
          "tipo": "investigacion",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Ecuador",
          "origen": "Todos",
          "destino": "Polonia",
          "tipo": "investigacion",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Ecuador",
          "origen": "Todos",
          "destino": "Portugal",
          "tipo": "investigacion",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Ecuador",
          "origen": "Todos",
          "destino": "Reino Unido",
          "tipo": "investigacion",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Ecuador",
          "origen": "Todos",
          "destino": "República Checa",
          "tipo": "investigacion",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Ecuador",
          "origen": "Todos",
          "destino": "Rumanía",
          "tipo": "investigacion",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Ecuador",
          "origen": "Todos",
          "destino": "Serbia",
          "tipo": "investigacion",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Ecuador",
          "origen": "Todos",
          "destino": "Suecia",
          "tipo": "investigacion",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Ecuador",
          "origen": "Todos",
          "destino": "Suiza",
          "tipo": "investigacion",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Ecuador",
          "origen": "Todos",
          "destino": "Túnez",
          "tipo": "investigacion",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Ecuador",
          "origen": "Todos",
          "destino": "Albania",
          "tipo": "investigacion",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Ecuador",
          "origen": "Todos",
          "destino": "Australia",
          "tipo": "investigacion",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Ecuador",
          "origen": "Todos",
          "destino": "Emiratos Árabes Unidos",
          "tipo": "investigacion",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Ecuador",
          "origen": "Todos",
          "destino": "India",
          "tipo": "investigacion",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Ecuador",
          "origen": "Todos",
          "destino": "Libia",
          "tipo": "investigacion",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Ecuador",
          "origen": "Todos",
          "destino": "Taiwán",
          "tipo": "investigacion",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Ecuador",
          "origen": "Todos",
          "destino": "Vietnam",
          "tipo": "investigacion",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Ecuador",
          "origen": "Todos",
          "destino": "Corea del Sur",
          "tipo": "investigacion",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Ecuador",
          "origen": "Todos",
          "destino": "Argentina",
          "tipo": "investigacion",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Ecuador",
          "origen": "Todos",
          "destino": "Bolivia",
          "tipo": "investigacion",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Ecuador",
          "origen": "Todos",
          "destino": "Brasil",
          "tipo": "investigacion",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Ecuador",
          "origen": "Todos",
          "destino": "Chile",
          "tipo": "investigacion",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Ecuador",
          "origen": "Todos",
          "destino": "China",
          "tipo": "investigacion",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Ecuador",
          "origen": "Todos",
          "destino": "Colombia",
          "tipo": "investigacion",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Ecuador",
          "origen": "Todos",
          "destino": "Curacao",
          "tipo": "investigacion",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Ecuador",
          "origen": "Todos",
          "destino": "El Salvador",
          "tipo": "investigacion",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Ecuador",
          "origen": "Todos",
          "destino": "Federación Rusa",
          "tipo": "investigacion",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Ecuador",
          "origen": "Todos",
          "destino": "Honduras",
          "tipo": "investigacion",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Ecuador",
          "origen": "Todos",
          "destino": "Hong Kong",
          "tipo": "investigacion",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Ecuador",
          "origen": "Todos",
          "destino": "Israel",
          "tipo": "investigacion",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Ecuador",
          "origen": "Todos",
          "destino": "Jamaica",
          "tipo": "investigacion",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Ecuador",
          "origen": "Todos",
          "destino": "Nicaragua",
          "tipo": "investigacion",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Ecuador",
          "origen": "Todos",
          "destino": "Panamá",
          "tipo": "investigacion",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Ecuador",
          "origen": "Todos",
          "destino": "Paraguay",
          "tipo": "investigacion",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Ecuador",
          "origen": "Todos",
          "destino": "Perú",
          "tipo": "investigacion",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Ecuador",
          "origen": "Todos",
          "destino": "República Dominicana",
          "tipo": "investigacion",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Ecuador",
          "origen": "Todos",
          "destino": "Singapur",
          "tipo": "investigacion",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Ecuador",
          "origen": "Todos",
          "destino": "Sudáfrica",
          "tipo": "investigacion",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Ecuador",
          "origen": "Todos",
          "destino": "Tailandia",
          "tipo": "investigacion",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Ecuador",
          "origen": "Todos",
          "destino": "Turquía",
          "tipo": "investigacion",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Ecuador",
          "origen": "Todos",
          "destino": "Uruguay",
          "tipo": "investigacion",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Ecuador",
          "origen": "Todos",
          "destino": "Venezuela",
          "tipo": "investigacion",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Colombia",
          "origen": "Todos",
          "destino": "Alemania",
          "tipo": "investigacion",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Colombia",
          "origen": "Todos",
          "destino": "Andorra",
          "tipo": "investigacion",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Colombia",
          "origen": "Todos",
          "destino": "Arabia Saudí",
          "tipo": "investigacion",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Colombia",
          "origen": "Todos",
          "destino": "Argelia",
          "tipo": "investigacion",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Colombia",
          "origen": "Todos",
          "destino": "Austria",
          "tipo": "investigacion",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Colombia",
          "origen": "Todos",
          "destino": "Bélgica",
          "tipo": "investigacion",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Colombia",
          "origen": "Todos",
          "destino": "Bulgaria",
          "tipo": "investigacion",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Colombia",
          "origen": "Todos",
          "destino": "Canadá",
          "tipo": "investigacion",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Colombia",
          "origen": "Todos",
          "destino": "Costa Rica",
          "tipo": "investigacion",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Colombia",
          "origen": "Todos",
          "destino": "Croacia",
          "tipo": "investigacion",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Colombia",
          "origen": "Todos",
          "destino": "Cuba",
          "tipo": "investigacion",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Colombia",
          "origen": "Todos",
          "destino": "Dinamarca",
          "tipo": "investigacion",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Colombia",
          "origen": "Todos",
          "destino": "Eslovaquia",
          "tipo": "investigacion",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Colombia",
          "origen": "Todos",
          "destino": "Eslovenia",
          "tipo": "investigacion",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Colombia",
          "origen": "Todos",
          "destino": "España",
          "tipo": "investigacion",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Colombia",
          "origen": "Todos",
          "destino": "Estados Unidos de América",
          "tipo": "investigacion",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Colombia",
          "origen": "Todos",
          "destino": "Estonia",
          "tipo": "investigacion",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Colombia",
          "origen": "Todos",
          "destino": "Finlandia",
          "tipo": "investigacion",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Colombia",
          "origen": "Todos",
          "destino": "Francia",
          "tipo": "investigacion",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Colombia",
          "origen": "Todos",
          "destino": "Grecia",
          "tipo": "investigacion",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Colombia",
          "origen": "Todos",
          "destino": "Guatemala",
          "tipo": "investigacion",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Colombia",
          "origen": "Todos",
          "destino": "Hungría",
          "tipo": "investigacion",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Colombia",
          "origen": "Todos",
          "destino": "Irlanda",
          "tipo": "investigacion",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Colombia",
          "origen": "Todos",
          "destino": "Islandia",
          "tipo": "investigacion",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Colombia",
          "origen": "Todos",
          "destino": "Italia",
          "tipo": "investigacion",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Colombia",
          "origen": "Todos",
          "destino": "Japón",
          "tipo": "investigacion",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Colombia",
          "origen": "Todos",
          "destino": "Letonia",
          "tipo": "investigacion",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Colombia",
          "origen": "Todos",
          "destino": "Líbano",
          "tipo": "investigacion",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Colombia",
          "origen": "Todos",
          "destino": "Liberia",
          "tipo": "investigacion",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Colombia",
          "origen": "Todos",
          "destino": "Liechtenstein",
          "tipo": "investigacion",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Colombia",
          "origen": "Todos",
          "destino": "Lituania",
          "tipo": "investigacion",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Colombia",
          "origen": "Todos",
          "destino": "Luxemburgo",
          "tipo": "investigacion",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Colombia",
          "origen": "Todos",
          "destino": "Malta",
          "tipo": "investigacion",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Colombia",
          "origen": "Todos",
          "destino": "Marruecos",
          "tipo": "investigacion",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Colombia",
          "origen": "Todos",
          "destino": "México",
          "tipo": "investigacion",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Colombia",
          "origen": "Todos",
          "destino": "Mónaco",
          "tipo": "investigacion",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Colombia",
          "origen": "Todos",
          "destino": "Montenegro",
          "tipo": "investigacion",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Colombia",
          "origen": "Todos",
          "destino": "Noruega",
          "tipo": "investigacion",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Colombia",
          "origen": "Todos",
          "destino": "Nueva Zelanda",
          "tipo": "investigacion",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Colombia",
          "origen": "Todos",
          "destino": "Países Bajos",
          "tipo": "investigacion",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Colombia",
          "origen": "Todos",
          "destino": "Polonia",
          "tipo": "investigacion",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Colombia",
          "origen": "Todos",
          "destino": "Portugal",
          "tipo": "investigacion",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Colombia",
          "origen": "Todos",
          "destino": "Reino Unido",
          "tipo": "investigacion",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Colombia",
          "origen": "Todos",
          "destino": "República Checa",
          "tipo": "investigacion",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Colombia",
          "origen": "Todos",
          "destino": "Rumanía",
          "tipo": "investigacion",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Colombia",
          "origen": "Todos",
          "destino": "Serbia",
          "tipo": "investigacion",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Colombia",
          "origen": "Todos",
          "destino": "Suecia",
          "tipo": "investigacion",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Colombia",
          "origen": "Todos",
          "destino": "Suiza",
          "tipo": "investigacion",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Colombia",
          "origen": "Todos",
          "destino": "Túnez",
          "tipo": "investigacion",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Colombia",
          "origen": "Todos",
          "destino": "Albania",
          "tipo": "investigacion",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Colombia",
          "origen": "Todos",
          "destino": "Australia",
          "tipo": "investigacion",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Colombia",
          "origen": "Todos",
          "destino": "Emiratos Árabes Unidos",
          "tipo": "investigacion",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Colombia",
          "origen": "Todos",
          "destino": "India",
          "tipo": "investigacion",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Colombia",
          "origen": "Todos",
          "destino": "Libia",
          "tipo": "investigacion",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Colombia",
          "origen": "Todos",
          "destino": "Taiwán",
          "tipo": "investigacion",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Colombia",
          "origen": "Todos",
          "destino": "Vietnam",
          "tipo": "investigacion",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Colombia",
          "origen": "Todos",
          "destino": "Corea del Sur",
          "tipo": "investigacion",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Colombia",
          "origen": "Todos",
          "destino": "Argentina",
          "tipo": "investigacion",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Colombia",
          "origen": "Todos",
          "destino": "Bolivia",
          "tipo": "investigacion",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Colombia",
          "origen": "Todos",
          "destino": "Brasil",
          "tipo": "investigacion",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Colombia",
          "origen": "Todos",
          "destino": "Chile",
          "tipo": "investigacion",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Colombia",
          "origen": "Todos",
          "destino": "China",
          "tipo": "investigacion",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Colombia",
          "origen": "Todos",
          "destino": "Colombia",
          "tipo": "investigacion",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Colombia",
          "origen": "Todos",
          "destino": "Curacao",
          "tipo": "investigacion",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Colombia",
          "origen": "Todos",
          "destino": "El Salvador",
          "tipo": "investigacion",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Colombia",
          "origen": "Todos",
          "destino": "Federación Rusa",
          "tipo": "investigacion",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Colombia",
          "origen": "Todos",
          "destino": "Honduras",
          "tipo": "investigacion",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Colombia",
          "origen": "Todos",
          "destino": "Hong Kong",
          "tipo": "investigacion",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Colombia",
          "origen": "Todos",
          "destino": "Israel",
          "tipo": "investigacion",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Colombia",
          "origen": "Todos",
          "destino": "Jamaica",
          "tipo": "investigacion",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Colombia",
          "origen": "Todos",
          "destino": "Nicaragua",
          "tipo": "investigacion",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Colombia",
          "origen": "Todos",
          "destino": "Panamá",
          "tipo": "investigacion",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Colombia",
          "origen": "Todos",
          "destino": "Paraguay",
          "tipo": "investigacion",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Colombia",
          "origen": "Todos",
          "destino": "Perú",
          "tipo": "investigacion",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Colombia",
          "origen": "Todos",
          "destino": "República Dominicana",
          "tipo": "investigacion",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Colombia",
          "origen": "Todos",
          "destino": "Singapur",
          "tipo": "investigacion",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Colombia",
          "origen": "Todos",
          "destino": "Sudáfrica",
          "tipo": "investigacion",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Colombia",
          "origen": "Todos",
          "destino": "Tailandia",
          "tipo": "investigacion",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Colombia",
          "origen": "Todos",
          "destino": "Turquía",
          "tipo": "investigacion",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Colombia",
          "origen": "Todos",
          "destino": "Uruguay",
          "tipo": "investigacion",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Colombia",
          "origen": "Todos",
          "destino": "Venezuela",
          "tipo": "investigacion",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Peru",
          "origen": "Todos",
          "destino": "Alemania",
          "tipo": "investigacion",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Peru",
          "origen": "Todos",
          "destino": "Andorra",
          "tipo": "investigacion",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Peru",
          "origen": "Todos",
          "destino": "Arabia Saudí",
          "tipo": "investigacion",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Peru",
          "origen": "Todos",
          "destino": "Argelia",
          "tipo": "investigacion",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Peru",
          "origen": "Todos",
          "destino": "Austria",
          "tipo": "investigacion",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Peru",
          "origen": "Todos",
          "destino": "Bélgica",
          "tipo": "investigacion",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Peru",
          "origen": "Todos",
          "destino": "Bulgaria",
          "tipo": "investigacion",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Peru",
          "origen": "Todos",
          "destino": "Canadá",
          "tipo": "investigacion",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Peru",
          "origen": "Todos",
          "destino": "Costa Rica",
          "tipo": "investigacion",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Peru",
          "origen": "Todos",
          "destino": "Croacia",
          "tipo": "investigacion",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Peru",
          "origen": "Todos",
          "destino": "Cuba",
          "tipo": "investigacion",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Peru",
          "origen": "Todos",
          "destino": "Dinamarca",
          "tipo": "investigacion",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Peru",
          "origen": "Todos",
          "destino": "Eslovaquia",
          "tipo": "investigacion",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Peru",
          "origen": "Todos",
          "destino": "Eslovenia",
          "tipo": "investigacion",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Peru",
          "origen": "Todos",
          "destino": "España",
          "tipo": "investigacion",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Peru",
          "origen": "Todos",
          "destino": "Estados Unidos de América",
          "tipo": "investigacion",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Peru",
          "origen": "Todos",
          "destino": "Estonia",
          "tipo": "investigacion",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Peru",
          "origen": "Todos",
          "destino": "Finlandia",
          "tipo": "investigacion",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Peru",
          "origen": "Todos",
          "destino": "Francia",
          "tipo": "investigacion",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Peru",
          "origen": "Todos",
          "destino": "Grecia",
          "tipo": "investigacion",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Peru",
          "origen": "Todos",
          "destino": "Guatemala",
          "tipo": "investigacion",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Peru",
          "origen": "Todos",
          "destino": "Hungría",
          "tipo": "investigacion",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Peru",
          "origen": "Todos",
          "destino": "Irlanda",
          "tipo": "investigacion",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Peru",
          "origen": "Todos",
          "destino": "Islandia",
          "tipo": "investigacion",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Peru",
          "origen": "Todos",
          "destino": "Italia",
          "tipo": "investigacion",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Peru",
          "origen": "Todos",
          "destino": "Japón",
          "tipo": "investigacion",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Peru",
          "origen": "Todos",
          "destino": "Letonia",
          "tipo": "investigacion",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Peru",
          "origen": "Todos",
          "destino": "Líbano",
          "tipo": "investigacion",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Peru",
          "origen": "Todos",
          "destino": "Liberia",
          "tipo": "investigacion",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Peru",
          "origen": "Todos",
          "destino": "Liechtenstein",
          "tipo": "investigacion",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Peru",
          "origen": "Todos",
          "destino": "Lituania",
          "tipo": "investigacion",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Peru",
          "origen": "Todos",
          "destino": "Luxemburgo",
          "tipo": "investigacion",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Peru",
          "origen": "Todos",
          "destino": "Malta",
          "tipo": "investigacion",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Peru",
          "origen": "Todos",
          "destino": "Marruecos",
          "tipo": "investigacion",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Peru",
          "origen": "Todos",
          "destino": "México",
          "tipo": "investigacion",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Peru",
          "origen": "Todos",
          "destino": "Mónaco",
          "tipo": "investigacion",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Peru",
          "origen": "Todos",
          "destino": "Montenegro",
          "tipo": "investigacion",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Peru",
          "origen": "Todos",
          "destino": "Noruega",
          "tipo": "investigacion",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Peru",
          "origen": "Todos",
          "destino": "Nueva Zelanda",
          "tipo": "investigacion",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Peru",
          "origen": "Todos",
          "destino": "Países Bajos",
          "tipo": "investigacion",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Peru",
          "origen": "Todos",
          "destino": "Polonia",
          "tipo": "investigacion",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Peru",
          "origen": "Todos",
          "destino": "Portugal",
          "tipo": "investigacion",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Peru",
          "origen": "Todos",
          "destino": "Reino Unido",
          "tipo": "investigacion",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Peru",
          "origen": "Todos",
          "destino": "República Checa",
          "tipo": "investigacion",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Peru",
          "origen": "Todos",
          "destino": "Rumanía",
          "tipo": "investigacion",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Peru",
          "origen": "Todos",
          "destino": "Serbia",
          "tipo": "investigacion",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Peru",
          "origen": "Todos",
          "destino": "Suecia",
          "tipo": "investigacion",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Peru",
          "origen": "Todos",
          "destino": "Suiza",
          "tipo": "investigacion",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Peru",
          "origen": "Todos",
          "destino": "Túnez",
          "tipo": "investigacion",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Peru",
          "origen": "Todos",
          "destino": "Albania",
          "tipo": "investigacion",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Peru",
          "origen": "Todos",
          "destino": "Australia",
          "tipo": "investigacion",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Peru",
          "origen": "Todos",
          "destino": "Emiratos Árabes Unidos",
          "tipo": "investigacion",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Peru",
          "origen": "Todos",
          "destino": "India",
          "tipo": "investigacion",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Peru",
          "origen": "Todos",
          "destino": "Libia",
          "tipo": "investigacion",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Peru",
          "origen": "Todos",
          "destino": "Taiwán",
          "tipo": "investigacion",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Peru",
          "origen": "Todos",
          "destino": "Vietnam",
          "tipo": "investigacion",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Peru",
          "origen": "Todos",
          "destino": "Corea del Sur",
          "tipo": "investigacion",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Peru",
          "origen": "Todos",
          "destino": "Argentina",
          "tipo": "investigacion",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Peru",
          "origen": "Todos",
          "destino": "Bolivia",
          "tipo": "investigacion",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Peru",
          "origen": "Todos",
          "destino": "Brasil",
          "tipo": "investigacion",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Peru",
          "origen": "Todos",
          "destino": "Chile",
          "tipo": "investigacion",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Peru",
          "origen": "Todos",
          "destino": "China",
          "tipo": "investigacion",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Peru",
          "origen": "Todos",
          "destino": "Colombia",
          "tipo": "investigacion",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Peru",
          "origen": "Todos",
          "destino": "Curacao",
          "tipo": "investigacion",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Peru",
          "origen": "Todos",
          "destino": "El Salvador",
          "tipo": "investigacion",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Peru",
          "origen": "Todos",
          "destino": "Federación Rusa",
          "tipo": "investigacion",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Peru",
          "origen": "Todos",
          "destino": "Honduras",
          "tipo": "investigacion",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Peru",
          "origen": "Todos",
          "destino": "Hong Kong",
          "tipo": "investigacion",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Peru",
          "origen": "Todos",
          "destino": "Israel",
          "tipo": "investigacion",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Peru",
          "origen": "Todos",
          "destino": "Jamaica",
          "tipo": "investigacion",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Peru",
          "origen": "Todos",
          "destino": "Nicaragua",
          "tipo": "investigacion",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Peru",
          "origen": "Todos",
          "destino": "Panamá",
          "tipo": "investigacion",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Peru",
          "origen": "Todos",
          "destino": "Paraguay",
          "tipo": "investigacion",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Peru",
          "origen": "Todos",
          "destino": "Perú",
          "tipo": "investigacion",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Peru",
          "origen": "Todos",
          "destino": "República Dominicana",
          "tipo": "investigacion",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Peru",
          "origen": "Todos",
          "destino": "Singapur",
          "tipo": "investigacion",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Peru",
          "origen": "Todos",
          "destino": "Sudáfrica",
          "tipo": "investigacion",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Peru",
          "origen": "Todos",
          "destino": "Tailandia",
          "tipo": "investigacion",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Peru",
          "origen": "Todos",
          "destino": "Turquía",
          "tipo": "investigacion",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Peru",
          "origen": "Todos",
          "destino": "Uruguay",
          "tipo": "investigacion",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Peru",
          "origen": "Todos",
          "destino": "Venezuela",
          "tipo": "investigacion",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Venezuela",
          "origen": "Todos",
          "destino": "Alemania",
          "tipo": "investigacion",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Venezuela",
          "origen": "Todos",
          "destino": "Andorra",
          "tipo": "investigacion",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Venezuela",
          "origen": "Todos",
          "destino": "Arabia Saudí",
          "tipo": "investigacion",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Venezuela",
          "origen": "Todos",
          "destino": "Argelia",
          "tipo": "investigacion",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Venezuela",
          "origen": "Todos",
          "destino": "Austria",
          "tipo": "investigacion",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Venezuela",
          "origen": "Todos",
          "destino": "Bélgica",
          "tipo": "investigacion",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Venezuela",
          "origen": "Todos",
          "destino": "Bulgaria",
          "tipo": "investigacion",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Venezuela",
          "origen": "Todos",
          "destino": "Canadá",
          "tipo": "investigacion",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Venezuela",
          "origen": "Todos",
          "destino": "Costa Rica",
          "tipo": "investigacion",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Venezuela",
          "origen": "Todos",
          "destino": "Croacia",
          "tipo": "investigacion",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Venezuela",
          "origen": "Todos",
          "destino": "Cuba",
          "tipo": "investigacion",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Venezuela",
          "origen": "Todos",
          "destino": "Dinamarca",
          "tipo": "investigacion",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Venezuela",
          "origen": "Todos",
          "destino": "Eslovaquia",
          "tipo": "investigacion",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Venezuela",
          "origen": "Todos",
          "destino": "Eslovenia",
          "tipo": "investigacion",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Venezuela",
          "origen": "Todos",
          "destino": "España",
          "tipo": "investigacion",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Venezuela",
          "origen": "Todos",
          "destino": "Estados Unidos de América",
          "tipo": "investigacion",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Venezuela",
          "origen": "Todos",
          "destino": "Estonia",
          "tipo": "investigacion",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Venezuela",
          "origen": "Todos",
          "destino": "Finlandia",
          "tipo": "investigacion",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Venezuela",
          "origen": "Todos",
          "destino": "Francia",
          "tipo": "investigacion",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Venezuela",
          "origen": "Todos",
          "destino": "Grecia",
          "tipo": "investigacion",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Venezuela",
          "origen": "Todos",
          "destino": "Guatemala",
          "tipo": "investigacion",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Venezuela",
          "origen": "Todos",
          "destino": "Hungría",
          "tipo": "investigacion",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Venezuela",
          "origen": "Todos",
          "destino": "Irlanda",
          "tipo": "investigacion",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Venezuela",
          "origen": "Todos",
          "destino": "Islandia",
          "tipo": "investigacion",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Venezuela",
          "origen": "Todos",
          "destino": "Italia",
          "tipo": "investigacion",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Venezuela",
          "origen": "Todos",
          "destino": "Japón",
          "tipo": "investigacion",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Venezuela",
          "origen": "Todos",
          "destino": "Letonia",
          "tipo": "investigacion",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Venezuela",
          "origen": "Todos",
          "destino": "Líbano",
          "tipo": "investigacion",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Venezuela",
          "origen": "Todos",
          "destino": "Liberia",
          "tipo": "investigacion",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Venezuela",
          "origen": "Todos",
          "destino": "Liechtenstein",
          "tipo": "investigacion",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Venezuela",
          "origen": "Todos",
          "destino": "Lituania",
          "tipo": "investigacion",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Venezuela",
          "origen": "Todos",
          "destino": "Luxemburgo",
          "tipo": "investigacion",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Venezuela",
          "origen": "Todos",
          "destino": "Malta",
          "tipo": "investigacion",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Venezuela",
          "origen": "Todos",
          "destino": "Marruecos",
          "tipo": "investigacion",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Venezuela",
          "origen": "Todos",
          "destino": "México",
          "tipo": "investigacion",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Venezuela",
          "origen": "Todos",
          "destino": "Mónaco",
          "tipo": "investigacion",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Venezuela",
          "origen": "Todos",
          "destino": "Montenegro",
          "tipo": "investigacion",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Venezuela",
          "origen": "Todos",
          "destino": "Noruega",
          "tipo": "investigacion",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Venezuela",
          "origen": "Todos",
          "destino": "Nueva Zelanda",
          "tipo": "investigacion",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Venezuela",
          "origen": "Todos",
          "destino": "Países Bajos",
          "tipo": "investigacion",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Venezuela",
          "origen": "Todos",
          "destino": "Polonia",
          "tipo": "investigacion",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Venezuela",
          "origen": "Todos",
          "destino": "Portugal",
          "tipo": "investigacion",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Venezuela",
          "origen": "Todos",
          "destino": "Reino Unido",
          "tipo": "investigacion",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Venezuela",
          "origen": "Todos",
          "destino": "República Checa",
          "tipo": "investigacion",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Venezuela",
          "origen": "Todos",
          "destino": "Rumanía",
          "tipo": "investigacion",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Venezuela",
          "origen": "Todos",
          "destino": "Serbia",
          "tipo": "investigacion",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Venezuela",
          "origen": "Todos",
          "destino": "Suecia",
          "tipo": "investigacion",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Venezuela",
          "origen": "Todos",
          "destino": "Suiza",
          "tipo": "investigacion",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Venezuela",
          "origen": "Todos",
          "destino": "Túnez",
          "tipo": "investigacion",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Venezuela",
          "origen": "Todos",
          "destino": "Albania",
          "tipo": "investigacion",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Venezuela",
          "origen": "Todos",
          "destino": "Australia",
          "tipo": "investigacion",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Venezuela",
          "origen": "Todos",
          "destino": "Emiratos Árabes Unidos",
          "tipo": "investigacion",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Venezuela",
          "origen": "Todos",
          "destino": "India",
          "tipo": "investigacion",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Venezuela",
          "origen": "Todos",
          "destino": "Libia",
          "tipo": "investigacion",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Venezuela",
          "origen": "Todos",
          "destino": "Taiwán",
          "tipo": "investigacion",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Venezuela",
          "origen": "Todos",
          "destino": "Vietnam",
          "tipo": "investigacion",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Venezuela",
          "origen": "Todos",
          "destino": "Corea del Sur",
          "tipo": "investigacion",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Venezuela",
          "origen": "Todos",
          "destino": "Argentina",
          "tipo": "investigacion",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Venezuela",
          "origen": "Todos",
          "destino": "Bolivia",
          "tipo": "investigacion",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Venezuela",
          "origen": "Todos",
          "destino": "Brasil",
          "tipo": "investigacion",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Venezuela",
          "origen": "Todos",
          "destino": "Chile",
          "tipo": "investigacion",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Venezuela",
          "origen": "Todos",
          "destino": "China",
          "tipo": "investigacion",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Venezuela",
          "origen": "Todos",
          "destino": "Colombia",
          "tipo": "investigacion",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Venezuela",
          "origen": "Todos",
          "destino": "Curacao",
          "tipo": "investigacion",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Venezuela",
          "origen": "Todos",
          "destino": "El Salvador",
          "tipo": "investigacion",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Venezuela",
          "origen": "Todos",
          "destino": "Federación Rusa",
          "tipo": "investigacion",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Venezuela",
          "origen": "Todos",
          "destino": "Honduras",
          "tipo": "investigacion",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Venezuela",
          "origen": "Todos",
          "destino": "Hong Kong",
          "tipo": "investigacion",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Venezuela",
          "origen": "Todos",
          "destino": "Israel",
          "tipo": "investigacion",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Venezuela",
          "origen": "Todos",
          "destino": "Jamaica",
          "tipo": "investigacion",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Venezuela",
          "origen": "Todos",
          "destino": "Nicaragua",
          "tipo": "investigacion",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Venezuela",
          "origen": "Todos",
          "destino": "Panamá",
          "tipo": "investigacion",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Venezuela",
          "origen": "Todos",
          "destino": "Paraguay",
          "tipo": "investigacion",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Venezuela",
          "origen": "Todos",
          "destino": "Perú",
          "tipo": "investigacion",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Venezuela",
          "origen": "Todos",
          "destino": "República Dominicana",
          "tipo": "investigacion",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Venezuela",
          "origen": "Todos",
          "destino": "Singapur",
          "tipo": "investigacion",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Venezuela",
          "origen": "Todos",
          "destino": "Sudáfrica",
          "tipo": "investigacion",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Venezuela",
          "origen": "Todos",
          "destino": "Tailandia",
          "tipo": "investigacion",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Venezuela",
          "origen": "Todos",
          "destino": "Turquía",
          "tipo": "investigacion",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Venezuela",
          "origen": "Todos",
          "destino": "Uruguay",
          "tipo": "investigacion",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Venezuela",
          "origen": "Todos",
          "destino": "Venezuela",
          "tipo": "investigacion",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "El Salvador",
          "origen": "Todos",
          "destino": "Andorra",
          "tipo": "investigacion",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "El Salvador",
          "origen": "Todos",
          "destino": "Arabia Saudí",
          "tipo": "investigacion",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "El Salvador",
          "origen": "Todos",
          "destino": "Argelia",
          "tipo": "investigacion",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "El Salvador",
          "origen": "Todos",
          "destino": "Austria",
          "tipo": "investigacion",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "El Salvador",
          "origen": "Todos",
          "destino": "Bélgica",
          "tipo": "investigacion",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "El Salvador",
          "origen": "Todos",
          "destino": "Bulgaria",
          "tipo": "investigacion",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "El Salvador",
          "origen": "Todos",
          "destino": "Canadá",
          "tipo": "investigacion",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "El Salvador",
          "origen": "Todos",
          "destino": "Costa Rica",
          "tipo": "investigacion",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "El Salvador",
          "origen": "Todos",
          "destino": "Croacia",
          "tipo": "investigacion",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "El Salvador",
          "origen": "Todos",
          "destino": "Cuba",
          "tipo": "investigacion",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "El Salvador",
          "origen": "Todos",
          "destino": "Dinamarca",
          "tipo": "investigacion",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "El Salvador",
          "origen": "Todos",
          "destino": "Eslovaquia",
          "tipo": "investigacion",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "El Salvador",
          "origen": "Todos",
          "destino": "Eslovenia",
          "tipo": "investigacion",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "El Salvador",
          "origen": "Todos",
          "destino": "España",
          "tipo": "investigacion",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "El Salvador",
          "origen": "Todos",
          "destino": "Estados Unidos de América",
          "tipo": "investigacion",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "El Salvador",
          "origen": "Todos",
          "destino": "Estonia",
          "tipo": "investigacion",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "El Salvador",
          "origen": "Todos",
          "destino": "Finlandia",
          "tipo": "investigacion",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "El Salvador",
          "origen": "Todos",
          "destino": "Francia",
          "tipo": "investigacion",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "El Salvador",
          "origen": "Todos",
          "destino": "Grecia",
          "tipo": "investigacion",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "El Salvador",
          "origen": "Todos",
          "destino": "Guatemala",
          "tipo": "investigacion",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "El Salvador",
          "origen": "Todos",
          "destino": "Hungría",
          "tipo": "investigacion",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "El Salvador",
          "origen": "Todos",
          "destino": "Irlanda",
          "tipo": "investigacion",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "El Salvador",
          "origen": "Todos",
          "destino": "Islandia",
          "tipo": "investigacion",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "El Salvador",
          "origen": "Todos",
          "destino": "Italia",
          "tipo": "investigacion",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "El Salvador",
          "origen": "Todos",
          "destino": "Japón",
          "tipo": "investigacion",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "El Salvador",
          "origen": "Todos",
          "destino": "Letonia",
          "tipo": "investigacion",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "El Salvador",
          "origen": "Todos",
          "destino": "Líbano",
          "tipo": "investigacion",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "El Salvador",
          "origen": "Todos",
          "destino": "Liberia",
          "tipo": "investigacion",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "El Salvador",
          "origen": "Todos",
          "destino": "Liechtenstein",
          "tipo": "investigacion",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "El Salvador",
          "origen": "Todos",
          "destino": "Lituania",
          "tipo": "investigacion",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "El Salvador",
          "origen": "Todos",
          "destino": "Luxemburgo",
          "tipo": "investigacion",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "El Salvador",
          "origen": "Todos",
          "destino": "Malta",
          "tipo": "investigacion",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "El Salvador",
          "origen": "Todos",
          "destino": "Marruecos",
          "tipo": "investigacion",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "El Salvador",
          "origen": "Todos",
          "destino": "México",
          "tipo": "investigacion",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "El Salvador",
          "origen": "Todos",
          "destino": "Mónaco",
          "tipo": "investigacion",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "El Salvador",
          "origen": "Todos",
          "destino": "Montenegro",
          "tipo": "investigacion",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "El Salvador",
          "origen": "Todos",
          "destino": "Noruega",
          "tipo": "investigacion",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "El Salvador",
          "origen": "Todos",
          "destino": "Nueva Zelanda",
          "tipo": "investigacion",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "El Salvador",
          "origen": "Todos",
          "destino": "Países Bajos",
          "tipo": "investigacion",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "El Salvador",
          "origen": "Todos",
          "destino": "Polonia",
          "tipo": "investigacion",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "El Salvador",
          "origen": "Todos",
          "destino": "Portugal",
          "tipo": "investigacion",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "El Salvador",
          "origen": "Todos",
          "destino": "Reino Unido",
          "tipo": "investigacion",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "El Salvador",
          "origen": "Todos",
          "destino": "República Checa",
          "tipo": "investigacion",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "El Salvador",
          "origen": "Todos",
          "destino": "Rumanía",
          "tipo": "investigacion",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "El Salvador",
          "origen": "Todos",
          "destino": "Serbia",
          "tipo": "investigacion",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "El Salvador",
          "origen": "Todos",
          "destino": "Suecia",
          "tipo": "investigacion",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "El Salvador",
          "origen": "Todos",
          "destino": "Suiza",
          "tipo": "investigacion",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "El Salvador",
          "origen": "Todos",
          "destino": "Túnez",
          "tipo": "investigacion",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "El Salvador",
          "origen": "Todos",
          "destino": "Albania",
          "tipo": "investigacion",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "El Salvador",
          "origen": "Todos",
          "destino": "Australia",
          "tipo": "investigacion",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "El Salvador",
          "origen": "Todos",
          "destino": "Emiratos Árabes Unidos",
          "tipo": "investigacion",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "El Salvador",
          "origen": "Todos",
          "destino": "India",
          "tipo": "investigacion",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "El Salvador",
          "origen": "Todos",
          "destino": "Libia",
          "tipo": "investigacion",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "El Salvador",
          "origen": "Todos",
          "destino": "Taiwán",
          "tipo": "investigacion",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "El Salvador",
          "origen": "Todos",
          "destino": "Vietnam",
          "tipo": "investigacion",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "El Salvador",
          "origen": "Todos",
          "destino": "Corea del Sur",
          "tipo": "investigacion",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "El Salvador",
          "origen": "Todos",
          "destino": "Argentina",
          "tipo": "investigacion",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "El Salvador",
          "origen": "Todos",
          "destino": "Bolivia",
          "tipo": "investigacion",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "El Salvador",
          "origen": "Todos",
          "destino": "Brasil",
          "tipo": "investigacion",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "El Salvador",
          "origen": "Todos",
          "destino": "Chile",
          "tipo": "investigacion",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "El Salvador",
          "origen": "Todos",
          "destino": "China",
          "tipo": "investigacion",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "El Salvador",
          "origen": "Todos",
          "destino": "Colombia",
          "tipo": "investigacion",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "El Salvador",
          "origen": "Todos",
          "destino": "Curacao",
          "tipo": "investigacion",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "El Salvador",
          "origen": "Todos",
          "destino": "El Salvador",
          "tipo": "investigacion",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "El Salvador",
          "origen": "Todos",
          "destino": "Federación Rusa",
          "tipo": "investigacion",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "El Salvador",
          "origen": "Todos",
          "destino": "Honduras",
          "tipo": "investigacion",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "El Salvador",
          "origen": "Todos",
          "destino": "Hong Kong",
          "tipo": "investigacion",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "El Salvador",
          "origen": "Todos",
          "destino": "Israel",
          "tipo": "investigacion",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "El Salvador",
          "origen": "Todos",
          "destino": "Jamaica",
          "tipo": "investigacion",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "El Salvador",
          "origen": "Todos",
          "destino": "Nicaragua",
          "tipo": "investigacion",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "El Salvador",
          "origen": "Todos",
          "destino": "Panamá",
          "tipo": "investigacion",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "El Salvador",
          "origen": "Todos",
          "destino": "Paraguay",
          "tipo": "investigacion",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "El Salvador",
          "origen": "Todos",
          "destino": "Perú",
          "tipo": "investigacion",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "El Salvador",
          "origen": "Todos",
          "destino": "República Dominicana",
          "tipo": "investigacion",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "El Salvador",
          "origen": "Todos",
          "destino": "Singapur",
          "tipo": "investigacion",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "El Salvador",
          "origen": "Todos",
          "destino": "Sudáfrica",
          "tipo": "investigacion",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "El Salvador",
          "origen": "Todos",
          "destino": "Tailandia",
          "tipo": "investigacion",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "El Salvador",
          "origen": "Todos",
          "destino": "Turquía",
          "tipo": "investigacion",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "El Salvador",
          "origen": "Todos",
          "destino": "Uruguay",
          "tipo": "investigacion",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "El Salvador",
          "origen": "Todos",
          "destino": "Venezuela",
          "tipo": "investigacion",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Mexico",
          "origen": "Todos",
          "destino": "Andorra",
          "tipo": "investigacion",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Mexico",
          "origen": "Todos",
          "destino": "Arabia Saudí",
          "tipo": "investigacion",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Mexico",
          "origen": "Todos",
          "destino": "Argelia",
          "tipo": "investigacion",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Mexico",
          "origen": "Todos",
          "destino": "Austria",
          "tipo": "investigacion",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Mexico",
          "origen": "Todos",
          "destino": "Bélgica",
          "tipo": "investigacion",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Mexico",
          "origen": "Todos",
          "destino": "Bulgaria",
          "tipo": "investigacion",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Mexico",
          "origen": "Todos",
          "destino": "Canadá",
          "tipo": "investigacion",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Mexico",
          "origen": "Todos",
          "destino": "Costa Rica",
          "tipo": "investigacion",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Mexico",
          "origen": "Todos",
          "destino": "Croacia",
          "tipo": "investigacion",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Mexico",
          "origen": "Todos",
          "destino": "Cuba",
          "tipo": "investigacion",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Mexico",
          "origen": "Todos",
          "destino": "Dinamarca",
          "tipo": "investigacion",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Mexico",
          "origen": "Todos",
          "destino": "Eslovaquia",
          "tipo": "investigacion",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Mexico",
          "origen": "Todos",
          "destino": "Eslovenia",
          "tipo": "investigacion",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Mexico",
          "origen": "Todos",
          "destino": "España",
          "tipo": "investigacion",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Mexico",
          "origen": "Todos",
          "destino": "Estados Unidos de América",
          "tipo": "investigacion",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Mexico",
          "origen": "Todos",
          "destino": "Estonia",
          "tipo": "investigacion",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Mexico",
          "origen": "Todos",
          "destino": "Finlandia",
          "tipo": "investigacion",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Mexico",
          "origen": "Todos",
          "destino": "Francia",
          "tipo": "investigacion",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Mexico",
          "origen": "Todos",
          "destino": "Grecia",
          "tipo": "investigacion",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Mexico",
          "origen": "Todos",
          "destino": "Guatemala",
          "tipo": "investigacion",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Mexico",
          "origen": "Todos",
          "destino": "Hungría",
          "tipo": "investigacion",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Mexico",
          "origen": "Todos",
          "destino": "Irlanda",
          "tipo": "investigacion",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Mexico",
          "origen": "Todos",
          "destino": "Islandia",
          "tipo": "investigacion",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Mexico",
          "origen": "Todos",
          "destino": "Italia",
          "tipo": "investigacion",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Mexico",
          "origen": "Todos",
          "destino": "Japón",
          "tipo": "investigacion",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Mexico",
          "origen": "Todos",
          "destino": "Letonia",
          "tipo": "investigacion",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Mexico",
          "origen": "Todos",
          "destino": "Líbano",
          "tipo": "investigacion",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Mexico",
          "origen": "Todos",
          "destino": "Liberia",
          "tipo": "investigacion",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Mexico",
          "origen": "Todos",
          "destino": "Liechtenstein",
          "tipo": "investigacion",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Mexico",
          "origen": "Todos",
          "destino": "Lituania",
          "tipo": "investigacion",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Mexico",
          "origen": "Todos",
          "destino": "Luxemburgo",
          "tipo": "investigacion",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Mexico",
          "origen": "Todos",
          "destino": "Malta",
          "tipo": "investigacion",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Mexico",
          "origen": "Todos",
          "destino": "Marruecos",
          "tipo": "investigacion",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Mexico",
          "origen": "Todos",
          "destino": "México",
          "tipo": "investigacion",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Mexico",
          "origen": "Todos",
          "destino": "Mónaco",
          "tipo": "investigacion",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Mexico",
          "origen": "Todos",
          "destino": "Montenegro",
          "tipo": "investigacion",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Mexico",
          "origen": "Todos",
          "destino": "Noruega",
          "tipo": "investigacion",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Mexico",
          "origen": "Todos",
          "destino": "Nueva Zelanda",
          "tipo": "investigacion",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Mexico",
          "origen": "Todos",
          "destino": "Países Bajos",
          "tipo": "investigacion",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Mexico",
          "origen": "Todos",
          "destino": "Polonia",
          "tipo": "investigacion",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Mexico",
          "origen": "Todos",
          "destino": "Portugal",
          "tipo": "investigacion",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Mexico",
          "origen": "Todos",
          "destino": "Reino Unido",
          "tipo": "investigacion",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Mexico",
          "origen": "Todos",
          "destino": "República Checa",
          "tipo": "investigacion",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Mexico",
          "origen": "Todos",
          "destino": "Rumanía",
          "tipo": "investigacion",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Mexico",
          "origen": "Todos",
          "destino": "Serbia",
          "tipo": "investigacion",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Mexico",
          "origen": "Todos",
          "destino": "Suecia",
          "tipo": "investigacion",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Mexico",
          "origen": "Todos",
          "destino": "Suiza",
          "tipo": "investigacion",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Mexico",
          "origen": "Todos",
          "destino": "Túnez",
          "tipo": "investigacion",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Mexico",
          "origen": "Todos",
          "destino": "Albania",
          "tipo": "investigacion",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Mexico",
          "origen": "Todos",
          "destino": "Australia",
          "tipo": "investigacion",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Mexico",
          "origen": "Todos",
          "destino": "Emiratos Árabes Unidos",
          "tipo": "investigacion",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Mexico",
          "origen": "Todos",
          "destino": "India",
          "tipo": "investigacion",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Mexico",
          "origen": "Todos",
          "destino": "Libia",
          "tipo": "investigacion",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Mexico",
          "origen": "Todos",
          "destino": "Taiwán",
          "tipo": "investigacion",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Mexico",
          "origen": "Todos",
          "destino": "Vietnam",
          "tipo": "investigacion",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Mexico",
          "origen": "Todos",
          "destino": "Corea del Sur",
          "tipo": "investigacion",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Mexico",
          "origen": "Todos",
          "destino": "Argentina",
          "tipo": "investigacion",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Mexico",
          "origen": "Todos",
          "destino": "Bolivia",
          "tipo": "investigacion",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Mexico",
          "origen": "Todos",
          "destino": "Brasil",
          "tipo": "investigacion",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Mexico",
          "origen": "Todos",
          "destino": "Chile",
          "tipo": "investigacion",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Mexico",
          "origen": "Todos",
          "destino": "China",
          "tipo": "investigacion",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Mexico",
          "origen": "Todos",
          "destino": "Colombia",
          "tipo": "investigacion",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Mexico",
          "origen": "Todos",
          "destino": "Curacao",
          "tipo": "investigacion",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Mexico",
          "origen": "Todos",
          "destino": "El Salvador",
          "tipo": "investigacion",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Mexico",
          "origen": "Todos",
          "destino": "Federación Rusa",
          "tipo": "investigacion",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Mexico",
          "origen": "Todos",
          "destino": "Honduras",
          "tipo": "investigacion",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Mexico",
          "origen": "Todos",
          "destino": "Hong Kong",
          "tipo": "investigacion",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Mexico",
          "origen": "Todos",
          "destino": "Israel",
          "tipo": "investigacion",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Mexico",
          "origen": "Todos",
          "destino": "Jamaica",
          "tipo": "investigacion",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Mexico",
          "origen": "Todos",
          "destino": "Nicaragua",
          "tipo": "investigacion",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Mexico",
          "origen": "Todos",
          "destino": "Panamá",
          "tipo": "investigacion",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Mexico",
          "origen": "Todos",
          "destino": "Paraguay",
          "tipo": "investigacion",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Mexico",
          "origen": "Todos",
          "destino": "Perú",
          "tipo": "investigacion",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Mexico",
          "origen": "Todos",
          "destino": "República Dominicana",
          "tipo": "investigacion",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Mexico",
          "origen": "Todos",
          "destino": "Singapur",
          "tipo": "investigacion",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Mexico",
          "origen": "Todos",
          "destino": "Sudáfrica",
          "tipo": "investigacion",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Mexico",
          "origen": "Todos",
          "destino": "Tailandia",
          "tipo": "investigacion",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Mexico",
          "origen": "Todos",
          "destino": "Turquía",
          "tipo": "investigacion",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Mexico",
          "origen": "Todos",
          "destino": "Uruguay",
          "tipo": "investigacion",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Mexico",
          "origen": "Todos",
          "destino": "Venezuela",
          "tipo": "investigacion",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Ecuador",
          "origen": "Todos",
          "destino": "Alemania",
          "tipo": "nomada",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Ecuador",
          "origen": "Todos",
          "destino": "Andorra",
          "tipo": "nomada",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Ecuador",
          "origen": "Todos",
          "destino": "Arabia Saudí",
          "tipo": "nomada",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Ecuador",
          "origen": "Todos",
          "destino": "Argelia",
          "tipo": "nomada",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Ecuador",
          "origen": "Todos",
          "destino": "Austria",
          "tipo": "nomada",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Ecuador",
          "origen": "Todos",
          "destino": "Bélgica",
          "tipo": "nomada",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Ecuador",
          "origen": "Todos",
          "destino": "Bulgaria",
          "tipo": "nomada",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Ecuador",
          "origen": "Todos",
          "destino": "Canadá",
          "tipo": "nomada",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Ecuador",
          "origen": "Todos",
          "destino": "Costa Rica",
          "tipo": "nomada",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Ecuador",
          "origen": "Todos",
          "destino": "Croacia",
          "tipo": "nomada",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Ecuador",
          "origen": "Todos",
          "destino": "Cuba",
          "tipo": "nomada",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Ecuador",
          "origen": "Todos",
          "destino": "Dinamarca",
          "tipo": "nomada",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Ecuador",
          "origen": "Todos",
          "destino": "Eslovaquia",
          "tipo": "nomada",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Ecuador",
          "origen": "Todos",
          "destino": "Eslovenia",
          "tipo": "nomada",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Ecuador",
          "origen": "Todos",
          "destino": "España",
          "tipo": "nomada",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Ecuador",
          "origen": "Todos",
          "destino": "Estados Unidos de América",
          "tipo": "nomada",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Ecuador",
          "origen": "Todos",
          "destino": "Estonia",
          "tipo": "nomada",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Ecuador",
          "origen": "Todos",
          "destino": "Finlandia",
          "tipo": "nomada",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Ecuador",
          "origen": "Todos",
          "destino": "Francia",
          "tipo": "nomada",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Ecuador",
          "origen": "Todos",
          "destino": "Grecia",
          "tipo": "nomada",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Ecuador",
          "origen": "Todos",
          "destino": "Guatemala",
          "tipo": "nomada",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Ecuador",
          "origen": "Todos",
          "destino": "Hungría",
          "tipo": "nomada",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Ecuador",
          "origen": "Todos",
          "destino": "Irlanda",
          "tipo": "nomada",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Ecuador",
          "origen": "Todos",
          "destino": "Islandia",
          "tipo": "nomada",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Ecuador",
          "origen": "Todos",
          "destino": "Italia",
          "tipo": "nomada",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Ecuador",
          "origen": "Todos",
          "destino": "Japón",
          "tipo": "nomada",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Ecuador",
          "origen": "Todos",
          "destino": "Letonia",
          "tipo": "nomada",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Ecuador",
          "origen": "Todos",
          "destino": "Líbano",
          "tipo": "nomada",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Ecuador",
          "origen": "Todos",
          "destino": "Liberia",
          "tipo": "nomada",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Ecuador",
          "origen": "Todos",
          "destino": "Liechtenstein",
          "tipo": "nomada",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Ecuador",
          "origen": "Todos",
          "destino": "Lituania",
          "tipo": "nomada",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Ecuador",
          "origen": "Todos",
          "destino": "Luxemburgo",
          "tipo": "nomada",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Ecuador",
          "origen": "Todos",
          "destino": "Malta",
          "tipo": "nomada",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Ecuador",
          "origen": "Todos",
          "destino": "Marruecos",
          "tipo": "nomada",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Ecuador",
          "origen": "Todos",
          "destino": "México",
          "tipo": "nomada",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Ecuador",
          "origen": "Todos",
          "destino": "Mónaco",
          "tipo": "nomada",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Ecuador",
          "origen": "Todos",
          "destino": "Montenegro",
          "tipo": "nomada",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Ecuador",
          "origen": "Todos",
          "destino": "Noruega",
          "tipo": "nomada",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Ecuador",
          "origen": "Todos",
          "destino": "Nueva Zelanda",
          "tipo": "nomada",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Ecuador",
          "origen": "Todos",
          "destino": "Países Bajos",
          "tipo": "nomada",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Ecuador",
          "origen": "Todos",
          "destino": "Polonia",
          "tipo": "nomada",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Ecuador",
          "origen": "Todos",
          "destino": "Portugal",
          "tipo": "nomada",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Ecuador",
          "origen": "Todos",
          "destino": "Reino Unido",
          "tipo": "nomada",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Ecuador",
          "origen": "Todos",
          "destino": "República Checa",
          "tipo": "nomada",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Ecuador",
          "origen": "Todos",
          "destino": "Rumanía",
          "tipo": "nomada",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Ecuador",
          "origen": "Todos",
          "destino": "Serbia",
          "tipo": "nomada",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Ecuador",
          "origen": "Todos",
          "destino": "Suecia",
          "tipo": "nomada",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Ecuador",
          "origen": "Todos",
          "destino": "Suiza",
          "tipo": "nomada",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Ecuador",
          "origen": "Todos",
          "destino": "Túnez",
          "tipo": "nomada",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Ecuador",
          "origen": "Todos",
          "destino": "Albania",
          "tipo": "nomada",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Ecuador",
          "origen": "Todos",
          "destino": "Australia",
          "tipo": "nomada",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Ecuador",
          "origen": "Todos",
          "destino": "Emiratos Árabes Unidos",
          "tipo": "nomada",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Ecuador",
          "origen": "Todos",
          "destino": "India",
          "tipo": "nomada",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Ecuador",
          "origen": "Todos",
          "destino": "Libia",
          "tipo": "nomada",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Ecuador",
          "origen": "Todos",
          "destino": "Taiwán",
          "tipo": "nomada",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Ecuador",
          "origen": "Todos",
          "destino": "Vietnam",
          "tipo": "nomada",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Ecuador",
          "origen": "Todos",
          "destino": "Corea del Sur",
          "tipo": "nomada",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Ecuador",
          "origen": "Todos",
          "destino": "Argentina",
          "tipo": "nomada",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Ecuador",
          "origen": "Todos",
          "destino": "Bolivia",
          "tipo": "nomada",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Ecuador",
          "origen": "Todos",
          "destino": "Brasil",
          "tipo": "nomada",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Ecuador",
          "origen": "Todos",
          "destino": "Chile",
          "tipo": "nomada",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Ecuador",
          "origen": "Todos",
          "destino": "China",
          "tipo": "nomada",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Ecuador",
          "origen": "Todos",
          "destino": "Colombia",
          "tipo": "nomada",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Ecuador",
          "origen": "Todos",
          "destino": "Curacao",
          "tipo": "nomada",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Ecuador",
          "origen": "Todos",
          "destino": "El Salvador",
          "tipo": "nomada",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Ecuador",
          "origen": "Todos",
          "destino": "Federación Rusa",
          "tipo": "nomada",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Ecuador",
          "origen": "Todos",
          "destino": "Honduras",
          "tipo": "nomada",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Ecuador",
          "origen": "Todos",
          "destino": "Hong Kong",
          "tipo": "nomada",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Ecuador",
          "origen": "Todos",
          "destino": "Israel",
          "tipo": "nomada",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Ecuador",
          "origen": "Todos",
          "destino": "Jamaica",
          "tipo": "nomada",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Ecuador",
          "origen": "Todos",
          "destino": "Nicaragua",
          "tipo": "nomada",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Ecuador",
          "origen": "Todos",
          "destino": "Panamá",
          "tipo": "nomada",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Ecuador",
          "origen": "Todos",
          "destino": "Paraguay",
          "tipo": "nomada",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Ecuador",
          "origen": "Todos",
          "destino": "Perú",
          "tipo": "nomada",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Ecuador",
          "origen": "Todos",
          "destino": "República Dominicana",
          "tipo": "nomada",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Ecuador",
          "origen": "Todos",
          "destino": "Singapur",
          "tipo": "nomada",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Ecuador",
          "origen": "Todos",
          "destino": "Sudáfrica",
          "tipo": "nomada",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Ecuador",
          "origen": "Todos",
          "destino": "Tailandia",
          "tipo": "nomada",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Ecuador",
          "origen": "Todos",
          "destino": "Turquía",
          "tipo": "nomada",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Ecuador",
          "origen": "Todos",
          "destino": "Uruguay",
          "tipo": "nomada",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Ecuador",
          "origen": "Todos",
          "destino": "Venezuela",
          "tipo": "nomada",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Colombia",
          "origen": "Todos",
          "destino": "Alemania",
          "tipo": "nomada",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Colombia",
          "origen": "Todos",
          "destino": "Andorra",
          "tipo": "nomada",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Colombia",
          "origen": "Todos",
          "destino": "Arabia Saudí",
          "tipo": "nomada",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Colombia",
          "origen": "Todos",
          "destino": "Argelia",
          "tipo": "nomada",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Colombia",
          "origen": "Todos",
          "destino": "Austria",
          "tipo": "nomada",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Colombia",
          "origen": "Todos",
          "destino": "Bélgica",
          "tipo": "nomada",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Colombia",
          "origen": "Todos",
          "destino": "Bulgaria",
          "tipo": "nomada",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Colombia",
          "origen": "Todos",
          "destino": "Canadá",
          "tipo": "nomada",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Colombia",
          "origen": "Todos",
          "destino": "Costa Rica",
          "tipo": "nomada",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Colombia",
          "origen": "Todos",
          "destino": "Croacia",
          "tipo": "nomada",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Colombia",
          "origen": "Todos",
          "destino": "Cuba",
          "tipo": "nomada",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Colombia",
          "origen": "Todos",
          "destino": "Dinamarca",
          "tipo": "nomada",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Colombia",
          "origen": "Todos",
          "destino": "Eslovaquia",
          "tipo": "nomada",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Colombia",
          "origen": "Todos",
          "destino": "Eslovenia",
          "tipo": "nomada",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Colombia",
          "origen": "Todos",
          "destino": "España",
          "tipo": "nomada",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Colombia",
          "origen": "Todos",
          "destino": "Estados Unidos de América",
          "tipo": "nomada",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Colombia",
          "origen": "Todos",
          "destino": "Estonia",
          "tipo": "nomada",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Colombia",
          "origen": "Todos",
          "destino": "Finlandia",
          "tipo": "nomada",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Colombia",
          "origen": "Todos",
          "destino": "Francia",
          "tipo": "nomada",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Colombia",
          "origen": "Todos",
          "destino": "Grecia",
          "tipo": "nomada",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Colombia",
          "origen": "Todos",
          "destino": "Guatemala",
          "tipo": "nomada",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Colombia",
          "origen": "Todos",
          "destino": "Hungría",
          "tipo": "nomada",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Colombia",
          "origen": "Todos",
          "destino": "Irlanda",
          "tipo": "nomada",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Colombia",
          "origen": "Todos",
          "destino": "Islandia",
          "tipo": "nomada",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Colombia",
          "origen": "Todos",
          "destino": "Italia",
          "tipo": "nomada",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Colombia",
          "origen": "Todos",
          "destino": "Japón",
          "tipo": "nomada",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Colombia",
          "origen": "Todos",
          "destino": "Letonia",
          "tipo": "nomada",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Colombia",
          "origen": "Todos",
          "destino": "Líbano",
          "tipo": "nomada",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Colombia",
          "origen": "Todos",
          "destino": "Liberia",
          "tipo": "nomada",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Colombia",
          "origen": "Todos",
          "destino": "Liechtenstein",
          "tipo": "nomada",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Colombia",
          "origen": "Todos",
          "destino": "Lituania",
          "tipo": "nomada",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Colombia",
          "origen": "Todos",
          "destino": "Luxemburgo",
          "tipo": "nomada",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Colombia",
          "origen": "Todos",
          "destino": "Malta",
          "tipo": "nomada",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Colombia",
          "origen": "Todos",
          "destino": "Marruecos",
          "tipo": "nomada",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Colombia",
          "origen": "Todos",
          "destino": "México",
          "tipo": "nomada",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Colombia",
          "origen": "Todos",
          "destino": "Mónaco",
          "tipo": "nomada",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Colombia",
          "origen": "Todos",
          "destino": "Montenegro",
          "tipo": "nomada",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Colombia",
          "origen": "Todos",
          "destino": "Noruega",
          "tipo": "nomada",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Colombia",
          "origen": "Todos",
          "destino": "Nueva Zelanda",
          "tipo": "nomada",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Colombia",
          "origen": "Todos",
          "destino": "Países Bajos",
          "tipo": "nomada",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Colombia",
          "origen": "Todos",
          "destino": "Polonia",
          "tipo": "nomada",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Colombia",
          "origen": "Todos",
          "destino": "Portugal",
          "tipo": "nomada",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Colombia",
          "origen": "Todos",
          "destino": "Reino Unido",
          "tipo": "nomada",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Colombia",
          "origen": "Todos",
          "destino": "República Checa",
          "tipo": "nomada",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Colombia",
          "origen": "Todos",
          "destino": "Rumanía",
          "tipo": "nomada",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Colombia",
          "origen": "Todos",
          "destino": "Serbia",
          "tipo": "nomada",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Colombia",
          "origen": "Todos",
          "destino": "Suecia",
          "tipo": "nomada",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Colombia",
          "origen": "Todos",
          "destino": "Suiza",
          "tipo": "nomada",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Colombia",
          "origen": "Todos",
          "destino": "Túnez",
          "tipo": "nomada",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Colombia",
          "origen": "Todos",
          "destino": "Albania",
          "tipo": "nomada",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Colombia",
          "origen": "Todos",
          "destino": "Australia",
          "tipo": "nomada",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Colombia",
          "origen": "Todos",
          "destino": "Emiratos Árabes Unidos",
          "tipo": "nomada",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Colombia",
          "origen": "Todos",
          "destino": "India",
          "tipo": "nomada",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Colombia",
          "origen": "Todos",
          "destino": "Libia",
          "tipo": "nomada",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Colombia",
          "origen": "Todos",
          "destino": "Taiwán",
          "tipo": "nomada",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Colombia",
          "origen": "Todos",
          "destino": "Vietnam",
          "tipo": "nomada",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Colombia",
          "origen": "Todos",
          "destino": "Corea del Sur",
          "tipo": "nomada",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Colombia",
          "origen": "Todos",
          "destino": "Argentina",
          "tipo": "nomada",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Colombia",
          "origen": "Todos",
          "destino": "Bolivia",
          "tipo": "nomada",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Colombia",
          "origen": "Todos",
          "destino": "Brasil",
          "tipo": "nomada",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Colombia",
          "origen": "Todos",
          "destino": "Chile",
          "tipo": "nomada",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Colombia",
          "origen": "Todos",
          "destino": "China",
          "tipo": "nomada",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Colombia",
          "origen": "Todos",
          "destino": "Colombia",
          "tipo": "nomada",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Colombia",
          "origen": "Todos",
          "destino": "Curacao",
          "tipo": "nomada",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Colombia",
          "origen": "Todos",
          "destino": "El Salvador",
          "tipo": "nomada",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Colombia",
          "origen": "Todos",
          "destino": "Federación Rusa",
          "tipo": "nomada",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Colombia",
          "origen": "Todos",
          "destino": "Honduras",
          "tipo": "nomada",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Colombia",
          "origen": "Todos",
          "destino": "Hong Kong",
          "tipo": "nomada",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Colombia",
          "origen": "Todos",
          "destino": "Israel",
          "tipo": "nomada",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Colombia",
          "origen": "Todos",
          "destino": "Jamaica",
          "tipo": "nomada",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Colombia",
          "origen": "Todos",
          "destino": "Nicaragua",
          "tipo": "nomada",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Colombia",
          "origen": "Todos",
          "destino": "Panamá",
          "tipo": "nomada",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Colombia",
          "origen": "Todos",
          "destino": "Paraguay",
          "tipo": "nomada",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Colombia",
          "origen": "Todos",
          "destino": "Perú",
          "tipo": "nomada",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Colombia",
          "origen": "Todos",
          "destino": "República Dominicana",
          "tipo": "nomada",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Colombia",
          "origen": "Todos",
          "destino": "Singapur",
          "tipo": "nomada",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Colombia",
          "origen": "Todos",
          "destino": "Sudáfrica",
          "tipo": "nomada",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Colombia",
          "origen": "Todos",
          "destino": "Tailandia",
          "tipo": "nomada",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Colombia",
          "origen": "Todos",
          "destino": "Turquía",
          "tipo": "nomada",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Colombia",
          "origen": "Todos",
          "destino": "Uruguay",
          "tipo": "nomada",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Colombia",
          "origen": "Todos",
          "destino": "Venezuela",
          "tipo": "nomada",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Peru",
          "origen": "Todos",
          "destino": "Alemania",
          "tipo": "nomada",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Peru",
          "origen": "Todos",
          "destino": "Andorra",
          "tipo": "nomada",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Peru",
          "origen": "Todos",
          "destino": "Arabia Saudí",
          "tipo": "nomada",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Peru",
          "origen": "Todos",
          "destino": "Argelia",
          "tipo": "nomada",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Peru",
          "origen": "Todos",
          "destino": "Austria",
          "tipo": "nomada",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Peru",
          "origen": "Todos",
          "destino": "Bélgica",
          "tipo": "nomada",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Peru",
          "origen": "Todos",
          "destino": "Bulgaria",
          "tipo": "nomada",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Peru",
          "origen": "Todos",
          "destino": "Canadá",
          "tipo": "nomada",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Peru",
          "origen": "Todos",
          "destino": "Costa Rica",
          "tipo": "nomada",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Peru",
          "origen": "Todos",
          "destino": "Croacia",
          "tipo": "nomada",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Peru",
          "origen": "Todos",
          "destino": "Cuba",
          "tipo": "nomada",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Peru",
          "origen": "Todos",
          "destino": "Dinamarca",
          "tipo": "nomada",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Peru",
          "origen": "Todos",
          "destino": "Eslovaquia",
          "tipo": "nomada",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Peru",
          "origen": "Todos",
          "destino": "Eslovenia",
          "tipo": "nomada",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Peru",
          "origen": "Todos",
          "destino": "España",
          "tipo": "nomada",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Peru",
          "origen": "Todos",
          "destino": "Estados Unidos de América",
          "tipo": "nomada",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Peru",
          "origen": "Todos",
          "destino": "Estonia",
          "tipo": "nomada",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Peru",
          "origen": "Todos",
          "destino": "Finlandia",
          "tipo": "nomada",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Peru",
          "origen": "Todos",
          "destino": "Francia",
          "tipo": "nomada",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Peru",
          "origen": "Todos",
          "destino": "Grecia",
          "tipo": "nomada",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Peru",
          "origen": "Todos",
          "destino": "Guatemala",
          "tipo": "nomada",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Peru",
          "origen": "Todos",
          "destino": "Hungría",
          "tipo": "nomada",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Peru",
          "origen": "Todos",
          "destino": "Irlanda",
          "tipo": "nomada",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Peru",
          "origen": "Todos",
          "destino": "Islandia",
          "tipo": "nomada",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Peru",
          "origen": "Todos",
          "destino": "Italia",
          "tipo": "nomada",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Peru",
          "origen": "Todos",
          "destino": "Japón",
          "tipo": "nomada",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Peru",
          "origen": "Todos",
          "destino": "Letonia",
          "tipo": "nomada",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Peru",
          "origen": "Todos",
          "destino": "Líbano",
          "tipo": "nomada",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Peru",
          "origen": "Todos",
          "destino": "Liberia",
          "tipo": "nomada",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Peru",
          "origen": "Todos",
          "destino": "Liechtenstein",
          "tipo": "nomada",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Peru",
          "origen": "Todos",
          "destino": "Lituania",
          "tipo": "nomada",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Peru",
          "origen": "Todos",
          "destino": "Luxemburgo",
          "tipo": "nomada",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Peru",
          "origen": "Todos",
          "destino": "Malta",
          "tipo": "nomada",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Peru",
          "origen": "Todos",
          "destino": "Marruecos",
          "tipo": "nomada",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Peru",
          "origen": "Todos",
          "destino": "México",
          "tipo": "nomada",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Peru",
          "origen": "Todos",
          "destino": "Mónaco",
          "tipo": "nomada",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Peru",
          "origen": "Todos",
          "destino": "Montenegro",
          "tipo": "nomada",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Peru",
          "origen": "Todos",
          "destino": "Noruega",
          "tipo": "nomada",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Peru",
          "origen": "Todos",
          "destino": "Nueva Zelanda",
          "tipo": "nomada",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Peru",
          "origen": "Todos",
          "destino": "Países Bajos",
          "tipo": "nomada",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Peru",
          "origen": "Todos",
          "destino": "Polonia",
          "tipo": "nomada",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Peru",
          "origen": "Todos",
          "destino": "Portugal",
          "tipo": "nomada",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Peru",
          "origen": "Todos",
          "destino": "Reino Unido",
          "tipo": "nomada",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Peru",
          "origen": "Todos",
          "destino": "República Checa",
          "tipo": "nomada",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Peru",
          "origen": "Todos",
          "destino": "Rumanía",
          "tipo": "nomada",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Peru",
          "origen": "Todos",
          "destino": "Serbia",
          "tipo": "nomada",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Peru",
          "origen": "Todos",
          "destino": "Suecia",
          "tipo": "nomada",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Peru",
          "origen": "Todos",
          "destino": "Suiza",
          "tipo": "nomada",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Peru",
          "origen": "Todos",
          "destino": "Túnez",
          "tipo": "nomada",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Peru",
          "origen": "Todos",
          "destino": "Albania",
          "tipo": "nomada",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Peru",
          "origen": "Todos",
          "destino": "Australia",
          "tipo": "nomada",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Peru",
          "origen": "Todos",
          "destino": "Emiratos Árabes Unidos",
          "tipo": "nomada",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Peru",
          "origen": "Todos",
          "destino": "India",
          "tipo": "nomada",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Peru",
          "origen": "Todos",
          "destino": "Libia",
          "tipo": "nomada",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Peru",
          "origen": "Todos",
          "destino": "Taiwán",
          "tipo": "nomada",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Peru",
          "origen": "Todos",
          "destino": "Vietnam",
          "tipo": "nomada",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Peru",
          "origen": "Todos",
          "destino": "Corea del Sur",
          "tipo": "nomada",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Peru",
          "origen": "Todos",
          "destino": "Argentina",
          "tipo": "nomada",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Peru",
          "origen": "Todos",
          "destino": "Bolivia",
          "tipo": "nomada",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Peru",
          "origen": "Todos",
          "destino": "Brasil",
          "tipo": "nomada",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Peru",
          "origen": "Todos",
          "destino": "Chile",
          "tipo": "nomada",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Peru",
          "origen": "Todos",
          "destino": "China",
          "tipo": "nomada",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Peru",
          "origen": "Todos",
          "destino": "Colombia",
          "tipo": "nomada",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Peru",
          "origen": "Todos",
          "destino": "Curacao",
          "tipo": "nomada",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Peru",
          "origen": "Todos",
          "destino": "El Salvador",
          "tipo": "nomada",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Peru",
          "origen": "Todos",
          "destino": "Federación Rusa",
          "tipo": "nomada",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Peru",
          "origen": "Todos",
          "destino": "Honduras",
          "tipo": "nomada",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Peru",
          "origen": "Todos",
          "destino": "Hong Kong",
          "tipo": "nomada",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Peru",
          "origen": "Todos",
          "destino": "Israel",
          "tipo": "nomada",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Peru",
          "origen": "Todos",
          "destino": "Jamaica",
          "tipo": "nomada",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Peru",
          "origen": "Todos",
          "destino": "Nicaragua",
          "tipo": "nomada",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Peru",
          "origen": "Todos",
          "destino": "Panamá",
          "tipo": "nomada",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Peru",
          "origen": "Todos",
          "destino": "Paraguay",
          "tipo": "nomada",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Peru",
          "origen": "Todos",
          "destino": "Perú",
          "tipo": "nomada",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Peru",
          "origen": "Todos",
          "destino": "República Dominicana",
          "tipo": "nomada",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Peru",
          "origen": "Todos",
          "destino": "Singapur",
          "tipo": "nomada",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Peru",
          "origen": "Todos",
          "destino": "Sudáfrica",
          "tipo": "nomada",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Peru",
          "origen": "Todos",
          "destino": "Tailandia",
          "tipo": "nomada",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Peru",
          "origen": "Todos",
          "destino": "Turquía",
          "tipo": "nomada",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Peru",
          "origen": "Todos",
          "destino": "Uruguay",
          "tipo": "nomada",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Peru",
          "origen": "Todos",
          "destino": "Venezuela",
          "tipo": "nomada",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Venezuela",
          "origen": "Todos",
          "destino": "Alemania",
          "tipo": "nomada",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Venezuela",
          "origen": "Todos",
          "destino": "Andorra",
          "tipo": "nomada",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Venezuela",
          "origen": "Todos",
          "destino": "Arabia Saudí",
          "tipo": "nomada",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Venezuela",
          "origen": "Todos",
          "destino": "Argelia",
          "tipo": "nomada",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Venezuela",
          "origen": "Todos",
          "destino": "Austria",
          "tipo": "nomada",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Venezuela",
          "origen": "Todos",
          "destino": "Bélgica",
          "tipo": "nomada",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Venezuela",
          "origen": "Todos",
          "destino": "Bulgaria",
          "tipo": "nomada",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Venezuela",
          "origen": "Todos",
          "destino": "Canadá",
          "tipo": "nomada",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Venezuela",
          "origen": "Todos",
          "destino": "Costa Rica",
          "tipo": "nomada",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Venezuela",
          "origen": "Todos",
          "destino": "Croacia",
          "tipo": "nomada",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Venezuela",
          "origen": "Todos",
          "destino": "Cuba",
          "tipo": "nomada",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Venezuela",
          "origen": "Todos",
          "destino": "Dinamarca",
          "tipo": "nomada",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Venezuela",
          "origen": "Todos",
          "destino": "Eslovaquia",
          "tipo": "nomada",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Venezuela",
          "origen": "Todos",
          "destino": "Eslovenia",
          "tipo": "nomada",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Venezuela",
          "origen": "Todos",
          "destino": "España",
          "tipo": "nomada",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Venezuela",
          "origen": "Todos",
          "destino": "Estados Unidos de América",
          "tipo": "nomada",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Venezuela",
          "origen": "Todos",
          "destino": "Estonia",
          "tipo": "nomada",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Venezuela",
          "origen": "Todos",
          "destino": "Finlandia",
          "tipo": "nomada",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Venezuela",
          "origen": "Todos",
          "destino": "Francia",
          "tipo": "nomada",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Venezuela",
          "origen": "Todos",
          "destino": "Grecia",
          "tipo": "nomada",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Venezuela",
          "origen": "Todos",
          "destino": "Guatemala",
          "tipo": "nomada",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Venezuela",
          "origen": "Todos",
          "destino": "Hungría",
          "tipo": "nomada",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Venezuela",
          "origen": "Todos",
          "destino": "Irlanda",
          "tipo": "nomada",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Venezuela",
          "origen": "Todos",
          "destino": "Islandia",
          "tipo": "nomada",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Venezuela",
          "origen": "Todos",
          "destino": "Italia",
          "tipo": "nomada",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Venezuela",
          "origen": "Todos",
          "destino": "Japón",
          "tipo": "nomada",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Venezuela",
          "origen": "Todos",
          "destino": "Letonia",
          "tipo": "nomada",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Venezuela",
          "origen": "Todos",
          "destino": "Líbano",
          "tipo": "nomada",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Venezuela",
          "origen": "Todos",
          "destino": "Liberia",
          "tipo": "nomada",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Venezuela",
          "origen": "Todos",
          "destino": "Liechtenstein",
          "tipo": "nomada",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Venezuela",
          "origen": "Todos",
          "destino": "Lituania",
          "tipo": "nomada",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Venezuela",
          "origen": "Todos",
          "destino": "Luxemburgo",
          "tipo": "nomada",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Venezuela",
          "origen": "Todos",
          "destino": "Malta",
          "tipo": "nomada",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Venezuela",
          "origen": "Todos",
          "destino": "Marruecos",
          "tipo": "nomada",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Venezuela",
          "origen": "Todos",
          "destino": "México",
          "tipo": "nomada",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Venezuela",
          "origen": "Todos",
          "destino": "Mónaco",
          "tipo": "nomada",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Venezuela",
          "origen": "Todos",
          "destino": "Montenegro",
          "tipo": "nomada",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Venezuela",
          "origen": "Todos",
          "destino": "Noruega",
          "tipo": "nomada",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Venezuela",
          "origen": "Todos",
          "destino": "Nueva Zelanda",
          "tipo": "nomada",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Venezuela",
          "origen": "Todos",
          "destino": "Países Bajos",
          "tipo": "nomada",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Venezuela",
          "origen": "Todos",
          "destino": "Polonia",
          "tipo": "nomada",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Venezuela",
          "origen": "Todos",
          "destino": "Portugal",
          "tipo": "nomada",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Venezuela",
          "origen": "Todos",
          "destino": "Reino Unido",
          "tipo": "nomada",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Venezuela",
          "origen": "Todos",
          "destino": "República Checa",
          "tipo": "nomada",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Venezuela",
          "origen": "Todos",
          "destino": "Rumanía",
          "tipo": "nomada",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Venezuela",
          "origen": "Todos",
          "destino": "Serbia",
          "tipo": "nomada",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Venezuela",
          "origen": "Todos",
          "destino": "Suecia",
          "tipo": "nomada",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Venezuela",
          "origen": "Todos",
          "destino": "Suiza",
          "tipo": "nomada",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Venezuela",
          "origen": "Todos",
          "destino": "Túnez",
          "tipo": "nomada",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Venezuela",
          "origen": "Todos",
          "destino": "Albania",
          "tipo": "nomada",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Venezuela",
          "origen": "Todos",
          "destino": "Australia",
          "tipo": "nomada",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Venezuela",
          "origen": "Todos",
          "destino": "Emiratos Árabes Unidos",
          "tipo": "nomada",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Venezuela",
          "origen": "Todos",
          "destino": "India",
          "tipo": "nomada",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Venezuela",
          "origen": "Todos",
          "destino": "Libia",
          "tipo": "nomada",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Venezuela",
          "origen": "Todos",
          "destino": "Taiwán",
          "tipo": "nomada",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Venezuela",
          "origen": "Todos",
          "destino": "Vietnam",
          "tipo": "nomada",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Venezuela",
          "origen": "Todos",
          "destino": "Corea del Sur",
          "tipo": "nomada",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Venezuela",
          "origen": "Todos",
          "destino": "Argentina",
          "tipo": "nomada",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Venezuela",
          "origen": "Todos",
          "destino": "Bolivia",
          "tipo": "nomada",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Venezuela",
          "origen": "Todos",
          "destino": "Brasil",
          "tipo": "nomada",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Venezuela",
          "origen": "Todos",
          "destino": "Chile",
          "tipo": "nomada",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Venezuela",
          "origen": "Todos",
          "destino": "China",
          "tipo": "nomada",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Venezuela",
          "origen": "Todos",
          "destino": "Colombia",
          "tipo": "nomada",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Venezuela",
          "origen": "Todos",
          "destino": "Curacao",
          "tipo": "nomada",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Venezuela",
          "origen": "Todos",
          "destino": "El Salvador",
          "tipo": "nomada",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Venezuela",
          "origen": "Todos",
          "destino": "Federación Rusa",
          "tipo": "nomada",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Venezuela",
          "origen": "Todos",
          "destino": "Honduras",
          "tipo": "nomada",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Venezuela",
          "origen": "Todos",
          "destino": "Hong Kong",
          "tipo": "nomada",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Venezuela",
          "origen": "Todos",
          "destino": "Israel",
          "tipo": "nomada",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Venezuela",
          "origen": "Todos",
          "destino": "Jamaica",
          "tipo": "nomada",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Venezuela",
          "origen": "Todos",
          "destino": "Nicaragua",
          "tipo": "nomada",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Venezuela",
          "origen": "Todos",
          "destino": "Panamá",
          "tipo": "nomada",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Venezuela",
          "origen": "Todos",
          "destino": "Paraguay",
          "tipo": "nomada",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Venezuela",
          "origen": "Todos",
          "destino": "Perú",
          "tipo": "nomada",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Venezuela",
          "origen": "Todos",
          "destino": "República Dominicana",
          "tipo": "nomada",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Venezuela",
          "origen": "Todos",
          "destino": "Singapur",
          "tipo": "nomada",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Venezuela",
          "origen": "Todos",
          "destino": "Sudáfrica",
          "tipo": "nomada",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Venezuela",
          "origen": "Todos",
          "destino": "Tailandia",
          "tipo": "nomada",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Venezuela",
          "origen": "Todos",
          "destino": "Turquía",
          "tipo": "nomada",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Venezuela",
          "origen": "Todos",
          "destino": "Uruguay",
          "tipo": "nomada",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Venezuela",
          "origen": "Todos",
          "destino": "Venezuela",
          "tipo": "nomada",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "El Salvador",
          "origen": "Todos",
          "destino": "Andorra",
          "tipo": "nomada",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "El Salvador",
          "origen": "Todos",
          "destino": "Arabia Saudí",
          "tipo": "nomada",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "El Salvador",
          "origen": "Todos",
          "destino": "Argelia",
          "tipo": "nomada",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "El Salvador",
          "origen": "Todos",
          "destino": "Austria",
          "tipo": "nomada",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "El Salvador",
          "origen": "Todos",
          "destino": "Bélgica",
          "tipo": "nomada",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "El Salvador",
          "origen": "Todos",
          "destino": "Bulgaria",
          "tipo": "nomada",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "El Salvador",
          "origen": "Todos",
          "destino": "Canadá",
          "tipo": "nomada",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "El Salvador",
          "origen": "Todos",
          "destino": "Costa Rica",
          "tipo": "nomada",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "El Salvador",
          "origen": "Todos",
          "destino": "Croacia",
          "tipo": "nomada",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "El Salvador",
          "origen": "Todos",
          "destino": "Cuba",
          "tipo": "nomada",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "El Salvador",
          "origen": "Todos",
          "destino": "Dinamarca",
          "tipo": "nomada",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "El Salvador",
          "origen": "Todos",
          "destino": "Eslovaquia",
          "tipo": "nomada",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "El Salvador",
          "origen": "Todos",
          "destino": "Eslovenia",
          "tipo": "nomada",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "El Salvador",
          "origen": "Todos",
          "destino": "España",
          "tipo": "nomada",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "El Salvador",
          "origen": "Todos",
          "destino": "Estados Unidos de América",
          "tipo": "nomada",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "El Salvador",
          "origen": "Todos",
          "destino": "Estonia",
          "tipo": "nomada",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "El Salvador",
          "origen": "Todos",
          "destino": "Finlandia",
          "tipo": "nomada",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "El Salvador",
          "origen": "Todos",
          "destino": "Francia",
          "tipo": "nomada",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "El Salvador",
          "origen": "Todos",
          "destino": "Grecia",
          "tipo": "nomada",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "El Salvador",
          "origen": "Todos",
          "destino": "Guatemala",
          "tipo": "nomada",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "El Salvador",
          "origen": "Todos",
          "destino": "Hungría",
          "tipo": "nomada",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "El Salvador",
          "origen": "Todos",
          "destino": "Irlanda",
          "tipo": "nomada",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "El Salvador",
          "origen": "Todos",
          "destino": "Islandia",
          "tipo": "nomada",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "El Salvador",
          "origen": "Todos",
          "destino": "Italia",
          "tipo": "nomada",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "El Salvador",
          "origen": "Todos",
          "destino": "Japón",
          "tipo": "nomada",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "El Salvador",
          "origen": "Todos",
          "destino": "Letonia",
          "tipo": "nomada",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "El Salvador",
          "origen": "Todos",
          "destino": "Líbano",
          "tipo": "nomada",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "El Salvador",
          "origen": "Todos",
          "destino": "Liberia",
          "tipo": "nomada",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "El Salvador",
          "origen": "Todos",
          "destino": "Liechtenstein",
          "tipo": "nomada",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "El Salvador",
          "origen": "Todos",
          "destino": "Lituania",
          "tipo": "nomada",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "El Salvador",
          "origen": "Todos",
          "destino": "Luxemburgo",
          "tipo": "nomada",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "El Salvador",
          "origen": "Todos",
          "destino": "Malta",
          "tipo": "nomada",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "El Salvador",
          "origen": "Todos",
          "destino": "Marruecos",
          "tipo": "nomada",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "El Salvador",
          "origen": "Todos",
          "destino": "México",
          "tipo": "nomada",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "El Salvador",
          "origen": "Todos",
          "destino": "Mónaco",
          "tipo": "nomada",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "El Salvador",
          "origen": "Todos",
          "destino": "Montenegro",
          "tipo": "nomada",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "El Salvador",
          "origen": "Todos",
          "destino": "Noruega",
          "tipo": "nomada",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "El Salvador",
          "origen": "Todos",
          "destino": "Nueva Zelanda",
          "tipo": "nomada",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "El Salvador",
          "origen": "Todos",
          "destino": "Países Bajos",
          "tipo": "nomada",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "El Salvador",
          "origen": "Todos",
          "destino": "Polonia",
          "tipo": "nomada",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "El Salvador",
          "origen": "Todos",
          "destino": "Portugal",
          "tipo": "nomada",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "El Salvador",
          "origen": "Todos",
          "destino": "Reino Unido",
          "tipo": "nomada",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "El Salvador",
          "origen": "Todos",
          "destino": "República Checa",
          "tipo": "nomada",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "El Salvador",
          "origen": "Todos",
          "destino": "Rumanía",
          "tipo": "nomada",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "El Salvador",
          "origen": "Todos",
          "destino": "Serbia",
          "tipo": "nomada",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "El Salvador",
          "origen": "Todos",
          "destino": "Suecia",
          "tipo": "nomada",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "El Salvador",
          "origen": "Todos",
          "destino": "Suiza",
          "tipo": "nomada",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "El Salvador",
          "origen": "Todos",
          "destino": "Túnez",
          "tipo": "nomada",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "El Salvador",
          "origen": "Todos",
          "destino": "Albania",
          "tipo": "nomada",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "El Salvador",
          "origen": "Todos",
          "destino": "Australia",
          "tipo": "nomada",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "El Salvador",
          "origen": "Todos",
          "destino": "Emiratos Árabes Unidos",
          "tipo": "nomada",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "El Salvador",
          "origen": "Todos",
          "destino": "India",
          "tipo": "nomada",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "El Salvador",
          "origen": "Todos",
          "destino": "Libia",
          "tipo": "nomada",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "El Salvador",
          "origen": "Todos",
          "destino": "Taiwán",
          "tipo": "nomada",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "El Salvador",
          "origen": "Todos",
          "destino": "Vietnam",
          "tipo": "nomada",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "El Salvador",
          "origen": "Todos",
          "destino": "Corea del Sur",
          "tipo": "nomada",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "El Salvador",
          "origen": "Todos",
          "destino": "Argentina",
          "tipo": "nomada",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "El Salvador",
          "origen": "Todos",
          "destino": "Bolivia",
          "tipo": "nomada",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "El Salvador",
          "origen": "Todos",
          "destino": "Brasil",
          "tipo": "nomada",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "El Salvador",
          "origen": "Todos",
          "destino": "Chile",
          "tipo": "nomada",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "El Salvador",
          "origen": "Todos",
          "destino": "China",
          "tipo": "nomada",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "El Salvador",
          "origen": "Todos",
          "destino": "Colombia",
          "tipo": "nomada",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "El Salvador",
          "origen": "Todos",
          "destino": "Curacao",
          "tipo": "nomada",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "El Salvador",
          "origen": "Todos",
          "destino": "El Salvador",
          "tipo": "nomada",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "El Salvador",
          "origen": "Todos",
          "destino": "Federación Rusa",
          "tipo": "nomada",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "El Salvador",
          "origen": "Todos",
          "destino": "Honduras",
          "tipo": "nomada",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "El Salvador",
          "origen": "Todos",
          "destino": "Hong Kong",
          "tipo": "nomada",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "El Salvador",
          "origen": "Todos",
          "destino": "Israel",
          "tipo": "nomada",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "El Salvador",
          "origen": "Todos",
          "destino": "Jamaica",
          "tipo": "nomada",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "El Salvador",
          "origen": "Todos",
          "destino": "Nicaragua",
          "tipo": "nomada",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "El Salvador",
          "origen": "Todos",
          "destino": "Panamá",
          "tipo": "nomada",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "El Salvador",
          "origen": "Todos",
          "destino": "Paraguay",
          "tipo": "nomada",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "El Salvador",
          "origen": "Todos",
          "destino": "Perú",
          "tipo": "nomada",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "El Salvador",
          "origen": "Todos",
          "destino": "República Dominicana",
          "tipo": "nomada",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "El Salvador",
          "origen": "Todos",
          "destino": "Singapur",
          "tipo": "nomada",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "El Salvador",
          "origen": "Todos",
          "destino": "Sudáfrica",
          "tipo": "nomada",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "El Salvador",
          "origen": "Todos",
          "destino": "Tailandia",
          "tipo": "nomada",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "El Salvador",
          "origen": "Todos",
          "destino": "Turquía",
          "tipo": "nomada",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "El Salvador",
          "origen": "Todos",
          "destino": "Uruguay",
          "tipo": "nomada",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "El Salvador",
          "origen": "Todos",
          "destino": "Venezuela",
          "tipo": "nomada",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Mexico",
          "origen": "Todos",
          "destino": "Andorra",
          "tipo": "nomada",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Mexico",
          "origen": "Todos",
          "destino": "Arabia Saudí",
          "tipo": "nomada",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Mexico",
          "origen": "Todos",
          "destino": "Argelia",
          "tipo": "nomada",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Mexico",
          "origen": "Todos",
          "destino": "Austria",
          "tipo": "nomada",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Mexico",
          "origen": "Todos",
          "destino": "Bélgica",
          "tipo": "nomada",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Mexico",
          "origen": "Todos",
          "destino": "Bulgaria",
          "tipo": "nomada",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Mexico",
          "origen": "Todos",
          "destino": "Canadá",
          "tipo": "nomada",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Mexico",
          "origen": "Todos",
          "destino": "Costa Rica",
          "tipo": "nomada",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Mexico",
          "origen": "Todos",
          "destino": "Croacia",
          "tipo": "nomada",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Mexico",
          "origen": "Todos",
          "destino": "Cuba",
          "tipo": "nomada",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Mexico",
          "origen": "Todos",
          "destino": "Dinamarca",
          "tipo": "nomada",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Mexico",
          "origen": "Todos",
          "destino": "Eslovaquia",
          "tipo": "nomada",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Mexico",
          "origen": "Todos",
          "destino": "Eslovenia",
          "tipo": "nomada",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Mexico",
          "origen": "Todos",
          "destino": "España",
          "tipo": "nomada",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Mexico",
          "origen": "Todos",
          "destino": "Estados Unidos de América",
          "tipo": "nomada",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Mexico",
          "origen": "Todos",
          "destino": "Estonia",
          "tipo": "nomada",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Mexico",
          "origen": "Todos",
          "destino": "Finlandia",
          "tipo": "nomada",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Mexico",
          "origen": "Todos",
          "destino": "Francia",
          "tipo": "nomada",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Mexico",
          "origen": "Todos",
          "destino": "Grecia",
          "tipo": "nomada",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Mexico",
          "origen": "Todos",
          "destino": "Guatemala",
          "tipo": "nomada",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Mexico",
          "origen": "Todos",
          "destino": "Hungría",
          "tipo": "nomada",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Mexico",
          "origen": "Todos",
          "destino": "Irlanda",
          "tipo": "nomada",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Mexico",
          "origen": "Todos",
          "destino": "Islandia",
          "tipo": "nomada",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Mexico",
          "origen": "Todos",
          "destino": "Italia",
          "tipo": "nomada",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Mexico",
          "origen": "Todos",
          "destino": "Japón",
          "tipo": "nomada",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Mexico",
          "origen": "Todos",
          "destino": "Letonia",
          "tipo": "nomada",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Mexico",
          "origen": "Todos",
          "destino": "Líbano",
          "tipo": "nomada",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Mexico",
          "origen": "Todos",
          "destino": "Liberia",
          "tipo": "nomada",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Mexico",
          "origen": "Todos",
          "destino": "Liechtenstein",
          "tipo": "nomada",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Mexico",
          "origen": "Todos",
          "destino": "Lituania",
          "tipo": "nomada",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Mexico",
          "origen": "Todos",
          "destino": "Luxemburgo",
          "tipo": "nomada",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Mexico",
          "origen": "Todos",
          "destino": "Malta",
          "tipo": "nomada",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Mexico",
          "origen": "Todos",
          "destino": "Marruecos",
          "tipo": "nomada",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Mexico",
          "origen": "Todos",
          "destino": "México",
          "tipo": "nomada",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Mexico",
          "origen": "Todos",
          "destino": "Mónaco",
          "tipo": "nomada",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Mexico",
          "origen": "Todos",
          "destino": "Montenegro",
          "tipo": "nomada",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Mexico",
          "origen": "Todos",
          "destino": "Noruega",
          "tipo": "nomada",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Mexico",
          "origen": "Todos",
          "destino": "Nueva Zelanda",
          "tipo": "nomada",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Mexico",
          "origen": "Todos",
          "destino": "Países Bajos",
          "tipo": "nomada",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Mexico",
          "origen": "Todos",
          "destino": "Polonia",
          "tipo": "nomada",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Mexico",
          "origen": "Todos",
          "destino": "Portugal",
          "tipo": "nomada",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Mexico",
          "origen": "Todos",
          "destino": "Reino Unido",
          "tipo": "nomada",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Mexico",
          "origen": "Todos",
          "destino": "República Checa",
          "tipo": "nomada",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Mexico",
          "origen": "Todos",
          "destino": "Rumanía",
          "tipo": "nomada",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Mexico",
          "origen": "Todos",
          "destino": "Serbia",
          "tipo": "nomada",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Mexico",
          "origen": "Todos",
          "destino": "Suecia",
          "tipo": "nomada",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Mexico",
          "origen": "Todos",
          "destino": "Suiza",
          "tipo": "nomada",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Mexico",
          "origen": "Todos",
          "destino": "Túnez",
          "tipo": "nomada",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Mexico",
          "origen": "Todos",
          "destino": "Albania",
          "tipo": "nomada",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Mexico",
          "origen": "Todos",
          "destino": "Australia",
          "tipo": "nomada",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Mexico",
          "origen": "Todos",
          "destino": "Emiratos Árabes Unidos",
          "tipo": "nomada",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Mexico",
          "origen": "Todos",
          "destino": "India",
          "tipo": "nomada",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Mexico",
          "origen": "Todos",
          "destino": "Libia",
          "tipo": "nomada",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Mexico",
          "origen": "Todos",
          "destino": "Taiwán",
          "tipo": "nomada",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Mexico",
          "origen": "Todos",
          "destino": "Vietnam",
          "tipo": "nomada",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Mexico",
          "origen": "Todos",
          "destino": "Corea del Sur",
          "tipo": "nomada",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Mexico",
          "origen": "Todos",
          "destino": "Argentina",
          "tipo": "nomada",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Mexico",
          "origen": "Todos",
          "destino": "Bolivia",
          "tipo": "nomada",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Mexico",
          "origen": "Todos",
          "destino": "Brasil",
          "tipo": "nomada",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Mexico",
          "origen": "Todos",
          "destino": "Chile",
          "tipo": "nomada",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Mexico",
          "origen": "Todos",
          "destino": "China",
          "tipo": "nomada",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Mexico",
          "origen": "Todos",
          "destino": "Colombia",
          "tipo": "nomada",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Mexico",
          "origen": "Todos",
          "destino": "Curacao",
          "tipo": "nomada",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Mexico",
          "origen": "Todos",
          "destino": "El Salvador",
          "tipo": "nomada",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Mexico",
          "origen": "Todos",
          "destino": "Federación Rusa",
          "tipo": "nomada",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Mexico",
          "origen": "Todos",
          "destino": "Honduras",
          "tipo": "nomada",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Mexico",
          "origen": "Todos",
          "destino": "Hong Kong",
          "tipo": "nomada",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Mexico",
          "origen": "Todos",
          "destino": "Israel",
          "tipo": "nomada",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Mexico",
          "origen": "Todos",
          "destino": "Jamaica",
          "tipo": "nomada",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Mexico",
          "origen": "Todos",
          "destino": "Nicaragua",
          "tipo": "nomada",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Mexico",
          "origen": "Todos",
          "destino": "Panamá",
          "tipo": "nomada",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Mexico",
          "origen": "Todos",
          "destino": "Paraguay",
          "tipo": "nomada",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Mexico",
          "origen": "Todos",
          "destino": "Perú",
          "tipo": "nomada",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Mexico",
          "origen": "Todos",
          "destino": "República Dominicana",
          "tipo": "nomada",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Mexico",
          "origen": "Todos",
          "destino": "Singapur",
          "tipo": "nomada",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Mexico",
          "origen": "Todos",
          "destino": "Sudáfrica",
          "tipo": "nomada",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Mexico",
          "origen": "Todos",
          "destino": "Tailandia",
          "tipo": "nomada",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Mexico",
          "origen": "Todos",
          "destino": "Turquía",
          "tipo": "nomada",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Mexico",
          "origen": "Todos",
          "destino": "Uruguay",
          "tipo": "nomada",
          "visado": "SI",
          "porcentaje": "0.98"
        },
        {
          "pasaporte": "Mexico",
          "origen": "Todos",
          "destino": "Venezuela",
          "tipo": "nomada",
          "visado": "SI",
          "porcentaje": "0.98"
        }
      ]
  }
}
