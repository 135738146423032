export class Packages {
  public static global = {
    "paquetes": [
      {
        "id": 1,
        "name": "Europa de tus sueños",
        "type": "Visa de turismo",
        "sub": "Turismo, negocios, visitas familiares y transito hasta 6 meses.",
        "description": "España, Francia e Italia desde 2990 USD. 17 Dias / 16 Noches",
        "image": "europa-suenos",
        "benefits": [
          "Boleto Quito-Madrid // Barcelona-Madrid - Quito",
          "Traslado de llegada y salida",
          "Recorrido en autocar con guía en español, Desayuno continental",
          "Hoteles turista",
          "Versalles en París. (entrada no incluida)",
          "Barco: Tronchetto / Plaza de San Marcos / Tronchetto en Venecia.",
          "Visita Panorámica en: Madrid, Paris, Venecia, Roma, Barcelona.",
          "Traslado Nocturno: Plaza Mayor en Madrid, Trastevere en Roma. Traslado: Barrio de Montmartre en Paris.",
          "Fabrica de Cristal de Murano en Venecia.",
          "IVA e impuestos hoteleros",
        ]
      },
      {
        "id": 2,
        "name": "Europa te invita",
        "type": "Visa de turismo",
        "sub": "Turismo, negocios, visitas familiares y transito hasta 6 meses.",
        "description": "(España, Francia, Alemania,Republica Checa, Austria e Italia) desde 3990 USD 20. Dias / 19 Noches",
        "image": "europa-invita",
        "benefits": [
          "Boleto Quito – Madrid//Barcelona – Madrid -  Quito",
          "Incluye traslado de llegada y salida",
          "Recorrido en autocar con guía en español, Desayuno continental.",
          "Hoteles turista",
          "Versalles en Paris. (entrada no incluida)",
          "Barco: Tronchetto / Plaza de San Marcos / Tronchetto en Venecia.",
          "Visita Panorámica en: Madrid, Paris, Praga, Venecia, Roma, Barcelona.",
          "Traslado Nocturno: Plaza Mayor en Madrid, centro histórico de Praga, Trastevere en Roma. Traslado: Barrio de Montmartre en Paris.",
          "Fábrica cristal de Murano en Venecia",
          "IVA e impuestos ecuatorianos"
        ]
      },
      {
        "id": 3,
        "name": "Punta Cana",
        "type": "4 Dias / 3 Noches",
        "sub": "Turismo, negocios, visitas familiares y transito hasta 6 meses.",
        "description": "desde 799USD. 4 Dias / 3 Noches. Los mejores precios en vuelos al destino del mundo que desees.",
        "image": "punta-cana",
        "benefits": [
          "Traslado aeropuerto – hotel - aeropuerto",
          "03 noches de alojamiento",
          "Sistema ALL INCLUSIVE: Desayuno, almuerzo y cena",
          "Snacks",
          "Bebidas ilimitadas",
          "Bar en la piscina",
          "WI-FI en el hotel",
          "Piscinas al aire libre",
          "Centro Fitness y jardín",
          "SPA",
          "Club infantil para niños y adolescentes",
          "Mini Golf",
          "Deportes acuáticos no motorizados",
          "Actividades de entretenimiento",
          "Shows nocturnos",
          "Impuestos hoteleros",
          "IVA Mayorista e ISD"
        ]
      },
      {
        "id": 4,
        "name": "Cartagena",
        "type": "Visa de turismo",
        "sub": "Turismo, negocios, visitas familiares y transito hasta 6 meses.",
        "description": "Desde 449 USD. 4 Dias / 3 Noches",
        "image": "cartagena",
        "benefits": [
          "Traslado aeropuerto – Hotel – aeropuerto",
          "03 noches de alojamiento",
          "Pasadía South Beach - Playa Tranquila",
          "City Tour + Entrada al Castillo",
          "Atardecer Romántico en Bote Deportivo",
          "Plan de alimentación TODO INCLUIDO",
          "Desayunos (Estilo Buffet) Horarios: 7:00am a 10:00am",
          "Almuerzos (Estilo Buffet) Horarios: 12:00pm a 2:00pm.",
          "Cenas a la carta según días establecidos por el hotel – Horarios: 7:00pm a 9:00pm.",
          "Snacks: Mañana Tarde y Noche",
          "Bar abierto: 24 horas",
          "Recreación dirigida",
          "Shows o actividades nocturnas en vivo",
          "Piscina, (con aforo)",
          "Zona de juegos y solárium",
          "Llamadas locales gratis."
        ]
      },
      {
        "id": 5,
        "name": "Galapagos",
        "type": "Visa de turismo",
        "sub": "Turismo, negocios, visitas familiares y transito hasta 6 meses.",
        "description": "4 Dias / 3 Noches",
        "image": "galapagos",
        "benefits": [
          "Traslados Aeropuerto – Hotel – Aeropuerto",
          "Transporte terrestre y marítimo en servicio compartido",
          "03 noches de alojamiento en el hotel seleccionado",
          "Alimentación Completa",
          "Visitas y Excursiones terrestres y marítimas",
          "Asistencia en el aeropuerto",
          "Asistencia permanente durante todo el viaje",
          "Guías autorizados por el Parque Nacional Galápagos (obligatorio en cada excursión)",
          "Impuestos hoteleros",
          "IVA Mayorista",
          "Actividades:",
            "Estación Charles Darwin",
            "Playa Tortuga Bay",
            "Parte Alta: Cráteres Gemelos, Rancho + Túneles de lava, Playa de los Alemanes, Minas de Sal + Grietas"
        ]
      },
      {
        "id": 6,
        "name": "Medellin",
        "type": "Visa de turismo",
        "sub": "Turismo, negocios, visitas familiares y transito hasta 6 meses.",
        "description": "4 Dias / 3 Noches",
        "image": "medellin",
        "benefits": [
          "Traslado Aeropuerto – Hotel – Aeropuerto (SEMIPRIVADO)",
          "03 noches de alojamiento",
          "Desayuno diario tipo americano",
          "Llamadas locales",
          "WIFI",
          "Tour Full Day en Medellín: City Tour + Compras (semiprivado)",
          "Tour Peñol y Guatapé - Ruta de los embalses (semiprivado)",
          "Tarjeta de asistencia médica durante los tours",
          "Transporte en bus turístico",
          "Guía acompañante",
          "Hidratación",
          "Impuestos hoteleros",
          "Seguro hotelero",
          "IVA Mayorista e ISD"
        ]
      },
      {
        "id": 7,
        "name": "Turquia, Egipto y Dubai",
        "type": "Visa de turismo",
        "sub": "Turismo, negocios, visitas familiares y transito hasta 6 meses.",
        "description": "22 Dias / 21 Noches",
        "image": "turquia",
        "benefits": [
          "EGIPTO",
            "Todos los traslados in / out en coches con aire acondicionado.",
            "03 noches en el Cairo base de alojamiento Desayuno.",
            "03 noches en crucero por el Nilo base de Pensión completa. (sin bebidas).",
            "02 noches en Hurghada en base de todo incluido.",
            "Visita de medio día a las Pirámides, la Esfinge, el templo del Valle \"sin entrar al interior de cualquier Pirámide\".",
            "Las visitas del crucero: Aswan: La alta presa. Kom Ombo: El templo dedicado a los dioses Sobek y Haroeris. Edfu: El templo dedicado al dios Horus. Luxor: Templo de Karnak, Templo de Luxor.",
            "Guía de habla hispana durante las visitas.",
            "DUBÁI",
            "Traslados aeropuerto de Dubái – hotel – aeropuerto de Dubái con asistencia de habla hispana.",
            "04 noches en hotel con desayuno.",
            "Tour de medio día de Dubái con guía de habla hispana.",
            "Safari en el desierto con traslados en Ingles. (Asistencia den español $ 100).",
            "TURQUÍA",
            "Traslados aeropuerto de Estambul.",
            "04 noches Estambul A/D.",
            "02 noches Capadocia M/P.",
            "01 noche Pmaukkale M/P.",
            "02 noche Zona Kusadasi Esmirna M/P.",
            "Guía profesional de habla hispana.",
            "Visitas incluidas.",
            "Propinas en Hoteles y Restaurantes.",
            "Impuestos ecuatorianos"
        ]
      },
      {
        "id": 8,
        "name": "Santa Marta",
        "type": "Visa de turismo",
        "sub": "Turismo, negocios, visitas familiares y transito hasta 6 meses.",
        "description": "4 Dias / 3 Noches",
        "image": "santa-marta",
        "benefits": [
          "Traslado en compartido aeropuerto SMR – hotel – aeropuerto SMR",
          "03 Noches de alojamiento en hotel a elección + DESAYUNO.",
          "Full Day playa Blanca + Tour Bahia concha + almuerzo típico – servicio compartido",
          "Traslado en vehiculo climatizado desde el hotel (zona El Rodadero)",
          "Traslado en lancha hasta Bahia Concha",
          "Pasadia Bahia Concha",
          "Almuerzo Tipico",
          "Impuestos hoteleros",
        ]
      }
    ]

  }
}
