export class PlanByCountry {
  public static planes = [
     {
      "plan": "Plan lite",
      "destino": "ESTADOS UNIDOS DE AMERICA",
      "maximo": 79
     },
     {
      "plan": "Plan lite",
      "destino": "Republica dominicana",
      "maximo": 84
     },
     {
      "plan": "Plan lite",
      "destino": "Canadá",
      "maximo": 84
     },
     {
      "plan": "Plan lite",
      "destino": "Argentina",
      "maximo": 84
     },
     {
      "plan": "Plan lite",
      "destino": "Bolivia",
      "maximo": 84
     },
     {
      "plan": "Plan lite",
      "destino": "Brasil",
      "maximo": 84
     },
     {
      "plan": "Plan lite",
      "destino": "Chile",
      "maximo": 84
     },
     {
      "plan": "Plan lite",
      "destino": "Colombia",
      "maximo": 84
     },
     {
      "plan": "Plan lite",
      "destino": "Ecuador",
      "maximo": 84
     },
     {
      "plan": "Plan lite",
      "destino": "Guyana",
      "maximo": 84
     },
     {
      "plan": "Plan lite",
      "destino": "Paraguay",
      "maximo": 84
     },
     {
      "plan": "Plan lite",
      "destino": "Perú",
      "maximo": 84
     },
     {
      "plan": "Plan lite",
      "destino": "Surinam",
      "maximo": 84
     },
     {
      "plan": "Plan lite",
      "destino": "Uruguay",
      "maximo": 84
     },
     {
      "plan": "Plan lite",
      "destino": "Venezuela",
      "maximo": 84
     },
     {
      "plan": "Plan lite",
      "destino": "Belice",
      "maximo": 84
     },
     {
      "plan": "Plan lite",
      "destino": "Guatemala",
      "maximo": 84
     },
     {
      "plan": "Plan lite",
      "destino": "Honduras",
      "maximo": 84
     },
     {
      "plan": "Plan lite",
      "destino": "Nicaragua",
      "maximo": 84
     },
     {
      "plan": "Plan estandar",
      "destino": "Albania",
      "maximo": 84
     },
     {
      "plan": "Plan estandar",
      "destino": "Andorra",
      "maximo": 84
     },
     {
      "plan": "Plan estandar",
      "destino": "Armenia",
      "maximo": 84
     },
     {
      "plan": "Plan estandar",
      "destino": "Austria",
      "maximo": 84
     },
     {
      "plan": "Plan estandar",
      "destino": "Azerbaiyán",
      "maximo": 84
     },
     {
      "plan": "Plan estandar",
      "destino": "Bielorrusia",
      "maximo": 84
     },
     {
      "plan": "Plan estandar",
      "destino": "Bosnia y Herzegovina",
      "maximo": 84
     },
     {
      "plan": "Plan estandar",
      "destino": "Bulgaria",
      "maximo": 84
     },
     {
      "plan": "Plan estandar",
      "destino": "Chipre",
      "maximo": 84
     },
     {
      "plan": "Plan estandar",
      "destino": "Ciudad del Vaticano",
      "maximo": 84
     },
     {
      "plan": "Plan estandar",
      "destino": "Croacia",
      "maximo": 84
     },
     {
      "plan": "Plan estandar",
      "destino": "Costa Rica",
      "maximo": 84
     },
     {
      "plan": "Plan estandar",
      "destino": "Dinamarca",
      "maximo": 84
     },
     {
      "plan": "Plan estandar",
      "destino": "Eslovaquia",
      "maximo": 84
     },
     {
      "plan": "Plan estandar",
      "destino": "Eslovenia",
      "maximo": 84
     },
     {
      "plan": "Plan estandar",
      "destino": "El salvador",
      "maximo": 84
     },
     {
      "plan": "Plan estandar",
      "destino": "Estonia",
      "maximo": 84
     },
     {
      "plan": "Plan estandar",
      "destino": "Finlandia",
      "maximo": 84
     },
     {
      "plan": "Plan estandar",
      "destino": "Georgia",
      "maximo": 84
     },
     {
      "plan": "Plan estandar",
      "destino": "Hungría",
      "maximo": 84
     },
     {
      "plan": "Plan estandar",
      "destino": "Irlanda",
      "maximo": 84
     },
     {
      "plan": "Plan estandar",
      "destino": "Islandia",
      "maximo": 84
     },
     {
      "plan": "Plan estandar",
      "destino": "Kazajistán",
      "maximo": 84
     },
     {
      "plan": "Plan estandar",
      "destino": "Kosovo",
      "maximo": 84
     },
     {
      "plan": "Plan estandar",
      "destino": "Letonia",
      "maximo": 84
     },
     {
      "plan": "Plan estandar",
      "destino": "Liechtenstein",
      "maximo": 84
     },
     {
      "plan": "Plan estandar",
      "destino": "Lituania",
      "maximo": 84
     },
     {
      "plan": "Plan estandar",
      "destino": "Luxemburgo",
      "maximo": 84
     },
     {
      "plan": "Plan estandar",
      "destino": "Malta",
      "maximo": 84
     },
     {
      "plan": "Plan estandar",
      "destino": "Moldavia",
      "maximo": 84
     },
     {
      "plan": "Plan estandar",
      "destino": "Mexico",
      "maximo": 84
     },
     {
      "plan": "Plan estandar",
      "destino": "Montenegro",
      "maximo": 84
     },
     {
      "plan": "Plan estandar",
      "destino": "Noruega",
      "maximo": 84
     },
     {
      "plan": "Plan estandar",
      "destino": "Países Bajos",
      "maximo": 84
     },
     {
      "plan": "Plan estandar",
      "destino": "Polonia",
      "maximo": 84
     },
     {
      "plan": "Plan estandar",
      "destino": "Portugal",
      "maximo": 84
     },
     {
      "plan": "Plan estandar",
      "destino": "Reino Unido",
      "maximo": 84
     },
     {
      "plan": "Plan estandar",
      "destino": "República Checa",
      "maximo": 84
     },
     {
      "plan": "Plan estandar",
      "destino": "Rumania",
      "maximo": 84
     },
     {
      "plan": "Plan estandar",
      "destino": "Rusia",
      "maximo": 84
     },
     {
      "plan": "Plan estandar",
      "destino": "San Marino",
      "maximo": 84
     },
     {
      "plan": "Plan estandar",
      "destino": "Serbia",
      "maximo": 84
     },
     {
      "plan": "Plan estandar",
      "destino": "Suecia",
      "maximo": 84
     },
     {
      "plan": "Plan estandar",
      "destino": "Suiza",
      "maximo": 84
     },
     {
      "plan": "Plan estandar",
      "destino": "Turquía",
      "maximo": 84
     },
     {
      "plan": "Plan estandar",
      "destino": "Ucrania",
      "maximo": 84
     },
     {
      "plan": "Plan estandar",
      "destino": "Macedonia del Norte",
      "maximo": 84
     },
     {
      "plan": "Plan estandar",
      "destino": "Panamá",
      "maximo": 84
     },
     {
      "plan": "Plan ideal",
      "destino": "España",
      "maximo": 79
     },
     {
      "plan": "Plan ideal",
      "destino": "Alemania",
      "maximo": 79
     },
     {
      "plan": "Plan ideal",
      "destino": "Francia",
      "maximo": 79
     },
     {
      "plan": "Plan ideal",
      "destino": "Italia",
      "maximo": 79
     },
     {
      "plan": "Plan ideal",
      "destino": "Turquia",
      "maximo": 79
     },
     {
      "plan": "Plan ideal",
      "destino": "Japón",
      "maximo": 79
     },
     {
      "plan": "Plan ideal",
      "destino": "Bélgica",
      "maximo": 79
     },
     {
      "plan": "Plan ideal",
      "destino": "Países Bajos",
      "maximo": 79
     },
     {
      "plan": "Plan ideal",
      "destino": "Grecia",
      "maximo": 79
     },
     {
      "plan": "Plan ideal",
      "destino": "Mónaco",
      "maximo": 79
     },
    ]
}

