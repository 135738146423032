export class VisasTrabajo {
  public static visados = {
      "visas":[
        {
          "pasaporte": "Ecuador",
          "origen": "Todos",
          "destino": "Alemania",
          "tipo": "trabajo",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Contrato de trabajo \nsuficiencia de alemán \ntítulos de estudios previso. Tambien disponibles: visado para niñera y visado para busqueda de trabajo. Comuníquese con un asesor."
          },
          {
          "pasaporte": "Ecuador",
          "origen": "Todos",
          "destino": "Andorra",
          "tipo": "trabajo",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Contrato de trabajo \n2 años de experiencia laboral \ncartas de recomendación \nprueba de alojamiento \nantecedentes penales \ncertificado médico."
          },
          {
          "pasaporte": "Ecuador",
          "origen": "Todos",
          "destino": "Arabia Saudí",
          "tipo": "trabajo",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Contrato de trabajo \nantecedentes penales \ncopia certificada de título de estudio \ncertificado médico"
          },
          {
          "pasaporte": "Ecuador",
          "origen": "Todos",
          "destino": "Argelia",
          "tipo": "trabajo",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Contrato de trabajo \ncertificado médico \ncertificado de estudios previos."
          },
          {
          "pasaporte": "Ecuador",
          "origen": "Todos",
          "destino": "Austria",
          "tipo": "trabajo",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Visado de trabajo por sistema de calificación por puntos. Certiificado de nacimiento \nprueba de alojamiento \nseguro médico \nsolvencia económica \ntítulo universitario o superior \ncertificados de trabajo \nhabilidades lingüisticas."
          },
          {
          "pasaporte": "Ecuador",
          "origen": "Todos",
          "destino": "Bélgica",
          "tipo": "trabajo",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Contrato de trabajo \npermiso de trabajo \nantecedentes penales \ncertificado médico."
          },
          {
          "pasaporte": "Ecuador",
          "origen": "Todos",
          "destino": "Bulgaria",
          "tipo": "trabajo",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Contrato de trabajo \nantecedentes penales \nseguro médico \ncomprobante de alojamiento."
          },
          {
          "pasaporte": "Ecuador",
          "origen": "Todos",
          "destino": "Canadá",
          "tipo": "trabajo",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Oferta de trabajo \ncrear usuario y solicitud en IRCC \nexámenes médicos \nantecedentes penales"
          },
          {
          "pasaporte": "Ecuador",
          "origen": "Todos",
          "destino": "Costa Rica",
          "tipo": "trabajo",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Visaado gestionado directamente por el empleador. Comuníquese con un asesor."
          },
          {
          "pasaporte": "Ecuador",
          "origen": "Todos",
          "destino": "Croacia",
          "tipo": "trabajo",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Contrato de trabajo \nprueba de alojamiento \nseguro médico \nestudios superiores \nexperiencia laboral mayor a 1 año."
          },
          {
          "pasaporte": "Ecuador",
          "origen": "Todos",
          "destino": "Cuba",
          "tipo": "trabajo",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Autorización de trabajo solicitada por el empleador \nseguro médico \nantecedentes penales \nsolvencia económica."
          },
          {
          "pasaporte": "Ecuador",
          "origen": "Todos",
          "destino": "Dinamarca",
          "tipo": "trabajo",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Contrato de trabajo. Posibilidad de tramitar permiso de trabajo por sistema de puntos (tarjeta verde)"
          },
          {
          "pasaporte": "Ecuador",
          "origen": "Todos",
          "destino": "Eslovaquia",
          "tipo": "trabajo",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Contrato de trabajo \nseguro médico \ncertificado de estudios y experiencia previa."
          },
          {
          "pasaporte": "Ecuador",
          "origen": "Todos",
          "destino": "Eslovenia",
          "tipo": "trabajo",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Contrato de trabajo \nseguro médico \ncertificado de estudios y experiencia previa."
          },
          {
          "pasaporte": "Ecuador",
          "origen": "Todos",
          "destino": "España",
          "tipo": "trabajo",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Contrato de trabajo \nseguro médico \nantecedentes penales \ncertificado médico"
          },
          {
          "pasaporte": "Ecuador",
          "origen": "Todos",
          "destino": "Estados Unidos de América",
          "tipo": "trabajo",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Existen cinco categorías de visa de trabajo para Estados Unidos destinadas a trabajadores permanentes \ny los requisitos para calificar varían de acuerdo con el tipo de visa: Cada categoría está destinada a diferentes profesiones o habilidades. Algunas categorías requieren que tenga una oferta de trabajo de un empleador en EE. UU. (patrocinador) \nantes de presentar su solicitud de visa. Para algunas categorías es necesario que el empleador (patrocinador) presente una solicitud al Servicio de Ciudadanía e Inmigración de Estados Unidos (USCIS \nsigla en inglés) y obtenga una certificación laboral del Departamento del Trabajo (DOL \nsigla en inglés)"
          },
          {
          "pasaporte": "Ecuador",
          "origen": "Todos",
          "destino": "Estonia",
          "tipo": "trabajo",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Contrato de trabajo \nseguro médico \ncertificado de estudios y experiencia previa."
          },
          {
          "pasaporte": "Ecuador",
          "origen": "Todos",
          "destino": "Finlandia",
          "tipo": "trabajo",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Contrato de trabajo \nantecedentes penales. Disponible tarjeta azul para trabajadores cualificados."
          },
          {
          "pasaporte": "Ecuador",
          "origen": "Todos",
          "destino": "Francia",
          "tipo": "trabajo",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Contrato de trabajo \nsolicitud de visado"
          },
          {
          "pasaporte": "Ecuador",
          "origen": "Todos",
          "destino": "Grecia",
          "tipo": "trabajo",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Declaración de empleador \nseguro médico \nsolvencia económica."
          },
          {
          "pasaporte": "Ecuador",
          "origen": "Todos",
          "destino": "Guatemala",
          "tipo": "trabajo",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Permiso de trabajo \ncertificado de nacimiento. Disponible plataforma digital para solicitud en línea."
          },
          {
          "pasaporte": "Ecuador",
          "origen": "Todos",
          "destino": "Hungría",
          "tipo": "trabajo",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Contrato de trabajo"
          },
          {
          "pasaporte": "Ecuador",
          "origen": "Todos",
          "destino": "Irlanda",
          "tipo": "trabajo",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Contrato de trabajo. Disponible visado de trabajo cualificado"
          },
          {
          "pasaporte": "Ecuador",
          "origen": "Todos",
          "destino": "Islandia",
          "tipo": "trabajo",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Contrato de trabajo. Disponible visado para búsqueda de empleo. Contactar a un asesor"
          },
          {
          "pasaporte": "Ecuador",
          "origen": "Todos",
          "destino": "Italia",
          "tipo": "trabajo",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Autorización para el trabajo (en original y fotocopia) expedida por la \"Direzione Provinciale del Lavoro\" y aprobada por la \"Questura\" competente o el “Nulla Osta” expedido por el “Sportello Unico"
          },
          {
          "pasaporte": "Ecuador",
          "origen": "Todos",
          "destino": "Japón",
          "tipo": "trabajo",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Oferta de trabajo. Visas disponibles para trabajo cualificado y habilidades especificadas. Contactar a un asesor"
          },
          {
          "pasaporte": "Ecuador",
          "origen": "Todos",
          "destino": "Letonia",
          "tipo": "trabajo",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Carta del empleador \nconstancia de alojamiento \npermiso de residencia."
          },
          {
          "pasaporte": "Ecuador",
          "origen": "Todos",
          "destino": "Líbano",
          "tipo": "trabajo",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Permiso de trabajo \nseguro de viaje \nacta de nacimiento"
          },
          {
          "pasaporte": "Ecuador",
          "origen": "Todos",
          "destino": "Lituania",
          "tipo": "trabajo",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Contrato de trabajo \nseguro médico \ncertificado de estudios y experiencia previa."
          },
          {
          "pasaporte": "Ecuador",
          "origen": "Todos",
          "destino": "Luxemburgo",
          "tipo": "trabajo",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Contrato de trabajo \nseguro médico \ncertificado de estudios y experiencia previa."
          },
          {
          "pasaporte": "Ecuador",
          "origen": "Todos",
          "destino": "Malta",
          "tipo": "trabajo",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Contrato de trabajo. Se solicita en Malta"
          },
          {
          "pasaporte": "Ecuador",
          "origen": "Todos",
          "destino": "Marruecos",
          "tipo": "trabajo",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Contrato de trabajo"
          },
          {
          "pasaporte": "Ecuador",
          "origen": "Todos",
          "destino": "México",
          "tipo": "trabajo",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Oferta de empleo \nNotificación del Instituto Nacional de Migración con Número Único de Trámite (NUT)"
          },
          {
          "pasaporte": "Ecuador",
          "origen": "Todos",
          "destino": "Montenegro",
          "tipo": "trabajo",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Contrato de trabajo \ncertificado médico \nantecedentes penales"
          },
          {
          "pasaporte": "Ecuador",
          "origen": "Todos",
          "destino": "Noruega",
          "tipo": "trabajo",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Contrato de trabajo \nprueba de hospedaje \ncertificado de trabajos previos \ncertificados de estudios previos"
          },
          {
          "pasaporte": "Ecuador",
          "origen": "Todos",
          "destino": "Nueva Zelanda",
          "tipo": "trabajo",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Oferta Laboral \nexperiencia laboral y estudios demostrables \nsuficiencia de ingles."
          },
          {
          "pasaporte": "Ecuador",
          "origen": "Todos",
          "destino": "Países Bajos",
          "tipo": "trabajo",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Contrato de trabajo \nantecedentes penales \nseguro de viaje \nexámenes médicos. Otros visados disponinles. Contacte a un asesor."
          },
          {
          "pasaporte": "Ecuador",
          "origen": "Todos",
          "destino": "Polonia",
          "tipo": "trabajo",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "El empleador debe solicitar permiso de trabajo \nseguro médico. Otras visas disponibles. Contacte a un asesor."
          },
          {
          "pasaporte": "Ecuador",
          "origen": "Todos",
          "destino": "Portugal",
          "tipo": "trabajo",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Contrato de trabajo. Visado para buscar trabajo según disponibilidad de cupo anual."
          },
          {
          "pasaporte": "Ecuador",
          "origen": "Todos",
          "destino": "Reino Unido",
          "tipo": "trabajo",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Contar con empleador auotrizado. Prueba de hospedaje \nprueba de tuberculosis. Otras visas disponibles. Contacte a un asesor."
          },
          {
          "pasaporte": "Ecuador",
          "origen": "Todos",
          "destino": "República Checa",
          "tipo": "trabajo",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "El empleador debe solicitar permiso de trabajo \nseguro médico \nantecedentes penales \ncopias certificadas de calificaciones académicas y laborales"
          },
          {
          "pasaporte": "Ecuador",
          "origen": "Todos",
          "destino": "Rumanía",
          "tipo": "trabajo",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Contrato de trabajo \ncertificado médico \nantecedentes penales"
          },
          {
          "pasaporte": "Ecuador",
          "origen": "Todos",
          "destino": "Serbia",
          "tipo": "trabajo",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Ell empleador debe solicitar al Servicio Nacional de Empleo ( National Employment Service  \nNES) que realice una prueba de mercado laboral para garantizar que no haya ciudadanos serbios elegibles disponibles para ocupar el puesto. Permiso de trabajo"
          },
          {
          "pasaporte": "Ecuador",
          "origen": "Todos",
          "destino": "Suecia",
          "tipo": "trabajo",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Contrato de trabajo \npermiso de trabajo. Disponible visado para buscar trabajo a personas altamente calificadas"
          },
          {
          "pasaporte": "Ecuador",
          "origen": "Todos",
          "destino": "Suiza",
          "tipo": "trabajo",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Contrato de trabajo para profesionales cualificados según disponibilidad de cupo anual."
          },
          {
          "pasaporte": "Ecuador",
          "origen": "Todos",
          "destino": "Túnez",
          "tipo": "trabajo",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Carta de invitación del empleador \ncomprobante de alojamiento"
          },
          {
          "pasaporte": "Ecuador",
          "origen": "Todos",
          "destino": "Albania",
          "tipo": "trabajo",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Contrato de empleo \nextracto del Centro Nacional de Registro que confirma el registro como empleador."
          },
          {
          "pasaporte": "Ecuador",
          "origen": "Todos",
          "destino": "Australia",
          "tipo": "trabajo",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Visa por habilidades y sistema de puntuación. Contacte a un asesor."
          },
          {
          "pasaporte": "Ecuador",
          "origen": "Todos",
          "destino": "Emiratos Árabes Unidos",
          "tipo": "trabajo",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Contrato de trabajo \nseguro médico. Disponible visa nómada y trabajo freelance"
          },
          {
          "pasaporte": "Ecuador",
          "origen": "Todos",
          "destino": "India",
          "tipo": "trabajo",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Contrato de trabajo \npara profesionales calificados \ncopias de diplomas de estudios previos y certificados laborales previos."
          },
          {
          "pasaporte": "Ecuador",
          "origen": "Todos",
          "destino": "Libia",
          "tipo": "trabajo",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Contrato de trabajo"
          },
          {
          "pasaporte": "Ecuador",
          "origen": "Todos",
          "destino": "Taiwán",
          "tipo": "trabajo",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Contrato de trabajo \ncertificado de saliud"
          },
          {
          "pasaporte": "Ecuador",
          "origen": "Todos",
          "destino": "Vietnam",
          "tipo": "trabajo",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Empleador debe solicitar permiso de trabajo"
          },
          {
          "pasaporte": "Ecuador",
          "origen": "Todos",
          "destino": "Corea del Sur",
          "tipo": "trabajo",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Copia del contrato de empleo \ncertificados educativos y profesionales \nantecedentes penales \ncarta de recomendación"
          },
          {
          "pasaporte": "Ecuador",
          "origen": "Todos",
          "destino": "Argentina",
          "tipo": "trabajo",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Contrato de trabajo \ncertificado médico \nantecedentes penales"
          },
          {
          "pasaporte": "Ecuador",
          "origen": "Todos",
          "destino": "Bolivia",
          "tipo": "trabajo",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Contrato de trabajo \ncertificado médico \nantecedentes penales"
          },
          {
          "pasaporte": "Ecuador",
          "origen": "Todos",
          "destino": "Brasil",
          "tipo": "trabajo",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Contrato de trabajo \ncertificado médico \nantecedentes penales"
          },
          {
          "pasaporte": "Ecuador",
          "origen": "Todos",
          "destino": "Chile",
          "tipo": "trabajo",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Contrato de trabajo \ncertificado médico \nantecedentes penales"
          },
          {
          "pasaporte": "Ecuador",
          "origen": "Todos",
          "destino": "China",
          "tipo": "trabajo",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Certificado de salud \npermiso de trabajo que se otorga a criterio según aporte al país. Prueba de alojamiento"
          },
          {
          "pasaporte": "Ecuador",
          "origen": "Todos",
          "destino": "Colombia",
          "tipo": "trabajo",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Contrato de trabajo \ncertificado médico \nantecedentes penales"
          },
          {
          "pasaporte": "Ecuador",
          "origen": "Todos",
          "destino": "Curacao",
          "tipo": "trabajo",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Certificado médico \ncertificado de antecedentes policiales \ncarta de su empleador indicando que le han ofrecido un trabajo \ncopia de su contrato de trabajo \ncopia de CV \ncopia de sus expedientes académicos."
          },
          {
          "pasaporte": "Ecuador",
          "origen": "Todos",
          "destino": "El Salvador",
          "tipo": "trabajo",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Contrato de trabajo. Los extranjeros podrán solicitar residencia temporal con autorización para trabajar en la Dirección General de Migración y Extranjería (DGME) con la opinión del Ministerio de Trabajo y Previsión Social (MTPS) según el caso."
          },
          {
          "pasaporte": "Ecuador",
          "origen": "Todos",
          "destino": "Federación Rusa",
          "tipo": "trabajo",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Empleador debe tramitar permiso de trabajo.Copias certificadas de calificaciones \ncertificado médico."
          },
          {
          "pasaporte": "Ecuador",
          "origen": "Todos",
          "destino": "Honduras",
          "tipo": "trabajo",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Contrato de trabajo \ncertificado médico \nantecedentes penales"
          },
          {
          "pasaporte": "Ecuador",
          "origen": "Todos",
          "destino": "Hong Kong",
          "tipo": "trabajo",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Contrato de trabajo \ntítulo profesional \nexperiencia profesional"
          },
          {
          "pasaporte": "Ecuador",
          "origen": "Todos",
          "destino": "Israel",
          "tipo": "trabajo",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Contrato de trabajo \ncertificado médico \nantecedentes penales"
          },
          {
          "pasaporte": "Ecuador",
          "origen": "Todos",
          "destino": "Jamaica",
          "tipo": "trabajo",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Empleador solicita permiso de trabajo \ncertificado de nacimiento \nantecedentes penales \nprueba de estado civil \nsolvencia económica."
          },
          {
          "pasaporte": "Ecuador",
          "origen": "Todos",
          "destino": "Nicaragua",
          "tipo": "trabajo",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Contrato de trabajo \ncertificado médico \nantecedentes penales"
          },
          {
          "pasaporte": "Ecuador",
          "origen": "Todos",
          "destino": "Panamá",
          "tipo": "trabajo",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Contrato de trabajo \ncertificado médico \nantecedentes penales \ncomprobante de alojamiento"
          },
          {
          "pasaporte": "Ecuador",
          "origen": "Todos",
          "destino": "Paraguay",
          "tipo": "trabajo",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Contrato de trabajo \ncertificado médico \nceertificado de estado civil \ncertificado de nacimiento \nantecedentes penales"
          },
          {
          "pasaporte": "Ecuador",
          "origen": "Todos",
          "destino": "Perú",
          "tipo": "trabajo",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Contrato de trabajo \ncertificado médico \nantecedentes penales"
          },
          {
          "pasaporte": "Ecuador",
          "origen": "Todos",
          "destino": "República Dominicana",
          "tipo": "trabajo",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Contrato de trabajo \ncertificado médico \nantecedentes penales"
          },
          {
          "pasaporte": "Ecuador",
          "origen": "Todos",
          "destino": "Singapur",
          "tipo": "trabajo",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "El empleador debe solicitar el visado"
          },
          {
          "pasaporte": "Ecuador",
          "origen": "Todos",
          "destino": "Sudáfrica",
          "tipo": "trabajo",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Oferta de trabajo \nexámenes médicos \nceertificado de vacunación \nantecedentes penales."
          },
          {
          "pasaporte": "Ecuador",
          "origen": "Todos",
          "destino": "Tailandia",
          "tipo": "trabajo",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Oferta de trabajo. Visas disponibles según el trabajo a desempeñar. Contacte a un asesor"
          },
          {
          "pasaporte": "Ecuador",
          "origen": "Todos",
          "destino": "Turquía",
          "tipo": "trabajo",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Oferta de trabajo o contrato de trabajo \nantecedentes penales \nseguro médico \nevidencia de alojamiento \nsolvencia económica"
          },
          {
          "pasaporte": "Ecuador",
          "origen": "Todos",
          "destino": "Uruguay",
          "tipo": "trabajo",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Contrato de trabajo \ncertificado médico \nantecedentes penales"
          },
          {
          "pasaporte": "Ecuador",
          "origen": "Todos",
          "destino": "Venezuela",
          "tipo": "trabajo",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Contrato de trabajo \ncertificado médico \nantecedentes penales"
          },
          {
          "pasaporte": "Pasaporte",
          "origen": "Origen",
          "destino": "Destino",
          "tipo": "trabajo",
          "visado": "¿Requiere VISADO?",
          "porcentaje": "PORCENTAJE DE APROBACIÓN",
          "requisitos": "REQUISITOS"
          },
          {
          "pasaporte": "Colombia",
          "origen": "Todos",
          "destino": "Alemania",
          "tipo": "trabajo",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Contrato de trabajo \nsuficiencia de alemán \ntítulos de estudios previso. Tambien disponibles: visado para niñera y visado para busqueda de trabajo. Comuníquese con un asesor."
          },
          {
          "pasaporte": "Colombia",
          "origen": "Todos",
          "destino": "Andorra",
          "tipo": "trabajo",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Contrato de trabajo \n2 años de experiencia laboral \ncartas de recomendación \nprueba de alojamiento \nantecedentes penales \ncertificado médico."
          },
          {
          "pasaporte": "Colombia",
          "origen": "Todos",
          "destino": "Arabia Saudí",
          "tipo": "trabajo",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Contrato de trabajo \nantecedentes penales \ncopia certificada de título de estudio \ncertificado médico"
          },
          {
          "pasaporte": "Colombia",
          "origen": "Todos",
          "destino": "Argelia",
          "tipo": "trabajo",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Contrato de trabajo \ncertificado médico \ncertificado de estudios previos."
          },
          {
          "pasaporte": "Colombia",
          "origen": "Todos",
          "destino": "Austria",
          "tipo": "trabajo",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Visado de trabajo por sistema de calificación por puntos. Certiificado de nacimiento \nprueba de alojamiento \nseguro médico \nsolvencia económica \ntítulo universitario o superior \ncertificados de trabajo \nhabilidades lingüisticas."
          },
          {
          "pasaporte": "Colombia",
          "origen": "Todos",
          "destino": "Bélgica",
          "tipo": "trabajo",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Contrato de trabajo \npermiso de trabajo \nantecedentes penales \ncertificado médico."
          },
          {
          "pasaporte": "Colombia",
          "origen": "Todos",
          "destino": "Bulgaria",
          "tipo": "trabajo",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Contrato de trabajo \nantecedentes penales \nseguro médico \ncomprobante de alojamiento."
          },
          {
          "pasaporte": "Colombia",
          "origen": "Todos",
          "destino": "Canadá",
          "tipo": "trabajo",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Oferta de trabajo \ncrear usuario y solicitud en IRCC \nexámenes médicos \nantecedentes penales"
          },
          {
          "pasaporte": "Colombia",
          "origen": "Todos",
          "destino": "Costa Rica",
          "tipo": "trabajo",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Visaado gestionado directamente por el empleador. Comuníquese con un asesor."
          },
          {
          "pasaporte": "Colombia",
          "origen": "Todos",
          "destino": "Croacia",
          "tipo": "trabajo",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Contrato de trabajo \nprueba de alojamiento \nseguro médico \nestudios superiores \nexperiencia laboral mayor a 1 año."
          },
          {
          "pasaporte": "Colombia",
          "origen": "Todos",
          "destino": "Cuba",
          "tipo": "trabajo",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Autorización de trabajo solicitada por el empleador \nseguro médico \nantecedentes penales \nsolvencia económica."
          },
          {
          "pasaporte": "Colombia",
          "origen": "Todos",
          "destino": "Dinamarca",
          "tipo": "trabajo",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Contrato de trabajo. Posibilidad de tramitar permiso de trabajo por sistema de puntos (tarjeta verde)"
          },
          {
          "pasaporte": "Colombia",
          "origen": "Todos",
          "destino": "Eslovaquia",
          "tipo": "trabajo",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Contrato de trabajo \nseguro médico \ncertificado de estudios y experiencia previa."
          },
          {
          "pasaporte": "Colombia",
          "origen": "Todos",
          "destino": "Eslovenia",
          "tipo": "trabajo",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Contrato de trabajo \nseguro médico \ncertificado de estudios y experiencia previa."
          },
          {
          "pasaporte": "Colombia",
          "origen": "Todos",
          "destino": "España",
          "tipo": "trabajo",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Contrato de trabajo \nseguro médico \nantecedentes penales \ncertificado médico"
          },
          {
          "pasaporte": "Colombia",
          "origen": "Todos",
          "destino": "Estados Unidos de América",
          "tipo": "trabajo",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Existen cinco categorías de visa de trabajo para Estados Unidos destinadas a trabajadores permanentes \ny los requisitos para calificar varían de acuerdo con el tipo de visa: Cada categoría está destinada a diferentes profesiones o habilidades. Algunas categorías requieren que tenga una oferta de trabajo de un empleador en EE. UU. (patrocinador) \nantes de presentar su solicitud de visa. Para algunas categorías es necesario que el empleador (patrocinador) presente una solicitud al Servicio de Ciudadanía e Inmigración de Estados Unidos (USCIS \nsigla en inglés) y obtenga una certificación laboral del Departamento del Trabajo (DOL \nsigla en inglés)"
          },
          {
          "pasaporte": "Colombia",
          "origen": "Todos",
          "destino": "Estonia",
          "tipo": "trabajo",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Contrato de trabajo \nseguro médico \ncertificado de estudios y experiencia previa."
          },
          {
          "pasaporte": "Colombia",
          "origen": "Todos",
          "destino": "Finlandia",
          "tipo": "trabajo",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Contrato de trabajo \nantecedentes penales. Disponible tarjeta azul para trabajadores cualificados."
          },
          {
          "pasaporte": "Colombia",
          "origen": "Todos",
          "destino": "Francia",
          "tipo": "trabajo",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Contrato de trabajo \nsolicitud de visado"
          },
          {
          "pasaporte": "Colombia",
          "origen": "Todos",
          "destino": "Grecia",
          "tipo": "trabajo",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Declaración de empleador \nseguro médico \nsolvencia económica."
          },
          {
          "pasaporte": "Colombia",
          "origen": "Todos",
          "destino": "Guatemala",
          "tipo": "trabajo",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Permiso de trabajo \ncertificado de nacimiento. Disponible plataforma digital para solicitud en línea."
          },
          {
          "pasaporte": "Colombia",
          "origen": "Todos",
          "destino": "Hungría",
          "tipo": "trabajo",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Contrato de trabajo"
          },
          {
          "pasaporte": "Colombia",
          "origen": "Todos",
          "destino": "Irlanda",
          "tipo": "trabajo",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Contrato de trabajo. Disponible visado de trabajo cualificado"
          },
          {
          "pasaporte": "Colombia",
          "origen": "Todos",
          "destino": "Islandia",
          "tipo": "trabajo",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Contrato de trabajo. Disponible visado para búsqueda de empleo. Contactar a un asesor"
          },
          {
          "pasaporte": "Colombia",
          "origen": "Todos",
          "destino": "Italia",
          "tipo": "trabajo",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Autorización para el trabajo (en original y fotocopia) expedida por la \"Direzione Provinciale del Lavoro\" y aprobada por la \"Questura\" competente o el “Nulla Osta” expedido por el “Sportello Unico"
          },
          {
          "pasaporte": "Colombia",
          "origen": "Todos",
          "destino": "Japón",
          "tipo": "trabajo",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Oferta de trabajo. Visas disponibles para trabajo cualificado y habilidades especificadas. Contactar a un asesor"
          },
          {
          "pasaporte": "Colombia",
          "origen": "Todos",
          "destino": "Letonia",
          "tipo": "trabajo",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Carta del empleador \nconstancia de alojamiento \npermiso de residencia."
          },
          {
          "pasaporte": "Colombia",
          "origen": "Todos",
          "destino": "Líbano",
          "tipo": "trabajo",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Permiso de trabajo \nseguro de viaje \nacta de nacimiento"
          },
          {
          "pasaporte": "Colombia",
          "origen": "Todos",
          "destino": "Lituania",
          "tipo": "trabajo",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Contrato de trabajo \nseguro médico \ncertificado de estudios y experiencia previa."
          },
          {
          "pasaporte": "Colombia",
          "origen": "Todos",
          "destino": "Luxemburgo",
          "tipo": "trabajo",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Contrato de trabajo \nseguro médico \ncertificado de estudios y experiencia previa."
          },
          {
          "pasaporte": "Colombia",
          "origen": "Todos",
          "destino": "Malta",
          "tipo": "trabajo",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Contrato de trabajo. Se solicita en Malta"
          },
          {
          "pasaporte": "Colombia",
          "origen": "Todos",
          "destino": "Marruecos",
          "tipo": "trabajo",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Contrato de trabajo"
          },
          {
          "pasaporte": "Colombia",
          "origen": "Todos",
          "destino": "México",
          "tipo": "trabajo",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Oferta de empleo \nNotificación del Instituto Nacional de Migración con Número Único de Trámite (NUT)"
          },
          {
          "pasaporte": "Colombia",
          "origen": "Todos",
          "destino": "Montenegro",
          "tipo": "trabajo",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Contrato de trabajo \ncertificado médico \nantecedentes penales"
          },
          {
          "pasaporte": "Colombia",
          "origen": "Todos",
          "destino": "Noruega",
          "tipo": "trabajo",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Contrato de trabajo \nprueba de hospedaje \ncertificado de trabajos previos \ncertificados de estudios previos"
          },
          {
          "pasaporte": "Colombia",
          "origen": "Todos",
          "destino": "Nueva Zelanda",
          "tipo": "trabajo",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Oferta Laboral \nexperiencia laboral y estudios demostrables \nsuficiencia de ingles."
          },
          {
          "pasaporte": "Colombia",
          "origen": "Todos",
          "destino": "Países Bajos",
          "tipo": "trabajo",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Contrato de trabajo \nantecedentes penales \nseguro de viaje \nexámenes médicos. Otros visados disponinles. Contacte a un asesor."
          },
          {
          "pasaporte": "Colombia",
          "origen": "Todos",
          "destino": "Polonia",
          "tipo": "trabajo",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "El empleador debe solicitar permiso de trabajo \nseguro médico. Otras visas disponibles. Contacte a un asesor."
          },
          {
          "pasaporte": "Colombia",
          "origen": "Todos",
          "destino": "Portugal",
          "tipo": "trabajo",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Contrato de trabajo. Visado para buscar trabajo según disponibilidad de cupo anual."
          },
          {
          "pasaporte": "Colombia",
          "origen": "Todos",
          "destino": "Reino Unido",
          "tipo": "trabajo",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Contar con empleador auotrizado. Prueba de hospedaje \nprueba de tuberculosis. Otras visas disponibles. Contacte a un asesor."
          },
          {
          "pasaporte": "Colombia",
          "origen": "Todos",
          "destino": "República Checa",
          "tipo": "trabajo",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "El empleador debe solicitar permiso de trabajo \nseguro médico \nantecedentes penales \ncopias certificadas de calificaciones académicas y laborales"
          },
          {
          "pasaporte": "Colombia",
          "origen": "Todos",
          "destino": "Rumanía",
          "tipo": "trabajo",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Contrato de trabajo \ncertificado médico \nantecedentes penales"
          },
          {
          "pasaporte": "Colombia",
          "origen": "Todos",
          "destino": "Serbia",
          "tipo": "trabajo",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Ell empleador debe solicitar al Servicio Nacional de Empleo ( National Employment Service  \nNES) que realice una prueba de mercado laboral para garantizar que no haya ciudadanos serbios elegibles disponibles para ocupar el puesto. Permiso de trabajo"
          },
          {
          "pasaporte": "Colombia",
          "origen": "Todos",
          "destino": "Suecia",
          "tipo": "trabajo",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Contrato de trabajo \npermiso de trabajo. Disponible visado para buscar trabajo a personas altamente calificadas"
          },
          {
          "pasaporte": "Colombia",
          "origen": "Todos",
          "destino": "Suiza",
          "tipo": "trabajo",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Contrato de trabajo para profesionales cualificados según disponibilidad de cupo anual."
          },
          {
          "pasaporte": "Colombia",
          "origen": "Todos",
          "destino": "Túnez",
          "tipo": "trabajo",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Carta de invitación del empleador \ncomprobante de alojamiento"
          },
          {
          "pasaporte": "Colombia",
          "origen": "Todos",
          "destino": "Albania",
          "tipo": "trabajo",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Contrato de empleo \nextracto del Centro Nacional de Registro que confirma el registro como empleador."
          },
          {
          "pasaporte": "Colombia",
          "origen": "Todos",
          "destino": "Australia",
          "tipo": "trabajo",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Visa por habilidades y sistema de puntuación. Contacte a un asesor."
          },
          {
          "pasaporte": "Colombia",
          "origen": "Todos",
          "destino": "Emiratos Árabes Unidos",
          "tipo": "trabajo",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Contrato de trabajo \nseguro médico. Disponible visa nómada y trabajo freelance"
          },
          {
          "pasaporte": "Colombia",
          "origen": "Todos",
          "destino": "India",
          "tipo": "trabajo",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Contrato de trabajo \npara profesionales calificados \ncopias de diplomas de estudios previos y certificados laborales previos."
          },
          {
          "pasaporte": "Colombia",
          "origen": "Todos",
          "destino": "Libia",
          "tipo": "trabajo",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Contrato de trabajo"
          },
          {
          "pasaporte": "Pasaporte",
          "origen": "Origen",
          "destino": "Destino",
          "tipo": "trabajo",
          "visado": "¿Requiere VISADO?",
          "porcentaje": "PORCENTAJE DE APROBACIÓN",
          "requisitos": "REQUISITOS"
          },
          {
          "pasaporte": "Peru",
          "origen": "Todos",
          "destino": "Alemania",
          "tipo": "trabajo",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Contrato de trabajo \nsuficiencia de alemán \ntítulos de estudios previso. Tambien disponibles: visado para niñera y visado para busqueda de trabajo. Comuníquese con un asesor."
          },
          {
          "pasaporte": "Peru",
          "origen": "Todos",
          "destino": "Andorra",
          "tipo": "trabajo",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Contrato de trabajo \n2 años de experiencia laboral \ncartas de recomendación \nprueba de alojamiento \nantecedentes penales \ncertificado médico."
          },
          {
          "pasaporte": "Peru",
          "origen": "Todos",
          "destino": "Arabia Saudí",
          "tipo": "trabajo",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Contrato de trabajo \nantecedentes penales \ncopia certificada de título de estudio \ncertificado médico"
          },
          {
          "pasaporte": "Peru",
          "origen": "Todos",
          "destino": "Argelia",
          "tipo": "trabajo",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Contrato de trabajo \ncertificado médico \ncertificado de estudios previos."
          },
          {
          "pasaporte": "Peru",
          "origen": "Todos",
          "destino": "Austria",
          "tipo": "trabajo",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Visado de trabajo por sistema de calificación por puntos. Certiificado de nacimiento \nprueba de alojamiento \nseguro médico \nsolvencia económica \ntítulo universitario o superior \ncertificados de trabajo \nhabilidades lingüisticas."
          },
          {
          "pasaporte": "Peru",
          "origen": "Todos",
          "destino": "Bélgica",
          "tipo": "trabajo",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Contrato de trabajo \npermiso de trabajo \nantecedentes penales \ncertificado médico."
          },
          {
          "pasaporte": "Peru",
          "origen": "Todos",
          "destino": "Bulgaria",
          "tipo": "trabajo",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Contrato de trabajo \nantecedentes penales \nseguro médico \ncomprobante de alojamiento."
          },
          {
          "pasaporte": "Peru",
          "origen": "Todos",
          "destino": "Canadá",
          "tipo": "trabajo",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Oferta de trabajo \ncrear usuario y solicitud en IRCC \nexámenes médicos \nantecedentes penales"
          },
          {
          "pasaporte": "Peru",
          "origen": "Todos",
          "destino": "Costa Rica",
          "tipo": "trabajo",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Visaado gestionado directamente por el empleador. Comuníquese con un asesor."
          },
          {
          "pasaporte": "Peru",
          "origen": "Todos",
          "destino": "Croacia",
          "tipo": "trabajo",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Contrato de trabajo \nprueba de alojamiento \nseguro médico \nestudios superiores \nexperiencia laboral mayor a 1 año."
          },
          {
          "pasaporte": "Peru",
          "origen": "Todos",
          "destino": "Cuba",
          "tipo": "trabajo",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Autorización de trabajo solicitada por el empleador \nseguro médico \nantecedentes penales \nsolvencia económica."
          },
          {
          "pasaporte": "Peru",
          "origen": "Todos",
          "destino": "Dinamarca",
          "tipo": "trabajo",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Contrato de trabajo. Posibilidad de tramitar permiso de trabajo por sistema de puntos (tarjeta verde)"
          },
          {
          "pasaporte": "Peru",
          "origen": "Todos",
          "destino": "Eslovaquia",
          "tipo": "trabajo",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Contrato de trabajo \nseguro médico \ncertificado de estudios y experiencia previa."
          },
          {
          "pasaporte": "Peru",
          "origen": "Todos",
          "destino": "Eslovenia",
          "tipo": "trabajo",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Contrato de trabajo \nseguro médico \ncertificado de estudios y experiencia previa."
          },
          {
          "pasaporte": "Peru",
          "origen": "Todos",
          "destino": "España",
          "tipo": "trabajo",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Contrato de trabajo \nseguro médico \nantecedentes penales \ncertificado médico"
          },
          {
          "pasaporte": "Peru",
          "origen": "Todos",
          "destino": "Estados Unidos de América",
          "tipo": "trabajo",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Existen cinco categorías de visa de trabajo para Estados Unidos destinadas a trabajadores permanentes \ny los requisitos para calificar varían de acuerdo con el tipo de visa: Cada categoría está destinada a diferentes profesiones o habilidades. Algunas categorías requieren que tenga una oferta de trabajo de un empleador en EE. UU. (patrocinador) \nantes de presentar su solicitud de visa. Para algunas categorías es necesario que el empleador (patrocinador) presente una solicitud al Servicio de Ciudadanía e Inmigración de Estados Unidos (USCIS \nsigla en inglés) y obtenga una certificación laboral del Departamento del Trabajo (DOL \nsigla en inglés)"
          },
          {
          "pasaporte": "Peru",
          "origen": "Todos",
          "destino": "Estonia",
          "tipo": "trabajo",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Contrato de trabajo \nseguro médico \ncertificado de estudios y experiencia previa."
          },
          {
          "pasaporte": "Peru",
          "origen": "Todos",
          "destino": "Finlandia",
          "tipo": "trabajo",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Contrato de trabajo \nantecedentes penales. Disponible tarjeta azul para trabajadores cualificados."
          },
          {
          "pasaporte": "Peru",
          "origen": "Todos",
          "destino": "Francia",
          "tipo": "trabajo",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Contrato de trabajo \nsolicitud de visado"
          },
          {
          "pasaporte": "Peru",
          "origen": "Todos",
          "destino": "Grecia",
          "tipo": "trabajo",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Declaración de empleador \nseguro médico \nsolvencia económica."
          },
          {
          "pasaporte": "Peru",
          "origen": "Todos",
          "destino": "Guatemala",
          "tipo": "trabajo",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Permiso de trabajo \ncertificado de nacimiento. Disponible plataforma digital para solicitud en línea."
          },
          {
          "pasaporte": "Peru",
          "origen": "Todos",
          "destino": "Hungría",
          "tipo": "trabajo",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Contrato de trabajo"
          },
          {
          "pasaporte": "Peru",
          "origen": "Todos",
          "destino": "Irlanda",
          "tipo": "trabajo",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Contrato de trabajo. Disponible visado de trabajo cualificado"
          },
          {
          "pasaporte": "Peru",
          "origen": "Todos",
          "destino": "Islandia",
          "tipo": "trabajo",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Contrato de trabajo. Disponible visado para búsqueda de empleo. Contactar a un asesor"
          },
          {
          "pasaporte": "Peru",
          "origen": "Todos",
          "destino": "Italia",
          "tipo": "trabajo",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Autorización para el trabajo (en original y fotocopia) expedida por la \"Direzione Provinciale del Lavoro\" y aprobada por la \"Questura\" competente o el “Nulla Osta” expedido por el “Sportello Unico"
          },
          {
          "pasaporte": "Peru",
          "origen": "Todos",
          "destino": "Japón",
          "tipo": "trabajo",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Oferta de trabajo. Visas disponibles para trabajo cualificado y habilidades especificadas. Contactar a un asesor"
          },
          {
          "pasaporte": "Peru",
          "origen": "Todos",
          "destino": "Letonia",
          "tipo": "trabajo",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Carta del empleador \nconstancia de alojamiento \npermiso de residencia."
          },
          {
          "pasaporte": "Peru",
          "origen": "Todos",
          "destino": "Líbano",
          "tipo": "trabajo",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Permiso de trabajo \nseguro de viaje \nacta de nacimiento"
          },
          {
          "pasaporte": "Peru",
          "origen": "Todos",
          "destino": "Lituania",
          "tipo": "trabajo",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Contrato de trabajo \nseguro médico \ncertificado de estudios y experiencia previa."
          },
          {
          "pasaporte": "Peru",
          "origen": "Todos",
          "destino": "Luxemburgo",
          "tipo": "trabajo",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Contrato de trabajo \nseguro médico \ncertificado de estudios y experiencia previa."
          },
          {
          "pasaporte": "Peru",
          "origen": "Todos",
          "destino": "Malta",
          "tipo": "trabajo",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Contrato de trabajo. Se solicita en Malta"
          },
          {
          "pasaporte": "Peru",
          "origen": "Todos",
          "destino": "Marruecos",
          "tipo": "trabajo",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Contrato de trabajo"
          },
          {
          "pasaporte": "Peru",
          "origen": "Todos",
          "destino": "México",
          "tipo": "trabajo",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Oferta de empleo \nNotificación del Instituto Nacional de Migración con Número Único de Trámite (NUT)"
          },
          {
          "pasaporte": "Peru",
          "origen": "Todos",
          "destino": "Montenegro",
          "tipo": "trabajo",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Contrato de trabajo \ncertificado médico \nantecedentes penales"
          },
          {
          "pasaporte": "Peru",
          "origen": "Todos",
          "destino": "Noruega",
          "tipo": "trabajo",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Contrato de trabajo \nprueba de hospedaje \ncertificado de trabajos previos \ncertificados de estudios previos"
          },
          {
          "pasaporte": "Peru",
          "origen": "Todos",
          "destino": "Nueva Zelanda",
          "tipo": "trabajo",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Oferta Laboral \nexperiencia laboral y estudios demostrables \nsuficiencia de ingles."
          },
          {
          "pasaporte": "Peru",
          "origen": "Todos",
          "destino": "Países Bajos",
          "tipo": "trabajo",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Contrato de trabajo \nantecedentes penales \nseguro de viaje \nexámenes médicos. Otros visados disponinles. Contacte a un asesor."
          },
          {
          "pasaporte": "Peru",
          "origen": "Todos",
          "destino": "Polonia",
          "tipo": "trabajo",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "El empleador debe solicitar permiso de trabajo \nseguro médico. Otras visas disponibles. Contacte a un asesor."
          },
          {
          "pasaporte": "Peru",
          "origen": "Todos",
          "destino": "Portugal",
          "tipo": "trabajo",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Contrato de trabajo. Visado para buscar trabajo según disponibilidad de cupo anual."
          },
          {
          "pasaporte": "Peru",
          "origen": "Todos",
          "destino": "Reino Unido",
          "tipo": "trabajo",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Contar con empleador auotrizado. Prueba de hospedaje \nprueba de tuberculosis. Otras visas disponibles. Contacte a un asesor."
          },
          {
          "pasaporte": "Peru",
          "origen": "Todos",
          "destino": "República Checa",
          "tipo": "trabajo",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "El empleador debe solicitar permiso de trabajo \nseguro médico \nantecedentes penales \ncopias certificadas de calificaciones académicas y laborales"
          },
          {
          "pasaporte": "Peru",
          "origen": "Todos",
          "destino": "Rumanía",
          "tipo": "trabajo",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Contrato de trabajo \ncertificado médico \nantecedentes penales"
          },
          {
          "pasaporte": "Peru",
          "origen": "Todos",
          "destino": "Serbia",
          "tipo": "trabajo",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Ell empleador debe solicitar al Servicio Nacional de Empleo ( National Employment Service  \nNES) que realice una prueba de mercado laboral para garantizar que no haya ciudadanos serbios elegibles disponibles para ocupar el puesto. Permiso de trabajo"
          },
          {
          "pasaporte": "Peru",
          "origen": "Todos",
          "destino": "Suecia",
          "tipo": "trabajo",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Contrato de trabajo \npermiso de trabajo. Disponible visado para buscar trabajo a personas altamente calificadas"
          },
          {
          "pasaporte": "Peru",
          "origen": "Todos",
          "destino": "Suiza",
          "tipo": "trabajo",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Contrato de trabajo para profesionales cualificados según disponibilidad de cupo anual."
          },
          {
          "pasaporte": "Peru",
          "origen": "Todos",
          "destino": "Túnez",
          "tipo": "trabajo",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Carta de invitación del empleador \ncomprobante de alojamiento"
          },
          {
          "pasaporte": "Peru",
          "origen": "Todos",
          "destino": "Albania",
          "tipo": "trabajo",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Contrato de empleo \nextracto del Centro Nacional de Registro que confirma el registro como empleador."
          },
          {
          "pasaporte": "Peru",
          "origen": "Todos",
          "destino": "Australia",
          "tipo": "trabajo",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Visa por habilidades y sistema de puntuación. Contacte a un asesor."
          },
          {
          "pasaporte": "Peru",
          "origen": "Todos",
          "destino": "Emiratos Árabes Unidos",
          "tipo": "trabajo",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Contrato de trabajo \nseguro médico. Disponible visa nómada y trabajo freelance"
          },
          {
          "pasaporte": "Peru",
          "origen": "Todos",
          "destino": "India",
          "tipo": "trabajo",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Contrato de trabajo \npara profesionales calificados \ncopias de diplomas de estudios previos y certificados laborales previos."
          },
          {
          "pasaporte": "Peru",
          "origen": "Todos",
          "destino": "Libia",
          "tipo": "trabajo",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Contrato de trabajo"
          },
          {
          "pasaporte": "Peru",
          "origen": "Todos",
          "destino": "Taiwán",
          "tipo": "trabajo",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Contrato de trabajo \ncertificado de saliud"
          },
          {
          "pasaporte": "Peru",
          "origen": "Todos",
          "destino": "Vietnam",
          "tipo": "trabajo",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Empleador debe solicitar permiso de trabajo"
          },
          {
          "pasaporte": "Peru",
          "origen": "Todos",
          "destino": "Corea del Sur",
          "tipo": "trabajo",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Copia del contrato de empleo \ncertificados educativos y profesionales \nantecedentes penales \ncarta de recomendación"
          },
          {
          "pasaporte": "Peru",
          "origen": "Todos",
          "destino": "Argentina",
          "tipo": "trabajo",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Contrato de trabajo \ncertificado médico \nantecedentes penales"
          },
          {
          "pasaporte": "Peru",
          "origen": "Todos",
          "destino": "Bolivia",
          "tipo": "trabajo",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Contrato de trabajo \ncertificado médico \nantecedentes penales"
          },
          {
          "pasaporte": "Peru",
          "origen": "Todos",
          "destino": "Brasil",
          "tipo": "trabajo",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Contrato de trabajo \ncertificado médico \nantecedentes penales"
          },
          {
          "pasaporte": "Peru",
          "origen": "Todos",
          "destino": "Chile",
          "tipo": "trabajo",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Contrato de trabajo \ncertificado médico \nantecedentes penales"
          },
          {
          "pasaporte": "Peru",
          "origen": "Todos",
          "destino": "China",
          "tipo": "trabajo",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Certificado de salud \npermiso de trabajo que se otorga a criterio según aporte al país. Prueba de alojamiento"
          },
          {
          "pasaporte": "Peru",
          "origen": "Todos",
          "destino": "Colombia",
          "tipo": "trabajo",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Contrato de trabajo \ncertificado médico \nantecedentes penales"
          },
          {
          "pasaporte": "Peru",
          "origen": "Todos",
          "destino": "Curacao",
          "tipo": "trabajo",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Certificado médico \ncertificado de antecedentes policiales \ncarta de su empleador indicando que le han ofrecido un trabajo \ncopia de su contrato de trabajo \ncopia de CV \ncopia de sus expedientes académicos."
          },
          {
          "pasaporte": "Peru",
          "origen": "Todos",
          "destino": "El Salvador",
          "tipo": "trabajo",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Contrato de trabajo. Los extranjeros podrán solicitar residencia temporal con autorización para trabajar en la Dirección General de Migración y Extranjería (DGME) con la opinión del Ministerio de Trabajo y Previsión Social (MTPS) según el caso."
          },
          {
          "pasaporte": "Peru",
          "origen": "Todos",
          "destino": "Federación Rusa",
          "tipo": "trabajo",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Empleador debe tramitar permiso de trabajo.Copias certificadas de calificaciones \ncertificado médico."
          },
          {
          "pasaporte": "Peru",
          "origen": "Todos",
          "destino": "Honduras",
          "tipo": "trabajo",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Contrato de trabajo \ncertificado médico \nantecedentes penales"
          },
          {
          "pasaporte": "Peru",
          "origen": "Todos",
          "destino": "Hong Kong",
          "tipo": "trabajo",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Contrato de trabajo \ntítulo profesional \nexperiencia profesional"
          },
          {
          "pasaporte": "Peru",
          "origen": "Todos",
          "destino": "Israel",
          "tipo": "trabajo",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Contrato de trabajo \ncertificado médico \nantecedentes penales"
          },
          {
          "pasaporte": "Peru",
          "origen": "Todos",
          "destino": "Jamaica",
          "tipo": "trabajo",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Empleador solicita permiso de trabajo \ncertificado de nacimiento \nantecedentes penales \nprueba de estado civil \nsolvencia económica."
          },
          {
          "pasaporte": "Peru",
          "origen": "Todos",
          "destino": "Nicaragua",
          "tipo": "trabajo",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Contrato de trabajo \ncertificado médico \nantecedentes penales"
          },
          {
          "pasaporte": "Peru",
          "origen": "Todos",
          "destino": "Panamá",
          "tipo": "trabajo",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Contrato de trabajo \ncertificado médico \nantecedentes penales \ncomprobante de alojamiento"
          },
          {
          "pasaporte": "Peru",
          "origen": "Todos",
          "destino": "Paraguay",
          "tipo": "trabajo",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Contrato de trabajo \ncertificado médico \nceertificado de estado civil \ncertificado de nacimiento \nantecedentes penales"
          },
          {
          "pasaporte": "Peru",
          "origen": "Todos",
          "destino": "Perú",
          "tipo": "trabajo",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Contrato de trabajo \ncertificado médico \nantecedentes penales"
          },
          {
          "pasaporte": "Peru",
          "origen": "Todos",
          "destino": "República Dominicana",
          "tipo": "trabajo",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Contrato de trabajo \ncertificado médico \nantecedentes penales"
          },
          {
          "pasaporte": "Peru",
          "origen": "Todos",
          "destino": "Singapur",
          "tipo": "trabajo",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "El empleador debe solicitar el visado"
          },
          {
          "pasaporte": "Peru",
          "origen": "Todos",
          "destino": "Sudáfrica",
          "tipo": "trabajo",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Oferta de trabajo \nexámenes médicos \nceertificado de vacunación \nantecedentes penales."
          },
          {
          "pasaporte": "Peru",
          "origen": "Todos",
          "destino": "Tailandia",
          "tipo": "trabajo",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Oferta de trabajo. Visas disponibles según el trabajo a desempeñar. Contacte a un asesor"
          },
          {
          "pasaporte": "Peru",
          "origen": "Todos",
          "destino": "Turquía",
          "tipo": "trabajo",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Oferta de trabajo o contrato de trabajo \nantecedentes penales \nseguro médico \nevidencia de alojamiento \nsolvencia económica"
          },
          {
          "pasaporte": "Peru",
          "origen": "Todos",
          "destino": "Uruguay",
          "tipo": "trabajo",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Contrato de trabajo \ncertificado médico \nantecedentes penales"
          },
          {
          "pasaporte": "Peru",
          "origen": "Todos",
          "destino": "Venezuela",
          "tipo": "trabajo",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Contrato de trabajo \ncertificado médico \nantecedentes penales"
          },
          {
          "pasaporte": "Pasaporte",
          "origen": "Origen",
          "destino": "Destino",
          "tipo": "trabajo",
          "visado": "¿Requiere VISADO?",
          "porcentaje": "PORCENTAJE DE APROBACIÓN",
          "requisitos": "REQUISITOS"
          },
          {
          "pasaporte": "Venezuela",
          "origen": "Todos",
          "destino": "Alemania",
          "tipo": "trabajo",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Contrato de trabajo \nsuficiencia de alemán \ntítulos de estudios previso. Tambien disponibles: visado para niñera y visado para busqueda de trabajo. Comuníquese con un asesor."
          },
          {
          "pasaporte": "Venezuela",
          "origen": "Todos",
          "destino": "Andorra",
          "tipo": "trabajo",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Contrato de trabajo \n2 años de experiencia laboral \ncartas de recomendación \nprueba de alojamiento \nantecedentes penales \ncertificado médico."
          },
          {
          "pasaporte": "Venezuela",
          "origen": "Todos",
          "destino": "Arabia Saudí",
          "tipo": "trabajo",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Contrato de trabajo \nantecedentes penales \ncopia certificada de título de estudio \ncertificado médico"
          },
          {
          "pasaporte": "Venezuela",
          "origen": "Todos",
          "destino": "Argelia",
          "tipo": "trabajo",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Contrato de trabajo \ncertificado médico \ncertificado de estudios previos."
          },
          {
          "pasaporte": "Venezuela",
          "origen": "Todos",
          "destino": "Austria",
          "tipo": "trabajo",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Visado de trabajo por sistema de calificación por puntos. Certiificado de nacimiento \nprueba de alojamiento \nseguro médico \nsolvencia económica \ntítulo universitario o superior \ncertificados de trabajo \nhabilidades lingüisticas."
          },
          {
          "pasaporte": "Venezuela",
          "origen": "Todos",
          "destino": "Bélgica",
          "tipo": "trabajo",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Contrato de trabajo \npermiso de trabajo \nantecedentes penales \ncertificado médico."
          },
          {
          "pasaporte": "Venezuela",
          "origen": "Todos",
          "destino": "Bulgaria",
          "tipo": "trabajo",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Contrato de trabajo \nantecedentes penales \nseguro médico \ncomprobante de alojamiento."
          },
          {
          "pasaporte": "Venezuela",
          "origen": "Todos",
          "destino": "Canadá",
          "tipo": "trabajo",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Oferta de trabajo \ncrear usuario y solicitud en IRCC \nexámenes médicos \nantecedentes penales"
          },
          {
          "pasaporte": "Venezuela",
          "origen": "Todos",
          "destino": "Costa Rica",
          "tipo": "trabajo",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Visaado gestionado directamente por el empleador. Comuníquese con un asesor."
          },
          {
          "pasaporte": "Venezuela",
          "origen": "Todos",
          "destino": "Croacia",
          "tipo": "trabajo",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Contrato de trabajo \nprueba de alojamiento \nseguro médico \nestudios superiores \nexperiencia laboral mayor a 1 año."
          },
          {
          "pasaporte": "Venezuela",
          "origen": "Todos",
          "destino": "Cuba",
          "tipo": "trabajo",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Autorización de trabajo solicitada por el empleador \nseguro médico \nantecedentes penales \nsolvencia económica."
          },
          {
          "pasaporte": "Venezuela",
          "origen": "Todos",
          "destino": "Dinamarca",
          "tipo": "trabajo",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Contrato de trabajo. Posibilidad de tramitar permiso de trabajo por sistema de puntos (tarjeta verde)"
          },
          {
          "pasaporte": "Venezuela",
          "origen": "Todos",
          "destino": "Eslovaquia",
          "tipo": "trabajo",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Contrato de trabajo \nseguro médico \ncertificado de estudios y experiencia previa."
          },
          {
          "pasaporte": "Venezuela",
          "origen": "Todos",
          "destino": "Eslovenia",
          "tipo": "trabajo",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Contrato de trabajo \nseguro médico \ncertificado de estudios y experiencia previa."
          },
          {
          "pasaporte": "Venezuela",
          "origen": "Todos",
          "destino": "España",
          "tipo": "trabajo",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Contrato de trabajo \nseguro médico \nantecedentes penales \ncertificado médico"
          },
          {
          "pasaporte": "Venezuela",
          "origen": "Todos",
          "destino": "Estados Unidos de América",
          "tipo": "trabajo",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Existen cinco categorías de visa de trabajo para Estados Unidos destinadas a trabajadores permanentes \ny los requisitos para calificar varían de acuerdo con el tipo de visa: Cada categoría está destinada a diferentes profesiones o habilidades. Algunas categorías requieren que tenga una oferta de trabajo de un empleador en EE. UU. (patrocinador) \nantes de presentar su solicitud de visa. Para algunas categorías es necesario que el empleador (patrocinador) presente una solicitud al Servicio de Ciudadanía e Inmigración de Estados Unidos (USCIS \nsigla en inglés) y obtenga una certificación laboral del Departamento del Trabajo (DOL \nsigla en inglés)"
          },
          {
          "pasaporte": "Venezuela",
          "origen": "Todos",
          "destino": "Estonia",
          "tipo": "trabajo",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Contrato de trabajo \nseguro médico \ncertificado de estudios y experiencia previa."
          },
          {
          "pasaporte": "Venezuela",
          "origen": "Todos",
          "destino": "Finlandia",
          "tipo": "trabajo",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Contrato de trabajo \nantecedentes penales. Disponible tarjeta azul para trabajadores cualificados."
          },
          {
          "pasaporte": "Venezuela",
          "origen": "Todos",
          "destino": "Francia",
          "tipo": "trabajo",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Contrato de trabajo \nsolicitud de visado"
          },
          {
          "pasaporte": "Venezuela",
          "origen": "Todos",
          "destino": "Grecia",
          "tipo": "trabajo",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Declaración de empleador \nseguro médico \nsolvencia económica."
          },
          {
          "pasaporte": "Venezuela",
          "origen": "Todos",
          "destino": "Guatemala",
          "tipo": "trabajo",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Permiso de trabajo \ncertificado de nacimiento. Disponible plataforma digital para solicitud en línea."
          },
          {
          "pasaporte": "Venezuela",
          "origen": "Todos",
          "destino": "Hungría",
          "tipo": "trabajo",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Contrato de trabajo"
          },
          {
          "pasaporte": "Venezuela",
          "origen": "Todos",
          "destino": "Irlanda",
          "tipo": "trabajo",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Contrato de trabajo. Disponible visado de trabajo cualificado"
          },
          {
          "pasaporte": "Venezuela",
          "origen": "Todos",
          "destino": "Islandia",
          "tipo": "trabajo",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Contrato de trabajo. Disponible visado para búsqueda de empleo. Contactar a un asesor"
          },
          {
          "pasaporte": "Venezuela",
          "origen": "Todos",
          "destino": "Italia",
          "tipo": "trabajo",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Autorización para el trabajo (en original y fotocopia) expedida por la \"Direzione Provinciale del Lavoro\" y aprobada por la \"Questura\" competente o el “Nulla Osta” expedido por el “Sportello Unico"
          },
          {
          "pasaporte": "Venezuela",
          "origen": "Todos",
          "destino": "Japón",
          "tipo": "trabajo",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Oferta de trabajo. Visas disponibles para trabajo cualificado y habilidades especificadas. Contactar a un asesor"
          },
          {
          "pasaporte": "Venezuela",
          "origen": "Todos",
          "destino": "Letonia",
          "tipo": "trabajo",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Carta del empleador \nconstancia de alojamiento \npermiso de residencia."
          },
          {
          "pasaporte": "Venezuela",
          "origen": "Todos",
          "destino": "Líbano",
          "tipo": "trabajo",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Permiso de trabajo \nseguro de viaje \nacta de nacimiento"
          },
          {
          "pasaporte": "Venezuela",
          "origen": "Todos",
          "destino": "Lituania",
          "tipo": "trabajo",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Contrato de trabajo \nseguro médico \ncertificado de estudios y experiencia previa."
          },
          {
          "pasaporte": "Venezuela",
          "origen": "Todos",
          "destino": "Luxemburgo",
          "tipo": "trabajo",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Contrato de trabajo \nseguro médico \ncertificado de estudios y experiencia previa."
          },
          {
          "pasaporte": "Venezuela",
          "origen": "Todos",
          "destino": "Malta",
          "tipo": "trabajo",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Contrato de trabajo. Se solicita en Malta"
          },
          {
          "pasaporte": "Venezuela",
          "origen": "Todos",
          "destino": "Marruecos",
          "tipo": "trabajo",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Contrato de trabajo"
          },
          {
          "pasaporte": "Venezuela",
          "origen": "Todos",
          "destino": "México",
          "tipo": "trabajo",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Oferta de empleo \nNotificación del Instituto Nacional de Migración con Número Único de Trámite (NUT)"
          },
          {
          "pasaporte": "Venezuela",
          "origen": "Todos",
          "destino": "Montenegro",
          "tipo": "trabajo",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Contrato de trabajo \ncertificado médico \nantecedentes penales"
          },
          {
          "pasaporte": "Venezuela",
          "origen": "Todos",
          "destino": "Noruega",
          "tipo": "trabajo",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Contrato de trabajo \nprueba de hospedaje \ncertificado de trabajos previos \ncertificados de estudios previos"
          },
          {
          "pasaporte": "Venezuela",
          "origen": "Todos",
          "destino": "Nueva Zelanda",
          "tipo": "trabajo",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Oferta Laboral \nexperiencia laboral y estudios demostrables \nsuficiencia de ingles."
          },
          {
          "pasaporte": "Venezuela",
          "origen": "Todos",
          "destino": "Países Bajos",
          "tipo": "trabajo",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Contrato de trabajo \nantecedentes penales \nseguro de viaje \nexámenes médicos. Otros visados disponinles. Contacte a un asesor."
          },
          {
          "pasaporte": "Venezuela",
          "origen": "Todos",
          "destino": "Polonia",
          "tipo": "trabajo",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "El empleador debe solicitar permiso de trabajo \nseguro médico. Otras visas disponibles. Contacte a un asesor."
          },
          {
          "pasaporte": "Venezuela",
          "origen": "Todos",
          "destino": "Portugal",
          "tipo": "trabajo",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Contrato de trabajo. Visado para buscar trabajo según disponibilidad de cupo anual."
          },
          {
          "pasaporte": "Venezuela",
          "origen": "Todos",
          "destino": "Reino Unido",
          "tipo": "trabajo",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Contar con empleador auotrizado. Prueba de hospedaje \nprueba de tuberculosis. Otras visas disponibles. Contacte a un asesor."
          },
          {
          "pasaporte": "Venezuela",
          "origen": "Todos",
          "destino": "República Checa",
          "tipo": "trabajo",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "El empleador debe solicitar permiso de trabajo \nseguro médico \nantecedentes penales \ncopias certificadas de calificaciones académicas y laborales"
          },
          {
          "pasaporte": "Venezuela",
          "origen": "Todos",
          "destino": "Rumanía",
          "tipo": "trabajo",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Contrato de trabajo \ncertificado médico \nantecedentes penales"
          },
          {
          "pasaporte": "Venezuela",
          "origen": "Todos",
          "destino": "Serbia",
          "tipo": "trabajo",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Ell empleador debe solicitar al Servicio Nacional de Empleo ( National Employment Service  \nNES) que realice una prueba de mercado laboral para garantizar que no haya ciudadanos serbios elegibles disponibles para ocupar el puesto. Permiso de trabajo"
          },
          {
          "pasaporte": "Venezuela",
          "origen": "Todos",
          "destino": "Suecia",
          "tipo": "trabajo",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Contrato de trabajo \npermiso de trabajo. Disponible visado para buscar trabajo a personas altamente calificadas"
          },
          {
          "pasaporte": "Venezuela",
          "origen": "Todos",
          "destino": "Suiza",
          "tipo": "trabajo",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Contrato de trabajo para profesionales cualificados según disponibilidad de cupo anual."
          },
          {
          "pasaporte": "Venezuela",
          "origen": "Todos",
          "destino": "Túnez",
          "tipo": "trabajo",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Carta de invitación del empleador \ncomprobante de alojamiento"
          },
          {
          "pasaporte": "Venezuela",
          "origen": "Todos",
          "destino": "Albania",
          "tipo": "trabajo",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Contrato de empleo \nextracto del Centro Nacional de Registro que confirma el registro como empleador."
          },
          {
          "pasaporte": "Venezuela",
          "origen": "Todos",
          "destino": "Australia",
          "tipo": "trabajo",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Visa por habilidades y sistema de puntuación. Contacte a un asesor."
          },
          {
          "pasaporte": "Venezuela",
          "origen": "Todos",
          "destino": "Emiratos Árabes Unidos",
          "tipo": "trabajo",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Contrato de trabajo \nseguro médico. Disponible visa nómada y trabajo freelance"
          },
          {
          "pasaporte": "Venezuela",
          "origen": "Todos",
          "destino": "India",
          "tipo": "trabajo",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Contrato de trabajo \npara profesionales calificados \ncopias de diplomas de estudios previos y certificados laborales previos."
          },
          {
          "pasaporte": "Venezuela",
          "origen": "Todos",
          "destino": "Libia",
          "tipo": "trabajo",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Contrato de trabajo"
          },
          {
          "pasaporte": "Pasaporte",
          "origen": "Origen",
          "destino": "Destino",
          "tipo": "trabajo",
          "visado": "¿Requiere VISADO?",
          "porcentaje": "PORCENTAJE DE APROBACIÓN",
          "requisitos": "REQUISITOS"
          },
          {
          "pasaporte": "Salvador",
          "origen": "Todos",
          "destino": "Alemania",
          "tipo": "trabajo",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Contrato de trabajo \nsuficiencia de alemán \ntítulos de estudios previso. Tambien disponibles: visado para niñera y visado para busqueda de trabajo. Comuníquese con un asesor."
          },
          {
          "pasaporte": "Salvador",
          "origen": "Todos",
          "destino": "Andorra",
          "tipo": "trabajo",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Contrato de trabajo \n2 años de experiencia laboral \ncartas de recomendación \nprueba de alojamiento \nantecedentes penales \ncertificado médico."
          },
          {
          "pasaporte": "Salvador",
          "origen": "Todos",
          "destino": "Arabia Saudí",
          "tipo": "trabajo",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Contrato de trabajo \nantecedentes penales \ncopia certificada de título de estudio \ncertificado médico"
          },
          {
          "pasaporte": "Salvador",
          "origen": "Todos",
          "destino": "Argelia",
          "tipo": "trabajo",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Contrato de trabajo \ncertificado médico \ncertificado de estudios previos."
          },
          {
          "pasaporte": "Salvador",
          "origen": "Todos",
          "destino": "Austria",
          "tipo": "trabajo",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Visado de trabajo por sistema de calificación por puntos. Certiificado de nacimiento \nprueba de alojamiento \nseguro médico \nsolvencia económica \ntítulo universitario o superior \ncertificados de trabajo \nhabilidades lingüisticas."
          },
          {
          "pasaporte": "Salvador",
          "origen": "Todos",
          "destino": "Bélgica",
          "tipo": "trabajo",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Contrato de trabajo \npermiso de trabajo \nantecedentes penales \ncertificado médico."
          },
          {
          "pasaporte": "Salvador",
          "origen": "Todos",
          "destino": "Bulgaria",
          "tipo": "trabajo",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Contrato de trabajo \nantecedentes penales \nseguro médico \ncomprobante de alojamiento."
          },
          {
          "pasaporte": "Salvador",
          "origen": "Todos",
          "destino": "Canadá",
          "tipo": "trabajo",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Oferta de trabajo \ncrear usuario y solicitud en IRCC \nexámenes médicos \nantecedentes penales"
          },
          {
          "pasaporte": "Salvador",
          "origen": "Todos",
          "destino": "Costa Rica",
          "tipo": "trabajo",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Visaado gestionado directamente por el empleador. Comuníquese con un asesor."
          },
          {
          "pasaporte": "Salvador",
          "origen": "Todos",
          "destino": "Croacia",
          "tipo": "trabajo",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Contrato de trabajo \nprueba de alojamiento \nseguro médico \nestudios superiores \nexperiencia laboral mayor a 1 año."
          },
          {
          "pasaporte": "Salvador",
          "origen": "Todos",
          "destino": "Cuba",
          "tipo": "trabajo",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Autorización de trabajo solicitada por el empleador \nseguro médico \nantecedentes penales \nsolvencia económica."
          },
          {
          "pasaporte": "Salvador",
          "origen": "Todos",
          "destino": "Dinamarca",
          "tipo": "trabajo",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Contrato de trabajo. Posibilidad de tramitar permiso de trabajo por sistema de puntos (tarjeta verde)"
          },
          {
          "pasaporte": "Salvador",
          "origen": "Todos",
          "destino": "Eslovaquia",
          "tipo": "trabajo",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Contrato de trabajo \nseguro médico \ncertificado de estudios y experiencia previa."
          },
          {
          "pasaporte": "Salvador",
          "origen": "Todos",
          "destino": "Eslovenia",
          "tipo": "trabajo",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Contrato de trabajo \nseguro médico \ncertificado de estudios y experiencia previa."
          },
          {
          "pasaporte": "Salvador",
          "origen": "Todos",
          "destino": "España",
          "tipo": "trabajo",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Contrato de trabajo \nseguro médico \nantecedentes penales \ncertificado médico"
          },
          {
          "pasaporte": "Salvador",
          "origen": "Todos",
          "destino": "Estados Unidos de América",
          "tipo": "trabajo",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Existen cinco categorías de visa de trabajo para Estados Unidos destinadas a trabajadores permanentes \ny los requisitos para calificar varían de acuerdo con el tipo de visa: Cada categoría está destinada a diferentes profesiones o habilidades. Algunas categorías requieren que tenga una oferta de trabajo de un empleador en EE. UU. (patrocinador) \nantes de presentar su solicitud de visa. Para algunas categorías es necesario que el empleador (patrocinador) presente una solicitud al Servicio de Ciudadanía e Inmigración de Estados Unidos (USCIS \nsigla en inglés) y obtenga una certificación laboral del Departamento del Trabajo (DOL \nsigla en inglés)"
          },
          {
          "pasaporte": "Salvador",
          "origen": "Todos",
          "destino": "Estonia",
          "tipo": "trabajo",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Contrato de trabajo \nseguro médico \ncertificado de estudios y experiencia previa."
          },
          {
          "pasaporte": "Salvador",
          "origen": "Todos",
          "destino": "Finlandia",
          "tipo": "trabajo",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Contrato de trabajo \nantecedentes penales. Disponible tarjeta azul para trabajadores cualificados."
          },
          {
          "pasaporte": "Salvador",
          "origen": "Todos",
          "destino": "Francia",
          "tipo": "trabajo",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Contrato de trabajo \nsolicitud de visado"
          },
          {
          "pasaporte": "Salvador",
          "origen": "Todos",
          "destino": "Grecia",
          "tipo": "trabajo",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Declaración de empleador \nseguro médico \nsolvencia económica."
          },
          {
          "pasaporte": "Salvador",
          "origen": "Todos",
          "destino": "Guatemala",
          "tipo": "trabajo",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Permiso de trabajo \ncertificado de nacimiento. Disponible plataforma digital para solicitud en línea."
          },
          {
          "pasaporte": "Salvador",
          "origen": "Todos",
          "destino": "Hungría",
          "tipo": "trabajo",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Contrato de trabajo"
          },
          {
          "pasaporte": "Salvador",
          "origen": "Todos",
          "destino": "Irlanda",
          "tipo": "trabajo",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Contrato de trabajo. Disponible visado de trabajo cualificado"
          },
          {
          "pasaporte": "Salvador",
          "origen": "Todos",
          "destino": "Islandia",
          "tipo": "trabajo",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Contrato de trabajo. Disponible visado para búsqueda de empleo. Contactar a un asesor"
          },
          {
          "pasaporte": "Salvador",
          "origen": "Todos",
          "destino": "Italia",
          "tipo": "trabajo",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Autorización para el trabajo (en original y fotocopia) expedida por la \"Direzione Provinciale del Lavoro\" y aprobada por la \"Questura\" competente o el “Nulla Osta” expedido por el “Sportello Unico"
          },
          {
          "pasaporte": "Salvador",
          "origen": "Todos",
          "destino": "Japón",
          "tipo": "trabajo",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Oferta de trabajo. Visas disponibles para trabajo cualificado y habilidades especificadas. Contactar a un asesor"
          },
          {
          "pasaporte": "Salvador",
          "origen": "Todos",
          "destino": "Letonia",
          "tipo": "trabajo",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Carta del empleador \nconstancia de alojamiento \npermiso de residencia."
          },
          {
          "pasaporte": "Salvador",
          "origen": "Todos",
          "destino": "Líbano",
          "tipo": "trabajo",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Permiso de trabajo \nseguro de viaje \nacta de nacimiento"
          },
          {
          "pasaporte": "Salvador",
          "origen": "Todos",
          "destino": "Lituania",
          "tipo": "trabajo",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Contrato de trabajo \nseguro médico \ncertificado de estudios y experiencia previa."
          },
          {
          "pasaporte": "Salvador",
          "origen": "Todos",
          "destino": "Luxemburgo",
          "tipo": "trabajo",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Contrato de trabajo \nseguro médico \ncertificado de estudios y experiencia previa."
          },
          {
          "pasaporte": "Salvador",
          "origen": "Todos",
          "destino": "Malta",
          "tipo": "trabajo",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Contrato de trabajo. Se solicita en Malta"
          },
          {
          "pasaporte": "Salvador",
          "origen": "Todos",
          "destino": "Marruecos",
          "tipo": "trabajo",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Contrato de trabajo"
          },
          {
          "pasaporte": "Salvador",
          "origen": "Todos",
          "destino": "México",
          "tipo": "trabajo",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Oferta de empleo \nNotificación del Instituto Nacional de Migración con Número Único de Trámite (NUT)"
          },
          {
          "pasaporte": "Salvador",
          "origen": "Todos",
          "destino": "Montenegro",
          "tipo": "trabajo",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Contrato de trabajo \ncertificado médico \nantecedentes penales"
          },
          {
          "pasaporte": "Salvador",
          "origen": "Todos",
          "destino": "Noruega",
          "tipo": "trabajo",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Contrato de trabajo \nprueba de hospedaje \ncertificado de trabajos previos \ncertificados de estudios previos"
          },
          {
          "pasaporte": "Salvador",
          "origen": "Todos",
          "destino": "Nueva Zelanda",
          "tipo": "trabajo",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Oferta Laboral \nexperiencia laboral y estudios demostrables \nsuficiencia de ingles."
          },
          {
          "pasaporte": "Salvador",
          "origen": "Todos",
          "destino": "Países Bajos",
          "tipo": "trabajo",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Contrato de trabajo \nantecedentes penales \nseguro de viaje \nexámenes médicos. Otros visados disponinles. Contacte a un asesor."
          },
          {
          "pasaporte": "Salvador",
          "origen": "Todos",
          "destino": "Polonia",
          "tipo": "trabajo",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "El empleador debe solicitar permiso de trabajo \nseguro médico. Otras visas disponibles. Contacte a un asesor."
          },
          {
          "pasaporte": "Salvador",
          "origen": "Todos",
          "destino": "Portugal",
          "tipo": "trabajo",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Contrato de trabajo. Visado para buscar trabajo según disponibilidad de cupo anual."
          },
          {
          "pasaporte": "Salvador",
          "origen": "Todos",
          "destino": "Reino Unido",
          "tipo": "trabajo",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Contar con empleador auotrizado. Prueba de hospedaje \nprueba de tuberculosis. Otras visas disponibles. Contacte a un asesor."
          },
          {
          "pasaporte": "Salvador",
          "origen": "Todos",
          "destino": "República Checa",
          "tipo": "trabajo",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "El empleador debe solicitar permiso de trabajo \nseguro médico \nantecedentes penales \ncopias certificadas de calificaciones académicas y laborales"
          },
          {
          "pasaporte": "Salvador",
          "origen": "Todos",
          "destino": "Rumanía",
          "tipo": "trabajo",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Contrato de trabajo \ncertificado médico \nantecedentes penales"
          },
          {
          "pasaporte": "Salvador",
          "origen": "Todos",
          "destino": "Serbia",
          "tipo": "trabajo",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Ell empleador debe solicitar al Servicio Nacional de Empleo ( National Employment Service  \nNES) que realice una prueba de mercado laboral para garantizar que no haya ciudadanos serbios elegibles disponibles para ocupar el puesto. Permiso de trabajo"
          },
          {
          "pasaporte": "Salvador",
          "origen": "Todos",
          "destino": "Suecia",
          "tipo": "trabajo",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Contrato de trabajo \npermiso de trabajo. Disponible visado para buscar trabajo a personas altamente calificadas"
          },
          {
          "pasaporte": "Salvador",
          "origen": "Todos",
          "destino": "Suiza",
          "tipo": "trabajo",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Contrato de trabajo para profesionales cualificados según disponibilidad de cupo anual."
          },
          {
          "pasaporte": "Salvador",
          "origen": "Todos",
          "destino": "Túnez",
          "tipo": "trabajo",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Carta de invitación del empleador \ncomprobante de alojamiento"
          },
          {
          "pasaporte": "Salvador",
          "origen": "Todos",
          "destino": "Albania",
          "tipo": "trabajo",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Contrato de empleo \nextracto del Centro Nacional de Registro que confirma el registro como empleador."
          },
          {
          "pasaporte": "Salvador",
          "origen": "Todos",
          "destino": "Australia",
          "tipo": "trabajo",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Visa por habilidades y sistema de puntuación. Contacte a un asesor."
          },
          {
          "pasaporte": "Salvador",
          "origen": "Todos",
          "destino": "Emiratos Árabes Unidos",
          "tipo": "trabajo",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Contrato de trabajo \nseguro médico. Disponible visa nómada y trabajo freelance"
          },
          {
          "pasaporte": "Salvador",
          "origen": "Todos",
          "destino": "India",
          "tipo": "trabajo",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Contrato de trabajo \npara profesionales calificados \ncopias de diplomas de estudios previos y certificados laborales previos."
          },
          {
          "pasaporte": "Salvador",
          "origen": "Todos",
          "destino": "Libia",
          "tipo": "trabajo",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Contrato de trabajo"
          },
          {
          "pasaporte": "Salvador",
          "origen": "Todos",
          "destino": "Taiwán",
          "tipo": "trabajo",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Contrato de trabajo \ncertificado de saliud"
          },
          {
          "pasaporte": "Salvador",
          "origen": "Todos",
          "destino": "Vietnam",
          "tipo": "trabajo",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Empleador debe solicitar permiso de trabajo"
          },
          {
          "pasaporte": "Salvador",
          "origen": "Todos",
          "destino": "Corea del Sur",
          "tipo": "trabajo",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Copia del contrato de empleo \ncertificados educativos y profesionales \nantecedentes penales \ncarta de recomendación"
          },
          {
          "pasaporte": "Salvador",
          "origen": "Todos",
          "destino": "Argentina",
          "tipo": "trabajo",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Contrato de trabajo \ncertificado médico \nantecedentes penales"
          },
          {
          "pasaporte": "Salvador",
          "origen": "Todos",
          "destino": "Bolivia",
          "tipo": "trabajo",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Contrato de trabajo \ncertificado médico \nantecedentes penales"
          },
          {
          "pasaporte": "Salvador",
          "origen": "Todos",
          "destino": "Brasil",
          "tipo": "trabajo",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Contrato de trabajo \ncertificado médico \nantecedentes penales"
          },
          {
          "pasaporte": "Salvador",
          "origen": "Todos",
          "destino": "Chile",
          "tipo": "trabajo",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Contrato de trabajo \ncertificado médico \nantecedentes penales"
          },
          {
          "pasaporte": "Salvador",
          "origen": "Todos",
          "destino": "China",
          "tipo": "trabajo",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Certificado de salud \npermiso de trabajo que se otorga a criterio según aporte al país. Prueba de alojamiento"
          },
          {
          "pasaporte": "Salvador",
          "origen": "Todos",
          "destino": "Colombia",
          "tipo": "trabajo",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Contrato de trabajo \ncertificado médico \nantecedentes penales"
          },
          {
          "pasaporte": "Salvador",
          "origen": "Todos",
          "destino": "Curacao",
          "tipo": "trabajo",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Certificado médico \ncertificado de antecedentes policiales \ncarta de su empleador indicando que le han ofrecido un trabajo \ncopia de su contrato de trabajo \ncopia de CV \ncopia de sus expedientes académicos."
          },
          {
          "pasaporte": "Salvador",
          "origen": "Todos",
          "destino": "El Salvador",
          "tipo": "trabajo",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Contrato de trabajo. Los extranjeros podrán solicitar residencia temporal con autorización para trabajar en la Dirección General de Migración y Extranjería (DGME) con la opinión del Ministerio de Trabajo y Previsión Social (MTPS) según el caso."
          },
          {
          "pasaporte": "Salvador",
          "origen": "Todos",
          "destino": "Federación Rusa",
          "tipo": "trabajo",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Empleador debe tramitar permiso de trabajo.Copias certificadas de calificaciones \ncertificado médico."
          },
          {
          "pasaporte": "Salvador",
          "origen": "Todos",
          "destino": "Honduras",
          "tipo": "trabajo",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Contrato de trabajo \ncertificado médico \nantecedentes penales"
          },
          {
          "pasaporte": "Salvador",
          "origen": "Todos",
          "destino": "Hong Kong",
          "tipo": "trabajo",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Contrato de trabajo \ntítulo profesional \nexperiencia profesional"
          },
          {
          "pasaporte": "Salvador",
          "origen": "Todos",
          "destino": "Israel",
          "tipo": "trabajo",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Contrato de trabajo \ncertificado médico \nantecedentes penales"
          },
          {
          "pasaporte": "Salvador",
          "origen": "Todos",
          "destino": "Jamaica",
          "tipo": "trabajo",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Empleador solicita permiso de trabajo \ncertificado de nacimiento \nantecedentes penales \nprueba de estado civil \nsolvencia económica."
          },
          {
          "pasaporte": "Salvador",
          "origen": "Todos",
          "destino": "Nicaragua",
          "tipo": "trabajo",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Contrato de trabajo \ncertificado médico \nantecedentes penales"
          },
          {
          "pasaporte": "Salvador",
          "origen": "Todos",
          "destino": "Panamá",
          "tipo": "trabajo",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Contrato de trabajo \ncertificado médico \nantecedentes penales \ncomprobante de alojamiento"
          },
          {
          "pasaporte": "Salvador",
          "origen": "Todos",
          "destino": "Paraguay",
          "tipo": "trabajo",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Contrato de trabajo \ncertificado médico \nceertificado de estado civil \ncertificado de nacimiento \nantecedentes penales"
          },
          {
          "pasaporte": "Salvador",
          "origen": "Todos",
          "destino": "Perú",
          "tipo": "trabajo",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Contrato de trabajo \ncertificado médico \nantecedentes penales"
          },
          {
          "pasaporte": "Salvador",
          "origen": "Todos",
          "destino": "República Dominicana",
          "tipo": "trabajo",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Contrato de trabajo \ncertificado médico \nantecedentes penales"
          },
          {
          "pasaporte": "Salvador",
          "origen": "Todos",
          "destino": "Singapur",
          "tipo": "trabajo",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "El empleador debe solicitar el visado"
          },
          {
          "pasaporte": "Salvador",
          "origen": "Todos",
          "destino": "Sudáfrica",
          "tipo": "trabajo",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Oferta de trabajo \nexámenes médicos \nceertificado de vacunación \nantecedentes penales."
          },
          {
          "pasaporte": "Salvador",
          "origen": "Todos",
          "destino": "Tailandia",
          "tipo": "trabajo",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Oferta de trabajo. Visas disponibles según el trabajo a desempeñar. Contacte a un asesor"
          },
          {
          "pasaporte": "Salvador",
          "origen": "Todos",
          "destino": "Turquía",
          "tipo": "trabajo",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Oferta de trabajo o contrato de trabajo \nantecedentes penales \nseguro médico \nevidencia de alojamiento \nsolvencia económica"
          },
          {
          "pasaporte": "Salvador",
          "origen": "Todos",
          "destino": "Uruguay",
          "tipo": "trabajo",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Contrato de trabajo \ncertificado médico \nantecedentes penales"
          },
          {
          "pasaporte": "Salvador",
          "origen": "Todos",
          "destino": "Venezuela",
          "tipo": "trabajo",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Contrato de trabajo \ncertificado médico \nantecedentes penales"
          },
          {
          "pasaporte": "Mexico",
          "origen": "Todos",
          "destino": "Alemania",
          "tipo": "trabajo",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Contrato de trabajo \nsuficiencia de alemán \ntítulos de estudios previso. Tambien disponibles: visado para niñera y visado para busqueda de trabajo. Comuníquese con un asesor."
          },
          {
          "pasaporte": "Mexico",
          "origen": "Todos",
          "destino": "Andorra",
          "tipo": "trabajo",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Contrato de trabajo \n2 años de experiencia laboral \ncartas de recomendación \nprueba de alojamiento \nantecedentes penales \ncertificado médico."
          },
          {
          "pasaporte": "Mexico",
          "origen": "Todos",
          "destino": "Arabia Saudí",
          "tipo": "trabajo",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Contrato de trabajo \nantecedentes penales \ncopia certificada de título de estudio \ncertificado médico"
          },
          {
          "pasaporte": "Mexico",
          "origen": "Todos",
          "destino": "Argelia",
          "tipo": "trabajo",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Contrato de trabajo \ncertificado médico \ncertificado de estudios previos."
          },
          {
          "pasaporte": "Mexico",
          "origen": "Todos",
          "destino": "Austria",
          "tipo": "trabajo",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Visado de trabajo por sistema de calificación por puntos. Certiificado de nacimiento \nprueba de alojamiento \nseguro médico \nsolvencia económica \ntítulo universitario o superior \ncertificados de trabajo \nhabilidades lingüisticas."
          },
          {
          "pasaporte": "Mexico",
          "origen": "Todos",
          "destino": "Bélgica",
          "tipo": "trabajo",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Contrato de trabajo \npermiso de trabajo \nantecedentes penales \ncertificado médico."
          },
          {
          "pasaporte": "Mexico",
          "origen": "Todos",
          "destino": "Bulgaria",
          "tipo": "trabajo",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Contrato de trabajo \nantecedentes penales \nseguro médico \ncomprobante de alojamiento."
          },
          {
          "pasaporte": "Mexico",
          "origen": "Todos",
          "destino": "Canadá",
          "tipo": "trabajo",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Oferta de trabajo \ncrear usuario y solicitud en IRCC \nexámenes médicos \nantecedentes penales"
          },
          {
          "pasaporte": "Mexico",
          "origen": "Todos",
          "destino": "Costa Rica",
          "tipo": "trabajo",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Visaado gestionado directamente por el empleador. Comuníquese con un asesor."
          },
          {
          "pasaporte": "Mexico",
          "origen": "Todos",
          "destino": "Croacia",
          "tipo": "trabajo",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Contrato de trabajo \nprueba de alojamiento \nseguro médico \nestudios superiores \nexperiencia laboral mayor a 1 año."
          },
          {
          "pasaporte": "Mexico",
          "origen": "Todos",
          "destino": "Cuba",
          "tipo": "trabajo",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Autorización de trabajo solicitada por el empleador \nseguro médico \nantecedentes penales \nsolvencia económica."
          },
          {
          "pasaporte": "Mexico",
          "origen": "Todos",
          "destino": "Dinamarca",
          "tipo": "trabajo",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Contrato de trabajo. Posibilidad de tramitar permiso de trabajo por sistema de puntos (tarjeta verde)"
          },
          {
          "pasaporte": "Mexico",
          "origen": "Todos",
          "destino": "Eslovaquia",
          "tipo": "trabajo",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Contrato de trabajo \nseguro médico \ncertificado de estudios y experiencia previa."
          },
          {
          "pasaporte": "Mexico",
          "origen": "Todos",
          "destino": "Eslovenia",
          "tipo": "trabajo",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Contrato de trabajo \nseguro médico \ncertificado de estudios y experiencia previa."
          },
          {
          "pasaporte": "Mexico",
          "origen": "Todos",
          "destino": "España",
          "tipo": "trabajo",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Contrato de trabajo \nseguro médico \nantecedentes penales \ncertificado médico"
          },
          {
          "pasaporte": "Mexico",
          "origen": "Todos",
          "destino": "Estados Unidos de América",
          "tipo": "trabajo",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Existen cinco categorías de visa de trabajo para Estados Unidos destinadas a trabajadores permanentes \ny los requisitos para calificar varían de acuerdo con el tipo de visa: Cada categoría está destinada a diferentes profesiones o habilidades. Algunas categorías requieren que tenga una oferta de trabajo de un empleador en EE. UU. (patrocinador) \nantes de presentar su solicitud de visa. Para algunas categorías es necesario que el empleador (patrocinador) presente una solicitud al Servicio de Ciudadanía e Inmigración de Estados Unidos (USCIS \nsigla en inglés) y obtenga una certificación laboral del Departamento del Trabajo (DOL \nsigla en inglés)"
          },
          {
          "pasaporte": "Mexico",
          "origen": "Todos",
          "destino": "Estonia",
          "tipo": "trabajo",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Contrato de trabajo \nseguro médico \ncertificado de estudios y experiencia previa."
          },
          {
          "pasaporte": "Mexico",
          "origen": "Todos",
          "destino": "Finlandia",
          "tipo": "trabajo",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Contrato de trabajo \nantecedentes penales. Disponible tarjeta azul para trabajadores cualificados."
          },
          {
          "pasaporte": "Mexico",
          "origen": "Todos",
          "destino": "Francia",
          "tipo": "trabajo",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Contrato de trabajo \nsolicitud de visado"
          },
          {
          "pasaporte": "Mexico",
          "origen": "Todos",
          "destino": "Grecia",
          "tipo": "trabajo",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Declaración de empleador \nseguro médico \nsolvencia económica."
          },
          {
          "pasaporte": "Mexico",
          "origen": "Todos",
          "destino": "Guatemala",
          "tipo": "trabajo",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Permiso de trabajo \ncertificado de nacimiento. Disponible plataforma digital para solicitud en línea."
          },
          {
          "pasaporte": "Mexico",
          "origen": "Todos",
          "destino": "Hungría",
          "tipo": "trabajo",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Contrato de trabajo"
          },
          {
          "pasaporte": "Mexico",
          "origen": "Todos",
          "destino": "Irlanda",
          "tipo": "trabajo",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Contrato de trabajo. Disponible visado de trabajo cualificado"
          },
          {
          "pasaporte": "Mexico",
          "origen": "Todos",
          "destino": "Islandia",
          "tipo": "trabajo",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Contrato de trabajo. Disponible visado para búsqueda de empleo. Contactar a un asesor"
          },
          {
          "pasaporte": "Mexico",
          "origen": "Todos",
          "destino": "Italia",
          "tipo": "trabajo",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Autorización para el trabajo (en original y fotocopia) expedida por la \"Direzione Provinciale del Lavoro\" y aprobada por la \"Questura\" competente o el “Nulla Osta” expedido por el “Sportello Unico"
          },
          {
          "pasaporte": "Mexico",
          "origen": "Todos",
          "destino": "Japón",
          "tipo": "trabajo",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Oferta de trabajo. Visas disponibles para trabajo cualificado y habilidades especificadas. Contactar a un asesor"
          },
          {
          "pasaporte": "Mexico",
          "origen": "Todos",
          "destino": "Letonia",
          "tipo": "trabajo",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Carta del empleador \nconstancia de alojamiento \npermiso de residencia."
          },
          {
          "pasaporte": "Mexico",
          "origen": "Todos",
          "destino": "Líbano",
          "tipo": "trabajo",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Permiso de trabajo \nseguro de viaje \nacta de nacimiento"
          },
          {
          "pasaporte": "Mexico",
          "origen": "Todos",
          "destino": "Lituania",
          "tipo": "trabajo",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Contrato de trabajo \nseguro médico \ncertificado de estudios y experiencia previa."
          },
          {
          "pasaporte": "Mexico",
          "origen": "Todos",
          "destino": "Luxemburgo",
          "tipo": "trabajo",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Contrato de trabajo \nseguro médico \ncertificado de estudios y experiencia previa."
          },
          {
          "pasaporte": "Mexico",
          "origen": "Todos",
          "destino": "Malta",
          "tipo": "trabajo",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Contrato de trabajo. Se solicita en Malta"
          },
          {
          "pasaporte": "Mexico",
          "origen": "Todos",
          "destino": "Marruecos",
          "tipo": "trabajo",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Contrato de trabajo"
          },
          {
          "pasaporte": "Mexico",
          "origen": "Todos",
          "destino": "México",
          "tipo": "trabajo",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Oferta de empleo \nNotificación del Instituto Nacional de Migración con Número Único de Trámite (NUT)"
          },
          {
          "pasaporte": "Mexico",
          "origen": "Todos",
          "destino": "Montenegro",
          "tipo": "trabajo",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Contrato de trabajo \ncertificado médico \nantecedentes penales"
          },
          {
          "pasaporte": "Mexico",
          "origen": "Todos",
          "destino": "Noruega",
          "tipo": "trabajo",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Contrato de trabajo \nprueba de hospedaje \ncertificado de trabajos previos \ncertificados de estudios previos"
          },
          {
          "pasaporte": "Mexico",
          "origen": "Todos",
          "destino": "Nueva Zelanda",
          "tipo": "trabajo",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Oferta Laboral \nexperiencia laboral y estudios demostrables \nsuficiencia de ingles."
          },
          {
          "pasaporte": "Mexico",
          "origen": "Todos",
          "destino": "Países Bajos",
          "tipo": "trabajo",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Contrato de trabajo \nantecedentes penales \nseguro de viaje \nexámenes médicos. Otros visados disponinles. Contacte a un asesor."
          },
          {
          "pasaporte": "Mexico",
          "origen": "Todos",
          "destino": "Polonia",
          "tipo": "trabajo",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "El empleador debe solicitar permiso de trabajo \nseguro médico. Otras visas disponibles. Contacte a un asesor."
          },
          {
          "pasaporte": "Mexico",
          "origen": "Todos",
          "destino": "Portugal",
          "tipo": "trabajo",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Contrato de trabajo. Visado para buscar trabajo según disponibilidad de cupo anual."
          },
          {
          "pasaporte": "Mexico",
          "origen": "Todos",
          "destino": "Reino Unido",
          "tipo": "trabajo",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Contar con empleador auotrizado. Prueba de hospedaje \nprueba de tuberculosis. Otras visas disponibles. Contacte a un asesor."
          },
          {
          "pasaporte": "Mexico",
          "origen": "Todos",
          "destino": "República Checa",
          "tipo": "trabajo",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "El empleador debe solicitar permiso de trabajo \nseguro médico \nantecedentes penales \ncopias certificadas de calificaciones académicas y laborales"
          },
          {
          "pasaporte": "Mexico",
          "origen": "Todos",
          "destino": "Rumanía",
          "tipo": "trabajo",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Contrato de trabajo \ncertificado médico \nantecedentes penales"
          },
          {
          "pasaporte": "Mexico",
          "origen": "Todos",
          "destino": "Serbia",
          "tipo": "trabajo",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Ell empleador debe solicitar al Servicio Nacional de Empleo ( National Employment Service  \nNES) que realice una prueba de mercado laboral para garantizar que no haya ciudadanos serbios elegibles disponibles para ocupar el puesto. Permiso de trabajo"
          },
          {
          "pasaporte": "Mexico",
          "origen": "Todos",
          "destino": "Suecia",
          "tipo": "trabajo",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Contrato de trabajo \npermiso de trabajo. Disponible visado para buscar trabajo a personas altamente calificadas"
          },
          {
          "pasaporte": "Mexico",
          "origen": "Todos",
          "destino": "Suiza",
          "tipo": "trabajo",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Contrato de trabajo para profesionales cualificados según disponibilidad de cupo anual."
          },
          {
          "pasaporte": "Mexico",
          "origen": "Todos",
          "destino": "Túnez",
          "tipo": "trabajo",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Carta de invitación del empleador \ncomprobante de alojamiento"
          },
          {
          "pasaporte": "Mexico",
          "origen": "Todos",
          "destino": "Albania",
          "tipo": "trabajo",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Contrato de empleo \nextracto del Centro Nacional de Registro que confirma el registro como empleador."
          },
          {
          "pasaporte": "Mexico",
          "origen": "Todos",
          "destino": "Australia",
          "tipo": "trabajo",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Visa por habilidades y sistema de puntuación. Contacte a un asesor."
          },
          {
          "pasaporte": "Mexico",
          "origen": "Todos",
          "destino": "Emiratos Árabes Unidos",
          "tipo": "trabajo",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Contrato de trabajo \nseguro médico. Disponible visa nómada y trabajo freelance"
          },
          {
          "pasaporte": "Mexico",
          "origen": "Todos",
          "destino": "India",
          "tipo": "trabajo",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Contrato de trabajo \npara profesionales calificados \ncopias de diplomas de estudios previos y certificados laborales previos."
          },
          {
          "pasaporte": "Mexico",
          "origen": "Todos",
          "destino": "Libia",
          "tipo": "trabajo",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Contrato de trabajo"
          },
          {
          "pasaporte": "Mexico",
          "origen": "Todos",
          "destino": "Taiwán",
          "tipo": "trabajo",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Contrato de trabajo \ncertificado de saliud"
          },
          {
          "pasaporte": "Mexico",
          "origen": "Todos",
          "destino": "Vietnam",
          "tipo": "trabajo",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Empleador debe solicitar permiso de trabajo"
          },
          {
          "pasaporte": "Mexico",
          "origen": "Todos",
          "destino": "Corea del Sur",
          "tipo": "trabajo",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Copia del contrato de empleo \ncertificados educativos y profesionales \nantecedentes penales \ncarta de recomendación"
          },
          {
          "pasaporte": "Mexico",
          "origen": "Todos",
          "destino": "Argentina",
          "tipo": "trabajo",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Contrato de trabajo \ncertificado médico \nantecedentes penales"
          },
          {
          "pasaporte": "Mexico",
          "origen": "Todos",
          "destino": "Bolivia",
          "tipo": "trabajo",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Contrato de trabajo \ncertificado médico \nantecedentes penales"
          },
          {
          "pasaporte": "Mexico",
          "origen": "Todos",
          "destino": "Brasil",
          "tipo": "trabajo",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Contrato de trabajo \ncertificado médico \nantecedentes penales"
          },
          {
          "pasaporte": "Mexico",
          "origen": "Todos",
          "destino": "Chile",
          "tipo": "trabajo",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Contrato de trabajo \ncertificado médico \nantecedentes penales"
          },
          {
          "pasaporte": "Mexico",
          "origen": "Todos",
          "destino": "China",
          "tipo": "trabajo",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Certificado de salud \npermiso de trabajo que se otorga a criterio según aporte al país. Prueba de alojamiento"
          },
          {
          "pasaporte": "Mexico",
          "origen": "Todos",
          "destino": "Colombia",
          "tipo": "trabajo",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Contrato de trabajo \ncertificado médico \nantecedentes penales"
          },
          {
          "pasaporte": "Mexico",
          "origen": "Todos",
          "destino": "Curacao",
          "tipo": "trabajo",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Certificado médico \ncertificado de antecedentes policiales \ncarta de su empleador indicando que le han ofrecido un trabajo \ncopia de su contrato de trabajo \ncopia de CV \ncopia de sus expedientes académicos."
          },
          {
          "pasaporte": "Mexico",
          "origen": "Todos",
          "destino": "El Salvador",
          "tipo": "trabajo",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Contrato de trabajo. Los extranjeros podrán solicitar residencia temporal con autorización para trabajar en la Dirección General de Migración y Extranjería (DGME) con la opinión del Ministerio de Trabajo y Previsión Social (MTPS) según el caso."
          },
          {
          "pasaporte": "Mexico",
          "origen": "Todos",
          "destino": "Federación Rusa",
          "tipo": "trabajo",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Empleador debe tramitar permiso de trabajo.Copias certificadas de calificaciones \ncertificado médico."
          },
          {
          "pasaporte": "Mexico",
          "origen": "Todos",
          "destino": "Honduras",
          "tipo": "trabajo",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Contrato de trabajo \ncertificado médico \nantecedentes penales"
          },
          {
          "pasaporte": "Mexico",
          "origen": "Todos",
          "destino": "Hong Kong",
          "tipo": "trabajo",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Contrato de trabajo \ntítulo profesional \nexperiencia profesional"
          },
          {
          "pasaporte": "Mexico",
          "origen": "Todos",
          "destino": "Israel",
          "tipo": "trabajo",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Contrato de trabajo \ncertificado médico \nantecedentes penales"
          },
          {
          "pasaporte": "Mexico",
          "origen": "Todos",
          "destino": "Jamaica",
          "tipo": "trabajo",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Empleador solicita permiso de trabajo \ncertificado de nacimiento \nantecedentes penales \nprueba de estado civil \nsolvencia económica."
          },
          {
          "pasaporte": "Mexico",
          "origen": "Todos",
          "destino": "Nicaragua",
          "tipo": "trabajo",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Contrato de trabajo \ncertificado médico \nantecedentes penales"
          },
          {
          "pasaporte": "Mexico",
          "origen": "Todos",
          "destino": "Panamá",
          "tipo": "trabajo",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Contrato de trabajo \ncertificado médico \nantecedentes penales \ncomprobante de alojamiento"
          },
          {
          "pasaporte": "Mexico",
          "origen": "Todos",
          "destino": "Paraguay",
          "tipo": "trabajo",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Contrato de trabajo \ncertificado médico \nceertificado de estado civil \ncertificado de nacimiento \nantecedentes penales"
          },
          {
          "pasaporte": "Mexico",
          "origen": "Todos",
          "destino": "Perú",
          "tipo": "trabajo",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Contrato de trabajo \ncertificado médico \nantecedentes penales"
          },
          {
          "pasaporte": "Mexico",
          "origen": "Todos",
          "destino": "República Dominicana",
          "tipo": "trabajo",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Contrato de trabajo \ncertificado médico \nantecedentes penales"
          },
          {
          "pasaporte": "Mexico",
          "origen": "Todos",
          "destino": "Singapur",
          "tipo": "trabajo",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "El empleador debe solicitar el visado"
          },
          {
          "pasaporte": "Mexico",
          "origen": "Todos",
          "destino": "Sudáfrica",
          "tipo": "trabajo",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Oferta de trabajo \nexámenes médicos \nceertificado de vacunación \nantecedentes penales."
          },
          {
          "pasaporte": "Mexico",
          "origen": "Todos",
          "destino": "Tailandia",
          "tipo": "trabajo",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Oferta de trabajo. Visas disponibles según el trabajo a desempeñar. Contacte a un asesor"
          },
          {
          "pasaporte": "Mexico",
          "origen": "Todos",
          "destino": "Turquía",
          "tipo": "trabajo",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Oferta de trabajo o contrato de trabajo \nantecedentes penales \nseguro médico \nevidencia de alojamiento \nsolvencia económica"
          },
          {
          "pasaporte": "Mexico",
          "origen": "Todos",
          "destino": "Uruguay",
          "tipo": "trabajo",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Contrato de trabajo \ncertificado médico \nantecedentes penales"
          },
          {
          "pasaporte": "Mexico",
          "origen": "Todos",
          "destino": "Venezuela",
          "tipo": "trabajo",
          "visado": "SI",
          "porcentaje": "0.98",
          "requisitos": "Contrato de trabajo \ncertificado médico \nantecedentes penales"
          }
      ]
    }
}
